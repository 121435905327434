export const CHANGE_NETWORK_STATUS = 'CHANGE_NETWORK_STATUS';

export interface ChangeNetworkStatusAction {
  type: typeof CHANGE_NETWORK_STATUS;
  isOnline: boolean;
}

export const changeNetworkStatus = (isOnline: boolean) => ({
  type: CHANGE_NETWORK_STATUS,
  isOnline
});
