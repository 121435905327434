import * as PIXI from 'pixi.js';

import { LayerZoomConfig } from './TimelineControlBus';
import TimelineHelpers from './Utils/TimelineHelpers';
import { MIN_ZOOM_IN_SECONDS } from './ZoomManager';

const TICK_COLOR = 0x7f7f7f;
const TEXT_COLOR = 0xd8d8d8;

export default class TimelineTopMeasure extends PIXI.Container {
  public pixelsPerSecond = 15;
  private _scribeLengthInSeconds: number;

  graphics: PIXI.Graphics;
  textLookup: { [key: string]: PIXI.Text };
  scribeStartTime: number;
  lastPrimaryMark = 0;
  minimumPrimaryMarkDistance = 35;
  layerHeight = 30;

  constructor(startTime: number, scribeLength: number, mask?: PIXI.Graphics) {
    super();
    this._scribeLengthInSeconds = Math.max(scribeLength, MIN_ZOOM_IN_SECONDS);
    this.scribeStartTime = startTime;
    this.graphics = new PIXI.Graphics();
    this.graphics.lineStyle(1, TICK_COLOR, 1);
    this.textLookup = {};
    this.addChild(this.graphics);
    this.drawTrack();
    this.mask = mask || null;
  }

  public get scribeLengthInSeconds(): number {
    return this._scribeLengthInSeconds;
  }

  public set scribeLengthInSeconds(value: number) {
    this._scribeLengthInSeconds = Math.max(value, MIN_ZOOM_IN_SECONDS);
  }

  private drawTrack(): void {
    const subSecondOffset = TimelineHelpers.roundNumber(Math.ceil(this.scribeStartTime) - this.scribeStartTime);
    if (subSecondOffset !== 0) {
      this.interpolateMarks(0, subSecondOffset * 10);
    }
    for (let i = 0; i < this.scribeLengthInSeconds + 1; i++) {
      const offsetIndex = i + subSecondOffset;
      this.graphics.moveTo(offsetIndex * this.pixelsPerSecond, this.layerHeight);
      if (
        i === 0 ||
        i * this.pixelsPerSecond - this.lastPrimaryMark * this.pixelsPerSecond > this.minimumPrimaryMarkDistance
      ) {
        this.graphics.lineTo(offsetIndex * this.pixelsPerSecond, 0);
        this.writeText(offsetIndex);
        this.lastPrimaryMark = offsetIndex;
        this.interpolateMarks(offsetIndex);
      } else {
        this.graphics.lineTo(offsetIndex * this.pixelsPerSecond, this.layerHeight - 9);
        this.interpolateMarks(offsetIndex);
      }
    }
    this.graphics.moveTo(0, this.layerHeight);
    this.graphics.lineTo(this.scribeLengthInSeconds * this.pixelsPerSecond, this.layerHeight);
  }

  private writeText(i: number) {
    let text: PIXI.Text;
    if (!this.textLookup[i.toString()]) {
      text = new PIXI.Text(TimelineHelpers.formatTime(i + this.scribeStartTime), {
        fontFamily: 'Effra',
        fontSize: 11,
        fill: TEXT_COLOR,
        align: 'left',
        fontWeight: '700'
      });

      this.textLookup[i.toString()] = text;
    } else {
      text = this.textLookup[i.toString()];
    }

    text.y = 5;
    text.x = Math.round(3 + i * this.pixelsPerSecond);
    this.addChild(text);
  }

  updateScribeLength(sceneLength: number, currentZoom: PIXI.Point) {
    if (sceneLength <= this.scribeLengthInSeconds && currentZoom.x === this.pixelsPerSecond) return;
    this.scribeLengthInSeconds = sceneLength;
    this.updateZoom(currentZoom, true);
  }

  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    if (this.destroyed || this.graphics.destroyed) return;
    this.graphics.destroy({ children: true, texture: true, baseTexture: true });

    for (const a in this.textLookup) {
      const text = this.textLookup[a];
      text.destroy({ children: true, texture: true, baseTexture: true });
      delete this.textLookup[a];
    }

    this.textLookup = {};
    super.destroy(options);
  }

  public updateZoom({ x }: LayerZoomConfig, forceRedraw = false) {
    if (this.pixelsPerSecond === x && forceRedraw === false) return;
    this.pixelsPerSecond = x as number;
    this.graphics.destroy();
    this.graphics = new PIXI.Graphics();
    this.graphics.lineStyle(1, TICK_COLOR, 1);
    this.removeChildren();
    this.addChild(this.graphics);
    this.drawTrack();
  }

  private interpolateMarks(i: number, offset = 11) {
    const basePosition = i * this.pixelsPerSecond;
    const subPosition = this.pixelsPerSecond / 10;
    const halfwayPoint = 5;
    const longMarkLength = 8;
    const shortMarkLength = 8;
    const lastMarkMinGap = 2.8;

    for (let k = 0; k < offset; k++) {
      if (offset - 1 - k !== halfwayPoint && subPosition < lastMarkMinGap) continue;

      this.graphics.moveTo(basePosition + k * subPosition, this.layerHeight);
      this.graphics.lineTo(
        basePosition + k * subPosition,
        this.layerHeight - (offset - 1 - k === halfwayPoint ? longMarkLength : shortMarkLength)
      );
    }
  }
}
