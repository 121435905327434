import React from 'react';
import classnames from 'classnames';

import './EditPanelLabel.css';

const EditPanelLabel = ({ children, htmlFor, className = '', ...props }) => {
  const labelClassNames = classnames('EditPanelLabel', className || '');
  return (
    <label className={labelClassNames} htmlFor={htmlFor} {...props}>
      {children}
    </label>
  );
};

export default EditPanelLabel;
