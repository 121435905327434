export function fixSvgWidthAndHeight(svgElement) {
  const svgOriginalWidth = svgElement.getAttribute('width');
  const svgOriginalHeight = svgElement.getAttribute('height');
  const svgOriginalViewBox = svgElement.getAttribute('viewBox');

  if (!svgOriginalViewBox && !svgOriginalWidth && !svgOriginalHeight) {
    svgElement.setAttribute('width', 1000);
    svgElement.setAttribute('height', 1000);
  } else if (!svgOriginalWidth && !svgOriginalHeight) {
    const svgViewBoxArray = svgOriginalViewBox.split(' ');
    svgElement.setAttribute('width', parseFloat(svgViewBoxArray[2]));
    svgElement.setAttribute('height', parseFloat(svgViewBoxArray[3]));
  } else {
    svgElement.setAttribute('width', parseFloat(svgOriginalWidth));
    svgElement.setAttribute('height', parseFloat(svgOriginalHeight));
  }
  return svgElement;
}
