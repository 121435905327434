import { FILE_CONTENT_TYPES } from 'js/config/consts';
import { svgRootFromText } from 'js/shared/lib/SVGConvert';

export async function withNounProject(imageFile: Blob) {
  if (imageFile.type !== FILE_CONTENT_TYPES.SVG) {
    return imageFile;
  }
  const svgText = await imageFile.text();
  const svgRoot = svgRootFromText(svgText);
  if (!svgRoot) {
    return null;
  }
  svgRoot.setAttribute('data-provider', 'noun-project');
  const newSvg = new XMLSerializer().serializeToString(svgRoot);
  return new Blob([newSvg], { type: FILE_CONTENT_TYPES.SVG });
}
