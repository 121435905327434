import { SelectEffect, select, call, CallEffect } from 'redux-saga/effects';
import { RootState, CurrentUser } from 'js/types';
import {
  getUserPermissionHelperFunctions,
  UserPermissionHelperFunctions,
  UserWithPermissions
} from 'js/permissions/permissionsHelpers';

export const selectUser = (state: RootState) => state.auth.currentUser;

export function* yieldUserWithPermission(): Generator<
  SelectEffect | CallEffect,
  UserWithPermissions,
  CurrentUser | UserPermissionHelperFunctions
> {
  const user = (yield select(selectUser)) as CurrentUser;
  const helperFunctions = (yield call(getUserPermissionHelperFunctions, user)) as UserPermissionHelperFunctions;
  return { ...user, ...helperFunctions };
}
