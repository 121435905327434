import {
  ALIGN_SELECTED_ELEMENTS,
  AlignSelectedElementsAction,
  DISTRIBUTE_SELECTED_ELEMENTS,
  DistributeSelectedElementsAction
} from 'js/actionCreators/elementAlignmentActions';
import { CallEffect, SelectEffect, call, select, takeEvery } from 'redux-saga/effects';
import {
  ALIGN_ACTION_RIGHT,
  ALIGN_ACTION_LEFT,
  ALIGN_ACTION_TOP,
  ALIGN_ACTION_BOTTOM,
  ALIGN_ACTION_V_CENTER,
  ALIGN_ACTION_H_CENTER
} from 'js/editor/EditPanels/AlignmentControls';
import { getActiveElements, getScribeById } from 'js/sagas/selectors';
import { mapActiveSceneElementIdsToElements } from 'js/shared/helpers/scenesHelpers';
import { ExistingScribeModel } from 'js/types';
import { DISTRIBUTE_ACTION_HORIZONTAL } from 'js/editor/EditPanels/DistributionControls';

import { track } from '../mixpanelProvider';

function* getActiveElementsType(projectId: number): Generator<SelectEffect, string | undefined, ExistingScribeModel> {
  if (!projectId) return;

  const project = yield select(getScribeById, projectId);
  if (!project || Array.isArray(project)) return;

  const activeElementIds = yield select(getActiveElements);
  if (!Array.isArray(activeElementIds) || !activeElementIds || !activeElementIds.length) return;

  const elements = mapActiveSceneElementIdsToElements(project);
  if (!elements || !elements.length) return;

  const activeElements = elements.filter(element => activeElementIds.includes(element.id));
  const allElementsSameType = activeElements.every(element => element.type === activeElements[0].type);
  const elementType = allElementsSameType ? activeElements[0].type : 'Mixed';

  return elementType;
}

function* trackElementAlignmentSaga({
  alignment,
  projectId
}: AlignSelectedElementsAction): Generator<SelectEffect | CallEffect, void, string | undefined> {
  const elementType = yield call(getActiveElementsType, projectId);

  let eventName = '';
  switch (alignment) {
    case ALIGN_ACTION_RIGHT:
      eventName = 'Align Elements Right';
      break;
    case ALIGN_ACTION_LEFT:
      eventName = 'Align Elements Left';
      break;
    case ALIGN_ACTION_TOP:
      eventName = 'Align Elements Top';
      break;
    case ALIGN_ACTION_BOTTOM:
      eventName = 'Align Elements Bottom';
      break;
    case ALIGN_ACTION_V_CENTER:
      eventName = 'Align Elements Center (Vertical)';
      break;
    case ALIGN_ACTION_H_CENTER:
      eventName = 'Align Elements Center (Horizontal)';
      break;
    default:
      return;
  }

  yield call(track, eventName, {
    'Scribe ID': projectId,
    'Element Type': elementType
  });
}

function* tractElementsDistribution({
  distribution,
  projectId
}: DistributeSelectedElementsAction): Generator<CallEffect, void, string | undefined> {
  const eventName =
    distribution === DISTRIBUTE_ACTION_HORIZONTAL
      ? 'Distribute Elements (Horizontal)'
      : 'Distribute Elements (Vertical)';
  const elementType = yield call(getActiveElementsType, projectId);

  yield call(track, eventName, {
    'Scribe ID': projectId,
    'Element Type': elementType
  });
}

export default function* trackElementAlignment() {
  yield takeEvery(ALIGN_SELECTED_ELEMENTS, trackElementAlignmentSaga);
  yield takeEvery(DISTRIBUTE_SELECTED_ELEMENTS, tractElementsDistribution);
}
