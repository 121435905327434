import { useEffect } from 'react';

export default function useOnClickOutside(inside, handler) {
  useEffect(() => {
    let cancelled = false;

    const handleClickOutside = e => {
      if (
        (Array.isArray(inside) && inside.some(ref => ref.current?.contains?.(e.target))) ||
        inside.current?.contains?.(e.target)
      ) {
        return;
      }

      if (!cancelled) {
        handler?.(e);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('touchstart', handleClickOutside);

    return () => {
      cancelled = true;
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('touchstart', handleClickOutside);
    };
  }, [inside, handler]);
}
