import {
  SaveAudioToLibraryAction,
  SaveAudioToLibraryFailedAction,
  SaveAudioToLibrarySuccessAction,
  SAVE_AUDIO_TO_LIBRARY,
  SAVE_AUDIO_TO_LIBRARY_FAILED,
  SAVE_AUDIO_TO_LIBRARY_SUCCESS,
  SAVE_AUDIO_ASSET_METADATA_SUCCESS,
  SaveAudioAssetMetadataSuccessAction,
  AudioClipLimitReachedAction,
  AUDIO_CLIP_LIMIT_REACHED,
  CancelAudioSelectionAction,
  CANCEL_AUDIO_SELECTION,
  UseAudioAssetInProjectSuccessAction,
  USE_AUDIO_ASSET_IN_PROJECT_SUCCESS,
  SAVE_AUDIO_ASSET_METADATA,
  SaveAudioAssetMetadataAction,
  SAVE_AUDIO_ASSET_METADATA_FAILED,
  SaveAudioAssetMetadataFailedAction,
  SaveAudioToLibraryInProgressAction,
  SAVE_AUDIO_TO_LIBRARY_IN_PROGRESS,
  SaveAudioToLibraryCancelledAction,
  SAVE_AUDIO_TO_LIBRARY_CANCELLED,
  SAVE_AUDIO_SUCCESS,
  SaveAudioSuccessAction
} from 'js/actionCreators/audioActions';
import { AudioUploadState } from 'js/types';

const initialState: AudioUploadState = {
  uploading: false,
  uploadingFilename: '',
  lastUploadId: undefined,
  lastUploadSource: undefined,
  incomingAudioAsset: undefined,
  savingMetadata: false,
  uploadAbortController: undefined
};

type AudioUploadActionType =
  | SaveAudioToLibraryAction
  | SaveAudioToLibraryFailedAction
  | SaveAudioToLibrarySuccessAction
  | SaveAudioAssetMetadataSuccessAction
  | AudioClipLimitReachedAction
  | CancelAudioSelectionAction
  | UseAudioAssetInProjectSuccessAction
  | SaveAudioAssetMetadataAction
  | SaveAudioAssetMetadataFailedAction
  | SaveAudioToLibraryInProgressAction
  | SaveAudioSuccessAction
  | SaveAudioToLibraryCancelledAction;

export default function audioUploadReducer(state = initialState, action: AudioUploadActionType) {
  switch (action.type) {
    case SAVE_AUDIO_TO_LIBRARY: {
      return {
        ...state,
        uploading: true,
        uploadingFilename: action.file.name,
        lastUploadId: undefined,
        lastUploadSource: undefined
      };
    }

    case SAVE_AUDIO_TO_LIBRARY_FAILED: {
      return {
        ...state,
        uploading: false,
        uploadingFilename: initialState.uploadingFilename,
        lastUploadId: undefined,
        lastUploadSource: undefined,
        uploadAbortController: undefined
      };
    }

    case SAVE_AUDIO_TO_LIBRARY_SUCCESS: {
      return {
        ...state,
        uploading: false,
        uploadingFilename: initialState.uploadingFilename,
        lastUploadId: action.audioClipData.projectAssetId,
        lastUploadSource: action.source,
        uploadAbortController: undefined
      };
    }

    case SAVE_AUDIO_ASSET_METADATA: {
      return {
        ...state,
        savingMetadata: true
      };
    }

    case SAVE_AUDIO_ASSET_METADATA_FAILED: {
      return {
        ...state,
        savingMetadata: false
      };
    }

    case SAVE_AUDIO_ASSET_METADATA_SUCCESS: {
      return {
        ...state,
        uploading: false,
        uploadingFilename: initialState.uploadingFilename,
        lastUploadId: undefined,
        savingMetadata: false
      };
    }

    case AUDIO_CLIP_LIMIT_REACHED: {
      return {
        ...state,
        incomingAudioAsset: action.incomingAsset
      };
    }

    case USE_AUDIO_ASSET_IN_PROJECT_SUCCESS:
    case CANCEL_AUDIO_SELECTION: {
      return {
        ...state,
        incomingAudioAsset: undefined
      };
    }

    case SAVE_AUDIO_TO_LIBRARY_IN_PROGRESS: {
      return {
        ...state,
        uploadAbortController: action.abortController
      };
    }

    case SAVE_AUDIO_SUCCESS:
      return {
        ...state,
        lastUploadId: action.assetId
      };

    case SAVE_AUDIO_TO_LIBRARY_CANCELLED: {
      return {
        ...state,
        uploadAbortController: undefined,
        uploading: false,
        uploadingFilename: initialState.uploadingFilename,
        lastUploadId: undefined,
        savingMetadata: false
      };
    }

    default:
      return state;
  }
}
