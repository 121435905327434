export interface Image {
  data: number[][];
}

export const findSeparateImageShapes = (image: Image): Image[] => {
  const { data } = image;
  const numRows = data.length;
  const numCols = data[0].length;

  const visited = new Array(numRows).fill(false).map(() => new Array(numCols).fill(false));

  const images = new Array<Image>();

  for (let r = 0; r < numRows; r++) {
    for (let c = 0; c < numCols; c++) {
      if (data[r][c] === 1 && !visited[r][c]) {
        const found = search(data, visited, r, c);
        images.push({ data: found });
      }
    }
  }

  return images;
};

// breadth-first search
function search(data: number[][], visited: boolean[][], startRow: number, startCol: number): number[][] {
  const row = [-1, -1, -1, 0, 0, 1, 1, 1];
  const col = [-1, 0, 1, -1, 1, -1, 0, 1];

  const q: [number, number][] = [];
  q.push([startRow, startCol]);
  visited[startRow][startCol] = true;

  const result = new Array(data.length).fill(0).map(() => new Array(data[0].length).fill(0));

  result[startRow][startCol] = 1;

  while (q.length > 0) {
    const next = q.shift();
    if (!next) break;
    const [r, c] = next;

    // for all 8 adjacent pixels
    for (let i = 0; i < 8; i++) {
      const adjacentRow = r + row[i];
      const adjacentCol = c + col[i];
      const isSameIsland =
        adjacentRow >= 0 &&
        adjacentCol >= 0 &&
        adjacentRow < data.length &&
        adjacentCol < data[0].length &&
        !visited[adjacentRow][adjacentCol] &&
        data[adjacentRow][adjacentCol] !== 0;
      if (isSameIsland) {
        visited[adjacentRow][adjacentCol] = true;
        result[adjacentRow][adjacentCol] = 1;
        q.push([adjacentRow, adjacentCol]);
      }
    }
  }

  return result;
}
