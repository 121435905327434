import {
  TRACK_CLICK_ADD_CUSTOM_FONTS,
  TrackClickAddCustomFontsAction,
  UPLOAD_FONTS_CONFIRMATION_STATUS,
  UploadConfirmationStatusAction
} from 'js/actionCreators/fontActions';
import { getUserFontFileExtension, getUserFontName } from 'js/shared/helpers/getUserFontName';
import { call, takeEvery } from 'redux-saga/effects';

import { track } from '../mixpanelProvider';

function* trackCustomFontsUpload({ confirmation }: UploadConfirmationStatusAction) {
  for (const filename of confirmation.filesUploaded) {
    yield call(track, 'Upload Custom Font', {
      'Font File Name': getUserFontName(filename),
      'Font Type': getUserFontFileExtension(filename)
    });
  }
}

export function* trackClickAddCustomFont({ scribeId }: TrackClickAddCustomFontsAction) {
  yield call(track, 'Click Add Custom Font', {
    'Scribe ID': scribeId
  });
}

export function* trackDeleteUserFont(fontname: string | null) {
  yield call(track, 'Delete Custom Font', {
    'Font File Name': fontname ? getUserFontName(fontname) : 'UNKNOWN',
    'Font Type': fontname ? getUserFontFileExtension(fontname) : 'UNKNOWN'
  });
}

export default function* trackUserFontsSaga() {
  yield takeEvery(UPLOAD_FONTS_CONFIRMATION_STATUS, trackCustomFontsUpload);
  yield takeEvery(TRACK_CLICK_ADD_CUSTOM_FONTS, trackClickAddCustomFont);
}
