const getLockedProperties = (locked: boolean) => {
  return {
    lockMovementX: locked,
    lockMovementY: locked,
    lockRotation: locked,
    lockScalingFlip: true,
    lockScalingX: locked,
    lockScalingY: locked,
    lockSkewingX: locked,
    lockSkewingY: locked
  };
};

export default getLockedProperties;
