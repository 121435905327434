const getVisibleProperties = (locked: boolean, hidden: boolean) => {
  if (locked && hidden) {
    return {
      selectable: false,
      evented: false,
      visible: false
    };
  } else if (locked && !hidden) {
    return {
      selectable: false,
      evented: false,
      visible: true
    };
  } else if (!locked && hidden) {
    return {
      selectable: false,
      evented: false,
      visible: false
    };
  } else if (!locked && !hidden) {
    return {
      selectable: true,
      evented: true,
      visible: true
    };
  }
};

export default getVisibleProperties;
