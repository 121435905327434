import * as PIXI from 'pixi.js';
import ExpandIcon from 'imgs/pixi_icons/IconExpand.svg';
import IconScrollToEnd from 'imgs/pixi_icons/IconScrollToEnd.svg';
import IconScrollToBeginning from 'imgs/pixi_icons/IconScrollToBeginning.svg';

import IconButton from './IconButton';
import TimelineHelpers from './Utils/TimelineHelpers';

interface ViewControlsConfig {
  onScrollToStart: () => void;
  onScrollToEnd: () => void;
  onHolisticView: () => void;
}

const BUTTON_SIZE = 18;
const PADDING = 2;

export default class ViewControls extends PIXI.Container {
  private onScrollToStart: () => void;
  private onScrollToEnd: () => void;
  private onHolisticView: () => void;

  private scrollToStartButton: IconButton;
  private scrollToEndButton: IconButton;
  private holisticViewButton: IconButton;
  private separators = new PIXI.Graphics();

  constructor({ onScrollToStart, onScrollToEnd, onHolisticView }: ViewControlsConfig) {
    super();

    this.onScrollToStart = onScrollToStart;
    this.onScrollToEnd = onScrollToEnd;
    this.onHolisticView = onHolisticView;

    this.scrollToStartButton = new IconButton(TimelineHelpers.importSVG(IconScrollToBeginning), this.onScrollToStart);
    this.scrollToEndButton = new IconButton(TimelineHelpers.importSVG(IconScrollToEnd), this.onScrollToEnd);
    this.holisticViewButton = new IconButton(TimelineHelpers.importSVG(ExpandIcon), this.onHolisticView);

    this.addChild(this.separators, this.scrollToStartButton, this.scrollToEndButton, this.holisticViewButton);

    this.layout();
  }

  private layout() {
    this.x = 0;
    this.y = 12;

    this.scrollToStartButton.x = 0;
    this.scrollToStartButton.y = 0;
    this.scrollToStartButton.height = BUTTON_SIZE;
    this.scrollToStartButton.width = BUTTON_SIZE;

    this.separators.lineStyle({ width: 1, color: 0x7f7f7f });
    this.separators.moveTo(BUTTON_SIZE + PADDING, 0);
    this.separators.lineTo(BUTTON_SIZE + PADDING, BUTTON_SIZE);

    this.scrollToEndButton.x = BUTTON_SIZE + 2 * PADDING;
    this.scrollToEndButton.y = 0;
    this.scrollToEndButton.height = BUTTON_SIZE;
    this.scrollToEndButton.width = BUTTON_SIZE;

    this.separators.moveTo(2 * BUTTON_SIZE + 3 * PADDING, 0);
    this.separators.lineTo(2 * BUTTON_SIZE + 3 * PADDING, BUTTON_SIZE);

    this.holisticViewButton.x = 2 * BUTTON_SIZE + 4 * PADDING;
    this.holisticViewButton.y = 0;
    this.holisticViewButton.height = BUTTON_SIZE;
    this.holisticViewButton.width = BUTTON_SIZE;
  }
}
