import { fabric } from 'fabric';
import { ScribeCameraElement, VSDimensions } from 'js/types';
import { CAMERA_COLOR, EDITOR_CANVAS_CONTROL_VISIBILITY, MAX_CAM_ZOOM, MIN_CAM_ZOOM } from 'js/config/defaults';
import clamp from 'lodash.clamp';

import getLockedProperties from './helpers/getLockedProperties';
import { setGroupTransform } from './helpers/canvasElementHelpers';
import { getCameraIcon } from './helpers/cameraIcon';
import { getEditorCanvas } from './EditorCanvas';
import ProjectCanvas from './ProjectCanvas';

const CAMERA_BORDER_WIDTH = 2;
const CAMERA_CORNER_SIZE = 16;

export const getCameraPositionalProperties = (element: ScribeCameraElement, canvasSize: VSDimensions) => {
  const clampedScale = clamp(element.scale, MIN_CAM_ZOOM, MAX_CAM_ZOOM);

  return {
    top: element.y,
    left: element.x,
    width: canvasSize.width / clampedScale,
    height: canvasSize.height / clampedScale,
    scaleX: 1,
    scaleY: 1
  };
};

export default class CameraElement extends fabric.Group {
  id: string;
  locked: boolean;
  cameraPinned: boolean;
  outputSize: VSDimensions;
  element: ScribeCameraElement;
  elementType: 'Camera';
  public cameraIndex: number;
  boundary: fabric.Object;
  cameraIconSrc: HTMLImageElement;

  constructor(projectElement: ScribeCameraElement, canvasSize: VSDimensions, cameraIndex: number) {
    const boundary = new fabric.Object({
      ...getCameraPositionalProperties(projectElement, canvasSize),
      ...getLockedProperties(projectElement.locked),
      lockMovementX: false,
      lockMovementY: false
    });
    super([boundary]);

    this.boundary = boundary;

    this.id = projectElement.id;
    this.cameraPinned = !!projectElement.cameraPinned;
    this.locked = projectElement.locked;
    this.outputSize = canvasSize;
    this.element = projectElement;
    this.elementType = 'Camera';
    this.setBoundingBoxStyles();
    this.cameraIndex = cameraIndex;
    this.cameraIconSrc = getCameraIcon();
    this.originX = 'left';
    this.originY = 'top';
    this.objectCaching = false;

    this.cameraIconSrc.addEventListener(
      'load',
      () => {
        this.dirty = true;
        this.canvas?.requestRenderAll();
      },
      { once: true }
    );

    this.dirty = true;

    this.on('selected', this.handleSelection);
    this.on('deselected', this.handleDeselection);
  }

  drawBorders(
    ctx: CanvasRenderingContext2D,
    styleOverride?: {
      borderDashArray?: string;
      borderColor?: string;
    }
  ): fabric.Object {
    if (this.isSelected()) {
      super.drawBorders(ctx, styleOverride);
    }

    const boundingBox = this._calculateCurrentDimensions();

    const right = boundingBox.x / 2;
    const bottom = boundingBox.y / 2;
    const left = -(boundingBox.x / 2);
    const top = -(boundingBox.y / 2);

    this.drawCameraMarkings(ctx, {
      right,
      bottom,
      left,
      top
    });

    return this;
  }

  drawBordersInGroup(
    ctx: CanvasRenderingContext2D,
    options?: fabric.TScaleMatrixArgs,
    styleOverride?: {
      borderDashArray?: string;
      borderColor?: string;
    }
  ): fabric.Object {
    if (this.isSelected()) {
      super.drawBordersInGroup(ctx, options, styleOverride);
    }
    if (this.width && this.height && options) {
      const boundingBox = fabric.util.sizeAfterTransform(this.width, this.height, options);

      const right = boundingBox.x / 2;
      const bottom = boundingBox.y / 2;
      const left = -(boundingBox.x / 2);
      const top = -(boundingBox.y / 2);

      this.drawCameraMarkings(ctx, {
        right,
        bottom,
        left,
        top
      });
    }
    return this;
  }

  drawCameraMarkings(
    ctx: CanvasRenderingContext2D,
    {
      top,
      left,
      bottom,
      right,
      width,
      height,
      zoom = 1,
      drawBounds = false,
      markingColor = CAMERA_COLOR,
      labelBackgroundColor = '#FFFFFF',
      labelColor = '#000000'
    }: {
      top: number;
      left: number;
      bottom: number;
      right: number;
      width?: number;
      height?: number;
      zoom?: number;
      drawBounds?: boolean;
      markingColor?: string;
      labelBackgroundColor?: string;
      labelColor?: string;
    }
  ) {
    const diagonalLineWidth = 1 / zoom;
    const borderLineWidth = CAMERA_BORDER_WIDTH / zoom;
    const diagonalDashArray = [5 / zoom, 5 / zoom];
    const iconBoxSize = 26 / zoom;
    const iconPaddingLeft = 3 / zoom;
    const iconPaddingTop = 4 / zoom;
    const fontSize = 12 / zoom;
    const textPaddingInline = 4 / zoom;
    const textLabel = `Camera ${this.cameraIndex}`;
    ctx.font = `bold ${fontSize}px "Effra"`;
    ctx.textBaseline = 'middle';
    const textLabelMetrics = ctx.measureText(textLabel);
    const textBoxWidth = textLabelMetrics.width + 2 * textPaddingInline;
    const labelIconBoxX = 0 - (textBoxWidth + iconBoxSize) / 2;
    const labelTextBoxX = iconBoxSize - (textBoxWidth + iconBoxSize) / 2;

    ctx.save();
    // Icon Box
    ctx.fillStyle = markingColor;
    ctx.fillRect(labelIconBoxX, top, iconBoxSize, iconBoxSize);
    // Icon
    ctx.drawImage(
      this.cameraIconSrc,
      labelIconBoxX + iconPaddingLeft,
      top + iconPaddingTop,
      this.cameraIconSrc.width / zoom,
      this.cameraIconSrc.height / zoom
    );

    // Label box
    ctx.fillStyle = labelBackgroundColor;
    ctx.fillRect(labelTextBoxX, top, textBoxWidth, iconBoxSize);
    // Label text
    ctx.fillStyle = labelColor;
    ctx.fillText(textLabel, labelTextBoxX + textPaddingInline, top + iconBoxSize / 2);

    ctx.strokeStyle = markingColor;

    if (drawBounds && width && height) {
      // Border
      ctx.moveTo(left, top);
      ctx.lineWidth = borderLineWidth;
      ctx.strokeRect(left, top, width, height);
    }

    // Diagonals
    ctx.fillStyle = 'transparent';
    ctx.lineWidth = diagonalLineWidth;
    ctx.setLineDash(diagonalDashArray);
    ctx.beginPath();
    ctx.moveTo(left, top);
    ctx.lineTo(right, bottom);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(right, top);
    ctx.lineTo(left, bottom);
    ctx.stroke();

    ctx.restore();
  }

  private setBoundingBoxStyles() {
    const updateBorder = {
      borderColor: CAMERA_COLOR,
      borderScaleFactor: CAMERA_BORDER_WIDTH,
      padding: -CAMERA_BORDER_WIDTH,
      cornerColor: CAMERA_COLOR,
      transparentCorners: false,
      cornerSize: CAMERA_CORNER_SIZE,
      lockScalingFlip: true
    };

    this.set(updateBorder);
    this.setControlsVisibility({
      ...EDITOR_CANVAS_CONTROL_VISIBILITY.camera,
      lockIndicator: this.locked
    });
  }

  public updateProps(props: ScribeCameraElement) {
    this.locked = props.locked;
    this.cameraPinned = !!props.cameraPinned;
    const update = this.group
      ? {
          ...getLockedProperties(props.locked)
        }
      : {
          ...getLockedProperties(props.locked),
          ...getCameraPositionalProperties(props, this.outputSize)
        };

    this.set(update);
    this.element = props;
    this.setBoundingBoxStyles();
  }

  public toVscElement(): ScribeCameraElement {
    const group = this.group;

    setGroupTransform(this);

    if (group) {
      group.remove(this);
    }

    this.clampCameraElementScale();

    const x = this.left || 0;
    const y = this.top || 0;
    const currentWidth = this.getScaledWidth();

    const scale = clamp(this.outputSize.width / currentWidth, MIN_CAM_ZOOM, MAX_CAM_ZOOM);

    const newElement = {
      ...this.element,
      y,
      x,
      scale
    };

    if (group) {
      group.addWithUpdate(this);
      group.dirty = true;
      this.dirty = true;
    }

    return newElement;
  }

  public handleSelection() {
    this.selectable = true;
    this.evented = true;

    this.dirty = true;
    this.canvas?.requestRenderAll();
  }

  public handleDeselection() {
    this.selectable = false;
    this.evented = false;

    this.dirty = true;
    this.canvas?.requestRenderAll();
  }

  public updateCameraIndex(cameraIndex: number) {
    this.cameraIndex = cameraIndex;
  }

  public clampCameraElementScale(projectCanvas?: ProjectCanvas) {
    const currentWidth = this.getScaledWidth();
    if (!projectCanvas) projectCanvas = getEditorCanvas();
    const scale = currentWidth / this.outputSize.width;

    const scaleClamp = clamp(scale, 1 / MAX_CAM_ZOOM, scale);

    this.scaleX = scaleClamp;
    this.scaleY = scaleClamp;
    this.height = this.outputSize.height;
    this.width = this.outputSize.width;
    const canvasBounds = projectCanvas?.getCanvasObjectCoordinates();

    if (!canvasBounds) return;

    const totalCanvasWidth = canvasBounds.right - canvasBounds.left;
    if (this.getScaledWidth() > totalCanvasWidth) {
      this.scaleY = this.scaleX = totalCanvasWidth / this.outputSize.width;
    }

    const maxLeftCoord = canvasBounds.right - this.getScaledWidth();
    const maxTopCoord = canvasBounds.bottom - this.getScaledHeight();

    if (this.top === undefined || this.left === undefined) return;

    if (this.top < canvasBounds.top) {
      this.top = canvasBounds.top;
    }

    if (this.left < canvasBounds.left) {
      this.left = canvasBounds.left;
    }

    if (this.left > maxLeftCoord) {
      this.left = maxLeftCoord;
    }

    if (this.top > maxTopCoord) {
      this.top = maxTopCoord;
    }
  }

  isSelected(): boolean {
    return !!this.group || this === this.canvas?.getActiveObject();
  }

  drawObject(ctx: CanvasRenderingContext2D): void {
    if (this.cameraPinned && !this.isSelected() && !!this.visible) {
      super.drawObject(ctx);
      const zoom = this.canvas?.getZoom() ?? 1;
      const boundingBox = this.getBoundingRect(true, true);
      const left = -(boundingBox.width / 2);
      const top = -(boundingBox.height / 2);
      const right = left + boundingBox.width;
      const bottom = top + boundingBox.height;
      const width = boundingBox.width;
      const height = boundingBox.height;

      this.drawCameraMarkings(ctx, {
        top,
        left,
        bottom,
        right,
        width,
        height,
        drawBounds: true,
        zoom,
        markingColor: `${CAMERA_COLOR}80`,
        labelBackgroundColor: '#FFFFFF80',
        labelColor: '#00000080'
      });
    }
  }
}
