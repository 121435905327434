import { put, select, takeLatest } from '@redux-saga/core/effects';
import { SET_ELEMENT_CURSOR } from 'js/actionCreators/cursorActions';
import { updateScribe } from 'js/actionCreators/scribeActions';
import cloneDeep from 'lodash.clonedeep';

import { getScribeById } from './selectors';

export function* setElementCursor({ scribeId, elementIds, cursorId }) {
  const scribe = yield select(getScribeById, scribeId);
  if (!scribe) return;

  const elementIdsToFind = Array.isArray(elementIds) ? elementIds : [elementIds];
  const updatedScribe = cloneDeep(scribe);

  const elements = updatedScribe.elements.filter(element => elementIdsToFind.includes(element.id));
  if (!elements.length) return;

  elements.forEach(el => (el.cursorId = cursorId));

  yield put(updateScribe(updatedScribe));
}

export default function* cursorSagas() {
  yield takeLatest(SET_ELEMENT_CURSOR, setElementCursor);
}
