import { call, takeEvery } from 'redux-saga/effects';
import {
  TRACK_HIT_DOWNLOAD_LIMIT,
  TRACK_HIT_PROJECT_DURATION_LIMIT,
  TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED,
  TrackHitDownloadLimitAction,
  TrackHitProjectDurationLimitAction,
  TrackHitProjectDurationLimitModalDisplayedAction
} from 'js/actionCreators/trackingActions';

import { track } from '../mixpanelProvider';

function* trackHitDownloadLimit({ scribeId }: TrackHitDownloadLimitAction) {
  yield call(track, 'Hit Download Limit', { scribeId });
}

function* trackHitProjectDurationLimit({ scribeId }: TrackHitProjectDurationLimitAction) {
  yield call(track, 'Hit Project Duration Limit', { scribeId });
}

function* trackHitProjectDurationLimitModalDisplayed({ scribeId }: TrackHitProjectDurationLimitModalDisplayedAction) {
  yield call(track, 'Hit Project Duration Limit Modal Displayed', { scribeId });
}

export default function* trackLimitsSagas() {
  yield takeEvery(TRACK_HIT_DOWNLOAD_LIMIT, trackHitDownloadLimit);
  yield takeEvery(TRACK_HIT_PROJECT_DURATION_LIMIT, trackHitProjectDurationLimit);
  yield takeEvery(TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED, trackHitProjectDurationLimitModalDisplayed);
}
