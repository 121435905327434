import loadImage from 'blueimp-load-image';
import { FILE_CONTENT_TYPES } from 'js/config/consts';
import { MAX_UPLOADED_IMAGE_DIMENSION } from 'js/config/defaults';

export default function processImageForUpload(file) {
  return new Promise((resolve, reject) => {
    if (file.type === FILE_CONTENT_TYPES.SVG || file.type === FILE_CONTENT_TYPES.GIF) {
      return resolve({
        file,
        name: file.name
      });
    }

    loadImage(
      file,
      img => {
        if (img.type === 'error') {
          return reject(`Error loading image ${file.name}`);
        }

        img.toBlob(blob => {
          blob.name = file.name;
          return resolve({
            file: blob,
            name: file.name
          });
        });
      },
      {
        maxWidth: MAX_UPLOADED_IMAGE_DIMENSION,
        maxHeight: MAX_UPLOADED_IMAGE_DIMENSION,
        orientation: true,
        canvas: true
      }
    );
  });
}
