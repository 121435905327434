import { fabric } from 'fabric';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

import brokenImage from '../../../imgs/broken-image.svg';

import { applyBoundingBoxStyles, setGroupTransform } from './helpers/canvasElementHelpers';
import getLockedProperties from './helpers/getLockedProperties';
import getVisibleProperties from './helpers/getVisibleProperties';
import sanitizeAngle from './helpers/sanitizeAngle';

export default class BrokenImageElement extends fabric.Group {
  id: string;
  locked: boolean;
  hidden: boolean;
  element: ScribeImageElementModel;
  elementType = 'Image' as const;

  constructor(objects: fabric.Object[], options: fabric.IGroupOptions, element: ScribeImageElementModel) {
    super(objects, {
      ...options,
      ...getLockedProperties(element.locked || false),
      ...getVisibleProperties(element.locked, element.hidden)
    });
    this.id = element.id;
    this.locked = element.locked;
    this.hidden = element.hidden;

    this.element = element;

    this.setBoundingBoxStyles(element);
  }

  // having bounding box styles per class as this will allow us to style them individually should we wish
  private setBoundingBoxStyles(props: ScribeImageElementModel) {
    applyBoundingBoxStyles(this, props);
  }

  public updateProps(props: ScribeImageElementModel) {
    this.locked = props.locked;
    this.hidden = props.hidden;
    this.element = props;

    const update = this.group
      ? {
          ...getLockedProperties(props.locked || false),
          ...getVisibleProperties(props.locked, props.hidden)
        }
      : {
          ...getLockedProperties(props.locked || false),
          ...getVisibleProperties(props.locked, props.hidden)
        };

    this.set(update);
    this.setBoundingBoxStyles(props);
  }

  public toVscElement(): ScribeImageElementModel {
    const originalGroupValues = setGroupTransform(this);

    const payload = new ScribeImageElementModel({
      ...this.element,
      scaleX: this.scaleX ?? 1,
      scaleY: this.scaleY ?? 1,
      y: this.top ?? 0,
      x: this.left ?? 0,
      opacity: this.opacity,
      angle: sanitizeAngle(this.angle),
      width: this.width ?? 150,
      height: this.height ?? 150,
      flipX: !!this.flipX,
      flipY: !!this.flipY,
      originX: this.originX ?? 'left',
      originY: this.originY ?? 'top',
      locked: this.locked,
      hidden: this.hidden
    });

    if (originalGroupValues) {
      this.set(originalGroupValues);
    }

    return payload;
  }

  public static async createBrokenBob(element: ScribeImageElementModel): Promise<BrokenImageElement> {
    return new Promise(resolve => {
      const reverseScaleX = 1 / Number(element.scaleX);
      const reverseScaleY = 1 / Number(element.scaleY);

      const background = new fabric.Rect({
        fill: '#E1E1E1',
        width: element.width,
        height: element.height,
        top: element.y,
        left: element.x,
        stroke: '#969696',
        strokeWidth: 3 * reverseScaleX
      });

      const popover = document.createElement('div');
      popover.classList.add('EditorCanvas__tooltip');
      popover.id = 'broken-image-tooltip';
      popover.style.position = 'fixed';
      popover.innerText = 'Broken image (failed to load)';

      const brokenImageContainer = new BrokenImageElement(
        [background],
        {
          top: element.y,
          left: element.x,
          hoverCursor: 'default'
        },
        element
      );

      const removePopover = () => {
        if (popover.parentElement) {
          popover.parentElement.removeChild(popover);
        }
      };

      brokenImageContainer.on('mouseover', () => {
        document.body.appendChild(popover);
      });

      brokenImageContainer.on('mousemove', event => {
        popover.style.top = '0px';
        popover.style.left = '0px';
        popover.style.transform = `translate(${event.e.clientX - 13}px, ${event.e.clientY - 38}px)`;
      });

      brokenImageContainer.on('mouseout', () => {
        removePopover();
      });

      brokenImageContainer.on('removed', () => {
        removePopover();
      });

      fabric.Image.fromURL(brokenImage, img => {
        const width = img.width || 300;
        const height = img.height || 500;
        img.set({
          left: -(width * reverseScaleX) / 2,
          top: -(height * reverseScaleY) / 2,
          scaleX: reverseScaleX,
          scaleY: reverseScaleY
        });

        brokenImageContainer.add(img);
        resolve(brokenImageContainer);
      });
    });
  }
}
