import React from 'react';
import { useSelector } from 'react-redux';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';
import { findCursorById } from 'js/selectors/findCursorById';
import IconNoAnimation from 'js/shared/icons/IconNoAnimation';
import { SCENE_TRANSITION_NO_CURSOR_ID, DEFAULT_ERASE_CURSOR_ID, DEFAULT_DRAG_CURSOR_ID } from 'js/config/consts';

import defaultHand from '../../../imgs/hands/default-erase-1.png';
import defaultDrag from '../../../imgs/hands/default-drag.png';
import ChoiceButtonContents from '../AnimationPanels/ChoiceButtonContents';

const NO_CURSOR = {
  noCursor: true,
  name: 'No Hand'
};

const DEFAULT_CURSOR = {
  group: 'Rosie',
  name: 'Default',
  id: DEFAULT_ERASE_CURSOR_ID,
  resources: { thumbnail: { url: defaultHand } }
};

const DEFAULT_DRAG_CURSOR = {
  group: 'Rosie',
  name: 'Default',
  id: DEFAULT_DRAG_CURSOR_ID,
  resources: { thumbnail: { url: defaultDrag } }
};

const ChoiceButtonIcon = ({ imgUrl }) => {
  return (
    <div
      style={{
        height: '100%',
        backgroundImage: `url(${imgUrl})`,
        backgroundPosition: '50% 0%',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '1 / 1'
      }}
    />
  );
};

const getCursorIcon = cursor => {
  if (!cursor) {
    return <IconIndeterminate />;
  }

  if (cursor.noCursor === true) {
    return <IconNoAnimation />;
  }
  return <ChoiceButtonIcon imgUrl={cursor.resources.thumbnail.url} />;
};

const CursorChoiceButton = ({ config, animationType }) => {
  let cursorId;
  if (config?.cursorId) {
    cursorId = config.cursorId;
  }

  let cursor = useSelector(({ cursors }) => findCursorById(cursors, cursorId));
  if (!cursor && cursorId === SCENE_TRANSITION_NO_CURSOR_ID) {
    cursor = NO_CURSOR;
  } else if (!cursor && cursorId === 'Mixed') {
    cursor = null;
  } else if (!cursor) {
    cursor = animationType === 'erase' ? DEFAULT_CURSOR : DEFAULT_DRAG_CURSOR;
  }

  const optIcon = getCursorIcon(cursor);
  const optTitle = cursor ? cursor.name : 'Mixed';

  return (
    <ChoiceButtonContents label={optTitle} id={`cursor-choice-contents`}>
      {optIcon}
    </ChoiceButtonContents>
  );
};

export default CursorChoiceButton;
