import * as PIXI from 'pixi.js';
import { ExistingScribeModel, ScribeScene } from 'js/types';
import {
  getElementEmphasisTimeMs,
  getElementEntranceAnimationTimeMs,
  getElementPauseTimeMs,
  getSceneStartTimeAndLength
} from 'js/playback/lib/Playback/helpers/timings';
import getScribeLengthMs from 'js/playback/lib/Playback/helpers/getScribeLengthMs';
import { SCENE_TRANSITION_KEY_NONE } from 'js/config/consts';
import SceneTransitionElementModel from 'js/models/SceneTransitionElementModel';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';

import { getElementExitTimeForDefinedAnimationMs, sceneHasTransition } from './Utils/TimelineHelpers';
import { TIMELINE_LAYER_BACKGROUND_COLOUR } from './TimelineWrapper';
import { getBitmapRect } from './Utils/GetBitmapRect';

export const TIMELINE_GHOST_ALPHA = 0.4;
export default class SceneGhost extends PIXI.Container {
  activeScribe: ExistingScribeModel;
  scene: ScribeScene;
  maxLayers: number;
  totalNumberOfVisibleLayers: number;
  currentZoom: PIXI.Point;
  isCurrentScene: boolean;
  isLastScene: boolean;
  sceneLength: number;
  scrollboxWidth: number;
  backgroundLayerHolder: PIXI.Container<PIXI.DisplayObject>;
  foregroundGraphicsContainer: PIXI.Container;
  backgroundGraphicsContainer: PIXI.Container;

  constructor(
    activeScribe: ExistingScribeModel,
    scene: ScribeScene,
    maxLayers: number,
    totalNumberOfVisibleLayers: number,
    currentZoom: PIXI.Point,
    isCurrentScene: boolean,
    isLastScene: boolean,
    sceneLength: number,
    scrollboxWidth: number,
    backgroundLayerHolder: PIXI.Container
  ) {
    super();
    this.activeScribe = activeScribe;
    this.scene = scene;
    this.maxLayers = maxLayers;
    this.totalNumberOfVisibleLayers = totalNumberOfVisibleLayers;
    this.currentZoom = currentZoom;
    this.name = scene ? scene.id : 'endbuffer';
    this.isCurrentScene = isCurrentScene;
    this.isLastScene = isLastScene;
    this.sceneLength = sceneLength;
    this.scrollboxWidth = scrollboxWidth;
    this.backgroundLayerHolder = backgroundLayerHolder;
    this.foregroundGraphicsContainer = new PIXI.Container();
    this.backgroundGraphicsContainer = new PIXI.Container();
    this.addChild(this.foregroundGraphicsContainer);
    this.backgroundLayerHolder.addChild(this.backgroundGraphicsContainer);
    this.draw();
  }

  draw = () => {
    this.foregroundGraphicsContainer.children.forEach(child => {
      child.destroy();
    });

    this.backgroundGraphicsContainer.children.forEach(child => {
      child.destroy();
    });

    const startOffset = getSceneStartTimeAndLength(this.activeScribe, this.scene);
    const scribeLength = getScribeLengthMs(this.activeScribe) / 1000;

    const lineHeight = this.currentZoom.y - 2;
    const numLayers = Math.max(this.maxLayers, this.totalNumberOfVisibleLayers + 1);
    let currentElementTime = 0;
    const activeScene = getActiveScene(this.activeScribe);

    if (scribeLength * this.currentZoom.x < this.scrollboxWidth && this.isLastScene) {
      //small scribe background
      const startOfBackground = (startOffset.sceneLength / 1000) * this.currentZoom.x;
      const backgroundLength =
        this.scrollboxWidth - ((startOffset.sceneStartTime + startOffset.sceneLength) / 1000) * this.currentZoom.x;

      const startPosion = (this.maxLayers - 1) * this.currentZoom.y;
      for (let i = 0; i < numLayers; i++) {
        const box = getBitmapRect(lineHeight, TIMELINE_LAYER_BACKGROUND_COLOUR);
        box.x = startOfBackground;
        box.y = startPosion - i * this.currentZoom.y + 1;
        box.width = backgroundLength;
        box.alpha = this.isCurrentScene ? 1 : TIMELINE_GHOST_ALPHA;
        if (i < this.maxLayers) {
          this.foregroundGraphicsContainer.addChild(box);
        } else {
          this.backgroundGraphicsContainer.addChild(box);
        }
      }
    } else if (startOffset.sceneLength === 0 && this.scene.id === activeScene?.id) {
      //check if the scene is the last scene in the scribe and if it is empty - add a buffer
      startOffset.sceneLength = 1000;
    }
    const elements = this.activeScribe.elements.concat();
    const elementIds = this.scene.elementIds.concat();
    if (sceneHasTransition(this.scene)) {
      elements.unshift({
        type: 'SceneTransition',
        id: 'sceneTransition',
        sceneTransitionTime: this.scene.settings?.sceneTransitionTime || 0,
        sceneTransitionType: this.scene.settings?.sceneTransitionType || SCENE_TRANSITION_KEY_NONE
      } as SceneTransitionElementModel);
      elementIds.unshift('sceneTransition');
    }
    for (let i = 0; i < numLayers; i++) {
      const yPos = (this.maxLayers - 1) * this.currentZoom.y - i * this.currentZoom.y + 1;
      const element = elements.find(el => this.scene && el.id === elementIds[i]);
      const graphicsToUse = i < this.maxLayers ? this.foregroundGraphicsContainer : this.backgroundGraphicsContainer;

      const box = getBitmapRect(lineHeight, TIMELINE_LAYER_BACKGROUND_COLOUR);
      box.alpha = this.isCurrentScene ? 1 : TIMELINE_GHOST_ALPHA;
      box.x = 0;
      box.y = yPos;
      box.width = (startOffset.sceneLength / 1000) * this.currentZoom.x;
      graphicsToUse.addChild(box);
      if (element && !this.isCurrentScene) {
        const animationTime = Math.round(getElementEntranceAnimationTimeMs(element, this.activeScribe)) / 1000;
        const emphasisAnimationTime = Math.round(getElementEmphasisTimeMs(element)) / 1000;
        let exitAnimationTime =
          'exitAnimationTime' in element ? getElementExitTimeForDefinedAnimationMs(element, this.activeScribe) : 0;
        if ('exitAnimationTime' in element && exitAnimationTime !== undefined)
          exitAnimationTime = Math.round(exitAnimationTime) / 1000;
        const pauseTime = Math.round(getElementPauseTimeMs(element, this.activeScribe)) / 1000;
        let duration =
          element.type === 'SceneTransition'
            ? element.sceneTransitionTime
            : animationTime + emphasisAnimationTime + pauseTime;
        if (exitAnimationTime !== undefined) duration += exitAnimationTime;
        const box2 = getBitmapRect(lineHeight, TIMELINE_LAYER_BACKGROUND_COLOUR);
        box2.alpha = this.isCurrentScene ? 1 : TIMELINE_GHOST_ALPHA;
        box2.x = currentElementTime * this.currentZoom.x;
        box2.y = yPos;
        box2.width = duration * this.currentZoom.x;
        graphicsToUse.addChild(box2);
        if (duration === 0) {
          //draw thin line for elements with no duration
          const noDuration = getBitmapRect(lineHeight, TIMELINE_LAYER_BACKGROUND_COLOUR);
          noDuration.alpha = this.isCurrentScene ? 1 : TIMELINE_GHOST_ALPHA;
          noDuration.x = currentElementTime * this.currentZoom.x;
          noDuration.y = yPos;
          noDuration.width = 1;
          graphicsToUse.addChild(noDuration);
        }
        currentElementTime += duration;
      }
      this.updatePosition();
    }
  };

  updateZoom = (zoom: PIXI.Point, numLayersInView?: number) => {
    if (numLayersInView !== undefined) {
      this.totalNumberOfVisibleLayers = numLayersInView;
    }
    if (!(this.currentZoom.x === zoom.x && this.currentZoom.y === zoom.y)) {
      this.currentZoom = zoom;
      this.draw();
    } else {
      this.updatePosition();
    }
  };

  updatePosition() {
    const startOffset = getSceneStartTimeAndLength(this.activeScribe, this.scene);
    const pos = new PIXI.Point((startOffset.sceneStartTime / 1000) * this.currentZoom.x, 0);
    this.x = pos.x;
    this.y = pos.y;
    const bgPosition = this.backgroundLayerHolder.toLocal(
      this.foregroundGraphicsContainer.toGlobal(new PIXI.Point(0, 0))
    );
    this.backgroundGraphicsContainer.x = bgPosition.x;
    this.backgroundGraphicsContainer.y = bgPosition.y;
  }

  destroy = () => {
    if (this.backgroundLayerHolder && this.backgroundGraphicsContainer)
      if (this.foregroundGraphicsContainer) this.removeChild(this.foregroundGraphicsContainer);
    this.backgroundLayerHolder.removeChild(this.backgroundGraphicsContainer);

    if (this.foregroundGraphicsContainer && this.foregroundGraphicsContainer.destroyed === false)
      this.foregroundGraphicsContainer.destroy({ children: true });
    if (this.backgroundGraphicsContainer && this.backgroundGraphicsContainer.destroyed === false)
      this.backgroundGraphicsContainer.destroy({ children: true });
    super.destroy();
  };
}
