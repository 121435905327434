import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { ROUTE_CODES } from 'js/config/routes';
import { matchPath } from 'react-router-dom';
import { TemplateState } from 'js/types';

import {
  CREATE_SCRIBE_FROM_TEMPLATE,
  CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS,
  CREATE_SCRIBE_FROM_TEMPLATE_FAILED,
  GET_SCRIBE_TEMPLATES,
  GET_SCRIBE_TEMPLATES_FAILED,
  GET_SCRIBE_TEMPLATES_BY_CATEGORY_SUCCESS,
  SAVE_SCRIBE_AS_TEMPLATE,
  SAVE_SCRIBE_AS_TEMPLATE_SUCCESS,
  SAVE_SCRIBE_AS_TEMPLATE_FAILED,
  CONTENTFUL_OAUTH_SUCCESS,
  CONTENTFUL_OAUTH_INVALID,
  CONTENTFUL_OAUTH_LOADED,
  GetScribeTemplatesByCategorySuccessAction,
  ContentfulOauthSuccessAction,
  ContentfulOauthLoadedAction,
  SORT_TEMPLATES,
  SortTemplatesAction,
  FilterByCanvasSizeAction,
  FILTER_TEMPLATES_BY_CANVAS_SIZE
} from '../actionCreators/templatesActions';

export const STATUS_SAVING = 'STATUS_SAVING';
export const STATUS_CLONING = 'STATUS_CLONING';
export const STATUS_INACTIVE = 'STATUS_INACTIVE';

interface LocationChangeAction {
  type: typeof LOCATION_CHANGE;
  payload: RouterState;
}

const initialState: TemplateState = {
  saveStatus: STATUS_INACTIVE,
  error: null,
  contentfulManagementToken: null,
  templatesByCategory: [],
  isFetchingTemplates: true,
  templatesSortOrder: 'default',
  canvasSizeFilter: 'allSizes'
};

type TemplateAction =
  | {
      type:
        | typeof CREATE_SCRIBE_FROM_TEMPLATE
        | typeof SAVE_SCRIBE_AS_TEMPLATE
        | typeof SAVE_SCRIBE_AS_TEMPLATE_SUCCESS
        | typeof SAVE_SCRIBE_AS_TEMPLATE_FAILED
        | typeof CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS
        | typeof CREATE_SCRIBE_FROM_TEMPLATE_FAILED
        | typeof CONTENTFUL_OAUTH_INVALID
        | typeof GET_SCRIBE_TEMPLATES
        | typeof GET_SCRIBE_TEMPLATES_FAILED;
    }
  | GetScribeTemplatesByCategorySuccessAction
  | ContentfulOauthSuccessAction
  | ContentfulOauthLoadedAction
  | SortTemplatesAction
  | FilterByCanvasSizeAction
  | LocationChangeAction;

export default function templatesReducer(state = initialState, action: TemplateAction): TemplateState {
  switch (action.type) {
    case GET_SCRIBE_TEMPLATES_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        templatesByCategory: action.categories,
        isFetchingTemplates: false
      };

    case CREATE_SCRIBE_FROM_TEMPLATE:
      return {
        ...state,
        saveStatus: STATUS_CLONING
      };

    case SAVE_SCRIBE_AS_TEMPLATE:
      return {
        ...state,
        saveStatus: STATUS_SAVING
      };

    case SAVE_SCRIBE_AS_TEMPLATE_SUCCESS:
    case SAVE_SCRIBE_AS_TEMPLATE_FAILED:
    case CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS:
    case CREATE_SCRIBE_FROM_TEMPLATE_FAILED:
      return {
        ...state,
        saveStatus: STATUS_INACTIVE
      };

    case CONTENTFUL_OAUTH_SUCCESS:
    case CONTENTFUL_OAUTH_LOADED:
      return {
        ...state,
        contentfulManagementToken: action.payload
      };

    case CONTENTFUL_OAUTH_INVALID:
      return {
        ...state,
        contentfulManagementToken: null
      };

    case GET_SCRIBE_TEMPLATES: {
      return {
        ...state,
        isFetchingTemplates: true
      };
    }

    case GET_SCRIBE_TEMPLATES_FAILED: {
      return {
        ...state,
        isFetchingTemplates: false
      };
    }

    case SORT_TEMPLATES:
      return {
        ...state,
        templatesSortOrder: action.sortOrder
      };

    case FILTER_TEMPLATES_BY_CANVAS_SIZE: {
      return {
        ...state,
        canvasSizeFilter: action.size
      };
    }

    case LOCATION_CHANGE:
      const { pathname } = action.payload.location;

      const isTemplateCategoryRoute =
        matchPath(pathname, { path: ROUTE_CODES.TEMPLATES_CATEGORY, exact: true }) !== null;
      const shouldResetFilterState = pathname.startsWith('/template-preview') || isTemplateCategoryRoute;

      return {
        ...state,
        canvasSizeFilter: shouldResetFilterState ? initialState.canvasSizeFilter : state.canvasSizeFilter,
        templatesSortOrder: shouldResetFilterState ? initialState.templatesSortOrder : state.templatesSortOrder
      };

    default:
      return state;
  }
}
