import React from 'react';
import { ReactComponent as IconElementDistributeV } from 'imgs/svg-icons/IconElementDistributeV.svg';
import { ReactComponent as IconElementDistributeH } from 'imgs/svg-icons/IconElementDistributeH.svg';
import { useDispatch } from 'react-redux';
import { distributeSelectedElements } from 'js/actionCreators/elementAlignmentActions';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { getActiveElements } from 'js/sagas/selectors';

import EditPanelFieldset from '../EditPanelFieldset';
import EditPanelLegend from '../EditPanelLegend';
import EditPanelTripleButtonGroup from '../EditPanelTripleButtonGroup';
import ActionButtonWithA11yLabel from '../ActionButton/ActionButtonWithA11yLabel';

import { DISTRIBUTE_ACTION_HORIZONTAL_LABEL, DISTRIBUTE_ACTION_VERTICAL_LABEL } from './DistributionControlsLabels';

export const DISTRIBUTE_ACTION_HORIZONTAL = 'DISTRIBUTE_ACTION_HORIZONTAL';
export const DISTRIBUTE_ACTION_VERTICAL = 'DISTRIBUTE_ACTION_VERTICAL';

export type DistributionAction = typeof DISTRIBUTE_ACTION_HORIZONTAL | typeof DISTRIBUTE_ACTION_VERTICAL;

const DistributionControls = ({ projectId }: { projectId: number }) => {
  const selectedElementIds = useTypedSelector(getActiveElements);
  const dispatch = useDispatch();

  const handleAction = (event: React.MouseEvent, action: DistributionAction) => {
    event.preventDefault();
    dispatch(distributeSelectedElements(action, projectId));
  };

  return selectedElementIds.length > 2 ? (
    <EditPanelFieldset>
      <EditPanelLegend>Distribution controls:</EditPanelLegend>
      <div className="AlignmentControls">
        <EditPanelTripleButtonGroup>
          <ActionButtonWithA11yLabel
            onClick={event => handleAction(event, DISTRIBUTE_ACTION_HORIZONTAL)}
            labelText={DISTRIBUTE_ACTION_HORIZONTAL_LABEL}
            id="elements-distribute-h"
          >
            <IconElementDistributeH />
          </ActionButtonWithA11yLabel>

          <ActionButtonWithA11yLabel
            onClick={event => handleAction(event, DISTRIBUTE_ACTION_VERTICAL)}
            labelText={DISTRIBUTE_ACTION_VERTICAL_LABEL}
            id="elements-distribute-v"
          >
            <IconElementDistributeV />
          </ActionButtonWithA11yLabel>
        </EditPanelTripleButtonGroup>
      </div>
    </EditPanelFieldset>
  ) : null;
};

export default DistributionControls;
