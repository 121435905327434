export const BETA_IMAGE_GENERATION = 'beta_image_generation';
export const BETA_VOICE_GENERATION = 'beta_voice_generation';
export const BETA_SCRIPT_GENERATION = 'beta_script_generation';
export const IMAGE_LIBRARY_REWORK = 'image_library_rework';
/**
 * VSP2-4666 - merging the premium content rework feature flag into the image library rework
 * feature flag so they can be controlled by one audience in Optimizely.
 *
 * TODO: VSP2-4667 - When we remove the feature flag ensure to remove anywhere PREMIUM_CONTENT_REWORK is used too
 */
export const PREMIUM_CONTENT_REWORK = 'image_library_rework';
