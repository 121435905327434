import { put, takeEvery, takeLatest, select, call } from 'redux-saga/effects';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { APP_DATA_KEYS } from 'js/config/consts';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import {
  loadUserSettingsFailed,
  loadUserSettingsSuccess,
  SET_USER_SETTING
} from 'js/actionCreators/userSettingsActions';
import { showError } from 'js/actionCreators/uiActions';

export const LOADING_ERROR_MESSAGE = 'Error loading user settings.';
export const SAVING_ERROR_MESSAGE = 'Error saving user settings.';

export const getUserSettingsFromState = state => state.userSettings.settings;

export function* loadUserSettings() {
  try {
    const settings = yield call({ fn: appServices.getAppData, context: appServices }, APP_DATA_KEYS.USER_SETTINGS);

    // VSP2-4038 feature was released with a bug that stored `null` in the values
    // this needs to remain to ensure corrupted user settings are not loaded
    const sanitizedSettings = {
      ...settings,
      customColours: settings.customColours?.filter(Boolean) ?? []
    };

    yield put(loadUserSettingsSuccess(sanitizedSettings));
  } catch (error) {
    yield put(loadUserSettingsFailed(error));
    if (error.httpStatusCode !== 404) {
      // We expect a 404 here if the user hasn't saved any settings yet, but any other error we should highlight
      yield put(showError({ message: LOADING_ERROR_MESSAGE, description: error.message }));
    }
  }
}

export function* setUserSetting() {
  try {
    const stateSettings = yield select(getUserSettingsFromState);

    // VSP2-4038 feature was released with a bug that stored `null` in the values
    // this needs to remain to ensure corrupted user settings are not saved
    const sanitizedSettings = {
      ...stateSettings,
      customColours: stateSettings.customColours?.filter(Boolean) ?? []
    };

    const responseSettings = yield call(
      { fn: appServices.setAppData, context: appServices },
      APP_DATA_KEYS.USER_SETTINGS,
      sanitizedSettings
    );
    yield put(loadUserSettingsSuccess(responseSettings));
  } catch (error) {
    yield put(showError({ message: SAVING_ERROR_MESSAGE, description: error.message }));
  }
}

export default function* userSettingSagas() {
  yield takeEvery(AUTH_SUCCESS, loadUserSettings);
  yield takeLatest(SET_USER_SETTING, setUserSetting);
}
