export function getImagePixelsFromUrl({ url }: { url: string }): Promise<Uint8ClampedArray> {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas');
    const image = new Image();

    image.addEventListener(
      'load',
      () => {
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return resolve(new Uint8ClampedArray());

        ctx.drawImage(image, 0, 0, image.width, image.height);
        const imageData = ctx.getImageData(0, 0, image.width, image.height);
        resolve(imageData.data);
      },
      { once: true }
    );

    image.addEventListener(
      'error',
      () => {
        resolve(new Uint8ClampedArray());
      },
      { once: true }
    );

    image.src = url;
  });
}
