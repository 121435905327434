import { prependUserImagesList, UploadImageAssetSuccessAction } from 'js/actionCreators/imagesActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { ExistingScribeModel, VSCAssetImageSourceName } from 'js/types';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import getOptimizelyInstance from 'js/shared/helpers/optimizely';
import { IMAGE_LIBRARY_REWORK } from 'js/config/featureKeys';
import { ImageGenerationSuccessAction } from 'js/actionCreators/betaAiFeaturesActions';
import { FILE_CONTENT_TYPES } from 'js/config/consts';

import { getProjectById } from './selectors';

const mapImageSourceToTargetUserLibrary = (source?: string): VSCAssetImageSourceName => {
  switch (source) {
    case 'library':
      return VSCAssetImageSourceName.SPARKOL_IMAGE;
    case 'noun-project':
      return VSCAssetImageSourceName.NOUN_PROJECT;
    case 'ai-library':
      return VSCAssetImageSourceName.AI;
    default:
      return VSCAssetImageSourceName.USER_UPLOAD;
  }
};

export function* updateUserImageList({
  assetId,
  originalAssetId,
  scribeId: projectId,
  source
}: UploadImageAssetSuccessAction): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  ExistingScribeModel | undefined | string
> {
  const optimizely = getOptimizelyInstance();
  if (!optimizely.isFeatureEnabled(IMAGE_LIBRARY_REWORK)) return;

  const project = yield select(getProjectById, projectId);
  if (!project || typeof project === 'string') return;

  const imageAsset = project.elements
    .filter((element): element is ScribeImageElementModel => element.type === 'Image')
    .find(element => element.image?.assetId === originalAssetId || element.image?.assetId === assetId);
  if (!imageAsset) return;

  const url = yield call(appServices.getAssetUrl, assetId);
  if (typeof url === 'string' && imageAsset.image && typeof assetId === 'number') {
    yield put(
      prependUserImagesList(
        [
          {
            projectAssetId: assetId,
            createdDate: new Date().toISOString(),
            contentType: imageAsset.image.contentType,
            filename: imageAsset.image.filename || null,
            id: assetId,
            url,
            isPremium: imageAsset.isPremium
          }
        ],
        mapImageSourceToTargetUserLibrary(source)
      )
    );
  }
}

export function* addAiGeneratedImagesToList(action: ImageGenerationSuccessAction) {
  const images = action.images.map(image => ({
    projectAssetId: image.assetId,
    createdDate: new Date().toISOString(),
    contentType: FILE_CONTENT_TYPES.SVG,
    filename: null,
    id: image.assetId,
    url: image.url
  }));

  yield put(prependUserImagesList(images, VSCAssetImageSourceName.AI));
}
