/**
 * Uses contentful Content Management api
 * @see https://www.contentful.com/developers/docs/references/content-management-api/
 */

import { createClient } from 'contentful-management';
import { FILE_CONTENT_TYPES } from 'js/config/consts';

import config, { CONTENTFUL_LOCALE } from '../../config/config';
import { appServices } from '../helpers/app-services/AppServices';

const spaceId = config.CONTENTFUL_SPACE_ID;
const envId = config.CONTENTFUL_ENVIRONMENT;

let CONTENTFUL_ENVIRONMENT = null;
export const getContentfulEnvironment = async accessToken => {
  if (CONTENTFUL_ENVIRONMENT) return;
  const client = createClient({ accessToken });
  const space = await client.getSpace(spaceId);
  CONTENTFUL_ENVIRONMENT = await space.getEnvironment(envId);
};

const addEntry = async (sparkolTemplateId, title, canvasSize, thumbnailBuffer) => {
  const environment = CONTENTFUL_ENVIRONMENT;
  if (!environment) {
    throw new Error('Contentful authorization not set up. Try Authenticate with Contentful link on homepage.');
  }
  try {
    const thumbnailAsset = await environment.createAssetFromFiles({
      fields: {
        title: {
          [CONTENTFUL_LOCALE]: `${title} template thumbnail`
        },
        file: {
          [CONTENTFUL_LOCALE]: {
            contentType: FILE_CONTENT_TYPES.PNG,
            fileName: 'thumbnail.png',
            file: thumbnailBuffer
          }
        }
      }
    });
    const processedAsset = await thumbnailAsset.processForAllLocales();
    await processedAsset.publish();

    const entry = await environment.createEntry('scribeTemplate', {
      fields: {
        title: {
          [CONTENTFUL_LOCALE]: title
        },
        canvasSize: {
          [CONTENTFUL_LOCALE]: canvasSize
        },
        sparkolTemplateId: {
          [CONTENTFUL_LOCALE]: `${sparkolTemplateId}`
        },
        templateThumbnail: {
          [CONTENTFUL_LOCALE]: {
            sys: {
              type: 'Link',
              linkType: 'Asset',
              id: processedAsset.sys.id
            }
          }
        }
      }
    });
    return entry;
  } catch (error) {
    throw error;
  }
};

export const saveTemplate = async (scribeId, scribeData, thumbnailBuffer) => {
  const { title, canvasSize } = scribeData;
  let templateData;
  try {
    templateData = await appServices.createTemplateFromProject(scribeId);
  } catch (error) {
    console.error('Failed to save project as a template in App Services');
  }

  return addEntry(templateData.projectDataId, title, canvasSize, thumbnailBuffer);
};
