import React from 'react';
import { SCENE_DIRECTION_CHOICES, ALL_SCENE_DIRECTION_CHOICES } from 'js/config/sceneTransitionsOptions';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';

import ChoiceButtonContents from '../AnimationPanels/ChoiceButtonContents';

interface Props {
  useAllDirections?: boolean;
  config: {
    direction?: string;
  };
}

const SceneDirectionChoiceButton = ({ config, useAllDirections }: Props) => {
  const directionButtons = useAllDirections ? ALL_SCENE_DIRECTION_CHOICES : SCENE_DIRECTION_CHOICES;

  if (config.direction !== 'Mixed') {
    const { title: optTitle, Icon } = directionButtons.find(choice => choice.key === config.direction) || {};
    return (
      <ChoiceButtonContents label={optTitle} id={'scene-direction-button-choice-contents'}>
        {Icon && <Icon />}
      </ChoiceButtonContents>
    );
  } else {
    return (
      <ChoiceButtonContents label={'Mixed'} id={'scene-direction-button-choice-contents'}>
        <IconIndeterminate />
      </ChoiceButtonContents>
    );
  }
};

export default SceneDirectionChoiceButton;
