import React from 'react';
import { useDispatch } from 'react-redux';
import config, { RAISE_A_TICKET_URL } from 'js/config/config';
import { logout } from 'js/actionCreators/authActions';

import './NoOnlineStorage.css';

export const NoOnlineStorage = () => {
  const dispatch = useDispatch();
  return (
    <div className="Background">
      <div className="NoOnlineStorage">
        <h1 className="NoOnlineStorage_title">Your organization has restricted VideoScribe for your browser</h1>
        <p className="t-body-copy-large">
          Please contact your system administrator or{' '}
          <a className="NoOnlineStorage_link" href={RAISE_A_TICKET_URL} target="_blank" rel="noreferrer">
            VideoScribe Customer Success
          </a>{' '}
          to enable access.
        </p>
        <h3 className="NoOnlineStorage_title">VideoScribe for your browser benefits</h3>
        <p className="t-body-copy-large">There are many benefits to using VideoScribe for your browser.</p>
        <div className="NoOnlineStorage_button-container">
          <a
            className="auth-btn auth-btn--primary NoOnlineStorage_learn-button"
            href={config.PRICING_URL}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>

          <div className="NoOnlineStorage_button-container NoOnlineStorage_logout-button">
            <button
              type="submit"
              className="auth-btn auth-btn--primary"
              onClick={() => {
                dispatch(logout());
              }}
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
