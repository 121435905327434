import ScribeElementModel from 'js/models/ScribeElementModel';
import { ExistingScribeModel } from 'js/types';

export const selectionContainsStartingCamera = (activeScribe: ExistingScribeModel, activeElements: Array<string>) => {
  let firstCameraInSelection = false;

  const activeScene = activeScribe.scenes.filter(scene => scene.active === true);

  if (activeElements.includes(activeScene[0].elementIds[0])) {
    firstCameraInSelection = true;
  }

  return firstCameraInSelection;
};

export const isAllCameraElements = (activeElements: Array<ScribeElementModel>) => {
  return activeElements.every(element => element.type === 'Camera');
};

export const containsSomeCameraElements = (activeElements: Array<ScribeElementModel>) => {
  return activeElements.some(element => element.type === 'Camera');
};
