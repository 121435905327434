import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { APP_TOOLTIP_ID } from 'js/config/consts';

import './ActionButton.css';

class ActionButton extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    linkTo: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    datatip: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    const {
      id,
      linkTo,
      disabled,
      onClick,
      datatip,
      ariaHasPopup = false,
      ariaExpanded = false,
      className
    } = this.props;

    const classes = disabled
      ? `ActionButton icon-button disabled ${className || ''}`
      : `ActionButton icon-button ${className || ''}`;

    return linkTo ? (
      <Link
        to={linkTo || '#'}
        data-for={APP_TOOLTIP_ID}
        data-tip={datatip}
        aria-label={datatip}
        id={id}
        className={classes}
        onClick={onClick}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        role="button"
        aria-haspopup={ariaHasPopup}
        aria-expanded={ariaExpanded}
      >
        {this.props.children}
      </Link>
    ) : (
      <button
        data-for={APP_TOOLTIP_ID}
        data-tip={datatip}
        aria-label={datatip}
        id={id}
        className={classes}
        onClick={onClick}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        aria-haspopup={ariaHasPopup}
        aria-expanded={ariaExpanded}
      >
        {this.props.children}
      </button>
    );
  }
}

export default ActionButton;
