import React from 'react';
import { setElementCursor } from 'js/actionCreators/cursorActions';
import { ElementMenuProps, ScribeCursorId } from 'js/types';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useTypedSelector } from 'js/selectors/typedUseSelector';

import OptionMenuButton from './OptionMenuButton';

import './PenMenu.css';

const TOOLTIP_ID = 'pen-tooltip';
const PenMenu = ({ element, elements, scribe, onSelection, stageKey }: ElementMenuProps) => {
  const pens = useTypedSelector(state => state.cursors.penDraw);
  const dispatch = useDispatch();
  const elementIds = element ? [element.id] : elements.map(el => el.id);

  let selectedCursorId: ScribeCursorId;
  if (element) {
    selectedCursorId = element.cursorId ?? scribe.cursor;
  } else {
    const firstElCursorId = elements[0].cursorId ?? scribe.cursor;
    const allSame = elements.every(el => (el.cursorId ?? scribe.cursor) === firstElCursorId);
    if (allSame) {
      selectedCursorId = firstElCursorId;
    }
  }

  const handleButtonClick = (cursorId: ScribeCursorId) => {
    dispatch(setElementCursor(scribe.id, elementIds, cursorId, stageKey));
    onSelection();
  };

  return (
    <>
      <div className="PenMenu">
        {pens.map(pen => {
          return (
            <OptionMenuButton
              key={pen.id}
              selected={pen.id === selectedCursorId}
              onClick={() => handleButtonClick(pen.id)}
              tooltipProps={{
                'data-tip': pen.name,
                'data-for': TOOLTIP_ID
              }}
            >
              <div className="PenMenu__buttonIcon" style={{ backgroundImage: `url(${pen.resources.thumbnail.url})` }} />
            </OptionMenuButton>
          );
        })}
      </div>

      <ReactTooltip id={TOOLTIP_ID} className="t-utility-label" />
    </>
  );
};

export default PenMenu;
