import React from 'react';
import classNames from 'classnames';

import './OptionMenuButton.css';

const OptionMenuButton = ({ children, onClick, selected, tooltipProps = {} }) => {
  const optionMenuCssClassnames = classNames('OptionMenuButton', {
    'OptionMenuButton--selected': selected
  });

  return (
    <button className={optionMenuCssClassnames} onClick={onClick} {...tooltipProps}>
      {children}
    </button>
  );
};

export default OptionMenuButton;
