import { HomepageProject, TemporaryDuplicatedScribe } from 'js/types';

export const DUPLICATE_SCRIBE = 'DUPLICATE_SCRIBE';
export const DUPLICATE_SCRIBE_START = 'DUPLICATE_SCRIBE_START';
export const DUPLICATE_SCRIBE_SUCCESS = 'DUPLICATE_SCRIBE_SUCCESS';
export const DUPLICATE_SCRIBE_FAILED = 'DUPLICATE_SCRIBE_FAILED';

export interface DuplicateScribeAction {
  type: typeof DUPLICATE_SCRIBE;
  scribeId: number;
  title: string;
  createdDate: Date;
}

export const duplicateScribe = (scribeId: number, title: string): DuplicateScribeAction => ({
  type: DUPLICATE_SCRIBE,
  scribeId,
  title,
  createdDate: new Date()
});

export interface DuplicateScribeStartAction {
  type: typeof DUPLICATE_SCRIBE_START;
  loadingScribe: TemporaryDuplicatedScribe;
}

export const duplicateScribeStart = (loadingScribe: TemporaryDuplicatedScribe): DuplicateScribeStartAction => ({
  type: DUPLICATE_SCRIBE_START,
  loadingScribe
});

export interface DuplicateScribeSuccessAction {
  type: typeof DUPLICATE_SCRIBE_SUCCESS;
  duplicatedProject: HomepageProject;
  originalProjectId: number;
  loadingId: string;
}

export const duplicateScribeSuccess = (
  duplicatedProject: HomepageProject,
  originalProjectId: number,
  loadingId: string
): DuplicateScribeSuccessAction => ({
  type: DUPLICATE_SCRIBE_SUCCESS,
  duplicatedProject,
  originalProjectId,
  loadingId
});

export interface DuplicateScribeFailedAction {
  type: typeof DUPLICATE_SCRIBE_FAILED;
  loadingId: string;
}

export const duplicateScribeFailed = (loadingId: string): DuplicateScribeFailedAction => ({
  type: DUPLICATE_SCRIBE_FAILED,
  loadingId
});
