import Auth from '@aws-amplify/auth';
import config from 'js/config/config';
import { sendErrorToSentry } from 'js/logging';

const { AWS_REGION, COGNITO_USER_POOL, COGNITO_CLIENT_ID } = config;

Auth.configure({
  region: AWS_REGION,
  userPoolId: COGNITO_USER_POOL,
  userPoolWebClientId: COGNITO_CLIENT_ID,
  authenticationFlowType: 'CUSTOM_AUTH',
  oauth: {
    domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN ?? 'auth.videoscribe.co',
    responseType: 'code',
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin']
  }
});

/**
 * Only for development
 */
export async function cognitoSignIn(username, password) {
  try {
    const response = await Auth.signIn({ username, password });
    const user = await Auth.sendCustomChallengeAnswer(response, 'some-token');
    return user;
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    return;
  }
}

export async function validateCognitoSession() {
  try {
    return await Auth.currentSession();
  } catch (e) {
    console.error(e);
    return;
  }
}

export async function cognitoSignOut() {
  try {
    return await Auth.signOut({ global: true });
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    return;
  }
}

export async function currentUserAccessToken() {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    return;
  }
}

// throws 'Failed to refresh Cognito user'
export async function refreshUser() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    return new Promise((resolve, reject) => {
      currentUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) {
          return reject(err);
        }
        return resolve(session);
      });
    });
  } catch (e) {
    return Promise.reject('Failed to refresh Cognito user');
  }
}

export async function isUserInGroup(groupName) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];

    return groups.includes(groupName);
  } catch (error) {
    console.error(error);
    return false;
  }
}
