import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

export const shouldIllustrateAnimation = (element: ScribeImageElementModel) => {
  return (
    typeof element.entranceAnimation?.config?.drawType === 'undefined' ||
    element.entranceAnimation.config.drawType === 'illustrate'
  );
};

export const shouldRevealAnimation = (element: ScribeImageElementModel) => {
  return element.entranceAnimation?.config?.drawType === 'reveal';
};
