import { delay, put, select, takeLatest, call } from 'redux-saga/effects';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { getTimeRemaining } from 'js/shared/helpers/getTimeRemaining';
import { AUTH_SUCCESS, CHECK_TRIAL_STATUS, setTrialHasExpired } from 'js/actionCreators/authActions';
import { ACCOUNT_TYPES } from 'js/config/consts';

export const DEBOUNCE_DELAY = 1000;
export const IDLE_TIME_ALLOWED = 5 * 60 * 1000;

export function* checkTrialStatus({ nowDate = new Date() } = {}) {
  yield delay(DEBOUNCE_DELAY); // debounce the saga

  const { gracePeriodHasEnded, accountType, trialExpiryDate } = yield select(state => state.auth.currentUser);

  if (accountType !== ACCOUNT_TYPES.FREE || gracePeriodHasEnded) return;

  const { expired } = yield call(getTimeRemaining, trialExpiryDate, nowDate);

  if (expired) {
    // start the idle period delay before flipping UI into upgrade only mode
    yield delay(IDLE_TIME_ALLOWED);
    yield put(setTrialHasExpired());
  }
}

export function* triggerCheckTrialStatusAfterRemainingTrialTime({ user }, nowDate = new Date()) {
  // This is to ensure if a user logs in within their trial but then stays idle and trial expires that an action
  // is fired to ensure grace period countdown begins
  const { trialExpiryDate, accountType, gracePeriodHasEnded } = user;

  if (accountType !== ACCOUNT_TYPES.FREE || gracePeriodHasEnded) return;

  const delayMs = differenceInMilliseconds(trialExpiryDate, nowDate);

  yield delay(delayMs);
  yield put({ type: CHECK_TRIAL_STATUS });
}

export default function* trialSagas() {
  yield takeLatest('*', checkTrialStatus);
  yield takeLatest(AUTH_SUCCESS, triggerCheckTrialStatusAfterRemainingTrialTime);
}
