import cloneDeep from 'lodash.clonedeep';
import {
  CreateScribeFromTemplateSuccessAction,
  CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS
} from 'js/actionCreators/templatesActions';
import { ExistingScribeModel, ScribeScene, ScribeState, TemporaryDuplicatedScribe } from 'js/types';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { updateCameraCoverage } from 'js/sagas/sagaHelpers/updateCameraCoverage';
import { getTimelineLocalStorage } from 'js/editor/Timeline/PixiTimeline/Utils/localStorageHelpers';

import {
  UPDATE_SCRIBE,
  SET_ACTIVE_SCRIBE,
  SET_ACTIVE_ELEMENTS,
  ADD_SCRIBE_SUCCESS,
  LOAD_SCRIBES,
  LOAD_SCRIBES_SUCCESS,
  DELETE_SCRIBE_SUCCESS,
  SORT_SCRIBES,
  ADD_TO_ACTIVE_ELEMENTS,
  PREP_SCRIBE_FOR_EDITOR_SUCCESS,
  EDITOR_CLOSE,
  CREATE_SCENE,
  SET_SELECTED_SCENES,
  SET_ACTIVE_SCENE,
  DELETE_MULTIPLE_ELEMENTS_BY_ID,
  DELETE_SELECTED_SCENES,
  SET_SHOW_ELEMENT_MODAL,
  CREATE_TEXT_ELEMENT,
  REWRITE_HISTORY,
  ADD_TO_SELECTED_SCENES,
  UPDATE_LAST_SELECTED_SCENE,
  TOGGLE_ACTIVE_ELEMENT,
  UpdateScribeAction,
  SetActiveScribeAction,
  SetActiveElementsAction,
  ToggleActiveElementAction,
  AddToActiveElementsAction,
  AddScribeSuccessAction,
  LoadScribesSuccessAction,
  DeleteScribeSuccessAction,
  SortScribeAction,
  UpdateUndoRedoHistoryAction,
  PrepScribeForEditorSuccessAction,
  SetSelectedScenesAction,
  SetActiveSceneAction,
  AddToSelectedScenesAction,
  UpdateLastSelectedSceneAction,
  CREATE_ELEMENT,
  CreateElementAction,
  UPDATE_PROJECT_TITLE,
  UpdateProjectTitleAction,
  UPDATE_PROJECT_TITLE_FAILED,
  UpdateProjectTitleFailedAction,
  LOAD_SCRIBE_BY_ID_SUCCESS,
  LoadScribeByIdSuccessAction,
  FilterByCanvasSizeAction,
  FILTER_SCRIBES_BY_CANVAS_SIZE,
  FILTER_SCRIBES_BY_SEARCH_TERM,
  UpdateProjectSearchTermAction,
  SET_SELECTED_AUDIO_CLIP,
  SetSelectedAudioClipAction,
  SET_FIRST_LOAD,
  SetFirstLoadAction,
  SET_SELECTED_TEXT_COLOR,
  SetSelectedTextColorAction,
  SetCustomTextStyleAction,
  SET_CUSTOM_TEXT_STYLE,
  SetIsTextboxEditingAction,
  SET_IS_TEXTBOX_EDITING
} from '../actionCreators/scribeActions';
import {
  EDITOR_UNDO_SUCCESS,
  EDITOR_REDO_SUCCESS,
  EDITOR_SET_EDIT_PANEL_MODE,
  EditorUndoSuccessAction,
  EditorRedoSuccessAction,
  EditorSetEditPanelModeAction
} from '../actionCreators/editorActions';
import { RESET_STATE } from '../actionCreators/generalActions';
import {
  DUPLICATE_SCRIBE,
  DUPLICATE_SCRIBE_SUCCESS,
  DUPLICATE_SCRIBE_FAILED,
  DUPLICATE_SCRIBE_START,
  DuplicateScribeStartAction,
  DuplicateScribeSuccessAction,
  DuplicateScribeFailedAction
} from '../actionCreators/duplicateScribeActions';

import setActiveSceneReducer from './reducerHelpers/setActiveSceneReducer';
import renameProjectReducer from './reducerHelpers/renameProjectReducer';
import { createHomepageProjectFromProject, replaceHomepageProject } from './reducerHelpers/homepageProjectHelpers';

const filterTemporaryDuplicateProject = (project: ExistingScribeModel | TemporaryDuplicatedScribe, loadingId: string) =>
  project.loadingId !== loadingId;

const initialState: ScribeState = {
  activeScribePast: [],
  activeScribeFuture: [],
  activeElements: [],
  selectedTextColor: null,
  loadingAllScribes: true,
  loadingScribes: [],
  allScribes: [],
  scribesSortOrder: 'updatedDate',
  loadingScribeForEditor: true,
  selectedSceneIds: [],
  selectedAudioClip: null,
  duplicatingScribe: false,
  firstLoad: true,
  lastSelectedScene: null,
  lastSelectedElement: null,
  canvasSizeFilter: 'allSizes',
  projectSearchTerm: '',
  homepageProjects: [],
  isTextboxEditing: false,
  customTextStyleToBeSet: null
};

type ScribeAction =
  | {
      type:
        | typeof RESET_STATE
        | typeof LOAD_SCRIBES
        | typeof EDITOR_CLOSE
        | typeof CREATE_SCENE
        | typeof DELETE_SELECTED_SCENES
        | typeof DELETE_MULTIPLE_ELEMENTS_BY_ID
        | typeof SET_SHOW_ELEMENT_MODAL
        | typeof CREATE_TEXT_ELEMENT
        | typeof DUPLICATE_SCRIBE;
    }
  | UpdateScribeAction
  | SetActiveScribeAction
  | SetActiveElementsAction
  | ToggleActiveElementAction
  | AddToActiveElementsAction
  | AddScribeSuccessAction
  | EditorUndoSuccessAction
  | EditorRedoSuccessAction
  | LoadScribesSuccessAction
  | DeleteScribeSuccessAction
  | SortScribeAction
  | UpdateUndoRedoHistoryAction
  | PrepScribeForEditorSuccessAction
  | SetSelectedScenesAction
  | SetActiveSceneAction
  | EditorSetEditPanelModeAction
  | DuplicateScribeStartAction
  | DuplicateScribeSuccessAction
  | DuplicateScribeFailedAction
  | AddToSelectedScenesAction
  | UpdateLastSelectedSceneAction
  | CreateScribeFromTemplateSuccessAction
  | CreateElementAction
  | UpdateProjectSearchTermAction
  | UpdateProjectTitleAction
  | UpdateProjectTitleFailedAction
  | LoadScribeByIdSuccessAction
  | SetSelectedAudioClipAction
  | SetFirstLoadAction
  | FilterByCanvasSizeAction
  | SetSelectedTextColorAction
  | SetCustomTextStyleAction
  | SetIsTextboxEditingAction;

export default function scribesReducer(state = initialState, action: ScribeAction): ScribeState {
  switch (action.type) {
    case UPDATE_SCRIBE:
      action.scribe.updatedOn = new Date();

      const newActiveElements = action.activeElementIds ? action.activeElementIds : state.activeElements;
      const newActiveAudioClipId = action.activeAudioClipId ? action.activeAudioClipId : state.selectedAudioClip?.id;

      const scribeToCopy = state.allScribes.find(scribe => scribe.id === action.scribe.id);

      const shouldUpdateUndoHistory = !action.skipUndoHistory && scribeToCopy;

      const activeScene = action.scribe.scenes.find(scene => scene.active);
      if (activeScene) action.scribe = updateCameraCoverage(newActiveElements, activeScene, action.scribe);

      if (shouldUpdateUndoHistory) {
        scribeToCopy.selectedElementIds = [...state.activeElements];
        scribeToCopy.selectedAudioClipId = state.selectedAudioClip?.id;
        action.scribe.nextSelectedElementIds = [...newActiveElements];
        action.scribe.nextSelectedAudioClipId = newActiveAudioClipId;
      }

      const activeScribePast = shouldUpdateUndoHistory
        ? [...state.activeScribePast, cloneDeep(scribeToCopy)]
        : state.activeScribePast;

      const homeProjectToUpdate = createHomepageProjectFromProject(action.scribe);
      return {
        ...state,
        allScribes: state.allScribes.map(scribe => (scribe.id === action.scribe.id ? action.scribe : scribe)),
        homepageProjects: replaceHomepageProject(state.homepageProjects, homeProjectToUpdate),
        activeScribePast,
        activeScribeFuture: action.skipUndoHistory ? state.activeScribeFuture : [],
        activeElements: newActiveElements
      };

    case SET_ACTIVE_SCRIBE: {
      return {
        ...state,
        // clear undo/redo history
        activeScribePast: [],
        activeScribeFuture: [],
        activeElements: [],
        loadingScribeForEditor: true,
        lastSelectedScene: null,
        lastSelectedElement: null
      };
    }
    case SET_FIRST_LOAD: {
      return { ...state, firstLoad: action.value };
    }
    case SET_ACTIVE_ELEMENTS: {
      return {
        ...state,
        activeElements: typeof action.elementIds === 'string' ? [action.elementIds] : action.elementIds || [],
        lastSelectedElement: action.elementIds?.length ? action.elementIds : null,
        selectedSceneIds:
          (!action.elementIds || action.elementIds.length === 0) && state.selectedSceneIds.length > 0
            ? state.selectedSceneIds
            : [],
        selectedAudioClip: null,
        lastSelectedScene: null
      };
    }

    case TOGGLE_ACTIVE_ELEMENT: {
      const elementIsAlreadySelected = state.activeElements.includes(action.elementId);

      if (elementIsAlreadySelected) {
        return {
          ...state,
          activeElements: state.activeElements.filter(element => element !== action.elementId)
        };
      } else {
        return {
          ...state,
          activeElements: [...state.activeElements, action.elementId],
          lastSelectedElement: action.elementId
        };
      }
    }

    case ADD_TO_ACTIVE_ELEMENTS: {
      const activeElementsCopy = state.activeElements.slice() as string | Array<string>;

      const newActiveElements = typeof activeElementsCopy === 'string' ? [activeElementsCopy] : activeElementsCopy;

      if (newActiveElements.indexOf(action.elementId) === -1) newActiveElements.push(action.elementId);

      return {
        ...state,
        activeElements: newActiveElements,
        lastSelectedElement: action.elementId
      };
    }

    case RESET_STATE: {
      return {
        ...initialState
      };
    }

    case ADD_SCRIBE_SUCCESS: {
      return {
        ...state,
        allScribes: [...state.allScribes, action.scribe],
        selectedSceneIds: [action.scribe.scenes[0].id],
        lastSelectedScene: action.scribe.scenes[0].id,
        homepageProjects: [...state.homepageProjects, createHomepageProjectFromProject(action.scribe)]
      };
    }

    case EDITOR_UNDO_SUCCESS:
    case EDITOR_REDO_SUCCESS: {
      return {
        ...state,
        activeScribePast: action.newPast,
        activeScribeFuture: action.newFuture,
        activeElements: []
      };
    }

    case LOAD_SCRIBES: {
      return {
        ...state,
        loadingAllScribes: true
      };
    }

    case LOAD_SCRIBES_SUCCESS: {
      return {
        ...state,
        homepageProjects: action.scribes,
        loadingAllScribes: false
      };
    }

    case DELETE_SCRIBE_SUCCESS: {
      const newScribes = [...state.allScribes];
      const index = newScribes.findIndex(scribe => {
        return scribe.id === action.scribeId;
      });

      if (index > -1) {
        newScribes.splice(index, 1);
      }

      return {
        ...state,
        allScribes: newScribes
      };
    }

    case SORT_SCRIBES:
      return {
        ...state,
        scribesSortOrder: action.sortOrder
      };

    case REWRITE_HISTORY:
      return {
        ...state,
        activeScribePast: action.activeScribePast,
        activeScribeFuture: action.activeScribeFuture
      };

    case PREP_SCRIBE_FOR_EDITOR_SUCCESS: {
      const replacementIndex = state.allScribes.findIndex(scribe => scribe.id === action.scribe.id);
      let activeScene: ScribeScene | undefined = getActiveScene(action.scribe);
      //check for locally stored activeScene
      let currentSceneId: string | null = null;
      try {
        currentSceneId = getTimelineLocalStorage(action.scribe.id, 'sceneId');
      } catch (error) {
        console.error(error);
      }
      if (currentSceneId) {
        if (activeScene) {
          activeScene.active = false;
        }
        activeScene = action.scribe.scenes.find(scene => scene.id === currentSceneId);

        if (activeScene) {
          activeScene.active = true;
        } else {
          action.scribe.scenes[0].active = true;
        }
      }
      if (replacementIndex !== -1) {
        const updatedScribes = [...state.allScribes];
        updatedScribes[replacementIndex] = action.scribe;

        return {
          ...state,
          allScribes: updatedScribes,
          loadingScribeForEditor: false,
          selectedSceneIds: activeScene ? [activeScene.id] : [],
          lastSelectedScene: activeScene ? activeScene.id : null
        };
      }
      return state;
    }

    case EDITOR_CLOSE: {
      return {
        ...state,
        loadingScribeForEditor: true,
        selectedSceneIds: [],
        lastSelectedScene: null,
        lastSelectedElement: null
      };
    }

    case CREATE_SCENE: {
      return {
        ...state,
        activeElements: []
      };
    }

    case SET_SELECTED_SCENES: {
      if (action.sceneIds.length === 0) {
        return {
          ...state,
          selectedAudioClip: null,
          selectedSceneIds: [],
          lastSelectedScene: null
        };
      } else {
        return {
          ...state,
          selectedSceneIds: [...action.sceneIds],
          activeElements: [],
          lastSelectedScene: action.sceneIds[action.sceneIds.length - 1] || null
        };
      }
    }

    case SET_SELECTED_AUDIO_CLIP: {
      return {
        ...state,
        selectedAudioClip: action.selectedAudioClip
      };
    }

    case SET_ACTIVE_SCENE: {
      return setActiveSceneReducer(state, action);
    }

    case EDITOR_SET_EDIT_PANEL_MODE: {
      if (action.mode === 'audio') {
        return {
          ...state,
          activeElements: []
        };
      }
      return {
        ...state,
        selectedAudioClip: null
      };
    }

    case DELETE_SELECTED_SCENES:
    case DELETE_MULTIPLE_ELEMENTS_BY_ID: {
      return {
        ...state,
        activeElements: []
      };
    }

    case DUPLICATE_SCRIBE: {
      return {
        ...state,
        duplicatingScribe: true
      };
    }

    case DUPLICATE_SCRIBE_START: {
      return {
        ...state,
        loadingScribes: [action.loadingScribe, ...state.loadingScribes]
      };
    }

    case DUPLICATE_SCRIBE_SUCCESS: {
      return {
        ...state,
        duplicatingScribe: true,
        homepageProjects: [action.duplicatedProject, ...state.homepageProjects],
        loadingScribes: state.loadingScribes.filter(scribe => filterTemporaryDuplicateProject(scribe, action.loadingId))
      };
    }

    case DUPLICATE_SCRIBE_FAILED: {
      return {
        ...state,
        duplicatingScribe: true,
        loadingScribes: state.loadingScribes.filter(scribe => filterTemporaryDuplicateProject(scribe, action.loadingId))
      };
    }

    case ADD_TO_SELECTED_SCENES: {
      const selectedScenesCopy = state.selectedSceneIds.slice() as string | Array<string>;
      const newSelectedScenes = typeof selectedScenesCopy === 'string' ? [selectedScenesCopy] : selectedScenesCopy;

      if (newSelectedScenes.indexOf(action.sceneId) === -1) newSelectedScenes.push(action.sceneId);

      return {
        ...state,
        selectedSceneIds: newSelectedScenes
      };
    }

    case UPDATE_LAST_SELECTED_SCENE: {
      const sceneIsInSelection = state.selectedSceneIds.includes(action.sceneId);
      return {
        ...state,
        lastSelectedScene: sceneIsInSelection ? action.sceneId : state.lastSelectedScene
      };
    }

    case CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS: {
      if (!action.project) return state;

      const activeScene = getActiveScene(action.project);
      const homepageProjects = [...state.homepageProjects, createHomepageProjectFromProject(action.project)];

      return {
        ...state,
        allScribes: [action.project, ...state.allScribes],
        selectedSceneIds: activeScene ? [activeScene.id] : [],
        lastSelectedScene: activeScene ? activeScene.id : null,
        homepageProjects
      };
    }

    case CREATE_ELEMENT: {
      return {
        ...state,
        selectedSceneIds: [],
        lastSelectedScene: null
      };
    }
    case FILTER_SCRIBES_BY_CANVAS_SIZE: {
      return {
        ...state,
        canvasSizeFilter: action.size
      };
    }
    case FILTER_SCRIBES_BY_SEARCH_TERM: {
      return { ...state, projectSearchTerm: action.term };
    }

    case UPDATE_PROJECT_TITLE: {
      return renameProjectReducer(state, action.scribeId, action.title);
    }

    case UPDATE_PROJECT_TITLE_FAILED: {
      return renameProjectReducer(state, action.scribeId, action.previousTitle);
    }

    case LOAD_SCRIBE_BY_ID_SUCCESS: {
      const existingIndex = state.allScribes.findIndex(scribe => scribe.id === action.scribe.id);

      if (existingIndex === -1) {
        return {
          ...state,
          firstLoad: true,
          allScribes: [...state.allScribes, action.scribe]
        };
      } else {
        const allScribes = cloneDeep(state.allScribes);
        allScribes[existingIndex] = action.scribe;

        return {
          ...state,
          firstLoad: true,
          selectedAudioClip: null,
          allScribes
        };
      }
    }

    case SET_SELECTED_TEXT_COLOR: {
      return {
        ...state,
        selectedTextColor: action.color
      };
    }

    case SET_CUSTOM_TEXT_STYLE: {
      return {
        ...state,
        customTextStyleToBeSet: action.style
      };
    }

    case SET_IS_TEXTBOX_EDITING: {
      return {
        ...state,
        isTextboxEditing: action.isEditing
      };
    }

    default:
      return state;
  }
}
