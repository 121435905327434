import * as PIXI from 'pixi.js';

const loaderOptions = {
  loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
  xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB
};

const rasterLoader = new PIXI.Loader();

export const rasterLoaderPromise = (): Promise<PIXI.utils.Dict<PIXI.LoaderResource>> =>
  new Promise(resolve => rasterLoader.load((_loader, resources) => resolve(resources)));

export const rasterLoaderAdd = async (url: string) => {
  if (!rasterLoader.resources[url]) {
    if (rasterLoader.loading) {
      const promise: Promise<void> = new Promise(resolve => {
        rasterLoader.onComplete.once(() => resolve());
      });
      await promise;
    }

    rasterLoader.add(url, loaderOptions);
  }
};
