import { END, EventChannel, eventChannel, Task } from 'redux-saga';
import { call, put, take, takeEvery, all, fork, join } from 'redux-saga/effects';
import {
  PROCESS_AI_IMAGES_REQUEST,
  PROCESS_IMAGE_FILE_ON_SUCCESSFUL_UPLOAD,
  processAiImagesFailure,
  processAiImageProgress,
  ProcessAiImagesRequestAction,
  processAiImagesSuccess,
  ProcessImageFileOnSuccessfulUploadAction
} from 'js/actionCreators/imageProcessingActions';
import { showError } from 'js/actionCreators/uiActions';
import extractGifData from 'js/playback/lib/Playback/helpers/extractGifData';
import {
  ImageProcessorCompleteEvent,
  ImageProcessorErrorEvent,
  ImageProcessorProgressEvent
} from 'js/shared/lib/ImageProcessor/ImageProcessorTypes';
import ProcessorWorker from 'js/shared/lib/ImageProcessor/ImageProcessor.worker?worker';

import { timeEvent, track } from './mixpanel/mixpanelProvider';

export async function processImage({ file, assetId }: ProcessImageFileOnSuccessfulUploadAction) {
  if (file.type === 'image/gif') {
    extractGifData(assetId.toString(), await file.arrayBuffer());
  }
}

function createImageProcessorChannel(
  processor: Worker
): EventChannel<ImageProcessorProgressEvent | ImageProcessorCompleteEvent | ImageProcessorErrorEvent> {
  return eventChannel(emitter => {
    const handleMessage = (event: MessageEvent) => {
      const { progress, blob, error } = event.data;

      if (progress) {
        return emitter({ progress });
      }
      if (blob) {
        emitter({ blob });
        return emitter(END);
      }
      if (error) {
        emitter({ error });
        return emitter(END);
      }
    };

    processor.addEventListener('message', handleMessage as EventListener);

    return () => {
      processor.removeEventListener('message', handleMessage as EventListener);
    };
  });
}

const EVENT_NAME = 'Process AI Image';
function* processAiImage(url: string, imageBitmap?: ImageBitmap, pipeline?: string) {
  const processor = new ProcessorWorker();
  const channel = createImageProcessorChannel(processor);

  try {
    yield call(timeEvent, EVENT_NAME);
    processor.postMessage({ url, imageBitmap, pipeline });
    while (true) {
      const { error, blob, progress } = yield take(channel);
      if (error) {
        return { success: false, error };
      }
      if (blob) {
        return { success: true, blob };
      }
      if (progress) {
        yield put(processAiImageProgress({ progress, url }));
      }
    }
  } finally {
    channel.close();
  }
}

type ProcessAiImagesResult =
  | {
      success: true;
      blob: Blob;
    }
  | {
      success: false;
      error: Error;
    };

function* handleProcessAiImagesResults(results: ProcessAiImagesResult[]) {
  const allSuccess = results.every(result => result.success);
  if (allSuccess) {
    yield call(track, EVENT_NAME, { success: true });
    yield put(
      processAiImagesSuccess({
        images: results.map(result => ({ url: URL.createObjectURL(result.blob), blob: result.blob }))
      })
    );
    return;
  }
  const failedResult = results.find(result => 'error' in result);
  if (failedResult) throw failedResult.error;
}

function* processAiImages({ images, pipeline }: ProcessAiImagesRequestAction) {
  try {
    // TODO: Limit the number of concurrent image processing tasks if needed
    const tasks = (yield all(
      images.map(({ url, imageBitmap }) => fork(processAiImage, url, imageBitmap, pipeline))
    )) as Task<ProcessAiImagesResult>[];
    const results = (yield join(tasks)) as ProcessAiImagesResult[];
    yield handleProcessAiImagesResults(results);
  } catch (error) {
    if (error instanceof Error) {
      yield put(processAiImagesFailure({ error }));
      yield put(
        showError({
          message: 'Something went wrong post processing image',
          description: error.message
        })
      );
      yield call(track, EVENT_NAME, { success: false });
    }
  }
}

export default function* imageProcessingSagas() {
  yield takeEvery(PROCESS_IMAGE_FILE_ON_SUCCESSFUL_UPLOAD, processImage);
  yield takeEvery(PROCESS_AI_IMAGES_REQUEST, processAiImages);
}
