import cloneDeep from 'lodash.clonedeep';
import uuid from 'uuid/v4';
import { getCenterOfViewport } from 'js/shared/helpers/element';
import * as PIXI from 'pixi.js';
import { getEditorCanvas } from 'js/editor/EditorCanvas/EditorCanvas';

import { getViewportData } from './getViewportData';
import { getPositionAdjustmentToFitInBounds } from './getPositionAdjustmentToFitInBounds';
import { getElementBoundingRectangle } from './getElementBoundingRectangle';

export const getGroupSelectionCentrePoints = (elementData, canvasSize) => {
  const allElementX = elementData.map(element => element.x);
  const allElementXPlusWidth = elementData.map(element => {
    if (element.type === 'Camera') {
      return element.x + canvasSize.width / element.scale;
    }

    return element.x + element.width * element.scaleX;
  });

  const minX = Math.min(...allElementX);
  const maxX = Math.max(...allElementXPlusWidth);

  const allElementY = elementData.map(element => element.y);
  const allElementYPlusHeight = elementData.map(element => {
    if (element.type === 'Camera') {
      return element.y + canvasSize.height / element.scale;
    }
    return element.y + element.height * element.scaleY;
  });

  const minY = Math.min(...allElementY);
  const maxY = Math.max(...allElementYPlusHeight);

  const multiSelectionWidth = maxX - minX;
  const multiSelectionHeight = maxY - minY;

  return {
    groupSelectionCentrePointX: minX + multiSelectionWidth / 2,
    groupSelectionCentrePointY: minY + multiSelectionHeight / 2
  };
};

function getSelectionBoundingRectangle(newElements, canvasDimensions) {
  const rectangles = newElements.map(element => getElementBoundingRectangle(element, canvasDimensions));

  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;

  rectangles.forEach(rect => {
    minX = Math.min(minX, rect.left);
    minY = Math.min(minY, rect.top);
    maxX = Math.max(maxX, rect.right);
    maxY = Math.max(maxY, rect.bottom);
  });

  const boundingRect = new PIXI.Rectangle(minX, minY, maxX - minX, maxY - minY);

  return boundingRect;
}

export default function pasteElementsIntoScribe(scribe, clipboard, activeElementIds, inPlace) {
  const clonedScribe = cloneDeep(scribe);
  const activeScene = clonedScribe.scenes.find(scene => scene.active);
  const pastePosition = activeElementIds?.length
    ? Math.max(...activeElementIds.map(elementId => activeScene.elementIds.findIndex(id => id === elementId))) + 1
    : activeScene.elementIds.length;

  const viewportCenter = getCenterOfViewport(0, 0);
  const canvasDimensions = getViewportData(clonedScribe.canvasSize);

  const isMultiple = clipboard.data.length > 1;

  const { groupSelectionCentrePointX, groupSelectionCentrePointY } = getGroupSelectionCentrePoints(
    clipboard.data,
    canvasDimensions
  );

  const newElements = clipboard.data.map(element => {
    const newElement = {
      ...cloneDeep(element),
      id: uuid()
    };
    if (newElement.text) {
      newElement.isNew = false;
    }

    let x = element.x;
    let y = element.y;

    if (isMultiple && !inPlace) {
      const relativeX = groupSelectionCentrePointX - newElement.x;
      const relativeY = groupSelectionCentrePointY - newElement.y;

      x = viewportCenter.x - relativeX;
      y = viewportCenter.y - relativeY;
    } else if (!isMultiple && !inPlace) {
      if (element.type === 'Camera') {
        x = viewportCenter.x - canvasDimensions.width / newElement.scale / 2;
        y = viewportCenter.y - canvasDimensions.height / newElement.scale / 2;
      } else {
        x = viewportCenter.x - (newElement.width * newElement.scaleX) / 2;
        y = viewportCenter.y - (newElement.height * newElement.scaleY) / 2;
      }
    }

    newElement.x = x;
    newElement.y = y;

    return newElement;
  });

  const editorCanvas = getEditorCanvas();
  const containsCamera = newElements.some(element => element.type === 'Camera');
  if (editorCanvas && containsCamera) {
    // Get the bounding rectangle of the new elements
    // Check to see if the rectangle is out of bounds
    // If it is out of bounds, find how much by
    // Adjust all position by the difference
    const boundingRect = getSelectionBoundingRectangle(newElements, canvasDimensions);
    const canvasArea = editorCanvas.canvasBounds;
    const { xAdjust, yAdjust } = getPositionAdjustmentToFitInBounds(boundingRect, canvasArea);

    newElements.forEach(el => {
      el.x = el.x + xAdjust;
      el.y = el.y + yAdjust;
    });
  }

  const newElementIds = newElements.map(el => el.id);

  clonedScribe.elements = [...clonedScribe.elements, ...newElements];
  activeScene.elementIds.splice(pastePosition, 0, ...newElementIds);

  const clipboardPayload = {
    type: 'element',
    metadata: {
      ...clipboard.metadata,
      sceneId: activeScene.id
    },
    data: cloneDeep(clipboard.data)
  };

  return { scribe: clonedScribe, newElementIds, clipboard: clipboardPayload };
}
