import {
  ADD_CAMERA,
  AddCameraAction,
  SET_CAMERA_EASE_TYPE,
  SetCameraEasingTypeAction
} from 'js/actionCreators/cameraActions';
import {
  PIN_CAMERAS,
  PinCamerasAction,
  TOGGLE_CAMERA_PINS,
  TogglePinsAction,
  UNPIN_CAMERAS,
  UnpinCamerasAction
} from 'js/actionCreators/scribeActions';
import { CAMERA_EASING_EASEOUT, CAMERA_EASING_LINEAR, CAMERA_EASING_NONE } from 'js/config/consts';
import { getScribeById } from 'js/sagas/selectors';
import { allCamerasPinned } from 'js/shared/allCamerasPinned';
import { ExistingScribeModel } from 'js/types';
import { CallEffect, SelectEffect, call, select, takeEvery } from 'redux-saga/effects';

import { track } from '../mixpanelProvider';

function* trackAddCamera({ projectId }: AddCameraAction) {
  yield call(track, 'Add Camera To Current View', {
    'Scribe ID': projectId
  });
}

function* trackCameraPins({ elementIds, scribeId, eventTrigger, type }: PinCamerasAction | UnpinCamerasAction) {
  const event = type === PIN_CAMERAS ? 'Pin Camera to Canvas' : 'Unpin Camera from Canvas';
  yield call(track, event, {
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger,
    'No. of Cameras': elementIds.length
  });
}

function* trackAllCameraPins({
  scribeId,
  eventTrigger
}: TogglePinsAction): Generator<SelectEffect | CallEffect, void, ExistingScribeModel> {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const activeSceneCamerasPinned = allCamerasPinned(scribe);

  const event = activeSceneCamerasPinned ? 'Unpin All Cameras from Canvas' : 'Pin All Cameras to Canvas';
  yield call(track, event, {
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger
  });
}

function* trackCameraEaseType({
  easeType,
  scribeId
}: SetCameraEasingTypeAction): Generator<SelectEffect | CallEffect, void, ExistingScribeModel> {
  const event = 'Set Camera Movement Type';
  let easeTypeParam = '';
  if (easeType === CAMERA_EASING_NONE) {
    easeTypeParam = 'None';
  } else if (easeType === CAMERA_EASING_EASEOUT) {
    easeTypeParam = 'Ease Out';
  } else if (easeType === CAMERA_EASING_LINEAR) {
    easeTypeParam = 'Linear';
  }
  yield call(track, event, {
    'Scribe ID': scribeId,
    'Movement Type': easeTypeParam
  });
}

export default function* trackCameraSagas() {
  yield takeEvery(ADD_CAMERA, trackAddCamera);
  yield takeEvery([PIN_CAMERAS, UNPIN_CAMERAS], trackCameraPins);
  yield takeEvery(TOGGLE_CAMERA_PINS, trackAllCameraPins);
  yield takeEvery(SET_CAMERA_EASE_TYPE, trackCameraEaseType);
}
