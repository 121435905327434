import * as PIXI from 'pixi.js';
import chunk from 'lodash.chunk';
import { SVG } from '@svgdotjs/svg.js';
import '@pixi/math-extras';
import { SVGMaskPathAndBrushData } from 'js/types';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { getSVGMaskPath, storeSVGMaskPath } from 'js/shared/lib/LocalDatabase';

/***
 * This function takes the pixel data of an SVG images and does the following:
 * 1. Detects the edge pixels of the drawing and finds the left-most pixel
 * 2. Calculates the Convex Hull of the edge pixels (giving us a reduced drawing area)
 * 3. Generates points along the hull in both clockwise and anti-clockwise directions to generate the "scribble"
 */
export async function generateScribbleFromHull(
  imageData: Uint8ClampedArray,
  width: number,
  height: number,
  element: ScribeImageElementModel
): Promise<SVGMaskPathAndBrushData> {
  if (element.image) {
    const cachedData = await getSVGMaskPath(element.image.assetId);
    if (cachedData) {
      return cachedData.data;
    }
  }

  const pixels = chunk(imageData, 4);
  const grid = chunk(pixels, width);
  const edgePixels = [];

  // Reduce the image to edge pixels only
  for (let rowIndex = 0; rowIndex < height; rowIndex++) {
    for (let columnIndex = 0; columnIndex < width; columnIndex++) {
      const pixel = grid[rowIndex][columnIndex];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_r, _g, _b, a] = pixel;

      if (a !== 0) {
        const nwPixel = grid[rowIndex - 1]?.[columnIndex - 1];
        const nePixel = grid[rowIndex - 1]?.[columnIndex + 1];
        const sePixel = grid[rowIndex + 1]?.[columnIndex + 1];
        const swPixel = grid[rowIndex + 1]?.[columnIndex - 1];

        if (!(nwPixel?.[3] > 0 && nePixel?.[3] > 0 && sePixel?.[3] > 0 && swPixel?.[3] > 0)) {
          edgePixels.push(new PIXI.Point(columnIndex, rowIndex));
        }
      }
    }
  }

  const sortedEdgePixels = edgePixels.sort((a, b) => a.x - b.x);
  const leftmostPixel = sortedEdgePixels[0];

  let currentVertex = leftmostPixel;
  const hull = [leftmostPixel];
  let nextVertex = sortedEdgePixels[1];
  let checkingIndex = 2;

  let done = false;
  // "gift-wrap" the pixels to get the convex hull
  while (!done) {
    const checking = sortedEdgePixels[checkingIndex];
    const a = currentVertex.subtract(nextVertex);
    const b = currentVertex.subtract(checking);
    const cross = a.cross(b);

    if (cross < 0) {
      nextVertex = checking;
    }

    checkingIndex += 1;

    if (checkingIndex === sortedEdgePixels.length) {
      if (nextVertex === leftmostPixel) {
        done = true;
      } else {
        hull.push(nextVertex);
        currentVertex = nextVertex;
        checkingIndex = 0;
        nextVertex = leftmostPixel;
      }
    }
  }

  // Create an svg path command for the convex hull so we can find the scribble points around it
  const svgPathArray = [];
  hull.forEach((point, i) => {
    if (i === 0) {
      svgPathArray.push(['M', point.x, point.y]);
    } else {
      svgPathArray.push(['L', point.x, point.y]);
    }
  });
  svgPathArray.push('z');

  const svgHull = SVG().path(svgPathArray);
  const steps = 10;

  const stepSize = svgHull.length() / 2 / steps;
  const scribblePoints = [];
  for (let stepIndex = 0; stepIndex < steps; stepIndex++) {
    scribblePoints.push(svgHull.pointAt(stepIndex * stepSize));
    scribblePoints.push(svgHull.pointAt(svgHull.length() - stepIndex * stepSize));
  }

  const output = {
    scribbleArray: scribblePoints,
    brushSize: stepSize
  };

  if (element.image) {
    await storeSVGMaskPath(element.image.assetId, output);
  }

  return output;
}
