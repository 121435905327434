import { ImageDrawerTab, VSLibraryImage, GeneralSearchImage, PremiumSearchImage, ImageSource } from 'js/types';

export const CHANGE_IMAGE_DRAWER_TAB = 'CHANGE_IMAGE_DRAWER_TAB';
export const GET_CATEGORY_IMAGES = 'GET_CATEGORY_IMAGES';
export const GET_CATEGORY_IMAGES_SUCCESS = 'GET_CATEGORY_IMAGES_SUCCESS';
export const GET_CATEGORY_IMAGES_FAILURE = 'GET_CATEGORY_IMAGES_FAILURE';
export const GET_SEARCH_IMAGES = 'GET_SEARCH_IMAGES';
export const GET_SEARCH_IMAGES_SUCCESS = 'GET_SEARCH_IMAGES_SUCCESS';
export const GET_SEARCH_IMAGES_FAILURE = 'GET_SEARCH_IMAGES_FAILURE';
export const SET_QUICK_IMAGE_SEARCH_TAG = 'SET_QUICK_IMAGE_SEARCH_TAG';
export const UPDATE_SEARCH_TAB_VIEWED = 'UPDATE_SEARCH_TAB_VIEWED';

export interface ChangeImageDrawerTabAction {
  type: typeof CHANGE_IMAGE_DRAWER_TAB;
  tab: ImageDrawerTab;
}

export const changeImageDrawerTab = (tab: ImageDrawerTab): ChangeImageDrawerTabAction => ({
  type: CHANGE_IMAGE_DRAWER_TAB,
  tab
});

export interface GetCategoryImagesAction {
  type: typeof GET_CATEGORY_IMAGES;
  categoryId: string;
  imageSource: ImageSource;
  nextPageId: string;
}

export const getCategoryImages = (
  categoryId: string,
  imageSource: ImageSource,
  nextPageId: string
): GetCategoryImagesAction => ({
  type: GET_CATEGORY_IMAGES,
  categoryId,
  imageSource,
  nextPageId
});

export interface GetCategoryImagesSuccessAction {
  type: typeof GET_CATEGORY_IMAGES_SUCCESS;
  categoryId: string;
  imageSource: ImageSource;
  images: Array<VSLibraryImage>;
  nextPageId: string | null;
}

export const getCategoryImagesSuccess = (
  categoryId: string,
  imageSource: ImageSource,
  images: Array<VSLibraryImage>,
  nextPageId: string | null
): GetCategoryImagesSuccessAction => ({
  type: GET_CATEGORY_IMAGES_SUCCESS,
  categoryId,
  imageSource,
  images,
  nextPageId
});

export interface GetCategoryImagesFailureAction {
  type: typeof GET_CATEGORY_IMAGES_FAILURE;
  categoryId: string;
  imageSource: ImageSource;
}

export const getCategoryImagesFailure = (
  categoryId: string,
  imageSource: ImageSource
): GetCategoryImagesFailureAction => ({
  type: GET_CATEGORY_IMAGES_FAILURE,
  categoryId,
  imageSource
});

export interface GetSearchImagesAction {
  type: typeof GET_SEARCH_IMAGES;
  imageSource: ImageSource;
  searchInput: string;
  offset: number;
  nextPageId?: string;
  isPrefetch?: boolean;
}

export const getSearchImages = (
  imageSource: ImageSource,
  searchInput: string,
  offset: number,
  nextPageId?: string,
  isPrefetch = false
): GetSearchImagesAction => ({
  type: GET_SEARCH_IMAGES,
  imageSource,
  searchInput,
  offset,
  nextPageId,
  isPrefetch
});

export interface GetSearchImagesSuccessAction {
  type: typeof GET_SEARCH_IMAGES_SUCCESS;
  imageSource: ImageSource;
  images: Array<GeneralSearchImage> | Array<PremiumSearchImage>;
  offset: number;
  total: number;
  searchInput: string;
  nextPageId?: string;
  isPrefetch: boolean;
}

export const getSearchImagesSuccess = (
  imageSource: ImageSource,
  images: Array<GeneralSearchImage> | Array<PremiumSearchImage>,
  offset: number,
  total: number,
  searchInput: string,
  isPrefetch: boolean,
  nextPageId?: string
): GetSearchImagesSuccessAction => ({
  type: GET_SEARCH_IMAGES_SUCCESS,
  imageSource,
  images,
  offset,
  total,
  searchInput,
  nextPageId,
  isPrefetch
});

export interface GetSearchImagesFailureAction {
  type: typeof GET_SEARCH_IMAGES_FAILURE;
  imageSource: ImageSource;
  searchInput: string;
}

export const getSearchImagesFailure = (
  imageSource: ImageSource,
  searchInput: string
): GetSearchImagesFailureAction => ({
  type: GET_SEARCH_IMAGES_FAILURE,
  imageSource,
  searchInput
});

export interface SetQuickImageSearchTagAction {
  type: typeof SET_QUICK_IMAGE_SEARCH_TAG;
  tag: string | null;
}

export const setQuickImageSearchTag = (tag: string | null): SetQuickImageSearchTagAction => ({
  type: SET_QUICK_IMAGE_SEARCH_TAG,
  tag
});

export interface UpdateSearchTabViewedAction {
  type: typeof UPDATE_SEARCH_TAB_VIEWED;
  imageSource: ImageSource;
  searchInput: string;
  isViewed?: boolean;
}

export const updateSearchTabViewed = (
  imageSource: ImageSource,
  searchInput: string,
  isViewed = true
): UpdateSearchTabViewedAction => ({
  type: UPDATE_SEARCH_TAB_VIEWED,
  imageSource,
  searchInput,
  isViewed
});
