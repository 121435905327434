import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMinutes from 'date-fns/subMinutes';
import isAfter from 'date-fns/isAfter';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';

export const getTimeRemaining = (trialExpiryDate?: Date, todayDate = new Date()) => {
  if (!trialExpiryDate || isAfter(todayDate, trialExpiryDate)) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: true
    };
  }

  const days = differenceInDays(trialExpiryDate, todayDate);

  const daysAdjusted = subDays(trialExpiryDate, days);
  const hours = differenceInHours(daysAdjusted, todayDate);

  const hoursAdjusted = subHours(daysAdjusted, hours);
  const minutes = differenceInMinutes(hoursAdjusted, todayDate);

  const minutesAdjusted = subMinutes(hoursAdjusted, minutes);
  const seconds = differenceInSeconds(minutesAdjusted, todayDate);

  return {
    days,
    hours,
    minutes,
    seconds,
    expired: false
  };
};

export type TimeRemaining = ReturnType<typeof getTimeRemaining>;
