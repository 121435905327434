import React, { useState, useRef, useEffect } from 'react';
import toNumber from 'lodash.tonumber';
import EditPanelLabel from 'js/editor/EditPanelLabel';
import classNames from 'classnames';
import { ARROWS_TRIGGER, INPUT_FIELD_TRIGGER, MAX_ANIMATION_TIME_SECONDS } from 'js/config/consts';
import { getRoundedInput } from 'js/shared/helpers/getRoundedInput';
import { INDETERMINATE_DISPLAY_VALUE } from 'js/config/defaults';

import NumberInputControls from '../NumberInputControls';

import './EditPanelNumberInput.css';

const noop = () => {
  /* noop */
};

const EditPanelNumberInput = ({
  className,
  label = '',
  id,
  value,
  onChange,
  min = 0,
  max = MAX_ANIMATION_TIME_SECONDS,
  step = 0.5,
  units = '',
  inputMode = 'decimal',
  disabled,
  onFocus = noop,
  onBlur = noop,
  allowDecimal = true,
  arrowControls = true
}: {
  className?: string;
  label?: string;
  id: string;
  value: number | string;
  onChange: ({
    value,
    eventTrigger,
    incrementValue,
    decrementValue
  }: {
    value: number | string;
    eventTrigger?: string;
    incrementValue?: boolean;
    decrementValue?: boolean;
  }) => void;
  min?: number;
  max?: number;
  step?: number;
  units?: string;
  inputMode?: 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: (value: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => void;
  allowDecimal?: boolean;
  arrowControls?: boolean;
}) => {
  const [stateValue, setStateValue] = useState<number | string>(value);
  const stateValueRef = useRef<number | string>();
  const valueRef = useRef<number | string>();
  // Keeps all the constraints needed for reference in cleanup
  const constraintsRef = useRef({ min, max });
  const showControls = arrowControls ? value !== INDETERMINATE_DISPLAY_VALUE || typeof value === 'number' : false;

  useEffect(() => {
    constraintsRef.current = { min, max };
  }, [min, max]);

  useEffect(() => {
    let cancelled = false;
    // Ensures the value ref is updated when value is changed
    valueRef.current = value;

    if (!cancelled) {
      // If the prop value is changed then this updates the internal `stateValue`
      setStateValue(value);
    }

    return () => {
      cancelled = true;
    };
  }, [value]);

  useEffect(() => {
    // Ensures the stateValue ref is updated when stateValue is changed
    stateValueRef.current = toNumber(stateValue);
  }, [stateValue]);

  useEffect(() => {
    /*
      In React if a component is unmounted then any onblur handlers are ignored even
      if the field is focused at the time. As we want the user to be able to edit the
      field and then click away without having to press a save button then we need to be
      able to call the onChange function if the component is unmounted. This means we need
      to track the prop `value` and the internal state `stateValue` using refs so that they
      can be referenced properly by the effect cleanup which is fired when the component
      is unmounted. The reason we have not used an inputRef: `The ref value 'inputRef.current' will 
      likely have changed by the time this effect cleanup function runs. If this ref points to a node 
      rendered by React, copy 'inputRef.current' to a variable inside the effect, and use that 
      variable in the cleanup`
    */
    return function cleanup() {
      if (stateValueRef.current !== valueRef.current) {
        const validNumber = validateNumberInput(stateValueRef.current);
        if (validNumber === false) {
          return;
        }
        const submitNumber = sanitizeNumber(validNumber);
        onChange({ value: submitNumber, eventTrigger: INPUT_FIELD_TRIGGER });
      }
    };
    // Disabling eslint for the dependencies as we only ever want this
    // function to run when the component is unmounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetField = () => setStateValue(value);

  const validateNumberInput = (input: undefined | null | string | number) => {
    if (typeof input === 'undefined' || input === null || input === '' || input === '-') {
      return false;
    }
    const numberValue = toNumber(input);
    if (!Number.isFinite(numberValue)) {
      return false;
    }

    return numberValue;
  };

  const sanitizeNumber = (number: number) => {
    const roundedNumber = getRoundedInput(number, allowDecimal);
    // Ensures we get the correct constraints even after the component is unmounted
    const { min: refMinNum, max: refMaxNum } = constraintsRef.current;
    let submitNumber;
    if (roundedNumber < refMinNum) {
      submitNumber = refMinNum;
    } else if (roundedNumber > refMaxNum) {
      submitNumber = refMaxNum;
    } else {
      submitNumber = roundedNumber;
    }

    return submitNumber;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateValue(e.target.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key.toUpperCase() === 'ENTER') {
      return submitValue();
    }
  };

  const increment = (eventTrigger: string = INPUT_FIELD_TRIGGER) => {
    const stateNumber = toNumber(stateValue);
    const isFiniteNumber = Number.isFinite(stateNumber);
    if (isFiniteNumber) {
      const sanitizedNumber = sanitizeNumber(stateNumber + step);
      setStateValue(sanitizedNumber);

      if (eventTrigger !== INPUT_FIELD_TRIGGER && stateNumber !== sanitizedNumber) {
        onChange({ value: sanitizedNumber, eventTrigger });
      }
    }
  };

  const decrement = (eventTrigger: string = INPUT_FIELD_TRIGGER) => {
    const stateNumber = toNumber(stateValue);
    const isFiniteNumber = Number.isFinite(stateNumber);
    if (isFiniteNumber) {
      const sanitizedNumber = sanitizeNumber(stateNumber - step);
      setStateValue(sanitizedNumber);

      if (eventTrigger !== INPUT_FIELD_TRIGGER && stateNumber !== sanitizedNumber) {
        onChange({ value: sanitizedNumber, eventTrigger });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const stateNumber = toNumber(stateValue);
    const isFiniteNumber = Number.isFinite(stateNumber);

    if ((key.toUpperCase() === 'ARROWUP' || key.toUpperCase() === 'UP') && isFiniteNumber) {
      event.preventDefault();
      return increment();
    }

    if ((key.toUpperCase() === 'ARROWDOWN' || key.toUpperCase() === 'DOWN') && isFiniteNumber) {
      event.preventDefault();
      return decrement();
    }

    if (key.toUpperCase() === 'ENTER') {
      event.preventDefault();
      onBlur(event);
    }
  };

  const submitValue = () => {
    if (toNumber(stateValue) === value) return;

    const validNumber = validateNumberInput(stateValue);
    if (validNumber === false) {
      return resetField();
    }

    const submitNumber = sanitizeNumber(validNumber);
    setStateValue(submitNumber);
    onChange({ value: submitNumber, eventTrigger: INPUT_FIELD_TRIGGER });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event);
    submitValue();
  };

  const handleArrowButtonIncrement = () => {
    return increment(ARROWS_TRIGGER);
  };

  const handleArrowButtonDecrement = () => {
    return decrement(ARROWS_TRIGGER);
  };

  const inputFieldCssClassnames = classNames('EditPanelNumberInput__field', {
    'EditPanelNumberInput__field--withControls': showControls
  });

  const unitsCssClassnames = classNames('EditPanelNumberInput__units', {
    'EditPanelNumberInput__units--withControls': showControls
  });

  return (
    <div className={className ? `EditPanelNumberInput ${className}` : 'EditPanelNumberInput'}>
      {label && (
        <EditPanelLabel className="EditPanelNumberInput__label" htmlFor={id}>
          {label}
        </EditPanelLabel>
      )}
      <div className="EditPanelNumberInput__row">
        <input
          className={inputFieldCssClassnames}
          type="text"
          id={id}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          value={stateValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          inputMode={inputMode}
          disabled={disabled}
          aria-live="polite"
        />
        {units ? <span className={unitsCssClassnames}>{units}</span> : null}
        {showControls && (
          <NumberInputControls
            onIncrement={handleArrowButtonIncrement}
            onDecrement={handleArrowButtonDecrement}
            a11yIncrementLabel={`Increase value by ${step}`}
            a11yDecrementLabel={`Decrease value by ${step}`}
          />
        )}
      </div>
    </div>
  );
};

export default EditPanelNumberInput;
