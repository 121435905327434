import { DownloadTrackingProperties } from 'js/types';

export const ABORT_DOWNLOAD = 'ABORT_DOWNLOAD';

export type AbortDownloadAction = {
  type: typeof ABORT_DOWNLOAD;
  shortId: string;
  trackingProperties: DownloadTrackingProperties;
};

export const abortDownload = (
  shortId: string,
  trackingProperties: DownloadTrackingProperties
): AbortDownloadAction => ({
  type: ABORT_DOWNLOAD,
  shortId,
  trackingProperties
});
