import { put, call, delay, takeLeading } from 'redux-saga/effects';
import { validateToken } from 'js/actionCreators/authActions';
import { checkingUpgrade, checkingUpgradeComplete, CHECK_UPGRADE } from 'js/actionCreators/checkoutActions';
import { refreshUser, isUserInGroup } from 'js/shared/helpers/authHelper';
import { showError } from 'js/actionCreators/uiActions';
import { VIDEOSCRIBE_GROUPS } from 'js/config/consts';

import { clearUpgradedOnWebsiteStorage } from './sagaHelpers/websiteUpgrade';

export const POLL_INTERVAL = 3000;
export const RETRY_LIMIT = 20;
export const UPGRADE_CHECK_FAILED_ERROR_MESSAGE =
  'There seems to be a problem processing your upgrade. Please try logging out and in again. If the problem persists please contact customer support.';

export function* refreshAndCheckIsInRequiredGroup(newTier) {
  yield call(refreshUser);

  if (newTier) {
    const expectedGroupName = `videoscribe-${newTier.toLowerCase()}`;
    const inExpectedGroup = yield call(isUserInGroup, expectedGroupName);
    return inExpectedGroup;
  }

  const isPro = yield call(isUserInGroup, VIDEOSCRIBE_GROUPS.PRO);
  const isEdu = yield call(isUserInGroup, VIDEOSCRIBE_GROUPS.EDU);
  const isPlus = yield call(isUserInGroup, VIDEOSCRIBE_GROUPS.PLUS);
  const isLite = yield call(isUserInGroup, VIDEOSCRIBE_GROUPS.LITE);
  const inRequiredGroup = isPro || isEdu || isPlus || isLite;

  return inRequiredGroup;
}

export function* refreshCognitoUserOnUpgrade({ userId, newTier, isTeamsPurchase } = {}) {
  yield put(checkingUpgrade());
  let inRequiredGroup = yield call(refreshAndCheckIsInRequiredGroup, newTier);
  let retryCount = 1;

  // Poll cognito until user has been added to one of the groups
  while (!inRequiredGroup) {
    yield delay(POLL_INTERVAL);
    inRequiredGroup = yield call(refreshAndCheckIsInRequiredGroup, newTier);

    retryCount += 1;
    if (retryCount >= RETRY_LIMIT) {
      // This is to stop an infinite polling loop.
      // If we've got here something must have gone wrong with the upgrade
      return put(
        showError({
          message: UPGRADE_CHECK_FAILED_ERROR_MESSAGE
        })
      );
    }
  }

  clearUpgradedOnWebsiteStorage(userId);
  yield put(checkingUpgradeComplete({ isTeamsPurchase }));
  if (!isTeamsPurchase) {
    yield call(revalidateToken);
  }
}

export function* revalidateToken() {
  // Re authorize user to set up as a pro user
  yield put(validateToken({ showSpinner: true, userUpgraded: true }));
}

function* checkoutSagas() {
  yield takeLeading([CHECK_UPGRADE], refreshCognitoUserOnUpgrade);
}

export default checkoutSagas;
