import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { setAnimationDirection, setEmphasisAnimationDirection } from 'js/actionCreators/animationActions';
import { selectableEntranceAnimationOptions, selectableShakeAnimationOptions } from 'js/config/animationOptions';
import {
  getSelectedTweenPosition,
  getSelectedTweenEmphasisDirection
} from 'js/shared/helpers/getSelectedTweenPosition';
import { getStageTweenKey } from 'js/shared/helpers/getStageTweenKey';
import { REVERSE_ICON_LIST } from 'js/config/consts';
import { validateAllPositionsMatch } from 'js/shared/helpers/validateAllPositionsMatch';

import OptionMenuButton from './OptionMenuButton';

import './DirectionMenu.css';

const TOOLTIP_ID = 'direction-tooltip';

const DirectionMenu = ({ element, elements, scribe, onSelection, stageKey }) => {
  const dispatch = useDispatch();
  const activeScene = scribe.scenes.find(scene => scene.active);
  const tweenKey = getStageTweenKey(stageKey);

  const animationType = element !== undefined ? element[tweenKey].id : elements[0][tweenKey].id;

  const handleButtonClick = tweenPosition => {
    let elementIds;
    if (element !== undefined) {
      elementIds = [element.id];
    }

    if (elements !== undefined) {
      elementIds = elements.map(el => el.id);
    }

    dispatch(
      setAnimationDirection({
        animationType,
        stage: stageKey,
        elementIds,
        scribeId: scribe.id,
        newTweenPosition: tweenPosition
      })
    );

    onSelection();
  };

  const handleEmphasisButtonClick = tweenPosition => {
    let elementIds;
    if (element !== undefined) {
      elementIds = [element.id];
    }

    if (elements !== undefined) {
      elementIds = elements.map(el => el.id);
    }

    dispatch(
      setEmphasisAnimationDirection({
        animationType,
        stage: stageKey,
        elementIds,
        scribeId: scribe.id,
        newTweenPosition: tweenPosition
      })
    );

    onSelection();
  };

  let tweenPositionForSelection;

  if (tweenKey !== 'emphasisTween') {
    tweenPositionForSelection = getSelectedTweenPosition(element, elements, tweenKey);
  } else {
    tweenPositionForSelection = getSelectedTweenEmphasisDirection(element, elements, tweenKey);
  }

  const allPositionsMatch =
    tweenPositionForSelection !== undefined && tweenPositionForSelection.every(validateAllPositionsMatch);

  return (
    <div className="DirectionMenu">
      <div className="DirectionMenu__Grid">
        {tweenKey !== 'emphasisTween'
          ? selectableEntranceAnimationOptions.map(dir => {
              if (dir.label === 'Scene Thumbnail') {
                return (
                  <div key={dir.label} className="DirectionMenu__SceneContainer">
                    <img className="DirectionMenu__SceneTile" src={activeScene.thumbnailImage} alt={dir.label} />
                  </div>
                );
              }
              return (
                <OptionMenuButton
                  key={dir.label}
                  selected={allPositionsMatch && tweenPositionForSelection[0].position === dir.value}
                  onClick={() => handleButtonClick(dir.value)}
                  tooltipProps={{
                    'data-tip': dir.label,
                    'data-for': TOOLTIP_ID
                  }}
                >
                  {dir.Icon({ flipImage: REVERSE_ICON_LIST.includes(animationType) })}
                </OptionMenuButton>
              );
            })
          : selectableShakeAnimationOptions.map(dir => {
              if (dir.label === 'Scene Thumbnail') {
                return (
                  <div key={dir.label} className="DirectionMenu__SceneContainer">
                    <img className="DirectionMenu__SceneTile" src={activeScene.thumbnailImage} alt={dir.label} />
                  </div>
                );
              }
              return (
                <OptionMenuButton
                  key={dir.label}
                  selected={allPositionsMatch && tweenPositionForSelection[0].direction === dir.direction}
                  onClick={() => handleEmphasisButtonClick(dir.direction)}
                  tooltipProps={{
                    'data-tip': dir.label,
                    'data-for': TOOLTIP_ID
                  }}
                >
                  {dir.Icon({ flipImage: REVERSE_ICON_LIST.includes(animationType) })}
                </OptionMenuButton>
              );
            })}
      </div>
      <ReactTooltip id={TOOLTIP_ID} className="t-utility-label" />
    </div>
  );
};

export default DirectionMenu;
