import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

import { getDistanceBetweenPoints } from '../../../../shared/helpers/trig';

import generateScribblePathArray from './generateScribblePathArray';
import { generateScribbleFromHull } from './generateScribbleFromHull/generateScribbleFromHull';
import { generatePathFromSkeletons } from './generatePathFromSkeletons';
import { shouldIllustrateAnimation } from './drawAnimationType';

interface GenerateFallbackRevealPathOptions {
  element: ScribeImageElementModel;
  pixels: Uint8ClampedArray;
  dontIllustrate?: boolean;
}

export const generateFallbackRevealPath = async ({
  element,
  pixels,
  dontIllustrate = false
}: GenerateFallbackRevealPathOptions) => {
  const { width, height } = element;

  let scribbleArray;
  let brushSize;

  const shouldIllustrate = !dontIllustrate && shouldIllustrateAnimation(element);

  try {
    if (shouldIllustrate) {
      try {
        const commands = await generatePathFromSkeletons({ pixels, width, height, element });
        if (commands.length === 1 && commands[0].points.length <= 1) {
          throw new Error('Skeleton algorithm produced no points');
        }
        return commands;
      } catch (error) {
        console.error(error);
        const { scribbleArray: sa, brushSize: bs } = await generateScribbleFromHull(pixels, width, height, element);
        scribbleArray = sa;
        brushSize = bs;
      }
    } else {
      const { scribbleArray: sa, brushSize: bs } = await generateScribbleFromHull(pixels, width, height, element);
      scribbleArray = sa;
      brushSize = bs;
    }
  } catch (error) {
    console.error(error);
    const { scribbleArray: sa, brushSize: bs } = generateScribblePathArray(width, height, 10);
    scribbleArray = sa;
    brushSize = bs;
  }

  const points = scribbleArray.map((point, i, arr) => {
    if (i === 0) {
      return {
        ...point,
        moveTo: true,
        pathLength: 0
      };
    }

    return {
      ...point,
      pathLength: getDistanceBetweenPoints(arr[i - 1], point)
    };
  });

  const totalLength = points.reduce((acc, point) => {
    return acc + point.pathLength;
  }, 0);

  return [
    {
      points,
      totalLength,
      brushSize
    }
  ];
};
