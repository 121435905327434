import { useCallback } from 'react';
import { IMAGE_PROVIDER_TYPES } from 'js/config/consts';
import { canvasSizes } from 'js/config/defaults';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { getScribeById } from 'js/sagas/selectors';
import { useParams } from 'react-router-dom';
import { ImageSource } from 'js/types';
import { SearchType } from 'js/reducers/imageSearchReducer';

import { useLibraries } from '../../hooks/useLibraries';

import useGetSearchResult from './useGetSearchResult';

const useFormatAddImageData = (searchType: SearchType, searchInput: string, categoryId: string | null) => {
  const { scribeId } = useParams<{ scribeId: string }>();
  const activeScribe = useTypedSelector(state => getScribeById(state, parseInt(scribeId)));

  const { getExistingTabResult } = useGetSearchResult(searchType, searchInput, categoryId);

  const [_, libraries] = useLibraries();

  return useCallback(
    (selectedTab: ImageSource, imageId: string | number) => {
      const tabImages = getExistingTabResult(selectedTab)?.images ?? [];
      const image = tabImages.find(image => image.id === imageId);
      if (!image || !activeScribe) return;
      const contentType = image.contentType ?? 'image/*';
      const category = categoryId ? libraries.find(library => library.id === categoryId)?.title : undefined;
      const searchSource =
        selectedTab === 'videoscribe' ? IMAGE_PROVIDER_TYPES.LIBRARY : IMAGE_PROVIDER_TYPES.NOUN_PROJECT;
      return {
        imageId: image.id,
        contentType,
        scribeId: activeScribe.id,
        canvasSize: canvasSizes[activeScribe.canvasSize],
        category,
        filename: image.title,
        provider: searchSource,
        isPremium: image.isPremium
      };
    },
    [getExistingTabResult, activeScribe, categoryId, libraries]
  );
};

export default useFormatAddImageData;
