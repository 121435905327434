import { AutoSaveState } from 'js/types';
import sanitizeScribeForTransport from 'js/sagas/sagaHelpers/sanitizeScribeForTransport';
import {
  PICK_PENDING_AUTOSAVE,
  CREATE_AUTOSAVE_QUEUE,
  PickPendingAutosaveAction,
  CreateAutosaveQueueAction
} from 'js/actionCreators/autoSaveActions';

import {
  UpdateScribeFailedAction,
  UpdateScribeSuccessAction,
  UPDATE_SCRIBE_FAILED,
  UPDATE_SCRIBE_SUCCESS,
  UPDATE_SCRIBE,
  UpdateScribeAction
} from '../actionCreators/scribeActions';
import { RESET_STATE } from '../actionCreators/generalActions';

export const initialState: AutoSaveState = {
  autoSaveError: null,
  autoSaveStatus: 'Idle',
  pendingSaveQueue: {}
};

type AutoSaveAction =
  | { type: typeof RESET_STATE }
  | UpdateScribeFailedAction
  | UpdateScribeSuccessAction
  | UpdateScribeAction
  | PickPendingAutosaveAction
  | CreateAutosaveQueueAction;

export default function autoSaveReducer(state = initialState, action: AutoSaveAction): AutoSaveState {
  switch (action.type) {
    case CREATE_AUTOSAVE_QUEUE: {
      return {
        ...state,
        pendingSaveQueue: {
          ...state.pendingSaveQueue,
          [action.projectId]: {
            isSaving: false,
            pendingSave: null
          }
        }
      };
    }

    case PICK_PENDING_AUTOSAVE: {
      const { projectId } = action;
      return {
        ...state,
        pendingSaveQueue: {
          ...state.pendingSaveQueue,
          [projectId]: {
            isSaving: true,
            pendingSave: null
          }
        }
      };
    }

    case UPDATE_SCRIBE: {
      const project = sanitizeScribeForTransport(action.scribe);

      return {
        ...state,
        pendingSaveQueue: {
          ...state.pendingSaveQueue,
          [project.id]: {
            isSaving: true,
            pendingSave: project
          }
        }
      };
    }

    case UPDATE_SCRIBE_FAILED: {
      const { scribe } = action;
      const currentQueue = state.pendingSaveQueue[scribe.id];
      return {
        ...state,
        autoSaveError: action.error,
        autoSaveStatus: 'Error',
        pendingSaveQueue: {
          ...state.pendingSaveQueue,
          [scribe.id]: {
            isSaving: !!currentQueue.pendingSave,
            pendingSave: currentQueue.pendingSave
          }
        }
      };
    }

    case UPDATE_SCRIBE_SUCCESS: {
      const { scribe } = action;
      const currentQueue = state.pendingSaveQueue[scribe.projectDataId];
      return {
        ...state,
        autoSaveError: null,
        autoSaveStatus: 'Idle',
        pendingSaveQueue: {
          ...state.pendingSaveQueue,
          [scribe.projectDataId]: {
            isSaving: !!currentQueue.pendingSave,
            pendingSave: currentQueue.pendingSave
          }
        }
      };
    }

    case RESET_STATE: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}
