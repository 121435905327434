/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { ReactComponent as ChevronIcon } from 'imgs/svg-icons/IconChevron.svg';

import './NumberInputControls.css';

type NumberInputControlsProps = {
  disabled?: boolean;
  onIncrement: () => void;
  onDecrement: () => void;
  a11yIncrementLabel: string;
  a11yDecrementLabel: string;
};

const NumberInputControls = ({
  disabled = false,
  onIncrement,
  onDecrement,
  a11yIncrementLabel,
  a11yDecrementLabel
}: NumberInputControlsProps) => {
  const incrementValue = (event: React.MouseEvent) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    onIncrement();
  };

  const decrementValue = (event: React.MouseEvent) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    onDecrement();
  };

  return (
    <div className="NumberInputControls">
      <button
        id="increment-button"
        type="button"
        className="NumberInputControls__controlButton NumberInputControls__controlButton--up"
        onClick={incrementValue}
        disabled={disabled}
      >
        <span className="sr-only">{a11yIncrementLabel}</span>
        <ChevronIcon />
      </button>
      <button
        id="decrement-button"
        type="button"
        className="NumberInputControls__controlButton"
        onClick={decrementValue}
        disabled={disabled}
      >
        <span className="sr-only">{a11yDecrementLabel}</span>
        <ChevronIcon />
      </button>
    </div>
  );
};

export default NumberInputControls;
