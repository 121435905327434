import React from 'react';

const IconNoAnimation = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
    fill="currentcolor"
  >
    <path
      d="M29.4,15.3c-8.2,0-14.9,6.7-14.9,14.9c0,8.2,6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9C44.3,22,37.6,15.3,29.4,15.3z
M41.3,30.2c0,2.8-0.9,5.3-2.5,7.3L22.1,20.8c2-1.6,4.6-2.5,7.3-2.5C35.9,18.3,41.3,23.6,41.3,30.2z M17.4,30.2
c0-2.8,0.9-5.3,2.5-7.3l16.7,16.7c-2,1.6-4.6,2.5-7.3,2.5C22.8,42.1,17.4,36.8,17.4,30.2z"
    />
  </svg>
);

export default IconNoAnimation;
