import { EventEmitter } from '@pixi/utils';
export const WRAPPER_UPDATE_ZOOM = 'WRAPPER_UPDATE_ZOOM';
export const WRAPPER_UPDATE_SIZE = 'WRAPPER_UPDATE_SIZE';
export const WRAPPER_SCROLL_TO = 'WRAPPER_SCROLL_TO';
export const WRAPPER_SWAP_LAYERS = 'WRAPPER_SWAP_LAYERS';
export const WRAPPER_REBUILD_VIEW = 'WRAPPER_REBUILD_VIEW';
export const PLAYHEAD_CHANGE_HEIGHT = 'PLAYHEAD_CHANGE_HEIGHT';
export const TIMELINE_SELECT_SCENE = 'TIMELINE_SELECT_SCENE';
export const PLAYHEAD_SHOW = 'PLAYHEAD_SHOW';
export const PLAYHEAD_HIDE = 'PLAYHEAD_HIDE';
export const PLAYHEAD_MOVE = 'PLAYHEAD_MOVE';
export const TOOLTIP_HIDE = 'TOOLTIP_HIDE';
export const TOOLTIP_SHOW = 'TOOLTIP_SHOW';
export const ELEMENT_MOVED = 'ELEMENT_MOVED';
export const DRAG_STOPPED = 'DRAG_STOPPED';
export const LAYER_MOVE_BEGUN = 'LAYER_MOVE_BEGUN';
export const LAYER_MOVE_ENDED = 'LAYER_MOVE_ENDED';
export const LAYER_CLICKED = 'LAYER_CLICKED';
export const LAYER_DOUBLE_CLICKED = 'LAYER_DOUBLE_CLICKED';
export const HIDE_AUDIO_LENGTH_ADJUST_INDICATOR = 'HIDE_AUDIO_LENGTH_ADJUST_INDICATOR';
export const SHOW_AUDIO_LENGTH_ADJUST_INDICATOR = 'SHOW_AUDIO_LENGTH_ADJUST_INDICATOR';
export const UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR = 'UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR';
export const AUDIO_BACKGROUND_LAYER_CLICKED = 'AUDIO_BACKGROUND_LAYER_CLICKED';
export const UPDATE_AUDIO_DURATION = 'UPDATE_AUDIO_DURATION';
const timelineBus = new EventEmitter();
export default timelineBus;

export interface LayerZoomConfig {
  duration?: number;
  x?: number;
  y?: number;
}
