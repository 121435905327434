import * as PIXI from 'pixi.js';
import { VSImageShapeOrTextElementModel } from 'js/types';
import { getFontFace } from 'js/playback/lib/Playback/helpers/generateBitmapFonts';

import { deg2rad } from '../../../../shared/helpers/trig';

import getFontBaselineShiftPercentage from './fontBaselineShift';

const applyElementPositions = (
  stageElement: PIXI.Container,
  editorElement: VSImageShapeOrTextElementModel,
  scaleHolder: PIXI.Container | null
) => {
  const { flipX, flipY, type } = editorElement;
  const isText = type === 'Text';

  if (isText) {
    const container = new PIXI.Container();

    container.addChild(stageElement);
    const {
      fontWeight,
      fontStyle,
      font: { value: fontFamily }
    } = editorElement;
    const bitmapFontFace = getFontFace(fontFamily, fontStyle, fontWeight);
    const isInstalledBitmapFont =
      bitmapFontFace !== undefined && PIXI.BitmapFont.available[bitmapFontFace.name] !== undefined;
    const baselineShift = getFontBaselineShiftPercentage(editorElement.font.value, isInstalledBitmapFont);
    const shift = editorElement.scaleY * editorElement.fontSize * baselineShift;

    stageElement.y = stageElement.y - shift;

    container.x = editorElement.x;
    container.y = editorElement.y;

    container.rotation = deg2rad(editorElement.angle);
    return container;
  } else {
    if (!scaleHolder) return stageElement;

    const posHolder = scaleHolder.getChildAt(0);
    posHolder.scale.x = flipX ? -1 : 1;
    posHolder.scale.y = flipY ? -1 : 1;
    posHolder.pivot.x = flipX ? editorElement.width : 0;
    posHolder.pivot.y = flipY ? editorElement.height : 0;
    stageElement.x = editorElement.x;
    stageElement.y = editorElement.y;
    stageElement.scale.set(1);
    scaleHolder.scale = {
      x: editorElement.scaleX,
      y: editorElement.scaleY
    };

    scaleHolder.rotation = deg2rad(editorElement.angle);
    return stageElement;
  }
};

export default applyElementPositions;
