import * as PIXI from 'pixi.js';
import IconWarning from 'imgs/icons/IconWarning.svg';

import TimelineHelpers from './Utils/TimelineHelpers';
export default class WarningTriangle extends PIXI.Container {
  public warningIcon;

  constructor() {
    super();
    this.interactive = true;
    this.warningIcon = new PIXI.Sprite(TimelineHelpers.importSVG(IconWarning));
    if (this.warningIcon.texture.baseTexture.valid) {
      this.draw();
    } else {
      this.warningIcon.texture.once('update', () => {
        this.draw();
      });
    }
  }

  draw = () => {
    const graphics = new PIXI.Graphics();
    this.addChild(graphics);
    //draw black rounded rectangle
    graphics.beginFill(0x000000);
    graphics.drawRect(1, 1, 12, 12);
    graphics.endFill();
    this.warningIcon.width = this.warningIcon.height = 12;
    this.warningIcon.x = this.warningIcon.y = 1.5;
    this.addChild(this.warningIcon);
    this.y = 2;
  };
}
