import { fabric } from 'fabric';
import { DEFAULT_TEXT_ELEMENT_COPY } from 'js/config/consts';
import {
  DEFAULT_TEXT_COLOR,
  DEFAULT_TEXT_FONT_STYLE,
  DEFAULT_TEXT_FONT_WEIGHT,
  MAX_CHARS_IN_TEXT,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE,
  SCRIBE_TEXT_LINE_HEIGHT
} from 'js/config/defaults';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import getFontFamilyWithFallbackString from 'js/shared/helpers/getFontFamilyWithFallbackString';
import { elementSelectionPadding, minScaleValueAsPercentage } from 'js/shared/resources/scribedefaults';
import { ElementType, FontAttributes, TextAlignValue } from 'js/types';
import round from 'lodash.round';

import { applyBoundingBoxStyles, setGroupTransform } from './helpers/canvasElementHelpers';
import getLockedProperties from './helpers/getLockedProperties';
import getVisibleProperties from './helpers/getVisibleProperties';
import sanitizeAngle from './helpers/sanitizeAngle';

const getTextAlignValue = (align?: string): TextAlignValue => {
  if (!align) {
    return 'left';
  }
  const alignValues: Array<TextAlignValue> = ['left', 'center', 'right'];
  const validValue = alignValues.find(val => val === align);

  return validValue || 'left';
};

const getPositionalProperties = (element: ScribeTextElementModel) => ({
  scaleX: element.scaleX,
  scaleY: element.scaleY,
  top: element.y,
  left: element.x,
  angle: element.angle,
  width: element.width,
  height: element.height,
  padding: element.scaleX <= 0.1 ? 10 : elementSelectionPadding,
  snapAngle: 1
});

const getTextProperties = (element: ScribeTextElementModel, updateText = true) => {
  const textProperty = updateText ? { text: element.text ?? '' } : {};

  return {
    ...textProperty,
    fontFamily: getFontFamilyWithFallbackString(element.font.value),
    fontStyle: element.fontStyle,
    fontWeight: element.fontWeight,
    fontSize: element.fontSize,
    textAlign: element.align,
    lineHeight: SCRIBE_TEXT_LINE_HEIGHT
  };
};

const getStyleProperties = (element: ScribeTextElementModel) => ({
  opacity: element.opacity,
  minScaleLimit: minScaleValueAsPercentage,
  strokeWidth: 0,
  styles: element.styles
});

interface NewScribeTextElementModel extends ScribeTextElementModel {
  isNew?: boolean;
}

export default class TextElement extends fabric.IText {
  font: FontAttributes;
  id: string;
  locked: boolean;
  hidden: boolean;
  isNew: boolean;
  elementType: ElementType;
  element: ScribeTextElementModel;
  savedStyle?: string | undefined;

  constructor(props: NewScribeTextElementModel) {
    super(props.text || '', {
      ...getStyleProperties(props),
      ...getTextProperties(props),
      ...getPositionalProperties(props),
      ...getLockedProperties(props.locked || false),
      ...getVisibleProperties(props.locked, props.hidden)
    });
    this.id = props.id;
    this.font = props.font;

    this.locked = props.locked;
    this.hidden = props.hidden;
    this.elementType = 'Text';
    this.isNew = props.text === DEFAULT_TEXT_ELEMENT_COPY && !!props.isNew;
    props.isNew = false;
    this.element = props;
    this.setBoundingBoxStyles(props);
    this.element.charBounds = this.__charBounds;
    this.element.lineWidths = this.__lineWidths;

    this.on('editing:entered', this.handleEditingEntered);
    this.on('editing:exited', this.handleEditingExit);
    this.on('selection:changed', this.handleSelectionChanged);
    this.on('changed', this.handleChanged);
    this.on('changed', this.updateViewportScroll);

    this.on('added', () => {
      this.handleAddededOnNextAnimationFrame();
    });
  }

  public getCurrentSelectionIndex() {
    if (this.selectionStart === undefined || this.selectionEnd === undefined) return;
    let selectionStart: number;
    let selectionEnd: number;
    if (this.selectionStart === this.selectionEnd) {
      selectionStart = this.selectionStart;
      selectionEnd = this.selectionEnd;
    } else {
      // When selecting by double-clicking in the textbox
      // selectionEnd could be less than selectionStart
      selectionStart = Math.min(this.selectionStart, this.selectionEnd);
      selectionEnd = Math.max(this.selectionStart, this.selectionEnd);
    }
    return { start: selectionStart, end: selectionEnd };
  }

  private handleSelectionChanged() {
    this.setSelectionPositionAndStyles();
  }

  private setSelectionPositionAndStyles() {
    const currentSelectionIndex = this.getCurrentSelectionIndex();
    if (!currentSelectionIndex) return;

    const { start: startLineIndex, end: startCharIndex } = currentSelectionIndex;

    const hasNoSelectedChar = startLineIndex === startCharIndex;
    let charColor: string | undefined;
    if (hasNoSelectedChar) {
      charColor = this.getCurrentCharColor();
    } else {
      const selectedCharsColors = this.getSelectionStyles(startLineIndex, startCharIndex).reduce((acc, cur) => {
        if (cur.fill) acc.push(cur.fill);
        return acc;
      }, []) as string[];
      charColor = selectedCharsColors[0];
    }
    this.canvas?.onSetSelectedTextColor(charColor);
  }

  async handleAddededOnNextAnimationFrame() {
    // wait for the next animation frame to ensure the element is rendered
    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    wait(1).then(() => this.handleAdded());
  }

  private handleAdded(): void {
    if (this.isNew) {
      this.enterEditing();
      this.selectAll();
      this.isNew = false;
      this.setSelectionPositionAndStyles();
    }
  }

  private handleChanged() {
    if (this.text?.length === 1 && this.savedStyle && !this.styles.hasOwnProperty(0)) {
      this.styles[0] = JSON.parse(this.savedStyle);
      this.setSelectionStyles(this.styles);
    } else if (this.text !== '') {
      this.savedStyle = JSON.stringify(this.styles[0]);
    }
    this.setSelectionPositionAndStyles();
  }

  updateViewportScroll = () => {
    if (this.canvas && this.canvas?.viewportTransform) {
      const viewportBounderies = this.canvas.viewportBounds();
      const absoluteTextBoundingRect = this.getBoundingRect(true, true);

      const padding = 50;

      const { charIndex, lineIndex } = this.get2DCursorLocation(this.selectionStart, false);
      // get the caret position
      let caretPositionY = 0;
      let lastLineHeight = 0;
      const canvasZoom = this.canvas.getZoom();
      this.__lineHeights.forEach((lineHeight, index) => {
        if (index < lineIndex) {
          caretPositionY += lineHeight;
          lastLineHeight = lineHeight * canvasZoom;
        }
      });

      if (this.__charBounds && this.__charBounds.length > 0) {
        const caretPositionX = this.__charBounds[lineIndex][charIndex].left;

        const absoluteCarrotPoint = new fabric.Point(
          canvasZoom * (caretPositionX + absoluteTextBoundingRect.left),
          canvasZoom * (caretPositionY + absoluteTextBoundingRect.top)
        );

        // if the caret goes out of the viewport, we need to adjust the viewport
        const outOfBounds = {
          right: absoluteCarrotPoint.x > viewportBounderies.right - padding,
          left: absoluteCarrotPoint.x < viewportBounderies.left + padding,
          bottom: absoluteCarrotPoint.y + lastLineHeight > viewportBounderies.bottom - padding,
          top: absoluteCarrotPoint.y < viewportBounderies.top + padding
        };
        if (outOfBounds.right) {
          this.canvas.viewportTransform[4] -= absoluteCarrotPoint.x - (viewportBounderies.right - padding);
        }
        if (outOfBounds.left) {
          this.canvas.viewportTransform[4] -= absoluteCarrotPoint.x - (viewportBounderies.left + padding);
        }
        if (outOfBounds.top) {
          this.canvas.viewportTransform[5] -= absoluteCarrotPoint.y - (viewportBounderies.top + padding);
        }
        if (outOfBounds.bottom) {
          this.canvas.viewportTransform[5] -=
            absoluteCarrotPoint.y + lastLineHeight - (viewportBounderies.bottom - padding);
        }
      }
    }
  };

  private handleEditingEntered() {
    this.hiddenTextarea?.setAttribute('maxlength', MAX_CHARS_IN_TEXT.toString());
    if (this.canvas) {
      this.canvas.textElementEditing = this;
      this.setSelectionPositionAndStyles();
      this.canvas.handleSetIsTextboxEditing(true);
      if (this.styles.hasOwnProperty('0')) this.savedStyle = JSON.stringify(this.styles[0]);
    }
  }

  private handleEditingExit() {
    if (this.canvas) {
      // @ts-ignore Type is incorrect in fabric codebase, value can be set to null to disable style copying
      // https://github.com/fabricjs/fabric.js/blob/v5.3.0/src/mixins/itext_key_behavior.mixin.ts#L314
      fabric.copiedTextStyle = null;
      this.canvas.textElementEditing = undefined;
      this.canvas?.onSetSelectedTextColor(null);
      this.canvas.handleSetIsTextboxEditing(false);
    }
  }

  private updateFontSizeFromScale() {
    if (this.fontSize && this.scaleX) {
      this.fontSize *= this.scaleX;
      this.fontSize = round(this.fontSize);

      if (this.fontSize === 0) this.fontSize = MIN_FONT_SIZE;
      if (this.fontSize > MAX_FONT_SIZE) this.fontSize = MAX_FONT_SIZE;
      this.scaleX = 1;
      this.scaleY = 1;
    }
  }

  private setBoundingBoxStyles(props: ScribeTextElementModel) {
    applyBoundingBoxStyles(this, props);
  }

  public updateProps(props: ScribeTextElementModel) {
    this.font = props.font;
    this.locked = props.locked;
    this.hidden = props.hidden;

    const shouldUpdateText = !this.isEditing;

    const update = this.group
      ? {
          ...getStyleProperties(props),
          ...getTextProperties(props, shouldUpdateText),
          ...getLockedProperties(props.locked || false),
          ...getVisibleProperties(props.locked, props.hidden)
        }
      : {
          ...getStyleProperties(props),
          ...getPositionalProperties(props),
          ...getTextProperties(props, shouldUpdateText),
          ...getLockedProperties(props.locked || false),
          ...getVisibleProperties(props.locked, props.hidden)
        };

    this.set(update);
    this.element = props;
    this.setBoundingBoxStyles(props);
  }

  public toVscElement(): ScribeTextElementModel {
    const originalGroupValues = setGroupTransform(this);

    this.updateFontSizeFromScale();

    const fontStyle =
      this.fontStyle === 'italic' || this.fontStyle === 'normal' ? this.fontStyle : DEFAULT_TEXT_FONT_STYLE;
    const fontWeight =
      this.fontWeight === 'normal' || this.fontWeight === 'bold' ? this.fontWeight : DEFAULT_TEXT_FONT_WEIGHT;
    const fill = typeof this.fill === 'string' ? this.fill : DEFAULT_TEXT_COLOR;

    const payload = new ScribeTextElementModel({
      ...this.element,
      text: this.text,
      font: this.font,
      fontStyle,
      fontWeight,
      fontSize: this.fontSize,
      scaleX: this.scaleX ?? 1,
      scaleY: this.scaleY ?? 1,
      y: this.top ?? 0,
      x: this.left ?? 0,
      fill,
      opacity: this.opacity,
      angle: sanitizeAngle(this.angle),
      width: this.width ?? 1,
      height: this.height ?? 1,
      flipX: !!this.flipX,
      flipY: !!this.flipY,
      originX: this.originX ?? 'left',
      originY: this.originY ?? 'top',
      locked: this.locked,
      hidden: this.hidden,
      unlockedRatio: false,
      align: getTextAlignValue(this.textAlign),
      styles: this.styles
    });

    if (originalGroupValues) {
      // Styles property has two valid formats representation
      // Using toObject on canvas element would convert it to another format
      // Which could potentially cause the text colour to use fill colour
      // It happens when the text element is grouped with image element
      this.set({ ...originalGroupValues, styles: this.styles });
    }

    return payload;
  }

  copy() {
    super.copy();
    // Remove fontSize from copied text style to prevent it from being copied into text element styles
    // Types for fabric.copiedTextStyle are incorrect and it can be `null`
    fabric.copiedTextStyle?.forEach(value => {
      delete value.fontSize;
    });
  }

  destroy() {
    this.off('editing:entered', this.handleEditingEntered);
    this.off('changed', this.handleChanged);
    this.off('added', this.handleAdded);
    this.canvas?.remove(this);
  }
}
