import { call, CallEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { getScribeById } from 'js/sagas/selectors';
import {
  RENDER_FAILED,
  GET_VIDEO_DOWNLOAD_URL_SUCCESS,
  GET_VIDEO_DOWNLOAD_URL_FAILED,
  DOWNLOAD_REQUEST_FAILED,
  DOWNLOAD_REQUEST,
  ABORT_DOWNLOAD,
  RequestDownloadAction
} from 'js/actionCreators/downloadActions';
import { RENDERER_RATE_LIMIT_ERROR_STRING } from 'js/config/consts';
import getDownloadTrackingProperties from 'js/sagas/sagaHelpers/getDownloadTrackingProperties';
import { TRACK_DOWNLOAD_PROGRESS_HIDDEN } from 'js/actionCreators/trackingActions';
import { RootState } from 'js/types';
import { DownloadRequestFailedAction } from 'js/actionCreators/downloadRequestFailedAction';
import { GetVideoDownloadUrlFailedAction } from 'js/actionCreators/getVideoDownloadUrlFailedAction';
import { RenderFailedAction } from 'js/actionCreators/renderFailedAction';
import { GetVideoDownloadUrlSuccessAction } from 'js/actionCreators/getVideoDownloadUrlSuccessAction';
import { AbortDownloadAction } from 'js/actionCreators/abortDownloadAction';

import { track, incrementUserProperties, incrementEventSuperProperty } from '../mixpanelProvider';

export function* trackDownloadRequests({
  scribeId,
  format
}: RequestDownloadAction): Generator<SelectEffect | CallEffect, void, RootState> {
  const state = yield select();
  const scribe = getScribeById(state, scribeId);
  if (!scribe) return;

  const userId = state.auth.currentUser?.id;

  if (!userId) return;

  const eventProperties = yield call(() => getDownloadTrackingProperties(scribe, format, state.templates));

  yield call(track, 'Start Download', eventProperties);
  yield call(incrementUserProperties, 'Scribe Downloads Started');
}

function* trackRenderFailed({
  trackingProperties,
  error
}: DownloadRequestFailedAction | GetVideoDownloadUrlFailedAction | RenderFailedAction) {
  yield call(track, 'Download Failed', {
    ...trackingProperties,
    'Failure Reason': error?.message.includes(RENDERER_RATE_LIMIT_ERROR_STRING) ? 'Request Limit' : undefined
  });
  yield call(incrementUserProperties, 'Scribe Downloads Failed');
}

function* trackDownloadSuccess({ trackingProperties }: GetVideoDownloadUrlSuccessAction) {
  yield call(track, 'Download Succeeded', trackingProperties);
  yield call(incrementUserProperties, 'Scribe Downloads Succeeded');
  yield call(incrementEventSuperProperty, 'Scribe Downloads Succeeded');
}

function* trackAbortDownload({ trackingProperties }: AbortDownloadAction) {
  yield call(track, 'Abort Download', trackingProperties);
  yield call(incrementUserProperties, 'Scribe Downloads Aborted');
}

function* trackDownloadProgressHidden(): Generator<SelectEffect | CallEffect, void, RootState> {
  const trackingProperties = yield select(state => state.download.currentDownloadTrackingProperties);
  yield call(track, 'Hide Download Modal', trackingProperties);
}

export default function* trackDownloadRequestsSagas() {
  yield takeEvery(DOWNLOAD_REQUEST, trackDownloadRequests);
  yield takeEvery([RENDER_FAILED, GET_VIDEO_DOWNLOAD_URL_FAILED, DOWNLOAD_REQUEST_FAILED], trackRenderFailed);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, trackDownloadSuccess);
  yield takeEvery(ABORT_DOWNLOAD, trackAbortDownload);
  yield takeEvery(TRACK_DOWNLOAD_PROGRESS_HIDDEN, trackDownloadProgressHidden);
}
