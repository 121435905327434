import { SET_QUICK_IMAGE_SEARCH_TAG, SetQuickImageSearchTagAction } from 'js/actionCreators/ImageDrawerActions';
import {
  CLEAR_SEARCH_INPUT,
  RESET_SEARCH,
  ResetSearchAction,
  ClearSearchInputAction,
  SEARCH_IMAGES,
  SearchImagesAction,
  SET_SEARCH_INPUT,
  SET_SELECTED_CATEGORY,
  SetSearchInputAction,
  SetSelectedCategoryAction
} from 'js/actionCreators/imageSearchActions';

export enum SearchType {
  INPUT = 'search',
  CATEGORY = 'category'
}

export enum ImageLibraryStep {
  CATEGORIES,
  SEARCH_RESULT
}

export interface ImageSearchState {
  searchInput: string;
  submittedSearchInput: string;
  searchType: SearchType;
  selectedCategoryId: string | null;
  displayedStep: ImageLibraryStep;
}

const initialState: ImageSearchState = {
  searchInput: '',
  submittedSearchInput: '',
  searchType: SearchType.INPUT,
  selectedCategoryId: null,
  displayedStep: ImageLibraryStep.CATEGORIES
};

type ActionType =
  | SetSearchInputAction
  | ClearSearchInputAction
  | SearchImagesAction
  | SetSelectedCategoryAction
  | ResetSearchAction
  | SetQuickImageSearchTagAction;

export default function imageSearchReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_SEARCH_INPUT: {
      return {
        ...state,
        searchInput: action.searchInput
      };
    }

    case CLEAR_SEARCH_INPUT: {
      return {
        ...state,
        searchInput: ''
      };
    }

    case SEARCH_IMAGES: {
      return {
        ...state,
        submittedSearchInput: state.searchInput,
        searchType: SearchType.INPUT,
        selectedCategoryId: null,
        displayedStep: ImageLibraryStep.SEARCH_RESULT
      };
    }

    case SET_QUICK_IMAGE_SEARCH_TAG: {
      return {
        ...state,
        searchInput: action.tag,
        submittedSearchInput: action.tag,
        searchType: SearchType.INPUT,
        displayedStep: ImageLibraryStep.SEARCH_RESULT,
        selectedCategoryId: null
      };
    }

    case SET_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategoryId: action.selectedCategoryId,
        searchType: SearchType.CATEGORY,
        displayedStep: ImageLibraryStep.SEARCH_RESULT,
        searchInput: '',
        submittedSearchInput: ''
      };
    }

    case RESET_SEARCH: {
      return {
        ...state,
        selectedCategoryId: null,
        searchInput: '',
        submittedSearchInput: '',
        searchType: SearchType.INPUT,
        displayedStep: ImageLibraryStep.CATEGORIES,
        selectedTag: null
      };
    }

    default:
      return state;
  }
}
