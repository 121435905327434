import { CAMERA_EASING_EASEOUT } from 'js/config/consts';
import { ScribeCameraElement } from 'js/types';
import uuidv4 from 'uuid/v4';

export default function getNewCamera({
  id,
  locked,
  pauseTime,
  animationTime,
  scale,
  x,
  y,
  cameraPinned,
  easingType
}: Partial<ScribeCameraElement> = {}): ScribeCameraElement {
  const newCamera: ScribeCameraElement = {
    id: id ?? uuidv4(),
    locked: locked ?? false,
    pauseTime: pauseTime ?? 0,
    animationTime: animationTime ?? 1,
    scale: scale ?? 1,
    x: x ?? 0,
    y: y ?? 0,
    type: 'Camera',
    cameraPinned: cameraPinned ?? false,
    easingType: easingType ?? CAMERA_EASING_EASEOUT
  };
  return newCamera;
}
