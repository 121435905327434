export const getScribeLink = ({ id }, paths = []) => {
  const parts = ['editor', id];

  return (
    '/' +
    parts
      .concat(paths)
      .filter(str => str === 0 || str)
      .map(String)
      .join('/')
      .replace(/\/+/g, '/') +
    '/'
  );
};

export const getScribeLinkForUpgrade = ({ id }, paths = []) => {
  const parts = ['editor', id];

  return (
    '/' +
    parts
      .concat(paths)
      .filter(str => str === 0 || str)
      .map(String)
      .join('/')
      .replace(/\/+/g, '/')
  );
};
