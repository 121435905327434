import cookie from 'cookie';

const noop = () => {};

const cloudRendererLogger = window.CloudRendererLogger || noop;
const cloudRendererFatalFailure = window.CloudRendererFatalFailure || noop;

function getRendererUser() {
  return cookie.parse(document.cookie)['rendererUser'];
}

function isRendererCheck() {
  const rendererUser = getRendererUser();
  const isRenderer = !!rendererUser;

  return isRenderer;
}

export function rendererLog(...message) {
  const isRenderer = isRendererCheck();
  if (!isRenderer) return;

  cloudRendererLogger(...message);
  console.log(`[Renderer Log]`, ...message);
}

export function rendererFatalFailure(reason) {
  const isRenderer = isRendererCheck();
  if (!isRenderer) return;

  rendererLog(reason);
  cloudRendererFatalFailure(reason);
}
