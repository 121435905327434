export const getElementScales = (
  scaleX: number | undefined,
  scaleY: number | undefined,
  width: number | undefined,
  height: number | undefined
): { newScaleX: number; newScaleY: number } => {
  let minWidth = 1;
  let minHeight = 1;
  let minScaleX = 1;
  let minScaleY = 1;

  if (width && height && scaleX && scaleY) {
    let ratio;

    const scaledWidth = width * scaleX;
    const scaledHeight = height * scaleY;

    if (scaledWidth < scaledHeight) {
      ratio = scaledHeight / scaledWidth;

      minWidth = 1;
      minHeight = 1 * ratio;

      minScaleX = minWidth / width;
      minScaleY = minHeight / height;
    } else if (scaledHeight < scaledWidth) {
      ratio = scaledWidth / scaledHeight;

      minWidth = 1 * ratio;
      minHeight = 1;

      minScaleX = minWidth / width;
      minScaleY = minHeight / height;
    } else {
      minScaleX = 1 / width;
      minScaleY = 1 / height;
    }
  }

  const newScaleX = scaleX && scaleX < minScaleX ? minScaleX : (scaleX ?? 1);
  const newScaleY = scaleY && scaleY < minScaleY ? minScaleY : (scaleY ?? 1);

  return { newScaleX, newScaleY };
};
