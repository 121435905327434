import React from 'react';
import PropTypes from 'prop-types';

const IconError = ({ className, src }) => (
  <span className={`icon ${className}`} src={src}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      style={{ enableBackground: 'new 0 0 100 100' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M63,86.7c0,1.7-0.3,3.4-0.8,5.1c-0.6,1.7-1.7,2.8-2.8,4.2c-1.1,1.1-2.5,2-4.2,2.8c-1.7,0.6-3.4,1.1-5.1,1.1
      c-1.7,0-3.4-0.3-5.1-1.1c-1.7-0.6-3.1-1.7-4.2-2.8c-1.1-1.1-2.3-2.5-2.8-4.2c-0.6-1.7-0.8-3.4-0.8-5.1s0.3-3.4,0.8-5.1
      c0.6-1.7,1.7-2.8,2.8-4.2c1.1-1.1,2.5-2,4.2-2.8c1.7-0.6,3.1-1.1,5.1-1.1c1.7,0,3.4,0.3,5.1,1.1c1.7,0.6,2.8,1.7,4.2,2.8
      s2,2.5,2.8,4.2C62.4,83.3,63,85,63,86.7z M59.3,65.8H40.4L37.9,0h24.3L59.3,65.8z"
        />
      </g>
    </svg>
  </span>
);

IconError.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export default IconError;
