import {
  FETCH_SCRIPT_FAILED,
  FETCH_SCRIPT_REQUESTED,
  FETCH_SCRIPT_SUCCEEDED,
  FetchScriptFailedAction,
  FetchScriptRequestedAction,
  FetchScriptSucceededAction,
  SYNC_SCRIPT,
  SyncScriptAction
} from 'js/actionCreators/projectScriptActions';
import { DELETE_SCRIBE_SUCCESS, DeleteScribeSuccessAction } from 'js/actionCreators/scribeActions';

export interface ProjectScriptsState {
  loading: boolean;
  projectScripts: {
    [projectId: number]: string;
  };
}

const initialState: ProjectScriptsState = {
  loading: false,
  projectScripts: {}
};

type ProjectScriptsAction =
  | SyncScriptAction
  | FetchScriptRequestedAction
  | FetchScriptSucceededAction
  | FetchScriptFailedAction
  | DeleteScribeSuccessAction;

export default function projectScriptsReducer(state = initialState, action: ProjectScriptsAction): ProjectScriptsState {
  switch (action.type) {
    case SYNC_SCRIPT: {
      const { projectId, script } = action;
      return {
        ...state,
        projectScripts: {
          ...state.projectScripts,
          [projectId]: script
        }
      };
    }

    case FETCH_SCRIPT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_SCRIPT_SUCCEEDED: {
      const { projectId, script } = action;
      return {
        ...state,
        loading: false,
        projectScripts: {
          ...state.projectScripts,
          [projectId]: script
        }
      };
    }

    case FETCH_SCRIPT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }

    case DELETE_SCRIBE_SUCCESS: {
      const scripts = { ...state.projectScripts };
      delete scripts[action.scribeId];
      return {
        ...state,
        projectScripts: scripts
      };
    }

    default:
      return state;
  }
}
