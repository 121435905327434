import { TemplateState } from 'js/types';

export const getTemplateCategory = (templates: TemplateState, templateId?: string) => {
  if (!templateId) {
    return undefined;
  }

  const templateCategory = templates.templatesByCategory.reduce<string | undefined>((title, category) => {
    if (title) {
      return title;
    }

    const templateInCategory = category.templates.find(template => template.id === templateId) !== undefined;

    return templateInCategory ? category.title : undefined;
  }, undefined);

  return templateCategory;
};
