import {
  DUPLICATE_SCRIBE,
  DuplicateScribeAction,
  duplicateScribeFailed,
  duplicateScribeStart,
  duplicateScribeSuccess
} from 'js/actionCreators/duplicateScribeActions';
import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { duplicateProject } from 'js/shared/helpers/app-services/AppServices/projects';
import { CanvasSize } from 'js/types';
import { CallEffect, PutEffect, call, put, takeEvery } from 'redux-saga/effects';
import uuid from 'uuid/v4';

interface ServicesTrimmedProject {
  id: number;
  title: string;
  createdDate: string | number;
  updatedDate: string | number;
  canvasSize: CanvasSize;
  thumbnail?: string;
  viewOnly?: boolean;
}

export function* duplicateScribe({
  scribeId,
  title,
  createdDate
}: DuplicateScribeAction): Generator<CallEffect | PutEffect, void, ServicesTrimmedProject> {
  const tempLoadingId = uuid();
  try {
    const newTitle = `${title} (Copy)`;

    yield put(
      duplicateScribeStart({
        title: newTitle,
        createdOn: createdDate,
        updatedOn: createdDate,
        loadingId: tempLoadingId,
        status: 'loading'
      })
    );

    const duplicatedProject = yield call(duplicateProject, scribeId, appServices.exchangeCognitoTokenForSparkolToken);

    const trimmedProjectToAdd = {
      id: duplicatedProject.id,
      title: duplicatedProject.title,
      createdOn: new Date(duplicatedProject.createdDate),
      updatedOn: new Date(duplicatedProject.updatedDate ?? duplicatedProject.createdDate),
      canvasSize: duplicatedProject.canvasSize,
      thumbnailImage: duplicatedProject.thumbnail,
      viewOnly: duplicatedProject.viewOnly
    };

    yield put(duplicateScribeSuccess(trimmedProjectToAdd, scribeId, tempLoadingId));
  } catch (error) {
    if (error instanceof Error) {
      yield put(duplicateScribeFailed(tempLoadingId));
      yield put(
        showError({
          message: `Duplicating Project ${scribeId} failed`,
          description: error.message
        })
      );
    }
  }
}

export default function* duplicateScribeSagas() {
  yield takeEvery(DUPLICATE_SCRIBE, duplicateScribe);
}
