import ArabicReshaper from 'arabic-reshaper';
import direction from 'direction';
import {
  DRAW_KEY,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  HAND_DRAW_KEY,
  PEN_DRAW_KEY
} from 'js/config/consts';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { generateCharacterMaskPath } from 'js/shared/helpers/generateCharacterMaskPath';
import getFontFamilyWithFallbackString from 'js/shared/helpers/getFontFamilyWithFallbackString';
import shouldTextUseFallbackDrawing from 'js/shared/helpers/fallbackDrawingChecker';
import rgbToInteger from 'js/shared/helpers/rgbToInteger';
import { VSCoordinateWithBrushSize, CustomisableTextElementProps } from 'js/types';
import * as PIXI from 'pixi.js';
import { splitGraphemes } from 'split-graphemes';
import { ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY } from 'js/config/animationOptions';
import { getFontFace } from 'js/playback/lib/Playback/helpers/generateBitmapFonts';
import { getMemoryCache } from 'js/playback/lib/Playback/helpers/generateBitmapFontsCache';

import { MASK_LINE_COLOR, SCRIBE_TEXT_LINE_HEIGHT, SCRIBE_TEXT_MULT } from '../../../../config/defaults';
import applyElementPositions from '../helpers/applyElementPositions';
import { debugPathing } from '../helpers/debugPathing';

import AnimatableElement from './AnimatableElement';
import VSScene from './VSScene';

const lineHeightForFontSize = (fontSize: number) =>
  SCRIBE_TEXT_LINE_HEIGHT * (parseInt(fontSize.toString(), 10) * SCRIBE_TEXT_MULT);
const projectedFontSize = (fontSize: number, scale: number) => fontSize * scale;

export const adjustForJustification = (
  align: string,
  leftOffset: number,
  maxLineWidth: number,
  currentLineWidth: number
) => {
  switch (align) {
    case 'left':
      return leftOffset;
    case 'right':
      return maxLineWidth - currentLineWidth + leftOffset;
    case 'center':
    default:
      return (maxLineWidth - currentLineWidth) / 2 + leftOffset;
  }
};

const shouldGenerateMaskPath = (element: ScribeTextElementModel): boolean => {
  const hasTween = !!element[ENTRANCE_TWEEN_DOCUMENT_KEY];

  if (hasTween) return false;

  const hasDefaultOrSetAnimationTime = element[ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY] !== 0;
  const entranceAnimationConfig = element[ENTRANCE_ANIMATION_DOCUMENT_KEY];

  const isDefaultAnimationWithDefaultOrSetTime = !entranceAnimationConfig && hasDefaultOrSetAnimationTime;

  const isDrawingAnimationTypeWithDefaultOrSetTime =
    !!entranceAnimationConfig &&
    [DRAW_KEY, PEN_DRAW_KEY, HAND_DRAW_KEY].includes(entranceAnimationConfig.id) &&
    hasDefaultOrSetAnimationTime;

  return isDefaultAnimationWithDefaultOrSetTime || isDrawingAnimationTypeWithDefaultOrSetTime;
};

interface VSTextElementProps {
  animationTime: number;
  emphasisAnimationTime: number;
  emphasisAnimationLoops: number;
  exitAnimationTime: number;
  pauseTime: number;
  timings: {
    startTimeMs: number;
    endTimeMs: number;
    scribeTotalLengthMs: number;
  };
  element: ScribeTextElementModel;
  playerRef: PIXI.Application;
  scene: VSScene;
}

interface VSTextElementConstructorProps extends Omit<VSTextElementProps, 'renderer'> {
  style: PIXI.TextStyle;
  letters: Array<Letter>;
  displayStack: Array<PIXI.Container>;
}

class MaskableText extends PIXI.Text {
  maskPath!: Array<VSCoordinateWithBrushSize>;
}

class MaskableBitmapText extends PIXI.BitmapText {
  maskPath!: Array<VSCoordinateWithBrushSize>;
}

interface Letter {
  character: MaskableText | MaskableBitmapText;
  mask: PIXI.Graphics;
}

type GetCharTextProps = {
  element: ScribeTextElementModel;
  char: string;
  style: PIXI.TextStyle;
  charStyles: CustomisableTextElementProps | undefined;
  withMask: boolean;
} & (
  | {
      isBitmapFont: false;
    }
  | {
      isBitmapFont: true;
      fontFaceName: string;
      installedFont: PIXI.BitmapFont;
    }
);

export default class VSTextElement extends AnimatableElement {
  style: PIXI.TextStyle;
  displayStack: Array<PIXI.Container>;
  letters: Array<Letter>;
  percentPerLetter: number;
  stageElement: PIXI.Container;

  constructor({
    element,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    timings,
    playerRef,
    style,
    letters,
    displayStack,
    scene
  }: VSTextElementConstructorProps) {
    super({
      element,
      playerRef,
      animationTime,
      emphasisAnimationTime,
      emphasisAnimationLoops,
      exitAnimationTime,
      pauseTime,
      timings,
      scene
    });
    if (!element) {
      throw new Error('No element object supplied to VSTextElement');
    }
    this.style = style;
    this.displayStack = displayStack;

    this.letters = letters;

    this.percentPerLetter = 1 / this.letters.length;
    this.displayStack.push(this.cursor);
    this.stageElement = this.createStageElement(this.displayStack, element);

    this.stageElement.name = element.id;
    if (element[ENTRANCE_TWEEN_DOCUMENT_KEY]) {
      this.clearMasksFromStage();
    }
  }

  static async build({
    element,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    timings,
    playerRef,
    scene
  }: VSTextElementProps) {
    const styleConfig = {
      fontFamily: getFontFamilyWithFallbackString(element.font.value),
      fill: element.fill,
      fontSize: element.fontSize * element.scaleX,
      align: element.align,
      strokeThickness: 0,
      fontStyle: element.fontStyle,
      padding: 100,
      fontWeight: element.fontWeight
    };
    const hasCharBoundsData = element.charBounds && element.charBounds.length > 0;
    const hasCustomStylesData = element.styles && Object.keys(element.styles).length > 0;
    const hasLineWidthsData = element.lineWidths && element.lineWidths.length > 0;
    const isTextRTL = direction(element.text ?? '') === 'rtl';
    const isScriptNotSupported = shouldTextUseFallbackDrawing(element.text ?? '');
    const isMissingCustomTextData = !hasCharBoundsData || !hasCustomStylesData || !hasLineWidthsData;
    const shouldUseFallbackDrawing = isMissingCustomTextData || isScriptNotSupported;

    const defaultStyle = new PIXI.TextStyle(styleConfig);
    const lineHeight = lineHeightForFontSize(projectedFontSize(element.fontSize, element.scaleX));

    let maxLineWidth: number;
    let lineWidths: Array<number>;
    if (shouldUseFallbackDrawing) {
      const textMetrics = PIXI.TextMetrics.measureText(element.text ?? '', defaultStyle);
      maxLineWidth = textMetrics.maxLineWidth;
      lineWidths = textMetrics.lineWidths;
    } else {
      lineWidths = element.lineWidths ?? [];
      maxLineWidth = Math.max(...lineWidths);
    }
    const bitmapFontFace = getFontFace(element.font.value, element.fontStyle, element.fontWeight);
    const bitmapSize = bitmapFontFace ? getMemoryCache().get(bitmapFontFace.name) : undefined;
    const font = bitmapFontFace ? PIXI.BitmapFont.available[bitmapFontFace.name] : undefined;
    const isInstalledBitmapFont = bitmapFontFace !== undefined && bitmapSize !== undefined && font !== undefined;

    const lines = (element.text ?? '').split('\n');
    let letters: Array<Letter> = [];
    let lineIndex = 0;
    const displayStack = [];
    const enableGenerateMaskPath = shouldGenerateMaskPath(element);
    for (const inputLine of lines) {
      const lineArray: {
        character: MaskableText;
        mask: PIXI.Graphics;
      }[] = [];
      const line = isTextRTL ? ArabicReshaper.convertArabic(inputLine) : inputLine;

      const graphemes: Array<string> = splitGraphemes(line);
      if (isTextRTL) graphemes.reverse();

      const letterY = lineHeight * lineIndex;
      let letterX = 0;
      for (let graphemeIndex = 0; graphemeIndex < graphemes.length; graphemeIndex++) {
        let style = defaultStyle;
        const charStyles = element.styles?.[lineIndex]?.[graphemeIndex];
        if (!isMissingCustomTextData && charStyles && !isInstalledBitmapFont) {
          style = new PIXI.TextStyle({
            ...styleConfig,
            ...charStyles
          });
        }
        const visibleChar = graphemes[graphemeIndex];
        const lineMetrics = PIXI.TextMetrics.measureText(graphemes.slice(0, graphemeIndex + 1).join(''), style);
        if (visibleChar === ' ') {
          letterX = lineMetrics.width;
          continue;
        }

        const singleChar = this.getCharText({
          element,
          char: visibleChar,
          style,
          charStyles,
          withMask: enableGenerateMaskPath,
          ...(isInstalledBitmapFont
            ? {
                isBitmapFont: true,
                fontFaceName: bitmapFontFace.name,
                installedFont: font
              }
            : { isBitmapFont: false })
        });

        if (enableGenerateMaskPath) {
          singleChar.resolution = 2;
          let yOffset = 0;
          let xOffset = 0;
          if (isInstalledBitmapFont && singleChar instanceof PIXI.BitmapText) {
            const char = font.chars[visibleChar.charCodeAt(0)];
            const fontSize = singleChar.fontSize;
            const scale = fontSize / bitmapSize;

            // offsets for current font size
            xOffset = Math.floor((char?.xOffset ?? 0) * scale);
            yOffset = Math.floor((char?.yOffset ?? 0) * scale);
          }
          const characterShapeMaskPaths = await generateCharacterMaskPath(
            singleChar,
            lineHeight,
            isTextRTL,
            element.font.value,
            element.fontWeight,
            element.fontStyle,
            element.align,
            element.fontSize,
            xOffset,
            yOffset
          );

          const coordinatesWithBrushSizes = characterShapeMaskPaths.flatMap(csmp =>
            csmp.maskPath.map(mp => ({ ...mp, brushSize: csmp.brushSize }))
          );

          if (import.meta.env.DEV && import.meta.env.VITE_DEBUG_PATHING_ALGORITHM) {
            // Wrapping this in the logic with import.meta.env.DEV means this entire block of code and dependency module will
            // be remove in production builds. Dead code elimination FTW!
            debugPathing(coordinatesWithBrushSizes);
          }

          (singleChar as MaskableText).maskPath = coordinatesWithBrushSizes;
        }

        const charBounds = element.charBounds?.[lineIndex]?.[graphemeIndex];
        const charLeftOffset = shouldUseFallbackDrawing || !charBounds ? letterX : charBounds.left;
        singleChar.x = adjustForJustification(element.align, charLeftOffset, maxLineWidth, lineWidths[lineIndex]);
        singleChar.y = letterY;

        if (enableGenerateMaskPath) {
          const letterMask = new PIXI.Graphics();
          singleChar.addChild(letterMask);
          lineArray.push({ character: singleChar as MaskableText, mask: letterMask });
        }
        displayStack.push(singleChar);

        //some fonts lose their ligatures on being split, making a simple test of line width unreliable, this code compares line widths of subsequent chars to find the char position
        if (graphemeIndex + 2 <= graphemes.length) {
          const withNextCharMet = PIXI.TextMetrics.measureText(graphemes.slice(0, graphemeIndex + 2).join(''), style);
          const nextCharSingleMet = PIXI.TextMetrics.measureText(
            graphemes.slice(graphemeIndex + 1, graphemeIndex + 2).join(''),
            style
          );
          letterX = withNextCharMet.width - nextCharSingleMet.width;
        } else {
          letterX = lineMetrics.width;
        }
      }

      if (isTextRTL) {
        lineArray.reverse();
      }

      letters = letters.concat(lineArray);
      lineIndex++;
    }

    return new VSTextElement({
      element,
      animationTime,
      emphasisAnimationTime,
      emphasisAnimationLoops,
      exitAnimationTime,
      pauseTime,
      timings,
      playerRef,
      style: defaultStyle,
      letters,
      displayStack,
      scene
    });
  }

  static getCharText(props: GetCharTextProps): MaskableText | MaskableBitmapText | PIXI.Text | PIXI.BitmapText {
    const { element, char, style, charStyles, withMask, isBitmapFont } = props;
    if (!isBitmapFont) return withMask ? new MaskableText(char, style) : new PIXI.Text(char, style);
    const bitmapChar = props.installedFont.chars[char.charCodeAt(0)];
    if (bitmapChar === undefined) return withMask ? new MaskableText(char, style) : new PIXI.Text(char, style);
    const tint = charStyles?.fill ? parseInt(charStyles.fill.slice(1), 16) : rgbToInteger(style.fill as string);
    const bitmapStyle = {
      fontName: props.fontFaceName,
      fontSize: element.fontSize * element.scaleX,
      tint
    };
    return withMask ? new MaskableBitmapText(char, bitmapStyle) : new PIXI.BitmapText(char, bitmapStyle);
  }

  clearMasksFromStage() {
    super.clearMasksFromStage();
    this.clearCursorFromCanvas();
    this.fillRevealMasks();
  }

  createStageElement = (children: Array<PIXI.Container>, editorElement: ScribeTextElementModel) => {
    const groupedImage = new PIXI.Container();
    groupedImage.alpha = editorElement.opacity ?? 1;
    groupedImage.addChild(...children);

    const width = editorElement.width * editorElement.scaleX;
    const height = editorElement.height * editorElement.scaleY;

    const stageElement = applyElementPositions(
      this.setUpNestedClips(groupedImage, width, height),
      editorElement,
      this.scaleHolder
    );
    return stageElement;
  };

  totallyRevealCharacter = (singleChar: Letter) => {
    singleChar.character.visible = true;
    singleChar.character.mask = null;
    singleChar.mask.visible = false;
  };

  totallyHideCharacter = (singleChar: Letter) => {
    singleChar.character.visible = false;
    singleChar.mask.visible = false;
  };

  setupCharacterMaskForPartialAnimation = (singleChar: Letter) => {
    singleChar.character.mask = singleChar.mask;
    singleChar.character.visible = true;
    singleChar.character.mask.visible = true;
    singleChar.mask.clear();
  };

  fillRevealMasks = () => {
    this.letters.forEach(singleChar => this.totallyRevealCharacter(singleChar));
  };

  revealAnimation(percentProgress: number) {
    const shouldAllLettersBeRevealed = !this.shouldAnimateReveal || percentProgress >= 1;
    if (shouldAllLettersBeRevealed) {
      this.fillRevealMasks();
      return;
    }

    const targetCharacterInThisLoop = Math.floor(this.letters.length * percentProgress);

    const shouldAllLettersBeHidden = percentProgress <= 0 && this.animationTime > 0;
    if (shouldAllLettersBeHidden) {
      this.letters.forEach(singleChar => {
        this.totallyHideCharacter(singleChar);
      });
      return;
    }

    this.letters.forEach((singleChar, i) => {
      const characterIsBlank = singleChar.character.text === undefined;
      const characterIsInFuture = i > targetCharacterInThisLoop;

      if (characterIsBlank || characterIsInFuture) {
        this.totallyHideCharacter(singleChar);
        return;
      }

      const characterIsInPast = i < targetCharacterInThisLoop;
      if (characterIsInPast) {
        this.totallyRevealCharacter(singleChar);

        const lastMaskPathPoint = singleChar.character.maskPath?.at(-1);
        this.cursor.x = singleChar.character.x + (lastMaskPathPoint?.x ?? 0);
        this.cursor.y = singleChar.character.y + (lastMaskPathPoint?.y ?? 0);

        return;
      }

      this.setupCharacterMaskForPartialAnimation(singleChar);

      const progressThroughCurrentChar = Math.ceil(
        singleChar.character.maskPath.length * ((percentProgress - i * this.percentPerLetter) / this.percentPerLetter)
      );
      // Never go over the length of the array if floating point calculations result in a number over 1, e.g 1.0000000000004
      const clampedProgressThroughCurrentChar = Math.min(
        singleChar.character.maskPath.length,
        progressThroughCurrentChar
      );

      for (let maskPathIndex = 0; maskPathIndex < clampedProgressThroughCurrentChar; maskPathIndex++) {
        const point = singleChar.character.maskPath[maskPathIndex];
        const mask = singleChar.character.mask as PIXI.Graphics;
        mask.beginTextureFill({
          color: MASK_LINE_COLOR
        });
        mask.drawCircle(point.x, point.y, point.brushSize / 2);
        mask.endFill();

        this.cursor.x = singleChar.character.x + point.x;
        this.cursor.y = singleChar.character.y + point.y;
      }
    });

    return this.cursor.getGlobalPosition();
  }
}
