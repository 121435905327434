import { TrackingState } from 'js/types';

import {
  TRACK_TEMPLATE_PREVIEW_CLICK,
  TrackTemplatePreviewClickAction,
  LEAVE_TEMPLATE_PREVIEW,
  LeaveTemplatePreviewAction
} from '../actionCreators/trackingActions';

const initialState: TrackingState = {
  category: null
};

type TrackingAction = TrackTemplatePreviewClickAction | LeaveTemplatePreviewAction;

export default function trackingReducer(state = initialState, action: TrackingAction): TrackingState {
  switch (action.type) {
    case TRACK_TEMPLATE_PREVIEW_CLICK: {
      return {
        ...initialState,
        category: action.category
      };
    }

    case LEAVE_TEMPLATE_PREVIEW: {
      return {
        ...initialState,
        category: null
      };
    }

    default:
      return state;
  }
}
