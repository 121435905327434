import {
  CHANGE_IMAGE_DRAWER_TAB,
  ChangeImageDrawerTabAction,
  SetQuickImageSearchTagAction,
  GET_CATEGORY_IMAGES,
  GetCategoryImagesAction,
  GET_CATEGORY_IMAGES_SUCCESS,
  GetCategoryImagesSuccessAction,
  GET_CATEGORY_IMAGES_FAILURE,
  GetCategoryImagesFailureAction,
  GET_SEARCH_IMAGES,
  GetSearchImagesAction,
  GET_SEARCH_IMAGES_SUCCESS,
  GetSearchImagesSuccessAction,
  GET_SEARCH_IMAGES_FAILURE,
  GetSearchImagesFailureAction,
  UPDATE_SEARCH_TAB_VIEWED,
  UpdateSearchTabViewedAction
} from 'js/actionCreators/ImageDrawerActions';
import { SET_SELECTED_CATEGORY, SetSelectedCategoryAction } from 'js/actionCreators/imageSearchActions';
import { ImageDrawerState, ImageDrawerTab } from 'js/types';

type ImageDrawerActionTypes =
  | ChangeImageDrawerTabAction
  | GetCategoryImagesAction
  | GetCategoryImagesSuccessAction
  | GetCategoryImagesFailureAction
  | GetSearchImagesAction
  | GetSearchImagesSuccessAction
  | GetSearchImagesFailureAction
  | SetQuickImageSearchTagAction
  | UpdateSearchTabViewedAction
  | SetSelectedCategoryAction;

export const initialState: ImageDrawerState = {
  panelTab: ImageDrawerTab.IMAGE_LIBRARY,
  imageLibrary: {
    category: {
      videoscribe: {},
      'noun-project': {}
    },
    search: {
      videoscribe: {},
      'noun-project': {}
    }
  }
};

export default function imageDrawerReducer(state = initialState, action: ImageDrawerActionTypes): ImageDrawerState {
  switch (action.type) {
    case CHANGE_IMAGE_DRAWER_TAB: {
      return {
        ...state,
        panelTab: action.tab
      };
    }

    case SET_SELECTED_CATEGORY: {
      return {
        ...state,
        panelTab: ImageDrawerTab.IMAGE_LIBRARY
      };
    }

    case GET_CATEGORY_IMAGES: {
      const { imageSource, categoryId } = action;
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          category: {
            ...state.imageLibrary.category,
            [imageSource]: {
              ...state.imageLibrary.category[imageSource],
              [categoryId]: {
                ...(state.imageLibrary.category[imageSource][categoryId] || {}),
                loading: true
              }
            }
          }
        }
      };
    }

    case GET_CATEGORY_IMAGES_SUCCESS: {
      const { categoryId, imageSource, images, nextPageId } = action;
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          category: {
            ...state.imageLibrary.category,
            [imageSource]: {
              ...state.imageLibrary.category[imageSource],
              [categoryId]: {
                loading: false,
                images: [...(state.imageLibrary.category[imageSource][categoryId]?.images || []), ...images],
                nextPageId
              }
            }
          }
        }
      };
    }

    case GET_CATEGORY_IMAGES_FAILURE: {
      const { categoryId, imageSource } = action;
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          category: {
            ...state.imageLibrary.category,
            [imageSource]: {
              ...state.imageLibrary.category[imageSource],
              [categoryId]: {
                ...(state.imageLibrary.category[imageSource][categoryId] || {}),
                loading: false
              }
            }
          }
        }
      };
    }

    case GET_SEARCH_IMAGES: {
      const { imageSource, searchInput } = action;
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          search: {
            ...state.imageLibrary.search,
            [imageSource]: {
              ...state.imageLibrary.search[imageSource],
              [searchInput]: {
                ...(state.imageLibrary.search[imageSource][searchInput] || {}),
                loading: true
              }
            }
          }
        }
      };
    }

    case GET_SEARCH_IMAGES_SUCCESS: {
      const { imageSource, searchInput, images, nextPageId, offset, total, isPrefetch } = action;
      const existingImages = state.imageLibrary.search[imageSource][searchInput]?.images || [];
      const newImages = images.filter(
        newImage => !existingImages.some(existingImage => existingImage.id === newImage.id)
      );
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          search: {
            ...state.imageLibrary.search,
            [imageSource]: {
              ...state.imageLibrary.search[imageSource],
              [searchInput]: {
                loading: false,
                images: [...existingImages, ...newImages],
                nextPageId,
                offset: Math.min(offset, total),
                total,
                isViewed: !isPrefetch
              }
            }
          }
        }
      };
    }

    case GET_SEARCH_IMAGES_FAILURE: {
      const { imageSource, searchInput } = action;
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          search: {
            ...state.imageLibrary.search,
            [imageSource]: {
              ...state.imageLibrary.search[imageSource],
              [searchInput]: {
                ...(state.imageLibrary.search[imageSource][searchInput] || {}),
                loading: false
              }
            }
          }
        }
      };
    }

    case UPDATE_SEARCH_TAB_VIEWED: {
      const { imageSource, searchInput, isViewed } = action;
      return {
        ...state,
        imageLibrary: {
          ...state.imageLibrary,
          search: {
            ...state.imageLibrary.search,
            [imageSource]: {
              ...state.imageLibrary.search[imageSource],
              [searchInput]: {
                ...(state.imageLibrary.search[imageSource][searchInput] || {}),
                isViewed
              }
            }
          }
        }
      };
    }

    default:
      return state;
  }
}
