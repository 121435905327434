import * as PIXI from 'pixi.js';

import TimelineWrapper from './TimelineWrapper';

export const GRABHANDLE_HIGHLIGHT_COLOUR = 0x19a1c7;
export const GRABHANDLE_DEFAULT_COLOUR = 0x000000;
export default class TimelineElementGrabHandle extends PIXI.Sprite {
  public index: number;
  public lastHandle = false;
  graphics: PIXI.Graphics;
  lowerClamp?: number;
  upperClamp?: number;
  event?: PIXI.InteractionEvent | null;
  onRightClick?: (event: PIXI.InteractionEvent) => void;
  handleColor: number;
  wrapperRef: TimelineWrapper;

  constructor(
    index: number,
    lastHandle = false,
    wrapperRef: TimelineWrapper,
    onContextClick?: (event: PIXI.InteractionEvent) => void,
    handleColor?: number
  ) {
    super();
    this.wrapperRef = wrapperRef;
    this.index = index;
    this.lastHandle = lastHandle;
    this.handleColor = handleColor ?? GRABHANDLE_HIGHLIGHT_COLOUR;
    this.graphics = new PIXI.Graphics();
    this.updateGraphics();
    this.addChild(this.graphics);
    this.onRightClick = onContextClick;
    this.wrapperRef = wrapperRef;
    if (this.index !== 0) {
      this.hitArea = this.getHitArea();
      this.interactive = this.buttonMode = true;
      this.cursor = 'timeDrag';
      this.on('pointerover', this.showIndicator)
        .on('pointerout', this.hideIndicator)
        .on('dragout', this.hideIndicator)
        .on('pointerdown', this.onDragStart)
        .on('pointerup', this.onDragEnd)
        .on('pointerupoutside', this.onDragEnd);
    }
  }

  private getHitArea(): PIXI.Rectangle {
    const x = -10;
    const y = 0;
    const width = 20;
    const height = this.wrapperRef.currentZoom.y + 2;
    return new PIXI.Rectangle(x, y, width, height);
  }

  public setClamps(lowerClamp: number, upperClamp: number) {
    this.lowerClamp = Math.floor(lowerClamp);
    this.upperClamp = Math.ceil(upperClamp);
  }

  showIndicator() {
    this.updateGraphics(this.handleColor, 1);
  }
  hideIndicator() {
    this.updateGraphics(GRABHANDLE_DEFAULT_COLOUR, 1);
  }

  updateZoom() {
    this.updateGraphics();
  }

  private updateGraphics(fillColour = 0x000000, width = 1) {
    this.graphics.clear();
    this.graphics.lineStyle(width, fillColour);
    this.graphics.moveTo(0, 1);
    this.graphics.lineTo(0, this.wrapperRef.currentZoom.y);
    this.hitArea = this.getHitArea();
  }

  private onDragStart(event: PIXI.InteractionEvent) {
    if (event.data.button === 0) {
      this.event = event;
      this.wrapperRef.dragging = true;
      this.updateGraphics(this.handleColor, 3);
      TimelineWrapper.DraggedGrabHandle = this;
    } else if (event.data.button === 2) {
      this.onRightClick?.(event);
    }
  }

  private onDragEnd(event: PIXI.InteractionEvent) {
    if (TimelineWrapper.DraggedGrabHandle) {
      this.emit('onGrabHandleDragComplete', this, event);

      this.wrapperRef.dragging = false;
      this.event = null;
      this.updateGraphics(this.handleColor, 1);

      TimelineWrapper.DraggedGrabHandle = null;
    }
  }

  public onDragMove(position: PIXI.Point) {
    if (TimelineWrapper.DraggedGrabHandle === this) {
      if (this.wrapperRef.dragging && this.event) {
        this.event.data.global.x = Math.round(position.x);
        this.event.data.global.y = Math.round(position.y);
        const newPosition = this.event.data.getLocalPosition(this.parent);
        if (newPosition !== undefined) {
          if (this.lowerClamp !== undefined && this.upperClamp !== undefined) {
            newPosition.x = Math.round(newPosition.x);
            if (newPosition.x < this.lowerClamp) newPosition.x = this.lowerClamp;
            if (newPosition.x > this.upperClamp) newPosition.x = this.upperClamp;
          }

          if (this.x !== newPosition.x) {
            this.x = newPosition.x;
            this.emit('onGrabHandleDragged', this, this.event);
          }
        }
      }
    }
  }
}
