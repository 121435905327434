import { SetProjectsViewModeAction } from 'js/actionCreators/uiActions';
import { call } from 'redux-saga/effects';

import { track } from '../mixpanelProvider';

export default function* trackSetProjectsViewMode({ mode }: SetProjectsViewModeAction) {
  yield call(track, 'Toggle My Projects View', {
    'Project View Turned On': mode === 'list' ? 'List' : 'Thumbnail'
  });
}
