import React, { useState, useEffect, useCallback, DragEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ImageCategory, ImageSource } from 'js/types';
import { trackImageProviderChange, trackImageSearch } from 'js/actionCreators/trackingActions';
import { addLibraryImageToCanvas, replaceLibraryImageElement } from 'js/actionCreators/imagesActions';
import { setDragAppResourceToCanvasData } from 'js/shared/helpers/dragAndDropHelpers';
import IconCrown from 'js/shared/icons/IconCrown';
import { updateSearchTabViewed } from 'js/actionCreators/ImageDrawerActions';
import { useParams } from 'react-router';
import { SearchType } from 'js/reducers/imageSearchReducer';
import useReplaceImage from 'js/shared/hooks/useReplaceImage';
import useUserWithPermissions from 'js/permissions/useUserWithPermissions';
import { SCOPE } from 'js/config/consts';

import SearchResultEmptyState from '../../components/EmptyState/SearchResult';

import useGetSearchResult from './useGetSearchResult';
import useLoadImages from './useLoadImages';
import useFormatAddImageData from './useFormatAddImageData';

const TAB_TITLES: Record<ImageSource, string> = {
  videoscribe: 'Illustrations',
  'noun-project': 'Icons'
};

const useTabLogic = (
  searchType: SearchType,
  searchInput: string,
  categoryId: string | null,
  onCategoryClick: (category: ImageCategory) => void
) => {
  const user = useUserWithPermissions();
  const dispatch = useDispatch();
  const { scribeId } = useParams<{ scribeId: string }>();
  const [selectedTab, setSelectedTab] = useState<ImageSource>('videoscribe');
  const { isInReplaceImageMode, replacingImageElementId } = useReplaceImage();

  const { isTabViewed, isTabLoading, getTabImages, getTabResultCount, isTabInit, getAlternativeTab } =
    useGetSearchResult(searchType, searchInput, categoryId);

  const loadImages = useLoadImages(searchType, searchInput, categoryId, selectedTab);

  const formatAddImageData = useFormatAddImageData(searchType, searchInput, categoryId);

  useEffect(() => {
    if (!isTabInit(selectedTab)) loadImages(true);
  }, [selectedTab, isTabInit, loadImages]);

  const handleTabChange = (tabId: string) => {
    const selectedTabId = tabId as ImageSource;
    setSelectedTab(selectedTabId);
    const provider = selectedTabId === 'videoscribe' ? 'library' : 'noun-project';
    dispatch(trackImageProviderChange(provider, searchType));
    if (!isTabViewed(selectedTabId)) {
      dispatch(updateSearchTabViewed(selectedTabId, searchInput, true));
      const tabName = selectedTabId === 'videoscribe' ? 'VideoScribe' : 'Noun Project';
      dispatch(trackImageSearch(searchInput, parseInt(scribeId), tabName));
    }
  };

  const addImageToCanvas = useCallback(
    (selectedTab: ImageSource, imageId: string | number) => {
      const addImageData = formatAddImageData(selectedTab, imageId);
      if (!addImageData) return;

      if (isInReplaceImageMode && replacingImageElementId) {
        return dispatch(
          replaceLibraryImageElement({
            imageId: addImageData.imageId,
            imageType: addImageData.contentType,
            scribeId: addImageData.scribeId,
            provider: addImageData.provider,
            canvasSize: addImageData.canvasSize,
            selectedLibrary: addImageData.category ?? '',
            filename: addImageData.filename,
            elementId: replacingImageElementId
          })
        );
      }

      dispatch(
        addLibraryImageToCanvas(
          addImageData.imageId,
          addImageData.contentType,
          addImageData.scribeId,
          addImageData.canvasSize,
          addImageData.category,
          addImageData.filename,
          addImageData.provider,
          addImageData.isPremium
        )
      );
    },
    [dispatch, formatAddImageData, isInReplaceImageMode, replacingImageElementId]
  );

  const handleImageDrag = useCallback(
    (e: DragEvent<HTMLButtonElement>, selectedTab: ImageSource, imageId: string | number) => {
      const addImageData = formatAddImageData(selectedTab, imageId);
      if (!addImageData) return;
      setDragAppResourceToCanvasData(e, addImageData);
    },
    [formatAddImageData]
  );

  const createTabConfig = useCallback(
    (tabId: ImageSource) => {
      let icon;

      if (tabId === 'noun-project' && !user.can([SCOPE.usePremiumContent])) {
        icon = <IconCrown />;
      }

      return {
        id: tabId,
        title: TAB_TITLES[tabId],
        count: getTabResultCount(tabId),
        icon,
        images: getTabImages(tabId),
        onLoadMore: loadImages,
        isLoading: isTabLoading(tabId),
        onClickImage: (imageId: string | number) => addImageToCanvas(tabId, imageId),
        onDragImage: (e: DragEvent<HTMLButtonElement>, imageId: string | number) => handleImageDrag(e, tabId, imageId),
        searchInput,
        categoryId,
        emptyState: (
          <SearchResultEmptyState
            tab={TAB_TITLES[tabId]}
            alternativeTab={getAlternativeTab(tabId)}
            onCategoryClick={onCategoryClick}
          />
        )
      };
    },
    [
      getTabResultCount,
      getTabImages,
      loadImages,
      isTabLoading,
      addImageToCanvas,
      handleImageDrag,
      getAlternativeTab,
      onCategoryClick,
      searchInput,
      categoryId,
      user
    ]
  );

  const configTabs = useCallback(
    () => [createTabConfig('videoscribe'), createTabConfig('noun-project')],
    [createTabConfig]
  );

  return {
    selectedTab,
    handleTabChange,
    configTabs
  };
};

export default useTabLogic;
