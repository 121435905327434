import React from 'react';

export default function IconAnimationPulse() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      enableBackground="new 0 0 60 60"
    >
      <path
        d="M30,37.4c-0.9,0-1.8-0.2-2.7-0.6c-3.5-1.5-5.1-5.6-3.6-9c0.7-1.7,2.1-3,3.8-3.7c1.7-0.7,3.6-0.6,5.3,0.1
	c3.5,1.5,5.1,5.6,3.6,9v0C35.2,35.8,32.6,37.4,30,37.4z M30,26.6c-1.5,0-2.9,0.9-3.6,2.3c-0.8,2,0.1,4.2,2,5.1c2,0.8,4.2-0.1,5.1-2
	l0,0c0.8-2-0.1-4.2-2-5.1C31,26.7,30.5,26.6,30,26.6z"
      />
      <path
        d="M30,50.9c-0.7,0-1.4,0-2.1-0.1c-5.4-0.6-10.3-3.2-13.7-7.4c-3.4-4.2-5-9.5-4.4-15c1.2-11.2,11.2-19.3,22.4-18.2
	c11.2,1.2,19.3,11.2,18.2,22.4l0,0C49.2,43.1,40.3,50.9,30,50.9z M30,13.1c-8.8,0-16.4,6.7-17.3,15.6c-0.5,4.6,0.9,9.2,3.8,12.8
	c2.9,3.6,7.1,5.9,11.7,6.3c4.6,0.5,9.2-0.9,12.8-3.8c3.6-2.9,5.9-7.1,6.3-11.7c1-9.5-6-18.1-15.5-19.1C31.2,13.1,30.6,13.1,30,13.1z
	"
      />
      <path
        d="M30,43.1c-1.7,0-3.3-0.3-4.9-1c-3.1-1.3-5.6-3.8-6.9-6.9c-1.3-3.1-1.3-6.6,0-9.7c2.7-6.5,10.1-9.5,16.6-6.8
	c6.5,2.7,9.5,10.1,6.8,16.6l0,0c-1.3,3.1-3.8,5.6-6.9,6.9C33.3,42.8,31.6,43.1,30,43.1z M30,20.7c-3.8,0-7.4,2.2-9,6
	c-1,2.4-1,5,0,7.4s2.8,4.3,5.2,5.3c2.4,1,5,1,7.4,0c2.4-1,4.3-2.8,5.3-5.2c2.1-4.9-0.3-10.6-5.2-12.7C32.5,21,31.3,20.7,30,20.7z"
      />
    </svg>
  );
}
