import React from 'react';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useSkeletonStyles = makeStyles({
  root: {
    background: 'var(--colorSkeletonBackground)'
  }
});

type SkeletonProps = {
  width?: string | number;
  height?: string | number;
  variant?: 'text' | 'rect' | 'circle';
};

const Skeleton: React.FC<SkeletonProps> = ({ width = '100%', height = '100%', variant = 'rect' }) => {
  const classes = useSkeletonStyles();
  return (
    <MuiSkeleton animation="pulse" variant={variant} width={width} height={height} classes={{ root: classes.root }} />
  );
};

export default Skeleton;
