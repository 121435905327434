import React from 'react';

import './EditPanelButton.css';

const EditPanelButton = ({
  children,
  onClick,
  className = '',
  disabled,
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
  <button {...props} onClick={onClick} disabled={disabled} className={`EditPanelButton ${className}`}>
    {children}
  </button>
);

export default EditPanelButton;
