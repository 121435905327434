const getSegmentPercentProgress = (isDrawingStrokes, percentProgress, strokePercentage) => {
  if (isDrawingStrokes) {
    return percentProgress / strokePercentage;
  } else {
    return (percentProgress - strokePercentage) / (1 - strokePercentage);
  }
};

const getDrawToLength = (isDrawingStrokes, totalLength, strokeMaskLength, segmentPercent) => {
  if (isDrawingStrokes) {
    return Math.floor(strokeMaskLength * segmentPercent);
  } else {
    return Math.floor(strokeMaskLength + (totalLength - strokeMaskLength) * segmentPercent);
  }
};

export const getCurrentStrokeAnimationValues = (percentProgress, strokePercentage, strokeMaskLength, totalLength) => {
  const defaultDrawToLength = Math.floor(totalLength * percentProgress);

  const noFills = strokeMaskLength === totalLength;

  if (noFills) {
    return {
      isDrawingStrokes: true,
      drawToLength: defaultDrawToLength
    };
  }

  const noStrokes = strokeMaskLength === 0;

  if (noStrokes) {
    return {
      isDrawingStrokes: false,
      drawToLength: defaultDrawToLength
    };
  }

  const isDrawingStrokes = percentProgress < strokePercentage;
  const segmentPercentage = getSegmentPercentProgress(isDrawingStrokes, percentProgress, strokePercentage);
  const drawToLength = getDrawToLength(isDrawingStrokes, totalLength, strokeMaskLength, segmentPercentage);

  return {
    isDrawingStrokes,
    drawToLength
  };
};
