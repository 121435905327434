import { ExistingScribeModel, VisualCanvasElement, VSCAssetData, VSImageShapeOrTextElementModel } from 'js/types';
import { PREMIUM_CONTENT_REWORK } from 'js/config/featureKeys';

import getOptimizelyInstance from '../optimizely';

const isPremiumFeatureEnabled = (): boolean => {
  const optimizely = getOptimizelyInstance();
  return optimizely.isFeatureEnabled(PREMIUM_CONTENT_REWORK);
};

export const isPremiumImageAsset = (asset: VSCAssetData): boolean => {
  if (!isPremiumFeatureEnabled()) {
    return false;
  }

  return !!asset.isPremium;
};

export const isPremiumVisualCanvasElement = (canvasElement: VisualCanvasElement): boolean => {
  if (!isPremiumFeatureEnabled()) {
    return false;
  }

  return !!canvasElement.element.isPremium;
};

export const isPremiumElement = (element: VSImageShapeOrTextElementModel): boolean => {
  if (!isPremiumFeatureEnabled()) {
    return false;
  }

  return !!element.isPremium;
};

export const projectContainsPremiumContent = (project: ExistingScribeModel): boolean => {
  if (!isPremiumFeatureEnabled()) {
    return false;
  }

  const elements = project.elements.filter(el => el.type === 'Image');

  return elements.some(element => isPremiumElement(element));
};
