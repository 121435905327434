import {
  EMPHASIS_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  EXIT_ANIMATION_DOCUMENT_KEY
} from 'js/config/consts';
import { AnimationStageDocumentKey, ElementAnimationStage } from 'js/types';

const getAnimationStageDocumentKey = (stageKey: ElementAnimationStage): AnimationStageDocumentKey => {
  if (stageKey === 'entrance') return ENTRANCE_ANIMATION_DOCUMENT_KEY;
  if (stageKey === 'emphasis') return EMPHASIS_ANIMATION_DOCUMENT_KEY;
  return EXIT_ANIMATION_DOCUMENT_KEY;
};

export default getAnimationStageDocumentKey;
