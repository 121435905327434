import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { useDispatch } from 'react-redux';

import {
  clearReplaceImage as cancelReplaceImageAction,
  startReplaceImage as startReplaceImageAction
} from '../../actionCreators/imagesActions';

export default function useReplaceImage() {
  const dispatch = useDispatch();
  const { replacingImageElementId } = useTypedSelector(state => state.images);

  const cancelReplaceImage = () => {
    dispatch(cancelReplaceImageAction());
  };

  const startReplaceImage = (imageElementId: string) => {
    dispatch(startReplaceImageAction(imageElementId));
  };

  return {
    replacingImageElementId,
    isInReplaceImageMode: !!replacingImageElementId,
    cancelReplaceImage,
    startReplaceImage
  };
}
