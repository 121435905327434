import { Cursor } from 'js/types';

export const LOAD_CURSORS_SUCCESS = 'LOAD_CURSORS_SUCCESS';

type LoadCursorsSuccessActionInput = {
  allowedCursors: Array<Cursor>;
  allowedEraseCursors: Array<Cursor>;
  allowedDragCursors: Array<Cursor>;
};

export type LoadCursorsSuccessAction = {
  type: typeof LOAD_CURSORS_SUCCESS;
  cursors: Array<Cursor>;
  eraseCursors: Array<Cursor>;
  dragCursors: Array<Cursor>;
};

export const loadCursorsSuccess = (cursors: LoadCursorsSuccessActionInput): LoadCursorsSuccessAction => ({
  type: LOAD_CURSORS_SUCCESS,
  cursors: cursors.allowedCursors,
  eraseCursors: cursors.allowedEraseCursors,
  dragCursors: cursors.allowedDragCursors
});
