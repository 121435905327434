import AnimationCursorChoiceButton from 'js/editor/AnimationPanels/AnimationCursorChoiceButton';
import CursorChoiceButton from 'js/editor/AnimationPanels/CursorChoiceButton';
import DirectionChoiceButton from 'js/editor/AnimationPanels/DirectionChoiceButton';
import DirectionMenu from 'js/editor/AnimationPanels/DirectionMenu';
import DrawTypeChoiceButton from 'js/editor/AnimationPanels/DrawTypeChoiceButton';
import DrawTypeMenu from 'js/editor/AnimationPanels/DrawTypeMenu';
import PenMenu from 'js/editor/AnimationPanels/PenMenu';
import SpinChoiceButton from 'js/editor/AnimationPanels/SpinChoiceButton';
import SpinDirectionMenu from 'js/editor/AnimationPanels/SpinDirectionMenu';
import DragHandMenu from 'js/editor/DragHandMenu';
import DrawHandMenu from 'js/editor/DrawHandMenu';
import { isSVGElement } from 'js/editor/EditorCanvas/helpers/isSVGElement';
import EraseHandPicker from 'js/editor/EraseHandPicker';
import IconAnimationBounceIn from 'js/shared/icons/IconAnimationBounceIn';
import {
  IconAnimationClockwise,
  IconAnimationCounterClockwise,
  IconAnimationDown,
  IconAnimationDownLeft,
  IconAnimationDownRight,
  IconAnimationLeft,
  IconAnimationRight,
  IconAnimationUp,
  IconAnimationUpLeft,
  IconAnimationUpRight
} from 'js/shared/icons/IconAnimationDirections';
import IconAnimationDisappear from 'js/shared/icons/IconAnimationDisappear';
import IconAnimationDragIn from 'js/shared/icons/IconAnimationDragIn';
import IconAnimationFade from 'js/shared/icons/IconAnimationFade';
import IconAnimationMoveIn from 'js/shared/icons/IconAnimationMoveIn';
import IconAnimationPulse from 'js/shared/icons/IconAnimationPulse';
import IconAnimationShake from 'js/shared/icons/IconAnimationShake';
import IconAnimationSlingshot from 'js/shared/icons/IconAnimationSlingshot';
import IconAnimationSpinLoop from 'js/shared/icons/IconAnimationSpinLoop';
import IconDraw from 'js/shared/icons/IconDraw';
import IconHandDraw from 'js/shared/icons/IconHandDraw';
import IconNoAnimation from 'js/shared/icons/IconNoAnimation';
import IconPenDraw from 'js/shared/icons/IconPenDraw';
import {
  AllAnimationTypeKeys,
  AnimationConfigurationOptions,
  DrawType,
  EmphasisAnimationTypeKeys,
  EntranceAnimationTypeKeys,
  ExitAnimationTypeKeys,
  VSImageShapeOrTextElementModel
} from 'js/types';

import { ReactComponent as IconErase } from '../../imgs/svg-icons/IconErase.svg';
import { ReactComponent as IconIllustrate } from '../../imgs/svg-icons/IconIllustrate.svg';
import { ReactComponent as IconReveal } from '../../imgs/svg-icons/IconReveal.svg';

import {
  BOUNCE_IN_KEY,
  BOUNCE_LOOP_KEY,
  DEFAULT_DRAG_CURSOR_ID,
  DEFAULT_ERASE_CURSOR_ID,
  DEFAULT_HAND_CURSOR_ID,
  DEFAULT_PEN_CURSOR_ID,
  DISAPPEAR_KEY,
  DRAG_IN_KEY,
  DRAW_KEY,
  EXIT_ANIMATION_ERASE_KEY,
  FADE_IN_KEY,
  FADE_OUT_KEY,
  HAND_DRAW_KEY,
  MOVE_IN_KEY,
  MOVE_OUT_KEY,
  NO_ANIMATION_KEY,
  PEN_DRAW_KEY,
  PULSE_LOOP_KEY,
  SHAKE_LOOP_KEY,
  SLINGSHOT_KEY,
  SPIN_LOOP_KEY
} from './consts';
import { SPIN_CLOCKWISE_DEFAULT, SPIN_COUNTER_CLOCKWISE_DEFAULT } from './defaults';

export const getEntranceAnimationOptions = (
  activeElement?: VSImageShapeOrTextElementModel,
  activeElements: Array<VSImageShapeOrTextElementModel> = []
) => {
  const allElementsAreSVG = !!activeElements.length && activeElements.every(element => isSVGElement(element));
  const allElementsSameAnimationType =
    !!activeElements.length &&
    activeElements.every(
      element =>
        (element.entranceAnimation?.id ?? HAND_DRAW_KEY) === (activeElements[0].entranceAnimation?.id ?? HAND_DRAW_KEY)
    );

  const animationTypeHasDrawStyle = (option: AllAnimationTypeKeys) =>
    [HAND_DRAW_KEY, PEN_DRAW_KEY, DRAW_KEY].includes(option);

  const allElementsHaveDrawStyle =
    !!activeElements.length &&
    activeElements.every(
      element => !element.entranceTween && animationTypeHasDrawStyle(element.entranceAnimation?.id ?? HAND_DRAW_KEY)
    );

  const allElementsSameSecondaryDrawType =
    !!activeElements.length &&
    activeElements.every(
      element =>
        !!element.entranceAnimation &&
        element.entranceAnimation.config?.drawType === activeElements[0].entranceAnimation?.config?.drawType
    );

  const options = ENTRANCE_ANIMATION_OPTIONS.map(option => {
    const showDrawTypeAsTertiaryOption =
      (isSVGElement(activeElement) && [HAND_DRAW_KEY, PEN_DRAW_KEY].includes(option.type)) ||
      (allElementsAreSVG && allElementsSameAnimationType && [HAND_DRAW_KEY, PEN_DRAW_KEY].includes(option.type)) ||
      (allElementsAreSVG &&
        allElementsSameAnimationType &&
        option.type === DRAW_KEY &&
        !allElementsSameSecondaryDrawType);
    const isCurrentOptionHasDrawStyle = animationTypeHasDrawStyle(option.type as EntranceAnimationTypeKeys);
    const showDrawTypeAsSecondaryOption =
      (isSVGElement(activeElement) && option.type === DRAW_KEY) ||
      (allElementsAreSVG && allElementsSameAnimationType && option.type === DRAW_KEY) ||
      (allElementsAreSVG && !allElementsSameAnimationType && isCurrentOptionHasDrawStyle && allElementsHaveDrawStyle);

    if (showDrawTypeAsTertiaryOption) {
      return {
        ...option,
        tertiaryMenu: {
          title: 'Draw style',
          ChoiceButton: DrawTypeChoiceButton,
          choiceButtonLabel: 'Draw style',
          Component: DrawTypeMenu
        }
      };
    }

    if (showDrawTypeAsSecondaryOption) {
      return {
        ...option,
        secondaryMenu: {
          title: 'Draw style',
          ChoiceButton: DrawTypeChoiceButton,
          choiceButtonLabel: 'Draw style',
          Component: DrawTypeMenu
        }
      };
    }

    return option;
  });

  return options;
};

export const ENTRANCE_ANIMATION_OPTIONS = [
  {
    type: NO_ANIMATION_KEY,
    title: 'None',
    Icon: IconNoAnimation
  },
  {
    type: HAND_DRAW_KEY,
    title: 'Hand draw',
    Icon: IconHandDraw,

    secondaryMenu: {
      title: 'Hand type',
      Component: DrawHandMenu,
      choiceButtonLabel: 'Hand',
      ChoiceButton: CursorChoiceButton
    }
  },
  {
    type: PEN_DRAW_KEY,
    title: 'Pen draw',
    Icon: IconPenDraw,

    secondaryMenu: {
      title: 'Pen type',
      Component: PenMenu,
      choiceButtonLabel: 'Pen',
      ChoiceButton: CursorChoiceButton
    }
  },
  {
    type: DRAW_KEY,
    title: 'Draw',
    Icon: IconDraw
  },
  {
    type: FADE_IN_KEY,
    title: 'Fade in',
    Icon: IconAnimationFade
  },
  {
    type: MOVE_IN_KEY,
    title: 'Move in',
    Icon: IconAnimationMoveIn,

    secondaryMenu: {
      title: 'Direction',
      Component: DirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: DirectionChoiceButton
    }
  },
  {
    type: DRAG_IN_KEY,
    title: 'Drag in',
    Icon: IconAnimationDragIn,
    secondaryMenu: {
      title: 'Drag Hand',
      Component: DragHandMenu,
      choiceButtonLabel: 'Hand',
      ChoiceButton: CursorChoiceButton
    },

    tertiaryMenu: {
      title: 'Direction',
      Component: DirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: DirectionChoiceButton
    }
  },
  {
    type: BOUNCE_IN_KEY,
    title: 'Bounce in',
    Icon: IconAnimationBounceIn,

    secondaryMenu: {
      title: 'Direction',
      Component: DirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: DirectionChoiceButton
    }
  }
];

export const EMPHASIS_ANIMATION_OPTIONS = [
  {
    type: NO_ANIMATION_KEY,
    title: 'None',
    Icon: IconNoAnimation
  },
  {
    type: PULSE_LOOP_KEY,
    title: 'Pulse',
    Icon: IconAnimationPulse
  },
  {
    type: SPIN_LOOP_KEY,
    title: 'Spin',
    Icon: IconAnimationSpinLoop,
    secondaryMenu: {
      title: 'Direction',
      Component: SpinDirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: SpinChoiceButton
    }
  },
  {
    type: SHAKE_LOOP_KEY,
    title: 'Shake',
    Icon: IconAnimationShake,

    secondaryMenu: {
      title: 'Direction',
      Component: DirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: DirectionChoiceButton
    }
  },
  {
    type: BOUNCE_LOOP_KEY,
    title: 'Bounce',
    Icon: IconAnimationBounceIn
  }
];

export const EXIT_ANIMATION_OPTIONS = [
  {
    type: NO_ANIMATION_KEY,
    title: 'None',
    Icon: IconNoAnimation
  },
  {
    type: FADE_OUT_KEY,
    title: 'Fade out',
    Icon: IconAnimationFade
  },
  {
    type: MOVE_OUT_KEY,
    title: 'Move out',
    Icon: IconAnimationMoveIn,

    secondaryMenu: {
      title: 'Direction',
      Component: DirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: DirectionChoiceButton
    }
  },
  {
    type: SLINGSHOT_KEY,
    title: 'Slingshot',
    Icon: IconAnimationSlingshot,

    secondaryMenu: {
      title: 'Direction',
      Component: DirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: DirectionChoiceButton
    }
  },
  {
    type: DISAPPEAR_KEY,
    title: 'Disappear',
    Icon: IconAnimationDisappear
  },
  {
    type: EXIT_ANIMATION_ERASE_KEY,
    title: 'Erase',
    Icon: IconErase,
    secondaryMenu: {
      title: 'Hand Type',
      Component: EraseHandPicker,
      choiceButtonLabel: 'Hand',
      ChoiceButton: AnimationCursorChoiceButton
    }
  }
];

export const ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY = 'animationTime';
export const EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY = 'emphasisAnimationTime';
export const EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY = 'emphasisAnimationLoops';
export const EXIT_ANIMATION_DURATION_DOCUMENT_KEY = 'exitAnimationTime';
export const PAUSE_DURATION_DOCUMENT_KEY = 'pauseTime';

export const selectableEntranceAnimationOptions = [
  {
    id: 0,
    label: 'Top left',
    Icon: IconAnimationDownLeft,
    value: '@tl'
  },
  {
    id: 1,
    label: 'Top',
    Icon: IconAnimationDown,
    value: '@t'
  },
  {
    id: 2,
    label: 'Top right',
    Icon: IconAnimationDownRight,
    value: '@tr'
  },
  {
    id: 3,
    label: 'Left',
    Icon: IconAnimationRight,
    value: '@l'
  },
  {
    id: 4,
    label: 'Scene Thumbnail'
  },
  {
    id: 5,
    label: 'Right',
    Icon: IconAnimationLeft,
    value: '@r'
  },
  {
    id: 6,
    label: 'Bottom left',
    Icon: IconAnimationUpRight,
    value: '@bl'
  },
  {
    id: 7,
    label: 'Bottom',
    Icon: IconAnimationUp,
    value: '@b'
  },
  {
    id: 8,
    label: 'Bottom right',
    Icon: IconAnimationUpLeft,
    value: '@br'
  }
];

export const selectableSpinDirectionOptions = [
  {
    id: 0,
    label: 'Clockwise',
    Icon: IconAnimationClockwise,
    value: SPIN_CLOCKWISE_DEFAULT
  },
  {
    id: 1,
    label: 'Counter Clockwise',
    Icon: IconAnimationCounterClockwise,
    value: SPIN_COUNTER_CLOCKWISE_DEFAULT
  }
];

export const DRAW_TYPE_REVEAL = 'reveal';
export const DRAW_TYPE_ILLUSTRATE = 'illustrate';
export const selectableDrawTypeOptions = [
  {
    id: DRAW_TYPE_ILLUSTRATE,
    label: 'Illustrate',
    Icon: IconIllustrate,
    value: DRAW_TYPE_ILLUSTRATE as DrawType
  },
  {
    id: DRAW_TYPE_REVEAL,
    label: 'Reveal',
    Icon: IconReveal,
    value: DRAW_TYPE_REVEAL as DrawType
  }
];

export const selectableShakeAnimationOptions = [
  {
    id: 0,
    label: 'Top left',
    Icon: IconAnimationDownLeft,
    direction: 'tl'
  },
  {
    id: 1,
    label: 'Top',
    Icon: IconAnimationDown,
    direction: 't'
  },
  {
    id: 2,
    label: 'Top right',
    Icon: IconAnimationDownRight,
    direction: 'tr'
  },
  {
    id: 3,
    label: 'Left',
    Icon: IconAnimationRight,
    direction: 'l'
  },
  {
    id: 4,
    label: 'Scene Thumbnail'
  },
  {
    id: 5,
    label: 'Right',
    Icon: IconAnimationLeft,
    direction: 'r'
  },
  {
    id: 6,
    label: 'Bottom left',
    Icon: IconAnimationUpRight,
    direction: 'bl'
  },
  {
    id: 7,
    label: 'Bottom',
    Icon: IconAnimationUp,
    direction: 'b'
  },
  {
    id: 8,
    label: 'Bottom right',
    Icon: IconAnimationUpLeft,
    direction: 'br'
  }
];

export const EXIT_ANIMATION_DEFAULTS: Record<ExitAnimationTypeKeys, AnimationConfigurationOptions> = {
  [EXIT_ANIMATION_ERASE_KEY]: { cursorId: DEFAULT_ERASE_CURSOR_ID },
  [FADE_OUT_KEY]: { cursorId: undefined },
  [MOVE_OUT_KEY]: { cursorId: undefined },
  [SLINGSHOT_KEY]: { cursorId: undefined },
  [DISAPPEAR_KEY]: { cursorId: undefined },
  [NO_ANIMATION_KEY]: { cursorId: undefined }
};

export const ENTRANCE_ANIMATION_DEFAULTS: Record<EntranceAnimationTypeKeys, AnimationConfigurationOptions> = {
  [DRAW_KEY]: { cursorId: undefined },
  [PEN_DRAW_KEY]: { cursorId: DEFAULT_PEN_CURSOR_ID },
  [HAND_DRAW_KEY]: { cursorId: DEFAULT_HAND_CURSOR_ID },
  [FADE_IN_KEY]: { cursorId: undefined },
  [MOVE_IN_KEY]: { cursorId: undefined },
  [BOUNCE_IN_KEY]: { cursorId: undefined },
  [DRAG_IN_KEY]: { cursorId: DEFAULT_DRAG_CURSOR_ID },
  [NO_ANIMATION_KEY]: { cursorId: undefined }
};

export const EMPHASIS_ANIMATION_DEFAULTS: Record<EmphasisAnimationTypeKeys, AnimationConfigurationOptions> = {
  [BOUNCE_LOOP_KEY]: { cursorId: undefined },
  [SPIN_LOOP_KEY]: { cursorId: undefined },
  [PULSE_LOOP_KEY]: { cursorId: undefined },
  [SHAKE_LOOP_KEY]: { cursorId: undefined },
  [NO_ANIMATION_KEY]: { cursorId: undefined }
};

export const ALL_ANIMATION_DEFAULTS: Record<AllAnimationTypeKeys, AnimationConfigurationOptions> = {
  ...EMPHASIS_ANIMATION_DEFAULTS,
  ...EXIT_ANIMATION_DEFAULTS,
  ...ENTRANCE_ANIMATION_DEFAULTS
};
