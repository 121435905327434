import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import networkReducer from './networkReducer';
import authReducer from './authReducer';
import loginReducer from './loginReducer';
import autoSaveReducer from './autoSaveReducer';
import scribesReducer from './scribesReducer';
import uiReducers from './uiReducers';
import imagesReducer from './imagesReducers';
import downloadReducer from './downloadReducer';
import checkoutReducer from './checkoutReducer';
import templatesReducer from './templatesReducer';
import marketingReducer from './marketingReducer';
import playbackReducer from './playbackReducer';
import userSettingsReducer from './userSettingsReducer';
import croppingReducer from './croppingReducer';
import fontReducer from './fontReducer';
import cursorReducer from './cursorReducer';
import audioReducer from './audioReducer';
import audioUploadReducer from './audioUploadReducer';
import audioLibraryReducer from './audioLibraryReducer';
import trackingReducer from './trackingReducer';
import betaAiFeaturesReducer from './betaAiFeaturesReducer';
import projectScriptsReducer from './projectScriptsReducer';
import imageDrawerReducer from './imageDrawerReducer';
import imageSearchReducer from './imageSearchReducer';
import limitReducer from './limitReducer';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    network: networkReducer,
    auth: authReducer,
    login: loginReducer,
    autoSave: autoSaveReducer,
    scribes: scribesReducer,
    ui: uiReducers,
    images: imagesReducer,
    download: downloadReducer,
    checkout: checkoutReducer,
    templates: templatesReducer,
    marketing: marketingReducer,
    playback: playbackReducer,
    userSettings: userSettingsReducer,
    cropping: croppingReducer,
    fonts: fontReducer,
    cursors: cursorReducer,
    audio: audioReducer,
    audioUpload: audioUploadReducer,
    audioLibrary: audioLibraryReducer,
    tracking: trackingReducer,
    betaAiFeatures: betaAiFeaturesReducer,
    projectScripts: projectScriptsReducer,
    imageDrawer: imageDrawerReducer,
    imageSearch: imageSearchReducer,
    limits: limitReducer
  });

export default createRootReducer;
