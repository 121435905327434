import { ExistingScribeModel, ScribeScene, VSElementModel } from 'js/types';
import { cloneDeep } from 'lodash';

import { getViewportData } from './getViewportData';
import { getElementBoundingRectangle } from './getElementBoundingRectangle';

export const updateCameraCoverage = (
  activeElements: Array<string>,
  sceneToUpdate: ScribeScene,
  newActiveScribe: ExistingScribeModel
): ExistingScribeModel => {
  const cameraCoverage = [];
  const sceneCopy = cloneDeep(sceneToUpdate);
  const sceneElements = sceneCopy.elementIds;
  const canvasDimensions = getViewportData(newActiveScribe.canvasSize);

  for (const elementId of sceneElements) {
    const element = newActiveScribe.elements.find(element => element.id === elementId);
    if (element && element.type === 'Camera') {
      const elementBounds = getElementBoundingRectangle(element, canvasDimensions);
      cameraCoverage.push({
        id: element.id,
        boundingBox: elementBounds
      });
    }
  }

  const activeCameraElements = activeElements.reduce((acc, elementId) => {
    const element = newActiveScribe.elements.find(element => element.id === elementId);
    if (element && element.type === 'Camera') acc.push(element);
    return acc;
  }, [] as Array<VSElementModel>);

  if (activeCameraElements.length > 0) {
    for (const element of activeCameraElements) {
      const camera = cameraCoverage.find(camera => camera.id === element?.id);
      const elementBounds = getElementBoundingRectangle(element, canvasDimensions);
      if (camera) {
        camera.boundingBox = elementBounds;
      } else {
        cameraCoverage.push({
          id: element.id,
          boundingBox: elementBounds
        });
      }
    }
  }
  activeElements = sceneCopy.elementIds;

  for (const elementId of activeElements) {
    const element = newActiveScribe.elements.find(element => element.id === elementId);
    if (!element) continue;
    if (element.type !== 'Camera') {
      const elementBounds = getElementBoundingRectangle(element, canvasDimensions);
      element.outOfCamera = !cameraCoverage.some(camera => camera.boundingBox.intersects(elementBounds));
    }
  }
  return newActiveScribe;
};
