import { fitElementToCanvas } from 'js/shared/helpers/element';
import { getImageDimensions } from 'js/shared/helpers/image';

export default async function fitImageToCanvas(url, contentType, canvasSize) {
  const imgDimensions = await getImageDimensions(url, contentType);
  return {
    ...fitElementToCanvas(imgDimensions.width, imgDimensions.height, canvasSize),
    width: imgDimensions.width,
    height: imgDimensions.height
  };
}
