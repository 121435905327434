import { DEFAULT_HAND_CURSOR_ID, DEFAULT_PEN_CURSOR_ID, CURSOR_TYPES } from 'js/config/consts';

export default function getCursorTypeFromId({ cursorId, cursors }) {
  if (cursorId === DEFAULT_HAND_CURSOR_ID) return CURSOR_TYPES.HAND;
  if (cursorId === DEFAULT_PEN_CURSOR_ID) return CURSOR_TYPES.PEN;

  const cursor = cursors.find(cur => cur.id === cursorId);
  if (!cursor) return;

  if (cursor.group === 'pens') return CURSOR_TYPES.PEN;

  return CURSOR_TYPES.HAND;
}
