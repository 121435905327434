import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { REDIRECT_ON_AUTH_KEY } from 'js/config/sessionStorageKeys';
import { validateCognitoSession } from 'js/shared/helpers/authHelper';

import { LOGIN_URL } from '../../config/config';

import LoadingSpinner from './LoadingSpinner';

const AuthenticatedComponent = ({ component: Component, location, ...rest }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getAuth() {
      let session;
      try {
        session = await validateCognitoSession();
      } catch (error) {
        console.error(error);
      }

      if (session) {
        setLoading(false);
      } else {
        setLoading(false);
        window.sessionStorage.setItem(REDIRECT_ON_AUTH_KEY, `${location.pathname}${location.search}`);
        window.location.href = LOGIN_URL;
      }
    }

    getAuth();

    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={props => <>{loading ? <LoadingSpinner message={'Authenticating...'} /> : <Component {...props} />}</>}
    />
  );
};

export default connect(state => ({
  currentUser: state.auth && state.auth.currentUser,
  isAuthenticating: state.auth && state.auth.isAuthenticating
}))(AuthenticatedComponent);
