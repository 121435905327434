const MAX_TEXTURE_SIZE = 500;
export function scaleTextureForThinning(
  data: Uint8ClampedArray,
  width: number,
  height: number
): { scaledData: ImageData; scaleFactor: number } {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  canvas.width = width;
  canvas.height = height;
  const imageData = ctx.createImageData(width, height);
  imageData.data.set(data);
  ctx.putImageData(imageData, 0, 0);

  const scaleFactor = Math.min(Math.min(MAX_TEXTURE_SIZE / width, 1), Math.min(MAX_TEXTURE_SIZE / height, 1));

  if (scaleFactor === 1) {
    return { scaledData: imageData, scaleFactor };
  }

  const scaledWidth = Math.floor(width * scaleFactor);
  const scaledHeight = Math.floor(height * scaleFactor);

  const scaledCanvas = document.createElement('canvas');
  scaledCanvas.width = scaledWidth;
  scaledCanvas.height = scaledHeight;
  const scaledCtx = scaledCanvas.getContext('2d');

  if (!scaledCtx) {
    throw new Error('Could not get scaled canvas context');
  }

  scaledCtx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);

  const scaledImageData = scaledCtx.getImageData(0, 0, scaledWidth, scaledHeight);

  return { scaledData: scaledImageData, scaleFactor };
}
