import React from 'react';

const IconUpload = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0004 9.0389C5.62926 9.0389 5.32371 8.75017 5.28462 8.37926L5.2804 8.29859L5.2804 2.25124L2.72852 4.27908C2.43608 4.51139 2.02384 4.47802 1.77033 4.21453L1.71477 4.14966C1.48732 3.85098 1.51999 3.42994 1.77797 3.171L1.84149 3.11426L5.50084 0.207303C5.63031 0.0790161 5.80639 0.000122087 6.00039 0.00012207C6.19501 0.000122053 6.37159 0.0795152 6.50118 0.20852L10.159 3.11426C10.4739 3.36444 10.5307 3.828 10.2857 4.14966C10.0583 4.44834 9.65067 4.51964 9.34145 4.32799L9.27197 4.27908L6.72039 2.25148L6.7204 8.29859C6.7204 8.70745 6.39804 9.0389 6.0004 9.0389ZM1.44 9.77794L1.44 7.7403L1.43577 7.65964C1.39668 7.28873 1.09113 7 0.72 7C0.322355 7 -3.57431e-08 7.33145 0 7.7403L5.81269e-07 10.2985L5.88201e-05 10.2996V11.2597L0.00428368 11.3403C0.0433741 11.7113 0.348923 12 0.720059 12H11.2801L11.3585 11.9956C11.7193 11.9554 12.0001 11.6413 12.0001 11.2597V10.2985V9.77907L12.0001 7.7403L11.9958 7.65964C11.9567 7.28873 11.6512 7 11.2801 7C10.8824 7 10.5601 7.33145 10.5601 7.7403L10.5601 9.77798C10.5601 9.77834 10.5601 9.7787 10.5601 9.77907L10.5594 10.5187H1.43937L1.43955 10.3248C1.43985 10.3161 1.44 10.3073 1.44 10.2985L1.44 9.84244L1.44006 9.77907L1.44 9.77794Z"
    />
  </svg>
);

export default IconUpload;
