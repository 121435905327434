import { SceneTransitionConfig } from 'js/types';

export const SET_SCENE_TRANSITION_TYPE = 'SET_SCENE_TRANSITION_TYPE';
export const SET_SCENE_TRANSITION_DURATION = 'SET_SCENE_TRANSITION_DURATION';
export const SET_SCENE_TRANSITION_CONFIG = 'SET_SCENE_TRANSITION_CONFIG';

export interface SetSceneTransitionTypeAction {
  type: typeof SET_SCENE_TRANSITION_TYPE;
  scribeId: number;
  sceneIds?: Array<string>;
  transitionType: string;
}

export const setSceneTransitionType = ({
  scribeId,
  sceneIds,
  transitionType
}: Omit<SetSceneTransitionTypeAction, 'type'>): SetSceneTransitionTypeAction => ({
  type: SET_SCENE_TRANSITION_TYPE,
  scribeId,
  sceneIds,
  transitionType
});

export interface SetSceneTransitionDurationAction {
  type: typeof SET_SCENE_TRANSITION_DURATION;
  scribeId: number;
  sceneIds?: Array<string>;
  duration: number | string;
  eventTrigger: string;
}

export const setSceneTransitionDuration = ({
  scribeId,
  sceneIds,
  duration,
  eventTrigger
}: Omit<SetSceneTransitionDurationAction, 'type'>): SetSceneTransitionDurationAction => ({
  type: SET_SCENE_TRANSITION_DURATION,
  scribeId,
  sceneIds,
  duration,
  eventTrigger
});

export interface SetSceneTransitionConfigAction {
  type: typeof SET_SCENE_TRANSITION_CONFIG;
  scribeId: number;
  sceneIds?: Array<string>;
  useAllDirections: boolean;
  config: SceneTransitionConfig;
}

export const setSceneTransitionConfig = ({
  scribeId,
  sceneIds,
  config,
  useAllDirections = false
}: {
  scribeId: number;
  sceneIds?: Array<string>;
  useAllDirections?: boolean;
  config: SceneTransitionConfig;
}): SetSceneTransitionConfigAction => ({
  type: SET_SCENE_TRANSITION_CONFIG,
  scribeId,
  sceneIds,
  config,
  useAllDirections
});
