import { SetProjectsViewModeAction } from 'js/actionCreators/uiActions';
import { ProjectsViewMode } from 'js/types';

const PROJECTS_VIEW_MODE_LOCAL_STORAGE_KEY = 'VSCProjectsViewMode';

function isProjectViewMode(mode: string): mode is ProjectsViewMode {
  return mode === 'grid' || mode === 'list';
}

export function storeProjectViewMode({ mode }: SetProjectsViewModeAction) {
  localStorage.setItem(PROJECTS_VIEW_MODE_LOCAL_STORAGE_KEY, mode);
}

export function getProjectViewModeInitialState(): ProjectsViewMode {
  const persistedState = localStorage.getItem(PROJECTS_VIEW_MODE_LOCAL_STORAGE_KEY);

  if (persistedState && isProjectViewMode(persistedState)) {
    return persistedState;
  }

  return 'grid';
}
