import cookie from 'cookie';

const CONTENTFUL_TOKEN = 'contentful';

export function setToken(token) {
  const path = '/';
  document.cookie = cookie.serialize(CONTENTFUL_TOKEN, token, { path });
}

export function getToken() {
  const cookies = cookie.parse(document.cookie);
  return cookies[CONTENTFUL_TOKEN] || '';
}

export function removeToken() {
  const cookies = cookie.parse(document.cookie);
  const path = '/';

  if (typeof cookies[CONTENTFUL_TOKEN] === 'undefined') {
    return;
  }

  document.cookie = cookie.serialize(CONTENTFUL_TOKEN, false, { maxAge: -1, expires: new Date(), path });
}
