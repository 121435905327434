import { push, replace } from 'connected-react-router';
import { put, takeLatest, select, fork } from 'redux-saga/effects';
import { TOGGLE_NEW_TIMELINE } from 'js/actionCreators/editorActions';

import { HIDE_MODAL, SET_PROJECTS_VIEW_MODE } from '../actionCreators/uiActions';
import { SET_SHOW_ELEMENT_MODAL } from '../actionCreators/scribeActions';
import { getScribeLink } from '../shared/helpers/linkHelper';
import { ROUTE_CODES, TEMPLATES_PATH } from '../config/routes';

import { getScribeById } from './selectors';
import { storeProjectViewMode } from './sagaHelpers/projectViewMode';
import { saveTimelineOpenStatus } from './sagaHelpers/timelineLocalStorage';
import audioLimitModalSagas from './audioLimitModalSagas';

function* hideModal({ scribeId }) {
  const currentHomeView = yield select(state => state.ui.currentHomeView);

  if (!scribeId && currentHomeView === 'templates') {
    yield put(replace(TEMPLATES_PATH));
    return;
  } else if (!scribeId && currentHomeView === 'my-projects') {
    yield put(replace(ROUTE_CODES.HOME));
    return;
  }

  const scribe = yield select(getScribeById, scribeId);
  yield put(replace(getScribeLink(scribe)));
}

function* showElementModal({ scribeId, elementType, elementId, replaceElement }) {
  const scribe = yield select(getScribeById, scribeId);

  let path;
  if (elementId) {
    path = ['edit', elementId];
  } else if (replaceElement) {
    path = ['replace', elementType];
  } else {
    path = ['add', elementType];
  }

  yield put(push(getScribeLink(scribe, path)));
}

function* toggleNewTimeline() {
  const isTimelineCurrentlyOpen = yield select(({ ui }) => ui.newTimeline);

  const result = saveTimelineOpenStatus(isTimelineCurrentlyOpen);

  if (!result.success) {
    console.error(result.message);
  }
}

function* uiSagas() {
  yield takeLatest(HIDE_MODAL, hideModal);
  yield takeLatest(SET_SHOW_ELEMENT_MODAL, showElementModal);
  yield takeLatest(SET_PROJECTS_VIEW_MODE, storeProjectViewMode);
  yield takeLatest(TOGGLE_NEW_TIMELINE, toggleNewTimeline);
  yield fork(audioLimitModalSagas);
}

export default uiSagas;
