import { RefObject, useEffect } from 'react';

export default function useInfiniteScroll(
  list: RefObject<HTMLElement>,
  lastElement: RefObject<HTMLElement>,
  listLength: number,
  callback: () => void
) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && listLength > 0) {
          callback();
        }
      },
      {
        root: list.current,
        threshold: 0.25
      }
    );

    const lastElementNode = lastElement.current;

    if (lastElementNode) {
      observer.observe(lastElementNode);
    }

    return () => {
      if (lastElementNode) {
        observer.unobserve(lastElementNode);
        observer.disconnect();
      }
    };
  }, [callback, lastElement, list, listLength]);
}
