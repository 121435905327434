import { AddUserImageToProjectEventTrigger, UploadProvider, VSCAssetImageSourceName } from 'js/types';
import { DragEvent } from 'react';

export const INTERNAL_DRAG_AND_DROP_ID = 'vsc-internal-resource';

type SetDragDataOptions = {
  effectAllowed?: DataTransfer['effectAllowed'];
  dataType?: string;
  dropEffect?: DataTransfer['dropEffect'];
};

export interface ImageLibraryDragData {
  imageId: string | number;
  contentType: string;
  scribeId: number;
  canvasSize: {
    width: number;
    height: number;
  };
  category: string | undefined;
  filename: string;
  provider: UploadProvider;
  imageLibrarySource?: VSCAssetImageSourceName;
  addEventTrigger?: AddUserImageToProjectEventTrigger;
  isPremium?: boolean;
}

const setDragData = <T>(e: DragEvent<HTMLElement>, data: T, options: SetDragDataOptions = {}) => {
  const { effectAllowed = 'copy', dataType = 'application/json', dropEffect = 'copy' } = options;

  e.dataTransfer.clearData();
  e.dataTransfer.effectAllowed = effectAllowed;
  e.dataTransfer.dropEffect = dropEffect;

  let dataToSet: string;
  if (dataType === 'application/json' && typeof data === 'object') {
    dataToSet = JSON.stringify({ id: INTERNAL_DRAG_AND_DROP_ID, ...data });
  } else {
    dataToSet = String(data);
  }
  e.dataTransfer.setData(dataType, dataToSet);
};

const setDragAppResourceToCanvasData = <T extends object>(e: DragEvent<HTMLElement>, data: T) => {
  const option: SetDragDataOptions = {
    effectAllowed: 'copy',
    dropEffect: 'copy',
    dataType: 'application/json'
  };
  setDragData(e, data, option);
};

const getDraggedData = <T = string>(e: DragEvent, dataType = 'application/json'): T | null => {
  const data = e.dataTransfer.getData(dataType);
  if (dataType === 'application/json') {
    try {
      const parsedData = JSON.parse(data);
      if (parsedData.id !== INTERNAL_DRAG_AND_DROP_ID) return null;
      delete parsedData.id;
      return parsedData as T;
    } catch (error) {
      console.error('Error parsing drag data:', error);
      return null;
    }
  }
  return data as T;
};

const getDraggedAppResourceData = <T extends object>(e: DragEvent) => {
  return getDraggedData<T>(e, 'application/json');
};

const getDragOperation = (e: DragEvent) => {
  return e.dataTransfer.effectAllowed;
};

/* 
  Drag data store is in protected mode until dropped, so we can't access it
  to differentiate dragging file for upload from dragging app resources.
  Accessing `effectAllowed` property is allowed and can be set when drag start
  https://html.spec.whatwg.org/multipage/dnd.html#dndevents
*/
const isDragAppResourceToCanvasAction = (e: DragEvent) => {
  return getDragOperation(e) === 'copy';
};

const getDraggedFiles = (e: DragEvent) => {
  return e.dataTransfer.files || [];
};

export {
  setDragData,
  setDragAppResourceToCanvasData,
  getDraggedData,
  getDraggedAppResourceData,
  isDragAppResourceToCanvasAction,
  getDraggedFiles
};
