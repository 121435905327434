import { pickPendingAutosave } from 'js/actionCreators/autoSaveActions';
import { PendingSave, RootState } from 'js/types';
import { CallEffect, SelectEffect, delay, select, put, PutEffect } from 'redux-saga/effects';

import sanitizeScribeForTransport from '../sagaHelpers/sanitizeScribeForTransport';

import { saveProject } from './saveProject';

export const AUTO_SAVE_INTERVAL = 2000;

export function getPendingSaveQueue(state: RootState, projectId: number): PendingSave {
  return state.autoSave.pendingSaveQueue[projectId];
}

export function* autoSavePollingSaga(
  projectId: number
): Generator<CallEffect | SelectEffect | PutEffect | Generator, void, PendingSave> {
  while (true) {
    yield delay(AUTO_SAVE_INTERVAL);
    const pendingSaveQueue = yield select(getPendingSaveQueue, projectId);

    if (pendingSaveQueue?.pendingSave) {
      const pendingProject = sanitizeScribeForTransport(pendingSaveQueue.pendingSave);
      yield put(pickPendingAutosave(projectId));
      yield saveProject(pendingProject);
    }
  }
}
