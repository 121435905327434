import { detect } from 'detect-browser';
import { SCENE_TRANSITION_KEY_NONE } from 'js/config/consts';
import { TIMELINE_HEIGHT } from 'js/config/defaults';
import {
  BLACK,
  MOUSE_DOWN_GRAY,
  MOUSE_OVER_GRAY,
  SCROLLBAR_FOREGROUND,
  TIMELINE_MARGIN_GRAY,
  WHITE
} from 'js/config/pixiColorCodes';
import ScribeElementModel from 'js/models/ScribeElementModel';
import { getLengthOfAllAssets, getSceneStartTimeAndLength } from 'js/playback/lib/Playback/helpers/timings';
import { ElementAnimationStageDurationKey, PlaybackImageResources, ScribeCameraElement } from 'js/types';
import isEqual from 'lodash.isequal';
import * as PIXI from 'pixi.js';
import SceneTransitionElementModel from 'js/models/SceneTransitionElementModel';

import { getActiveScene } from '../../../shared/helpers/scenesHelpers';
import { TimelinePropsConnected } from '../TimelineView';

import LayerDragManager from './LayerDragManager';
import SceneTransitionLayer from './SceneTransitionLayer';
import TimelineAudioLayerProject from './TimelineAudioLayerProject';
import TimelineAudioWaveform, { deleteWaveformCachedDataById, soundsPool } from './TimelineAudioWaveform';
import TimelineCameraLayer from './TimelineCameraLayer';
import timelineBus, {
  ELEMENT_MOVED,
  LAYER_CLICKED,
  LAYER_DOUBLE_CLICKED,
  LAYER_MOVE_BEGUN,
  LAYER_MOVE_ENDED,
  LayerZoomConfig,
  PLAYHEAD_CHANGE_HEIGHT,
  TIMELINE_SELECT_SCENE,
  WRAPPER_REBUILD_VIEW,
  WRAPPER_SCROLL_TO,
  WRAPPER_SWAP_LAYERS,
  WRAPPER_UPDATE_SIZE,
  WRAPPER_UPDATE_ZOOM
} from './TimelineControlBus';
import TimelineElementGrabHandle from './TimelineElementGrabHandle';
import TimelineElementLayer from './TimelineElementLayer';
import TimelineLayerTooltip from './TimelineLayerTooltip';
import TimelinePlayHead from './TimelinePlayhead';
import { TimelineTimingsComparisonModel } from './TimelineTimingsComparisonModel';
import TimelineTopMeasure from './TimelineTopMeasure';
import TimelineHelpers, {
  compareAudioClipArrays,
  createTimingsComparisonFromLayers,
  createTimingsComparisonFromProps,
  sceneHasTransition
} from './Utils/TimelineHelpers';
import { getTimelineHeight, setTimelineLocalStorage } from './Utils/localStorageHelpers';
import ViewControls from './ViewControls';
import ZoomManager from './ZoomManager';
import { ELEMENT_LAYER_PADDING, LEFT_ZOOM_MARGIN, TOP_ZOOM_MARGIN, VERTICAL_SCROLLBAR_GUTTER } from './consts';
import SceneGhost from './SceneGhost';
import SceneLabels, { BANNER_HEIGHT, LABEL_WIDTH } from './SceneLabels';
import TimelineAdvancedAudioManager from './TimelineAdvancedAudioManager';
import { Scrollbox } from './Utils/Scrollbox';
import { getBitmapRect } from './Utils/GetBitmapRect';
import TimelineAudioClip from './TimelineAudioClip';
import TimelineAdvancedAudioLayer from './TimelineAdvancedAudioLayer';

const browser = detect();
export const SCROLL_BOX_MARGIN = 30;
export const TIMINGS_CHANGED = 'TIMINGS_CHANGED';
export const SCENE_TRANSITION_LENGTH_CHANGED = 'SCENE_TRANSITION_LENGTH_CHANGED';
export const GRABHANDLE_GRAPHIC_WIDTH = 25;
export const SCROLLBOX_SEPERATION_HEIGHT = 12;

export const TIMELINE_LAYER_BACKGROUND_COLOUR = 0x2f2f2f;
export const TIMELINE_LAYER_FOREGROUND_COLOUR = 0x707070;
export const TIMELINE_LAYER_ACTIVE_LINE_WIDTH = 3;
export const TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR = 0x19a1c7;
export const TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR = 0xffb434;

export interface ScrollToProps {
  sceneElement: AllTimelineLayerTypes | SceneTransitionLayer;
  focus: 'x' | 'y' | 'both' | 'ySensitive' | 'xSensitive' | 'bothSensitive' | undefined;
  focusPosition?: 'start' | 'end';
  offset?: PIXI.Point;
}

export type AllTimelineLayerTypes = TimelineElementLayer | TimelineCameraLayer | SceneTransitionLayer;
export interface TimelineContextMenu {
  scribeId: number | string;
  elementId?: string;
  sceneId?: string;
  audioType?: string;
  type: 'element' | 'sceneTransition' | 'audio';
  mouseX: number;
  mouseY: number;
  duration?: number;
  clipId?: string;
}
export default class TimelineWrapper {
  public static DraggedGrabHandle: TimelineElementGrabHandle | null = null;
  public app: PIXI.Application;
  public timelineHolder?: PIXI.Container;
  public layers: Array<AllTimelineLayerTypes> = [];
  public topMeasure?: TimelineTopMeasure;
  public sceneLabels?: SceneLabels;
  public playheadLine?: TimelinePlayHead;
  public elementLayersHolder?: PIXI.Container;
  public audioElementLayersHolder?: PIXI.Container;
  public sceneGhostHolder?: PIXI.Container;
  public sceneGhostBGHolder?: PIXI.Container;
  public sceneGhostBGHolderMask?: PIXI.Graphics;
  private _currentZoom: PIXI.Point; // x: px per sec  y: px height per lane
  currentZoomTween?: gsap.core.Tween;
  public audioClipManager?: TimelineAdvancedAudioManager;
  focusedScrollbox: Scrollbox | undefined;
  audioScrollboxCenterTarget?: PIXI.Point;

  rateLimiter = false;
  queuedUpdateSize?: { forceUpdate: boolean; updateZoomHandles: boolean };
  sceneGhosts: SceneGhost[] = [];
  interactionManager: PIXI.InteractionManager;
  lastSelectedElementId?: string;
  extendedScribeLength = 0;
  public get currentZoom(): PIXI.Point {
    this._currentZoom.x = Math.max(this._currentZoom.x, 0.01);
    this._currentZoom.y = Math.max(this._currentZoom.y, 0.01);
    return new PIXI.Point(this._currentZoom.x, this._currentZoom.y);
  }
  public set currentZoom(point: PIXI.Point) {
    console.warn('THIS SHOULD ONLY EVER BE CALLED FROM A TEST');
    this._currentZoom.x = point.x;
    this._currentZoom.y = point.y;
  }

  public scrollBox?: Scrollbox;
  public audioScrollBox?: Scrollbox;
  public sceneLength = 0;
  public props: TimelinePropsConnected;
  private layerDragManager: LayerDragManager;
  public pinnedElementsHeight = 30;
  public imageResources?: PlaybackImageResources;
  public zoomManager: ZoomManager;
  public activeElements?: Array<AllTimelineLayerTypes>;
  private lastSelected?: AllTimelineLayerTypes;
  public topPadding: number;
  public leftPadding: number;
  private topMeasureMask: PIXI.Graphics;
  timelineLayerTooltip?: TimelineLayerTooltip;
  public leftZoomMargin?: PIXI.Graphics;
  multiDragIndicatorLayer: PIXI.Container;
  private viewControls: ViewControls;
  private screenWidthAndHeight: PIXI.Point;
  selectedAudioLayer: TimelineAudioLayerProject | null;
  public onContextClick: (context: TimelineContextMenu) => void;
  selected: Array<TimelineAudioLayerProject | SceneTransitionLayer | AllTimelineLayerTypes> = [];
  public isMac: boolean | undefined;
  projectAudioLayers: Array<TimelineAudioLayerProject>;
  projectAudioLayerHolder?: PIXI.Container;
  public projectAudioLayersHeight = 0;
  dragging = false;
  audioLayerHolder: PIXI.Container;
  audioScrollBoxEnabled = false;
  scrollBoxHeight = 0;
  calculatedAudioScrollBoxHeight = 0;
  scrollBoxHeightGrabHandle?: PIXI.Container;
  scrollBoxHeightGrabHandlePointerDown = false;
  scrollBoxHeightGrabHandlePointerStart = new PIXI.Point();
  handleLine: PIXI.Graphics;
  fullScrollablesHeight = 0;
  private lastFrameScrollBoxValues: { x: number; y: number } = { x: 0, y: 0 };
  private lastFrameAudioScrollBoxValues: { x: number; y: number } = { x: 0, y: 0 };
  private rightMarker = new PIXI.Graphics();

  constructor(
    app: PIXI.Application,
    props: TimelinePropsConnected,
    currentZoom: PIXI.Point,
    scrollBoxHeight: number | null | undefined,
    audioScrollBoxHeight: number | null | undefined,
    imageResources: PlaybackImageResources,
    onContextClick: (context: TimelineContextMenu) => void,
    _currentPosition?: PIXI.Point
  ) {
    this.projectAudioLayers = [];
    this.interactionManager = app.renderer.plugins.interaction;
    this.isMac = browser?.os?.toLowerCase().includes('mac');
    this.imageResources = imageResources;
    this.props = Object.assign(props);
    this.onContextClick = onContextClick;
    this.app = app;
    this._currentZoom = currentZoom.clone();
    this.app.renderer.plugins.interaction.cursorStyles.timeDrag = `col-resize`;
    this.app.renderer.plugins.interaction.cursorStyles.audioDrag = `col-resize`;

    this.topPadding = TOP_ZOOM_MARGIN;
    this.leftPadding = LEFT_ZOOM_MARGIN;
    this.screenWidthAndHeight = new PIXI.Point(0, 0);
    this.layerDragManager = new LayerDragManager(this);
    this.selectedAudioLayer = null;
    this.zoomManager = new ZoomManager(this);
    this.projectAudioLayerHolder = new PIXI.Container();

    this.addBusListeners();

    this.topMeasureMask = new PIXI.Graphics();

    this.viewControls = new ViewControls({
      onScrollToStart: this.scrollToBeginningClicked,
      onScrollToEnd: this.scrollToEnd,
      onHolisticView: this.showAll
    });
    this.handleLine = new PIXI.Graphics();
    this.audioLayerHolder = new PIXI.Container();

    this.multiDragIndicatorLayer = new PIXI.Container();
    this.multiDragIndicatorLayer.name = 'Multi Drag Indicator Layer';
    this.multiDragIndicatorLayer.x = 0;
    this.multiDragIndicatorLayer.y = 0;
    this.multiDragIndicatorLayer.visible = false;
    this.multiDragIndicatorLayer.interactive = false;
    this.app.stage.addChildAt(this.multiDragIndicatorLayer, this.app.stage.children.length);
    this.getScrollBoxHeights(scrollBoxHeight, audioScrollBoxHeight);

    this.updateImageResources(imageResources);
    this.addView();
    this.addZoomControl();
    this.updateSize(true);
    PIXI.Ticker.shared.add(this.update);

    this.zoomManager.updateZoom({ ...this._currentZoom, forceUpdate: true });

    const timelineHeight = getTimelineHeight(this.props.activeScribe.id) || TIMELINE_HEIGHT;
    if (this.activeElements && this.activeElements.length > 0) {
      this.scrollTo({ sceneElement: this.activeElements[0], focus: 'bothSensitive', focusPosition: 'start' });
    } else {
      this.scrollToBeginning();
    }
    console.log(
      'remove this timelineheight check when v1 is deprecated - is to satisy deadcode requirements in pipeline',
      timelineHeight
    );
    this.rightMarker.clear();
    this.rightMarker.beginFill(0x000000, 0.01);
    this.rightMarker.drawRect(0, 0, 1, 100);
    this.rightMarker.endFill();
  }

  public getAudioScrollBoxEnabled() {
    const currentScene = getActiveScene(this.props.activeScribe);
    if (!currentScene) return false;
    if (
      (currentScene.audioLayerIds === undefined || currentScene.audioLayerIds?.length === 0) &&
      (!this.props.activeScribe.projectAudioLayerIds || this.props.activeScribe.projectAudioLayerIds?.length === 0)
    ) {
      return false;
    }
    return true;
  }

  addBusListeners() {
    timelineBus.removeAllListeners();
    timelineBus.addListener(WRAPPER_SCROLL_TO, this.scrollTo, this);
    timelineBus.addListener(WRAPPER_UPDATE_ZOOM, this.updateCurrentZoom, this);
    timelineBus.addListener(WRAPPER_UPDATE_SIZE, this.updateSize, this);
    timelineBus.addListener(WRAPPER_REBUILD_VIEW, this.rebuildView, this);
    timelineBus.addListener(ELEMENT_MOVED, this.elementMoved, this);
    timelineBus.addListener(WRAPPER_SWAP_LAYERS, this.swapLayers, this);
    timelineBus.addListener(TIMELINE_SELECT_SCENE, this.clearSelection, this);
  }

  public getScrollBoxHeights(scrollBoxHeight?: number | null, audioScrollBoxHeight?: number | null) {
    const currentScene = getActiveScene(this.props.activeScribe);
    if (!currentScene) return;

    this.projectAudioLayersHeight = this.currentZoom.y;
    this.fullScrollablesHeight =
      this.app.screen.height -
      (this.pinnedElementsHeight + this.topPadding + BANNER_HEIGHT + SCROLLBOX_SEPERATION_HEIGHT);

    if (this.getAudioScrollBoxEnabled()) {
      if (audioScrollBoxHeight) {
        this.calculatedAudioScrollBoxHeight = audioScrollBoxHeight;
      }

      const projClipsLength = this.props.activeScribe.projectAudioLayerIds?.length ?? 0;
      const sceneClipsLength = currentScene?.audioLayerIds?.length ?? 0;
      const additionalLayers = 2;
      this.projectAudioLayersHeight = (projClipsLength + 1) * this.currentZoom.y;
      const calculatedAudioScrollboxHeight =
        (projClipsLength + sceneClipsLength + additionalLayers) * this.currentZoom.y;
      if (
        this.calculatedAudioScrollBoxHeight === 0 ||
        this.calculatedAudioScrollBoxHeight > calculatedAudioScrollboxHeight
      ) {
        this.calculatedAudioScrollBoxHeight = calculatedAudioScrollboxHeight;
      }
      const audioScrollBoxTopBound =
        this.app.screen.height -
        ((this.scrollBox?.y ?? 0) + this.currentZoom.y + SCROLLBOX_SEPERATION_HEIGHT + TOP_ZOOM_MARGIN);

      if (this.calculatedAudioScrollBoxHeight > audioScrollBoxTopBound) {
        this.calculatedAudioScrollBoxHeight = audioScrollBoxTopBound;
      } else if (this.calculatedAudioScrollBoxHeight < this.currentZoom.y) {
        this.calculatedAudioScrollBoxHeight = this.currentZoom.y;
      }

      this.scrollBoxHeight = Math.max(
        this._currentZoom.y,
        this.fullScrollablesHeight - this.calculatedAudioScrollBoxHeight - SCROLLBOX_SEPERATION_HEIGHT
      );
    } else {
      this.scrollBoxHeight = scrollBoxHeight ? scrollBoxHeight : this.fullScrollablesHeight;
      this.calculatedAudioScrollBoxHeight = 0;
    }
    if (this.scrollBoxHeightGrabHandle) {
      this.scrollBoxHeightGrabHandle.visible = this.calculatedAudioScrollBoxHeight > 0;
    }
    this.saveScrollbarHeightsToLocalStorage();
  }

  private saveScrollbarHeightsToLocalStorage() {
    if (this.scrollBox?.content) {
      const projectId = this.props.activeScribe.id;
      setTimelineLocalStorage(projectId, 'scrollBoxHeight', this.scrollBoxHeight.toString());
      setTimelineLocalStorage(projectId, 'audioScrollBoxHeight', this.calculatedAudioScrollBoxHeight.toString());
    }
  }

  private saveZoomToLocalStorage() {
    if (this.scrollBox?.content) {
      const projectId = this.props.activeScribe.id;
      if (this.currentZoom.x === 0) this.currentZoom.x = 0.01;
      if (this.currentZoom.y === 0) this.currentZoom.y = 0.01;
      const currentZoom = JSON.stringify({ x: this.currentZoom.x, y: this.currentZoom.y });

      setTimelineLocalStorage(projectId, 'zoom', currentZoom);
    }
  }

  private savePositionToLocalStorage() {
    if (this.scrollBox?.content) {
      const projectId = this.props.activeScribe.id;

      const currentPosition = JSON.stringify({
        x: this.scrollBox.content.center.x,
        y: this.scrollBox.content.center.y
      });

      setTimelineLocalStorage(projectId, 'position', currentPosition);
    }
  }

  public updatedProps(
    incomingProps: TimelinePropsConnected,
    forceFullRedraw = false,
    imageResources: PlaybackImageResources
  ) {
    this.updateImageResources(imageResources);
    if (this.scrollBox === undefined) return;
    const center = this.scrollBox.content.center.clone();
    const slimmedPreviousProps: Array<TimelineTimingsComparisonModel> = createTimingsComparisonFromLayers(this.layers);
    const slimmedProps: Array<TimelineTimingsComparisonModel> = createTimingsComparisonFromProps(incomingProps);
    const difference = slimmedProps.filter(
      (slimmedProp, propIndex) =>
        !slimmedPreviousProps.some(
          (slimmedPreviousProp, prevPropIndex) =>
            slimmedProp.id === slimmedPreviousProp.id &&
            isEqual(slimmedProp, slimmedPreviousProp) &&
            prevPropIndex === propIndex
        )
    );

    const currentScene = getActiveScene(this.props.activeScribe);
    const incomingScene = getActiveScene(incomingProps.activeScribe);
    const elementsOrderAreTheSame = currentScene?.elementIds.every(
      (elId, index) => incomingScene?.elementIds[index] === elId
    );

    const scrollToNewElement =
      incomingProps.sceneElements.length > this.props.sceneElements.length &&
      (incomingProps.activeElements.length > 0 || this.props.activeElements.length > 0);
    if (
      forceFullRedraw ||
      difference === undefined ||
      !elementsOrderAreTheSame ||
      (this.props.activeScribe.audioClips &&
        incomingProps.activeScribe.audioClips &&
        !compareAudioClipArrays(this.props.activeScribe.audioClips, incomingProps.activeScribe.audioClips)) ||
      (currentScene?.settings &&
        currentScene?.settings.sceneTransitionType !== incomingScene?.settings.sceneTransitionType)
    ) {
      // Rebuild the view if the scene has changed or the elements have changed
      this.props = Object.assign(incomingProps);
      this.rebuildView();
      if (incomingProps.activeElements.length === 0 && !incomingProps.selectedAudioClip) {
        this.scrollToBeginning();
      }
      center.x = this.scrollBox.content.center.x;
      center.y = this.scrollBox.content.center.y;
    } else {
      if (!isEqual(incomingProps.activeElements, this.props.activeElements)) {
        this.updateActiveElements(incomingProps);
      } else {
        this.props = Object.assign(incomingProps);
        if (difference !== undefined && difference.length > 0) {
          this.refreshView(incomingProps, difference);
        }
      }
    }

    if (this.activeElements && this.activeElements.length > 0) {
      if (scrollToNewElement) {
        this.activeElements.sort((a, b) => {
          if (a.zIndex > b.zIndex) return 1;
          if (a.zIndex < b.zIndex) return -1;
          return 0;
        });
      }
      if (this.activeElements.length > 1) {
        // there are multiple elements, scroll to the first one
        if (this.activeElements[0].y < this.scrollBox.content.center.y - this.scrollBox.boxHeight / 2) {
          // if the first element is above the center of the scrollbox, scroll to it
          this.scrollTo({ sceneElement: this.activeElements[0], focus: 'bothSensitive' });
        } else if (
          this.activeElements[this.activeElements.length - 1].y >
          this.scrollBox.content.center.y + this.scrollBox.boxHeight / 2
        ) {
          // if the last element is below the center of the scrollbox, scroll to it
          this.scrollTo({
            sceneElement: this.activeElements[this.activeElements.length - 1],
            focus: 'bothSensitive'
          });
        } else {
          // if the elements are within the scrollbox, scroll to the first one
          this.scrollTo({ sceneElement: this.activeElements[0], focus: 'bothSensitive' });
        }
      } else {
        // there is only one element, scroll to it
        if (this.lastSelectedElementId !== this.activeElements[0].id) {
          this.scrollTo({ sceneElement: this.activeElements[0], focus: 'bothSensitive', focusPosition: 'start' });
        } else {
          this.scrollTo({ sceneElement: this.activeElements[0], focus: 'bothSensitive' });
        }
        this.lastSelectedElementId = this.activeElements[0].id;
      }
    }
    this.manageSelectionState(incomingProps);
  }

  manageSelectionState(incomingProps: TimelinePropsConnected) {
    this.selected = [];

    if (incomingProps.activeElements && incomingProps.activeElements.length) {
      // Find the element layers and set selected to it
      this.selected = this.layers.filter(layer => incomingProps.activeElements.includes(layer.id));
      this.selectedAudioLayer = null;
    } else if (incomingProps.selectedAudioClip) {
      this.selectedAudioLayer =
        this.projectAudioLayers.find(
          currentLayer => currentLayer.assetId === incomingProps.selectedAudioClip?.assetId.toString()
        ) || null;
      // Set the audio layer to selected
      this.selectedAudioLayer && this.selected.push(this.selectedAudioLayer);
    }

    [...this.layers, ...this.projectAudioLayers]
      .filter((layer): layer is SceneTransitionLayer | AllTimelineLayerTypes | TimelineAudioLayerProject => {
        return (
          layer instanceof SceneTransitionLayer ||
          layer instanceof TimelineElementLayer ||
          layer instanceof TimelineAudioLayerProject ||
          layer instanceof TimelineCameraLayer
        );
      })
      .forEach(layer => {
        if (this.selected.includes(layer)) {
          layer.select();
        } else {
          layer.deselect();
        }
      });
  }

  private updateImageResources(imageResources: PlaybackImageResources) {
    this.layers.forEach(layer => {
      if (layer.type === 'Element') {
        layer.updateImageResources(imageResources);
      }
    });
    this.imageResources = imageResources;
  }

  public clearSelection() {
    this.selectedAudioLayer = null;
    this.props.onClearSelection();
  }

  private rebuildView(sceneHasChanged = false) {
    if (sceneHasChanged) this.clearSelection();

    if (this.scrollBox) this.scrollBox.disable = true; //this stops aff of the plugins firing when the values arent yet correct
    this.destroyView();
    this.addView();
    this.addZoomControl();
    this.zoomManager.updateZoom({ ...this._currentZoom, forceUpdate: true });

    if (this.scrollBox) this.scrollBox.disable = false; // re-enable the plugins

    if (sceneHasChanged && this.scrollBox) {
      this.scrollInToView();
    }
  }

  private refreshView(props: TimelinePropsConnected, updatedElements?: Array<TimelineTimingsComparisonModel>) {
    const currentScrollPos = this.scrollBox?.content.center;
    const currentScene = getActiveScene(this.props.activeScribe);
    if (updatedElements !== undefined) {
      if (currentScene === undefined) return;
      const startOffset = getSceneStartTimeAndLength(this.props.activeScribe, currentScene);
      const newSceneLength = startOffset.sceneLength / 1000;
      let updateAllLayerLengths = false;
      const newExtendedScribeLength = getLengthOfAllAssets(this.props.activeScribe);

      if (this.sceneLength !== newSceneLength || this.extendedScribeLength !== newExtendedScribeLength) {
        this.sceneLength = newSceneLength;
        this.extendedScribeLength = newExtendedScribeLength;
        updateAllLayerLengths = true;
        if (this.topMeasure) {
          this.topMeasure.updateScribeLength(this.extendedScribeLength, this.currentZoom);
        }
      }

      updatedElements.forEach(updatedElement => {
        const layerToChange = this.layers.find(({ id }) => id === updatedElement.id);
        const elementModel = this.props.activeScribe.elements.find(({ id }) => id === updatedElement.id);
        if (layerToChange !== undefined && elementModel !== undefined) {
          if (elementModel.type === 'Camera' && layerToChange.type === 'Camera') {
            layerToChange.updateProperties(updatedElement, elementModel);
          } else if (elementModel.type !== 'Camera' && layerToChange.type !== 'Camera') {
            layerToChange.updateProperties(updatedElement, elementModel);
          }
        }
      });
      if (updateAllLayerLengths) {
        this.layers.forEach(layer => {
          layer.updateScribeLength(this.sceneLength);
        });

        this.projectAudioLayers.forEach(layer => {
          layer.updateScribeLength(this.sceneLength);
        });

        if (this.sceneGhostHolder) {
          this.drawSceneGhosts();
        }
      }
    }

    this.layers.forEach(layer => {
      if (layer instanceof TimelineElementLayer || layer instanceof SceneTransitionLayer) {
        layer.updateScribe(props.activeScribe);
      }
    });
    this.zoomManager.updateMaxMinZoomX();

    this.zoomManager.updateZoom({ ...this.currentZoom, forceUpdate: true });
    if (this.scrollBox && currentScrollPos) {
      this.scrollBox.content.moveCenter(currentScrollPos);
      this.scrollBox.update();
      if (this.audioScrollBox) {
        this.audioScrollBox.content.x = this.scrollBox.content.x;
        this.audioScrollBox?.update();
      }
    }
  }

  private updateCurrentZoom({ x, y }: LayerZoomConfig, forceUpdate = false) {
    if (x === undefined || (x !== undefined && this._currentZoom.x === x)) {
      if (y === undefined || (y !== undefined && this._currentZoom.y === y)) {
        if (forceUpdate !== true) return;
      }
    }
    if (this.scrollBox) {
      this._currentZoom.x = x === undefined ? this.currentZoom.x : x;
      this._currentZoom.y = y === undefined ? this.currentZoom.y : y;
      this._currentZoom.x = Math.max(this._currentZoom.x, 0.01);
      this._currentZoom.y = Math.max(this._currentZoom.y, 0.01);

      this.getScrollBoxHeights();

      this.updateSize(true);

      this.layers.forEach((layer, index) => {
        layer.updateZoom();
        layer.setY((this.layers.length - 1 - index) * this.currentZoom.y);
        layer.redraw();
      });

      if (this.elementLayersHolder && this.topMeasure) {
        this.topMeasure?.updateZoom({ x: this.currentZoom.x });
      }
      if (this.elementLayersHolder && this.sceneLabels) {
        this.sceneLabels?.updateZoom();
      }
      this.sceneGhosts.forEach(ghost => {
        const numLayersInView = Math.round((this.scrollBox?.boxHeight ?? 0) / this.currentZoom.y);
        ghost.updateZoom(this.currentZoom, numLayersInView);
      });

      this.audioClipManager?.updateZoom(this.currentZoom);
    }
    this.saveZoomToLocalStorage();
  }

  public destroyView() {
    this.app.view.removeEventListener('wheel', this.zoomManager?.mouseWheel);
    if (this.topMeasure) this.topMeasure.destroy({ children: true });
    TimelineWrapper.DraggedGrabHandle = null;
    this.zoomManager.destroy();
    this.sceneGhosts.forEach(ghost => ghost.destroy());
    this.sceneGhosts = [];

    if (this.sceneGhostHolder) {
      this.sceneGhostHolder.destroy();
      this.sceneGhostHolder = undefined;
    }
    if (this.sceneGhostBGHolder) {
      this.sceneGhostBGHolder.destroy();
      this.sceneGhostBGHolder = undefined;
    }
    if (this.sceneGhostBGHolderMask) {
      this.sceneGhostBGHolderMask.destroy();
      this.sceneGhostBGHolderMask = undefined;
    }
    if (this.elementLayersHolder) {
      this.elementLayersHolder.destroy();
      this.elementLayersHolder = undefined;
    }
    if (this.sceneLabels) {
      this.sceneLabels.destroy();
      this.sceneLabels = undefined;
    }

    this.layers.forEach((layer, i) => {
      layer.destroy({ children: true });
      delete this.layers[i];
    });

    this.pinnedElementsHeight = 30;
    if (this.timelineHolder) this.timelineHolder.destroy();

    this.layers = [];
    for (const layer of this.projectAudioLayers) {
      layer.waveform.destroy();
    }
    this.projectAudioLayers = [];
  }

  private addView() {
    this.timelineHolder = new PIXI.Container();
    this.timelineHolder.interactive = true;
    this.timelineHolder.interactiveChildren = true;

    const topMarginBackground = new PIXI.Graphics();
    topMarginBackground.width = this.app.renderer.width;
    topMarginBackground.height = this.topPadding + this.pinnedElementsHeight;
    topMarginBackground.x = 0;
    topMarginBackground.y = 0;
    topMarginBackground.beginFill(TIMELINE_MARGIN_GRAY);
    topMarginBackground.drawRect(0, 0, this.app.renderer.width, this.topPadding + this.pinnedElementsHeight);
    this.timelineHolder?.addChild(topMarginBackground);

    const leftMarginBackground = new PIXI.Graphics();
    leftMarginBackground.width = this.leftPadding;
    leftMarginBackground.x = 0;
    leftMarginBackground.y = 0;
    leftMarginBackground.height = this.app.renderer.height;
    leftMarginBackground.beginFill(TIMELINE_MARGIN_GRAY);
    leftMarginBackground.drawRect(0, 0, this.leftPadding, this.app.renderer.height);
    this.timelineHolder?.addChild(leftMarginBackground);
    this.leftZoomMargin = leftMarginBackground;

    this.timelineHolder.name = 'Timeline Holder';
    this.app.stage.addChild(this.timelineHolder);
    this.app.stage.name = 'Stage';

    this.zoomManager.addInput();
    this.layers = [];
    const scrollBoxRightPosition = this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER;
    const scrollBoxLeftPosition = LEFT_ZOOM_MARGIN;

    this.timelineLayerTooltip = new TimelineLayerTooltip(scrollBoxRightPosition, scrollBoxLeftPosition);

    this.createLayers();
    this.app.stage.addChild(this.viewControls);

    this.update(0);
  }

  private addZoomControl() {
    this.zoomManager.addZoomControl();
    this.app.view.removeEventListener('wheel', this.zoomManager.mouseWheel);
    this.app.view.addEventListener('wheel', this.zoomManager.mouseWheel);
    this.updateSize(true, true);
    this.zoomManager.setInitialZoom();
    if (!this.playheadLine) {
      this.playheadLine = new TimelinePlayHead();
    }
    this.timelineHolder?.addChild(this.playheadLine);
    this.updateZoomManagerAndZoomHandles();
  }

  private createLayers() {
    this.getScrollBoxHeights();
    const currentScene = getActiveScene(this.props.activeScribe);
    if (currentScene === undefined || !this.imageResources) return;

    const startOffset = getSceneStartTimeAndLength(
      this.props.activeScribe,
      this.props.activeScribe.scenes[this.props.activeScribe.scenes.length - 1]
    );
    this.sceneLength = (startOffset.sceneLength + startOffset.sceneStartTime) / 1000;
    const sceneStartOffset = getSceneStartTimeAndLength(this.props.activeScribe, currentScene);
    const currentSceneLength = sceneStartOffset.sceneLength / 1000;
    const startTime = sceneStartOffset.sceneStartTime / 1000;
    this.lastFrameAudioScrollBoxValues = { x: 0, y: 0 };
    this.lastFrameScrollBoxValues = { x: 0, y: 0 };
    if (!this.scrollBox) {
      this.scrollBox = new Scrollbox({
        name: 'Scrollbox',
        boxWidth: this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER - this.leftPadding,
        boxHeight: this.scrollBoxHeight,
        dragScroll: false,
        stopPropagation: false,
        scrollbarBackground: BLACK,
        scrollbarSize: 6,
        scrollbarForeground: SCROLLBAR_FOREGROUND,
        scrollbarMouseDown: MOUSE_DOWN_GRAY,
        scrollbarMouseOver: MOUSE_OVER_GRAY,
        underflow: 'bottom-left',
        noTicker: true,
        interaction: this.interactionManager
      });
    }
    this.scrollBox.content.removeChildren();
    this.scrollBox.content.removeAllListeners();

    this.scrollBox.y = this.pinnedElementsHeight + this.topPadding + 25;
    this.scrollBox.x = this.leftPadding;
    this.audioElementLayersHolder = new PIXI.Container();

    if (!this.audioScrollBox) {
      this.audioScrollBox = new Scrollbox({
        name: 'Audio Scrollbox',
        boxWidth: this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER,
        boxHeight: this.calculatedAudioScrollBoxHeight,
        dragScroll: false,
        stopPropagation: false,
        scrollbarBackground: BLACK,
        scrollbarSize: 6,
        scrollbarForeground: SCROLLBAR_FOREGROUND,
        scrollbarMouseDown: MOUSE_DOWN_GRAY,
        scrollbarMouseOver: MOUSE_OVER_GRAY,
        underflow: 'center',
        overflowX: 'hidden',
        overflowY: 'scroll',
        noTicker: true,
        interaction: this.interactionManager
      });
    }

    this.audioScrollBox.content.removeChildren();
    this.audioScrollBox.content.removeAllListeners();
    this.audioScrollBox.y = this.pinnedElementsHeight + this.topPadding + this.scrollBox.boxHeight;
    this.audioScrollBox.x = this.leftPadding;

    this.audioScrollBox.content.clampZoom({
      minScale: { x: 1, y: 1 },
      maxScale: { x: 1, y: 1 }
    });

    this.scrollBox.content.wheel({ axis: 'all' });

    this.audioScrollBox.content.on('moved', () => {
      this.audioToMainBoxSync();
    });

    this.audioScrollBox.content.wheel({ axis: 'all' });

    this.scrollBox.content.on('moved', () => {
      this.mainToAudioBoxSync();
    });

    this.scrollBox.content.clampZoom({
      minScale: { x: 1, y: 1 },
      maxScale: { x: 1, y: 1 }
    });

    this.audioElementLayersHolder.x = ELEMENT_LAYER_PADDING;
    this.audioScrollBox.content.addChild(this.audioElementLayersHolder);
    if (this.projectAudioLayerHolder) {
      this.audioScrollBox.content.addChild(this.projectAudioLayerHolder);
      this.projectAudioLayerHolder.x = ELEMENT_LAYER_PADDING;
      this.projectAudioLayerHolder.y = this.audioElementLayersHolder.y + this.audioElementLayersHolder.height;
    }
    this.timelineHolder?.addChild(this.audioScrollBox);
    //add a horizontal grab handle between the scrollbox and the audioScrollbox, dragging will resize both boxes
    this.scrollBoxHeightGrabHandle = new PIXI.Container();
    this.scrollBoxHeightGrabHandle.interactive = true;
    this.scrollBoxHeightGrabHandle.buttonMode = true;
    this.scrollBoxHeightGrabHandle.cursor = 'row-resize';
    this.scrollBoxHeightGrabHandle.on('pointerdown', this.handleScrollBoxHeightGrabHandlePointerDown, this);
    this.scrollBoxHeightGrabHandle.on('pointerup', this.handleScrollBoxHeightGrabHandlePointerUp, this);
    this.scrollBoxHeightGrabHandle.on('pointerupoutside', this.handleScrollBoxHeightGrabHandlePointerUp, this);
    this.scrollBoxHeightGrabHandle.on('pointermove', this.handleScrollBoxHeightGrabHandlePointerMove, this);
    this.scrollBoxHeightGrabHandle.x = this.scrollBox?.x;

    this.scrollBoxHeightGrabHandle.width = this.app.screen.width;
    this.scrollBoxHeightGrabHandle.height = SCROLLBOX_SEPERATION_HEIGHT;
    const handleGraphics = new PIXI.Graphics();
    handleGraphics.beginFill(0x0000);
    handleGraphics.drawRect(0, 0, this.audioScrollBox.boxWidth - LEFT_ZOOM_MARGIN, SCROLLBOX_SEPERATION_HEIGHT);
    handleGraphics.endFill();

    this.scrollBoxHeightGrabHandle.addChild(handleGraphics);
    this.scrollBoxHeightGrabHandle.addChild(this.handleLine);
    this.timelineHolder?.addChild(this.scrollBoxHeightGrabHandle);

    this.audioScrollBox.content.x = this.scrollBox.content.x;

    if (this.projectAudioLayerHolder) {
      if (this.audioClipManager === undefined) {
        this.audioClipManager = new TimelineAdvancedAudioManager(
          this,
          this.audioElementLayersHolder,
          this.projectAudioLayerHolder
        );
      } else {
        this.audioClipManager.updateContainers(this.audioElementLayersHolder, this.projectAudioLayerHolder);
      }
    }
    this.scrollBox.scrollbarOffsetVertical = this.calculatedAudioScrollBoxHeight;
    this.sceneGhostBGHolder = new PIXI.Container();
    this.timelineHolder?.addChild(this.sceneGhostBGHolder);

    this.sceneGhostHolder = new PIXI.Container();
    this.sceneGhostHolder.x = ELEMENT_LAYER_PADDING;
    this.scrollBox.content.addChild(this.sceneGhostHolder);

    this.elementLayersHolder = new PIXI.Container();
    this.elementLayersHolder.x = ELEMENT_LAYER_PADDING;
    this.scrollBox.content.addChild(this.elementLayersHolder);

    this.timelineHolder?.addChild(this.scrollBox);
    this.extendedScribeLength = getLengthOfAllAssets(this.props.activeScribe);

    this.topMeasure = new TimelineTopMeasure(0, this.extendedScribeLength, this.topMeasureMask);
    this.topMeasure.x = this.scrollBox.x + this.elementLayersHolder.x;
    this.topMeasure.y = this.topPadding;
    this.timelineHolder?.addChild(this.topMeasure);
    this.timelineHolder?.addChild(this.topMeasureMask);

    this.sceneLabels = new SceneLabels(
      this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER - LEFT_ZOOM_MARGIN,
      this,
      this.scrollBox.content.x + this.scrollBox.x + this.elementLayersHolder.x,
      currentScene.id
    );
    this.sceneLabels.x = LEFT_ZOOM_MARGIN;
    this.sceneLabels.y = this.pinnedElementsHeight + this.topPadding;
    this.timelineHolder?.addChild(this.sceneLabels);

    this.activeElements = [];

    const elements = this.props.sceneElements.concat();

    if (sceneHasTransition(currentScene)) {
      elements.unshift({
        type: 'SceneTransition',
        id: 'sceneTransition',
        sceneTransitionTime: currentScene.settings?.sceneTransitionTime || 0,
        sceneTransitionType: currentScene.settings?.sceneTransitionType || SCENE_TRANSITION_KEY_NONE
      } as SceneTransitionElementModel);
    }

    this.layers.forEach(layer => {
      layer.destroy();
    });
    let i = 0;
    let cameraCount = 0;
    if (currentScene === undefined || !this.imageResources || !this.activeElements) return;

    for (i; i < elements.length; i++) {
      const sceneElement = elements[i];
      const isActiveElement = !!this.props.activeElements.find((element: string) => element === sceneElement.id);
      let layerNode: TimelineElementLayer | TimelineCameraLayer | SceneTransitionLayer;
      if (sceneElement.type === 'SceneTransition') {
        layerNode = new SceneTransitionLayer({
          elementModel: sceneElement,
          sceneID: currentScene.id,
          scribe: this.props.activeScribe,
          isActive: isActiveElement,
          imageResources: this.imageResources,
          sceneStartTime: startTime,
          currentSceneLength: currentSceneLength,

          wrapperRef: this,
          sceneTransitionTime: currentScene.settings?.sceneTransitionTime || 0,
          sceneTransitionType: currentScene.settings?.sceneTransitionType || SCENE_TRANSITION_KEY_NONE,
          cursors: this.props.cursors
        });
      } else if (sceneElement.type === 'Camera') {
        cameraCount++;
        layerNode = new TimelineCameraLayer({
          elementModel: sceneElement,
          sceneID: currentScene.id,
          scribe: this.props.activeScribe,
          sceneStartTime: startTime,
          currentSceneLength: currentSceneLength,
          isActive: isActiveElement,

          wrapperRef: this,
          imageResources: this.imageResources,
          cameraNumber: cameraCount
        });
        layerNode.on(LAYER_DOUBLE_CLICKED, this.handleCameraDoubleClick, this);
      } else {
        layerNode = new TimelineElementLayer({
          elementModel: sceneElement,
          sceneID: currentScene.id,
          scribe: this.props.activeScribe,
          sceneStartTime: startTime,
          currentSceneLength: currentSceneLength,
          isActive: isActiveElement,
          wrapperRef: this,
          imageResources: this.imageResources,
          cursors: this.props.cursors
        });

        layerNode.on(LAYER_DOUBLE_CLICKED, this.handleElementDoubleClick, this);
      }

      if (isActiveElement) this.activeElements.push(layerNode);
      const yPos = (elements.length - i) * this.currentZoom.y;
      if (layerNode.thumb) layerNode.thumb.y = yPos;
      layerNode.y = yPos;
      if (layerNode instanceof SceneTransitionLayer) {
        layerNode.on(SCENE_TRANSITION_LENGTH_CHANGED, this.sceneTransitionChanged);
        layerNode.on(LAYER_CLICKED, this.handleLayerClick, this);
      } else {
        layerNode.on(LAYER_MOVE_BEGUN, this.layerMoveBegun, this);
        layerNode.on(LAYER_MOVE_ENDED, this.layerMoveEnded);
        layerNode.on(TIMINGS_CHANGED, this.timingsChanged);
        layerNode.on(LAYER_CLICKED, this.handleLayerClick, this);
      }

      this.layers.push(layerNode);
      this.elementLayersHolder?.addChild(layerNode);
    }

    if (!!this.props.activeScribe?.audioClips?.length && this.elementLayersHolder) {
      for (const a in soundsPool) {
        const matchingClip = this.props.activeScribe?.audioClips.find(audioClip => {
          return audioClip.assetId.toString() === a;
        });
        if (!matchingClip) {
          deleteWaveformCachedDataById(a);
        }
      }

      this.audioClipManager?.rebuildView();
    }
    if (this.timelineLayerTooltip && this.timelineHolder) this.timelineHolder.addChild(this.timelineLayerTooltip);

    this.drawSceneGhosts();
    this.scrollBox.content.setZoom(1);
    this.scrollBox.update();
    this.audioScrollBox?.update();
    this.updateSceneGhostHolderMask();
  }
  audioToMainBoxSync = () => {
    if (this.scrollBox && this.audioScrollBox) {
      const mainCorner = this.scrollBox.content.corner;
      const audioCorner = this.audioScrollBox.content.corner;
      if (mainCorner.x !== audioCorner.x) {
        this.scrollBox.content.moveCorner(audioCorner.x, mainCorner.y);
      }
    }
  };

  mainToAudioBoxSync = () => {
    if (this.scrollBox && this.audioScrollBox) {
      const mainCorner = this.scrollBox.content.corner;
      const audioCorner = this.audioScrollBox.content.corner;
      if (mainCorner.x !== audioCorner.x) {
        this.audioScrollBox.content.moveCorner(mainCorner.x, audioCorner.y);
        this.audioScrollBox.updateTransform();
        this.audioScrollBox.update();
      }
    }
  };

  drawSceneGhosts() {
    const currentScene = getActiveScene(this.props.activeScribe);
    if (!this.sceneGhostHolder || !currentScene) return;

    this.sceneGhosts.forEach(child => {
      child.destroy();
    });
    this.sceneGhosts = [];

    const numLayersInView = Math.round((this.scrollBox?.boxHeight ?? 0) / this.currentZoom.y);

    if (this.sceneGhostBGHolder) {
      for (let sceneIndex = 0; sceneIndex < this.props.activeScribe.scenes.length; sceneIndex++) {
        const scene = this.props.activeScribe.scenes[sceneIndex];
        const sceneGhost = new SceneGhost(
          this.props.activeScribe,
          scene,
          this.layers.length,
          numLayersInView,
          this.currentZoom,
          scene && scene.id === currentScene.id,
          scene && sceneIndex === this.props.activeScribe.scenes.length - 1,
          this.sceneLength,
          this.scrollBox?.boxWidth || 0,
          this.sceneGhostBGHolder
        );
        this.sceneGhosts.push(sceneGhost);
        this.sceneGhostHolder.addChild(sceneGhost);
      }
    }

    this.adjustElementHolderHeightForSceneGhosts();
    this.updateSceneGhostHolderMask();
  }

  updateSceneGhostHolderMask() {
    if (!this.sceneGhostBGHolder || !this.scrollBox) return;
    if (!this.sceneGhostBGHolderMask) this.sceneGhostBGHolderMask = getBitmapRect(this.scrollBox.boxHeight, 0x000000);
    this.sceneGhostBGHolderMask.width = this.scrollBox.boxWidth;
    this.sceneGhostBGHolderMask.height = this.scrollBox.boxHeight;
    this.sceneGhostBGHolderMask.x = this.scrollBox.x;
    this.sceneGhostBGHolderMask.y = this.scrollBox.y;

    this.sceneGhostBGHolder.addChild(this.sceneGhostBGHolderMask);
    this.sceneGhostBGHolder.mask = this.sceneGhostBGHolderMask;
  }

  handleScrollBoxHeightGrabHandlePointerDown(event: PIXI.InteractionEvent) {
    if (!this.scrollBoxHeightGrabHandle) return;
    this.scrollBoxHeightGrabHandlePointerDown = true;
    this.scrollBoxHeightGrabHandlePointerStart = event.data.getLocalPosition(this.scrollBoxHeightGrabHandle.parent);
  }

  handleScrollBoxHeightGrabHandlePointerUp = (event: PIXI.InteractionEvent) => {
    this.handleScrollBoxHeightGrabHandlePointerMove(event);
    this.scrollBoxHeightGrabHandlePointerDown = false;
  };

  handleScrollBoxHeightGrabHandlePointerMove = (event: PIXI.InteractionEvent) => {
    if (
      !this.scrollBoxHeightGrabHandle ||
      !this.scrollBoxHeightGrabHandlePointerDown ||
      !this.scrollBox ||
      !this.audioScrollBox
    )
      return;

    const currentScene = getActiveScene(this.props.activeScribe);
    const currentPos = event.data.getLocalPosition(this.scrollBoxHeightGrabHandle.parent);
    const currentScrollBoxPosition = this.scrollBox.content.x;
    const deltaY = currentPos.y - this.scrollBoxHeightGrabHandlePointerStart.y;
    const spareAudioLayers = 2;
    const audioLayerHeight =
      spareAudioLayers +
      (currentScene?.audioLayerIds?.length ?? 0) +
      (this.props.activeScribe.projectAudioLayerIds?.length ?? 0);
    if (this.scrollBoxHeight + deltaY < this.currentZoom.y) {
      return;
    }
    if (this.calculatedAudioScrollBoxHeight - deltaY < this.currentZoom.y) {
      return;
    }
    if (currentScene && this.calculatedAudioScrollBoxHeight - deltaY > audioLayerHeight * this.currentZoom.y) {
      return;
    }

    this.scrollBoxHeightGrabHandlePointerStart = currentPos;
    this.getScrollBoxHeights(this.scrollBoxHeight + deltaY, this.calculatedAudioScrollBoxHeight - deltaY);
    if (event.type === 'pointerup' || event.type === 'pointerupoutside') {
      this.updateSize(true, true);
    } else {
      this.updateSize(true, false);
    }
    this.scrollBox.content.x = currentScrollBoxPosition;
  };

  adjustElementHolderHeightForSceneGhosts() {
    if (!this.elementLayersHolder || !this.sceneGhostHolder) return;

    this.sceneGhosts.forEach(child => {
      if (child instanceof SceneGhost) {
        child.updateZoom(this.currentZoom);
      }
    });
    this.updateSceneGhostHolderMask();
  }

  addProjectAudioLayer(
    waveform: TimelineAudioWaveform,
    audioType: string,
    startTime: number,
    elementLayer: number,
    audioLayerOffset: number,
    assetId: string,
    isActive: boolean,
    id: string
  ) {
    console.log('Wip - element layer not being read any more?', elementLayer);
    const audioLayer = new TimelineAudioLayerProject({
      sceneStartTime: startTime,
      scribeLength: this.sceneLength,
      currentZoom: this.currentZoom.clone(),
      windowRef: this.app.screen,
      waveform: waveform,
      layerIndex: audioLayerOffset,
      audioType: audioType,
      wrapperRef: this,
      assetId: assetId,
      isActive,
      id
    });

    if (this.scrollBox) {
      audioLayer.x = this.leftPadding;
      audioLayer.updateCurrentZoom(this.currentZoom);
      audioLayer.on('pointerup', this.showAudioPanel);
      audioLayer.on('rightclick', this.showAudioContextMenu);
      this.timelineHolder?.addChildAt(this.scrollBox, 0);
    }
    this.timelineHolder?.addChildAt(audioLayer, 2);
    waveform.generate();
    return audioLayer;
  }

  showAudioPanel = (event: PIXI.InteractionEvent) => {
    event.target = event.currentTarget;
    this.selectedAudioLayer = event.target as TimelineAudioLayerProject;
    if (this.selectedAudioLayer) {
      this.selectedAudioLayer.select();
    }
    const selectedAudioClip = this.props.activeScribe?.audioClips?.find(
      clip => clip.id === this.selectedAudioLayer?.id
    );
    if (selectedAudioClip) this.props.onAudioSelected(selectedAudioClip);
  };

  private showAudioContextMenu = (event: PIXI.InteractionEvent) => {
    this.showAudioPanel(event);

    if (event.target instanceof TimelineAudioLayerProject && event.data.originalEvent instanceof MouseEvent) {
      const { target: audioClip } = event;
      const projectClip = this.props.activeScribe?.audioClips?.find(clip => clip.id === audioClip.id);

      const mouseX = event.data.originalEvent.clientX;
      const mouseY = event.data.originalEvent.clientY;
      const duration = projectClip?.instanceDuration || projectClip?.duration || 0;

      this.onContextClick({
        scribeId: this.props.activeScribe.id,
        type: 'audio',
        audioType: audioClip.audioType,
        duration,
        mouseX,
        mouseY,
        clipId: audioClip.id
      });
    }
  };

  updateTopMeasureMask() {
    if (!this.scrollBox) {
      return;
    }

    this.topMeasureMask.x = this.leftPadding;
    this.topMeasureMask.y = this.topPadding;

    this.topMeasureMask.clear();
    this.topMeasureMask.beginFill(WHITE);
    this.topMeasureMask.drawRect(ELEMENT_LAYER_PADDING, 0, this.scrollBox.boxWidth, this.pinnedElementsHeight);
    this.topMeasureMask.endFill();
  }

  public scrollToEnd = () => {
    if (this.layers.length) {
      const currentScene = getActiveScene(this.props.activeScribe);
      if (currentScene && this.scrollBox) {
        const startOffset = getSceneStartTimeAndLength(this.props.activeScribe, currentScene);
        const sceneLength = startOffset.sceneLength / 1000;
        const sceneStartTime = startOffset.sceneStartTime / 1000;
        const sceneEndTime = sceneStartTime + sceneLength;
        const endPos = Math.max(0, sceneEndTime * this.currentZoom.x - this.scrollBox.width / 4);
        const wholeSceneInView =
          sceneEndTime * this.currentZoom.x < -this.scrollBox.content.x + this.scrollBox.width &&
          sceneStartTime * this.currentZoom.x > -this.scrollBox.content.x;
        if (
          (this.scrollBox.content.x < -endPos + (this.scrollBox.width / 4) * 3 &&
            this.scrollBox.content.x > -endPos + this.scrollBox.width / 4) ||
          wholeSceneInView
        ) {
          return;
        }
        this.scrollToPoint(new PIXI.Point(endPos, 0));
        this.props.onScrollToEnd();
      }
    }
  };

  scrollInToView() {
    if (this.scrollBox && this.sceneLabels) {
      const padding = LABEL_WIDTH + 3;
      const b1 = this.scrollBox.getBounds();
      b1.x += padding;
      const b2 = this.sceneLabels.activeConstraintGraphics.getBounds();
      if (!b1.intersects(b2)) {
        this.scrollToBeginning();
      } else {
        this.scrollBox.content.y = -this.layers.length * this.currentZoom.y;
      }
    }
  }

  public scrollToBeginningClicked = () => {
    this.props.onScrollToBeginning();
    this.scrollToBeginning();
  };

  public scrollToBeginning = () => {
    const currentScene = getActiveScene(this.props.activeScribe);
    if (currentScene && this.scrollBox) {
      const startOffset = getSceneStartTimeAndLength(this.props.activeScribe, currentScene);
      const sceneStartTime = startOffset.sceneStartTime / 1000;
      this.scrollBox.content.x = -sceneStartTime * this.currentZoom.x + LABEL_WIDTH;
      this.scrollBox.content.y = -this.layers.length * this.currentZoom.y;
      this.scrollBox.update();
    }
  };

  getCenterElement(axis: 'x' | 'y'): TimelineElementLayer | undefined {
    let minDist = Number.MAX_SAFE_INTEGER;
    let closestElement = undefined;
    const allLayers: Array<AllTimelineLayerTypes> = this.layers.concat();
    allLayers.forEach(element => {
      if (this.scrollBox !== undefined && this.scrollBox?.content) {
        const distToCenter =
          axis === 'y'
            ? Math.abs(element.y - this.scrollBox.content.center.y)
            : Math.abs(element.getStartX() - this.scrollBox.content.center.x);
        if (distToCenter < minDist) {
          minDist = distToCenter;
          closestElement = element;
        }
      }
    });
    return closestElement;
  }

  public showAll = () => {
    this.zoomManager.zoomOut();
    this.updateScrollBoxContents();
    this.scrollToBeginning();
    this.props.onZoomExpandedClick();
  };

  public updateScrollBoxContents() {
    if (!this.scrollBox) return;
    this.scrollBox.update();
    this.scrollBox.content.updateTransform();
    if (this.getAudioScrollBoxEnabled() && this.audioScrollBox) {
      this.audioScrollBox.update();
      this.audioScrollBox.content.updateTransform();
    }
    this.drawSceneGhosts();
    this.updateCurrentZoom(this.currentZoom);
  }

  public updateSize = (forceUpdate = false, updateZoomAndView = true) => {
    //If the rate limiter is true, queue the update and return, the update will be called again after the rate limiter is reset
    if (this.rateLimiter === true) {
      this.queuedUpdateSize = { forceUpdate, updateZoomHandles: updateZoomAndView };
      return;
    }
    this.rateLimiter = true;
    if (this.app) {
      // if the app is defined, update the size
      if (this.scrollBox) {
        if (
          this.screenWidthAndHeight.x === this.app.screen.width &&
          this.screenWidthAndHeight.y === this.app.screen.height &&
          forceUpdate === false
        )
          return;
        // if the screen width and height have not changed, and we are not forcing an update, do not update the size
        if (!this.getAudioScrollBoxEnabled()) {
          if (this.props?.activeScribe?.audioClips?.length) {
            const spaceForAudioLayers =
              this.app.renderer.height -
              TIMELINE_HEIGHT -
              this.pinnedElementsHeight -
              this.topPadding * 3 -
              SCROLL_BOX_MARGIN;
            // if there are audio layers, calculate the space for them
            if (spaceForAudioLayers < this.currentZoom.y * this.props.activeScribe.audioClips.length + 1) {
              this.updateCurrentZoom({ y: spaceForAudioLayers / this.props.activeScribe.audioClips.length + 1 });
            }
          }
        }
        const centerY = this.scrollBox?.content.center.y;
        const scrollBarMargin = 20;
        const scrollBarPadding = 12;

        if (!forceUpdate) this.getScrollBoxHeights();

        timelineBus.emit(PLAYHEAD_CHANGE_HEIGHT, this.app.screen.height - SCROLL_BOX_MARGIN);
        let boxHeightChanged = false;
        let boxWidthChanged = false;
        if (this.scrollBox) {
          if (this.scrollBoxHeight !== this.scrollBox.boxHeight) {
            boxHeightChanged = true;
          }
          if (this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER - this.leftPadding !== this.scrollBox.boxWidth) {
            boxWidthChanged = true;
          }
        }
        if (boxHeightChanged || boxWidthChanged || forceUpdate) {
          this.scrollBox.boxWidth = this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER - this.leftPadding;
          this.scrollBox.boxHeight = this.scrollBoxHeight - SCROLL_BOX_MARGIN;
          if (this.scrollBoxHeightGrabHandle)
            this.scrollBoxHeightGrabHandle.y = (this.scrollBox?.boxHeight ?? 0) + (this.scrollBox?.y ?? 0);
        }

        this.scrollBox.scrollbarOffsetHorizontal =
          this.getAudioScrollBoxEnabled() && this.audioScrollBox
            ? this.calculatedAudioScrollBoxHeight + SCROLLBOX_SEPERATION_HEIGHT + SCROLL_BOX_MARGIN - scrollBarPadding
            : SCROLL_BOX_MARGIN - scrollBarPadding;

        this.scrollBox.scrollbarOffsetVertical = SCROLL_BOX_MARGIN - scrollBarPadding;
        this.scrollBox.scrollBarWidthOverride =
          this.app.screen.width - this.scrollBox.x - scrollBarMargin - this.leftPadding;

        this.scrollBox.scrollBarHeightOverride = this.scrollBoxHeight - scrollBarMargin - this.topPadding;

        this.scrollBox.update();

        if (this.getAudioScrollBoxEnabled() && this.audioScrollBox && this.audioScrollBox.parent) {
          this.audioScrollBox.scrollBarWidthOverride = this.scrollBox.scrollBarWidthOverride;
          this.audioScrollBox.scrollBarHeightOverride = this.calculatedAudioScrollBoxHeight;

          this.audioScrollBox.boxWidth = this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER - this.leftPadding;
          this.audioScrollBox.boxHeight = this.calculatedAudioScrollBoxHeight;
          this.audioScrollBox.scrollbarOffsetVertical = SCROLL_BOX_MARGIN - scrollBarPadding;
          this.audioScrollBox.y = this.scrollBox.y + this.scrollBox.boxHeight + SCROLLBOX_SEPERATION_HEIGHT;
          if (!this.scrollBoxHeightGrabHandlePointerDown && this.scrollBoxHeightGrabHandle && this.audioScrollBox) {
            this.scrollBoxHeightGrabHandle.y = this.scrollBox.y + this.scrollBox.boxHeight;
          }
        }

        if (this.leftZoomMargin) {
          this.leftZoomMargin.height = this.app.renderer.height;
        }
        if (updateZoomAndView || boxHeightChanged) {
          this.updateSceneLabels();
          this.updateZoomManagerAndZoomHandles();
          this.updateTopMeasureMask();
          this.updateScrollBoxContents();
        }
        this.adjustElementHolderHeightForSceneGhosts();
        this.scrollToPoint(new PIXI.Point(this.scrollBox?.content.center.x, centerY));

        if (this.timelineLayerTooltip) {
          this.timelineLayerTooltip.updateMaxPosition(
            this.app.screen.width - VERTICAL_SCROLLBAR_GUTTER,
            LEFT_ZOOM_MARGIN
          );
        }
        this.viewControls.x = this.app.screen.width - this.viewControls.width - 10;
      }
    }

    this.screenWidthAndHeight.x = this.app.screen.width;
    this.screenWidthAndHeight.y = this.app.screen.height;
    this.projectAudioLayers.forEach(layer => layer.updateCurrentZoom(this.currentZoom));
    this.savePositionToLocalStorage();
  };

  updateSceneLabels() {
    if (this.sceneLabels && this.scrollBox) {
      if (this.projectAudioLayerHolder) {
        this.sceneLabels.updateScrollBoxHeight(
          Math.max(
            this.scrollBox.boxHeight + SCROLL_BOX_MARGIN,
            Math.min(this.fullScrollablesHeight, this.projectAudioLayerHolder.getBounds().top - this.sceneLabels.y)
          )
        );
      } else {
        this.sceneLabels.updateScrollBoxHeight(this.fullScrollablesHeight);
      }
    }
  }
  private updateZoomManagerAndZoomHandles() {
    this.zoomManager.updateSize(SCROLL_BOX_MARGIN);
    this.zoomManager.setInitialZoom();
  }

  private updateActiveElements(props: TimelinePropsConnected) {
    this.activeElements = this.layers.filter(layer => props.activeElements.includes(layer.id));
    this.props = Object.assign(props);
  }

  private sensitiveScrollY(newCenter: PIXI.Point) {
    if (this.scrollBox === undefined) return newCenter;

    const centerToAdjust = newCenter.clone();
    const topMargin = 50;
    const bottomMargin = 50 + this.currentZoom.y;
    const topBounds = this.scrollBox.content.center.y - (this.scrollBox.boxHeight / 2 - topMargin);
    const bottomBounds = this.scrollBox.content.center.y + (this.scrollBox.boxHeight / 2 - bottomMargin);

    if (centerToAdjust.y < topBounds) {
      centerToAdjust.y += this.scrollBox.boxHeight / 2 - topMargin;
    } else if (centerToAdjust.y > bottomBounds) {
      centerToAdjust.y -= this.scrollBox.boxHeight / 2 - bottomMargin;
    } else {
      centerToAdjust.y = this.scrollBox.content.center.y;
    }

    return centerToAdjust;
  }

  private sensitiveScrollX(newCenterStart: PIXI.Point, newCenterEnd?: PIXI.Point) {
    if (!this.scrollBox) return newCenterStart;
    const rMargin = 50;
    const lMargin = 50;

    const leftBound = this.scrollBox.content.center.x - (this.scrollBox.boxWidth / 2 - lMargin);
    const rightBound = this.scrollBox.content.center.x + (this.scrollBox.boxWidth / 2 - rMargin);
    if (newCenterStart.x > rightBound || (newCenterEnd && newCenterEnd.x < leftBound)) {
      let centerToAdjust = newCenterStart.clone();
      if (newCenterEnd && newCenterEnd.x < leftBound) centerToAdjust = newCenterEnd.clone();
      const startAndLength = getSceneStartTimeAndLength(
        this.props.activeScribe,
        getActiveScene(this.props.activeScribe)
      );
      const sceneStartTime = startAndLength.sceneStartTime / 1000;
      const sceneEndTime = startAndLength.sceneLength / 1000 + sceneStartTime;
      if (centerToAdjust.x < leftBound) {
        centerToAdjust.x += this.scrollBox.boxWidth / 2 - lMargin;
      } else if (centerToAdjust.x > rightBound) {
        centerToAdjust.x -= this.scrollBox.boxWidth / 2 - rMargin;
      } else {
        centerToAdjust.x = this.scrollBox.content.center.x;
      }
      if (centerToAdjust.x < sceneStartTime * this._currentZoom.x) {
        centerToAdjust.x = sceneStartTime * this._currentZoom.x;
      }
      if (centerToAdjust.x > sceneEndTime * this._currentZoom.x) {
        centerToAdjust.x = (this.props.sceneStartTime / 1000) * this._currentZoom.x;
      }
      return centerToAdjust;
    }
    return this.scrollBox.content.center.clone();
  }

  scrollToPoint = (point: PIXI.Point, centerOrCorner: 'center' | 'corner' = 'center') => {
    if (this.scrollBox === undefined) return;
    this.focusedScrollbox = this.scrollBox;
    if (centerOrCorner === 'center') {
      this.scrollBox.content.moveCenter(point);
    } else {
      this.scrollBox.content.moveCorner(point);
    }
    this.scrollBox.content.clamp({ direction: 'all', underflow: 'bottom-left' });
    this.scrollBox.update();

    if (this.getAudioScrollBoxEnabled() && this.audioScrollBox) {
      this.audioScrollBox.content.x = this.scrollBox.content.x;
    }
    if (this.topMeasure && this.elementLayersHolder)
      this.topMeasure.x = this.scrollBox.content.x + this.scrollBox.x + this.elementLayersHolder.x;
    if (this.sceneLabels && this.elementLayersHolder)
      this.sceneLabels.updatePosition(this.scrollBox.content.x + this.scrollBox.x + this.elementLayersHolder.x);
    this.sceneGhosts.forEach(child => {
      child.updatePosition();
    });
    this.savePositionToLocalStorage();
  };

  public scrollTo({ sceneElement, focus, focusPosition, offset }: ScrollToProps) {
    if (this.scrollBox === undefined) {
      return;
    }

    let newCenterStart = this.scrollBox.content.center.clone();
    let newCenterEnd = this.scrollBox.content.center.clone();
    if (sceneElement !== undefined) {
      const elementStartPos = sceneElement.getStartX();
      const elementEndPos = sceneElement.getEndX();
      const positionBuffer = focusPosition === 'start' ? 0 : 0;
      const elementBounds = sceneElement.getBounds();
      if (!elementBounds) return;
      const relativePosition = this.scrollBox.content.toLocal(elementBounds);
      newCenterStart = new PIXI.Point(elementStartPos, relativePosition.y);
      newCenterEnd = new PIXI.Point(elementEndPos, relativePosition.y);
      if (focusPosition === 'start') newCenterEnd = newCenterStart.clone();
      if (focus === 'ySensitive') {
        newCenterStart = this.sensitiveScrollY(newCenterStart);
      } else if (focus === 'xSensitive') {
        newCenterStart = this.sensitiveScrollX(newCenterStart, newCenterEnd);
      } else if (focus === 'bothSensitive') {
        newCenterStart = this.sensitiveScrollY(newCenterStart);
        newCenterStart = this.sensitiveScrollX(newCenterStart, newCenterEnd);
      } else {
        if (focus === 'x') newCenterStart.y = this.scrollBox.content.center.y;
        if (focus === 'y') newCenterStart.x = this.scrollBox.content.center.x;
        if (focus === undefined) newCenterStart.set(this.scrollBox.content.center.x, this.scrollBox.content.center.y);
      }
      if (offset) {
        if (focus === 'x') newCenterStart.x += offset.x - positionBuffer;
        newCenterStart.y += offset.y;
      }
    }
    if (
      newCenterStart.x + this.scrollBox.boxWidth / 2 > Math.abs(this.scrollBox.content.x) + LABEL_WIDTH * 2 &&
      newCenterStart.x + this.scrollBox.boxWidth / 2 <
        Math.abs(this.scrollBox.content.x) + this.scrollBox.boxWidth - LABEL_WIDTH * 2
    ) {
      return;
    }
    this.scrollToPoint(newCenterStart);
  }

  public elementMoved = (fromIndex: number, toIndex: number, elementID: string) => {
    this.props.onElementOrderChange(
      { index: fromIndex, droppableId: 'elementList' },
      { index: toIndex, droppableId: 'elementList' },
      elementID
    );

    if (this.scrollBox) {
      const cachedScrollPosition = {
        left: this.scrollBox.scrollLeft,
        top: this.scrollBox.scrollTop
      };
      this.rebuildView();
      this.scrollBox.scrollLeft = cachedScrollPosition.left;
      this.scrollBox.scrollTop = cachedScrollPosition.top;
      if (this.audioScrollBoxEnabled && this.audioScrollBox) {
        this.audioScrollBox.scrollLeft = cachedScrollPosition.left;
        this.audioScrollBox.scrollTop = cachedScrollPosition.top;
      }
    }
  };

  public sceneTransitionChanged = (sceneTransitionTime: number) => {
    const currentSceneId = getActiveScene(this.props.activeScribe)?.id;
    if (currentSceneId !== undefined)
      this.props.onSceneTransitionDurationChanged(currentSceneId, TimelineHelpers.roundNumber(sceneTransitionTime));
  };

  public timingsChanged = (
    elementId: string,
    propertyValuePair: { [k in ElementAnimationStageDurationKey]: number }
  ) => {
    this.props.onElementTimingsChange(propertyValuePair, elementId);
    this.props.onElementClick([elementId]);
  };

  update = (deltaTimeMultiplier: number) => {
    this.rateLimiter = false;
    if (this.queuedUpdateSize) {
      this.updateSize(this.queuedUpdateSize.forceUpdate, this.queuedUpdateSize.updateZoomHandles);
      this.queuedUpdateSize = undefined;
    }
    if (TimelineWrapper.DraggedGrabHandle && this.scrollBox) {
      const mousePosition = this.app.renderer.plugins.interaction.mouse.global;
      const scrollSpeed = 2;
      let scrollAmount = 0;
      const diff = mousePosition.x - (this.scrollBox.x + this.scrollBox.width);
      const handlePosition = TimelineWrapper.DraggedGrabHandle.toGlobal(new PIXI.Point(0, 0));
      const localMousePos = this.scrollBox.content.toLocal(handlePosition);
      if (diff > 0) {
        scrollAmount = this.scrollBox.content.corner.x + Math.min(diff, scrollSpeed);
        this.scrollBox.content.addChild(this.rightMarker);
        if (this.rightMarker.x < localMousePos.x) {
          this.rightMarker.x = localMousePos.x;
        }
      } else if (mousePosition.x < VERTICAL_SCROLLBAR_GUTTER) {
        scrollAmount = this.scrollBox.content.corner.x - scrollSpeed;
        if (this.rightMarker.x > localMousePos.x) this.rightMarker.x -= scrollSpeed;
      }
      TimelineWrapper.DraggedGrabHandle.onDragMove(mousePosition);
      if (scrollAmount !== 0) {
        this.scrollToPoint(new PIXI.Point(scrollAmount, this.scrollBox.content.corner.y), 'corner');
      }
    } else {
      if (this.rightMarker.parent && this.scrollBox) {
        this.scrollBox.content.removeChild(this.rightMarker);
      }
    }

    this.layerDragManager.update(deltaTimeMultiplier);

    if (this.scrollBox && this.elementLayersHolder) {
      this.slowScrollElementInAudioScrollBox();

      if (
        this.scrollBox.content.x !== this.lastFrameScrollBoxValues.x ||
        this.scrollBox.content.y !== this.lastFrameScrollBoxValues.y
      ) {
        this.scrollBox.update();

        if (this.topMeasure) {
          this.topMeasure.x = this.scrollBox.content.x + this.scrollBox.x + this.elementLayersHolder.x;
          this.projectAudioLayers.forEach(layer => {
            if (this.topMeasure) layer.positionWaveform(this.topMeasure.x);
          });
        }

        if (this.scrollBox.content.x !== this.lastFrameScrollBoxValues.x) {
          this.sceneLabels?.updatePosition(this.scrollBox.content.x + this.scrollBox.x + this.elementLayersHolder.x);
        }

        this.sceneGhosts.forEach(child => {
          child.updatePosition();
        });
      }
      if (this.audioScrollBox && this.audioScrollBox.content) {
        if (
          this.scrollBox.content.x !== this.lastFrameScrollBoxValues.x ||
          this.scrollBox.content.y !== this.lastFrameScrollBoxValues.y ||
          this.audioScrollBox.content.x !== this.lastFrameAudioScrollBoxValues.x ||
          this.audioScrollBox.content.y !== this.lastFrameAudioScrollBoxValues.y
        ) {
          this.audioScrollBox.content.x = this.scrollBox.content.x;
          this.audioScrollBox.update();
          this.updateSceneLabels();
        }
        this.lastFrameAudioScrollBoxValues.x = this.audioScrollBox.content.x;
        this.lastFrameAudioScrollBoxValues.y = this.audioScrollBox.content.y;
      }

      this.lastFrameScrollBoxValues.x = this.scrollBox.content.x;
      this.lastFrameScrollBoxValues.y = this.scrollBox.content.y;

      this.handleLine.clear();
      const visibleSceneWidth = Math.min(
        this.scrollBox.boxWidth,
        this.elementLayersHolder.width + this.scrollBox.content.x
      );
      if (visibleSceneWidth > GRABHANDLE_GRAPHIC_WIDTH * 2) {
        this.handleLine.lineStyle(1, TIMELINE_LAYER_FOREGROUND_COLOUR);
        this.handleLine.moveTo(visibleSceneWidth / 2 - GRABHANDLE_GRAPHIC_WIDTH, SCROLLBOX_SEPERATION_HEIGHT / 2);
        this.handleLine.lineTo(visibleSceneWidth / 2 + GRABHANDLE_GRAPHIC_WIDTH, SCROLLBOX_SEPERATION_HEIGHT / 2);
      }
    }
  };

  slowScrollElementInAudioScrollBox() {
    if (this.audioClipManager?.audioScrollboxCenterTarget && this.audioScrollBox && this.audioScrollBox.content) {
      const globalBoxBounds = this.audioScrollBox.getBounds();
      const scrollBoxBounds = new PIXI.Rectangle(
        globalBoxBounds.x,
        globalBoxBounds.y,
        this.audioScrollBox.boxWidth,
        this.audioScrollBox.boxHeight
      );
      const currentCenter = this.audioScrollBox.content.center.clone();
      const targetX = this.interactionManager.mouse.global.x;
      const targetY = this.audioClipManager?.audioScrollboxCenterTarget.y;
      const dragPaddingX = 50;
      const dragPaddingY = 20;
      const speed = 2;
      if (targetX > scrollBoxBounds.right - dragPaddingX) {
        currentCenter.x += speed;
      } else if (targetX < scrollBoxBounds.left + dragPaddingX) {
        currentCenter.x -= speed;
      }

      if (this.audioScrollBox.content.center.y !== this.audioClipManager?.audioScrollboxCenterTarget.y) {
        if (targetY < scrollBoxBounds.y + dragPaddingY) {
          currentCenter.y -= speed;
        } else if (targetY > scrollBoxBounds.bottom - dragPaddingY) {
          currentCenter.y += speed;
        }
      }
      if (
        this.audioScrollBox.content.center.x !== currentCenter.x ||
        this.audioScrollBox.content.center.y !== currentCenter.y
      ) {
        this.audioScrollBox.content.moveCenter(currentCenter);
        this.audioToMainBoxSync();
      }
    }
  }

  scrollToAudioClip = (audioClip: TimelineAudioClip, layer: TimelineAdvancedAudioLayer) => {
    if (!this.audioScrollBox || !this.audioClipManager) return;
    this.audioScrollBox.updateTransform();
    const left = audioClip.x + this.audioScrollBox.content.x + this.audioScrollBox.x;
    let top = layer.y + this.audioScrollBox.content.y + this.audioScrollBox.y;
    if (audioClip.audioClip.type === 'project')
      top =
        layer.y +
        this.audioScrollBox.content.y +
        this.audioScrollBox.y +
        this.audioClipManager.projectLayersContainer.y;
    const bounds = new PIXI.Rectangle(left, top, audioClip.width, audioClip.height);

    const scrollToPos = this.audioScrollBox.content.toLocal(new PIXI.Point(bounds.x, bounds.y + bounds.height / 2));
    const scrollBoxBounds = new PIXI.Rectangle(
      this.audioScrollBox.x,
      this.audioScrollBox.y,
      this.audioScrollBox.boxWidth,
      this.audioScrollBox.boxHeight
    );

    if (scrollBoxBounds.intersects(bounds)) {
      // if the audio clip is already in view, do nothing
      return;
    }
    this.audioScrollBox.content.snap(scrollToPos.x, scrollToPos.y, {
      time: 200,
      interrupt: true,
      removeOnComplete: true,
      removeOnInterrupt: true
    });
  };

  private swapLayers = (layer: AllTimelineLayerTypes, direction = 1) => {
    const layerIndex = this.layers.indexOf(layer);
    this.layers.forEach(element => {
      element.alpha = 1;
    });
    if (
      (this.layers[0].type === 'SceneTransition' && layerIndex + direction <= 1) ||
      layerIndex + direction < 0 ||
      layerIndex + direction >= this.layers.length
    )
      return;
    const swapWithLayer: AllTimelineLayerTypes = this.layers[layerIndex + direction];
    const tempTime: number = layer.startTime;
    layer.startTime =
      direction === -1 ? swapWithLayer.startTime : layer.startTime + (swapWithLayer.endTime - swapWithLayer.startTime);
    layer.recalculateEndTime();
    swapWithLayer.startTime = direction === -1 ? layer.endTime : tempTime;
    swapWithLayer.recalculateEndTime();
    this.layers[layerIndex] = swapWithLayer;
    this.layers[layerIndex + direction] = layer;

    layer.redraw();
    swapWithLayer.redraw();
    swapWithLayer.movedDuringLayerSwap = true;
    this.layers.forEach((layer, index) => {
      layer.setY((this.layers.length - 1 - index) * this.currentZoom.y);
      layer.x = 0;
    });
  };

  private layerMoveEnded = () => {
    this.layers.forEach(layer => {
      layer.alpha = 1;
      if (layer.movedDuringLayerSwap) {
        layer.redraw();
        layer.movedDuringLayerSwap = false;
      }
    });

    this.layerDragManager.dragMoveEnded();
  };

  private layerMoveBegun = (layer: AllTimelineLayerTypes) => {
    this.layerDragManager.layerMoveBegun(layer, this.activeElements?.length ?? 0);
  };

  private handleLayerClick(layerId: string, event: PIXI.InteractionEvent) {
    const layer = this.layers.find(layer => layer.id === layerId);
    if (layer) {
      this.updateSelectedLayers(layer, event);
    }
  }

  private handleCameraDoubleClick(layerId: string) {
    const layer = this.layers.find(layer => layer.id === layerId);
    if (layer) {
      this.props.onCameraDoubleClick(layer.elementModel as ScribeCameraElement);
    }
  }

  private handleElementDoubleClick(layerId: string) {
    const layer = this.layers.find(layer => layer.id === layerId);
    if (layer) {
      this.props.onElementDoubleClick(layer.elementModel as ScribeElementModel);
    }
  }

  private updateSelectedLayers = (layer: AllTimelineLayerTypes, event: PIXI.InteractionEvent) => {
    const selectedElements = new Array<AllTimelineLayerTypes>();

    const isMac = browser?.os?.toLowerCase().includes('mac');

    selectedElements.push(layer);
    if (event.data.originalEvent.metaKey || (event.data.originalEvent.ctrlKey && !isMac)) {
      this.activeElements?.forEach(element => {
        if (element.id !== layer.id) {
          selectedElements.push(element);
        } else {
          selectedElements.splice(selectedElements.indexOf(layer), 1);
        }
      });
    } else if (event.data.originalEvent.shiftKey) {
      if (this.lastSelected) {
        const selectedIndex = this.layers.indexOf(layer);
        const lastSelectedIndex = this.layers.indexOf(this.lastSelected);
        this.activeElements?.forEach(element => {
          if (element.id !== layer.id) selectedElements.push(element);
        });
        if (lastSelectedIndex !== -1) {
          this.layers?.forEach((element, index) => {
            if (
              (index >= lastSelectedIndex && index <= selectedIndex) ||
              (index <= lastSelectedIndex && index >= selectedIndex)
            ) {
              if (element.id !== layer.id)
                if (selectedElements.includes(element) === false) {
                  selectedElements.push(element);
                }
            }
          });
        }
      }
    } else {
      if (this.activeElements?.includes(layer)) {
        this.activeElements?.forEach(element => {
          if (element.id !== layer.id) selectedElements.push(element);
        });
      }
    }

    this.lastSelected = layer;
    this.activeElements = selectedElements;
    this.props.onElementClick(selectedElements.map(element => element.id));
  };
}
