import * as PIXI from 'pixi.js';
import clamp from 'lodash.clamp';

import { VERTICAL_SCROLLBAR_GUTTER } from './consts';
import { buildSplitTrackConfirmation, makeSplitCaret } from './SplitTrackUtils';
import TimelineAdvancedAudioManager from './TimelineAdvancedAudioManager';
import TimelineAudioClip from './TimelineAudioClip';
import timelineBus, {
  HIDE_AUDIO_LENGTH_ADJUST_INDICATOR,
  UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR,
  WRAPPER_UPDATE_SIZE,
  WRAPPER_UPDATE_ZOOM
} from './TimelineControlBus';
import TimelineWrapper from './TimelineWrapper';
import TimelineHelpers from './Utils/TimelineHelpers';

export class SplitTrackManager {
  destroy() {
    this.splitModeClip = undefined;
    this.splitModeClipId = undefined;
    this.splitModeConfimationBg?.destroy();
    this.splitModeConfimationBg = undefined;
    this.splitModeConfimation?.destroy();
    this.splitModeConfimation = undefined;
    this.splitCaret?.destroy();
    this.splitCaret = undefined;

    window.removeEventListener('wheel', this.disableAllScrollingInSplitMode, { capture: true });
  }
  splitModeConfimation?: PIXI.Graphics;
  splitModeClip: TimelineAudioClip | undefined;
  splitTrackPosition = 0;
  splitModeClipId?: string;
  splitCaret?: PIXI.Graphics;
  lastSplitPosition: number;
  adjustingSplitCaret: boolean;
  wrapperRef: TimelineWrapper;
  audioManagerRef: TimelineAdvancedAudioManager;
  splitModeConfimationBg?: PIXI.Graphics;
  constructor(wrapperRef: TimelineWrapper, audioManagerRef: TimelineAdvancedAudioManager) {
    this.wrapperRef = wrapperRef;
    this.audioManagerRef = audioManagerRef;
    this.adjustingSplitCaret = false;
    this.lastSplitPosition = 0;
  }

  get props() {
    return this.wrapperRef.props;
  }

  get splitMode() {
    return !!this.props.audioSplitElement;
  }

  public startSplitModeStateUpdate = (elementId: string) => {
    this.splitModeClipId = elementId;
    this.splitModeClip = this.audioManagerRef.getTimelineAudioClipById(elementId);

    window.addEventListener('wheel', this.disableAllScrollingInSplitMode, { passive: false, capture: true });
    timelineBus.on(WRAPPER_UPDATE_SIZE, this.resizeSplitModeUI);
    timelineBus.on(WRAPPER_UPDATE_ZOOM, this.resizeSplitModeUI);
    if (!this.splitModeClip) return;
    const clipBounds = this.getClipboundsWithinViewport(this.splitModeClip);
    if (!clipBounds) return;

    const indicatorPosition = new PIXI.Point(clipBounds.x + clipBounds.width / 2, clipBounds.y + clipBounds.height / 2);
    this.splitTrackPosition = clipBounds.width / 2;
    if (!this.splitCaret) {
      this.splitCaret = makeSplitCaret(this.wrapperRef.currentZoom.y);
      this.wrapperRef.timelineHolder?.addChild(this.splitCaret);
    }
    this.splitModeClip.cursor = 'timeDrag';
    this.splitCaret.x = indicatorPosition.x - this.splitCaret.width / 2;
    this.splitCaret.y = clipBounds.y;

    this.showSplitTrackConfirmation();
    this.updateSplitPosition(indicatorPosition);
    this.adjustingSplitCaret = false;
  };

  private resizeSplitModeUI = () => {
    if (!this.splitModeClip) return;
    const clipBounds = this.getClipboundsWithinViewport(this.splitModeClip);
    if (!clipBounds) return;
    const indicatorPosition = new PIXI.Point(clipBounds.x + clipBounds.width / 2, clipBounds.y + clipBounds.height / 2);
    if (this.splitCaret) {
      this.splitCaret.x = indicatorPosition.x - this.splitCaret.width / 2;
      this.splitCaret.y = clipBounds.y;
    }
    this.updateSplitPosition(indicatorPosition);
    this.drawConfirmationBG();
  };

  public endSplitModeStateUpdate = () => {
    window.removeEventListener('wheel', this.disableAllScrollingInSplitMode, { capture: true });
    if (this.splitModeClip) this.splitModeClip.cursor = 'arrow';
    if (this.wrapperRef.scrollBox?.content) this.wrapperRef.scrollBox.content.interactiveChildren = true;

    timelineBus.emit(HIDE_AUDIO_LENGTH_ADJUST_INDICATOR);
    this.audioManagerRef.hideClipPositionIndicator();
    this.splitModeConfimationBg?.destroy();
    this.splitModeConfimationBg = undefined;

    this.splitModeConfimation?.destroy();
    this.splitModeConfimation = undefined;

    this.splitModeClipId = undefined;
    this.splitCaret?.destroy();
    this.splitCaret = undefined;
    this.adjustingSplitCaret = false;
  };

  splitMoved(global: PIXI.Point) {
    const minClipLength = 0.1;
    if (this.splitModeClipId) {
      this.splitModeClip = this.audioManagerRef.getTimelineAudioClipById(this.splitModeClipId);
      if (this.splitModeClip) {
        this.splitModeClip.showSplitMode();
        const position = global;
        const clipBounds = this.splitModeClip.getBounds();
        if (position.x < clipBounds.x + minClipLength * this.wrapperRef.currentZoom.x) {
          position.x = clipBounds.x + minClipLength * this.wrapperRef.currentZoom.x;
        }
        if (position.x > clipBounds.right - minClipLength * this.wrapperRef.currentZoom.x) {
          position.x = clipBounds.right - minClipLength * this.wrapperRef.currentZoom.x;
        }

        this.splitTrackPosition = position.x - clipBounds.x;
        timelineBus.emit(UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR, position, this.splitModeClip, false);
      }
    }
  }
  disableAllScrollingInSplitMode = (e: WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleCancelSplitModeWithOutsideClick = (e: PIXI.InteractionEvent | PointerEvent | MouseEvent) => {
    e.stopPropagation();
    if (e instanceof PIXI.InteractionEvent) {
      e.data.originalEvent.preventDefault();
    } else {
      e.preventDefault();
    }
    if (this.splitMode) {
      if (
        e.target === null ||
        (e.target instanceof PIXI.DisplayObject &&
          e.target.parent !== this.splitModeConfimation &&
          e.target !== this.splitModeClip?.background) ||
        (e instanceof MouseEvent && !this.adjustingSplitCaret)
      ) {
        this.stopSplitModeFromClick();
      }
      this.adjustingSplitCaret = false;
    }
  };

  public stopSplitModeFromClick = () => {
    if (this.splitModeClip) {
      this.setSplitModeToFalse();
      if (this.splitModeClip) this.audioManagerRef.props.cancelSplitMode(this.splitModeClip.audioClip);
    }
  };
  public setSplitModeToFalse = () => {
    this.audioManagerRef.props.updateSplitModeState(this.splitModeClip?.audioClip, false);
  };
  public getClipboundsWithinViewport = (clip: TimelineAudioClip) => {
    if (!clip) return;
    const bounds = clip.getBounds();
    const viewportBounds = this.wrapperRef.audioScrollBox?.getBounds();
    if (!viewportBounds) return;
    viewportBounds.width = this.wrapperRef.app.screen.width - VERTICAL_SCROLLBAR_GUTTER - viewportBounds.x;
    if (bounds.left < viewportBounds.left) bounds.x = viewportBounds.left;
    if (bounds.right > viewportBounds.right) bounds.width = viewportBounds.right - bounds.x;
    return bounds;
  };

  public updateSplitPosition = (position: PIXI.Point) => {
    const inBoundsPosition = position.clone();
    if (this.splitMode && this.splitModeClip && this.splitCaret) {
      const bounds = this.getClipboundsWithinViewport(this.splitModeClip);
      if (!bounds) return;
      if (inBoundsPosition.x < bounds.x) {
        inBoundsPosition.x = bounds.x;
      } else if (inBoundsPosition.x > bounds.right) {
        inBoundsPosition.x = bounds.right;
      }
      this.lastSplitPosition = (inBoundsPosition.x - this.splitModeClip?.getBounds().x) / this.wrapperRef.currentZoom.x;

      this.adjustingSplitCaret = true;
      if (this.splitModeConfimation) {
        this.splitModeConfimation.visible = true;
        const width = 165;
        if (inBoundsPosition.x < width) {
          this.splitModeConfimation.x = inBoundsPosition.x;
        } else {
          this.splitModeConfimation.x = inBoundsPosition.x - width;
        }
      }
      this.splitCaret.x = inBoundsPosition.x - this.splitCaret.width / 2;
      if (this.splitModeClip) this.splitCaret.y = this.splitModeClip.toGlobal(new PIXI.Point(0, 0)).y;
      if (this.audioManagerRef.clipLengthAdjustIndicator)
        this.audioManagerRef.clipLengthAdjustIndicator.visible = false;

      const relativePos = this.wrapperRef.audioElementLayersHolder?.toLocal(new PIXI.Point(inBoundsPosition.x, 0));
      if (relativePos)
        this.audioManagerRef.updateClipPositionIndicator(
          inBoundsPosition.x,
          TimelineHelpers.formatTime(relativePos.x / this.wrapperRef.currentZoom.x)
        );
    }
  };
  showSplitTrackConfirmation = () => {
    if (!this.splitModeClip) return;
    if (this.splitModeConfimation && this.splitModeConfimationBg) {
      this.splitModeConfimationBg.visible = true;
      this.wrapperRef.timelineHolder?.addChild(this.splitModeConfimationBg);

      this.splitModeConfimation.visible = true;
      this.wrapperRef.timelineHolder?.addChild(this.splitModeConfimation);

      return;
    }

    this.splitModeConfimation = buildSplitTrackConfirmation(
      this.splitModeClip,
      this.stopSplitModeFromClick,
      this.splitTrack
    );
    this.splitModeConfimationBg = new PIXI.Graphics();

    this.drawConfirmationBG();
    this.wrapperRef.timelineHolder?.addChild(this.splitModeConfimationBg);
    this.wrapperRef.timelineHolder?.addChild(this.splitModeConfimation);
  };

  drawConfirmationBG = () => {
    if (!this.splitModeClip || !this.splitModeConfimationBg) return;
    this.splitModeConfimationBg.clear();
    const bounds = this.getClipboundsWithinViewport(this.splitModeClip);
    if (!bounds) return;
    this.splitModeConfimationBg.beginFill(0x000000, 0.2);
    this.splitModeConfimationBg.drawRect(0, 0, this.wrapperRef.app.screen.width, bounds.y);
    this.splitModeConfimationBg.drawRect(0, bounds.y, bounds.x, bounds.height);
    this.splitModeConfimationBg.drawRect(
      bounds.x + bounds.width,
      bounds.y,
      this.wrapperRef.app.screen.width,
      bounds.height
    );
    this.splitModeConfimationBg.drawRect(
      0,
      bounds.y + bounds.height,
      this.wrapperRef.app.screen.width,
      this.wrapperRef.app.screen.height
    );
    this.splitModeConfimationBg.endFill();
    this.splitModeConfimationBg.interactive = true;
    this.splitModeConfimationBg.on('pointerdown', this.stopSplitModeFromClick);
    this.splitModeConfimationBg.on('rightdown', this.stopSplitModeFromClick);
  };

  splitTrack = (clip: TimelineAudioClip) => {
    if (clip) {
      if (this.splitModeClip && this.wrapperRef.scrollBox) {
        const offset = clip?.audioClip?.startOffset ?? 0;
        const scrollbarMargin = 20;
        const lowerClamp = Math.abs(this.wrapperRef.scrollBox?.content.x) - this.splitModeClip.x;
        const upperClamp =
          Math.abs(this.wrapperRef.scrollBox?.content.x) -
          this.splitModeClip.x +
          this.wrapperRef.scrollBox?.width -
          scrollbarMargin;

        const clampedX = clamp(this.splitTrackPosition, lowerClamp, upperClamp);

        this.props.onSplitAudio(clip.audioClip, clampedX / this.wrapperRef.currentZoom.x + offset);
      }
    }
  };
}
