export function webGLMaxTextureSize(): number | undefined {
  const testCanvas = document.createElement('canvas');
  document.body.appendChild(testCanvas);
  const gl = testCanvas.getContext('webgl');

  if (!gl) {
    document.body.removeChild(testCanvas);
    return;
  }

  const maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

  if (!maxTextureSize || typeof maxTextureSize !== 'number') {
    document.body.removeChild(testCanvas);
    return;
  }

  document.body.removeChild(testCanvas);
  return maxTextureSize;
}
