import { select, takeEvery } from 'redux-saga/effects';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { ADD_SCRIBE_SUCCESS, CREATE_SCENE, SET_ELEMENTS_TIMINGS } from 'js/actionCreators/scribeActions';
import { SAVE_AUDIO_SUCCESS } from 'js/actionCreators/audioActions';
import { MP4_FILE_FORMAT } from 'js/config/consts';
import { GET_VIDEO_DOWNLOAD_URL_SUCCESS } from 'js/actionCreators/downloadActions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { PLAY_FROM_EDITOR } from 'js/actionCreators/playbackActions';
import { ADD_CAMERA } from 'js/actionCreators/cameraActions';
import { TOGGLE_NEW_TIMELINE } from 'js/actionCreators/editorActions';
import { SET_SCENE_TRANSITION_TYPE } from 'js/actionCreators/sceneTransitionsActions';
import {
  TRACK_HIT_DOWNLOAD_LIMIT,
  TRACK_HIT_PROJECT_DURATION_LIMIT,
  TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED
} from 'js/actionCreators/trackingActions';

const hsqInit = () => {
  return {
    push(...args) {
      const _hsq = (window._hsq = window._hsq || []);
      if (!!import.meta.env.VITE_DEBUG_HUBSPOT) {
        console.log(`%c[HubSpot Debug]: `, 'color: #ff7a59', ...args);
      }
      _hsq.push(...args);
    }
  };
};

const hsq = hsqInit();

const TRACK_EVENT_NAME = 'trackCustomBehavioralEvent';
const LEGACY_TRACK_EVENT_NAME = 'trackEvent';

export function hubspotIdentify({ user }) {
  hsq.push([
    'identify',
    {
      email: user.email
    }
  ]);
  hsq.push([
    TRACK_EVENT_NAME,
    {
      name: 'pe5474140_last_access_date'
    }
  ]);
}

function hubspotTrackCustomBehaviorEvent(eventName, properties) {
  hsq.push([
    TRACK_EVENT_NAME,
    {
      name: eventName,
      ...(properties ? { properties } : {})
    }
  ]);
}

export function hubspotSaveAudioSuccess({ audioType }) {
  const eventName = audioType === 'voiceover' ? 'pe5474140_added_voice_over' : 'pe5474140_added_background_music';

  hubspotTrackCustomBehaviorEvent(eventName);
}

export function hubspotDownloadRequest({ format }) {
  const eventName = format === MP4_FILE_FORMAT ? 'pe5474140_downloaded_mp4' : 'pe5474140_downloaded_as_gif';

  hubspotTrackCustomBehaviorEvent(eventName);
}

export function hubspotAddScribeSuccess() {
  hsq.push([
    LEGACY_TRACK_EVENT_NAME,
    {
      id: '000009028695' // New Scribe
    }
  ]);
}

function hubspotTrackPreviewPageView({ payload }) {
  if (payload?.location?.pathname?.includes('/preview/')) {
    hsq.push(['setPath', `${window.location.pathname}?utm_campaign=Preview%20Sharing%20Link&utm_source=VSC%20User`]);
    hsq.push(['trackPageView']);
  }
}

function* hubspotTrackTimelineOn() {
  const timelineOn = yield select(state => state.ui.newTimeline);
  if (timelineOn) {
    hubspotTrackCustomBehaviorEvent('pe5474140_vsc___turn_timeline_on');
  }
}

function* hubspotTrackHitDownloadLimit() {
  const HIT_DOWNLOAD_LIMIT_PROPERTY_KEY = 'download_limit_reset_date';
  const downloadResetDate = yield select(state => state.download.downloadResetDate);
  const formattedDownloadResetDate = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).format(new Date(downloadResetDate));
  hubspotTrackCustomBehaviorEvent('pe5474140_hit_download_limit', {
    [HIT_DOWNLOAD_LIMIT_PROPERTY_KEY]: formattedDownloadResetDate
  });
}

export default function* hubspotSagas() {
  yield takeEvery(AUTH_SUCCESS, hubspotIdentify);
  yield takeEvery(SAVE_AUDIO_SUCCESS, hubspotSaveAudioSuccess);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, hubspotDownloadRequest);
  yield takeEvery(ADD_SCRIBE_SUCCESS, hubspotAddScribeSuccess);
  yield takeEvery(LOCATION_CHANGE, hubspotTrackPreviewPageView);
  yield takeEvery(PLAY_FROM_EDITOR, hubspotTrackCustomBehaviorEvent, 'pe5474140_vsc___preview_from_any_point', null);
  yield takeEvery(ADD_CAMERA, hubspotTrackCustomBehaviorEvent, 'pe5474140_vsc___add_camera_to_canvas', null);
  yield takeEvery(TOGGLE_NEW_TIMELINE, hubspotTrackTimelineOn);
  yield takeEvery(
    SET_ELEMENTS_TIMINGS,
    hubspotTrackCustomBehaviorEvent,
    'pe5474140_vsc___timeline___set_animation_time',
    null
  );
  yield takeEvery(CREATE_SCENE, hubspotTrackCustomBehaviorEvent, 'pe5474140_vsc___add_scene', null);
  yield takeEvery(
    SET_SCENE_TRANSITION_TYPE,
    hubspotTrackCustomBehaviorEvent,
    'pe5474140_vsc___set_scene_transition',
    null
  );
  yield takeEvery(TRACK_HIT_DOWNLOAD_LIMIT, hubspotTrackHitDownloadLimit);
  yield takeEvery(
    TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED,
    hubspotTrackCustomBehaviorEvent,
    'pe5474140_hit_project_duration_limit_modal_displayed',
    null
  );
  yield takeEvery(
    TRACK_HIT_PROJECT_DURATION_LIMIT,
    hubspotTrackCustomBehaviorEvent,
    'pe5474140_hit_project_duration_limit',
    null
  );
}
