import { useAudioAssetInProjectSuccess } from 'js/actionCreators/audioActions';
import { updateScribe } from 'js/actionCreators/scribeActions';
import { ExistingScribeModel } from 'js/types';
import { PutEffect, put } from 'redux-saga/effects';

export function* useAudioAssetInProjectSuccessSaga(
  clonedProject: ExistingScribeModel,
  activeAudioClipAssetId?: number,
  activeAudioClipId?: string
): Generator<PutEffect, void, unknown> {
  const foundActiveAudioClipId = clonedProject.audioClips?.find(clip => clip.assetId === activeAudioClipAssetId)?.id;
  yield put(updateScribe(clonedProject, false, undefined, activeAudioClipId ?? foundActiveAudioClipId));
  yield put(useAudioAssetInProjectSuccess());
}
