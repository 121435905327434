import Dexie, { Table } from 'dexie';
import { PathingPointsWithMetadata } from 'js/playback/lib/Playback/helpers/generatePathFromSkeletons';
import { MaskPathAndBrushData, SVGMaskPathAndBrushData, ConvertedSVGData, ExtractedGIFData } from 'js/types';

interface MaskPathCache {
  id: string;
  data: Array<MaskPathAndBrushData>;
}

interface GIFCache {
  id: string;
  data: ExtractedGIFData;
}

interface ConvertedSVGDataCache {
  id: string;
  data: ConvertedSVGData;
}

interface FontBaselineCache {
  fontName: string;
  fontBaselineShift: number;
}

interface DatabaseAsset {
  assetId: string;
  assetBlob: Blob;
  dateAdded: Date;
}

interface SVGMaskPathCache {
  id: string;
  data: SVGMaskPathAndBrushData;
}

interface SVGSkeletonPathsCache {
  id: string;
  data: Array<PathingPointsWithMetadata>;
}

class AssetDatabase extends Dexie {
  assets!: Dexie.Table<DatabaseAsset, string>;
  constructor(databaseName: string) {
    super(databaseName);
    this.version(1).stores({
      assets: '&assetId,dateAdded'
    });

    this.open().then(() => {
      clearOldAssets();
    });
  }
}

const assetDatabase = new AssetDatabase('assetDatabase');

export const addAssetToDatabase = async (assetId: string, assetBlob: Blob) => {
  try {
    await assetDatabase.assets.put({
      assetId,
      assetBlob,
      dateAdded: new Date()
    });
  } catch (error) {
    console.error(error);
  }
};

export const getAssetsFromDatabase = async (assetIds: Array<string>) => {
  try {
    const results = await assetDatabase.assets.bulkGet(assetIds);
    return results;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const clearOldAssets = async () => {
  try {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    const oldAssets = await assetDatabase.assets
      .where('dateAdded')
      .below(threeMonthsAgo)
      .toArray();

    return await assetDatabase.assets.bulkDelete(oldAssets.map(asset => asset.assetId));
  } catch (error) {
    console.error(error);
  }
};

class VSDexie extends Dexie {
  characterMaskPaths!: Table<MaskPathCache>;
  convertedSVGs!: Table<ConvertedSVGDataCache>;
  gifs!: Table<GIFCache>;
  fontBaseline!: Table<FontBaselineCache>;
  svgRevealPaths!: Table<SVGMaskPathCache>;
  svgSkeletonPaths!: Table<SVGSkeletonPathsCache>;

  constructor(databaseName: string) {
    super(databaseName);

    // We use version numbers to manage schema changes similar to MYSQL database migrations
    // https://dexie.org/docs/Tutorial/Understanding-the-basics
    this.version(1).stores({
      characterMaskPaths: '&id'
    });
    this.version(2).stores({
      gifs: '&id'
    });
    this.version(3).stores({
      convertedSVGs: '&id'
    });
    this.version(4).stores({
      fontBaseline: '&fontName'
    });
    this.version(5).upgrade(async transaction => {
      await transaction.table('convertedSVGs').clear();
    });
    this.version(6).upgrade(async transaction => {
      // VSP2-3399 - Error in how Firefox processes SVG with no preserveAspectRatio attribute means Firefox cache is corrupt. Clearing down
      await transaction.table('convertedSVGs').clear();
    });
    this.version(7).stores({
      svgRevealPaths: '&id'
    });
    this.version(8).upgrade(async transaction => {
      // VSP2-3691 - Text drawing algorithm has been improved. Clear down the existing path cache.
      await transaction.table('characterMaskPaths').clear();
    });
    this.version(9).stores({
      svgSkeletonPaths: '&id'
    });
    this.version(10).upgrade(async transaction => {
      // VSP2-3844 - Bug with character mask path generation. Clearing local cache as it will be corrupted.
      await transaction.table('characterMaskPaths').clear();
    });
  }
}

const db = new VSDexie('VideoScribe');

export const storeMaskPath = async (id: string, data: Array<MaskPathAndBrushData>) => {
  try {
    await db.characterMaskPaths.put({ id, data });
  } catch (error) {
    console.error(error);
  }
};

export const getMaskPath = async (id: string) => {
  try {
    return await db.characterMaskPaths.get(id);
  } catch (error) {
    console.error(error);
  }
};

export const storeConvertedSVGData = async (id: string, data: ConvertedSVGData) => {
  try {
    await db.convertedSVGs.put({ id, data });
  } catch (error) {
    console.error(error);
  }
};

export const getConvertedSVGData = async (id: string) => {
  try {
    return await db.convertedSVGs.get(id);
  } catch (error) {
    console.error(error);
  }
};

export const deleteSVGDataBulk = async (ids: Array<string>) => {
  try {
    return await db.convertedSVGs.bulkDelete(ids);
  } catch (error) {
    console.error(error);
  }
};

export const moveConvertedSVGData = async (tempId: string, newId: string) => {
  try {
    const tempData = await getConvertedSVGData(tempId);
    if (!tempData) {
      return;
    }

    await storeConvertedSVGData(newId, tempData.data);
    await deleteSVGDataBulk([tempId]);
  } catch (error) {
    console.error(error);
  }
};

export const storeGIFData = async (id: string, data: ExtractedGIFData) => {
  try {
    await db.gifs.put({ id, data });
  } catch (error) {
    console.error(error);
  }
};

export const getGIFData = async (id: string) => {
  try {
    return await db.gifs.get(id);
  } catch (error) {
    console.error(error);
  }
};

export const deleteGIFDataBulk = async (ids: Array<string>) => {
  try {
    return await db.gifs.bulkDelete(ids);
  } catch (error) {
    console.error(error);
  }
};

export const moveGIFData = async (tempId: string, newId: string) => {
  try {
    const tempData = await getGIFData(tempId);

    if (!tempData) {
      return;
    }

    await storeGIFData(newId, tempData.data);
    await deleteGIFDataBulk([tempId]);
  } catch (error) {
    console.error(error);
  }
};

export const storeFontBaselineShift = async (fontName: string, fontBaselineShift: number) => {
  try {
    await db.fontBaseline.put({
      fontBaselineShift,
      fontName
    });
  } catch (error) {
    console.error(error);
  }
};

export const getFontBaselineShift = async (fontName: string) => {
  try {
    return await db.fontBaseline.get(fontName);
  } catch (error) {
    console.error(error);
  }
};

export const deleteFontBaselineShift = async (fontName: string) => {
  try {
    return await db.fontBaseline.delete(fontName);
  } catch (error) {
    console.error(error);
  }
};

export const storeSVGMaskPath = async (assetId: number, data: SVGMaskPathAndBrushData) => {
  try {
    return await db.svgRevealPaths.put({
      id: assetId.toString(),
      data
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSVGMaskPath = async (assetId: number) => {
  try {
    return await db.svgRevealPaths.get(assetId.toString());
  } catch (error) {
    console.error(error);
  }
};

export const deleteSVGMaskPath = async (assetId: number) => {
  try {
    return await db.svgRevealPaths.delete(assetId.toString());
  } catch (error) {
    console.error(error);
  }
};

export const storeSVGSkeletonPaths = async (id: string, data: Array<PathingPointsWithMetadata>) => {
  try {
    return await db.svgSkeletonPaths.put({ id, data });
  } catch (error) {
    console.error(error);
  }
};

export const getSVGSkeletonPaths = async (id: string) => {
  try {
    return await db.svgSkeletonPaths.get(id);
  } catch (error) {
    console.error(error);
  }
};
