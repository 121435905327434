import { getActiveElements, getScribeById } from 'js/sagas/selectors';
import { takeEvery, select, put, call } from 'redux-saga/effects';
import cloneDeep from 'lodash.clonedeep';
import { EDITOR_KEYBOARD_DELETE, EDITOR_COPY } from 'js/actionCreators/editorActions';
import { deleteActiveElement, deleteSelectedScenes } from 'js/actionCreators/scribeActions';
import { deleteAudioFromProject } from 'js/actionCreators/audioActions';
import canDeleteScenes from 'js/shared/helpers/canDeleteScenes';
import { KEYBOARD_TRIGGER } from 'js/config/consts';

import createSceneClipboardPayload from './sagaHelpers/createSceneClipboardPayload';
import { writeToClipboard } from './sagaHelpers/writeToClipboard';

function* handleKeyboardDelete({ scribeId }) {
  // Determine if we are dealing with scene or elements or nothing and fire off the appropriate action
  const scribe = yield select(getScribeById, scribeId);
  const { selectedSceneIds, activeElements, selectedAudioClip } = yield select(state => state.scribes);

  const hasSelectedScene = selectedSceneIds.length > 0;
  const hasActiveElements = activeElements.length > 0;
  const hasSelectedAudioClip = selectedAudioClip !== null;
  const hasNoActiveSelection = !hasSelectedScene && !hasActiveElements && !hasSelectedAudioClip;
  if (hasNoActiveSelection) return;

  switch (true) {
    case hasSelectedAudioClip:
      yield put(deleteAudioFromProject(scribeId, selectedAudioClip.id));
      break;
    case hasSelectedScene:
      const areDeletableScenes = canDeleteScenes(scribe, selectedSceneIds);
      if (!areDeletableScenes) return;
      yield put(deleteSelectedScenes(scribeId, KEYBOARD_TRIGGER));
      break;
    case hasActiveElements:
      yield put(deleteActiveElement(scribeId));
      break;
    default:
      return;
  }
}

function* handleEditorCopy({ scribeId, asCut = false }) {
  const scribe = yield select(getScribeById, scribeId);
  const activeScene = scribe.scenes.find(scene => scene.active);
  const activeElementIds = cloneDeep(yield select(getActiveElements));
  const selectedSceneIds = yield select(state => state.scribes.selectedSceneIds);

  const hasScenesSelected = !!selectedSceneIds.length;
  const hasElementsSelected = !!activeElementIds.length;

  if (!scribe || (!hasScenesSelected && !hasElementsSelected)) return;

  if (hasScenesSelected) {
    const clipboardPayload = createSceneClipboardPayload(scribe, selectedSceneIds);
    yield call(writeToClipboard, clipboardPayload);
  } else {
    const activeSceneElementIds = activeScene.elementIds;

    activeElementIds.sort((a, b) => activeSceneElementIds.indexOf(a) - activeSceneElementIds.indexOf(b));

    const elementClones = activeElementIds.map(elementId => {
      const clone = cloneDeep(scribe.elements.find(el => el.id === elementId));
      delete clone.id;
      return clone;
    });

    const clipboardPayload = {
      type: 'element',
      metadata: {
        sceneId: activeScene.id,
        wasCut: asCut
      },
      data: elementClones
    };

    yield call(writeToClipboard, clipboardPayload);
  }
}

export default function* editorSagas() {
  yield takeEvery(EDITOR_KEYBOARD_DELETE, handleKeyboardDelete);
  yield takeEvery(EDITOR_COPY, handleEditorCopy);
}
