export const PROCESS_IMAGE_FILE_ON_SUCCESSFUL_UPLOAD = 'PROCESS_IMAGE_FILE_ON_SUCCESSFUL_UPLOAD';
export const PROCESS_AI_IMAGES_REQUEST = 'PROCESS_AI_IMAGES_REQUEST';
export const PROCESS_AI_IMAGE_PROGRESS = 'PROCESS_AI_IMAGE_PROGRESS';
export const PROCESS_AI_IMAGES_SUCCESS = 'PROCESS_AI_IMAGES_SUCCESS';
export const PROCESS_AI_IMAGES_FAILURE = 'PROCESS_AI_IMAGES_FAILURE';
export const PROCESS_AI_IMAGE_RESET = 'PROCESS_AI_IMAGE_RESET';

export interface ProcessImageFileOnSuccessfulUploadAction {
  type: typeof PROCESS_IMAGE_FILE_ON_SUCCESSFUL_UPLOAD;
  file: File;
  assetId: number;
}

export const processImageFileOnSuccessfulUpload = ({
  file,
  assetId
}: Omit<ProcessImageFileOnSuccessfulUploadAction, 'type'>): ProcessImageFileOnSuccessfulUploadAction => ({
  type: PROCESS_IMAGE_FILE_ON_SUCCESSFUL_UPLOAD,
  file,
  assetId
});

export interface AiImage {
  url: string;
  imageBitmap?: ImageBitmap;
}
export interface ProcessAiImagesRequestAction {
  type: typeof PROCESS_AI_IMAGES_REQUEST;
  images: AiImage[];
  pipeline?: 'full' | 'embedded-raster';
}
export const processAiImagesRequest = ({
  images,
  pipeline = 'embedded-raster'
}: Omit<ProcessAiImagesRequestAction, 'type'>): ProcessAiImagesRequestAction => ({
  type: PROCESS_AI_IMAGES_REQUEST,
  images,
  pipeline
});

export interface ProcessAiImageProgressAction {
  type: typeof PROCESS_AI_IMAGE_PROGRESS;
  url: string;
  progress: number;
}
export const processAiImageProgress = ({
  url,
  progress
}: Omit<ProcessAiImageProgressAction, 'type'>): ProcessAiImageProgressAction => ({
  type: PROCESS_AI_IMAGE_PROGRESS,
  url,
  progress
});

type ProcessedImages = {
  url: string;
  blob: Blob;
};

export interface ProcessAiImagesSuccessAction {
  type: typeof PROCESS_AI_IMAGES_SUCCESS;
  images: ProcessedImages[];
}
export const processAiImagesSuccess = ({
  images
}: Omit<ProcessAiImagesSuccessAction, 'type'>): ProcessAiImagesSuccessAction => ({
  type: PROCESS_AI_IMAGES_SUCCESS,
  images
});

export interface ProcessAiImagesFailureAction {
  type: typeof PROCESS_AI_IMAGES_FAILURE;
  error: Error;
}
export const processAiImagesFailure = ({
  error
}: Omit<ProcessAiImagesFailureAction, 'type'>): ProcessAiImagesFailureAction => ({
  type: PROCESS_AI_IMAGES_FAILURE,
  error
});

export interface ProcessAiImageResetAction {
  type: typeof PROCESS_AI_IMAGE_RESET;
}
export const processAiImageReset = (): ProcessAiImageResetAction => ({
  type: PROCESS_AI_IMAGE_RESET
});
