import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { REDIRECT_ON_AUTH_KEY } from 'js/config/sessionStorageKeys';

export default function* redirectUserToReturnPath() {
  const returnPath = window.sessionStorage.getItem(REDIRECT_ON_AUTH_KEY);
  if (returnPath) {
    // Always remove the path from storage once read and redirected to prevent accidental infinite redirect loops
    window.sessionStorage.removeItem(REDIRECT_ON_AUTH_KEY);
    yield put(push(returnPath));
  }
}
