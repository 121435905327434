import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import IconClose from '../icons/IconClose';

import './Flash.css';

const duration = 400;

// Flash notification bar which appears at the top of the screen to notify users.
class Flash extends Component {
  constructor(props) {
    super(props);

    this.onEnter = this.onEnter.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  // If autoDismiss is set then set a timer to fire onDismiss after a timout when the Flash enters
  onEnter() {
    const { onDismiss, autoDismiss } = this.props;

    if (onDismiss && typeof onDismiss === 'function' && autoDismiss) {
      this.timeout = setTimeout(onDismiss, 10000);
    }
  }

  onExit() {
    // Clear the timeout if the user manually dismissed the Flash before the end of the timeout
    clearTimeout(this.timeout);
  }

  // Only render an icon if an icon is passed as props
  renderIcon() {
    const { icon } = this.props;
    if (icon) {
      return <div className="flash__icon-holder">{icon}</div>;
    }
  }

  // Only render the dismiss button if a handler function is passed
  renderDismissable() {
    const { onDismiss } = this.props;
    if (onDismiss && typeof onDismiss === 'function') {
      return (
        <div className="flash__dismiss-holder">
          <button className="flash__dismiss" onClick={onDismiss}>
            <IconClose />
          </button>
        </div>
      );
    }
  }

  render() {
    const { isVisible, message, flashType } = this.props;

    return (
      <CSSTransition
        in={isVisible}
        timeout={duration}
        classNames="flash-"
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
        onEnter={this.onEnter}
        onExit={this.onExit}
      >
        {() => (
          <aside className={`flash flash--${flashType}`} role="alert">
            {this.renderIcon()}
            <div className="flash__message-holder">{message}</div>
            {this.renderDismissable()}
          </aside>
        )}
      </CSSTransition>
    );
  }
}

Flash.propTypes = {
  // Used to display and remove the Flash element
  isVisible: PropTypes.bool.isRequired,
  // Message to display to the user
  message: PropTypes.node.isRequired,
  // Icon markup to display on the left of the Flash
  icon: PropTypes.node,
  // Setting this will display the dismiss button and this will be fired on click of the button
  onDismiss: PropTypes.func,
  // Used to style the flash. This will add the CSS class of flash--{flashType} e.g. flash--success will make the flash green.
  flashType: PropTypes.oneOf(['success', 'warn', 'error', 'default', 'upgrade']).isRequired,
  // Whether the Flash should dismiss itself after a certain timeout
  autoDismiss: PropTypes.bool
};

Flash.defaultProps = {
  isVisible: false,
  message: '',
  flashType: 'default'
};

export default Flash;
