import { getProjectById } from 'js/sagas/selectors';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { useRouteMatch } from 'react-router';

export function useProject() {
  const { params } = useRouteMatch<{ scribeId: string }>();
  const { scribeId: projectId } = params;
  const project = useTypedSelector(state => getProjectById(state, projectId));
  return project;
}
