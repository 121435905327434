import * as PIXI from 'pixi.js'; // Import the 'PIXI' module
import { AdvancedAudioType, AudioClip, ScribeScene } from 'js/types';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { ScribeAudioLayerModel } from 'js/models/ScribeAudioLayerModel';
import { getLengthOfAllAssets, getSceneStartTimeAndLength } from 'js/playback/lib/Playback/helpers/timings';

import TimelineAudioClip from './TimelineAudioClip';
import TimelineWrapper, { TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR } from './TimelineWrapper';
import TimelineAdvancedAudioLayer from './TimelineAdvancedAudioLayer';
import timelineBus, {
  AUDIO_BACKGROUND_LAYER_CLICKED,
  HIDE_AUDIO_LENGTH_ADJUST_INDICATOR,
  PLAYHEAD_HIDE,
  PLAYHEAD_MOVE,
  PLAYHEAD_SHOW,
  SHOW_AUDIO_LENGTH_ADJUST_INDICATOR,
  UPDATE_AUDIO_DURATION,
  UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR,
  WRAPPER_UPDATE_SIZE,
  WRAPPER_UPDATE_ZOOM
} from './TimelineControlBus';
import TimelineAudioDragIndicator from './TimelineAudioDragIndicator';
import TimelineHelpers from './Utils/TimelineHelpers';
import { SplitTrackManager } from './SplitTrackManager';
import { getBitmapRect } from './Utils/GetBitmapRect';

export const AUDIO_TEMP_LAYER = 'temp_layer';

export default class TimelineAdvancedAudioManager {
  wrapperRef: TimelineWrapper;
  sceneAudioLayers: Array<TimelineAdvancedAudioLayer> = [];
  projectAudioLayers: Array<TimelineAdvancedAudioLayer> = [];
  currentDragged?: TimelineAudioClip;
  dragOffset?: PIXI.Point;
  currentlyDraggedStartLayer: TimelineAdvancedAudioLayer | undefined;
  sceneAudioLayersContainer: PIXI.Container;
  layerToDragTo: TimelineAdvancedAudioLayer | undefined;

  currentSceneStartTime = 0;
  projectLayersContainer: PIXI.Container<PIXI.DisplayObject>;
  tempSceneAudioLayer?: ScribeAudioLayerModel;
  tempProjectAudioLayer?: ScribeAudioLayerModel;
  sceneLayerModels?: Array<ScribeAudioLayerModel>;
  projectLayerModels?: Array<ScribeAudioLayerModel>;
  audioDragArea?: AdvancedAudioType;
  clipLengthAdjustIndicator?: TimelineAudioDragIndicator;
  currentScene: ScribeScene;
  clipPool: { [key: string]: TimelineAudioClip } = {};
  layerPool: { [key: string]: TimelineAdvancedAudioLayer } = {};
  clickedTimer = false;
  splitTrackManager: SplitTrackManager;
  lastSelectedAudioClip: string;
  audioScrollboxCenterTarget?: PIXI.Point;
  prevClips: AudioClip[] | undefined;
  rightBoundMainTimelineClip?: PIXI.Graphics;
  rightBoundAudioTimelineClip?: PIXI.Graphics;
  allClipsRightBound = 0;

  constructor(
    wrapperRef: TimelineWrapper,
    sceneAudioLayersContainer: PIXI.Container,
    projectLayersContainer: PIXI.Container
  ) {
    this.wrapperRef = wrapperRef;
    this.sceneAudioLayersContainer = sceneAudioLayersContainer;
    this.projectLayersContainer = projectLayersContainer;
    this.sceneAudioLayers = [];
    this.projectAudioLayers = [];
    this.splitTrackManager = new SplitTrackManager(wrapperRef, this);
    this.lastSelectedAudioClip = '';
    this.currentScene = getActiveScene(this.props.activeScribe);
    timelineBus.on(WRAPPER_UPDATE_SIZE, this.updateSize);
    timelineBus.on(WRAPPER_UPDATE_ZOOM, this.updateSize);

    this.addClipAdjustIndicator();
  }
  get props() {
    return this.wrapperRef.props;
  }
  updateContainers(sceneAudioLayersContainer: PIXI.Container, projectLayersContainer: PIXI.Container) {
    if (this.sceneAudioLayersContainer) this.sceneAudioLayersContainer.removeChildren();
    if (this.projectLayersContainer) this.projectLayersContainer.removeChildren();

    this.sceneAudioLayersContainer = sceneAudioLayersContainer;
    this.projectLayersContainer = projectLayersContainer;
  }

  addClipAdjustIndicator() {
    this.clipLengthAdjustIndicator = new TimelineAudioDragIndicator(this.wrapperRef);
    this.wrapperRef.app.stage.addChild(this.clipLengthAdjustIndicator);
    this.clipLengthAdjustIndicator.visible = false;
    timelineBus.on(SHOW_AUDIO_LENGTH_ADJUST_INDICATOR, this.showClipLengthIndicator);
    timelineBus.on(HIDE_AUDIO_LENGTH_ADJUST_INDICATOR, this.hideClipLengthIndicator);
    timelineBus.on(UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR, this.updateClipLengthIndicator);
    timelineBus.on(AUDIO_BACKGROUND_LAYER_CLICKED, this.audioBackgroundLayerClicked);
    timelineBus.on(UPDATE_AUDIO_DURATION, this.initialDurationSetIfUndefined);
  }

  initialDurationSetIfUndefined = (clip: AudioClip, duration: number) => {
    if (
      (clip.duration === undefined && clip.instanceDuration === undefined) ||
      (clip.duration === 0 && clip.instanceDuration === 0)
    ) {
      this.props.onSetAudioDuration(clip, duration);
    }
  };

  audioBackgroundLayerClicked = () => {
    this.props.onAudioSelected(undefined);
  };

  updateClipLengthIndicator = (position: PIXI.Point, audioClip: AudioClip, isStart: boolean) => {
    if (this.splitTrackManager.splitMode) {
      this.splitTrackManager.updateSplitPosition(position);
      return;
    }

    const relativePos = this.wrapperRef.audioElementLayersHolder?.toLocal(position);
    if (!relativePos) return;
    if (this.clipLengthAdjustIndicator) {
      this.wrapperRef.app.stage.addChild(this.clipLengthAdjustIndicator);
      this.clipLengthAdjustIndicator.x = relativePos.x;
      this.clipLengthAdjustIndicator.y = relativePos.y;
      const draggedTime =
        relativePos.x / this.wrapperRef.currentZoom.x - (audioClip.type === 'scene' ? this.currentSceneStartTime : 0); //
      const startOffset = (audioClip?.startTime ?? 0) - (audioClip?.startOffset ?? 0);
      const endOffset = startOffset + (audioClip?.duration ?? 0);

      this.clipLengthAdjustIndicator.updateOffset(
        isStart ? startOffset - draggedTime : endOffset - draggedTime,
        !isStart,
        position
      );
      this.clipLengthAdjustIndicator.visible = !this.splitTrackManager.splitMode;

      this.updateClipPositionIndicator(
        position.x,
        TimelineHelpers.formatTime(relativePos.x / this.wrapperRef.currentZoom.x)
      );
    }
  };

  showClipPositionIndicator = (audioClip: TimelineAudioClip) => {
    timelineBus.emit(PLAYHEAD_SHOW, audioClip);
  };

  updateClipPositionIndicator = (globalXPosition: number, label: string) => {
    timelineBus.emit(PLAYHEAD_MOVE, globalXPosition, label);
  };

  hideClipPositionIndicator = () => {
    timelineBus.emit(PLAYHEAD_HIDE);
  };

  hideClipLengthIndicator = () => {
    if (this.clipLengthAdjustIndicator && this.clipLengthAdjustIndicator.visible) {
      this.clipLengthAdjustIndicator.visible = false;
      this.hideClipPositionIndicator();
    }
  };

  showClipLengthIndicator = (position: PIXI.Point, audioClip: AudioClip, isStart: boolean) => {
    if (this.clipLengthAdjustIndicator) {
      this.clipLengthAdjustIndicator.visible = !this.splitTrackManager.splitMode;
    }

    const timelineAudioClip = this.getTimelineAudioClip(audioClip);

    if (timelineAudioClip) {
      this.showClipPositionIndicator(timelineAudioClip);
      this.updateClipLengthIndicator(position, audioClip, isStart);
    }
  };

  destroy() {
    timelineBus.off(WRAPPER_UPDATE_SIZE, this.updateSize);
    timelineBus.off(WRAPPER_UPDATE_ZOOM, this.updateSize);
    timelineBus.off(SHOW_AUDIO_LENGTH_ADJUST_INDICATOR, this.showClipLengthIndicator);
    timelineBus.off(HIDE_AUDIO_LENGTH_ADJUST_INDICATOR, this.hideClipLengthIndicator);
    timelineBus.off(UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR, this.updateClipLengthIndicator);
    timelineBus.off(AUDIO_BACKGROUND_LAYER_CLICKED, this.audioBackgroundLayerClicked);
    timelineBus.off(UPDATE_AUDIO_DURATION, this.initialDurationSetIfUndefined);
    this.removeTempLayer();
    this.clipLengthAdjustIndicator?.destroy();
    this.splitTrackManager.destroy();
    this.layerPool = {};
    this.clipPool = {};
    this.sceneAudioLayers = [];
    this.projectAudioLayers = [];
    this.currentDragged = undefined;
    this.dragOffset = undefined;
    this.currentlyDraggedStartLayer = undefined;
    this.sceneAudioLayersContainer.removeChildren();
    this.projectLayersContainer.removeChildren();
  }
  scrollToBottom() {
    this.rebuildView();
    if (this.wrapperRef.audioScrollBox) {
      this.wrapperRef.mainToAudioBoxSync();

      const corner = this.wrapperRef.audioScrollBox?.content.corner.clone();
      if (corner) {
        corner.y -= this.wrapperRef.audioScrollBox?.content.height;
        this.wrapperRef.audioScrollBox.content.y = corner.y;
      }
    }
  }
  rebuildView() {
    this.currentScene = getActiveScene(this.props.activeScribe);

    this.sceneLayerModels = [];
    this.projectLayerModels = [];
    //if there are no audio layers in the scene, destroy all the layers

    this.sceneAudioLayersContainer.removeChildren();
    this.projectLayersContainer.removeChildren();
    this.sceneAudioLayers = [];
    this.projectAudioLayers = [];

    if (!this.currentScene) {
      return;
    }
    //for each audio layer in the model, create a new layerModel
    this.props.activeScribe.projectAudioLayerIds?.forEach(layerId => {
      const layer = this.props.activeScribe.audioLayers.find(layer => layer.id === layerId);
      if (layer && this.projectLayerModels) this.projectLayerModels.push(layer);
    });

    //for each audio layer in the model, create a new layerModel
    this.currentScene.audioLayerIds?.forEach(layerId => {
      const layer = this.props.activeScribe.audioLayers.find(layer => layer.id === layerId);
      if (layer && this.sceneLayerModels) this.sceneLayerModels.push(layer);
    });
    //destroy all the layers that are not in the model
    this.updateStartTime(getSceneStartTimeAndLength(this.props.activeScribe, this.currentScene).sceneStartTime / 1000);

    this.updateItemsInContainer(
      this.sceneLayerModels,
      this.sceneAudioLayers,
      this.sceneAudioLayersContainer,
      this.currentScene,
      'scene'
    );
    this.updateItemsInContainer(
      this.projectLayerModels,
      this.projectAudioLayers,
      this.projectLayersContainer,
      this.currentScene,
      'project'
    );

    if (
      !(this.currentScene?.audioLayerIds?.length === 0 && this.props.activeScribe.projectAudioLayerIds?.length === 0)
    ) {
      this.createTempLayer('scene');

      this.createTempLayer('project');
    }

    this.wrapperRef.getScrollBoxHeights();
    this.wrapperRef.updateScrollBoxContents();
    this.wrapperRef.updateSize(true);
    this.updateSize();

    for (const layer in this.layerPool) {
      if (
        !this.sceneLayerModels.find(layerModel => layerModel.id === layer) &&
        !this.projectLayerModels.find(layerModel => layerModel.id === layer)
      ) {
        this.layerPool[layer].destroy();
        delete this.layerPool[layer];
      }
    }
    //destroy all the clips that are not in the model
    for (const clip in this.clipPool) {
      if (this.props.activeScribe.audioClips) {
        if (!this.props.activeScribe.audioClips.find(clipModel => clipModel.id === clip)) {
          this.clipPool[clip].destroy();
          delete this.clipPool[clip];
        }
      }
    }
    if (this.props.selectedAudioClip?.id) {
      if (this.lastSelectedAudioClip !== this.props.selectedAudioClip.id) {
        this.lastSelectedAudioClip = this.props.selectedAudioClip.id;
      }
    }
    if (
      !this.props.audioSplitElement ||
      (this.props.audioSplitElement &&
        (this.props.audioSplitElement !== this.lastSelectedAudioClip ||
          !this.getTimelineAudioClipById(this.props.audioSplitElement)))
    ) {
      this.splitTrackManager.setSplitModeToFalse();
    } else {
      const element = this.props.audioSplitElement;
      if (element) {
        this.splitTrackManager.startSplitModeStateUpdate(element);
      }
    }
    this.allClipsRightBound = 0;
    this.projectAudioLayers.forEach(layer => {
      layer.timelineAudioClips.forEach(clip => {
        if (clip.x + clip.background.width > this.allClipsRightBound) {
          this.allClipsRightBound = clip.x + clip.background.width;
        }
      });
    });

    if (this.wrapperRef.scrollBox && this.wrapperRef.audioScrollBox) {
      if (!this.rightBoundMainTimelineClip) {
        this.rightBoundMainTimelineClip = getBitmapRect(1, 0xff0000);
        this.rightBoundMainTimelineClip.width = 1;
        this.rightBoundMainTimelineClip.alpha = 0;
        this.rightBoundMainTimelineClip.y = 0;
      }
      if (!this.rightBoundAudioTimelineClip) {
        this.rightBoundAudioTimelineClip = getBitmapRect(1, 0xff0000);
        this.rightBoundAudioTimelineClip.width = 1;
        this.rightBoundAudioTimelineClip.alpha = 0;
        this.rightBoundAudioTimelineClip.y = 0;
      }
      this.wrapperRef.audioScrollBox.content.addChild(this.rightBoundAudioTimelineClip);
      this.wrapperRef.scrollBox.content.addChild(this.rightBoundMainTimelineClip);
      this.rightBoundMainTimelineClip.x = this.rightBoundAudioTimelineClip.x = this.allClipsRightBound;

      this.wrapperRef.scrollBox.updateTransform();
      const extendedLength = getLengthOfAllAssets(this.props.activeScribe);
      if (extendedLength !== this.wrapperRef.extendedScribeLength) {
        if (this.wrapperRef.topMeasure) {
          this.wrapperRef.extendedScribeLength = extendedLength;
          this.wrapperRef.topMeasure.updateScribeLength(extendedLength, this.wrapperRef.currentZoom);
        }
      }
    }
    this.wrapperRef.updateSceneLabels();
  }

  updateSize = () => {
    this.updateSceneLayers();
    this.updateProjectLayers();
    this.sceneAudioLayersContainer.y = 0;
    this.projectLayersContainer.y = this.sceneAudioLayersContainer.y + this.sceneAudioLayersContainer.height + 1;
  };

  updateItemsInContainer = (
    layerModels: Array<ScribeAudioLayerModel>,
    timelineLayersArray: TimelineAdvancedAudioLayer[],
    layersHolder: PIXI.Container,
    scene: ScribeScene,
    type: AdvancedAudioType = 'scene'
  ) => {
    layersHolder.interactiveChildren = true;

    layerModels.forEach((layer, index) => {
      //if there is a new layer in the model that is not in the timeline, create a new layer
      let timelineLayer = this.layerPool[layer.id];

      if (!timelineLayer) {
        timelineLayer = new TimelineAdvancedAudioLayer(layer, this.wrapperRef, type, this);
        this.layerPool[layer.id] = timelineLayer;
      } else {
        timelineLayer.updateLayer(layer);
      }
      timelineLayersArray.push(timelineLayer);
      timelineLayer.y = index * this.wrapperRef.currentZoom.y;
      layersHolder.addChild(timelineLayer);

      timelineLayer.timelineAudioClips.forEach(clip => {
        const audioClip = clip as TimelineAudioClip;
        if (audioClip) {
          audioClip.tintWaveform(type === 'scene' ? TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR : undefined);
        }
      });
    });

    timelineLayersArray.forEach((timelineLayer, index) => {
      //if there is a layer in the timeline that is not in the model, destroy it

      if (!layerModels.find(layer => layer.id === timelineLayer.audioLayerModel.id)) {
        timelineLayersArray.splice(index, 1);

        timelineLayer.destroy();
      }
    });

    timelineLayersArray.sort((a, b) => {
      if (!scene.audioLayerIds) return 0;
      return scene.audioLayerIds.indexOf(a.audioLayerModel.id) - scene.audioLayerIds.indexOf(b.audioLayerModel.id);
    });
  };

  createTempLayer(layerType: AdvancedAudioType = 'scene'): TimelineAdvancedAudioLayer {
    let tempLayer;
    if (layerType === 'project') {
      tempLayer = this.projectAudioLayers.find(layer => layer.audioLayerModel.id === AUDIO_TEMP_LAYER);
    }
    if (layerType === 'scene') {
      tempLayer = this.sceneAudioLayers.find(layer => layer.audioLayerModel.id === AUDIO_TEMP_LAYER);
    }

    if (!tempLayer) {
      tempLayer = new TimelineAdvancedAudioLayer(
        new ScribeAudioLayerModel({
          id: AUDIO_TEMP_LAYER,
          audioClipIds: []
        }),
        this.wrapperRef,
        layerType,
        this
      );
    }

    if (layerType === 'project') {
      if (this.projectAudioLayers.includes(tempLayer)) {
        this.projectAudioLayers.unshift(
          this.projectAudioLayers.splice(this.projectAudioLayers.indexOf(tempLayer), 1)[0]
        );
      } else {
        this.projectAudioLayers.unshift(tempLayer);
      }
      this.projectLayersContainer.addChild(tempLayer);
      this.updateProjectLayers();
    } else {
      if (this.sceneAudioLayers.includes(tempLayer)) {
        this.sceneAudioLayers.unshift(this.sceneAudioLayers.splice(this.sceneAudioLayers.indexOf(tempLayer), 1)[0]);
      } else {
        this.sceneAudioLayers.unshift(tempLayer);
      }
      this.sceneAudioLayersContainer.addChild(tempLayer);
      this.updateSceneLayers();
    }
    this.layerToDragTo = tempLayer;
    return tempLayer;
  }

  removeTempLayer() {
    let tempLayer = this.sceneAudioLayers.find(layer => layer.audioLayerModel.id === AUDIO_TEMP_LAYER);
    if (tempLayer) {
      this.sceneAudioLayers = this.sceneAudioLayers.filter(layer => layer !== tempLayer);
      tempLayer.destroy();
    }

    tempLayer = this.projectAudioLayers.find(layer => layer.audioLayerModel.id === AUDIO_TEMP_LAYER);
    if (tempLayer) {
      this.projectAudioLayers = this.projectAudioLayers.filter(layer => layer !== tempLayer);
      tempLayer.destroy();
    }
  }
  updateSelectedClip() {
    this.sceneAudioLayers.forEach(layer => {
      layer.timelineAudioClips.forEach(clip => {
        if (clip.audioClip.id === this.props.selectedAudioClip?.id) {
          if (this.props.selectedAudioClip?.id && this.props.selectedAudioClip?.id !== this.lastSelectedAudioClip) {
            clip.showSelected();

            this.wrapperRef.scrollToAudioClip(clip, layer);
          }
        } else {
          clip.hideSelected();
        }
      });
    });
    this.projectAudioLayers.forEach(layer => {
      layer.timelineAudioClips.forEach(clip => {
        if (clip.audioClip.id === this.props.selectedAudioClip?.id) {
          if (this.props.selectedAudioClip?.id && this.props.selectedAudioClip?.id !== this.lastSelectedAudioClip) {
            clip.showSelected();
            this.wrapperRef.scrollToAudioClip(clip, layer);
          }
        } else {
          clip.hideSelected();
        }
      });
    });
    this.lastSelectedAudioClip = this.props.selectedAudioClip?.id ?? '';
  }

  updateSceneLayers() {
    this.sceneAudioLayers.forEach((layer, index) => {
      layer.y = index * this.wrapperRef.currentZoom.y;
      layer.updateZoom(this.wrapperRef.currentZoom);
      this.updateSelectedClip();
    });
  }

  updateProjectLayers() {
    this.projectAudioLayers.forEach((layer, index) => {
      layer.y = index * this.wrapperRef.currentZoom.y;
      layer.updateZoom(this.wrapperRef.currentZoom);
      this.projectLayersContainer.addChild(layer);
      this.updateSelectedClip();
    });
  }

  updateZoom(zoom: PIXI.Point) {
    this.sceneAudioLayers.forEach(layer => {
      layer.updateZoom(zoom);
    });
    this.updateSceneLayers();

    this.projectAudioLayers.forEach(layer => {
      layer.updateZoom(zoom);
    });
    this.updateProjectLayers();
  }

  getTimelineAudioClip(audioClip: AudioClip): TimelineAudioClip | undefined {
    if (audioClip.id) {
      return this.getTimelineAudioClipById(audioClip.id);
    }
    return undefined;
  }

  public getTimelineAudioClipById(audioClipId: string): TimelineAudioClip | undefined {
    for (const layer of [...this.projectAudioLayers, ...this.sceneAudioLayers]) {
      const clip = layer.timelineAudioClips.find(clip => clip.audioClip.id === audioClipId);
      if (clip) return clip;
    }
    return undefined;
  }
  checkIfClipIsRegistered(audioClip: TimelineAudioClip) {
    return this.getAudioClipLayer(audioClip) !== undefined;
  }

  getAudioClipLayer(audioClip: TimelineAudioClip) {
    return [...this.projectAudioLayers, ...this.sceneAudioLayers].find(layer => {
      return layer.timelineAudioClips.includes(audioClip);
    });
  }

  registerClip = (audioClip: TimelineAudioClip) => {
    audioClip.background.removeAllListeners();
    audioClip.background.interactive = true;
    audioClip.background.on('pointerdown', this.audioClipDragStart);
    audioClip.background.on('pointerup', this.audioClipDragComplete);
    audioClip.background.on('pointerupoutside', this.audioClipDragComplete);
    audioClip.background.on('rightclick', this.showAudioContextMenu);
  };

  showAudioContextMenu = (event: PIXI.InteractionEvent) => {
    if (event.data.originalEvent instanceof MouseEvent) {
      const audioClip = (event.target.parent as unknown as TimelineAudioClip).audioClip;
      this.props.onAudioSelected(audioClip);

      const mouseX = event.data.originalEvent.clientX;
      const mouseY = event.data.originalEvent.clientY;
      const duration = audioClip.instanceDuration ?? audioClip.duration ?? 0;

      this.wrapperRef.onContextClick({
        scribeId: this.props.activeScribe.id,
        type: 'audio',
        audioType: audioClip.type,
        duration,
        mouseX,
        mouseY,
        clipId: audioClip.id
      });
    }
  };
  audioClipDragStart = (event: PIXI.InteractionEvent) => {
    event.stopPropagation();

    this.currentDragged = event.target.parent as unknown as TimelineAudioClip;

    window.addEventListener('pointerup', this.audioClipDragComplete, { passive: false });
    this.currentDragged.background.on('pointerup', this.audioClipDragComplete);
    this.currentDragged.background.on('pointerupoutside', this.audioClipDragComplete);

    this.dragOffset = event.data.getLocalPosition(this.currentDragged);
    this.currentlyDraggedStartLayer = this.getAudioClipLayer(this.currentDragged);
    this.updateClipPositionIndicator(...this.getClipPositionPlayheadData(this.currentDragged.getDragGraphic()));
    this.showClipPositionIndicator(this.currentDragged);

    this.checkAudioDragged(event);
    this.wrapperRef.timelineHolder?.off('pointermove', this.checkAudioDragged);
    this.wrapperRef.timelineHolder?.on('pointermove', this.checkAudioDragged);
  };

  audioClipDragComplete = (event: PIXI.InteractionEvent | PointerEvent) => {
    this.clearScrollToDraggedClip();
    this.wrapperRef.timelineHolder?.off('pointermove', this.checkAudioDragged);
    event.stopPropagation();
    window.removeEventListener('pointerup', this.audioClipDragComplete);
    const clicked = this.currentDragged;
    if (this.splitTrackManager.splitMode && clicked) {
      setTimeout(() => {
        if (this.splitTrackManager.splitMode) this.splitTrackManager.showSplitTrackConfirmation();
      }, 10);

      return;
    }
    if (clicked) {
      setTimeout(() => {
        this.props.onAudioSelected(clicked.audioClip);
      }, 10);
    }

    if (this.layerToDragTo && this.currentDragged && this.audioDragArea) {
      const draggedClip = this.currentDragged;
      this.props.onAudioDragged(
        draggedClip.audioClip,
        draggedClip.getDragGraphic().x / this.wrapperRef.currentZoom.x -
          (this.audioDragArea === 'scene' ? this.currentSceneStartTime : 0),
        this.layerToDragTo.audioLayerModel.id,
        this.audioDragArea
      );
      draggedClip.removeDragGraphic();
    }
    this.currentDragged?.background.off('pointerup', this.audioClipDragComplete);
    this.currentDragged?.background.off('pointerupoutside', this.audioClipDragComplete);

    this.currentDragged?.removeDragGraphic();
    this.currentDragged = undefined;
    this.layerToDragTo = undefined;
    this.audioDragArea = undefined;

    this.hideClipPositionIndicator();
  };

  updateStartTime(sceneStartTime: number) {
    this.currentSceneStartTime = sceneStartTime;
  }

  checkAudioDragged = (event: PIXI.InteractionEvent) => {
    if (this.splitTrackManager.splitMode) {
      this.splitTrackManager.splitMoved(event.data.global);
      return;
    }

    if (this.currentDragged) {
      const draggedClip = this.currentDragged.getDragGraphic();
      if (this.dragOffset && this.sceneAudioLayersContainer) {
        let localPos = this.sceneAudioLayersContainer.toLocal(event.data.global);
        // if the mouse is over the audio layers container, move the dragged clip to the scene

        if (this.projectLayersContainer && event.data.global.y < this.projectLayersContainer.getBounds().top) {
          this.sceneAudioLayersContainer.addChild(draggedClip);
          localPos = this.sceneAudioLayersContainer.toLocal(event.data.global);
          this.audioDragArea = 'scene';
        } else {
          // if the mouse is over the project layers container, move the dragged clip to the project
          this.projectLayersContainer.addChild(draggedClip);
          localPos = this.projectLayersContainer.toLocal(event.data.global);
          this.audioDragArea = 'project';
        }
        // move the dragged clip to the mouse position
        draggedClip.x = localPos.x - this.dragOffset.x;

        if (this.currentlyDraggedStartLayer === undefined) return;
        // check if the dragged clip is over another clip
        this.layerToDragTo = (this.audioDragArea === 'project' ? this.projectAudioLayers : this.sceneAudioLayers).find(
          layer => {
            return localPos.y > layer.y && localPos.y < layer.y + layer.height;
          }
        );

        let audioClipsInLayer: TimelineAudioClip[] = [];
        if (this.layerToDragTo) {
          audioClipsInLayer = this.layerToDragTo.timelineAudioClips;
        }
        if (this.layerToDragTo) {
          //can drop the clip to the layer
          const cantDrop = this.adjustClip(audioClipsInLayer);
          if (cantDrop) {
            //it cant fit in the layer
            //find the closest layer to drop the clip to
            let dropableLayers = [...this.sceneAudioLayers];
            if (this.audioDragArea === 'project') dropableLayers = [...this.projectAudioLayers];
            dropableLayers.sort((a, b) => {
              if (this.currentDragged === undefined) return 0;
              return Math.abs(a.y - draggedClip.y) - Math.abs(b.y - draggedClip.y);
            });

            dropableLayers = dropableLayers.filter(layer => this.canDropClipToLayer(layer));

            // drop it on the closest layer
            this.layerToDragTo = dropableLayers[0];
          }
          draggedClip.y = this.layerToDragTo.y;

          if (this.audioDragArea === 'scene') {
            //if the dragged clip is in the scene, move it to the scene audio layers container and on top of everything
            this.sceneAudioLayersContainer.addChild(draggedClip);
          } else {
            //if the dragged clip is in the project, move it to the project audio layers container and on top of everything
            this.projectLayersContainer.addChild(draggedClip);
          }
        }

        this.updateClipPositionIndicator(...this.getClipPositionPlayheadData(draggedClip));
        if (draggedClip.x > this.allClipsRightBound) {
          if (this.rightBoundMainTimelineClip && this.rightBoundAudioTimelineClip) {
            this.rightBoundMainTimelineClip.x = draggedClip.x + draggedClip.width;
            this.rightBoundAudioTimelineClip.x = draggedClip.x + draggedClip.width;
          }
        }
      }
    }

    this.scrollToDraggedClip();
  };
  scrollToDraggedClip() {
    this.audioScrollboxCenterTarget = this.wrapperRef.interactionManager.mouse.global;
  }

  clearScrollToDraggedClip() {
    this.audioScrollboxCenterTarget = undefined;
  }

  getClipPositionPlayheadData(displayObject: PIXI.DisplayObject): [number, string] {
    const indicatorPosition = displayObject.getGlobalPosition().x;
    const indicatorLabel = displayObject.x / this.wrapperRef.currentZoom.x;
    return [indicatorPosition, TimelineHelpers.formatTime(indicatorLabel)];
  }

  adjustClip(audioClipsInLayer: TimelineAudioClip[]) {
    let adjusted = false;

    const startOfScene = this.currentSceneStartTime * this.wrapperRef.currentZoom.x;

    if (this.currentDragged) {
      const dragGraphic = this.currentDragged.getDragGraphic();
      //we check twice as the first adjustment might have caused the dragged clip to overlap with another clip
      for (let i = 0; i < 2; i++) {
        adjusted = false;
        for (const audio of audioClipsInLayer) {
          const audioBounds = audio.background.getBounds();
          // if the dragged clip is over another clip, move it to the other clip's position
          if (adjusted === false) {
            if (audio.audioClip.id !== this.currentDragged.audioClip.id) {
              if (dragGraphic.x + dragGraphic.width > audio.x && dragGraphic.x < audio.x + audio.background.width) {
                if (
                  dragGraphic.x < audio.x &&
                  dragGraphic.x + dragGraphic.width > audio.x &&
                  this.wrapperRef.interactionManager.mouse.global.x < audioBounds.left
                ) {
                  dragGraphic.x = audio.x - dragGraphic.width;
                }
                if (
                  dragGraphic.x + dragGraphic.width > audio.x + audio.background.width &&
                  this.wrapperRef.interactionManager.mouse.global.x > audioBounds.right
                ) {
                  dragGraphic.x = audio.x + audio.background.width;
                }
                adjusted = true;
              }
            }
          }
        }
        if (adjusted === false && i === 1) {
          if (this.audioDragArea === 'scene') {
            // move the dragged clip to the mouse position
            if (dragGraphic.x < startOfScene) {
              dragGraphic.x = startOfScene;
              adjusted = true;
            }
          }
        } else {
          if (dragGraphic.x < 0) {
            dragGraphic.x = 0;
            adjusted = true;
          }
        }
      }
    }
    return adjusted;
  }

  canDropClipToLayer(layer: TimelineAdvancedAudioLayer) {
    if (!this.currentDragged) return false;
    const draggedGraphic = this.currentDragged.getDragGraphic();
    const draggedBounds = draggedGraphic.getBounds().clone();
    draggedBounds.x += 1;
    draggedBounds.width -= 2;
    for (const audio of layer.timelineAudioClips) {
      const testBounds = audio.background.getBounds();
      draggedBounds.y = testBounds.y;
      if (audio !== this.currentDragged && draggedBounds.intersects(testBounds)) {
        return false;
      }
    }

    return true;
  }
}
