import * as PIXI from 'pixi.js';

import { LEFT_ZOOM_MARGIN } from '../PixiTimeline/consts';
import MultiDragIndicator from '../PixiTimeline/MultiDragIndicator';
import timelineBus, { DRAG_STOPPED, ELEMENT_MOVED, WRAPPER_SWAP_LAYERS } from '../PixiTimeline/TimelineControlBus';
import TimelineWrapper, { AllTimelineLayerTypes } from '../PixiTimeline/TimelineWrapper';
import Maths from '../PixiTimeline/Utils/Maths';

import SceneTransitionLayer from './SceneTransitionLayer';
import TimelineElementLayer from './TimelineElementLayer';
import TimelineCameraLayer from './TimelineCameraLayer';

export default class LayerDragManager {
  public currentlyDragged?: AllTimelineLayerTypes;
  private wrapperRef: TimelineWrapper;
  interactionManager: PIXI.InteractionManager;
  private layerMovedFrom = 0;
  private dragStartPos?: PIXI.Point;
  private hasDragged = false;
  private holdOffset = 50;
  private yTarget = 0;
  multiDragIndicator = new MultiDragIndicator();
  private lastXYDragIdPos?: PIXI.Point;
  private dragDirection = '';
  private xTarget = 0;

  constructor(wrapperRef: TimelineWrapper) {
    this.wrapperRef = wrapperRef;
    this.interactionManager = this.wrapperRef.app.renderer.plugins.interaction;
  }

  public update(_deltaTimeMultiplier: number) {
    this.layerDrag(_deltaTimeMultiplier);
    this.checkLayerSwap();
  }

  public dragMoveEnded = () => {
    this.wrapperRef.app.stage.removeChild(this.wrapperRef.multiDragIndicatorLayer);
    if (this.currentlyDragged) {
      this.currentlyDragged.foregroundGraphics.x = 0;
      this.hasDragged = false;
      timelineBus.emit(DRAG_STOPPED);
      let currentlyDraggedIndex = this.wrapperRef.layers.indexOf(this.currentlyDragged);
      this.currentlyDragged.y =
        (this.wrapperRef.layers.length - 1 - currentlyDraggedIndex) * this.wrapperRef.currentZoom.y;
      if (this.wrapperRef.layers[0] instanceof SceneTransitionLayer) {
        this.layerMovedFrom--;
        currentlyDraggedIndex--;
      }
      if (this.layerMovedFrom !== currentlyDraggedIndex) {
        timelineBus.emit(
          ELEMENT_MOVED,
          this.layerMovedFrom,
          currentlyDraggedIndex,
          this.currentlyDragged.elementModel.id
        );
      }
    }
    this.wrapperRef.layers.forEach(layer => {
      layer.interactiveChildren = true;
      layer.interactive = true;
    });
    this.currentlyDragged = undefined;
  };

  private setupMultiDragIndicator(numberOfLayers: number) {
    this.wrapperRef.app.stage.addChildAt(
      this.wrapperRef.multiDragIndicatorLayer,
      this.wrapperRef.app.stage.children.length
    );
    this.multiDragIndicator.setLabel(numberOfLayers.toString());
    this.multiDragIndicator.x = 0;
    this.multiDragIndicator.y = 0;
    this.multiDragIndicator.visible = true;
    this.wrapperRef.multiDragIndicatorLayer.visible = true;
    this.wrapperRef.multiDragIndicatorLayer.addChild(this.multiDragIndicator);
    if (this.currentlyDragged) this.updateMultiDragIndicatorPosition(this.currentlyDragged);
  }

  private updateMultiDragIndicatorPosition(layer: AllTimelineLayerTypes) {
    const elementGlobalPosition = layer.toGlobal(this.wrapperRef.app.stage);
    const xPosition = Math.max(
      elementGlobalPosition.x + layer.getStartX() - this.multiDragIndicator.width / 2,
      LEFT_ZOOM_MARGIN
    );
    const yPosition = elementGlobalPosition.y - this.multiDragIndicator.height / 2;
    this.multiDragIndicator.position.set(xPosition, yPosition);
  }

  public layerMoveBegun(layer: AllTimelineLayerTypes, numberOfLayers: number) {
    this.currentlyDragged = layer;
    if (numberOfLayers > 1) {
      this.setupMultiDragIndicator(numberOfLayers);
    }

    this.dragStartPos = this.interactionManager.mouse.global.clone();
    this.dragStartPos.x = Math.max(this.holdOffset, this.dragStartPos.x);

    this.hasDragged = false;
    this.dragDirection = '';
    this.layerMovedFrom = this.wrapperRef.layers.indexOf(this.currentlyDragged);
    this.wrapperRef.layers.forEach(layer => {
      layer.interactiveChildren = false;
      if (layer !== this.currentlyDragged) {
        layer.interactive = false;
      }
    });
  }

  private layerDrag(deltaTimeMultiplyer: number) {
    if (!this.wrapperRef || !this.wrapperRef.timelineHolder || !this.wrapperRef.scrollBox || !this.dragStartPos) return;
    if (this.currentlyDragged && this.currentlyDragged.destroyed === false) {
      const localHeldPoint = new PIXI.Point(this.currentlyDragged.getStartX(), 0);

      const currentlyDraggedGlobalPos = this.currentlyDragged.toGlobal(localHeldPoint);

      const currentDragPos = this.interactionManager.mouse.global.clone();
      if (this.lastXYDragIdPos === undefined) {
        this.lastXYDragIdPos = currentDragPos.clone();
      }

      const dragTollerance = 10;
      if (Math.abs(this.lastXYDragIdPos.y - currentDragPos.y) > dragTollerance) {
        this.lastXYDragIdPos = currentDragPos.clone();
        this.dragDirection = 'y';
      }
      const contentCenterPosition = this.wrapperRef.scrollBox.content.center.clone();
      const verticalScrollMargin = this.wrapperRef.currentZoom.y;
      const horizontalScrollMargin = 30;

      const topBound = this.wrapperRef.scrollBox.y + verticalScrollMargin;
      const bottomBound = this.wrapperRef.scrollBox.y + this.wrapperRef.scrollBox.boxHeight - verticalScrollMargin;

      const leftBound = this.wrapperRef.scrollBox.x + 100;
      const rightBound = this.wrapperRef.scrollBox.x + this.wrapperRef.scrollBox.width - 100;

      this.yTarget = contentCenterPosition.y;
      const ySpeed = 60;
      if (this.dragDirection === 'y') {
        //dragging in y direction
        if (currentDragPos.y > bottomBound || currentDragPos.y < topBound) {
          if (currentDragPos.y < topBound) {
            this.yTarget = contentCenterPosition.y - ySpeed;
            this.xTarget = contentCenterPosition.x;
            this.hasDragged = true;
          }
          if (currentDragPos.y > bottomBound) {
            this.yTarget = contentCenterPosition.y + ySpeed;
            this.xTarget = contentCenterPosition.x;
            this.hasDragged = true;
          }
        }

        if (currentlyDraggedGlobalPos.x < leftBound) {
          this.xTarget = contentCenterPosition.x - horizontalScrollMargin;

          this.hasDragged = true;
        }

        // if the dragged element is dragged beyond the right bound
        if (currentlyDraggedGlobalPos.x > rightBound) {
          //set the target x position to the right
          this.xTarget = contentCenterPosition.x + horizontalScrollMargin;
          this.hasDragged = true;
        }

        this.moveGraphics(deltaTimeMultiplyer);
        if (this.hasDragged) {
          this.wrapperRef.scrollBox.content.moveCenter(
            new PIXI.Point(
              Maths.lerp(this.wrapperRef.scrollBox.content.center.x, this.xTarget, deltaTimeMultiplyer * 0.7),
              Maths.lerp(this.wrapperRef.scrollBox.content.center.y, this.yTarget, deltaTimeMultiplyer * 0.05)
            )
          );
          this.wrapperRef.scrollBox?.content.clamp({ direction: 'all', underflow: 'bottom-left' });
          this.wrapperRef.scrollBox.update();
        }
      }
    } else {
      this.currentlyDragged = undefined;
    }
  }

  private moveGraphics(deltaTimeMultiplyer: number, instant = false) {
    if (this.currentlyDragged && this.wrapperRef.scrollBox && this.wrapperRef.activeElements) {
      this.wrapperRef.activeElements.forEach(layer => {
        if (layer instanceof SceneTransitionLayer) return;
        if (!this.wrapperRef.elementLayersHolder || !this.currentlyDragged || !this.dragStartPos) return;
        const layerRelativeDragPos = this.wrapperRef.elementLayersHolder.toLocal(this.interactionManager.mouse.global);

        if (layer === this.currentlyDragged) {
          let targetX: number;
          targetX = layerRelativeDragPos.x - layer.getStartX();
          //clamp to scene end
          if (
            targetX + layer.getStartX() + layer.foregroundGraphics.width >
            (layer.sceneStartTime + layer.currentSceneLength) * this.wrapperRef.currentZoom.x
          ) {
            targetX =
              (layer.sceneStartTime + layer.currentSceneLength) * this.wrapperRef.currentZoom.x -
              layer.getStartX() -
              layer.foregroundGraphics.width;
          }
          //clamp to scene start
          if (targetX + layer.getStartX() < layer.sceneStartTime * this.wrapperRef.currentZoom.x) {
            targetX = layer.sceneStartTime * this.wrapperRef.currentZoom.x - layer.getStartX();
          }
          if (this.dragDirection === 'y') {
            layer.foregroundGraphics.x = layer.continuationGraphics.x = 0;
          } else {
            layer.foregroundGraphics.x = layer.continuationGraphics.x = instant
              ? targetX
              : Maths.lerp(layer.foregroundGraphics.x, targetX, 0.1 * deltaTimeMultiplyer);
          }
          this.updateMultiDragIndicatorPosition(layer);
        } else {
          layer.alpha = 0.2;
        }
      });
    }
  }

  private checkLayerSwap() {
    if (this.currentlyDragged && this.wrapperRef.elementLayersHolder && this.wrapperRef.activeElements) {
      const mousePos = this.wrapperRef.elementLayersHolder.toLocal(this.interactionManager.mouse.global);
      const position = new PIXI.Point(mousePos.x, mousePos.y);

      const layers = this.wrapperRef.activeElements.concat();
      const tempLayers: Array<TimelineElementLayer | TimelineCameraLayer | SceneTransitionLayer> = [];
      //remove all the SceneTransitionLayers from the layers array
      this.wrapperRef.layers.forEach(element => {
        if (layers.includes(element) === false && !(element instanceof SceneTransitionLayer)) {
          tempLayers.push(element);
        }
      });
      if (this.dragDirection === 'y') {
        const layer = this.currentlyDragged;
        const heldLayerIndex = this.wrapperRef.layers.indexOf(this.currentlyDragged);

        const nextLayer =
          heldLayerIndex < this.wrapperRef.layers.length - 1 ? this.wrapperRef.layers[heldLayerIndex + 1] : undefined;
        const prevLayer = heldLayerIndex > 1 ? this.wrapperRef.layers[heldLayerIndex - 1] : undefined;
        if (layer === prevLayer || layer === nextLayer) {
          return;
        }
        if (prevLayer && position.y > prevLayer.y) {
          timelineBus.emit(WRAPPER_SWAP_LAYERS, this.currentlyDragged, -1);
          this.moveGraphics(1, true);
        } else if (nextLayer && position.y < nextLayer.y + this.wrapperRef.currentZoom.y) {
          timelineBus.emit(WRAPPER_SWAP_LAYERS, this.currentlyDragged, 1);
          this.moveGraphics(1, true);
        }
      }
    }
  }
}
