import { canvasSizes, resolution } from 'js/config/defaults';
import { CanvasSize } from 'js/types';

export function getViewportData(canvasSize: CanvasSize = 'landscape') {
  const { width, height } = canvasSizes[canvasSize];
  return {
    width,
    height,
    resolution
  };
}
