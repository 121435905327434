import './SubTabs.css';
import React, { useState, useRef, useMemo } from 'react';
import classnames from 'classnames';
import useIsElementsSameWidth from 'js/shared/hooks/useIsElementsSameWidth';
import TabContentWrapper from 'js/editor/ImageDrawer/components/TabContentWrapper';

import Tab, { TabProps } from './Tab';

export type TabItem = Omit<TabProps, 'isActive' | 'onSelect'> & {
  images?: { id: string | number; src: string; isPremium?: boolean; canChangeColor?: boolean }[];
  isLoading?: boolean;
  onLoadMore: () => void;
  emptyState?: React.ReactNode;
  onClickImage?: (imageId: string | number) => void;
  onDragImage?: (e: React.DragEvent<HTMLButtonElement>, imageId: string | number) => void;
  onDeleteImage?: (imageId: string | number) => void;
  searchInput?: string;
  categoryId?: string | null;
};

type SubTabsProps = {
  tabs: TabItem[];
  defaultActiveTab?: TabItem['id'];
  onTabChange?: (tabId: string) => void;
};

const SubTabs: React.FC<SubTabsProps> = ({ tabs, defaultActiveTab, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0]?.id);
  const tabListRef = useRef<HTMLUListElement>(null);
  const tabContentRef = useRef<HTMLDivElement>(null);
  const isFirstTabActive = useMemo(() => activeTab === tabs[0]?.id, [activeTab, tabs]);
  const isLastTabActive = useMemo(() => activeTab === tabs[tabs.length - 1]?.id, [activeTab, tabs]);
  const isListSameWidth = useIsElementsSameWidth(tabListRef, tabContentRef, isFirstTabActive || isLastTabActive);

  const tabContentClasses = classnames('SubTabs__content', {
    'SubTabs__content--no-one-border-radius': isFirstTabActive || isListSameWidth
  });

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
    onTabChange?.(tabId);
  };

  if (!tabs.length) return null;

  return (
    <div className={`SubTabs`}>
      <ul className="SubTabs__list" role="tablist" ref={tabListRef}>
        {tabs.map(tab => (
          <Tab
            key={tab.id}
            id={`tab-${tab.id}`}
            title={`${tab.title}`}
            count={tab.count}
            icon={tab.icon}
            isActive={activeTab === tab.id}
            onSelect={() => handleTabChange(tab.id)}
          />
        ))}
      </ul>
      <div className={tabContentClasses} ref={tabContentRef}>
        {tabs.map(tab => (
          <TabContentWrapper
            visible={activeTab === tab.id}
            key={tab.id}
            images={tab.images}
            onLoadMore={tab.onLoadMore}
            isLoading={tab.isLoading}
            emptyState={tab.emptyState}
            onClickImage={tab.onClickImage}
            onDragImage={tab.onDragImage}
            onDeleteImage={tab.onDeleteImage}
            searchInput={tab.searchInput}
            categoryId={tab.categoryId}
          />
        ))}
      </div>
    </div>
  );
};

export default SubTabs;
