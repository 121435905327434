import { DEFAULT_TEXT_ELEMENT_COPY } from 'js/config/consts';
import { textCharacterAnimationTime } from 'js/shared/resources/scribedefaults';
import round from 'lodash.round';

export const updateElementsInScene = (newActiveScribe, elements) => {
  const allElements = newActiveScribe.elements;
  const activeScene = newActiveScribe.scenes.find(scene => scene.active === true);

  for (const element of elements) {
    const existingElementIndex = allElements.findIndex(el => el.id === element.id);
    const existingSceneElementIndex = activeScene.elementIds.findIndex(el => el === element.id);

    if (existingElementIndex === -1) {
      allElements.push(element);
    } else if (
      element.updateAnimationTime &&
      element.updateAnimationTime === true &&
      element.text !== DEFAULT_TEXT_ELEMENT_COPY &&
      element.text.trim().length
    ) {
      element.animationTime =
        element.animationTime !== undefined
          ? element.animationTime
          : round(element.text.trim().replace(/\s/g, '').length * textCharacterAnimationTime, 1);
      element.updateAnimationTime = false;
      allElements[existingElementIndex] = element;
    } else if (element.type === 'Text' && !element.text.trim().length) {
      allElements.splice(existingElementIndex, 1);
      activeScene.elementIds.splice(existingSceneElementIndex, 1);
    } else {
      allElements[existingElementIndex] = element;
    }
  }

  return newActiveScribe;
};
