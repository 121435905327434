import './QuickCarouselTag.css';

import classNames from 'classnames';
import React from 'react';

interface QuickCarouselTagProps {
  children: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
}

const QuickCarouselTag = ({ children, active = false, onClick }: QuickCarouselTagProps) => {
  const classes = classNames('QuickCarouselTag', {
    'QuickCarouselTag--selected': active
  });
  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};
export default QuickCarouselTag;
