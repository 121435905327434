import { call, CallEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import uniq from 'lodash.uniq';
import { DeleteMultipleElementsByIdAction, DELETE_MULTIPLE_ELEMENTS_BY_ID } from 'js/actionCreators/scribeActions';
import { isImageElement } from 'js/editor/EditorCanvas/helpers/canvasElementHelpers';
import { deleteGIFDataBulk, deleteSVGDataBulk, deleteSVGMaskPath } from 'js/shared/lib/LocalDatabase';
import { constructSVGAssetKey } from 'js/shared/lib/LocalDatabase/keys';
import { ExistingScribeModel } from 'js/types';
import {
  SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX,
  SetElementUseContentSvgViewboxAction
} from 'js/actionCreators/elementActions';

import { getProjectById, getScribeById } from './selectors';

export function* manageGifs(
  action: DeleteMultipleElementsByIdAction
): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined> {
  const { elements: elementIds, scribeId } = action;

  const project = yield select(getScribeById, scribeId);
  if (!project) return;

  const elementsBeingDeleted = project.elements.filter(el => elementIds.includes(el.id));
  const imageElementBeingDeleted = elementsBeingDeleted.filter(el => el.type === 'Image').filter(isImageElement);

  if (!imageElementBeingDeleted.length) return;

  const removingAssetIds = uniq(
    imageElementBeingDeleted
      .map(el => el.image?.assetId)
      .filter((id): id is number => typeof id === 'number')
      .map(id => id.toString())
  );

  const remainingProjectElements = project.elements.filter(el => !elementsBeingDeleted.includes(el));

  const assetIdsNoLongerInProject = removingAssetIds.filter(assetId => {
    const count = remainingProjectElements.reduce((count, el) => {
      if (el.type !== 'Image') return count;
      if (el.image?.assetId.toString() !== assetId) return count;
      return count + 1;
    }, 0);
    return count === 0;
  });

  if (!assetIdsNoLongerInProject.length) return;
  yield call(deleteGIFDataBulk, assetIdsNoLongerInProject);
}

export function* manageSVGData(
  action: DeleteMultipleElementsByIdAction
): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined> {
  const { elements: elementIds, scribeId } = action;

  const project = yield select(getScribeById, scribeId);
  if (!project) return;

  const elementsBeingDeleted = project.elements.filter(el => elementIds.includes(el.id));
  const imageElementBeingDeleted = elementsBeingDeleted.filter(el => el.type === 'Image').filter(isImageElement);

  if (!imageElementBeingDeleted.length) return;

  const baseAssetIds: Array<string> = [];
  const removingAssetKeys = uniq(
    imageElementBeingDeleted.map(el => {
      if (el.image?.assetId) {
        const key = constructSVGAssetKey({
          assetId: el.image.assetId,
          recolorSettings: el.image?.recolor,
          useContentSvgViewbox: !!el.useContentSvgViewbox
        });
        baseAssetIds.push(el.image.assetId.toString() + '-originalViewbox');
        baseAssetIds.push(el.image.assetId.toString() + '-contentViewbox');
        return key;
      }
      return undefined;
    })
  ).filter((key): key is string => typeof key === 'string');

  const remainingProjectElements = project.elements.filter(el => !elementsBeingDeleted.includes(el));

  const baseAssetsNoLongerInProject = baseAssetIds.filter(assetId => {
    const count = remainingProjectElements.reduce((count, el) => {
      if (el.type !== 'Image') return count;
      if (
        el.image?.assetId.toString() + '-originalViewbox' !== assetId ||
        el.image?.assetId.toString() + '-contentViewbox' !== assetId
      )
        return count;
      return count + 1;
    }, 0);
    return count === 0;
  });
  const assetKeysNoLongerInProject = removingAssetKeys.filter(assetKey => {
    const count = remainingProjectElements.reduce((count, el) => {
      if (el.type !== 'Image' || !el.image?.assetId) return count;
      const key = constructSVGAssetKey({
        assetId: el.image.assetId,
        recolorSettings: el.image?.recolor,
        useContentSvgViewbox: !!el.useContentSvgViewbox
      });
      if (key !== assetKey) return count;
      return count + 1;
    }, 0);
    return count === 0;
  });

  if (!assetKeysNoLongerInProject.length) return;
  yield call(deleteSVGDataBulk, uniq([...assetKeysNoLongerInProject, ...baseAssetsNoLongerInProject]));
}

function* manageSvgRevealPath(
  action: SetElementUseContentSvgViewboxAction
): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield select(getProjectById, action.scribeId);

  if (!project) return;

  const element = project.elements.find(el => el.id === action.elementId);

  if (!element || !isImageElement(element) || !element.image?.assetId) return;

  yield call(deleteSVGMaskPath, element.image.assetId);
}

export default function* localDatabaseSagas() {
  yield takeEvery(DELETE_MULTIPLE_ELEMENTS_BY_ID, manageGifs);
  yield takeEvery(DELETE_MULTIPLE_ELEMENTS_BY_ID, manageSVGData);
  yield takeEvery(SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX, manageSvgRevealPath);
}
