import React from 'react';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';
import { DEFAULT_ERASE_CURSOR_ID } from 'js/config/consts';
import ScribeElementModel from 'js/models/ScribeElementModel';
import ScribeModel from 'js/models/ScribeModel';
import { AnimationStageDocumentKey, CursorOption, ElementAnimationStage, ScribeCursorId } from 'js/types';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import getAnimationStageDocumentKey from 'js/shared/helpers/getAnimationStageDocumentKey';
import IconNoAnimation from 'js/shared/icons/IconNoAnimation';

import defaultHand from '../../../imgs/hands/default-erase-1.png';

import ChoiceButtonContents from './ChoiceButtonContents';

interface CursorChoiceButtonProps {
  scribe: ScribeModel;
  element: ScribeElementModel;
  elements: Array<ScribeElementModel>;
  stageKey: ElementAnimationStage;
}

const defaultCursor: CursorOption = {
  group: 'Rosie',
  name: 'Default',
  id: DEFAULT_ERASE_CURSOR_ID,
  resources: { thumbnail: { url: defaultHand } }
};

const getElementAnimationConfigCursorId = (
  element: ScribeElementModel,
  stageDocumentKey: AnimationStageDocumentKey
): ScribeCursorId | undefined => {
  return element[stageDocumentKey]?.config?.cursorId;
};

const ChoiceButtonIcon = ({ imgUrl, isPen }: { imgUrl: string; isPen: boolean }) => {
  return (
    <div
      style={{
        height: '100%',
        backgroundImage: `url(${imgUrl})`,
        backgroundPosition: isPen ? '50% 50%' : '50% 0%',
        backgroundSize: isPen ? 'contain' : '100% auto',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '1 / 1'
      }}
    />
  );
};

const getCursorIcon = (cursor?: CursorOption) => {
  if (!cursor) {
    return <IconIndeterminate />;
  }

  if (cursor.id === -1 || !cursor.resources) {
    return <IconNoAnimation />;
  }

  return <ChoiceButtonIcon imgUrl={cursor.resources.thumbnail.url} isPen={cursor.group === 'pens'} />;
};

const AnimationCursorChoiceButton = ({ element, elements, stageKey }: CursorChoiceButtonProps) => {
  const stageDocumentKey = getAnimationStageDocumentKey(stageKey);
  let cursorId: ScribeCursorId | undefined;
  if (element) {
    cursorId = getElementAnimationConfigCursorId(element, stageDocumentKey);
  } else {
    const firstCursorId = getElementAnimationConfigCursorId(elements[0], stageDocumentKey);
    const allSame = elements.every(el => getElementAnimationConfigCursorId(el, stageDocumentKey) === firstCursorId);
    cursorId = allSame ? firstCursorId : undefined;
  }

  let cursor: CursorOption | undefined = useTypedSelector(({ cursors }) =>
    cursors.handErase.find(cur => cur.id === cursorId)
  );

  if (!cursor && cursorId === DEFAULT_ERASE_CURSOR_ID) {
    cursor = defaultCursor;
  } else if (!cursor && cursorId === -1) {
    cursor = {
      id: cursorId,
      group: 'none',
      name: 'No Hand'
    };
  }

  const optIcon = getCursorIcon(cursor);
  const optTitle = cursor ? cursor.name : 'Mixed';

  return (
    <ChoiceButtonContents label={optTitle} id={`${stageKey}-cursor-choice-contents`}>
      {optIcon}
    </ChoiceButtonContents>
  );
};

export default AnimationCursorChoiceButton;
