import { DownloadTrackingProperties } from 'js/types';

export const GET_VIDEO_DOWNLOAD_URL_SUCCESS = 'GET_VIDEO_DOWNLOAD_URL_SUCCESS';

type GetVideoDownloadUrlSuccessActionInput = {
  url: string;
  trackingProperties: DownloadTrackingProperties;
  format: string;
  scribeId: number;
};

export type GetVideoDownloadUrlSuccessAction = {
  type: typeof GET_VIDEO_DOWNLOAD_URL_SUCCESS;
  downloadUrl: string;
  trackingProperties: DownloadTrackingProperties;
  format: string;
  scribeId: number;
};

export const getVideoDownloadUrlSuccess = ({
  url,
  trackingProperties,
  format,
  scribeId
}: GetVideoDownloadUrlSuccessActionInput): GetVideoDownloadUrlSuccessAction => ({
  type: GET_VIDEO_DOWNLOAD_URL_SUCCESS,
  downloadUrl: url,
  trackingProperties,
  format,
  scribeId
});
