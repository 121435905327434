export default function getAudioFileDuration(file: Blob): Promise<number | undefined> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = evt => {
      if (!evt || !evt.target || !evt.target.result || typeof evt.target.result === 'string') {
        resolve(undefined);
        return;
      }

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContext.decodeAudioData(evt.target.result, buffer => {
        resolve(buffer.duration);
      });
    };
    reader.readAsArrayBuffer(file);
  });
}
