import * as PIXI from 'pixi.js';
import isEqual from 'lodash.isequal';

import timelineBus, { TOOLTIP_HIDE, TOOLTIP_SHOW } from './TimelineControlBus';
import { THUMBNAIL_WIDTH } from './consts';
import TimelineLayerThumbnail from './TimelineLayerThumbnail';

export const TIMELINE_TOOLTIP_BACKGROUND_COLOUR = 0x131515;
export const TIMELINE_TOOLTIP_BORDER_COLOUR = 0x444444;
export const TIMELINE_TOOLTIP_TEXT_COLOUR = 0xffffff;
export const TIMELINE_TOOLTIP_TEXT_SIZE = 11;
export const TIMELINE_TOOLTIP_TEXT_WEIGHT = '600';
export const TIMELINE_TOOLTIP_HEIGHT = 26;
export const TIMELINE_ARROW_WIDTH = 16;
export const TIMELINE_ARROW_HEIGHT = 5;
export const TOOLTIP_BOTTOM = 10;
export const ARROW_BOTTOM = TOOLTIP_BOTTOM + TIMELINE_ARROW_HEIGHT;
export const BORDER_RADIUS = 3;
export const TIMELINE_TOOLTIP_OFFSET = 7;

export const TOOLTIP_ALIGN_CENTRAL = 'TOOLTIP_ALIGN_CENTRAL';
export const TOOLTIP_ALIGN_LEFT = 'TOOLTIP_ALIGN_LEFT';
export const TOOLTIP_ALIGN_RIGHT = 'TOOLTIP_ALIGN_RIGHT';
export const TOOLTIP_SCENE_ALIGN = 'TOOLTIP_SCENE_ALIGN';
export const ELEMENT_ANIMATION_TOOLTIPS = 'ELEMENT_ANIMATION_TOOLTIPS';
export const TOOLTIP_ICON_ALIGN_LEFT = 'TOOLTIP_ICON_ALIGN_LEFT';
export const TOOLTIP_ICON_ALIGN_RIGHT = 'TOOLTIP_ICON_ALIGN_RIGHT';

export default class TimelineLayerTooltip extends PIXI.Sprite {
  graphics: PIXI.Graphics;
  timeLabelText: PIXI.Container;
  textPadding = 10;
  tooltip: PIXI.Container;
  alignment?: string;
  xMaxLimit: number;
  xMinLimit: number;
  textStyle: PIXI.TextStyle;

  tipText?: (string | TimelineLayerThumbnail)[];

  constructor(xMaxLimit: number, xMinLimit: number) {
    super();
    this.tooltip = new PIXI.Container();
    this.graphics = new PIXI.Graphics();
    this.tooltip.addChild(this.graphics);
    this.textStyle = new PIXI.TextStyle({
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--fontFamilyBody'),
      fontSize: TIMELINE_TOOLTIP_TEXT_SIZE,
      fill: TIMELINE_TOOLTIP_TEXT_COLOUR,
      fontWeight: TIMELINE_TOOLTIP_TEXT_WEIGHT,
      align: 'left'
    });
    this.timeLabelText = new PIXI.Container();
    this.timeLabelText.x = this.textPadding;
    this.timeLabelText.y = -this.textPadding;
    this.tooltip.addChild(this.timeLabelText);
    timelineBus.addListener(TOOLTIP_HIDE, this.hide);
    timelineBus.addListener(TOOLTIP_SHOW, this.show);
    this.xMaxLimit = xMaxLimit;
    this.xMinLimit = xMinLimit;
    this.addChild(this.tooltip);
    this.tooltip.interactive = false;
    this.tooltip.interactiveChildren = false;
    this.hide();
  }
  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    timelineBus.removeListener(TOOLTIP_HIDE, this.hide);
    timelineBus.removeListener(TOOLTIP_SHOW, this.show);
    this.tooltip.destroy();
    this.graphics.destroy();
    this.timeLabelText.destroy();

    super.destroy(options);
  }

  private hide = () => {
    this.visible = false;
  };

  public show = (
    event: PIXI.InteractionEvent,
    tipText: (string | TimelineLayerThumbnail)[],
    alignment: string,
    layerheight: number,
    handle?: PIXI.Container
  ) => {
    const target = handle ? handle : (event.target as PIXI.Container);
    if (!(isEqual(this.tipText, tipText) && alignment === this.alignment)) {
      this.tipText = tipText;
      this.drawText(tipText);

      if (alignment === ELEMENT_ANIMATION_TOOLTIPS) {
        const tooltipOverlapsRightPanel = target.getGlobalPosition().x + this.tooltip.width / 2 > this.xMaxLimit;
        const tooltipOverlapsLeftPanel = target.getGlobalPosition().x - this.tooltip.width / 2 < this.xMinLimit;
        if (tooltipOverlapsRightPanel) {
          this.alignment = TOOLTIP_ICON_ALIGN_LEFT;
        } else if (tooltipOverlapsLeftPanel) {
          this.alignment = TOOLTIP_ICON_ALIGN_RIGHT;
        } else {
          this.alignment = TOOLTIP_ALIGN_CENTRAL;
        }
      } else {
        this.alignment = alignment;
      }

      this.getBackgroundGraphics();

      switch (this.alignment) {
        case TOOLTIP_ALIGN_LEFT:
          this.tooltip.x = TIMELINE_ARROW_WIDTH / 2 - this.graphics.width;
          break;
        case TOOLTIP_ALIGN_RIGHT:
          this.tooltip.x = THUMBNAIL_WIDTH / 2 - TIMELINE_TOOLTIP_OFFSET;
          break;
        case TOOLTIP_ALIGN_CENTRAL:
          this.tooltip.x = -this.graphics.width / 2;
          break;
        case TOOLTIP_SCENE_ALIGN:
          this.tooltip.x = 0 + TIMELINE_TOOLTIP_OFFSET;
          break;
        case TOOLTIP_ICON_ALIGN_LEFT:
          if (!(target as PIXI.Container) || !(target as PIXI.Container).width) return;
          this.tooltip.x = TIMELINE_ARROW_WIDTH / 2 - this.graphics.width + (target as PIXI.Container).width / 2;
          break;
        case TOOLTIP_ICON_ALIGN_RIGHT:
          if (!(target as PIXI.Container) || !(target as PIXI.Container).width) return;
          this.tooltip.x = TIMELINE_ARROW_WIDTH / 2 - (target as PIXI.Container).width / 2;
          break;
      }
    }

    this.x = target.getGlobalPosition().x + target.width / 2;
    this.y = target.getGlobalPosition().y - layerheight / 2 + 5;
    this.interactive = false;
    this.interactiveChildren = false;
    this.visible = true;
  };

  private getBackgroundGraphics(): void {
    this.graphics.clear();
    this.graphics.beginFill(TIMELINE_TOOLTIP_BACKGROUND_COLOUR, 0.9);
    this.graphics.lineStyle(1, TIMELINE_TOOLTIP_BORDER_COLOUR);
    const textWidthWithPadding = this.timeLabelText.width + this.textPadding * 2;
    const TOOLTIP_TOP = TOOLTIP_BOTTOM - TIMELINE_TOOLTIP_HEIGHT;

    switch (this.alignment) {
      case TOOLTIP_ALIGN_CENTRAL:
        this.graphics.moveTo(textWidthWithPadding / 2, ARROW_BOTTOM);
        this.graphics.lineTo(textWidthWithPadding / 2 - TIMELINE_ARROW_WIDTH / 2, TOOLTIP_BOTTOM);
        this.graphics.lineTo(BORDER_RADIUS, TOOLTIP_BOTTOM);
        this.graphics.quadraticCurveTo(0, TOOLTIP_BOTTOM, 0, TOOLTIP_BOTTOM - BORDER_RADIUS);
        this.graphics.lineTo(0, TOOLTIP_TOP + BORDER_RADIUS);
        this.graphics.quadraticCurveTo(0, TOOLTIP_TOP, BORDER_RADIUS, TOOLTIP_TOP);
        this.graphics.lineTo(textWidthWithPadding - BORDER_RADIUS, TOOLTIP_TOP);
        this.graphics.quadraticCurveTo(
          textWidthWithPadding,
          TOOLTIP_TOP,
          textWidthWithPadding,
          TOOLTIP_TOP + BORDER_RADIUS
        );
        this.graphics.lineTo(textWidthWithPadding, TOOLTIP_BOTTOM - BORDER_RADIUS);
        this.graphics.quadraticCurveTo(
          textWidthWithPadding,
          TOOLTIP_BOTTOM,
          textWidthWithPadding - BORDER_RADIUS,
          TOOLTIP_BOTTOM
        );
        this.graphics.lineTo(textWidthWithPadding / 2 + TIMELINE_ARROW_WIDTH / 2, TOOLTIP_BOTTOM);
        this.graphics.lineTo(textWidthWithPadding / 2, ARROW_BOTTOM);
        break;
      case TOOLTIP_ALIGN_LEFT:
      case TOOLTIP_ICON_ALIGN_LEFT:
        this.graphics.moveTo(textWidthWithPadding - TIMELINE_ARROW_WIDTH / 2, ARROW_BOTTOM);
        this.graphics.lineTo(textWidthWithPadding - TIMELINE_ARROW_WIDTH, TOOLTIP_BOTTOM);
        this.graphics.lineTo(BORDER_RADIUS, TOOLTIP_BOTTOM);
        this.graphics.quadraticCurveTo(0, TOOLTIP_BOTTOM, 0, TOOLTIP_BOTTOM - BORDER_RADIUS);
        this.graphics.lineTo(0, TOOLTIP_TOP + BORDER_RADIUS);
        this.graphics.quadraticCurveTo(0, TOOLTIP_TOP, BORDER_RADIUS, TOOLTIP_TOP);
        this.graphics.lineTo(textWidthWithPadding - BORDER_RADIUS, TOOLTIP_TOP);
        this.graphics.quadraticCurveTo(
          textWidthWithPadding,
          TOOLTIP_TOP,
          textWidthWithPadding,
          TOOLTIP_TOP + BORDER_RADIUS
        );
        this.graphics.lineTo(textWidthWithPadding, TOOLTIP_BOTTOM);
        this.graphics.lineTo(textWidthWithPadding - TIMELINE_ARROW_WIDTH / 2, ARROW_BOTTOM);
        break;
      case TOOLTIP_ALIGN_RIGHT:
      case TOOLTIP_SCENE_ALIGN:
      case TOOLTIP_ICON_ALIGN_RIGHT:
        this.graphics.moveTo(TIMELINE_ARROW_WIDTH / 2, ARROW_BOTTOM);
        this.graphics.lineTo(0, TOOLTIP_BOTTOM);
        this.graphics.lineTo(0, TOOLTIP_TOP + BORDER_RADIUS);
        this.graphics.quadraticCurveTo(0, TOOLTIP_TOP, BORDER_RADIUS, TOOLTIP_TOP);
        this.graphics.lineTo(textWidthWithPadding - BORDER_RADIUS, TOOLTIP_TOP);
        this.graphics.quadraticCurveTo(
          textWidthWithPadding,
          TOOLTIP_TOP,
          textWidthWithPadding,
          TOOLTIP_TOP + BORDER_RADIUS
        );
        this.graphics.lineTo(textWidthWithPadding, TOOLTIP_BOTTOM - BORDER_RADIUS);
        this.graphics.quadraticCurveTo(
          textWidthWithPadding,
          TOOLTIP_BOTTOM,
          textWidthWithPadding - BORDER_RADIUS,
          TOOLTIP_BOTTOM
        );
        this.graphics.lineTo(TIMELINE_ARROW_WIDTH, TOOLTIP_BOTTOM);
        this.graphics.lineTo(TIMELINE_ARROW_WIDTH / 2, ARROW_BOTTOM);
        break;
    }
  }

  public drawText(text: (string | TimelineLayerThumbnail | PIXI.Container)[]): void {
    this.timeLabelText.removeChildren();
    let currentX = 0;
    for (let i = 0; i < text.length; i++) {
      const element = text[i];
      if (element instanceof TimelineLayerThumbnail) {
        element.x = currentX;
        const desiredHeight = TIMELINE_TOOLTIP_HEIGHT - this.textPadding;
        const scaleFactor = desiredHeight / element.background.height;
        element.scale.x = element.scale.y = scaleFactor;
        element.y = -this.textPadding / 4;
        this.timeLabelText.addChild(element);
        currentX += element.background.width * element.scale.x + this.textPadding;
      } else if (element instanceof PIXI.Container) {
        element.x = currentX;
        element.height = TIMELINE_TOOLTIP_HEIGHT - this.textPadding;
        element.scale.x = element.scale.y;
        element.y = -this.textPadding / 4;
        this.timeLabelText.addChild(element);
        currentX += element.width + this.textPadding;
      } else {
        const textElement = new PIXI.Text(element, this.textStyle);
        textElement.x = currentX;
        this.timeLabelText.addChild(textElement);
        currentX += textElement.width + this.textPadding;
      }

      if (i < text.length - 1) {
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(1, TIMELINE_TOOLTIP_BORDER_COLOUR);
        graphics.moveTo(currentX, 0);
        graphics.lineTo(currentX, TIMELINE_TOOLTIP_HEIGHT / 2);
        currentX += this.textPadding;
        this.timeLabelText.addChild(graphics);
      }
    }
    this.getBackgroundGraphics();
  }

  public updateMaxPosition(newXMaxLimit: number, newXMinLimit: number) {
    this.xMaxLimit = newXMaxLimit;
    this.xMinLimit = newXMinLimit;
  }
}
