import { ExistingScribeModel, PlaybackScribeModel } from 'js/types';

import getCursorResourceGroupsFromResourcesAndMetadata from '../getCursorResourceGroupsFromResourcesAndMetadata';

export default function getScribeCursorUrls(scribe: PlaybackScribeModel | ExistingScribeModel): Array<string> {
  if (isPlaybackScribeModel(scribe)) {
    const cursorResourceGroups = getCursorResourceGroupsFromResourcesAndMetadata(
      scribe.cursorData.resourcesAndMetadata
    );
    const cursorUrls = cursorResourceGroups.flatMap(([, cursorData]) => cursorData.resources);

    return cursorUrls;
  }
  return [];
}
function isPlaybackScribeModel(pet: PlaybackScribeModel | ExistingScribeModel): pet is PlaybackScribeModel {
  return (pet as PlaybackScribeModel).cursorData !== undefined;
}
