import { FILE_CONTENT_TYPES } from './consts';

const config = {
  ENVIRONMENT: import.meta.env.VITE_ENV || 'testing',
  OPTIMIZELY_SDK_KEY: import.meta.env.VITE_OPTIMIZELY_SDK_KEY || '49ZkmCj1we5AX8GuThQGpJ',
  OPTIMIZELY_LOG_LEVEL: import.meta.env.VITE_OPTIMIZELY_LOG_LEVEL,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
  SENTRY_ALLOW_URLS: import.meta.env.VITE_SENTRY_ALLOW_URLS?.split(',') || [],
  LIBRARY_API_DOMAIN: import.meta.env.VITE_LIBRARY_API_DOMAIN || '', // allow override for testing
  APPLICATION_SERVICES_URL: import.meta.env.VITE_APPLICATION_SERVICES_URL || 'https://services-test.sparkol-dev.co.uk',
  APPLICATION_NAME: import.meta.env.VITE_APPLICATION_NAME || 'videoscribe-cloud',
  MANAGE_ACCOUNT_URL:
    import.meta.env.VITE_MANAGE_ACCOUNT_URL || 'https://videoscribecloud.dev.sparkol-dev.co.uk/account',
  CONTENTFUL_SPACE_ID: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
  CONTENTFUL_ENVIRONMENT: import.meta.env.VITE_CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_CDN_TOKEN: import.meta.env.VITE_CONTENTFUL_CDN_TOKEN,
  CONTENTFUL_OAUTH_URL: 'https://be.contentful.com/oauth/authorize',
  CONTENTFUL_OAUTH_CLIENT_ID: import.meta.env.VITE_CONTENTFUL_OAUTH_CLIENT_ID,
  MIXPANEL_API_TOKEN: import.meta.env.VITE_MIXPANEL_API_TOKEN,
  DEBUG_MIXPANEL: import.meta.env.VITE_DEBUG_MIXPANEL,
  THUMB_SIZE: '140',
  SERVICES_REQUEST_RATE_LIMIT: 6,
  SERVICES_REQUEST_CONCURRENCY: 2,
  MAX_AUDIO_RECORD_TIME_MS: import.meta.env.VITE_MAX_AUDIO_RECORD_TIME_MS || 20 * 60 * 1000,
  PRICING_URL: import.meta.env.VITE_PRICING_URL,
  FAQ_URL: import.meta.env.VITE_FAQ_URL,
  COGNITO_CLIENT_ID: import.meta.env.VITE_COGNITO_CLIENT_ID_VSC,
  COGNITO_USER_POOL: import.meta.env.VITE_COGNITO_USER_POOL,
  AWS_REGION: 'us-east-1',
  AWIN_ADVERTISER_ID: import.meta.env.VITE_AWIN_ADVERTISER_ID || '',
  BITMAP_FONT_WORKER_CDN: import.meta.env.VITE_BITMAP_FONT_WORKER_CDN,
  VOICEOVER_ASSETS_CDN: import.meta.env.VITE_BITMAP_FONT_WORKER_CDN
};

export default config;

export const FREE_DOWNLOAD_LIMIT = 3;
export const GRID_SIZE = 30;
export const CROPPABLE_IMAGE_TYPES = [
  FILE_CONTENT_TYPES.PNG,
  FILE_CONTENT_TYPES.JPG,
  FILE_CONTENT_TYPES.BMP,
  FILE_CONTENT_TYPES.SVG
];

export const CROP_SHAPE_TYPES = {
  RECTANGLE: 'RECTANGLE',
  SQUARE: 'SQUARE',
  CIRCLE: 'CIRCLE',
  ELLIPSE: 'ELLIPSE'
};

export const APPLICATION_DISPLAY_NAME = 'VideoScribe';
export const LOGIN_URL = import.meta.env.VITE_LOGIN_URL || 'https://www.videoscribe.co/en/login/';
export const RAISE_A_TICKET_URL = 'https://support.sparkol.com/knowledge/kb-tickets/new';
export const CONTENTFUL_LOCALE = 'en-US';
export const FREE_TRIAL_URL = import.meta.env.VITE_FREE_TRIAL_URL || 'https://www.videoscribe.co/en/free-trial/';

export const PREVIEW_SHARING_UTM_NAV =
  '?utm_campaign=Preview%20Sharing%20Link&utm_source=VSC%20User&utm_medium=Nav%20Reg';

export const PREVIEW_SHARING_UTM_CTA =
  '?utm_campaign=Preview%20Sharing%20Link&utm_source=VSC%20User&utm_medium=Try%20For%20Free%20CTA';

export const ALLOWED_AUDIO_MIME_TYPE = 'audio/mpeg';
export const MAX_AUDIO_TRACKS_IN_PROJECT = 2;
export const PIN_ALL_CAMERA_BY_DEFAULT = true;
export const SUPPORTED_AUDIO_LOOPING = false;

export const LEGAL_PAGE_URL = 'https://www.sparkol.com/legal/';

export const AI_FEATURES = {
  IMAGE: {
    GENERATION_QUANTITY: 2,
    MIN_PROMPT_LENGTH: 1,
    MAX_PROMPT_LENGTH: 500,
    PROMPT_ROWS: 5,
    PROMPT_PLACEHOLDER:
      'A business woman with shoulder-length hair, gazing intently at a smartphone in her hand. Her expression is serious, conveying concentration and professionalism. She is wearing a smart, fitted blazer.',
    IMAGE_GEN_LIMIT: parseInt(import.meta.env.VITE_BETA_IMAGE_GENERATION_LIMIT) || 250
  },
  VOICE: {
    MIN_PROMPT_LENGTH: 1,
    MAX_PROMPT_LENGTH: 4000,
    PROMPT_ROWS: 8,
    PROMPT_PLACEHOLDER: 'Enter the text you want to convert to speech',
    PROMPT_INVALID_MESSAGE: '*Please complete this required field',
    LANGUAGE_PLACEHOLDER: 'Language and accent',
    VOICE_GEN_SECONDS_LIMIT: parseInt(import.meta.env.VITE_BETA_VOICE_GENERATION_SECONDS_LIMIT) || 18000,
    MAX_FEEDBACK_LENGTH: 255,
    FEEDBACK_ROWS: 4
  },
  SCRIPT: {
    MIN_PROMPT_LENGTH: 1,
    MAX_PROMPT_LENGTH: 2000,
    PROMPT_ROWS: 5,
    PROMPT_PLACEHOLDER: 'Describe what you want your script to be about in as much detail as possible.',
    MAX_FEEDBACK_LENGTH: 255,
    FEEDBACK_ROWS: 4,
    MIN_SCENES: 1,
    MAX_SCENES: 25,
    DEFAULT_NUMBER_OF_SCENES: 1,
    STYLE_OPTIONS: [
      'Instructional',
      'Professional',
      'Inspirational',
      'Storytelling',
      'Humorous',
      'Motivational',
      'Explainer',
      'Friendly',
      'Upbeat',
      'Academic'
    ],
    MIN_SCRIPT_LENGTH_MINUTES: 0,
    MAX_SCRIPT_LENGTH_MINUTES: 14,
    MIN_SCRIPT_LENGTH_SECONDS: 0,
    MAX_SCRIPT_LENGTH_SECONDS: 59,
    DEFAULT_SCRIPT_LENGTH_MINUTES: 3,
    DEFAULT_SCRIPT_LENGTH_SECONDS: 0,
    MIN_REQUIRED_LENGTH_SECONDS: 5,
    SCRIPT_GEN_SECONDS_LIMIT: parseInt(import.meta.env.VITE_BETA_SCRIPT_GENERATION_SECONDS_LIMIT) || 18000
  },
  FEEDBACK: {
    MAX_FEEDBACK_LENGTH: 255,
    FEEDBACK_ROWS: 4
  }
};

export const IMAGE_LIBRARY_RESULT_NUMBER_PER_PAGE = 30;
