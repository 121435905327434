// Encodes SVG element into Base64 image so it can be used as a texture in PIXI
import DOMPurify from 'dompurify';

/**
 * Returns a quick hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 */
function hashCode(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const URL_CACHE = {};

const svgToDataUrl = svg => {
  svg.setAttribute('preserveAspectRatio', 'none');
  const serialiser = new XMLSerializer();
  const dirtySting = serialiser.serializeToString(svg);
  const identifier = hashCode(dirtySting);

  if (URL_CACHE[identifier]) return URL_CACHE[identifier];

  const svgString = DOMPurify.sanitize(dirtySting);
  const output = encodeSvgFromString(svgString);
  URL_CACHE[identifier] = output;
  return output;
};

export const encodeSvgFromString = svgString => {
  return `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svgString)))}`;
};

export default svgToDataUrl;
