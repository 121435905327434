import React from 'react';

export default function IconAnimationSlingshot() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="59.4px"
      height="60px"
      viewBox="0 0 59.4 60"
      enableBackground="new 0 0 59.4 60"
      stroke="currentcolor"
      fill="currentcolor"
    >
      <path
        d="M49.3,30.8c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6c4.2,0,7.6,3.4,7.6,7.6S53.5,30.8,49.3,30.8z M49.3,18.6
	c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6S51.9,18.6,49.3,18.6z"
      />
      <path
        d="M29.5,17.6l-1.9-2.3c-7.8,5.1-15.3,5.1-23.1,0l-1.9,2.3l11.9,12.9v15h3v-15L29.5,17.6z M22,21.3l-6,6.5l-6-6.5
	C14,22.5,18,22.5,22,21.3z"
      />
      <rect x="31.2" y="19.3" width="7.8" height="3" />
      <rect x="25.4" y="24.8" width="13.5" height="3" />
    </svg>
  );
}
