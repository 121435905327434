import { SCOPE, ACCOUNT_TYPES } from 'js/config/consts';
import { CurrentUser, AccountContentKey } from 'js/types';
import mapAccountTypeContentKeyToContent from 'js/shared/content/accountTypeContent';

import { PERMISSIONS } from './permissions';

export type UserWithPermissions = CurrentUser & UserPermissionHelperFunctions;

export type UserPermissionHelperFunctions = {
  can: (scopes: SCOPE[]) => boolean;
  hasAccountTypes: (accountTypes: AccountTypes | AccountTypes[]) => boolean;
  getContent: (contentKey: AccountContentKey) => string;
};

type AccountTypes = keyof typeof ACCOUNT_TYPES;
type HasAccountTypes = (accountTypes: AccountTypes | AccountTypes[], user: CurrentUser) => boolean;

const userPermissionLogic = (scopes: SCOPE[], user: CurrentUser) => {
  const role = user.accountType;
  return scopes.every(scope => PERMISSIONS[role].includes(scope));
};

const hasAccountTypesLogic: HasAccountTypes = (accountTypes, user) => {
  const accountTypesArray = Array.isArray(accountTypes) ? accountTypes : [accountTypes];
  return accountTypesArray.includes(user.accountType);
};

export const getUserPermissionHelperFunctions = (user: CurrentUser): UserPermissionHelperFunctions => ({
  can: (scopes: SCOPE[]) => userPermissionLogic(scopes, user),
  hasAccountTypes: (accountTypes: AccountTypes | AccountTypes[]) => hasAccountTypesLogic(accountTypes, user),
  getContent: (contentKey: AccountContentKey) => mapAccountTypeContentKeyToContent(user.accountType, contentKey)
});
