import * as PIXI from 'pixi.js';
export default class Maths {
  public static lerp(current: number, target: number, amount: number): number {
    return current + Math.min((target - current) * amount);
  }

  public static lerpPoint(
    current: { x: number; y: number },
    target: { x: number; y: number },
    amount: number
  ): { x: number; y: number } {
    return {
      x: Maths.lerp(current.x, target.x, amount),
      y: Maths.lerp(current.y, target.y, amount)
    };
  }

  public static asPIXIPoint({ x, y }: { x: number; y: number }): PIXI.Point {
    return new PIXI.Point(x, y);
  }

  public static roundToDecimalPlace(value: number | undefined, accuracy: number) {
    if (value === undefined) return 0;
    return Math.round(value * accuracy) / accuracy;
  }
  public static clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(value, max));
  }
  public static rangeValue = (minVal: number, maxVal: number, currVal: number) => {
    const min = currVal - minVal;
    const max = maxVal - minVal;

    return min / max;
  };
}
