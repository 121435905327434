import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationLeft({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path d="M 16.9,30.7 30.6,44.5 32.8,42.3 21.7,31.2 H 43.3 V 28 H 21.8 L 32.9,17 30.7,14.8 16.9,28.6 c -0.3,0.3 -0.5,0.7 -0.5,1.1 0,0.4 0.2,0.7 0.5,1 z" />
    </svg>
  );
}
