import * as PIXI from 'pixi.js';

const documentStyles = window.getComputedStyle(document.documentElement);

export const THUMBNAIL_WIDTH = 40;
export const VERTICAL_SCROLLBAR_GUTTER = 26;

export const LEFT_ZOOM_MARGIN = 40;
export const TOP_ZOOM_MARGIN = 40;

export const ELEMENT_LAYER_PADDING = 2;

export const DRAG_DELAY_TIMEOUT = 100;
export const BUTTON_ICON_COLOR = 0xffffff;
export const BUTTON_HOVER_COLOR = PIXI.utils.string2hex(
  documentStyles.getPropertyValue('--colorAccentTeal').trim() || '#FFFFFF'
);

export const MAX_LANE_ZOOM = 60;
export const MIN_LANE_ZOOM = 20;
