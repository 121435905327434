import { Linear } from 'gsap';
import * as PIXI from 'pixi.js';

import TimelineDragHandle from './TimelineDragHandle';
import TimelineWrapper, { SCROLL_BOX_MARGIN } from './TimelineWrapper';
import timelineBus, { WRAPPER_SCROLL_TO, WRAPPER_UPDATE_ZOOM } from './TimelineControlBus';
import { Input } from './Utils/Input';
import TimelineElementLayer from './TimelineElementLayer';
import { MAX_LANE_ZOOM, MIN_LANE_ZOOM } from './consts';
import TimelineAdvancedAudioLayer from './TimelineAdvancedAudioLayer';

const MAX_ZOOM_IN_SECONDS = 120;
export const MIN_ZOOM_IN_SECONDS = 10;
export const MIN_USER_ZOOM_IN_SECONDS = 3;
const PADDING_FOR_BUTTONS = 32;
const MAX_DRAG_HANDLE_WIDTH = 160;
const BUTTON_WIDTH = 60;
const TIMELINE_TOGGLE_WIDTH = 50;

export default class ZoomManager {
  wrapperRef: TimelineWrapper;
  minZoom: PIXI.Point;
  maxZoom: PIXI.Point;
  zoomHandleX?: TimelineDragHandle;
  zoomHandleY?: TimelineDragHandle;
  interactionManager: PIXI.InteractionManager;
  mousePos: PIXI.Point;
  input?: Input;
  zoomExpandButton?: PIXI.Sprite;
  onZoomExpandedClick: () => void;

  centerElementY?: TimelineElementLayer;

  offsetY = 0;
  dragStartScrollPos?: PIXI.Point;
  dragStartZoomVal?: PIXI.Point;
  startScenePosition?: PIXI.Rectangle;

  constructor(wrapperRef: TimelineWrapper) {
    this.wrapperRef = wrapperRef;
    this.minZoom = new PIXI.Point();
    this.maxZoom = new PIXI.Point();
    this.updateMaxMinZoomX();
    this.mousePos = new PIXI.Point(0, 0);
    this.interactionManager = this.wrapperRef.app.renderer.plugins.interaction;
    this.onZoomExpandedClick = wrapperRef.props.onZoomExpandedClick;
  }

  public updateMaxMinZoomX() {
    if (this.wrapperRef.scrollBox) {
      const max = (this.wrapperRef.scrollBox.boxWidth - SCROLL_BOX_MARGIN * 2) / MIN_USER_ZOOM_IN_SECONDS;
      const min =
        (this.wrapperRef.scrollBox.boxWidth - SCROLL_BOX_MARGIN * 2) /
        Math.max(MIN_ZOOM_IN_SECONDS, MAX_ZOOM_IN_SECONDS);
      this.minZoom = new PIXI.Point(min, MIN_LANE_ZOOM);
      this.maxZoom = new PIXI.Point(max, MAX_LANE_ZOOM);
      this.zoomHandleX?.updateMaxMinVal(this.minZoom.x, this.maxZoom.x);
    }
  }

  addInput() {
    this.input = new Input(this.wrapperRef.timelineHolder);
  }

  public zoomOut() {
    if (!(this.zoomHandleX && this.zoomHandleY)) return;
    this.zoomHandleX.currentValue = 0;
    this.zoomHandleX.update(1, true);
    this.zoomHandleY.currentValue = 0;
    this.zoomHandleY.update(1, true);
  }

  mouseWheel = (event: WheelEvent): void => {
    let xDelta = event.deltaX;
    let yDelta = event.deltaY;
    if (event.shiftKey) {
      if (xDelta === 0) {
        xDelta = event.deltaY;
        yDelta = 0;
      }
    }
    if (event.altKey) {
      this.updateMouseWheel(xDelta, yDelta);
    } else {
      if (this.wrapperRef.scrollBox) {
        //check if the mouse is over scrollBox or audioScrollbox
        let targetScrollBox = this.wrapperRef.scrollBox;
        if (this.wrapperRef.getAudioScrollBoxEnabled() && this.wrapperRef.audioScrollBox) {
          const audioMousePos = this.wrapperRef.audioScrollBox.parent.toLocal(this.interactionManager.mouse.global);
          const box = this.wrapperRef.audioScrollBox;
          if (
            audioMousePos.x >= box.x &&
            audioMousePos.x <= box.x + box.boxWidth &&
            audioMousePos.y >= box.y &&
            audioMousePos.y <= box.y + box.boxHeight
          ) {
            targetScrollBox = this.wrapperRef.audioScrollBox;
          }
        }

        const center = targetScrollBox?.content.center.clone();
        if (center) {
          center.x += xDelta * 0.1;
          center.y += yDelta * 0.1;
          targetScrollBox?.content.moveCenter(center);
          targetScrollBox?.content.clamp({ direction: 'all', underflow: 'bottom-left' });
        }
        targetScrollBox.update();
      }
    }
  };

  zoomControlX = (xVal: number) => {
    this.updateZoom({ x: xVal });

    if (this.wrapperRef.activeElements?.length) {
      if (this.wrapperRef.scrollBox?.boxWidth) {
        const newX =
          this.wrapperRef.activeElements[0].startTime * this.wrapperRef.currentZoom.x -
          this.wrapperRef.scrollBox?.boxWidth / 2;
        if (!this.wrapperRef.audioScrollBoxEnabled) {
          //adjust position to match for any audio lanes
          this.wrapperRef.audioLayerHolder?.children.forEach(layer => {
            if (layer instanceof TimelineAdvancedAudioLayer && this.wrapperRef.scrollBox) {
              layer.x = newX;
            }
          });
        }
        this.wrapperRef.scrollToPoint(new PIXI.Point(newX, this.wrapperRef.activeElements[0].y), 'corner');
      }
    } else {
      if (this.wrapperRef.scrollBox && this.dragStartScrollPos && this.dragStartZoomVal) {
        const sceneGlobalPos = this.startScenePosition?.x ?? this.wrapperRef.scrollBox.boxWidth / 2;
        const xChange = this.dragStartZoomVal.x / xVal;
        const midScreen = sceneGlobalPos;

        const cornerPos = this.dragStartScrollPos.clone();
        cornerPos.x += midScreen;
        cornerPos.x = cornerPos.x / xChange;
        cornerPos.x -= midScreen;

        this.wrapperRef.scrollToPoint(cornerPos, 'corner');
      }
    }
  };

  zoomControlY = (yVal: number) => {
    this.updateZoom({ y: yVal });
    if (this.wrapperRef.activeElements?.length) {
      timelineBus.emit(WRAPPER_SCROLL_TO, {
        sceneElement: this.wrapperRef.activeElements[0],
        focus: 'y',
        offset: new PIXI.Point(0, this.offsetY)
      });
    } else {
      if (this.centerElementY) {
        timelineBus.emit(WRAPPER_SCROLL_TO, {
          sceneElement: this.centerElementY,
          focus: 'y',
          offset: new PIXI.Point(0, this.offsetY)
        });
      }
    }
  };

  setInitialZoom() {
    this.updateMaxMinZoomX();
    if (this.wrapperRef.currentZoom.x > this.maxZoom.x) this.updateZoom({ x: this.maxZoom.x });
    if (this.wrapperRef.currentZoom.x < this.minZoom.x) this.updateZoom({ x: this.minZoom.x });

    if (this.zoomHandleY && this.zoomHandleY.parent) {
      const scrollVal =
        (this.wrapperRef.currentZoom.y - this.zoomHandleY.minVal) / (this.zoomHandleY.maxVal - this.zoomHandleY.minVal);
      this.zoomHandleY.currentValue = scrollVal;
      this.zoomHandleY.updateDisplay();
    }
  }

  clamp(input: number, min: number, max: number): number {
    if (input < min) return min;
    if (input > max) return max;
    return input;
  }

  updateZoom({ x, y, forceUpdate = false }: { x?: number; y?: number; forceUpdate?: boolean }): void {
    if (!this.wrapperRef.scrollBox) return;
    this.updateMaxMinZoomX();

    const targetZoom = new PIXI.Point();
    targetZoom.x = x !== undefined ? this.clamp(x, this.minZoom.x, this.maxZoom.x) : this.wrapperRef.currentZoom.x;

    const minBarHeightY = this.minZoom.y;
    const maxBarHeightY = this.maxZoom.y;

    if (y === undefined) y = this.wrapperRef.currentZoom.y;

    const zoomPercentageY =
      maxBarHeightY - minBarHeightY === 0 ? 1 : (y - minBarHeightY) / (maxBarHeightY - minBarHeightY);

    const yZoom = zoomPercentageY * (maxBarHeightY - minBarHeightY) + minBarHeightY;
    targetZoom.y = this.clamp(yZoom, minBarHeightY, maxBarHeightY);

    if (
      targetZoom.x !== this.wrapperRef.currentZoom.x ||
      targetZoom.y !== this.wrapperRef.currentZoom.y ||
      x !== undefined
    ) {
      timelineBus.emit(
        WRAPPER_UPDATE_ZOOM,
        {
          x: targetZoom.x,
          y: targetZoom.y
        },
        forceUpdate
      );
    }
    this.updateZoomHandles();
  }

  updateZoomHandles() {
    if (this.zoomHandleY && this.zoomHandleY.parent) {
      const rangeValue = this.zoomHandleY.getRangeValue(this.wrapperRef.currentZoom.y);
      this.zoomHandleY.currentValue = rangeValue;
      this.zoomHandleY.updateDisplay();
    }
    if (this.zoomHandleX && this.zoomHandleX.parent) {
      const rangeValue = this.zoomHandleX.getRangeValue(this.wrapperRef.currentZoom.x);
      this.zoomHandleX.currentValue = rangeValue;
      this.zoomHandleX.updateDisplay();
    }
  }

  addZoomControl() {
    if (this.zoomHandleX) this.zoomHandleX.destroy();
    if (this.zoomHandleY) this.zoomHandleY.destroy();
    this.updateMaxMinZoomX();
    this.zoomHandleX = new TimelineDragHandle({
      minVal: this.minZoom.x,
      maxVal: this.maxZoom.x,
      size: MAX_DRAG_HANDLE_WIDTH,
      scaleFn: Linear.easeIn,
      currentZoomVal: this.wrapperRef.currentZoom.x
    });

    this.zoomHandleX.on('update', this.zoomControlX);
    this.zoomHandleX.on('handleStartDrag', this.zoomXStartDrag);
    this.zoomHandleX.on('handleStopDrag', this.zoomXStopDrag);
    if (this.wrapperRef.scrollBox) {
      this.zoomHandleY = new TimelineDragHandle({
        minVal: this.minZoom.y,
        maxVal: this.maxZoom.y,
        size: this.getYHandleSize(),
        scaleFn: Linear.easeIn,
        currentZoomVal: this.wrapperRef.currentZoom.y,
        angle: 270
      });

      this.zoomHandleY.on('update', this.zoomControlY);
      this.zoomHandleY.on('handleStartDrag', this.zoomYStartDrag);
      this.zoomHandleY.on('handleStopDrag', this.zoomYStopDrag);

      this.wrapperRef.timelineHolder?.addChild(this.zoomHandleX);
      this.wrapperRef.timelineHolder?.addChild(this.zoomHandleY);
    }
  }

  zoomYStartDrag = () => {
    if (this.wrapperRef.scrollBox?.content) {
      if (this.wrapperRef.activeElements && this.wrapperRef.activeElements.length) {
        this.offsetY = this.wrapperRef.scrollBox.content.center.y - this.wrapperRef.activeElements[0].y;
      } else {
        this.centerElementY = this.wrapperRef.getCenterElement('y');
        if (this.centerElementY) {
          this.offsetY = this.wrapperRef.scrollBox.content.center.y - this.centerElementY.y;
        }
      }
    }
  };
  zoomYStopDrag = () => {
    this.offsetY = 0;
    this.wrapperRef.updateSize(true);
    this.updateZoomHandles();
  };
  zoomXStartDrag = () => {
    this.dragStartZoomVal = this.wrapperRef.currentZoom.clone();
    this.dragStartScrollPos = this.wrapperRef.scrollBox?.content.corner.clone();
    this.startScenePosition = this.wrapperRef.sceneLabels?.getCurrentSceneClip().getBounds();
  };

  public startResize() {}

  zoomXStopDrag = () => {
    this.updateZoomHandles();
  };

  getYHandleSize = (margin?: number) => {
    if (!this.wrapperRef.scrollBox) {
      return MAX_DRAG_HANDLE_WIDTH;
    }
    let ySizeCalculation: number;
    const boxHeight =
      this.wrapperRef.getAudioScrollBoxEnabled() && this.wrapperRef.audioScrollBox
        ? this.wrapperRef.audioScrollBox.boxHeight +
          this.wrapperRef.scrollBox.boxHeight +
          this.wrapperRef.projectAudioLayersHeight
        : this.wrapperRef.scrollBox.boxHeight + this.wrapperRef.projectAudioLayersHeight;

    if (margin) {
      ySizeCalculation = boxHeight - margin / 2;
    } else {
      ySizeCalculation = boxHeight;
    }

    return ySizeCalculation > MAX_DRAG_HANDLE_WIDTH ? MAX_DRAG_HANDLE_WIDTH : ySizeCalculation;
  };

  updateSize(margin: number) {
    if (
      this.zoomHandleX &&
      this.zoomHandleY &&
      this.zoomHandleX.parent &&
      this.zoomHandleY.parent &&
      this.wrapperRef.scrollBox
    ) {
      const newYSize = this.getYHandleSize(margin);
      this.zoomHandleY.updateSize(newYSize);
      this.zoomHandleX.x = this.wrapperRef.scrollBox.x + BUTTON_WIDTH + TIMELINE_TOGGLE_WIDTH;
      this.zoomHandleX.y = 20;
      this.zoomHandleY.x = 20;
      this.zoomHandleY.y = margin + this.wrapperRef.pinnedElementsHeight + newYSize + PADDING_FOR_BUTTONS;
    }
    this.updateZoom({});
  }

  updateMouseWheel(xDelta: number, yDelta: number) {
    if (this.zoomHandleX && this.zoomHandleX.parent && this.wrapperRef.scrollBox) {
      this.mousePos = this.wrapperRef.scrollBox.content.toLocal(this.interactionManager.mouse.global);
      this.zoomHandleX.currentValue -= xDelta * 0.00051;
      this.zoomHandleX.emitUpdate({ instant: true });
      this.zoomHandleX.updateDisplay();
    }
    if (this.zoomHandleY && this.zoomHandleY.parent) {
      this.zoomHandleY.currentValue -= yDelta * 0.00051;
      this.zoomYStartDrag();
      this.zoomHandleY.emitUpdate({ instant: true });
      this.zoomHandleY.updateDisplay();
    }
  }

  destroy() {
    this.input?.Dispose();
    this.zoomHandleX?.destroy({ children: true });
    this.zoomHandleY?.destroy({ children: true });
    this.zoomHandleX = undefined;
    this.zoomHandleY = undefined;
  }
}
