import cloneDeep from 'lodash.clonedeep';

import { getUID } from '../../../helpers/TokenHelper';
import { fetchWithReAuth } from '../../requestLimiter';

import { getObject, getOptions, getServicesUrl, validateResponse } from './helpers';

const productName = 'videoscribe-cloud';

const baseUrl = getServicesUrl();

export function getProjectList(getUserByToken, queryParams) {
  const uid = getUID();
  const queryString = queryParams ? `?${queryParams}` : '';
  const url = `${baseUrl}user/${uid}/projects/${productName}${queryString}`;
  const options = getOptions();

  return (
    fetchWithReAuth(url, getUserByToken, options)
      .then(r => validateResponse(r, `Error fetching your projects.`))
      .then(getObject)
  );
}

export function getProject(scribeId, getUserByToken) {
  const url = `${baseUrl}project-data/${scribeId}`;
  const options = getOptions('GET');

  return (
    fetchWithReAuth(url, getUserByToken, options)
      .then(r => validateResponse(r, `Error fetching project ${scribeId}.`))
      .then(getObject)
  );
}

export function createProject(project, getUserByToken) {
  const url = `${baseUrl}project-data`;
  const options = getOptions(
    'POST',
    JSON.stringify({
      title: project.title,
      appName: productName,
      projectModel: project
    })
  );

  return (
    fetchWithReAuth(url, getUserByToken, options)
      .then(r => validateResponse(r, `Error creating project.`))
      .then(getObject)
      .then(response => {
        const data = response;
        const id = data.projectDataId;

        project.id = id;

        return project;
      })
  );
}

export function deleteProject(project, getUserByToken) {
  const url = `${baseUrl}project-data/${project.id}`;
  const options = getOptions('DELETE');

  return fetchWithReAuth(url, getUserByToken, options).then(r => validateResponse(r, `Error deleting your project.`));
}

export function updateProject(project, getUserByToken) {
  const url = `${baseUrl}project-data/${project.id}`;
  const clone = cloneDeep(project);

  delete clone.updatedOn;
  delete clone.viewOnly;

  const data = {
    title: clone.title,
    projectModel: clone
  };
  const options = getOptions('PUT', JSON.stringify(data));

  return (
    fetchWithReAuth(url, getUserByToken, options)
      .then(r => validateResponse(r, `Error updating your project.`))
      .then(getObject)
  );
}

export function duplicateProject(projectId, getUserByToken) {
  const url = `${baseUrl}project-data/duplicate/${projectId}`;
  const options = getOptions('POST');

  return (
    fetchWithReAuth(url, getUserByToken, options)
      .then(r => validateResponse(r, `Error duplicating your project.`))
      .then(getObject)
  );
}
