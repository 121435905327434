import { DownloadTrackingProperties } from 'js/types';

export const STOP_RENDER_STATUS_POLLING = 'STOP_RENDER_STATUS_POLLING';

export type StopRenderStatusPollingAction = {
  type: typeof STOP_RENDER_STATUS_POLLING;
  shortId: string;
  format: string;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export const stopRenderStatusPolling = (
  shortId: string,
  format: string,
  trackingProperties: DownloadTrackingProperties,
  scribeId: number
): StopRenderStatusPollingAction => ({
  type: STOP_RENDER_STATUS_POLLING,
  shortId,
  format,
  trackingProperties,
  scribeId
});
