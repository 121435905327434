import { ScribeAudioLayerModel } from 'js/models/ScribeAudioLayerModel';
import { AudioClip, ExistingScribeModel, SceneClipboardPayload, ScribeScene, VSElementModel } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import uuid from 'uuid/v4';

export default function pasteScenesIntoScribe(
  scribe: ExistingScribeModel,
  clipboard: SceneClipboardPayload,
  selectedSceneIds: string[] = []
): { updatedScribe: ExistingScribeModel; lastSceneId: string | null } {
  const clonedScribe = cloneDeep(scribe);

  const pastePosition = selectedSceneIds.length
    ? Math.max(...selectedSceneIds.map(sceneId => clonedScribe.scenes.findIndex(scene => scene.id === sceneId))) + 1
    : clonedScribe.scenes.findIndex(scene => scene.active) + 1;

  const newElements: VSElementModel[] = [];
  const newScenes = clipboard.data.map(clipboardScene => {
    const clonedScene = cloneDeep(clipboardScene);
    clonedScene.id = uuid();

    clonedScene.elementIds.forEach((elementId, index, arr) => {
      const elementFromClipboard = clipboard.metadata.elements.find(element => element.tempId === elementId);
      if (!elementFromClipboard) return;
      const clonedElement = cloneDeep(elementFromClipboard);
      clonedElement.id = uuid();

      arr[index] = clonedElement.id;
      delete clonedElement.tempId;
      newElements.push(clonedElement as VSElementModel);
    });

    const audioLayerIds: string[] = [];
    const audioLayers: ScribeAudioLayerModel[] = [];
    const audioClips: AudioClip[] = [];
    clonedScene.audioLayerIds?.forEach(audioLayerId => {
      const audioLayer = clipboard.metadata.audioLayers?.find(audioLayer => audioLayer.id === audioLayerId);
      if (!audioLayer) return;

      const audioLayerClone = cloneDeep(audioLayer);

      audioLayerClone.id = uuid();
      audioLayerIds.push(audioLayerClone.id);

      const audioClipsIds: string[] = [];
      audioLayer.audioClipIds?.forEach(audioClipId => {
        const audioClip = clipboard.metadata.audioClips?.find(audioClip => audioClip.id === audioClipId);
        if (!audioClip) return;

        const audioClipClone = cloneDeep(audioClip);

        audioClipClone.id = uuid();
        audioClipsIds.push(audioClipClone.id);
        audioClips.push(audioClipClone);
      });

      audioLayerClone.audioClipIds = audioClipsIds;
      audioLayers.push(new ScribeAudioLayerModel(audioLayerClone));
    });
    clonedScene.audioLayerIds = audioLayerIds;
    clonedScribe.audioLayers = [...clonedScribe.audioLayers, ...audioLayers];
    clonedScribe.audioClips = clonedScribe.audioClips ? [...clonedScribe.audioClips, ...audioClips] : [...audioClips];

    return clonedScene as ScribeScene;
  });

  clonedScribe.elements = [...clonedScribe.elements, ...newElements];
  clonedScribe.scenes.splice(pastePosition, 0, ...newScenes);

  const lastSceneId = newScenes.length ? newScenes[newScenes.length - 1].id : null;

  return { updatedScribe: clonedScribe, lastSceneId };
}
