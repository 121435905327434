import { ExistingScribeModel, ScribeCameraElement } from 'js/types';

export function allCamerasPinned(project: ExistingScribeModel) {
  const scribeElements = project.elements;
  const allCameras = scribeElements.filter((element): element is ScribeCameraElement => element.type === 'Camera');

  const allCamerasPinned = allCameras.every(camera => !!camera.cameraPinned === true);

  return allCamerasPinned;
}
