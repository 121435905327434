import * as PIXI from 'pixi.js';

// Initialise the cursor for reporting where the pen nib should sit.
const setupCursor = () => {
  const cursor = new PIXI.Container();
  cursor.x = 0;
  cursor.y = 0;

  return cursor;
};

export default setupCursor;
