import { FILE_CONTENT_TYPES } from 'js/config/consts';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { constructSVGAssetKey } from 'js/shared/lib/LocalDatabase/keys';
import { ExistingScribeModel, PlaybackScribeModel, SVGAsset, ScribeCameraElement } from 'js/types';
import clamp from 'lodash.clamp';
import { mapSceneElementIdsToElements } from 'js/shared/helpers/scenesHelpers';

import { MAX_SVG_TEXTURE_SIZE, MIN_SVG_TEXTURE_SIZE } from '../../models/VSVectorImage';

export const getMaxCameraZoomFromProject = (project: PlaybackScribeModel | ExistingScribeModel) => {
  const cameras = project.elements.filter((el): el is ScribeCameraElement => el.type === 'Camera');
  const maxZoom = Math.max(...cameras.map(cam => cam.scale));

  return maxZoom;
};

export const getMaxCameraZoomForSceneImageIsIn = (
  image: ScribeImageElementModel,
  project: PlaybackScribeModel | ExistingScribeModel
) => {
  const sceneImageIsIn = project.scenes.find(scene => scene.elementIds.includes(image.id));

  if (!sceneImageIsIn) {
    return 1;
  }

  const cameras = mapSceneElementIdsToElements(project, sceneImageIsIn).filter(element => element.type === 'Camera');
  const maxZoom = Math.max(...cameras.map(cam => cam.scale));

  return maxZoom;
};

export const getClampedSvgSize = (dimension: number, scale: number, zoom: number) => {
  return clamp(dimension * scale * zoom, MIN_SVG_TEXTURE_SIZE, MAX_SVG_TEXTURE_SIZE);
};

export default function getScribeSvgUrls(project: PlaybackScribeModel | ExistingScribeModel): Array<SVGAsset> {
  const scribeImageElements = project.elements.filter((el): el is ScribeImageElementModel => el.type === 'Image');

  const svgAssets = scribeImageElements
    .filter(imgEl => imgEl.image?.contentType === FILE_CONTENT_TYPES.SVG)
    .map(imageEl => {
      if (imageEl.image?.assetId) {
        const { width, height, scaleX, scaleY } = imageEl;
        const maxCameraZoom = getMaxCameraZoomForSceneImageIsIn(imageEl, project);

        const svgWidth = getClampedSvgSize(width, scaleX, maxCameraZoom);
        const svgHeight = getClampedSvgSize(height, scaleY, maxCameraZoom);

        return {
          key: constructSVGAssetKey({
            assetId: imageEl.image?.assetId,
            recolorSettings: imageEl.image?.recolor,
            useContentSvgViewbox: !!imageEl.useContentSvgViewbox
          }),
          url: imageEl._imageUrl,
          svgWidth,
          svgHeight
        };
      }

      return undefined;
    })
    .filter((asset): asset is SVGAsset => {
      return !!asset?.key && !!asset.url;
    });

  return svgAssets;
}
