import ScribeTextElementModel from 'js/models/ScribeTextElementModel';

interface FontData {
  fontFaces: string[];
  totalFontFaces: number;
}

export const getFontFace = (family: string, style: 'normal' | 'italic', weight: 'normal' | 'bold'): string => {
  let fontFace = family;
  if (weight === 'normal' && style === 'normal') fontFace += ' Regular';
  if (weight === 'normal' && style === 'italic') fontFace += ' Italic';
  if (weight === 'bold' && style === 'normal') fontFace += ' Bold';
  if (weight === 'bold' && style === 'italic') fontFace += ' BoldItalic';
  return fontFace;
};

export default function getTextElementsFontData(elements: Array<ScribeTextElementModel>): FontData {
  const scribeFontFacesSet = elements.reduce((fontFaces, element) => {
    const fontFamily = element.font.value;
    const fontWeight = element.fontWeight;
    const fontStyle = element.fontStyle;
    const fontFace = getFontFace(fontFamily, fontStyle, fontWeight);
    fontFaces.add(fontFace);
    return fontFaces;
  }, new Set<string>());
  return {
    fontFaces: [...scribeFontFacesSet],
    totalFontFaces: scribeFontFacesSet.size
  };
}
