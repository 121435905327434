import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationDown({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path d="M 30.9,42.6 44.7,28.9 42.5,26.7 31.4,37.8 V 16.2 H 28.2 V 37.7 L 17.2,26.6 15,28.8 28.8,42.6 c 0.3,0.3 0.7,0.5 1.1,0.5 0.4,0 0.7,-0.2 1,-0.5 z" />
    </svg>
  );
}
