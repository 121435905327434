import { CalloutCallToAction } from 'js/types';
import { downloadSuccessUpsellAction } from 'js/actionCreators/downloadActions';

import { NEXT_ACTION_TYPES } from './consts';

const useGetNextAction = (callToAction?: CalloutCallToAction) => {
  if (!callToAction) return;
  switch (callToAction.type) {
    case NEXT_ACTION_TYPES.DOWNLOAD_SUCCESS_UPSELL_ACTION:
      return downloadSuccessUpsellAction();
    default:
      return;
  }
};

export default useGetNextAction;
