import { PREP_SCRIBE_FOR_EDITOR_SUCCESS } from 'js/actionCreators/scribeActions';
import { call, take } from 'redux-saga/effects';

import { incrementEventSuperProperty, incrementUserProperties, track } from '../mixpanelProvider';

export default function* trackTemplateLoadedSaga(baseEventProperties) {
  const { assets } = yield take('TEMPLATE_ASSETS_FETCHED_FROM_CONTENTFUL');

  const sizeOfAssets = assets.reduce((counter, asset) => {
    return counter + asset.fields?.file?.details?.size || 0;
  }, 0);
  const numberOfImages = assets.filter(asset => asset.fields?.file?.contentType?.includes('image')).length;
  const numberOfAudio = assets.filter(asset => asset.fields?.file?.contentType?.includes('audio')).length;

  const { scribe } = yield take(PREP_SCRIBE_FOR_EDITOR_SUCCESS);

  yield call(track, 'Template Loaded', {
    ...baseEventProperties,
    'Scribe ID': scribe.id,
    'Size of Assets': sizeOfAssets,
    'Number of Images': numberOfImages,
    'Audio Exists': numberOfAudio > 0
  });

  yield call(incrementUserProperties, {
    'Total Scribes Created': 1,
    'Templates Opened': 1
  });
  yield call(incrementEventSuperProperty, 'Total Scribes Created');
  yield call(incrementEventSuperProperty, 'Templates Opened');
}

export function* trackServicesTemplateLoadedSaga(baseEventProperties) {
  const { scribe } = yield take(PREP_SCRIBE_FOR_EDITOR_SUCCESS);

  const imageElements = scribe.elements.filter(element => element.type === 'Image');
  const numberOfImages = imageElements.length;
  const audioClips = scribe.audioClips ?? [];
  const numberOfAudio = audioClips.length;

  yield call(track, 'Template Loaded', {
    ...baseEventProperties,
    'Scribe ID': scribe.id,
    'Number of Images': numberOfImages,
    'Audio Exists': numberOfAudio > 0
  });
  yield call(track, 'Edit Template', {
    ...baseEventProperties,
    'Scribe ID': scribe.id
  });
  yield call(incrementUserProperties, {
    'Total Scribes Created': 1,
    'Templates Opened': 1
  });
  yield call(incrementEventSuperProperty, 'Total Scribes Created');
  yield call(incrementEventSuperProperty, 'Templates Opened');
}
