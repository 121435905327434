import { ScribeCursorId } from 'js/types';

export interface TimelineTimingsComparisonModelConfig {
  id: string;
  startTime: number;
  emphasisType: string;
  exitType: string;
  animationType?: string | undefined;
  animationTime?: number;
  emphasisAnimationTime?: number;
  exitAnimationTime?: number;
  pauseTime?: number;
  cursorId?: ScribeCursorId;
  modelString?: string;
  locked?: boolean;
  hidden?: boolean;
  outOfCamera?: boolean;
}

export class TimelineTimingsComparisonModel {
  id: string;
  startTime: number;
  emphasisType: string;
  exitType: string;
  animationType?: string | undefined;
  cursorId?: ScribeCursorId;
  animationTime?: number;
  emphasisAnimationTime?: number;
  exitAnimationTime?: number;
  pauseTime?: number;
  modelString?: string;
  locked?: boolean;
  hidden?: boolean;
  outOfCamera?: boolean;

  constructor({
    id,
    startTime,
    emphasisType,
    exitType,
    animationType,
    animationTime,
    emphasisAnimationTime,
    exitAnimationTime,
    pauseTime,
    cursorId,
    modelString,
    locked,
    hidden,
    outOfCamera
  }: TimelineTimingsComparisonModelConfig) {
    this.id = id;
    this.startTime = startTime;
    this.animationTime = animationTime;
    this.emphasisAnimationTime = emphasisAnimationTime;
    this.exitAnimationTime = exitAnimationTime;
    this.pauseTime = pauseTime;
    this.animationType = animationType;
    this.emphasisType = emphasisType;
    this.exitType = exitType;
    this.cursorId = cursorId;
    this.modelString = modelString;
    this.locked = locked;
    this.hidden = hidden;
    this.outOfCamera = outOfCamera;
  }
}
