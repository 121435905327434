import { RootState } from 'js/types';
import React from 'react';
import { useSelector } from 'react-redux';

import './DownloadIframe.css';

export default function DownloadIframe() {
  const downloadUrl = useSelector((state: RootState) => state.download.downloadUrl);

  if (!downloadUrl) return null;

  return <iframe className="DownloadIframe" title="download-iframe" src={downloadUrl} />;
}
