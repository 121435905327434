import './ImageLibrary.css';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchBar from 'js/shared/components/SearchBar';
import { setQuickImageSearchTag } from 'js/actionCreators/ImageDrawerActions';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { IMAGE_LIBRARY_QUICK_SEARCH_TAGS } from 'js/config/consts';
import { getUserImages } from 'js/actionCreators/imagesActions';
import { ImageLibraryStep } from 'js/reducers/imageSearchReducer';

import CategoriesView from '../CategoriesView';
import SearchResultView from '../SearchResultView';
import UploadImageButton from '../UploadImageButton';
import ImageLibrarySection from '../ImageLibrarySection';
import ReplaceImageModeBanner from '../components/ReplaceImageModeBanner';

import QuickSearchCarousel from './QuickSearchCarousel';
import useHandleSearch from './hooks/useHandleSearch';
import QuickCarouselTag from './QuickCarouselTag';

export const MAX_IMAGE_SEARCH_INPUT_LENGTH = 128;

const ImageLibrary: React.FC = () => {
  const dispatch = useDispatch();
  const isCategoriesStep = useTypedSelector(state => state.imageSearch.displayedStep === ImageLibraryStep.CATEGORIES);
  const {
    searchInput,
    submittedSearchInput,
    searchType,
    selectedCategoryId,
    displayedStep,
    setSearchInput,
    clearSearchInput,
    handleCategoryClick,
    handleClickToCategories,
    handleSearch
  } = useHandleSearch();

  const handleQuickSearchTagClick = (tag: string) => {
    dispatch(setQuickImageSearchTag(tag));
  };

  useEffect(() => {
    dispatch(getUserImages());
    // On mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="ImageLibrary">
        <ImageLibrarySection>
          <div className="ImageLibrary__searchBar">
            <SearchBar
              value={searchInput}
              placeholder="Search for images"
              onChange={setSearchInput}
              clearSearchInput={clearSearchInput}
              onSubmit={handleSearch}
              inputProps={{ maxLength: MAX_IMAGE_SEARCH_INPUT_LENGTH }}
            />

            <UploadImageButton />
          </div>
          {isCategoriesStep && (
            <div className="ImageLibrary__carousel">
              <QuickSearchCarousel>
                {IMAGE_LIBRARY_QUICK_SEARCH_TAGS.map(tag => (
                  <QuickCarouselTag key={tag} onClick={() => handleQuickSearchTagClick(tag)}>
                    {tag}
                  </QuickCarouselTag>
                ))}
              </QuickSearchCarousel>
            </div>
          )}
        </ImageLibrarySection>

        <ReplaceImageModeBanner />

        {displayedStep === ImageLibraryStep.CATEGORIES && <CategoriesView onCategoryClick={handleCategoryClick} />}
        {displayedStep === ImageLibraryStep.SEARCH_RESULT && (
          <SearchResultView
            onClickToCategories={handleClickToCategories}
            searchType={searchType}
            searchInput={submittedSearchInput}
            categoryId={selectedCategoryId}
            onCategoryClick={handleCategoryClick}
          />
        )}
      </section>
    </>
  );
};

export default ImageLibrary;
