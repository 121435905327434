import { SetActiveSceneAction } from 'js/actionCreators/scribeActions';
import { setTimelineLocalStorage } from 'js/editor/Timeline/PixiTimeline/Utils/localStorageHelpers';
import { ScribeState } from 'js/types';
import cloneDeep from 'lodash.clonedeep';

export default function setActiveSceneReducer(state: ScribeState, action: SetActiveSceneAction) {
  const { scribeId, sceneId } = action;
  const scribe = state.allScribes.find(s => s.id === scribeId);

  if (scribe) {
    const updatedScribe = cloneDeep(scribe);
    const isSceneInScribe = updatedScribe.scenes.find(sc => sc.id === sceneId);

    if (isSceneInScribe) {
      updatedScribe.scenes.forEach(scene => {
        scene.active = scene.id === sceneId;
      });

      const updatedAllScribes = [...state.allScribes];
      const replacementIndex = updatedAllScribes.findIndex(s => s.id === updatedScribe.id);
      updatedAllScribes[replacementIndex] = updatedScribe;
      setTimelineLocalStorage(scribeId, 'sceneId', sceneId);

      return {
        ...state,
        allScribes: updatedAllScribes,
        selectedSceneIds: [sceneId],
        selectedAudioClip: null,
        activeElements: [],
        lastSelectedScene: sceneId,
        lastSelectedElement: null
      };
    }
  }

  return state;
}
