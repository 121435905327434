import React from 'react';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';
import { selectableSpinDirectionOptions } from 'js/config/animationOptions';
import { getStageTweenKey } from 'js/shared/helpers/getStageTweenKey';
import { getCurrentSpinDirection } from 'js/shared/helpers/getCurrentSpinDirection';

import ChoiceButtonContents from './ChoiceButtonContents';

const SpinChoiceButton = ({ element, elements, stageKey }) => {
  const tweenKey = getStageTweenKey(stageKey);

  const currentSelectedValue = getCurrentSpinDirection(element, elements, tweenKey);

  const selectedOption = selectableSpinDirectionOptions.find(opt => opt.value === currentSelectedValue);

  const optIcon = selectedOption !== undefined ? selectedOption.Icon() : <IconIndeterminate />;
  const optTitle = selectedOption ? selectedOption.label : 'Mixed';

  return (
    <ChoiceButtonContents label={optTitle} id={`${stageKey}-spin-choice-contents`}>
      {optIcon}
    </ChoiceButtonContents>
  );
};

export default SpinChoiceButton;
