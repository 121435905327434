import cloneDeep from 'lodash.clonedeep';

export default function multiDragAwareReorder(list, source, destination, draggableId, selectedIds) {
  if (selectedIds.length > 1) {
    const sortedSelectedIds = list.filter(listItem => selectedIds.includes(listItem.id)).map(listItem => listItem.id);
    const movingItems = sortedSelectedIds.map(id => {
      const clone = cloneDeep(list.find(listItem => listItem.id === id));
      clone.moved = true;
      return clone;
    });
    const newItems = Array.from(list);
    newItems.splice(source.index >= destination.index ? destination.index : destination.index + 1, 0, ...movingItems);
    const reorderedItems = newItems
      .filter(scene => !selectedIds.includes(scene.id) || (selectedIds.includes(scene.id) && scene.moved === true))
      .map(listItem => {
        delete listItem.moved;
        return listItem;
      });

    return reorderedItems;
  } else {
    const reorderedItems = Array.from(list);
    reorderedItems.splice(source.index, 1);
    reorderedItems.splice(
      destination.index,
      0,
      list.find(listItem => listItem.id === draggableId)
    );
    return reorderedItems;
  }
}
