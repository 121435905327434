import { FILE_CONTENT_TYPES } from 'js/config/consts';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { ExistingScribeModel, GIFAsset, PlaybackScribeModel } from 'js/types';

export default function getScribeGifUrls(scribe: PlaybackScribeModel | ExistingScribeModel): Array<GIFAsset> {
  const scribeImageElements = scribe.elements.filter((el): el is ScribeImageElementModel => {
    return el.type === 'Image';
  });
  const gifUrls = scribeImageElements
    .filter(imgEl => imgEl.image?.contentType === FILE_CONTENT_TYPES.GIF)
    .map(imageEl => ({
      url: imageEl._imageUrl,
      assetId: imageEl.image?.assetId
    }))
    .filter((payload): payload is GIFAsset => {
      return !!payload.url && !!payload.assetId;
    });

  return gifUrls;
}
