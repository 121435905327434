import React from 'react';
import { useDispatch } from 'react-redux';
import { hideCallout } from 'js/actionCreators/uiActions';
import { useTypedSelector } from 'js/selectors/typedUseSelector';

import IconTick from '../../icons/IconTick';
import IconError from '../../icons/IconError';
import Flash from '../Flash';

import useGetNextAction from './useGetNextAction';

const Callout = () => {
  const dispatch = useDispatch();
  const handleOnDismiss = () => dispatch(hideCallout());
  const showCallout = useTypedSelector(({ ui }) => ui.showCallout);
  const nextAction = useGetNextAction(showCallout?.callToAction);

  const icon = !showCallout || showCallout.isError ? <IconError src="error" /> : <IconTick src="tick" />;

  const actionBtn = () => {
    if (!showCallout?.callToAction || !nextAction) return;
    const handleOnClick = () => dispatch(nextAction);
    return (
      <button className="flash__action-btn" onClick={handleOnClick}>
        {showCallout.callToAction.label}
      </button>
    );
  };

  const message = (
    <span>
      <strong className="t-emphasis-text">{showCallout && showCallout.title}</strong>
      <span className="flash__text t-body-small">
        {showCallout && showCallout.details}
        {showCallout?.callToAction && actionBtn()}
      </span>
    </span>
  );

  return (
    <Flash
      isVisible={!!showCallout}
      icon={icon}
      message={message}
      onDismiss={handleOnDismiss}
      flashType={showCallout && showCallout.isError ? 'error' : 'default'}
      autoDismiss={showCallout?.autoDismiss ?? true}
    />
  );
};

export default Callout;
