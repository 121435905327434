import { gsap } from 'gsap';
import { SCENE_DIRECTION_KEYS } from 'js/config/sceneTransitionsOptions';
import { SceneTransitionConfig } from 'js/types';

import VSSceneTransitionBase, { VSSceneTransitionBaseProps } from './VSSceneTransitionBase';

export default class VSSlideTransition extends VSSceneTransitionBase {
  config?: SceneTransitionConfig;
  tween1PrevOut?: GSAPTween;
  tween2CurrIn: GSAPTween;

  constructor(props: VSSceneTransitionBaseProps) {
    super({ ...props });
    this.config = props.config;

    this.scene1Out = 1;
    this.scene2In = 1;

    this.tween1PrevOut = this.setupExitTransition();
    this.tween2CurrIn = this.setupIntroTransition();
  }

  setupExitTransition() {
    if (!this.previousScene) return;

    let tweenConfig;
    switch (this.config?.direction) {
      case SCENE_DIRECTION_KEYS.DOWN: {
        tweenConfig = {
          y: this.viewportDimensions.height,
          duration: 1
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.LEFT: {
        tweenConfig = {
          x: -this.viewportDimensions.width,
          duration: 1
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.RIGHT: {
        tweenConfig = {
          x: this.viewportDimensions.width,
          duration: 1
        };
        break;
      }

      case SCENE_DIRECTION_KEYS.UP:
      default: {
        tweenConfig = {
          y: -this.viewportDimensions.height,
          duration: 1
        };
      }
    }
    const tween = gsap.fromTo(this.previousScene.viewportContainer, { x: 0, y: 0 }, tweenConfig);
    tween.pause();

    return tween;
  }

  setupIntroTransition() {
    let tweenConfig;
    switch (this.config?.direction) {
      case SCENE_DIRECTION_KEYS.DOWN: {
        tweenConfig = {
          y: -this.viewportDimensions.height
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.LEFT: {
        tweenConfig = {
          x: this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.RIGHT: {
        tweenConfig = {
          x: -this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP:
      default: {
        tweenConfig = {
          y: this.viewportDimensions.height
        };
      }
    }

    const tween = gsap.fromTo(this.currentScene.viewportContainer, tweenConfig, { x: 0, y: 0, duration: 1 });
    tween.pause();

    return tween;
  }

  updateExitProgress(progress: number) {
    if (this.tween1PrevOut) {
      this.tween1PrevOut.progress(progress);
    }
  }

  updateIntroProgress(progress: number) {
    if (this.tween2CurrIn) {
      this.tween2CurrIn.progress(progress);
    }
  }
}
