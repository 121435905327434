import {
  SCENE_TRANSITION_KEY_NONE,
  SCENE_TRANSITION_KEY_SLIDE,
  SCENE_TRANSITION_KEY_BOUNCE,
  SCENE_TRANSITION_KEY_FADE,
  SCENE_TRANSITION_KEY_ERASE,
  SCENE_TRANSITION_KEY_DRAG_ON
} from 'js/config/consts';
import { SceneTransitionClassMap, SceneTransitionKey } from 'js/types';

import VSBounceTransition from './VSBounceTransition';
import VSSlideTransition from './VSSlideTransition';
import VSFadeTransition from './VSFadeTransition';
import VSEraseTransition from './VSEraseTransition';
import VSDragOnTransition from './VSDragOnTransition';

const classes = {
  [SCENE_TRANSITION_KEY_NONE]: null,
  [SCENE_TRANSITION_KEY_SLIDE]: VSSlideTransition,
  [SCENE_TRANSITION_KEY_FADE]: VSFadeTransition,
  [SCENE_TRANSITION_KEY_BOUNCE]: VSBounceTransition,
  [SCENE_TRANSITION_KEY_ERASE]: VSEraseTransition,
  [SCENE_TRANSITION_KEY_DRAG_ON]: VSDragOnTransition
};

export default function TransitionClassFromKey(key: SceneTransitionKey): SceneTransitionClassMap {
  return classes[key];
}
