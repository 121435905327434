import { ExistingScribeModel, PlaybackScribeModel } from 'js/types';

export const UPLOAD_RESOURCE_TO_GPU = 'UPLOAD_RESOURCE_TO_GPU';
export const UPLOAD_RESOURCE_TO_GPU_COMPLETE = 'UPLOAD_RESOURCE_TO_GPU_COMPLETE';

export interface UploadResourceToGpuAction {
  type: typeof UPLOAD_RESOURCE_TO_GPU;
  project: PlaybackScribeModel | ExistingScribeModel;
}

export const uploadResourceToGpu = (project: PlaybackScribeModel | ExistingScribeModel): UploadResourceToGpuAction => ({
  type: UPLOAD_RESOURCE_TO_GPU,
  project
});

export const uploadResourceToGpuComplete = () => ({
  type: UPLOAD_RESOURCE_TO_GPU_COMPLETE
});
