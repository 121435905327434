import { ScribeClipboard } from '../../types';

export async function writeToClipboard(payload: ScribeClipboard) {
  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(JSON.stringify(payload));
    } else {
      throw new Error('Clipboard API not available');
    }
  } catch (e) {
    console.error('Failed to copy: ', e);
    fallbackCopyTextToClipboard(JSON.stringify(payload));
  }
}

export const isValidClipboardObject = (clipboard: ScribeClipboard | string): clipboard is ScribeClipboard => {
  if (typeof clipboard !== 'object') return false;
  const isBasicClipboard =
    clipboard !== undefined &&
    clipboard.type !== undefined &&
    clipboard.data !== undefined &&
    clipboard.metadata !== undefined;
  if (!isBasicClipboard) return false;
  if (clipboard.type === 'scene') {
    return clipboard.metadata.canvasSize !== undefined && clipboard.metadata.elements !== undefined;
  } else if (clipboard.type === 'element') {
    return clipboard.metadata.sceneId !== undefined && clipboard.metadata.wasCut !== undefined;
  }
  return false;
};

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
}
