import { useCallback } from 'react';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { ImageSource } from 'js/types';
import { SearchType } from 'js/reducers/imageSearchReducer';

const useGetSearchResult = (searchType: SearchType, searchInput: string, categoryId: string | null) => {
  const imageLibrary = useTypedSelector(state => state.imageDrawer.imageLibrary);
  const key = searchType === SearchType.INPUT ? searchInput : categoryId || '0';

  const getExistingTabResult = useCallback(
    (tab: ImageSource) => imageLibrary[searchType][tab][key],
    [imageLibrary, searchType, key]
  );

  const isTabInit = useCallback((tab: ImageSource) => getExistingTabResult(tab) !== undefined, [getExistingTabResult]);

  const isTabLoading = useCallback(
    (tab: ImageSource) => getExistingTabResult(tab)?.loading ?? false,
    [getExistingTabResult]
  );

  const getTabImages = useCallback(
    (tab: ImageSource) =>
      (getExistingTabResult(tab)?.images ?? []).map(image => ({
        id: image.id,
        src: `${image.bucketUrl}/${image.thumbnailFilename}`,
        isPremium: image.isPremium,
        canChangeColor: tab === 'noun-project'
      })),
    [getExistingTabResult]
  );

  const getTabResultCount = useCallback((tab: ImageSource) => getExistingTabResult(tab)?.total, [getExistingTabResult]);

  const getAlternativeTab = useCallback(
    (tab: ImageSource) => {
      if (getTabImages('videoscribe').length === 0 && getTabImages('noun-project').length === 0) return;
      return tab === 'videoscribe' ? 'Icons' : 'Illustrations';
    },
    [getTabImages]
  );

  const isTabViewed = useCallback(
    (tab: ImageSource) => {
      const tabData = getExistingTabResult(tab);
      return 'isViewed' in tabData && tabData.isViewed;
    },
    [getExistingTabResult]
  );

  return {
    isTabInit,
    isTabLoading,
    getTabImages,
    getTabResultCount,
    getExistingTabResult,
    getAlternativeTab,
    isTabViewed
  };
};

export default useGetSearchResult;
