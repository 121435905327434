import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { useMemo } from 'react';

import { getUserPermissionHelperFunctions, UserWithPermissions } from './permissionsHelpers';

const useUserWithPermissions = (): UserWithPermissions => {
  const user = useTypedSelector(state => state.auth).currentUser;

  return useMemo(() => {
    const helperFunctions = getUserPermissionHelperFunctions(user);
    return { ...user, ...helperFunctions };
  }, [user]);
};

export default useUserWithPermissions;
