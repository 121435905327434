import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'element.scrollintoviewifneeded-polyfill';
import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import ErrorBoundary from 'js/shared/components/ErrorBoundaryView';

import App from './App';
import { getStore, initializeStore } from './store';
import { BASE_PATH } from './config/routes';
import config from './config/config';
import { getHistory } from './store/middlewares/routes';
import initSentry from './logging';
import theme from './theme';

import '@sparkol/videoscribe-react-ui/dist/style.css';
import './shims/ios.js';
import './shared/helpers/get-user-media.js';

import '../css/main.css';
import 'animate.css';

initSentry({
  dsn: config.SENTRY_DSN,
  release: config.SENTRY_RELEASE,
  environment: config.SENTRY_ENVIRONMENT,
  allowUrls: config.SENTRY_ALLOW_URLS
});

if (import.meta.env.MODE !== 'production') {
  console.warn(`
    You are running this application in development or test mode.
    If you are seeing this message in production there was an issue with the environment settings on the build server.
  `);
}

initializeStore();
class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Provider store={getStore()}>
            <ConnectedRouter history={getHistory()} basename={BASE_PATH}>
              <Route component={App} />
            </ConnectedRouter>
          </Provider>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
}

const rootEl = document.getElementById('root');

render(<Index />, rootEl);
