import { select, SelectEffect } from 'redux-saga/effects';
import { ExistingScribeModel } from 'js/types';
import { getProjectById } from 'js/sagas/selectors';

const selectProjectIdFromPathnameRegexp = /\/editor\/(\d+)\//;

export default function* getProjectFromPathname(): Generator<
  SelectEffect,
  ExistingScribeModel | undefined,
  string | ExistingScribeModel
> {
  const pathname = yield select(state => state.router.location.pathname);
  const projectId = typeof pathname === 'string' ? pathname.match(selectProjectIdFromPathnameRegexp)?.[1] : undefined;
  if (!projectId) return;
  const project = yield select(getProjectById, projectId);
  return typeof project !== 'string' ? project : undefined;
}
