import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';

export const getStyleProperties = (element: ScribeShapeElementModel) => ({
  fill: element.fill,
  opacity: element.opacity,
  backgroundType: element.backgroundType,
  backgroundColorFrom: element.backgroundColorFrom,
  backgroundColorTo: element.backgroundColorTo,
  backgroundGradientType: element.backgroundGradientType
});
