import React from 'react';
import classnames from 'classnames';

import './Tab.css';

export type TabProps = {
  id: string;
  title: string;
  count?: number;
  icon?: React.ReactNode;
  isActive: boolean;
  onSelect: (id: string) => void;
};

const Tab: React.FC<TabProps> = ({ id, title, count, icon, isActive, onSelect }) => {
  const tabClassNames = classnames('SubTab', { 'SubTab--active': isActive });
  return (
    <li className={tabClassNames} role="presentation">
      <button
        className="SubTab__button"
        onClick={() => onSelect(id)}
        role="tab"
        aria-selected={isActive}
        id={id}
        type="button"
      >
        {icon && <span className="SubTab__icon">{icon}</span>}
        <span className="SubTab__title">{title}</span>
        {count !== undefined && <span className="SubTab__count">({count.toLocaleString()})</span>}
      </button>
    </li>
  );
};

export default Tab;
