import { MarketingAnswer } from 'js/types';

export const SHOW_USERPERSONA_MODAL = 'SHOW_USERPERSONA_MODAL';
export const DISMISS_USERPERSONA_MODAL = 'DISMSS_USERPERSONA_MODAL';
export const CLOSE_USERPERSONA_MODAL = 'CLOSE_USERPERSONA_MODAL';
export const SUBMIT_USERPERSONA_MODAL = 'SUBMIT_USERPERSONA_MODAL';
export const GET_USERPERSONA_DETAILS_SUCCESS = 'GET_USERPERSONA_DETAILS_SUCCESS';

export const showUserpersonaModal = () => ({
  type: SHOW_USERPERSONA_MODAL
});

export const dismissUserpersonaModal = () => ({
  type: DISMISS_USERPERSONA_MODAL
});

export const closeUserpersonaModal = () => ({
  type: CLOSE_USERPERSONA_MODAL
});

export interface SubmitUserpersonaAction {
  type: typeof SUBMIT_USERPERSONA_MODAL;
  answers: MarketingAnswer;
}

export const submitUserpersona = (answers: MarketingAnswer): SubmitUserpersonaAction => ({
  type: SUBMIT_USERPERSONA_MODAL,
  answers
});
export interface GetUserPersonaDetailsSuccessAction {
  type: typeof GET_USERPERSONA_DETAILS_SUCCESS;
  dismissDate: string;
  dontShowAgain?: boolean;
}

export const getUserpersonaDetailsSuccess = ({
  dismissDate,
  dontShowAgain
}: Omit<GetUserPersonaDetailsSuccessAction, 'type'>): GetUserPersonaDetailsSuccessAction => ({
  type: GET_USERPERSONA_DETAILS_SUCCESS,
  dismissDate,
  dontShowAgain
});
