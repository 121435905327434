import React from 'react';
import classnames from 'classnames';

type IconCloseProps = {
  classes?: string;
};

const IconClose = ({ classes }: IconCloseProps) => (
  <span className={classnames('icon', classes)}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <path
        d="M88.3,2L50,40.3L11.7,2C9-0.7,4.6-0.7,2,2c-2.7,2.7-2.7,7.1,0,9.7L40.3,50L2,88.3C-0.7,91-0.7,95.4,2,98
c2.7,2.7,7.1,2.7,9.7,0L50,59.7L88.3,98c2.7,2.7,7.1,2.7,9.7,0c2.7-2.7,2.7-7.1,0-9.7L59.7,50L98,11.7c2.7-2.7,2.7-7.1,0-9.7
C95.4-0.7,91-0.7,88.3,2z"
      />
    </svg>
  </span>
);

export default IconClose;
