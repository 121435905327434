import cloneDeep from 'lodash.clonedeep';

export default function replaceActiveScene(scene, activeScribe) {
  let newActiveScribe = cloneDeep(activeScribe);

  const activeSceneIndex = newActiveScribe.scenes.findIndex(scene => scene.active);
  newActiveScribe.scenes.splice(activeSceneIndex, 1, scene);

  return newActiveScribe;
}
