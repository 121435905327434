import * as PIXI from 'pixi.js';

export function getPositionAdjustmentToFitInBounds(rectangle: PIXI.Rectangle, bounds?: PIXI.Rectangle) {
  let xAdjust = 0;
  let yAdjust = 0;

  if (!bounds) {
    return {
      xAdjust,
      yAdjust
    };
  }

  if (rectangle.right > bounds.right) {
    xAdjust = -(rectangle.right - bounds.right);
  }
  if (rectangle.left < bounds.left) {
    xAdjust = bounds.left - rectangle.left;
  }
  if (rectangle.bottom > bounds.bottom) {
    yAdjust = -(rectangle.bottom - bounds.bottom);
  }
  if (rectangle.top < bounds.top) {
    yAdjust = bounds.top - rectangle.top;
  }

  return {
    xAdjust,
    yAdjust
  };
}
