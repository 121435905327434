import React from 'react';

const IconSceneFade = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="90px"
    height="60px"
    viewBox="0 0 90 60"
    enableBackground="new 0 0 90 60"
    fill="currentcolor"
  >
    <path d="M76.6,7.8H13.7c-2.5,0-4.6,2-4.6,4.6v18.4h72V12.3C81.2,9.8,79.1,7.8,76.6,7.8z" />
    <path d="M9.6,50.1c0.4,0.9,1.1,1.6,1.9,2.1h67.3c0.8-0.5,1.5-1.2,1.9-2.1H9.6z" />
    <rect x="9.2" y="42.3" width="72" height="4.4" />
    <rect x="9.2" y="34.4" width="72" height="4.5" />
  </svg>
);

export default IconSceneFade;
