import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureContext } from '@sentry/types';
import createSentryMiddleware from 'redux-sentry-middleware';
import { History } from 'history';
import { HTTPError } from 'js/types';

import { AUTH } from '../config/routes';

import { sentrySensitiveProps } from './sentrySensitiveProps';
import { sentryIgnoreList } from './ignoreList';

interface SentryConfig {
  dsn?: string;
  release?: string;
  environment?: string;
  allowUrls?: Array<string>;
}

export default function initSentry({ dsn, release, environment, allowUrls }: SentryConfig) {
  if (import.meta.env.DEV) return;

  if (!dsn) {
    console.warn('MONITORING: Missing SENTRY_DSN. Errors will not be sent to Sentry.');
    return;
  }

  const isFatal = isCriticalPath(window.location.pathname);

  Sentry.configureScope(scope => {
    scope.setLevel(isFatal ? 'fatal' : 'error');
  });

  Sentry.init({
    dsn,
    release,
    environment,
    integrations: [new BrowserTracing()],
    allowUrls
  });
}

export function initSentryMiddleware() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore : Library types are out of sync with each other.
  return createSentryMiddleware(Sentry, {
    actionTransformer: action => {
      let reduxAction: unknown | null = { ...action };
      sentrySensitiveProps.forEach(name => {
        if (action.hasOwnProperty(name)) reduxAction = null;
      });
      return reduxAction;
    }
  });
}

export function sendRendererErrorToSentry(error: Error) {
  sendErrorToSentry(error, {
    tags: {
      renderer: true
    }
  });
}

export function sendTimelineErrorToSentry(error: Error) {
  sendErrorToSentry(error, {
    tags: {
      timeline: 'pixi'
    }
  });
}

export function sendErrorToSentry(err: HTTPError, captureContext?: CaptureContext | undefined) {
  if ((err.httpStatusCode && Number(err.httpStatusCode) < 500) || (err.err && sentryIgnoreList.indexOf(err?.err) >= 0))
    return; // if `err` is from a Http Request only log `server errors`
  let error = err;

  if (!(error instanceof Error)) {
    error = err?.err && typeof err.err === 'string' ? new Error(err?.err) : new Error(JSON.stringify(err));
  }

  Sentry.captureException(error, captureContext);
}

export function setSeverityLevel(history: History<unknown>) {
  history.listen(({ pathname }) => {
    const isFatal = isCriticalPath(pathname);
    Sentry.configureScope(scope => {
      scope.setLevel(isFatal ? 'fatal' : 'error');
    });
  });
}

export function setUserContext({ userId }: { userId: string }) {
  Sentry.setUser({ id: userId });
}

function isCriticalPath(pathname: string) {
  const criticalPaths = new RegExp(`(${AUTH})`);
  return criticalPaths.test(pathname);
}
