import ScribeModel from 'js/models/ScribeModel';

export const REQUEST_PREVIEW = 'REQUEST_PREVIEW';
export const GET_PROJECT_SHARING_ID = 'GET_PROJECT_SHARING_ID';
export const SETUP_PLAYBACK_STATE_FOR_PREVIEW = 'SETUP_PLAYBACK_STATE_FOR_PREVIEW';

export interface RequestPreviewStartedAction {
  type: typeof REQUEST_PREVIEW;
  projectModel: ScribeModel;
  signedUrls: Array<string>;
}

export const requestPreviewStarted = (
  projectModel: ScribeModel,
  signedUrls: Array<string>
): RequestPreviewStartedAction => ({
  type: REQUEST_PREVIEW,
  projectModel,
  signedUrls
});

export interface GetProjectSharingIdAction {
  type: typeof GET_PROJECT_SHARING_ID;
  projectId: number;
}

export const getProjectSharingId = (projectId: number): GetProjectSharingIdAction => ({
  type: GET_PROJECT_SHARING_ID,
  projectId
});

export const setupPlaybackForPreview = () => ({
  type: SETUP_PLAYBACK_STATE_FOR_PREVIEW
});
