import { FILE_CONTENT_TYPES } from 'js/config/consts';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { svgRootFromText } from 'js/shared/lib/SVGConvert';
import { encodeSvgFromString } from 'js/shared/helpers/svgToDataUrl';
import { applySvgViewboxAttributes } from 'js/shared/helpers/svgViewboxHelpers';
import { recolorSvg } from 'js/shared/providers';

import { calculateNewPosition } from './calculateNewPosition';
import getImageBlobFromUrl from './getImageBlobFromUrl';

export default async function processImageOnChange(element: ScribeImageElementModel, updatePositions?: boolean) {
  if (element.image && element.image.contentType === FILE_CONTENT_TYPES.SVG) {
    const file = await getImageBlobFromUrl(element._imageUrl);
    const svgText = await file.text();
    const svgElement = svgRootFromText(svgText);

    if (!svgElement) {
      return element;
    }

    const viewboxAttributesToApply = element?.viewboxAttributes
      ? element.viewboxAttributes[element?.useContentSvgViewbox ? 'contents' : 'original']
      : undefined;

    const viewboxApplied = viewboxAttributesToApply
      ? applySvgViewboxAttributes(svgElement, viewboxAttributesToApply)
      : svgElement;

    const recolor = element.recolorAvailable && !!element.image.recolor;

    const outputUrl = encodeSvgFromString(
      recolor ? recolorSvg(viewboxApplied.outerHTML, element.image.recolor) : viewboxApplied.outerHTML
    );

    element._imageUrl = outputUrl;

    if (updatePositions) {
      const newPositionAttributes = calculateNewPosition(element);

      if (newPositionAttributes) {
        element.x = newPositionAttributes.x;
        element.y = newPositionAttributes.y;
        element.width = newPositionAttributes.width;
        element.height = newPositionAttributes.height;
      }
    }
  }

  return element;
}
