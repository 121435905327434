import React from 'react';
import uniq from 'lodash.uniq';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { ElementMenuProps, ScribeCursorId } from 'js/types';
import { useDispatch } from 'react-redux';
import { setElementCursor } from 'js/actionCreators/cursorActions';
import { DEFAULT_HAND_CURSOR_ID } from 'js/config/consts';

import HandMenu from '../AnimationPanels/HandMenu';

const DrawHandMenu = ({ scribe, element, elements, onSelection, stageKey }: ElementMenuProps) => {
  const elementIds = element ? [element.id] : elements.map(el => el.id);

  const dispatch = useDispatch();
  const hands = useTypedSelector(state => state.cursors.handDraw);
  const filters = ['all', ...uniq(hands.map(hand => hand.group.toLowerCase()).sort())];

  let selectedCursorId;
  if (element) {
    selectedCursorId = element.cursorId ?? scribe.cursor;
  } else {
    const firstElCursorId = elements[0].cursorId ?? scribe.cursor;
    const allSame = elements.every(el => (el.cursorId ?? scribe.cursor) === firstElCursorId);
    if (allSame) {
      selectedCursorId = firstElCursorId;
    }
  }

  const handleSelection = (cursorId: ScribeCursorId) => {
    dispatch(setElementCursor(scribe.id, elementIds, cursorId, stageKey));
    onSelection();
  };

  return (
    <HandMenu
      hands={hands}
      onSelection={handleSelection}
      selectedCursorId={selectedCursorId}
      defaultCursorId={DEFAULT_HAND_CURSOR_ID}
      defaultCursorGroup="rosie"
      filters={filters}
    />
  );
};

export default DrawHandMenu;
