/* eslint-disable */
import { fabric } from 'fabric';

import { SCRIBE_TEXT_LINE_HEIGHT } from '../../config/defaults';
import getFontFamilyWithFallbackString from './getFontFamilyWithFallbackString';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';

export interface PathingPoint {
  x: number;
  y: number;
  moveTo?: boolean;
  isTerminal?: boolean;
}

const findStartPoint = (pixels: Array<Array<number>>, isTextRTL: boolean) => {
  const coordinates: Array<PathingPoint> = [];
  pixels.forEach((row, rowIndex) => {
    row.forEach((pixel, colIndex) => {
      if (pixel === 0) return;

      const point = { x: colIndex, y: rowIndex };
      const neighbors = countNeighbors({ x: colIndex, y: rowIndex }, pixels);
      coordinates.push({
        ...point,
        isTerminal: neighbors === 1
      });
    });
  });

  const terminals = coordinates.filter(coord => coord.isTerminal);

  // Favour terminal pixels over remaining pixels
  const potentialStartingPoints = terminals.length ? terminals : coordinates;
  const potentialStartingPointsSortedByY = [...potentialStartingPoints].sort((a, b) => a.y - b.y);
  const potentialStartingPointsSortedByX = [...potentialStartingPointsSortedByY].sort((a, b) => a.x - b.x);

  return isTextRTL ? potentialStartingPointsSortedByX.reverse()[0] : potentialStartingPointsSortedByX[0];
};

const COMPASS_POINT_ANGLE_STEP = 45;
const findDirectionOfTravelFromAverage = (directionHistory: Array<number>): number => {
  const averageDirectionTotal = directionHistory.reduce((p, c) => p + c, 0);

  if (averageDirectionTotal === 0) {
    return averageDirectionTotal;
  } else {
    const averageDirection = averageDirectionTotal / directionHistory.length;
    const leftTurnAngle = Math.ceil(averageDirection / COMPASS_POINT_ANGLE_STEP) * COMPASS_POINT_ANGLE_STEP;
    const rightTurnAngle = Math.floor(averageDirection / COMPASS_POINT_ANGLE_STEP) * COMPASS_POINT_ANGLE_STEP;

    return Math.abs(averageDirection - leftTurnAngle) > Math.abs(averageDirection - rightTurnAngle)
      ? rightTurnAngle
      : leftTurnAngle;
  }
};

const DIRECTION_HISTORY_SAMPLE_SIZE = 5;
const traceFromPoint = (
  point: PathingPoint,
  pixels: Array<Array<number>>
): [Array<PathingPoint>, Array<Array<number>>] => {
  let foundPoint: PathingPoint | undefined = point;
  let lastGoodPoint = point;
  let pixelsArr = pixels;
  let directionOfTravel;
  const directionHistory = [];
  const orderedPoints = [point];
  while (foundPoint) {
    const neighborCount = countNeighbors(foundPoint, pixels);
    if (neighborCount >= 2) lastGoodPoint = foundPoint;

    if (directionHistory.length) {
      directionOfTravel = findDirectionOfTravelFromAverage(directionHistory);
    }

    [foundPoint, pixelsArr, directionOfTravel] = findNearPoint(foundPoint, pixelsArr, directionOfTravel);

    if (typeof directionOfTravel !== 'undefined') {
      directionHistory.push(directionOfTravel);
      if (directionHistory.length > DIRECTION_HISTORY_SAMPLE_SIZE) directionHistory.shift();
    }

    if (foundPoint) {
      orderedPoints.push(foundPoint);
    } else {
      const neighborCount = countNeighbors(lastGoodPoint, pixels);
      if (neighborCount > 0) foundPoint = lastGoodPoint;
    }
  }

  return [orderedPoints, pixelsArr];
};

const findNearPoint = (
  { x, y }: PathingPoint,
  pixels: Array<Array<number>>,
  lastDirectionOfTravel?: number
): [PathingPoint | undefined, Array<Array<number>>, number | undefined] => {
  let directions: Array<[number, PathingPoint]> = [
    // [compassDirection, 1px jump]
    [0, { y: y - 1, x }],
    [45, { y: y - 1, x: x - 1 }],
    [90, { y, x: x - 1 }],
    [135, { y: y + 1, x: x - 1 }],
    [180, { y: y + 1, x }],
    [225, { y: y + 1, x: x + 1 }],
    [270, { y, x: x + 1 }],
    [315, { y: y - 1, x: x + 1 }]
  ];

  if (lastDirectionOfTravel) {
    // Reorder the directions so the last direction of travel is first in the array
    const compassDirIndex = directions.findIndex(([compassDir]) => compassDir === lastDirectionOfTravel);

    if (compassDirIndex > 0) {
      directions = directions.slice(compassDirIndex).concat(directions.slice(0, compassDirIndex));
    }
  }

  const neighbors = directions.filter(([_angle, direction]) => {
    return pixels[direction.y] && pixels[direction.y][direction.x] == 1;
  });

  let closest;
  const isFork = neighbors.length > 1;
  if (isFork) {
    const lastDirection = directions[0];
    // Find all possible paths for next step. Choose the one with the closest angle to lastDirectionOfTravel
    closest = neighbors.reduce((neighbor1, neighbor2) => {
      const n1Angle = Math.abs(lastDirection[0] - neighbor1[0]);
      const n2Angle = Math.abs(lastDirection[0] - neighbor2[0]);

      return n1Angle < n2Angle ? neighbor1 : neighbor2;
    }, neighbors[0]);
  } else if (neighbors.length === 1) {
    closest = neighbors[0];
  }

  if (closest) {
    // Make the pixel white
    pixels[closest[1].y][closest[1].x] = 0;
  }

  return [closest?.[1], pixels, closest?.[0]];
};

export const generateMaskPath = (pixels: Array<Array<number>>, isTextRTL = false) => {
  let pixelsArr = pixels;
  let startPoint = findStartPoint(pixelsArr, isTextRTL) || {
    x: 0,
    y: 0
  };
  startPoint.moveTo = true;

  pixelsArr[startPoint.y][startPoint.x] = 0;
  let points: Array<PathingPoint> = [];
  let orderedPoints = [];

  while (startPoint) {
    [orderedPoints, pixelsArr] = traceFromPoint(startPoint, pixelsArr);
    points = [...points, ...orderedPoints];
    startPoint = findStartPoint(pixelsArr, isTextRTL);
    if (startPoint) {
      pixelsArr[startPoint.y][startPoint.x] = 0;
      startPoint.moveTo = true;
    }
  }
  return points;
};

const countNeighbors = (point: PathingPoint, pixels: Array<Array<number>>) => {
  const row = [-1, -1, -1, 0, 0, 1, 1, 1];
  const col = [-1, 0, 1, -1, 1, -1, 0, 1];
  let neighbors = 0;
  for (let i = 0; i <= 8; i++) {
    const r = point.y + row[i];
    const c = point.x + col[i];
    const isNeighbor = r >= 0 && c >= 0 && r < pixels.length && c < pixels[0].length && pixels[r][c] !== 0;
    if (isNeighbor) neighbors++;
  }
  return neighbors;
};

export const getTextDimensions = (element: ScribeTextElementModel) => {
  const obj = new fabric.Text(element.text ?? '', {
    fontFamily: getFontFamilyWithFallbackString(element.font.value),
    fill: element.fill,
    fontSize: element.fontSize,
    lineHeight: SCRIBE_TEXT_LINE_HEIGHT,
    fontStyle: element.fontStyle,
    fontWeight: element.fontWeight,
    textAlign: element.align,
    snapAngle: 1
  });

  return { height: obj.height, width: obj.width };
};
