import React from 'react';

const IconIndeterminate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-3 -3 30 30"
    height="60px"
    width="60px"
    fill="currentcolor"
    x="0px"
    y="0px"
    enableBackground="new 0 0 60 60"
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <g>
          <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M17,13H7v-2h10V13z" />
        </g>
      </g>
    </g>
  </svg>
);

export default IconIndeterminate;
