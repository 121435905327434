import React, { Component } from 'react';
import Loader from 'react-loader';

import IconRecolor from '../../../imgs/icons/recolor.png';

interface ImageBrowsePanelThumbnailProps {
  assetId: number;
  thumb: boolean;
  asyncUrlProvider: (assetId: number, thumb: boolean, contentType: string, recolor: unknown) => Promise<string>;
  showLoadingSpinner: boolean;
  recolor: unknown;
  contentType: string;
  className: string;
  alt: string;
  draggable: boolean;
  showRecolorIcon: boolean;
}

interface ImageBrowsePanelThumbnailState {
  src: string | null;
}

class ImageBrowsePanelThumbnail extends Component<ImageBrowsePanelThumbnailProps, ImageBrowsePanelThumbnailState> {
  static defaultProps = {
    thumb: false,
    showLoadingSpinner: false,
    showRecolorIcon: false
  };

  constructor(props: ImageBrowsePanelThumbnailProps) {
    super(props);

    this.state = {
      src: null
    };

    this.mounted = true;
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.getImageUrl(this.props.assetId);
  }

  shouldComponentUpdate(nextProps: ImageBrowsePanelThumbnailProps) {
    if (this.props.assetId !== nextProps.assetId) {
      this.setState({ src: null });
    }
    return true;
  }

  componentDidUpdate(prevProps: ImageBrowsePanelThumbnailProps) {
    const { assetId, recolor } = this.props;
    if (assetId !== prevProps.assetId || recolor !== prevProps.recolor) {
      this.getImageUrl(assetId);
    }
  }

  componentWillUnmount() {
    if (this.imageRef.current) {
      this.imageRef.current.src = '';
    }
    this.mounted = false;
  }

  mounted: boolean;
  imageRef: React.RefObject<HTMLImageElement>;

  async getImageUrl(assetId: number) {
    const { asyncUrlProvider, thumb, contentType, recolor } = this.props;
    asyncUrlProvider(assetId, thumb, contentType, recolor).then(src => {
      if (this.mounted) {
        this.setState({ src });
      }
    });
  }

  render() {
    const { className, alt, showLoadingSpinner, assetId, draggable, showRecolorIcon } = this.props;
    const { src } = this.state;

    if (!src) {
      if (showLoadingSpinner === true) {
        return <Loader loaded={false} />;
      }
      return null;
    }

    return (
      <>
        <img
          id={`img-${assetId}`}
          className={className}
          src={src}
          alt={alt}
          draggable={draggable}
          ref={this.imageRef}
        />
        {/* recolor icons are burned into SAM thumbnail images */}
        {showRecolorIcon ? <img src={IconRecolor} className="ImageBrowsePanel__recolor" alt="" /> : null}
      </>
    );
  }
}

export default ImageBrowsePanelThumbnail;
