import { SVG_NAMESPACE } from '../../../../config/defaults';

// Sets up SVG element with component parts ready to be serialized for a PIXI Sprite
const setupSVGElement = (svgElements, width, height, viewBox) => {
  const svg = document.createElementNS(SVG_NAMESPACE, 'svg');
  svgElements.forEach(el => svg.appendChild(el));
  svg.setAttribute('width', `${width}px`);
  svg.setAttribute('height', `${height}px`);
  svg.setAttribute('viewBox', viewBox);
  svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');

  return svg;
};

export default setupSVGElement;
