import { ExistingScribeModel } from 'js/types';

export const GET_SCRIBE_FOR_PLAYBACK_ERROR = 'GET_SCRIBE_FOR_PLAYBACK_ERROR';
export const GET_SCRIBE_FOR_PLAYBACK_SUCCESS = 'GET_SCRIBE_FOR_PLAYBACK_SUCCESS';
export const GET_SCRIBE_FOR_RENDER = 'GET_SCRIBE_FOR_RENDER';
export const SET_MUTED = 'SET_MUTED';
export const PLAY_FROM_EDITOR = 'PLAY_FROM_EDITOR';
export const PLAYBACK_IS_READY = 'PLAYBACK_IS_READY';
export const START_PLAYBACK = 'START_PLAYBACK';
export const STOP_PLAYBACK = 'STOP_PLAYBACK';

export interface GetScribeForPlaybackErrorAction {
  type: typeof GET_SCRIBE_FOR_PLAYBACK_ERROR;
  error: string;
}

export const getScribeForPlaybackError = (error: string) => ({
  type: GET_SCRIBE_FOR_PLAYBACK_ERROR,
  error
});

export interface GetScribeForPlaybackSuccessAction {
  type: typeof GET_SCRIBE_FOR_PLAYBACK_SUCCESS;
  scribeData: ExistingScribeModel;
}

export const getScribeForPlaybackSuccess = (scribeData: ExistingScribeModel): GetScribeForPlaybackSuccessAction => ({
  type: GET_SCRIBE_FOR_PLAYBACK_SUCCESS,
  scribeData
});

export interface GetScribeForRenderAction {
  type: typeof GET_SCRIBE_FOR_RENDER;
  scribeId: number;
  startTimeMs: number;
  durationMs: number;
}

export const getScribeForRender = ({
  scribeId,
  startTimeMs,
  durationMs
}: Omit<GetScribeForRenderAction, 'type'>): GetScribeForRenderAction => ({
  type: GET_SCRIBE_FOR_RENDER,
  scribeId,
  startTimeMs,
  durationMs
});

export interface SetMutedAction {
  type: typeof SET_MUTED;
  muted: boolean;
}

export const setMuted = (muted: boolean): SetMutedAction => ({
  type: SET_MUTED,
  muted
});

export interface PlayFromElementEditorAction {
  type: typeof PLAY_FROM_EDITOR;
  scribeId: number;
  startingElementId: string | null;
  autoPlay: boolean;
  isRecordingAudio: boolean;
  previewAudioPlayback: boolean;
  audioUrl: string | null;
  playAudio: boolean;
}

export interface PlayFromElementEditorActionProps {
  scribeId: number;
  startingElementId?: string | null;
  autoPlay?: boolean;
  isRecordingAudio?: boolean;
  previewAudioPlayback?: boolean;
  audioUrl?: string | null;
  playAudio?: boolean;
}

export const playFromElementEditor = ({
  scribeId,
  startingElementId = null,
  autoPlay = true,
  isRecordingAudio = false,
  previewAudioPlayback = false,
  audioUrl = null,
  playAudio = true
}: PlayFromElementEditorActionProps): PlayFromElementEditorAction => ({
  type: PLAY_FROM_EDITOR,
  scribeId,
  startingElementId,
  autoPlay,
  isRecordingAudio,
  previewAudioPlayback,
  audioUrl,
  playAudio
});

export interface PlayFromSceneEditorAction {
  type: typeof PLAY_FROM_EDITOR;
  scribeId: number;
  startingSceneIndex: number | null;
  autoPlay: boolean;
  isRecordingAudio: boolean;
  previewAudioPlayback: boolean;
  audioUrl: string | null;
  playAudio: boolean;
}

export const playFromSceneEditor = ({
  scribeId,
  startingSceneIndex = null,
  autoPlay = true,
  isRecordingAudio = false,
  previewAudioPlayback = false,
  audioUrl = null,
  playAudio = true
}: Omit<PlayFromSceneEditorAction, 'type'>): PlayFromSceneEditorAction => ({
  type: PLAY_FROM_EDITOR,
  scribeId,
  startingSceneIndex,
  autoPlay,
  isRecordingAudio,
  previewAudioPlayback,
  audioUrl,
  playAudio
});

export const playbackIsReady = () => ({
  type: PLAYBACK_IS_READY
});

export const startPlayback = () => ({
  type: START_PLAYBACK
});

export const stopPlayback = () => ({
  type: STOP_PLAYBACK
});
