import { getViewportData } from 'js/sagas/sagaHelpers/getViewportData';
import { CanvasSize } from 'js/types';

export const getCanvasObjectCoordinates = (canvasSize: CanvasSize) => {
  const canvasDimensions = getViewportData(canvasSize);
  const MAX_SCROLL_MULTIPLIER = 3;
  const canvasCoordinates = {
    top: -canvasDimensions.height * MAX_SCROLL_MULTIPLIER,
    bottom: canvasDimensions.height * (MAX_SCROLL_MULTIPLIER + 1),
    left: -canvasDimensions.width * MAX_SCROLL_MULTIPLIER,
    right: canvasDimensions.width * (MAX_SCROLL_MULTIPLIER + 1)
  };

  return canvasCoordinates;
};
