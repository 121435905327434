import { ExtractedGIFData, GIFAsset } from 'js/types';
import * as PIXI from 'pixi.js';
import { IAddOptions, LoaderResource } from 'pixi.js';

import extractGifData from '../extractGifData';

const gifLoaderOptions = {
  loadType: PIXI.LoaderResource.LOAD_TYPE.XHR,
  xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BUFFER,
  crossOrigin: ''
};

const gifLoader = new PIXI.Loader();

const gifPostLoadingMiddleware = async (resource: LoaderResource, next: () => void) => {
  const gifData: ExtractedGIFData = await extractGifData(resource.name, resource.data);
  const textures = gifData.frames.map(img => PIXI.Texture.from(img));

  const newMeta = { ...resource.metadata, gifData, gifTextures: textures };

  resource.metadata = newMeta;
  resource.texture = textures[0];
  next();
};

gifLoader.use(gifPostLoadingMiddleware);

export const gifLoaderLoadPromise = (): Promise<PIXI.utils.Dict<PIXI.LoaderResource>> =>
  new Promise(resolve => gifLoader.load((_loader, resources) => resolve(resources)));

export const gifLoaderAdd = async (asset: GIFAsset) => {
  if (!gifLoader.resources[asset.assetId]) {
    if (gifLoader.loading) {
      const promise: Promise<void> = new Promise(resolve => {
        gifLoader.onComplete.once(() => resolve());
      });
      await promise;
    }

    gifLoader.add({
      key: asset.assetId.toString(),
      url: asset.url,
      ...gifLoaderOptions
    } as IAddOptions);
  }
};
