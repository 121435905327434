import { DEFAULT_SHAPE_COLOR } from 'js/config/defaults';

import {
  ScribeShapeElementModelProps,
  NewScribeShapeElementProps,
  ShapeType,
  BackgroundGradientType,
  BackgroundType
} from '../types';

import ScribeElementModel from './ScribeElementModel';

class ScribeShapeElementModel extends ScribeElementModel {
  type = 'Shape' as const;
  shapeType: ShapeType;
  fill: string;
  backgroundType: BackgroundType;
  backgroundColorFrom: string;
  backgroundColorTo: string;
  backgroundGradientType: BackgroundGradientType;

  radius?: number;
  rx?: number;
  ry?: number;

  constructor(data: ScribeShapeElementModelProps | NewScribeShapeElementProps) {
    super(data, 'Shape');

    if ('id' in data) {
      this.shapeType = data.shapeType;
      this.width = data.width;
      this.height = data.height;
      this.radius = data.radius;
      this.rx = data.rx;
      this.ry = data.ry;
      this.fill = data.fill;
      this.backgroundType = data.backgroundType;
      this.backgroundColorFrom = data.backgroundColorFrom || data.backgroundColourFrom || DEFAULT_SHAPE_COLOR;
      this.backgroundColorTo = data.backgroundColorTo || data.backgroundColourTo || DEFAULT_SHAPE_COLOR;
      this.backgroundGradientType = data.backgroundGradientType;
    } else {
      this.shapeType = data.shapeType;
      this.width = 150;
      this.height = 150;
      this.radius = 75;
      this.rx = 100;
      this.ry = 50;
      this.fill = DEFAULT_SHAPE_COLOR;
      this.backgroundType = 'solid';
      this.backgroundColorFrom = DEFAULT_SHAPE_COLOR;
      this.backgroundColorTo = DEFAULT_SHAPE_COLOR;
      this.backgroundGradientType = 'vertical';
    }
  }
}

export default ScribeShapeElementModel;
