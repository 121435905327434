import React from 'react';

const IconHandDraw = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <path
      d="M50.4,25.9h-8.1l-2.5-2.5l0,0c0.6-0.6,0.6-1.5,0-2.1l-6-6c-0.3-0.3-0.7-0.4-1.1-0.4v0H21.4c-0.4,0-0.8,0.2-1.1,0.4L7.7,27.8
	c-0.9,0.9-1.4,2.1-1.4,3.5c0,2.7,2.2,4.9,5,4.9c0.3,0,0.6,0,0.9-0.1l-1.8,7.2c-0.1,0.5,0,1.1,0.4,1.4c0.3,0.3,0.7,0.4,1.1,0.4
	c0.1,0,0.2,0,0.4,0l7.4-1.9c0.3-0.1,0.5-0.2,0.7-0.4l3.5-3.5c0.7,0.4,1.5,0.6,2.3,0.6c0,0,0,0,0,0l24.3-0.1c0.8,0,1.5-0.7,1.5-1.5
	v-11C51.9,26.5,51.2,25.9,50.4,25.9z M36.6,22.3l-1.8,1.8l-3.9-3.9l1.7-1.8L36.6,22.3z M22.8,32.1c-0.9,0.9-1.4,2-1.4,3.3
	c0,0.6,0.1,1.2,0.3,1.8l-2.6,2.6l-3.5-3.5l13.1-13.8l3.8,3.8l-4.2,4.2h-2.1c-1.2,0-2.4,0.5-3.3,1.4L22.8,32.1z M11.1,33.2
	c-1,0-1.9-0.8-1.9-1.9c0-0.5,0.2-1,0.6-1.3L22,17.7h7.2l-3.7,3.8h-1.3c-0.4,0-0.8,0.2-1.1,0.4L12.6,32.6
	C12.2,33,11.7,33.2,11.1,33.2z M14.5,39.2l1.8,1.8l-2.4,0.6L14.5,39.2z M48.9,36.9l-22.8,0.1c-0.4,0-0.8-0.2-1.1-0.5
	c-0.6-0.6-0.6-1.7,0-2.3l0.3-0.3c0.3-0.3,0.7-0.5,1.1-0.5h9.2c0.6,0,1.2-0.4,1.4-0.9c0.2-0.6,0.1-1.2-0.3-1.6l-2.2-2.2l3.2-3.2
	l2.9,2.9c0.3,0.3,0.7,0.4,1.1,0.4h7.3V36.9z"
    />
  </svg>
);

export default IconHandDraw;
