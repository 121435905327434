export const appendImageToTempCanvas = (
  url: string,
  width: number,
  height: number
): Promise<CanvasRenderingContext2D | undefined> => {
  const tempCanvas = document.createElement('canvas');
  const widthMin = Math.floor(width);
  const heightMin = Math.floor(height);
  tempCanvas.width = widthMin;
  tempCanvas.height = heightMin;

  return new Promise(resolve => {
    const tempCanvasContext = tempCanvas.getContext('2d');
    const newImage = new Image();

    newImage.crossOrigin = 'Anonymous';

    newImage.src = url;
    newImage.style.width = `${widthMin}px`;
    newImage.style.height = `${heightMin}px`;

    newImage.onload = () => {
      if (!tempCanvasContext) {
        return resolve(undefined);
      }

      tempCanvasContext.drawImage(newImage, 0, 0, width, height);

      resolve(tempCanvasContext);
    };
  });
};
