import React from 'react';

const IconSceneErase = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="90px"
    height="60px"
    viewBox="0 0 90 60"
    enableBackground="new 0 0 90 60"
    fill="currentcolor"
  >
    <path d="M28.8,38.4c-0.6,0-1.2-0.1-1.6-0.6L10.7,21.3c-0.4-0.4-0.6-1.1-0.6-1.6c0-0.6,0.3-1.2,0.6-1.7L20,8.7c0.9-0.9,2.2-0.9,3.1,0  l16.5,16.5c0.9,0.9,0.9,2.2,0,3.1l-9.3,9.3C29.8,38.3,29.4,38.4,28.8,38.4z M15.5,19.7L28.8,33l6.1-6.2L21.6,13.5L15.5,19.7z" />
    <path d="M76.5,8H65.4L31.1,42.4c-1.2,1.2-3.3,1.2-4.5,0L16.4,32.2L9,39.4v9.1c0,2.6,2.1,4.5,4.5,4.5h63c2.6,0,4.5-2.1,4.5-4.5v-36  C81,10.1,78.9,8,76.5,8z" />
    <path
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="4.5"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M38.1,26.9"
    />
  </svg>
);

export default IconSceneErase;
