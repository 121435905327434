import React from 'react';

const IconPenDraw = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <path
      d="M52.6,21.2l-6-6c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.2-1.1,0.5l-19,19.9c-0.2,0.2-0.3,0.4-0.4,0.7l-1.6,6.4h-6.9
	c2-0.7,3.8-1.6,3.7-3.5c0-0.6-0.2-2.4-5.8-3l-0.3,3c0.8,0.1,1.7,0.3,2.3,0.4c-0.7,0.3-1.7,0.6-2.4,0.7c-2.6,0.7-5.6,1.6-5.3,4.1
	c0.1,0.7,0.7,1.3,1.5,1.3h14.6V45c0.1,0,0.1,0,0.2,0l7.4-1.9c0.3-0.1,0.5-0.2,0.7-0.4l19.5-19.5C53.2,22.7,53.2,21.8,52.6,21.2z
	 M28.6,36.1l13.1-13.8l3.8,3.8L32.1,39.6L28.6,36.1z M27.3,39.1l1.8,1.8l-2.4,0.6L27.3,39.1z M47.6,24l-3.8-3.8l1.7-1.8l3.9,3.9
	L47.6,24z"
    />
  </svg>
);

export default IconPenDraw;
