import React from 'react';

import './EditPanelForm.css';

const EditPanelForm = ({
  children,
  className,
  onSubmit,
  ...props
}: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>) => {
  const handleSubmit = onSubmit
    ? onSubmit
    : (e: React.SyntheticEvent) => {
        e.preventDefault();
      };

  return (
    <form {...props} onSubmit={handleSubmit} className={`EditPanelForm ${className || ''}`}>
      {children}
    </form>
  );
};

export default EditPanelForm;
