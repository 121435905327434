import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import { PlaybackScribeModel, ScribeScene } from 'js/types';

export function getNonAnimatingScenes(
  project: PlaybackScribeModel,
  scenes: Array<ScribeScene>,
  startTimeMs: number,
  endTimeMs: number
) {
  return scenes.filter((scene, sceneIndex) => {
    const { startTime: sceneStartTime, endTime: sceneEndTime } = getSceneStartEndTimes(project, scene);
    const nextScene = scenes[sceneIndex + 1];
    const actualEndTime = sceneEndTime + (nextScene?.settings?.sceneTransitionTime ?? 0) * 1000;

    return actualEndTime < startTimeMs || sceneStartTime > endTimeMs;
  });
}

export function getAnimatingScenes(
  project: PlaybackScribeModel,
  scenes: Array<ScribeScene>,
  startTimeMs: number,
  endTimeMs: number
) {
  const notAnimating = getNonAnimatingScenes(project, scenes, startTimeMs, endTimeMs);
  return scenes.filter(scene => !notAnimating.includes(scene));
}
