const sleekNoteScriptId = 'sleeknoteScript';

export function mountSleekNote() {
  const sleeknoteScriptTag = document.createElement('script');
  sleeknoteScriptTag.id = sleekNoteScriptId;
  sleeknoteScriptTag.type = 'text/javascript';
  sleeknoteScriptTag.src = '//sleeknotecustomerscripts.sleeknote.com/49666.js';
  document.head.appendChild(sleeknoteScriptTag);
}

export function closeSleekNote() {
  if (window.sleekNoteClose && typeof window.sleekNoteClose === 'function') {
    window.sleekNoteClose();
  }
}
