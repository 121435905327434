import {
  DEFAULT_DRAG_CURSOR_ID,
  DEFAULT_HAND_CURSOR_ID,
  DEFAULT_PEN_CURSOR_ID,
  ROSIE_FINELINE_ID as DEFAULT_HAND_CURSOUR_NUMERIC_ID
} from 'js/config/consts';
import { findCursorById } from 'js/selectors/findCursorById';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';
import React from 'react';
import { useSelector } from 'react-redux';

import defaultHand from '../../../imgs/hands/Rosie1.png';
import defaultDrag from '../../../imgs/hands/default-drag.png';
import defaultPen from '../../../imgs/hands/default-pen.png';

import ChoiceButtonContents from './ChoiceButtonContents';

const rosieDefaultCursor = {
  group: 'Rosie',
  name: 'Rosie black fineliner',
  id: DEFAULT_HAND_CURSOR_ID,
  resources: { thumbnail: { url: defaultHand } }
};

const penDefaultCursor = {
  group: 'pens',
  name: 'Fountain Pen',
  id: DEFAULT_PEN_CURSOR_ID,
  resources: { thumbnail: { url: defaultPen } }
};

const dragDefaultCursor = {
  group: 'Rosie',
  name: 'Default',
  id: DEFAULT_DRAG_CURSOR_ID,
  resources: { thumbnail: { url: defaultDrag } }
};

const ChoiceButtonIcon = ({ imgUrl, isPen }) => {
  return (
    <div
      style={{
        height: '100%',
        backgroundImage: `url(${imgUrl})`,
        backgroundPosition: isPen ? '50% 50%' : '50% 0%',
        backgroundSize: isPen ? 'contain' : '100% auto',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '1 / 1'
      }}
    />
  );
};

const CursorChoiceButton = ({ scribe, element, elements, stageKey }) => {
  let cursorId;
  if (element) {
    cursorId = element.cursorId ?? scribe.cursor;
  } else {
    const firstCursor = elements[0].cursorId ?? DEFAULT_HAND_CURSOR_ID;
    const allSame = elements.every(el => {
      return (
        (el.cursorId ?? scribe.cursor) === firstCursor ||
        (firstCursor === DEFAULT_HAND_CURSOR_ID && el.cursorId === DEFAULT_HAND_CURSOUR_NUMERIC_ID) ||
        (firstCursor === DEFAULT_HAND_CURSOUR_NUMERIC_ID && el.cursorId === DEFAULT_HAND_CURSOR_ID)
      );
    });
    cursorId = allSame ? firstCursor : null;
  }
  let cursor = useSelector(({ cursors }) => findCursorById(cursors, cursorId));
  if (!cursor && cursorId === DEFAULT_HAND_CURSOR_ID) {
    cursor = rosieDefaultCursor;
  }
  if (!cursor && cursorId === DEFAULT_PEN_CURSOR_ID) {
    cursor = penDefaultCursor;
  }
  if (!cursor && cursorId === DEFAULT_DRAG_CURSOR_ID) {
    cursor = dragDefaultCursor;
  }

  const optIcon = cursor ? (
    <ChoiceButtonIcon imgUrl={cursor.resources.thumbnail.url} isPen={cursor.group === 'pens'} />
  ) : (
    <IconIndeterminate />
  );
  const optTitle = cursor ? cursor.name : 'Mixed';

  return (
    <ChoiceButtonContents label={optTitle} id={`${stageKey}-cursor-choice-contents`}>
      {optIcon}
    </ChoiceButtonContents>
  );
};

export default CursorChoiceButton;
