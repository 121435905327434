import { spawn, take } from 'redux-saga/effects';

import { setUserContext } from '../logging';
import { AUTH_SUCCESS } from '../actionCreators/authActions';
import { RESET_STATE } from '../actionCreators/generalActions';

export default function* monitoringSaga() {
  yield spawn(setUser);
  yield spawn(resetUser);
}

function* setUser() {
  while (true) {
    const { user } = yield take(AUTH_SUCCESS);
    setUserContext({ userId: user.id });
  }
}

function* resetUser() {
  while (true) {
    yield take(RESET_STATE);
    setUserContext({ userId: undefined });
  }
}
