import { defaultCanvasColor } from 'js/config/defaults';
import { allCamerasPinned } from 'js/shared/allCamerasPinned';
import getNewCamera from 'js/shared/helpers/getNewCamera';
import cloneDeep from 'lodash.clonedeep';
import uuidv4 from 'uuid/v4';

export default function createNewScene(scribe, sceneIds) {
  const newActiveScribe = cloneDeep(scribe);
  const activeScene = newActiveScribe.scenes.find(scene => scene.active);
  const activeSceneIndex = newActiveScribe.scenes.findIndex(scene => scene.active);
  const newScene = cloneDeep(activeScene);
  const newCamera = getNewCamera();
  newCamera.cameraPinned = allCamerasPinned(scribe);
  newCamera.animationTime = 0;
  newScene.elements = [newCamera];
  newScene.audioLayerIds = [];
  if (newActiveScribe.elements === undefined) newActiveScribe.elements = [];
  newActiveScribe.elements.push(newCamera);
  newScene.elementIds = [newCamera.id];
  newScene.id = uuidv4();
  newScene.settings.backgroundColor = defaultCanvasColor;
  newScene.thumbnailImage = null;
  newScene.settings.sceneTransitionType = undefined;
  newScene.settings.sceneTransitionTime = 0;
  newScene.settings.sceneTransitionConfig = undefined;

  activeScene.active = false;

  if (sceneIds.length === 0) {
    const addSceneIndex = activeSceneIndex + 1;

    newActiveScribe.scenes.splice(addSceneIndex, 0, newScene);
  } else {
    let activeSceneIndexes = [];

    for (const sceneId of sceneIds) {
      activeSceneIndexes.push(newActiveScribe?.scenes.findIndex(scene => scene?.id === sceneId));
    }
    const addSceneIndex = Math.max(...activeSceneIndexes) + 1;

    newActiveScribe.scenes.splice(addSceneIndex, 0, newScene);
  }

  return { newActiveScribe, sceneId: newScene.id };
}
