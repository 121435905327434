import {
  getStartTime,
  getSceneStartEndTimes,
  getElementEntranceAnimationTimeMs,
  getElementExitAnimationTimeMs,
  getElementEmphasisTimeMs
} from 'js/playback/lib/Playback/helpers/timings';
import { mapSceneElementIdsToElements } from 'js/shared/helpers/scenesHelpers';
import {
  DEFAULT_ERASE_CURSOR_ID,
  DEFAULT_DRAG_CURSOR_ID,
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  SCENE_TRANSITION_KEY_ERASE,
  SCENE_TRANSITION_KEY_DRAG_ON,
  DRAG_IN_KEY
} from 'js/config/consts';
import { CursorTiming, ExistingScribeModel, ScribeScene } from 'js/types';

const getSceneCursorId = (scene: ScribeScene) => {
  if (scene.settings?.sceneTransitionConfig?.cursorId === undefined) {
    return scene.settings.sceneTransitionType === 'erase' ? DEFAULT_ERASE_CURSOR_ID : DEFAULT_DRAG_CURSOR_ID;
  }

  return scene.settings.sceneTransitionConfig.cursorId;
};

export default function getCursorTimings(scribe: ExistingScribeModel) {
  const defaultCursor = scribe.cursor;

  const elementCursorTimings = scribe.scenes
    .flatMap(scene => mapSceneElementIdsToElements(scribe, scene)).reduce<Array<CursorTiming>>((timings, element) => {
      if (element.type === 'Camera') return timings;

      const cursorsInElement = [];

      const entranceStartTime = getStartTime(scribe, element.id);
      const entranceEndTime = entranceStartTime + getElementEntranceAnimationTimeMs(element, scribe);

      if (!element[ENTRANCE_TWEEN_DOCUMENT_KEY] || element[ENTRANCE_TWEEN_DOCUMENT_KEY]?.id === DRAG_IN_KEY) {
        const entranceCursorTiming = {
          startTime: entranceStartTime,
          endTime: entranceEndTime,
          cursorId: element.cursorId || defaultCursor
        };

        cursorsInElement.push(entranceCursorTiming);
      }

      // Add emphasis animation cursor information to timing array
      const emphasisEndTime = entranceEndTime + getElementEmphasisTimeMs(element);

      if (element.emphasisAnimation && element.emphasisAnimation.config && element.emphasisAnimation.config?.cursorId) {
        cursorsInElement.push({
          startTime: entranceEndTime,
          endTime: emphasisEndTime,
          cursorId: element.emphasisAnimation.config.cursorId
        });
      }

      // Add exit animation cursor information to timing array
      const exitEndTime = emphasisEndTime + getElementExitAnimationTimeMs(element, scribe);
      if (element.exitAnimation && element.exitAnimation.config && element.exitAnimation.config.cursorId) {
        cursorsInElement.push({
          startTime: emphasisEndTime,
          endTime: exitEndTime,
          cursorId: element.exitAnimation.config.cursorId
        });
      }

      return [...timings, ...cursorsInElement];
    }, []);

  const sceneTransitionTimings = scribe.scenes.reduce<Array<CursorTiming>>((timings, scene) => {
    if (
      scene.settings.sceneTransitionType !== SCENE_TRANSITION_KEY_ERASE &&
      scene.settings.sceneTransitionType !== SCENE_TRANSITION_KEY_DRAG_ON
    ) {
      return timings;
    }

    const { startTime } = getSceneStartEndTimes(scribe, scene);

    return [
      ...timings,
      {
        startTime,
        endTime: scene.settings.sceneTransitionTime
          ? startTime + scene.settings?.sceneTransitionTime * 1000
          : startTime,
        cursorId: getSceneCursorId(scene)
      }
    ];
  }, []);

  return elementCursorTimings.concat(sceneTransitionTimings).sort((timingA, timingB) => {
    if (timingA.startTime === timingB.startTime) {
      return timingA.endTime - timingB.endTime;
    } else {
      return timingA.startTime - timingB.startTime;
    }
  });
}
