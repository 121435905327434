import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationUp({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path d="M 28.8,16.7 15,30.4 17.2,32.6 28.3,21.5 v 21.6 h 3.2 V 21.6 l 11,11.1 2.2,-2.2 -13.8,-13.8 c -0.3,-0.3 -0.7,-0.5 -1.1,-0.5 -0.4,0 -0.7,0.2 -1,0.5 z" />
    </svg>
  );
}
