import './CategoryCarousel.css';

import React from 'react';
import { ImageCategory } from 'js/types';

import QuickSearchCarousel from '../QuickSearchCarousel';
import { useLibraries } from '../../hooks/useLibraries';
import QuickCarouselTag from '../QuickCarouselTag';

const CategoryCarousel = ({
  categoryId,
  onCategoryClick
}: {
  categoryId: string;
  onCategoryClick: (library: ImageCategory) => void;
}) => {
  const [loading, libraries] = useLibraries();

  if (loading) {
    return null;
  }

  return (
    <div className="CategoryCarousel">
      <QuickSearchCarousel>
        {libraries.map(library => {
          return (
            <QuickCarouselTag
              key={library.id}
              active={categoryId === library.id}
              onClick={() => onCategoryClick(library)}
            >
              {library.title}
            </QuickCarouselTag>
          );
        })}
      </QuickSearchCarousel>
    </div>
  );
};

export default CategoryCarousel;
