import { ExistingScribeModel, ScribeScene, VSElementModel } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import uuidv4 from 'uuid/v4';

export function createDuplicateScenesElements(clonedElements: VSElementModel[], newActiveScribe: ExistingScribeModel) {
  const newSceneElementIds = [];

  for (const element of clonedElements) {
    element.id = uuidv4();
    newSceneElementIds.push(element.id);
    newActiveScribe.elements.push(element);
  }
  return newSceneElementIds;
}

function createDuplicateAudioLayers(audioLayerIds: string[], newActiveScribe: ExistingScribeModel) {
  const newAudioLayers = [];
  const allNewAudioClips = [];

  for (const audioLayerId of audioLayerIds) {
    const audioLayer = newActiveScribe.audioLayers.find(al => al.id === audioLayerId);
    if (audioLayer) {
      const newAudioLayer = cloneDeep(audioLayer);
      newAudioLayer.id = uuidv4();
      const newAudioClips = [];

      for (const audioClipId of newAudioLayer.audioClipIds) {
        const audioClip = newActiveScribe.audioClips?.find(ac => ac.id === audioClipId);
        if (audioClip) {
          const newAudioClip = cloneDeep(audioClip);
          newAudioClip.id = uuidv4();
          const audioClipsWithSameSourceAssetId = newActiveScribe.audioClips?.filter(
            ac => ac.assetId === newAudioClip.assetId
          ).length;
          if (newActiveScribe.audioClips)
            newAudioClip.filename = audioClipsWithSameSourceAssetId + '_' + newAudioClip.filename;

          newAudioClips.push(newAudioClip);
        }
      }

      newAudioLayer.audioClipIds = newAudioClips.map(ac => ac.id);
      newAudioLayers.push(newAudioLayer);
      allNewAudioClips.push(...newAudioClips);
    }
  }

  return { newAudioLayers, newAudioClips: allNewAudioClips };
}

export function updateNewSceneElementsAndAudio(newScene: ScribeScene, newActiveScribe: ExistingScribeModel) {
  const elementsToBeCloned = newScene.elementIds
    .map(elId => newActiveScribe.elements.find(el => el.id === elId))
    .filter((el): el is VSElementModel => !!el);

  const clonedElements = cloneDeep(elementsToBeCloned);

  const newSceneElementIds = createDuplicateScenesElements(clonedElements, newActiveScribe);
  newScene.elementIds = newSceneElementIds;

  const { newAudioLayers, newAudioClips } = createDuplicateAudioLayers(newScene.audioLayerIds ?? [], newActiveScribe);
  newScene.audioLayerIds = newAudioLayers.map(al => al.id);
  if (newActiveScribe.audioLayers) newActiveScribe.audioLayers.push(...newAudioLayers);
  if (newActiveScribe.audioClips) newActiveScribe.audioClips.push(...newAudioClips);

  return newScene;
}

export function createDuplicateScenes(scenesToCopy: ScribeScene[], newActiveScribe: ExistingScribeModel) {
  const clonedScenes = [];
  const clonedSelectedScenes = [];

  const lastScene = scenesToCopy[scenesToCopy.length - 1];

  for (const scene of scenesToCopy) {
    const newScene = cloneDeep(scene);
    newScene.id = uuidv4();
    newScene.active = scene === lastScene;
    const updatedScene = updateNewSceneElementsAndAudio(newScene, newActiveScribe);
    clonedScenes.push(updatedScene);
    clonedSelectedScenes.push(updatedScene.id);
  }
  return { clonedScenes, clonedSelectedScenes };
}

export function getScenesToCopy(newActiveScribe: ExistingScribeModel, sceneIds: string[]) {
  const selectedSceneIndexes = [];
  const scenesToCopy = [];

  for (const sceneId of sceneIds) {
    selectedSceneIndexes.push(newActiveScribe?.scenes.findIndex(scene => scene?.id === sceneId));
  }

  selectedSceneIndexes.sort((a, b) => a - b);

  for (const index of selectedSceneIndexes) {
    scenesToCopy.push(newActiveScribe?.scenes[index]);
  }

  return { selectedSceneIndexes, scenesToCopy };
}

export function duplicateScenesIntoScribe(scribe: ExistingScribeModel, sceneIds: string[]) {
  const newActiveScribe = cloneDeep(scribe);
  const activeScene = newActiveScribe.scenes.find(scene => scene.active);

  if (activeScene) {
    activeScene.active = false;
  }

  const { selectedSceneIndexes, scenesToCopy } = getScenesToCopy(newActiveScribe, sceneIds);
  const insertSceneIndex = Math.max(...selectedSceneIndexes) + 1;
  const { clonedScenes, clonedSelectedScenes } = createDuplicateScenes(scenesToCopy, newActiveScribe);
  newActiveScribe.scenes.splice(insertSceneIndex, 0, ...clonedScenes);
  return { newActiveScribe, clonedSelectedScenes };
}
