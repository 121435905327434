import { VSImageShapeOrTextElementModel } from 'js/types';

export const getSvgScales = (
  element: VSImageShapeOrTextElementModel,
  fabricObject: { scaleX: number; scaleY: number; height: number; width: number }
): { scaleX: number; scaleY: number } => {
  let minWidth = 1;
  let minHeight = 1;
  let minScaleX = 1;
  let minScaleY = 1;

  let ratio;

  const scaledWidth = fabricObject.scaleX * fabricObject.width;
  const scaledHeight = fabricObject.scaleY * fabricObject.height;

  if (scaledWidth < scaledHeight) {
    ratio = scaledHeight / scaledWidth;
    minWidth = 1;
    minHeight = 1 * ratio;

    minScaleX = minWidth / element.width;
    minScaleY = minHeight / element.height;
  } else if (scaledHeight < scaledWidth) {
    ratio = scaledWidth / scaledHeight;

    minWidth = 1 * ratio;
    minHeight = 1;

    minScaleX = minWidth / element.width;
    minScaleY = minHeight / element.height;
  } else {
    minScaleX = 1 / element.width;
    minScaleY = 1 / element.height;
  }

  const scaleX = scaledWidth / element.width < minScaleX ? minScaleX : scaledWidth / element.width || 1;
  const scaleY = scaledHeight / element.height < minScaleY ? minScaleY : scaledHeight / element.height || 1;

  return { scaleX, scaleY };
};
