import React from 'react';

import ActionButton from '.';

interface ActionButtonWithA11yLabelProps {
  onClick: (event: React.MouseEvent) => void;
  id: string;
  labelText: string;
  children: React.ReactNode;
}

const ActionButtonWithA11yLabel = ({ onClick, id, labelText, children }: ActionButtonWithA11yLabelProps) => {
  return (
    <span>
      <label htmlFor={id} className="sr-only">
        {labelText}
      </label>
      <ActionButton onClick={onClick} datatip={labelText} id={id}>
        {children}
      </ActionButton>
    </span>
  );
};

export default ActionButtonWithA11yLabel;
