import { call, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import cloneDeep from 'lodash.clonedeep';
import {
  SET_ANIMATION_TYPE,
  SET_ANIMATION_DIRECTION,
  SET_EMPHASIS_ANIMATION_DIRECTION,
  SetAnimationTypeAction,
  SetAnimationDirectionAction,
  SetEmphasisAnimationDirectionAction
} from 'js/actionCreators/animationActions';
import { updateScribe, UpdateScribeAction } from 'js/actionCreators/scribeActions';
import getCursorTypeFromId from 'js/shared/helpers/getCursorTypeFromId';
import {
  AllAnimationTypeKeys,
  AnimationConfigurationOptions,
  AnimationStage,
  Cursor,
  ElementAnimationStageDurationKey,
  EntranceAnimationTypeKeys,
  RootState,
  TweenAnimation
} from 'js/types';
import { ENTRANCE_TWEEN_DEFAULTS, EXIT_TWEEN_DEFAULTS, EMPHASIS_TWEEN_DEFAULTS } from 'js/config/tweens';
import {
  ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY,
  EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY,
  EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY,
  EXIT_ANIMATION_DURATION_DOCUMENT_KEY,
  ALL_ANIMATION_DEFAULTS
} from 'js/config/animationOptions';
import {
  DRAW_KEY,
  CURSOR_TYPES,
  DEFAULT_HAND_CURSOR_ID,
  DEFAULT_PEN_CURSOR_ID,
  HAND_DRAW_KEY,
  NO_ANIMATION_KEY,
  PEN_DRAW_KEY,
  ENTRANCE_STAGE_KEY,
  EMPHASIS_STAGE_KEY,
  EXIT_STAGE_KEY,
  EXIT_ANIMATION_ERASE_KEY,
  SHAKE_LOOP_KEY
} from 'js/config/consts';
import { animationTime, exitAnimationTime } from 'js/shared/resources/scribedefaults';
import { getStageTweenKey } from 'js/shared/helpers/getStageTweenKey';
import { animationTypeHasNoDuration } from 'js/shared/helpers/animationTypeHasNoDuration';
import getAnimationLoops from 'js/shared/helpers/getAnimationLoops';
import getEmphasisAnimationDuration from 'js/shared/helpers/getEmphasisAnimationDuration';
import { ExistingScribeModel } from 'js/types';
import getStageAnimationConfigurationKey from 'js/shared/helpers/getStageAnimationConfigurationKey';
import {
  SetElementsAnimationCursorAction,
  SET_ELEMENTS_ANIMATION_CURSOR,
  SET_ELEMENTS_ANIMATION_DRAW_TYPE,
  SetElementsAnimationDrawTypeAction
} from 'js/actionCreators/animationConfigActions';
import getAnimationStageDocumentKey from 'js/shared/helpers/getAnimationStageDocumentKey';
import { noCameras } from 'js/shared/helpers/elementFilters';

import { getCursorsFromState, getProjectById, getScribeById } from './selectors';
import { getShakeEmphasisTween } from './sagaHelpers/getShakeEmphasisTween';

const DEFAULT_ANIMATION_TIME = animationTime;

const getDefaultAnimationTypeConfig = (animationType: AllAnimationTypeKeys): AnimationConfigurationOptions => {
  return ALL_ANIMATION_DEFAULTS[animationType];
};

function getStageAnimationTimingKey(stage: AnimationStage): ElementAnimationStageDurationKey {
  if (stage === 'entrance') return ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY;
  if (stage === 'emphasis') return EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY;
  return EXIT_ANIMATION_DURATION_DOCUMENT_KEY;
}

export function* setAnimationTypeToNone({
  stage,
  elementIds,
  scribeId
}: SetAnimationTypeAction): Generator<
  SelectEffect | PutEffect<UpdateScribeAction>,
  void,
  ExistingScribeModel | undefined
> {
  const response = yield select(getScribeById, scribeId);
  if (!response) {
    return;
  }

  const scribe = cloneDeep(response);
  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id))); // TODO Check this once camera animation types are worked on https://sparkol.atlassian.net/browse/VSP2-3069
  if (!elementsToUpdate.length) return;

  const animationDurationKey = getStageAnimationTimingKey(stage);
  const animationLoopsKey = EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY;
  const animationTweenKey = getStageTweenKey(stage);
  const animationDocumentKey = getStageAnimationConfigurationKey(stage);

  elementsToUpdate.forEach(element => {
    if (animationDurationKey === EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY) {
      element[animationDurationKey] = element[animationDurationKey] ?? 0;
      element[animationLoopsKey] = 0;
    } else {
      if (animationDurationKey) element[animationDurationKey] = 0;
    }

    delete element[animationTweenKey];
    delete element[animationDocumentKey];
  });

  yield put(updateScribe(scribe));
}

export function* setAnimationTypeToDraw({
  stage,
  elementIds,
  scribeId,
  animationType
}: SetAnimationTypeAction): Generator<SelectEffect | PutEffect<UpdateScribeAction>, void, RootState> {
  const state = yield select();
  const response = getScribeById(state, scribeId);
  if (!response) {
    return;
  }

  const scribe = cloneDeep(response);
  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id)));
  if (!elementsToUpdate.length) return;

  const animationDurationKey = getStageAnimationTimingKey(stage);
  const animationTweenKey = getStageTweenKey(stage);

  const cursors = getCursorsFromState(state);

  const animationDocumentKey = getStageAnimationConfigurationKey(stage);

  elementsToUpdate.forEach(element => {
    const currentCursorId = element.cursorId ?? scribe.cursor;

    if (animationType === DRAW_KEY) {
      element.cursorId = -1;
      element[animationDocumentKey] = { ...element[animationDocumentKey], id: DRAW_KEY };
    } else if (animationType === PEN_DRAW_KEY) {
      const cursorType = getCursorTypeFromId({ cursorId: currentCursorId, cursors });
      if (cursorType !== CURSOR_TYPES.PEN) {
        element.cursorId = DEFAULT_PEN_CURSOR_ID;
      }
      element[animationDocumentKey] = { ...element[animationDocumentKey], id: PEN_DRAW_KEY };
    } else if (animationType === HAND_DRAW_KEY) {
      const cursorType = getCursorTypeFromId({ cursorId: currentCursorId, cursors });
      if (cursorType !== CURSOR_TYPES.HAND) {
        element.cursorId = DEFAULT_HAND_CURSOR_ID;
      }
      element[animationDocumentKey] = { ...element[animationDocumentKey], id: HAND_DRAW_KEY };
    }

    if (animationDurationKey && element[animationDurationKey] === 0) {
      element[animationDurationKey] = DEFAULT_ANIMATION_TIME;
    }

    delete element[animationTweenKey];
  });

  yield put(updateScribe(scribe));
}

export function* setAnimationTypeToTween({
  animationType,
  elementIds,
  scribeId,
  stage,
  tweenPositions
}: SetAnimationTypeAction): Generator<
  SelectEffect | PutEffect<UpdateScribeAction>,
  void,
  ExistingScribeModel | undefined
> {
  let tweenDefault: TweenAnimation | undefined;
  if (stage === ENTRANCE_STAGE_KEY) {
    tweenDefault = ENTRANCE_TWEEN_DEFAULTS.find(tweenDef => {
      return tweenDef.id === animationType;
    });
  } else if (stage === EMPHASIS_STAGE_KEY) {
    tweenDefault = EMPHASIS_TWEEN_DEFAULTS.find(tweenDef => tweenDef.id === animationType);
  } else if (stage === EXIT_STAGE_KEY) {
    tweenDefault = EXIT_TWEEN_DEFAULTS.find(tweenDef => tweenDef.id === animationType);
  }

  if (!tweenDefault) return;

  const animationConfig = ALL_ANIMATION_DEFAULTS[animationType];

  const animationTweenKey = getStageTweenKey(stage);
  const animationDurationKey = getStageAnimationTimingKey(stage);
  const animationDocumentKey = getStageAnimationConfigurationKey(stage);
  const animationLoopsKey = EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY;

  const response = yield select(getScribeById, scribeId);

  if (!response) {
    return;
  }

  const scribe = cloneDeep(response);

  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id)));
  elementsToUpdate.forEach(element => {
    const tweenToUse = cloneDeep(tweenDefault);

    const animationDuration = element[animationDurationKey];

    if (animationDurationKey && !animationDuration && stage === EMPHASIS_STAGE_KEY) {
      const emphasisAnimationDuration = getEmphasisAnimationDuration({
        scribe,
        element: { ...element, [animationTweenKey]: tweenToUse },
        durationProperty: animationDurationKey,
        elements: null
      });

      element[animationDurationKey] = emphasisAnimationDuration;
    } else if (!animationDuration) {
      element[animationDurationKey] = DEFAULT_ANIMATION_TIME;
    }

    if (!element[animationLoopsKey] && stage === EMPHASIS_STAGE_KEY) {
      const numberOfLoops = getAnimationLoops({
        scribe,
        element: { ...element, [animationTweenKey]: tweenToUse },
        loopsProperty: animationLoopsKey,
        elements: null
      });

      element[animationLoopsKey] = numberOfLoops;
    }

    element[animationTweenKey] = tweenToUse;

    const animationTween = element[animationTweenKey];

    if (!animationTween) {
      return;
    }

    if (tweenPositions !== undefined) {
      if (animationTween.tweens) {
        const newPosition = tweenPositions.find(pos => pos.id === element.id)?.position;

        if (!newPosition) {
          return;
        }

        animationTween.tweens[0].position = newPosition;
      }
    }

    if (animationTypeHasNoDuration(animationType)) {
      element[animationDurationKey] = 0;
    }

    if (animationConfig && animationConfig.cursorId && stage === ENTRANCE_STAGE_KEY) {
      element.cursorId = animationConfig.cursorId;
    }

    delete element[animationDocumentKey];
  });

  yield put(updateScribe(scribe));
}

function* setAnimationTypeToAnimation({
  scribeId,
  elementIds,
  stage,
  animationType
}: SetAnimationTypeAction): Generator<
  SelectEffect | PutEffect<UpdateScribeAction>,
  void,
  ExistingScribeModel | undefined
> {
  const scribe = cloneDeep(yield select(getScribeById, scribeId));
  if (!scribe) return;
  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id)));
  if (!elementsToUpdate.length) return;

  const animationDurationKey = getStageAnimationTimingKey(stage);
  const animationTweenKey = getStageTweenKey(stage);
  const animationDocumentKey = getStageAnimationConfigurationKey(stage);

  // Need to setup some default config object for cursors
  elementsToUpdate.forEach(element => {
    element[animationDurationKey] = element[animationDurationKey] || exitAnimationTime;

    element[animationDocumentKey] = {
      id: animationType,
      config: getDefaultAnimationTypeConfig(animationType)
    };

    delete element[animationTweenKey];
  });

  yield put(updateScribe(scribe));
}

export function* setAnimationType(action: SetAnimationTypeAction) {
  const { animationType } = action;

  if (animationType === NO_ANIMATION_KEY) {
    yield call(setAnimationTypeToNone, action);
    return;
  } else if ([DRAW_KEY, PEN_DRAW_KEY, HAND_DRAW_KEY].includes(animationType)) {
    yield call(setAnimationTypeToDraw, action);
    return;
  } else if ([EXIT_ANIMATION_ERASE_KEY].includes(animationType)) {
    yield call(setAnimationTypeToAnimation, action);
    return;
  } else {
    yield call(setAnimationTypeToTween, action);
    return;
  }
}

export function* setAnimationDirection({
  elementIds,
  scribeId,
  stage,
  newTweenPosition,
  spinRotation
}: SetAnimationDirectionAction): Generator<
  SelectEffect | PutEffect<UpdateScribeAction>,
  void,
  ExistingScribeModel | undefined
> {
  const scribe = cloneDeep(yield select(getScribeById, scribeId));

  if (!scribe) {
    return;
  }

  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id)));
  const animationTweenKey = getStageTweenKey(stage);

  elementsToUpdate.forEach(element => {
    const tween = element[animationTweenKey];
    if (!tween) {
      return;
    }

    if (tween.tweens && newTweenPosition) {
      tween.tweens[0].position = newTweenPosition;
    }

    if (tween.tweens_in && spinRotation) {
      tween.tweens_in[0].rotation = spinRotation;
    }
  });

  yield put(updateScribe(scribe));
}

// write this just for shake
export function* setEmphasisAnimationDirection({
  elementIds,
  scribeId,
  stage,
  newTweenPosition,
  spinRotation
}: SetEmphasisAnimationDirectionAction): Generator<
  SelectEffect | PutEffect<UpdateScribeAction>,
  void,
  ExistingScribeModel | undefined
> {
  const scribe = cloneDeep(yield select(getScribeById, scribeId));

  if (!scribe) {
    return;
  }

  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id)));
  const animationTweenKey = getStageTweenKey(stage);

  elementsToUpdate.forEach(element => {
    let tween = element[animationTweenKey];
    if (!tween) {
      return;
    }

    if (tween.tweens_in && spinRotation) {
      tween.tweens_in[0].rotation = spinRotation;
    }
    if (tween.id === SHAKE_LOOP_KEY && newTweenPosition) {
      tween = getShakeEmphasisTween(newTweenPosition);
      element.emphasisTween = tween;
    }
  });

  yield put(updateScribe(scribe));
}

function* setElementsAnimationCursor({
  stage,
  cursorId,
  elementIds,
  scribeId
}: SetElementsAnimationCursorAction): Generator<
  SelectEffect | PutEffect<UpdateScribeAction>,
  void,
  ExistingScribeModel | undefined
> {
  const scribe = cloneDeep(yield select(getScribeById, scribeId));
  if (!scribe) {
    return;
  }
  const elementsToUpdate = noCameras(scribe.elements.filter(el => elementIds.includes(el.id)));
  const animationConfigKey = getAnimationStageDocumentKey(stage);
  elementsToUpdate.forEach(element => {
    const animationConfig = element[animationConfigKey];

    if (!animationConfig) {
      return;
    }

    animationConfig.config = { ...animationConfig?.config, cursorId };
  });

  yield put(updateScribe(scribe));
}

function* setElementsAnimationDrawType({
  projectId,
  elementIds,
  drawType,
  stage
}: SetElementsAnimationDrawTypeAction): Generator<
  SelectEffect | PutEffect,
  void,
  {
    project: ExistingScribeModel | undefined;
    cursors: Cursor[];
  }
> {
  const { project, cursors } = yield select(state => ({
    project: getProjectById(state, projectId),
    cursors: getCursorsFromState(state)
  }));

  if (!project) return;

  const clonedProject = cloneDeep(project);
  const elementsToUpdate = noCameras(clonedProject.elements.filter(el => elementIds.includes(el.id)));
  const animationConfigKey = getAnimationStageDocumentKey(stage);
  elementsToUpdate.forEach(element => {
    const animationConfig = element[animationConfigKey];
    const currentCursorId = element.cursorId ?? clonedProject.cursor;
    const cursorType = getCursorTypeFromId({ cursorId: currentCursorId, cursors });

    if (!animationConfig) {
      let id = HAND_DRAW_KEY;
      if (cursorType === CURSOR_TYPES.PEN) {
        id = PEN_DRAW_KEY;
      } else if (!cursorType) {
        id = DRAW_KEY;
      }
      element.cursorId = currentCursorId;
      element[animationConfigKey] = {
        id: id as EntranceAnimationTypeKeys,
        config: {
          drawType
        }
      };
    } else {
      animationConfig.config = { ...animationConfig?.config, drawType };
    }
  });

  yield put(updateScribe(clonedProject));
}

export default function* animationSagas() {
  yield takeEvery(SET_ANIMATION_TYPE, setAnimationType);
  yield takeEvery(SET_ANIMATION_DIRECTION, setAnimationDirection);
  yield takeEvery(SET_EMPHASIS_ANIMATION_DIRECTION, setEmphasisAnimationDirection);
  yield takeEvery(SET_ELEMENTS_ANIMATION_CURSOR, setElementsAnimationCursor);
  yield takeEvery(SET_ELEMENTS_ANIMATION_DRAW_TYPE, setElementsAnimationDrawType);
}
