import {
  GET_USER_FONTS_SUCCESS,
  SHOW_UPLOAD_CUSTOM_FONTS_MODAL,
  UPDATE_MODAL_STAGE,
  UPLOAD_FONTS_PROGRESS_STATUS,
  UPLOAD_FONTS_CONFIRMATION_STATUS,
  DELETE_USER_FONT_SUCCESS,
  SHOW_DELETE_CUSTOM_FONTS_MODAL,
  UploadFontFilesAction,
  GetUserFontsSuccessAction,
  ShowUploadCustomFontsModalAction,
  UpdateModalStageAction,
  UploadProgressStatusAction,
  UploadConfirmationStatusAction,
  LoadUserFontsAction,
  GET_USER_FONTS_REQUESTED,
  LOAD_USER_FONTS_SUCCESS,
  ShowDeleteCustomFontsModalAction,
  DeleteUserFontAction,
  DeleteUserFontSuccessAction,
  UPDATE_BITMAP_FONTS,
  UPDATE_BITMAP_FONTS_DONE,
  UpdateBitmapFontsAction,
  UpdateBitmapFontsDoneAction
} from 'js/actionCreators/fontActions';
import { FontsState } from 'js/types';

const initialState: FontsState = {
  userFonts: [],
  showUploadCustomFontsModal: false,
  uploadStage: 0,
  uploadStatus: {
    max: 100,
    complete: 0,
    message: 'Checking files'
  },
  confirmation: {
    invalidFiles: [],
    duplicateFiles: [],
    filesUploaded: []
  },
  showDeleteCustomFontsModal: false,
  selectedFont: null,
  isUpdatingBitmapFonts: false
};

type FontsAction =
  | {
      type: typeof GET_USER_FONTS_REQUESTED | typeof LOAD_USER_FONTS_SUCCESS;
    }
  | UploadFontFilesAction
  | GetUserFontsSuccessAction
  | ShowUploadCustomFontsModalAction
  | UpdateModalStageAction
  | UploadProgressStatusAction
  | UploadConfirmationStatusAction
  | LoadUserFontsAction
  | ShowDeleteCustomFontsModalAction
  | DeleteUserFontAction
  | DeleteUserFontSuccessAction
  | UpdateBitmapFontsAction
  | UpdateBitmapFontsDoneAction;

export default function fontReducer(state = initialState, action: FontsAction): FontsState {
  switch (action.type) {
    case GET_USER_FONTS_SUCCESS: {
      return {
        ...state,
        userFonts: [...action.fonts]
      };
    }

    case SHOW_UPLOAD_CUSTOM_FONTS_MODAL: {
      return {
        ...state,
        showUploadCustomFontsModal: action.value
      };
    }

    case UPDATE_MODAL_STAGE: {
      return {
        ...state,
        uploadStage: action.value
      };
    }

    case UPLOAD_FONTS_PROGRESS_STATUS: {
      return {
        ...state,
        uploadStatus: {
          max: initialState.uploadStatus.max,
          complete: action.progress,
          message: action.message
        }
      };
    }

    case UPLOAD_FONTS_CONFIRMATION_STATUS: {
      return {
        ...state,
        confirmation: action.confirmation,
        error: action.error
      };
    }

    case DELETE_USER_FONT_SUCCESS: {
      const userFonts = state.userFonts.filter(font => font.projectAssetId !== action.fontId);
      return {
        ...state,
        userFonts
      };
    }

    case SHOW_DELETE_CUSTOM_FONTS_MODAL: {
      return {
        ...state,
        showDeleteCustomFontsModal: action.value,
        selectedFont: action.font || null
      };
    }

    case UPDATE_BITMAP_FONTS: {
      return {
        ...state,
        isUpdatingBitmapFonts: true
      };
    }

    case UPDATE_BITMAP_FONTS_DONE: {
      return {
        ...state,
        isUpdatingBitmapFonts: false
      };
    }

    default:
      return state;
  }
}
