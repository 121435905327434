import * as PIXI from 'pixi.js';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { rendererLog } from 'js/shared/lib/CloudRenderer/rendererUtils.js';

import { manifest } from './manifest.js';
import installBitmapFont from './installBitmapFont';
import { getMemoryCache } from './generateBitmapFontsCache';

interface FontFace {
  name: string;
  filename: string;
  variable: boolean;
  variableWeight?: number;
  pixelRange?: number;
}

export async function generateBitmapFonts(elements: ScribeTextElementModel[]): Promise<void> {
  rendererLog('Generating bitmap fonts');
  const elementsByFontFamily = groupBy(elements, element => element.font.value);

  for (const fontFamilyName in elementsByFontFamily) {
    const elements = elementsByFontFamily[fontFamilyName];
    await generateFontFamilyBitmaps(fontFamilyName, elements);
  }
}

const generateFontFamilyBitmaps = async (family: string, elements: ScribeTextElementModel[]) => {
  await generateFontFaceBitmap(family, 'normal', 'normal', elements);
  await generateFontFaceBitmap(family, 'normal', 'bold', elements);
  await generateFontFaceBitmap(family, 'italic', 'normal', elements);
  await generateFontFaceBitmap(family, 'italic', 'bold', elements);
};

const generateFontFaceBitmap = async (
  family: string,
  style: 'normal' | 'italic',
  weight: 'normal' | 'bold',
  familyElements: ScribeTextElementModel[]
) => {
  const elements = familyElements.filter(el => el.fontStyle === style && el.fontWeight === weight);

  if (elements.length === 0) return false;

  const fontFace = getFontFace(family, style, weight);

  if (!fontFace) return false;

  const allChars = elements.reduce((acc, el) => acc + el.text || '', '');
  const uniqueChars = [...new Set(allChars)];

  const installedFont = PIXI.BitmapFont.available[fontFace.name];
  if (installedFont) {
    const hasEveryChar = uniqueChars.every(
      char => char === ' ' || char === '\n' || !!installedFont.chars[char.charCodeAt(0)]
    );
    if (hasEveryChar && getMemoryCache().has(fontFace.name)) return true;
    PIXI.BitmapFont.uninstall(fontFace.name);
    getMemoryCache().delete(fontFace.name);
  }

  const atlasData = await generateSingleBitmapFont(fontFace, uniqueChars.join(''));

  if (atlasData) {
    const atlasJson = JSON.parse(atlasData);
    getMemoryCache().set(fontFace.name, atlasJson.atlas.size);
    return true;
  }

  return false;
};

export const getFontFace = (
  family: string,
  style: 'normal' | 'italic',
  weight: 'normal' | 'bold'
): FontFace | undefined => {
  const font = manifest.fonts.find(f => f.fontFamily === family.replace(/\s/g, ''));
  if (!font) return undefined;
  if (weight === 'normal' && style === 'normal') return font.Regular;
  if (weight === 'bold' && style === 'normal') return font.Bold;
  if (weight === 'normal' && style === 'italic') return font.Italic;
  if (weight === 'bold' && style === 'italic') return font.BoldItalic;
  return undefined;
};

const generateSingleBitmapFont = (fontFace: FontFace, text: string): Promise<string | undefined> => {
  const worker = new Worker(new URL('../../../BitmapFontWorker/worker.ts', import.meta.url), {
    type: 'module'
  });

  return new Promise(resolve => {
    const handleError = () => {
      worker.removeEventListener('message', listener);
      worker.terminate();
      return resolve(undefined);
    };

    const listener = async (
      message: MessageEvent<{ bitmap: Uint8Array; bitmapFontInfo: string; atlasData: string; error?: string }>
    ) => {
      try {
        if (message.data.error !== undefined) return resolve(undefined);
        if (!message.data.bitmap) return resolve(undefined);
        const imageData = message.data.bitmap;
        const fontData = message.data.bitmapFontInfo;
        const atlasData = message.data.atlasData;
        const installedFont = await installFont(fontData, imageData);
        rendererLog(`Bitmap font installed [${installedFont.font}]`);
        return resolve(atlasData);
      } catch (e) {
        rendererLog('Error installing font:', e);
        return resolve(undefined);
      } finally {
        worker.removeEventListener('message', listener);
        worker.terminate();
      }
    };

    worker.addEventListener('message', listener);

    worker.addEventListener('error', handleError);

    worker.postMessage({
      filename: fontFace.filename,
      fontName: fontFace.name,
      variableWeight: fontFace.variableWeight,
      pixelRange: fontFace.pixelRange,
      text
    });
  });
};

const groupBy = <T, K extends string | symbol | number>(list: T[], getKey: (item: T) => K) =>
  list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>
  );

const installFont = (fontData: string, imageData: Uint8Array): Promise<PIXI.BitmapFont> => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([imageData], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      URL.revokeObjectURL(imageUrl);
      const texture = PIXI.Texture.from(img);
      const font = installBitmapFont(fontData, texture);
      return resolve(font);
    };
    img.onerror = () => reject('Failed loading font');
  });
};
