import { NetworkState } from 'js/types';

import { ChangeNetworkStatusAction, CHANGE_NETWORK_STATUS } from '../actionCreators/networkActions';

const initialState: NetworkState = {
  isOnline: window.navigator.onLine
};

type NetworkAction = ChangeNetworkStatusAction;

function networkReducer(state = initialState, action: NetworkAction): NetworkState {
  switch (action.type) {
    case CHANGE_NETWORK_STATUS:
      return {
        ...state,
        isOnline: action.isOnline
      };
    default:
      return state;
  }
}

export default networkReducer;
