import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationDownLeft({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path d="m 40.315181,37.830254 0.07071,-19.445436 H 37.27462 l 1e-6,15.697771 -15.273507,-15.273507 -2.262741,2.262742 15.202795,15.202795 -15.627059,-0.07071 v 3.11127 l 19.516146,10e-7 c 0.424264,0 0.848529,-0.141421 1.131372,-0.424264 0.282842,-0.282843 0.353552,-0.636397 0.353552,-1.060661 z" />
    </svg>
  );
}
