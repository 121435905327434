import { call, takeEvery, select } from 'redux-saga/effects';
import {
  TRACK_CURSOR_SETTINGS_OPEN,
  TRACK_OPEN_DOWNLOAD,
  TRACK_SHARE_PROJECT,
  TRACK_SEARCH_BY_PROJECT_TITLE,
  TRACK_NO_ACCESS_FEATURE_MODAL_DISPLAYED
} from 'js/actionCreators/trackingActions';
import { getScribeById } from 'js/sagas/selectors';
import { EDITOR_SET_EDIT_PANEL_MODE } from 'js/actionCreators/editorActions';
import { TOGGLE_GRID } from 'js/actionCreators/scribeActions';
import {
  SHOW_UPGRADE_WARNING_MODAL,
  SHOW_PREMIUM_FEATURE_MODAL,
  SHOW_LEFT_HAND_PANEL
} from 'js/actionCreators/uiActions';
import { LeftHandPanel } from 'js/types';
import getProjectFromPathname from 'js/sagas/sagaHelpers/getProjectFromPathname';

import { track } from '../mixpanelProvider';

function* trackAudioOpen({ mode, scribeId }) {
  if (mode === 'audio' && !!scribeId) {
    yield call(track, 'Open Scribe Audio', { 'Scribe ID': scribeId });
  }
}

function* trackCursorSettingsOpen({ scribeId }) {
  yield call(track, 'Open Drawing Hand', { 'Scribe ID': scribeId });
}

function* trackToggleGrid({ scribeId, eventTrigger }) {
  const gridlinesOn = yield select(state => state.ui.grid);

  yield call(track, gridlinesOn ? 'Turn Gridlines On' : 'Turn Gridlines Off', {
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger
  });
}

function* trackOpenDownload({ scribeId }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  yield call(track, 'Click Download Button', {
    'Scribe ID': scribeId,
    'Scribe Type': scribe.source?.type || 'unknown'
  });
}

function* trackShareProject({ scribeId, trigger }) {
  yield call(track, 'Share Project', { 'Scribe ID': scribeId, 'Event Trigger': trigger });
}

function* trackSearchByProjectTitle() {
  yield call(track, 'Search My Projects');
}

function* trackOpenUpgradeWarningModal({ triggerSource }) {
  yield call(track, 'No Paid Subscription Modal Displayed', {
    'Event Trigger': triggerSource
  });
}

export function* trackNoAccessFeatureModalDisplayed({ scribeId, triggerSource }) {
  yield call(track, 'No Access Feature Modal Displayed', {
    'Event Trigger': triggerSource,
    'Scribe ID': scribeId
  });
}

function* trackShowImageDrawer(action) {
  if (action.name !== LeftHandPanel.IMAGES) return;
  const project = yield call(getProjectFromPathname);
  if (!project) return;
  yield call(track, 'Open Image Modal', { 'Scribe ID': project.id });
}

export default function* trackUiSagas() {
  yield takeEvery(EDITOR_SET_EDIT_PANEL_MODE, trackAudioOpen);
  yield takeEvery(TRACK_CURSOR_SETTINGS_OPEN, trackCursorSettingsOpen);
  yield takeEvery(TOGGLE_GRID, trackToggleGrid);
  yield takeEvery(TRACK_OPEN_DOWNLOAD, trackOpenDownload);
  yield takeEvery(TRACK_SHARE_PROJECT, trackShareProject);
  yield takeEvery(TRACK_SEARCH_BY_PROJECT_TITLE, trackSearchByProjectTitle);
  yield takeEvery(SHOW_UPGRADE_WARNING_MODAL, trackOpenUpgradeWarningModal);
  yield takeEvery(
    [TRACK_NO_ACCESS_FEATURE_MODAL_DISPLAYED, SHOW_PREMIUM_FEATURE_MODAL],
    trackNoAccessFeatureModalDisplayed
  );
  yield takeEvery(SHOW_LEFT_HAND_PANEL, trackShowImageDrawer);
}
