import { ScribeBackgroundSettings, ScribeScene, ScribeCameraElement } from 'js/types';
import * as PIXI from 'pixi.js';
import IconCamera from 'imgs/pixi_icons/IconCamera.svg';

import TimelineLayerTooltip from './TimelineLayerTooltip';
import TimelineWrapper from './TimelineWrapper';
import { THUMBNAIL_WIDTH } from './consts';
import ThumbnailLockedIcon from './ThumbnailLockedIcon';
import TimelineHelpers from './Utils/TimelineHelpers';
import { TIMELINE_LAYER_FOREGROUND_COLOUR } from './TimelineWrapper';

type PropsType = {
  element: ScribeCameraElement;
  zoomValue: PIXI.Point;
  wrapperRef: TimelineWrapper;
  backgroundSettings: ScribeBackgroundSettings;
  cameraNumber: number;
};

const TEXT_COLOR = 0xffffff;
const LAYER_GAP = 6;

export default class TimelineLayerCameraThumbnail extends PIXI.Container {
  shapeGraphic?: PIXI.Graphics;
  textField?: PIXI.Text;
  background: PIXI.Graphics;
  padding: number;
  highlight: PIXI.Graphics;
  wrapperRef: TimelineWrapper;
  tooltip?: TimelineLayerTooltip;
  imageSprite?: PIXI.Sprite;
  activeScene?: ScribeScene;
  element: ScribeCameraElement;
  padlock?: ThumbnailLockedIcon;
  opaqueOverlay?: PIXI.Graphics;
  locked = false;
  hidden = false;
  icon: PIXI.Container;
  tooltipIcon?: PIXI.Container;
  labelText: PIXI.Text;
  cameraIcon?: PIXI.Container<PIXI.DisplayObject>;

  constructor({ element, wrapperRef, cameraNumber }: PropsType) {
    super();

    this.element = element;
    this.wrapperRef = wrapperRef;

    this.background = new PIXI.Graphics();
    this.highlight = new PIXI.Graphics();
    this.updateBackground(new PIXI.Point(10, 18));
    this.padding = 2;
    this.addChild(this.background);
    const { cameraIcon, labelText } = this.createCameraElement(element, cameraNumber);
    this.icon = cameraIcon;
    const texture = TimelineHelpers.importSVG(IconCamera);
    if (texture.valid) {
      this.tooltipIcon = new PIXI.Sprite(texture);
    } else {
      texture.on('update', () => {
        this.tooltipIcon = new PIXI.Sprite(texture);
      });
    }

    this.labelText = labelText;

    this.updateZoom();

    this.locked = this.element.locked;
    if (this.element.locked) this.lock();
  }
  get currentZoom() {
    return this.wrapperRef.currentZoom;
  }
  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    if (this.imageSprite) this.imageSprite.destroy({ children: true, texture: false, baseTexture: false });
    if (this.textField) this.textField.destroy({ children: true, texture: true, baseTexture: true });
    if (this.shapeGraphic) this.shapeGraphic.destroy({ children: true, texture: true, baseTexture: true });

    if (this.icon) this.icon.destroy({ children: true, texture: true, baseTexture: false });
    if (this.padlock) this.padlock.destroy({ children: true, texture: true, baseTexture: false });

    if (this.background) this.background.destroy({ children: true, texture: true, baseTexture: true });
    if (this.highlight) this.highlight.destroy({ children: true, texture: true, baseTexture: true });
    if (this.labelText) this.labelText.destroy({ children: true, texture: true, baseTexture: true });
    if (this.cameraIcon) this.cameraIcon.destroy({ children: true, texture: false, baseTexture: false });
    super.destroy(options);
  }

  public hide() {
    this.hidden = true;
    if (this.opaqueOverlay && this.opaqueOverlay !== undefined) this.opaqueOverlay.clear();
    this.opaqueOverlay = new PIXI.Graphics();
    this.opaqueOverlay.beginFill(0x000000, 0.5);
    this.opaqueOverlay.drawRect(0, 1, this.background.width, this.background.height + 1);
    this.addChild(this.opaqueOverlay);
  }

  public show() {
    this.hidden = false;
    if (this.opaqueOverlay && this.opaqueOverlay !== undefined) this.opaqueOverlay.clear();
  }

  public lock() {
    this.locked = true;
    if (this.padlock === undefined) this.padlock = new ThumbnailLockedIcon();
    else this.padlock.visible = true;

    this.addChild(this.padlock);
  }

  public unlock() {
    this.locked = false;
    if (this.padlock !== undefined) this.padlock.visible = false;
  }

  public updateGraphic(element: ScribeCameraElement) {
    this.addChild(this.icon);
    this.addChild(this.highlight);
    if (element.locked && !this.locked) this.lock();
    else if (!element.locked && this.locked) this.unlock();
  }

  private updateBackground(zoomVal: PIXI.Point) {
    this.background.clear();
    const height = zoomVal.y;
    this.background.beginFill(TIMELINE_LAYER_FOREGROUND_COLOUR);
    this.background.drawRoundedRect(0, 1, THUMBNAIL_WIDTH - LAYER_GAP, height - LAYER_GAP, 3);
    this.background.endFill();
    this.background.alpha = 0;
  }

  public updateZoom() {
    this.updateBackground(this.currentZoom);

    this.centerGraphics();

    if (this.textField) {
      this.textField.x = (this.background.width + this.padding) / 2 - this.textField.width / 2;
      this.textField.y = (this.background.height + this.padding) / 2 - this.textField.height / 2;
    }

    if (this.locked) this.lock();
    if (this.hidden) this.hide();
  }

  centerGraphics() {
    const bgAspect = this.background.width / this.background.height;
    const imageAspect = this.icon ? this.icon.width / this.icon.height : 1;
    if (this.icon) {
      this.addChild(this.icon);
      if (imageAspect < bgAspect) {
        this.icon.height = this.background.height - this.padding * 2;
        if (this.icon.scale.y > 1) this.icon.scale.y = 1;
        this.icon.scale.x = this.icon.scale.y;
      } else {
        this.icon.width = this.background.width - this.padding * 2;
        if (this.icon.scale.x > 1) this.icon.scale.x = 1;
        this.icon.scale.y = this.icon.scale.x;
      }

      this.icon.x = 5;
      this.icon.y = (this.background.height + this.padding) / 2 - this.icon.height / 2;
    }

    if (this.labelText) {
      this.addChild(this.labelText);

      if (this.icon) {
        if (this.background.height < this.icon.height + this.labelText.height + this.padding * 2) {
          this.icon.scale.x = this.icon.scale.y = this.icon.scale.y * 0.8;
          this.labelText.scale.x = this.labelText.scale.y = this.labelText.text.length > 2 ? 0.75 : 1;
          this.icon.x = this.padding;
          this.labelText.y = (this.background.height + this.padding) / 2 - this.labelText.height / 2;
          this.labelText.x = this.icon.width + this.padding * 2;
        } else {
          this.icon.y -= this.labelText.height / 2;
          this.labelText.scale.x = this.labelText.scale.y = 1;
          this.labelText.x = 5;
          this.labelText.y = this.icon.y + this.icon.height + 3;
        }
      }
    }
  }

  createCameraElement = (_element: ScribeCameraElement, cameraNumber: number) => {
    const labelText = new PIXI.Text('C' + cameraNumber.toString(), {
      fontFamily: 'Effra',
      fontSize: 11,
      fill: TEXT_COLOR,
      align: 'left',
      fontWeight: '700'
    });
    const cameraIcon = new PIXI.Container();

    const texture = TimelineHelpers.importSVG(IconCamera);

    if (texture.valid) {
      this.makeSprite(texture, cameraIcon, cameraNumber);
    } else {
      texture.on('update', () => {
        this.makeSprite(texture, cameraIcon, cameraNumber);
      });
    }

    return { cameraIcon, labelText };
  };

  makeSprite = (texture: PIXI.Texture, cameraIcon: PIXI.Container, cameraNumber: number) => {
    const cameraSprite = new PIXI.Sprite(texture);
    cameraSprite.width = 20;
    cameraSprite.height = 17;
    cameraSprite.x = cameraNumber > 9 ? -3 : 0;
    cameraSprite.y = 0;
    cameraIcon.addChild(cameraSprite);
    this.icon = cameraIcon;
    this.centerGraphics();
  };
}
