import { TweenAnimation } from 'js/types';

import {
  BOUNCE_IN_KEY,
  SLINGSHOT_KEY,
  DISAPPEAR_KEY,
  FADE_IN_KEY,
  FADE_OUT_KEY,
  MOVE_IN_KEY,
  MOVE_OUT_KEY,
  PULSE_LOOP_KEY,
  SPIN_LOOP_KEY,
  SHAKE_LOOP_KEY,
  BOUNCE_LOOP_KEY,
  DRAG_IN_KEY
} from './consts';
import { SPIN_CLOCKWISE_DEFAULT } from './defaults';

export const ENTRANCE_TWEEN_DEFAULTS: Array<TweenAnimation> = [
  {
    id: FADE_IN_KEY,
    tweens: [
      {
        alpha: 0
      }
    ]
  },
  {
    id: MOVE_IN_KEY,
    tweens: [
      {
        position: '@l',
        ease: 'power1'
      }
    ]
  },
  {
    id: DRAG_IN_KEY,
    tweens: [
      {
        position: '@t',
        ease: 'power1'
      }
    ]
  },
  {
    id: BOUNCE_IN_KEY,
    tweens: [
      {
        position: '@l',
        ease: 'bounce'
      }
    ]
  }
];

export const EMPHASIS_TWEEN_DEFAULTS: Array<TweenAnimation> = [
  {
    //set percentage_of_total in and out tweens to add up to 1. the time will then be determined by the users preferences of loops and duration
    id: PULSE_LOOP_KEY,

    tweens_in: [
      {
        scaleX: 1.1,
        scaleY: 1.1,
        ease: 'elastic',
        anchor: '@m',
        percentage_of_total: 0.3333
      }
    ],
    tweens_out: [
      {
        scaleX: 1,
        scaleY: 1,
        ease: 'bounce',
        anchor: '@m',
        percentage_of_total: 0.6666
      }
    ]
  },
  {
    id: SPIN_LOOP_KEY,
    tweens_in: [
      {
        rotation: SPIN_CLOCKWISE_DEFAULT,
        ease: 'none',
        anchor: '@m',
        percentage_of_total: 1
      }
    ]
  },
  {
    id: SHAKE_LOOP_KEY,

    tweens_in: [
      {
        x: '-=80',
        ease: 'none',
        percentage_of_total: 0.25
      },
      {
        x: '+=80',
        ease: 'none',
        percentage_of_total: 0.25
      }
    ],
    tweens_out: [
      {
        x: '+=80',
        ease: 'none',
        percentage_of_total: 0.25
      },
      {
        x: '-=80',
        ease: 'none',
        percentage_of_total: 0.25
      }
    ]
  },
  {
    id: BOUNCE_LOOP_KEY,

    tweens_in: [
      {
        y: '-=80',
        ease: 'power3',
        percentage_of_total: 0.3333
      }
    ],
    tweens_out: [
      {
        y: '+=80',
        ease: 'bounce',
        percentage_of_total: 0.6666
      }
    ]
  }
];

export const EXIT_TWEEN_DEFAULTS: Array<TweenAnimation> = [
  {
    id: FADE_OUT_KEY,
    tweens: [
      {
        alpha: 0
      }
    ]
  },
  {
    id: MOVE_OUT_KEY,
    tweens: [
      {
        position: '@r',
        ease: 'power1'
      }
    ]
  },
  {
    id: SLINGSHOT_KEY,
    tweens: [
      {
        position: '@r',
        ease: 'back.in'
      }
    ]
  },
  {
    id: DISAPPEAR_KEY,
    tweens: [
      {
        position: '@t',
        ease: 'none'
      }
    ]
  }
];

export const ALL_ANIMATION_TWEEN_DEFAULTS = [
  ...ENTRANCE_TWEEN_DEFAULTS,
  ...EMPHASIS_TWEEN_DEFAULTS,
  ...EXIT_TWEEN_DEFAULTS
];
