import { mapSceneElementIdsToElements } from 'js/shared/helpers/scenesHelpers';

import { stripProjectOfElementsNotInSegmentCameraViews } from './stripProjectOfElementsNotInSegmentCameraViews';
import { getNonAnimatingScenes } from './scenesAnimating';
import { stripElementForRenderer } from './stripElementForRenderer';

// Transforms the project data to change out any image elements in scenes which are not going to be visible in the render
// This should reduce the amount of resources we need to request and process in each render segment
function stripProjectOfElementsNotBeingRenderedInScenes({ project, startTimeMs, endTimeMs }) {
  const { scenes } = project;

  const scenesNotAnimating = getNonAnimatingScenes(project, scenes, startTimeMs, endTimeMs);

  const elementsNotAnimating = scenesNotAnimating.flatMap(scene => mapSceneElementIdsToElements(project, scene));

  elementsNotAnimating.forEach(stripElementForRenderer);

  return project;
}

export default function transformProjectForRendering({ project, startTimeMs, durationMs }) {
  const endTimeMs = startTimeMs + durationMs;

  const strippedOfScenesProject = stripProjectOfElementsNotBeingRenderedInScenes({ project, startTimeMs, endTimeMs });
  const strippedOfOutOfCamera = stripProjectOfElementsNotInSegmentCameraViews({
    project: strippedOfScenesProject,
    startTimeMs,
    endTimeMs
  });

  return strippedOfOutOfCamera;
}
