import { initSentryMiddleware } from '../../logging';

import sagas, { runSagaMiddleware } from './sagas';
import routes from './routes';

export const postApply = () => {
  runSagaMiddleware();
};

const middlewares = {
  initSentryMiddleware,
  sagas,
  routes
};

export default middlewares;
