import { call, put, select, takeLatest } from 'redux-saga/effects';
import isBefore from 'date-fns/isBefore';
import subDays from 'date-fns/subDays';
import {
  SUBMIT_USERPERSONA_MODAL,
  DISMISS_USERPERSONA_MODAL,
  showUserpersonaModal,
  getUserpersonaDetailsSuccess
} from 'js/actionCreators/marketingActions';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { trackPersonaAnswers } from 'js/actionCreators/trackingActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';

function* fetchUserpersonaResponse() {
  let userDetails = {};
  try {
    userDetails = yield appServices.getAppData('userpersona');
  } catch (error) {
    if (error.httpStatusCode !== 404) {
      userDetails = { error: true };
    }
  }
  return userDetails;
}

function* saveUserpersonaResponse(userpersonaResponse) {
  try {
    yield appServices.setAppData('userpersona', userpersonaResponse);
  } catch (error) {
    yield;
  }
}

function* saveUserpersonaDismissal() {
  const previousDismissDate = yield select(state => state.marketing.personaDismissDate);

  const requestData = {
    dismissDate: new Date()
  };

  yield saveUserpersonaResponse(previousDismissDate ? { ...requestData, dontShowAgain: true } : requestData);
}

function* saveAnswers({ answers }) {
  const answerDate = new Date();
  yield call(saveUserpersonaResponse, {
    answers,
    answerDate
  });
  yield put(trackPersonaAnswers(answers, answerDate, true));
}

export function* initUserpersona() {
  const { dismissDate, answerDate, error, answers, dontShowAgain } = yield fetchUserpersonaResponse();

  if (!error) {
    yield put(getUserpersonaDetailsSuccess({ dismissDate, dontShowAgain }));
  }

  const hasNotErrored = !error;
  const hasNotBeenSetToNotShowAgain = !dontShowAgain;
  const hasNotAlreadyAnswered = !answerDate;
  const hasNotDismissedBefore = !dismissDate;
  const wasDismissedOver7DaysAgo = isBefore(new Date(dismissDate), subDays(Date.now(), 7));

  if (
    hasNotErrored &&
    hasNotBeenSetToNotShowAgain &&
    hasNotAlreadyAnswered &&
    (hasNotDismissedBefore || wasDismissedOver7DaysAgo)
  ) {
    yield put(showUserpersonaModal());
  }
  if (answers) {
    yield put(trackPersonaAnswers(answers, answerDate));
  }
}

function* marketingSagas() {
  yield takeLatest(AUTH_SUCCESS, initUserpersona);
  yield takeLatest(DISMISS_USERPERSONA_MODAL, saveUserpersonaDismissal);
  yield takeLatest(SUBMIT_USERPERSONA_MODAL, saveAnswers);
}

export default marketingSagas;
