import { ExistingScribeModel, PlaybackScribeModel } from 'js/types';

import { getSceneStartEndTimes, getElementTotalTimesLengthMs } from './timings';

export default function getScribeLengthMs(scribe: ExistingScribeModel | PlaybackScribeModel) {
  if (scribe.scenes) {
    return getSceneStartEndTimes(scribe, scribe.scenes[scribe.scenes.length - 1]).endTime;
  } else {
    return (
      scribe.elements?.reduce((prevTime, el) => {
        return prevTime + getElementTotalTimesLengthMs(el, scribe);
      }, 0) || 0
    );
  }
}
