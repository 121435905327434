import UserModel from 'js/shared/helpers/app-services/UserModel';
import { VSError } from 'js/types';

export const RESTART_LOGIN = 'RESTART_LOGIN';
export const VALIDATE_TOKEN_REQUESTED = 'VALIDATE_TOKEN_REQUESTED';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILED = 'VALIDATE_TOKEN_FAILED';
export const LOGOUT = 'LOGOUT';
export const SET_ACCOUNT_MANAGEMENT_REDIRECT = 'SET_ACCOUNT_MANAGEMENT_REDIRECT';
export const SET_TRIAL_HAS_EXPIRED = 'SET_TRIAL_HAS_EXPIRED';
export const CHECK_TRIAL_STATUS = 'CHECK_TRIAL_STATUS';
export const SET_NO_ONLINE_STORAGE = 'SET_NO_ONLINE_STORAGE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export interface AuthSuccessAction {
  type: typeof AUTH_SUCCESS;
  user: UserModel;
  revalidation: boolean;
}

export interface ValidateTokenAction {
  type: typeof VALIDATE_TOKEN_REQUESTED;
  showSpinner: boolean;
  revalidation: boolean;
  userUpgraded: boolean;
}

export interface ValidateTokenFailureAction {
  type: typeof VALIDATE_TOKEN_FAILED;
  error: Error;
}

export interface ValidateTokenSuccessAction {
  type: typeof VALIDATE_TOKEN_SUCCESS;
  user: UserModel;
}

export interface SetAccountManagementRedirectAction {
  type: typeof SET_ACCOUNT_MANAGEMENT_REDIRECT;
  redirect: boolean;
}

export interface SetTrialHasExpiredAction {
  type: typeof SET_TRIAL_HAS_EXPIRED;
  user: UserModel;
}

export interface SetNoOnlineStorageAction {
  type: typeof SET_NO_ONLINE_STORAGE;
  error: VSError;
}

export const authSuccess = (user: UserModel, revalidation = false): AuthSuccessAction => ({
  type: AUTH_SUCCESS,
  user,
  revalidation
});

export const restartLogin = () => ({
  type: RESTART_LOGIN
});

export const validateToken = ({
  showSpinner = true,
  revalidation = false,
  userUpgraded = false
}): ValidateTokenAction => ({
  type: VALIDATE_TOKEN_REQUESTED,
  showSpinner,
  revalidation,
  userUpgraded
});

export const validateTokenFailure = (error: Error): ValidateTokenFailureAction => ({
  type: VALIDATE_TOKEN_FAILED,
  error
});

export const validateTokenSuccess = (user: UserModel): ValidateTokenSuccessAction => ({
  type: VALIDATE_TOKEN_SUCCESS,
  user
});

export type LogoutAction = {
  type: typeof LOGOUT;
  userInitiated: boolean;
};
export const logout = ({ userInitiated = true } = {}): LogoutAction => ({
  type: LOGOUT,
  userInitiated
});

export const setAccountManagementRedirect = (redirect: boolean): SetAccountManagementRedirectAction => ({
  type: SET_ACCOUNT_MANAGEMENT_REDIRECT,
  redirect
});

export const setTrialHasExpired = (user: UserModel): SetTrialHasExpiredAction => ({
  type: SET_TRIAL_HAS_EXPIRED,
  user
});

export const setNoOnlineStorage = (error: VSError) => ({
  type: SET_NO_ONLINE_STORAGE,
  error
});
