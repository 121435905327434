import cloneDeep from 'lodash.clonedeep';

export default function sanitizeScribeForTransport(dirtyScribe, stripId = false, stripPreviewId = false) {
  const scribe = cloneDeep(dirtyScribe);
  const { elements } = scribe;
  elements?.forEach(element => {
    if (element.hasOwnProperty('_imageUrl')) {
      delete element._imageUrl;
    }
    if (element.hasOwnProperty('_imageLoaded')) {
      delete element._imageLoaded;
    }
    if (element.hasOwnProperty('_imageLoading')) {
      delete element._imageLoading;
    }
    if (element.image?.hasOwnProperty('imgUrl')) {
      delete element.image.imgUrl;
    }
  });

  scribe.audioClips?.forEach(audioClip => {
    delete audioClip.url;
  });

  if (stripId) {
    delete scribe.id;
  }

  if (stripPreviewId) {
    delete scribe.publicSharingId;
  }

  return scribe;
}
