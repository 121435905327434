import { ExistingScribeModel, ProjectTemplateCategory, SortOrder } from 'js/types';

export const CREATE_SCRIBE_FROM_TEMPLATE = 'CREATE_SCRIBE_FROM_TEMPLATE';
export const CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS = 'CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS';
export const CREATE_SCRIBE_FROM_TEMPLATE_FAILED = 'CREATE_SCRIBE_FROM_TEMPLATE_FAILED';

export const GET_SCRIBE_TEMPLATES = 'GET_SCRIBE_TEMPLATES';
export const GET_SCRIBE_TEMPLATES_FAILED = 'GET_SCRIBE_TEMPLATES_FAILED';
export const GET_SCRIBE_TEMPLATES_BY_CATEGORY_SUCCESS = 'GET_SCRIBE_TEMPLATES_BY_CATEGORY_SUCCESS';

export const SAVE_SCRIBE_AS_TEMPLATE = 'SAVE_SCRIBE_AS_TEMPLATE';
export const SAVE_SCRIBE_AS_TEMPLATE_SUCCESS = 'SAVE_SCRIBE_AS_TEMPLATE_SUCCESS';
export const SAVE_SCRIBE_AS_TEMPLATE_FAILED = 'SAVE_SCRIBE_AS_TEMPLATE_FAILED';

export const CONTENTFUL_OAUTH_SUCCESS = 'CONTENTFUL_OAUTH_SUCCESS';
export const CONTENTFUL_OAUTH_INVALID = 'CONTENTFUL_OAUTH_INVALID';
export const CONTENTFUL_OAUTH_LOADED = 'CONTENTFUL_OAUTH_LOADED';

export const SORT_TEMPLATES = 'SORT_TEMPLATES';
export const FILTER_TEMPLATES_BY_CANVAS_SIZE = 'FILTER_TEMPLATES_BY_CANVAS_SIZE';
export const SCROLL_TEMPLATE_CATEGORIES = 'SCROLL_TEMPLATE_CATEGORIES';

export interface CreateScribeFromTemplateAction {
  type: typeof CREATE_SCRIBE_FROM_TEMPLATE;
  templateId: string;
}

export function createScribeFromTemplate(templateId: string): CreateScribeFromTemplateAction {
  return {
    type: CREATE_SCRIBE_FROM_TEMPLATE,
    templateId
  };
}

export interface CreateScribeFromTemplateSuccessAction {
  type: typeof CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS;
  project: ExistingScribeModel;
}

export function createScribeFromTemplateSuccess(project: ExistingScribeModel): CreateScribeFromTemplateSuccessAction {
  return {
    type: CREATE_SCRIBE_FROM_TEMPLATE_SUCCESS,
    project
  };
}

export function createScribeFromTemplateFailed() {
  return {
    type: CREATE_SCRIBE_FROM_TEMPLATE_FAILED
  };
}

export const getScribeTemplates = () => ({
  type: GET_SCRIBE_TEMPLATES
});

export interface GetScribeTemplateFailedAction {
  type: typeof GET_SCRIBE_TEMPLATES_FAILED;
  error: Error;
}

export const getScribeTemplatesFailed = (error: Error): GetScribeTemplateFailedAction => ({
  type: GET_SCRIBE_TEMPLATES_FAILED,
  error
});

export interface GetScribeTemplatesByCategorySuccessAction {
  type: typeof GET_SCRIBE_TEMPLATES_BY_CATEGORY_SUCCESS;
  categories: Array<ProjectTemplateCategory>;
}

export const getScribeTemplatesByCategorySuccess = (
  categories: Array<ProjectTemplateCategory>
): GetScribeTemplatesByCategorySuccessAction => ({
  type: GET_SCRIBE_TEMPLATES_BY_CATEGORY_SUCCESS,
  categories
});

export interface SaveScribeAsTemplateAction {
  type: typeof SAVE_SCRIBE_AS_TEMPLATE;
  scribeId: number;
}

export const saveScribeAsTemplate = (scribeId: number): SaveScribeAsTemplateAction => ({
  type: SAVE_SCRIBE_AS_TEMPLATE,
  scribeId
});

export const saveScribeAsTemplateSuccess = () => ({
  type: SAVE_SCRIBE_AS_TEMPLATE_SUCCESS
});

export interface SaveScribeAsTemplateFailedAction {
  type: typeof SAVE_SCRIBE_AS_TEMPLATE_FAILED;
  error: Error;
}

export const saveScribeAsTemplateFailed = (error: Error): SaveScribeAsTemplateFailedAction => ({
  type: SAVE_SCRIBE_AS_TEMPLATE_FAILED,
  error
});

export interface ContentfulOauthSuccessAction {
  type: typeof CONTENTFUL_OAUTH_SUCCESS;
  payload: string;
}

export const contentfulOauthSuccess = (accessToken: string): ContentfulOauthSuccessAction => ({
  type: CONTENTFUL_OAUTH_SUCCESS,
  payload: accessToken
});

export interface ContentfulOauthLoadedAction {
  type: typeof CONTENTFUL_OAUTH_LOADED;
  payload: string;
}

export const contentfulOauthLoaded = (accessToken: string): ContentfulOauthLoadedAction => ({
  type: CONTENTFUL_OAUTH_LOADED,
  payload: accessToken
});

export const contentfulOauthInvalid = () => ({
  type: CONTENTFUL_OAUTH_INVALID
});

export interface SortTemplatesAction {
  type: typeof SORT_TEMPLATES;
  sortOrder: SortOrder;
}

export const sortTemplates = (sortOrder: SortOrder): SortTemplatesAction => ({
  type: SORT_TEMPLATES,
  sortOrder
});

export interface FilterByCanvasSizeAction {
  type: typeof FILTER_TEMPLATES_BY_CANVAS_SIZE;
  size: string;
}

export const filterTemplatesByCanvasSize = (size: string): FilterByCanvasSizeAction => ({
  type: FILTER_TEMPLATES_BY_CANVAS_SIZE,
  size
});

export interface ScrollTemplateCategoriesAction {
  type: typeof SCROLL_TEMPLATE_CATEGORIES;
  eventTrigger: string;
}

export const scrollTemplateCategories = (eventTrigger: string): ScrollTemplateCategoriesAction => ({
  type: SCROLL_TEMPLATE_CATEGORIES,
  eventTrigger
});
