export const CREATE_AUTOSAVE_QUEUE = 'CREATE_AUTOSAVE_QUEUE';
export const PICK_PENDING_AUTOSAVE = 'PICK_PENDING_AUTOSAVE';

export interface CreateAutosaveQueueAction {
  type: typeof CREATE_AUTOSAVE_QUEUE;
  projectId: number;
}

export const createAutosaveQueue = (projectId: number): CreateAutosaveQueueAction => ({
  type: CREATE_AUTOSAVE_QUEUE,
  projectId
});

export interface PickPendingAutosaveAction {
  type: typeof PICK_PENDING_AUTOSAVE;
  projectId: number;
}

export const pickPendingAutosave = (projectId: number): PickPendingAutosaveAction => ({
  type: PICK_PENDING_AUTOSAVE,
  projectId
});
