import { AddUserImageToScribeAction } from 'js/actionCreators/imagesActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { call } from 'redux-saga/effects';

export function* updateRecentImages(action: AddUserImageToScribeAction) {
  try {
    yield call(appServices.updateAssetMetadata, action.imageId, { lastUsed: action.actionTime });
  } catch (error) {
    console.error('Failed to update last used date for image', action.imageId, error);
  }
}
