import './ReplaceImageModeBanner.css';

import { Notification } from 'js/authentication/Notification';
import useReplaceImage from 'js/shared/hooks/useReplaceImage';
import React from 'react';

const ReplaceImageModeBanner = () => {
  const { isInReplaceImageMode, cancelReplaceImage } = useReplaceImage();

  if (!isInReplaceImageMode) return null;

  return (
    <div className="ReplaceImageModeBanner">
      <Notification type="warning">
        <div className="ReplaceImageModeBanner__content">
          <span>Select an image to replace your currently selected image</span>
          <button onClick={cancelReplaceImage} className="ReplaceImageModeBanner__linkButton">
            Cancel
          </button>
        </div>
      </Notification>
    </div>
  );
};

export default ReplaceImageModeBanner;
