import React from 'react';

import './EditPanelFieldset.css';

const EditPanelFieldset = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const classes = className ? ` ${className}` : '';

  return <fieldset className={'EditPanelFieldset' + classes}>{children}</fieldset>;
};

export default EditPanelFieldset;
