import { ExistingScribeModel, HomepageProject } from 'js/types';

export const createHomepageProjectFromProject = (project: ExistingScribeModel): HomepageProject => {
  return {
    id: project.id,
    title: project.title,
    createdOn: project.createdOn,
    updatedOn: project.updatedOn,
    canvasSize: project.canvasSize,
    thumbnailImage: project.thumbnailImage,
    viewOnly: project.viewOnly
  };
};

export const replaceHomepageProject = (projects: Array<HomepageProject>, newProject: HomepageProject) => {
  return projects.map(project => (project.id === newProject.id ? newProject : project));
};
