import { addDays, addHours, addMinutes } from 'date-fns';
import { AccountType, AccountTerm } from 'js/types';

export type UserModelProps = {
  email: string;
  firstname?: string;
  surname?: string;
  accountType: AccountType;
  term: AccountTerm | null;
  signupDate?: string;
  trialDaysLeft: number;
  trialHoursLeft: number;
  trialMinutesLeft: number;
} & (
  | {
      uid: string;
    }
  | { id: string }
);

export interface UserTokens {
  IdToken?: string;
  RefreshToken?: string;
}

class UserModel {
  id: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  accountType: AccountType;
  term: AccountTerm | null;
  signupDate?: string;
  token?: string;
  tokenTtl?: string | number;
  userTokens?: UserTokens;
  trialExpiryDate: Date;
  authenticated?: boolean;

  constructor(
    body: UserModelProps,
    { sparkolToken, sparkolTokenTtl }: { sparkolTokenTtl?: string | number; sparkolToken?: string },
    userTokens: UserTokens = {}
  ) {
    this.id = 'uid' in body ? body.uid : body.id;
    this.email = body.email;
    this.firstname = body.firstname;
    this.lastname = body.surname;
    this.accountType = body.accountType;
    this.term = body.term;
    this.token = sparkolToken;
    this.tokenTtl = sparkolTokenTtl;
    this.userTokens = userTokens;
    this.signupDate = body.signupDate;
    this.trialExpiryDate = addMinutes(
      addHours(addDays(new Date(), body.trialDaysLeft), body.trialHoursLeft),
      body.trialMinutesLeft
    );
  }
}

export default UserModel;
