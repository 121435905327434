import uniq from 'lodash.uniq';
import { ExistingScribeModel, PlaybackImageResources, PlaybackScribeModel } from 'js/types';
import { getStore } from 'js/store';
import { uploadResourceToGpu } from 'js/actionCreators/gpuActions';

import { gifLoaderLoadPromise, gifLoaderAdd } from './gifLoader';
import { svgLoaderPromise, svgLoaderAdd, svgLoaderManageResourcesInProject } from './svgLoader';
import { rasterLoaderPromise, rasterLoaderAdd } from './rasterLoader';
import getScribeGifUrls from './getScribeGifUrls';
import getScribeRasterUrls from './getScribeRasterUrls';
import getScribeSvgUrls from './getScribeSvgUrls';
import getScribeCursorUrls from './getScribeCursorUrls';

const resourceLoader = async (
  scribe: PlaybackScribeModel | ExistingScribeModel,
  gpuUpload = false
): Promise<PlaybackImageResources> => {
  svgLoaderManageResourcesInProject(scribe);

  const gifAssets = getScribeGifUrls(scribe);
  const rasterUrls = getScribeRasterUrls(scribe);
  const svgAssets = getScribeSvgUrls(scribe);
  const cursorUrls = getScribeCursorUrls(scribe);

  await Promise.all([
    ...uniq(gifAssets).map(asset => {
      return gifLoaderAdd(asset);
    }),
    ...uniq([...rasterUrls, ...cursorUrls]).map(url => {
      return rasterLoaderAdd(url);
    }),
    ...uniq(svgAssets).map(asset => {
      return svgLoaderAdd(asset);
    })
  ]);

  const [gifResources, rasterResources, svgResources] = await Promise.all([
    gifLoaderLoadPromise(),
    rasterLoaderPromise(),
    svgLoaderPromise()
  ]);

  const imageResources = {
    gifResources,
    rasterResources,
    svgResources
  };

  if (gpuUpload) {
    const { dispatch } = getStore();
    dispatch(uploadResourceToGpu(scribe));
  }

  return imageResources;
};

export default resourceLoader;
