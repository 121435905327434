import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';

export const getPositionalProperties = (element: ScribeShapeElementModel | ScribeImageElementModel) => ({
  scaleX: element.scaleX,
  scaleY: element.scaleY,
  top: element.y,
  left: element.x,
  angle: element.angle,
  width: element.width,
  height: element.height,
  flipX: element.flipX,
  flipY: element.flipY
});
