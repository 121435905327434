import './TabContentWrapper.css';

import React, { useRef, useEffect, CSSProperties, DragEvent, useLayoutEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import useInfiniteScroll from 'js/shared/hooks/useInfiniteScroll';
import Skeleton from 'js/shared/components/Skeleton';
import { IMAGE_LIBRARY_RESULT_NUMBER_PER_PAGE } from 'js/config/config';
import useUserWithPermissions from 'js/permissions/useUserWithPermissions';
import { AccountContentKey } from 'js/types';
import { SCOPE } from 'js/config/consts';
import { useFeature } from '@optimizely/react-sdk';
import { PREMIUM_CONTENT_REWORK } from 'js/config/featureKeys';
import { useDispatch } from 'react-redux';
import { showPremiumFeatureModal } from 'js/actionCreators/uiActions';
import { UpgradeSubscriptionClickedEventTrigger } from 'js/actionCreators/trackingActions';

import ImageThumbnail from '../ImageThumbnail';

type TabContentWrapperProps = {
  onLoadMore: () => void;
  emptyState?: React.ReactNode;
  images?: { id: string | number; src: string; isPremium?: boolean; canChangeColor?: boolean }[];
  isLoading?: boolean;
  visible: boolean;
  onClickImage?: (imageId: string | number) => void;
  onDragImage?: (e: DragEvent<HTMLButtonElement>, imageId: string | number) => void;
  onDeleteImage?: (imageId: string | number) => void;
  searchInput?: string;
  categoryId?: string | null;
};

const TabContentWrapper: React.FC<TabContentWrapperProps> = ({
  onLoadMore,
  emptyState,
  images = [],
  isLoading = false,
  visible,
  onClickImage,
  onDragImage,
  onDeleteImage,
  searchInput,
  categoryId
}) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const lastElementRef = useRef<HTMLLIElement>(null);
  const visibilityStyle = visible ? undefined : ({ display: 'none' } as CSSProperties);
  const user = useUserWithPermissions();
  const userCanUsePremiumContent = user.can([SCOPE.usePremiumContent]);
  const [premiumContentReworkFeatureEnabled] = useFeature(PREMIUM_CONTENT_REWORK);
  const dispatch = useDispatch();

  useInfiniteScroll(listContainerRef, lastElementRef, images.length, () => {
    if (!isLoading) {
      onLoadMore();
    }
  });

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, [images.length]);

  const showEmptyState = images.length === 0 && !isLoading;

  useEffect(() => {
    listContainerRef.current?.scrollTo(0, 0);
  }, [searchInput, categoryId]);

  return (
    <div className="TabContentWrapper" style={visibilityStyle}>
      <div className="TabContentWrapper__content" ref={listContainerRef}>
        {showEmptyState ? (
          emptyState
        ) : (
          <ul className="TabContentWrapper__image-list">
            {images.map(({ id, src, isPremium, canChangeColor }, imageIndex) => {
              let premiumTooltip = '';
              let handlePremiumIconClick;
              if (isPremium && !userCanUsePremiumContent && premiumContentReworkFeatureEnabled) {
                premiumTooltip = user.getContent(AccountContentKey.PREMIUM_CONTENT_IMAGE_LIBRARY_UPSELL_MSG);
                handlePremiumIconClick = () => {
                  dispatch(
                    showPremiumFeatureModal(
                      'Use premium images in your project',
                      user.getContent(AccountContentKey.PREMIUM_CONTENT_UPSELL_MSG),
                      UpgradeSubscriptionClickedEventTrigger.PREMIUM_THUMBNAIL_INDICATOR
                    )
                  );
                };
              }

              const isLastElement = imageIndex === images.length - 1;
              const ref = isLastElement ? lastElementRef : null;

              return (
                <ImageThumbnail
                  ref={ref}
                  key={id}
                  src={src}
                  id={id}
                  onClickImage={() => onClickImage?.(id)}
                  onDragStart={(e: DragEvent<HTMLButtonElement>) => onDragImage?.(e, id)}
                  premiumTooltip={premiumTooltip}
                  canChangeColor={canChangeColor}
                  onPremiumIconClick={handlePremiumIconClick}
                  onConfirmDeleteClick={onDeleteImage}
                />
              );
            })}
            {isLoading &&
              Array.from(Array(IMAGE_LIBRARY_RESULT_NUMBER_PER_PAGE).keys()).map((_, index) => (
                <li key={index} className="TabContentWrapper__skeleton-wrapper">
                  <Skeleton variant="rect" width={'100%'} height={'100%'} />
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TabContentWrapper;
