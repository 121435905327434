import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import isNumber from 'lodash.isnumber';
import { getScribeById } from 'js/sagas/selectors';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import {
  loadCursors,
  loadScribeById,
  LOAD_SCRIBE_BY_ID_SUCCESS,
  UPDATE_SCRIBE_SUCCESS
} from 'js/actionCreators/scribeActions';
import { LOAD_CURSORS_SUCCESS } from 'js/actionCreators/loadCursorsSuccessAction';
import { UPDATE_BITMAP_FONTS_DONE } from 'js/actionCreators/fontActions';
import {
  getScribeForPlaybackError,
  getScribeForPlaybackSuccess,
  GET_SCRIBE_FOR_RENDER,
  PLAY_FROM_EDITOR
} from 'js/actionCreators/playbackActions';
import { sendErrorToSentry } from 'js/logging';
import { rendererLog, rendererFatalFailure } from 'js/shared/lib/CloudRenderer';
import getCursorsResources from 'js/sagas/sagaHelpers/getCursorsResources';
import getCursorTimings from 'js/sagas/sagaHelpers/getCursorTimings';
import { showError } from 'js/actionCreators/uiActions';
import { SAVE_AUDIO_FAILED, SAVE_AUDIO_SUCCESS } from 'js/actionCreators/audioActions';
import { UPLOAD_RESOURCE_TO_GPU_COMPLETE } from 'js/actionCreators/gpuActions';

import transformProjectForRendering from './sagaHelpers/transformProjectForRendering';
import { getViewportData } from './sagaHelpers/getViewportData';
import { getCachedUrlsForAudioClips } from './sagaHelpers/getCachedUrlsForAudioClips';
import { cacheImagesForPlayback } from './sagaHelpers/cacheImagesForPlayback';

export function* getScribeForPlayback({ scribeId, isRender = false, startTimeMs, durationMs }) {
  try {
    rendererLog(`Start load of all project assets`);
    // If we are recording audio when the user starts a playback via a click then we should wait until the audio has finished
    // processing before we continue
    const isAudioRecording = yield select(state => state.ui.isAudioPanelBusy);
    if (isAudioRecording) {
      const saveAudioAction = yield take([SAVE_AUDIO_SUCCESS, SAVE_AUDIO_FAILED]);
      if (saveAudioAction.type === SAVE_AUDIO_SUCCESS) {
        yield take(UPDATE_SCRIBE_SUCCESS);
      }
    }

    const { isUpdatingBitmapFonts } = yield select(state => state.fonts);
    if (isUpdatingBitmapFonts) yield take(UPDATE_BITMAP_FONTS_DONE);

    // Using JSON parse/stringify here as the scribe at this point the scribe is a JS Class Instance. We want to make sure we're passing only
    // data to the react component so using this method to get it. Lodash clonedeep will just give us a new class instance which is why it's not used.
    let scribeData = yield select(state => JSON.parse(JSON.stringify(getScribeById(state, scribeId))));

    const brokenImagesInProject = scribeData.elements
      .filter(element => element.type === 'Image')
      .filter(element => typeof element.image.assetId === 'string' && !element._imageUrl)
      .map(element => element.image.assetId);
    if (brokenImagesInProject.length > 0) {
      throw new Error(
        'Your project contains one or more broken image files. Replace the broken images on the canvas to download your project.',
        { cause: { assetIds: brokenImagesInProject } }
      );
    }

    if (isRender && isNumber(startTimeMs) && isNumber(durationMs)) {
      // Strip the project of data which isn't going to be rendered in this time segment
      scribeData = transformProjectForRendering({ project: scribeData, startTimeMs, durationMs });
    }

    scribeData.cursorData = {
      resourcesAndMetadata: yield call(getCursorsResources, scribeData),
      timings: getCursorTimings(scribeData)
    };
    scribeData.viewport = getViewportData(scribeData.canvasSize);

    const imageElements = scribeData.elements?.filter(el => el.type === 'Image');
    const imageAssetIds = imageElements
      .filter(imgEl => !imgEl._imageUrl && !!imgEl.image?.assetId && typeof imgEl.image.assetId === 'number')
      .map(imgEl => imgEl.image.assetId);
    const audioAssetIds = scribeData.audioClips?.filter(clip => !!clip.assetId).map(clip => clip.assetId) || [];
    const assetIds = isRender ? imageAssetIds : [...imageAssetIds, ...audioAssetIds];

    let urlResources = [];
    if (assetIds.length) {
      try {
        urlResources = yield call(appServices.getAssetUrls, assetIds);
      } catch (error) {
        console.error(error);
        throw new Error(`FAILED: fetching signed URLs from services - Error: ${error.message}`);
      }

      if (scribeData.audioClips?.length !== 0 && !isRender) {
        scribeData.audioClips = getCachedUrlsForAudioClips(scribeData.audioClips, urlResources);
      }

      if (imageElements.length) {
        yield cacheImagesForPlayback(imageElements, urlResources);
      }
    }

    const uploadingToGPU = yield select(state => state.playback.uploadingResourceToGpu);
    if (uploadingToGPU) {
      yield take(UPLOAD_RESOURCE_TO_GPU_COMPLETE);
    }

    yield put(getScribeForPlaybackSuccess(scribeData));
    rendererLog(`SUCCESS: Project assets all loaded`);
  } catch (error) {
    rendererFatalFailure(`Failed to load project data: ${error.message}`);
    console.error(error);
    sendErrorToSentry(error);
    yield put(getScribeForPlaybackError(error.message));
    yield put(
      showError({ message: 'There was a problem loading your project for playback', description: error.message })
    );
  }
}

export function* getScribeForRender({ scribeId, startTimeMs, durationMs }) {
  yield all([put(loadScribeById(scribeId)), put(loadCursors())]);
  yield all([take(LOAD_SCRIBE_BY_ID_SUCCESS), take(LOAD_CURSORS_SUCCESS)]);
  yield call(getScribeForPlayback, { scribeId, isRender: true, startTimeMs, durationMs });
}

export default function* playbackSagas() {
  yield takeLatest(PLAY_FROM_EDITOR, getScribeForPlayback);
  yield takeLatest(GET_SCRIBE_FOR_RENDER, getScribeForRender);
}
