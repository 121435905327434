import cloneDeep from 'lodash.clonedeep';
import uuidv4 from 'uuid/v4';

function replaceImageUrlInElement(newActiveScribe, elementId, assetId) {
  const activeElementIndex = newActiveScribe.elements.findIndex(element => element.id === elementId);
  if (activeElementIndex === -1) return newActiveScribe;

  const activeElement = newActiveScribe.elements[activeElementIndex];

  const newElement = cloneDeep(activeElement);

  newElement.image.assetId = assetId;
  newElement.image.provider = 'user';
  newElement._imageLoaded = true;
  newElement._imageLoading = false;

  newActiveScribe.elements.splice(activeElementIndex, 1, newElement);

  return newActiveScribe;
}

export function replaceImageUrlInScribe(activeScribe, originalAssetId, assetId, signedImgUrl) {
  let newActiveScribe = cloneDeep(activeScribe);

  const elementsToUpdate = newActiveScribe.elements.filter(
    element => element.type === 'Image' && element.image.assetId === originalAssetId
  );

  for (const element of elementsToUpdate) {
    newActiveScribe = replaceImageUrlInElement(newActiveScribe, element.id, assetId, signedImgUrl);
  }

  return newActiveScribe;
}

export function replaceImageUrlInHistory(activeScribeHistory, assetId, signedImgUrl, originalAssetId) {
  let activeScribeHistoryObjectsToUpdate = {};
  const newActiveScribeHistory = cloneDeep(activeScribeHistory);

  newActiveScribeHistory.map(async (scribeHistory, scribeHistoryIndex) => {
    const newActiveScribe = replaceImageUrlInScribe(scribeHistory, originalAssetId, assetId, signedImgUrl);

    activeScribeHistoryObjectsToUpdate[scribeHistoryIndex] = newActiveScribe;
  });

  for (const [scribeHistoryObjectIndex, newScribeHistory] of Object.entries(activeScribeHistoryObjectsToUpdate)) {
    newActiveScribeHistory.splice(scribeHistoryObjectIndex, 1, newScribeHistory);
  }

  return newActiveScribeHistory;
}

export function appliesBrokenImage(newElement, newId) {
  delete newElement._imageUrl;
  delete newElement.image.imgUrl;
  delete newElement.recolorAvailable;
  delete newElement._recolorDefaults;
  newElement._imageLoaded = false;
  newElement._imageLoading = false;
  newElement.id = newId;

  return newElement;
}

async function updateHistoryElement(newActiveScribe, elementId, historyIdsToUpdate) {
  const sceneToUpdate = newActiveScribe.scenes.find(scene => scene.elementIds.includes(elementId));

  const activeElementIndex = newActiveScribe.elements.findIndex(element => element.id === elementId);
  if (activeElementIndex === -1) return newActiveScribe;

  const activeElement = newActiveScribe.elements[activeElementIndex];

  const newId = uuidv4();

  historyIdsToUpdate.push({ oldElementId: activeElement.id, newElementId: newId });

  const newElement = appliesBrokenImage(cloneDeep(activeElement), newId);

  const oldElementIdIndex = sceneToUpdate.elementIds.findIndex(elId => elId === elementId);

  newActiveScribe.elements.splice(activeElementIndex, 1, newElement);
  sceneToUpdate.elementIds[oldElementIdIndex] = newElement.id;

  return { newActiveScribe: newActiveScribe, historyIdsToUpdate: historyIdsToUpdate };
}

export async function replaceImageUrlBrokenImageInScribe(activeScribe, originalAssetId) {
  let historyIdsToUpdate = [];
  let newActiveScribe = cloneDeep(activeScribe);
  let result;

  const elementsToUpdate = newActiveScribe.elements.filter(
    element => element.type === 'Image' && element.image.assetId === originalAssetId
  );

  for (const element of elementsToUpdate) {
    result = await updateHistoryElement(newActiveScribe, element.id, historyIdsToUpdate);
  }

  return { newActiveScribe: result.newActiveScribe, historyIdsToUpdate: result.historyIdsToUpdate };
}

export async function replaceBrokenImageUrlInHistory(activeScribeHistory, historyIdsToUpdate) {
  let activeScribeHistoryObjectsToUpdate = {};
  const newActiveScribeHistory = cloneDeep(activeScribeHistory);

  await Promise.all(
    newActiveScribeHistory.map(async (scribeHistory, scribeHistoryIndex) => {
      let newActiveScribe = cloneDeep(scribeHistory);

      historyIdsToUpdate.forEach(async (row, rowIndex) => {
        const elementToUpdate = newActiveScribe.elements.find(el => el.id === row.oldElementId);

        if (elementToUpdate) {
          const newElement = appliesBrokenImage(cloneDeep(elementToUpdate), row.newElementId);

          newActiveScribe.elements.splice(rowIndex, 1, newElement);
        }
      });

      activeScribeHistoryObjectsToUpdate[scribeHistoryIndex] = newActiveScribe;
    })
  );

  for (const [scribeHistoryObjectIndex, newScribeHistory] of Object.entries(activeScribeHistoryObjectsToUpdate)) {
    newActiveScribeHistory.splice(scribeHistoryObjectIndex, 1, newScribeHistory);
  }

  return newActiveScribeHistory;
}
