import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const SCROLL_SPEED = 700;

export const useCarousel = (
  children: React.ReactNode,
  sliderRef: React.RefObject<HTMLDivElement>,
  trackRef: React.RefObject<HTMLDivElement>,
  onScrollLeft?: () => void,
  onScrollRight?: () => void,
  onShowControls?: (showControls: boolean) => void,
  margin = 0
) => {
  const [showControls, setShowControls] = useState(true);
  const [leftOverflowing, setLeftOverflowing] = useState(false);
  const [rightOverflowing, setRightOverflowing] = useState(false);

  const customScrollBy = (element: HTMLElement, distance: number, duration: number) => {
    const start = element.scrollLeft;
    const startTime = performance.now();

    const easeInOutCubic = (x: number): number => {
      return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    };

    const scrollStep = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easedProgress = easeInOutCubic(progress);
      element.scrollLeft = start + distance * easedProgress;

      if (progress < 1) {
        requestAnimationFrame(scrollStep);
      }
    };

    requestAnimationFrame(scrollStep);
  };

  const handleScrollLeft = () => {
    if (sliderRef.current) {
      const { width } = sliderRef.current.getBoundingClientRect();
      customScrollBy(sliderRef.current, -width / 2, SCROLL_SPEED);
      onScrollLeft?.();
    }
  };

  const handleScrollRight = () => {
    if (sliderRef.current) {
      const { width } = sliderRef.current.getBoundingClientRect();
      customScrollBy(sliderRef.current, width / 2, SCROLL_SPEED);
      onScrollRight?.();
    }
  };

  useEffect(() => {
    const showControlsIfNeeded = debounce(() => {
      if (trackRef.current && sliderRef.current) {
        const { width: sliderWidth } = sliderRef.current.getBoundingClientRect();
        const childElements = trackRef.current.children;
        const childrenWidth = Array.from(childElements).reduce((acc, child) => {
          const { width } = child.getBoundingClientRect();
          return acc + width + margin;
        }, 0);

        const show = childrenWidth > sliderWidth;
        setShowControls(show);
        onShowControls?.(show);
      }
    }, 200);

    showControlsIfNeeded();
    window.addEventListener('resize', showControlsIfNeeded);

    return () => {
      window.removeEventListener('resize', showControlsIfNeeded);
    };
  }, [children, margin, onShowControls, sliderRef, trackRef]);

  useEffect(() => {
    if (sliderRef.current && trackRef.current) {
      const observerOptions = {
        root: sliderRef.current,
        threshold: 1,
        rootMargin: `0px ${margin}px`
      };
      const overflowRightObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setRightOverflowing(!entry.isIntersecting);
        });
      }, observerOptions);
      const overflowLeftObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setLeftOverflowing(!entry.isIntersecting);
        });
      }, observerOptions);
      const childrenElements = Array.from(trackRef.current.children);
      const firstChild = childrenElements.at(0);
      const lastChild = childrenElements.at(-1);
      if (firstChild) {
        overflowLeftObserver.observe(firstChild);
      }
      if (lastChild) {
        overflowRightObserver.observe(lastChild);
      }
      return () => {
        if (firstChild) {
          overflowLeftObserver.unobserve(firstChild);
        }
        if (lastChild) {
          overflowRightObserver.unobserve(lastChild);
        }
      };
    }
  }, [children, margin, showControls, sliderRef, trackRef]);

  return {
    handleScrollLeft,
    handleScrollRight,
    showControls,
    leftOverflowing,
    rightOverflowing
  };
};
