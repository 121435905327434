import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';

import CircleElement from '../CircleElement';
import EllipseElement from '../EllipseElement';
import SquareElement from '../SquareElement';
import TriangleElement from '../TriangleElement';

const createShapeElement = (scribeElement: ScribeShapeElementModel) => {
  let shapeObj = null;

  switch (scribeElement.shapeType) {
    case 'circle':
      shapeObj = new CircleElement(scribeElement);
      break;
    case 'ellipse': // We no longer offer ellipse as a specific shape type but we should leave this in for existing documents
      shapeObj = new EllipseElement(scribeElement);
      break;
    case 'triangle':
      shapeObj = new TriangleElement(scribeElement);
      break;
    default:
      shapeObj = new SquareElement(scribeElement);
      break;
  }

  return shapeObj;
};

export default createShapeElement;
