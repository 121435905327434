import { put, select, takeEvery } from '@redux-saga/core/effects';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import {
  SET_ELEMENT_FILL,
  SET_ELEMENT_GRADIENT_TYPE,
  SET_ELEMENT_OPACITY,
  SET_ELEMENT_GRADIENT_COLOR,
  SET_ELEMENT_COLOR,
  SET_ELEMENT_UNLOCKED_RATIO,
  SET_ELEMENT_FLIP_X,
  SET_ELEMENT_FLIP_Y,
  SET_ELEMENT_TEXT_ALIGN,
  SET_ELEMENT_TEXT_BOLD,
  SET_ELEMENT_TEXT_ITALIC,
  SET_ELEMENT_TEXT_FONT,
  SET_ELEMENT_TEXT_SIZE,
  SET_ELEMENT_IMAGE_RECOLOR,
  CLEAR_ELEMENT_IMAGE_RECOLOR,
  SET_ELEMENT_TWEEN,
  SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX,
  UPDATE_TEXT_FIELD_DIMENSIONS
} from 'js/actionCreators/elementActions';
import { updateScribe, setCustomTextStyle } from 'js/actionCreators/scribeActions';
import { updateBitmapFonts } from 'js/actionCreators/fontActions';
import {
  FONT_ALLOW_DECIMAL,
  FONT_STEP,
  INDETERMINATE_DISPLAY_VALUE,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE
} from 'js/config/defaults';
import { getIncrementedValue } from 'js/shared/helpers/getIncrementedValue';
import { getDecrementedValue } from 'js/shared/helpers/getDecrementedValue';
import { ALIGN_SELECTED_ELEMENTS, DISTRIBUTE_SELECTED_ELEMENTS } from 'js/actionCreators/elementAlignmentActions';

import { getScribeById } from './selectors';
import processImageOnChange from './sagaHelpers/processImageOnChange';
import { elementAlignment, elementDistribution } from './elementAlignmentSaga';

export function* updateElementProperty({ scribeId, elementIds = [], update }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const elementsIdsToUpdate = Array.isArray(elementIds) ? elementIds : [elementIds];
  let updatedScribe = cloneDeep(scribe);
  let elementsToUpdate = updatedScribe.elements.filter(el => elementsIdsToUpdate.includes(el.id));

  if (!elementsToUpdate.length) return;

  const textElements = elementsToUpdate.filter(element => element.type === 'Text');
  const isTextboxEditing = yield select(({ scribes }) => scribes.isTextboxEditing);
  const haveTextElementToCustomise = textElements.length === 1 && update.hasOwnProperty('fill');
  const isCustomisingTextElement = haveTextElementToCustomise && isTextboxEditing;

  // Trigger Fabric to update characters' colour on the canvas and update the model from there
  if (isCustomisingTextElement) return yield put(setCustomTextStyle({ fill: update.fill }));

  elementsToUpdate.forEach(element => {
    const updateIndex = updatedScribe.elements.findIndex(el => el.id === element.id);
    updatedScribe.elements[updateIndex] = { ...element, ...update };
  });

  const defaultConfig = textElements.length === 1 ? textElements[0] : updatedScribe.settings.textStylingConfig;
  updatedScribe.settings.textStylingConfig = {
    font: update.font ?? defaultConfig.font,
    align: update.align ?? defaultConfig.align,
    fontWeight: update.fontWeight ?? defaultConfig.fontWeight,
    fontStyle: update.fontStyle ?? defaultConfig.fontStyle,
    fill: update.fill ?? defaultConfig.fill,
    opacity: update.opacity ?? defaultConfig.opacity,
    fontSize: updatedScribe.settings.textStylingConfig.fontSize
  };

  yield put(updateScribe(updatedScribe));

  // Trigger Fabric to update all texts' colour on the canvas and update the model from there
  const haveTextElementsToCustomise = textElements.length > 0 && update.hasOwnProperty('fill');
  if (haveTextElementsToCustomise) yield put(setCustomTextStyle({ fill: update.fill }));

  const requireUpdateBitmapData =
    update.font !== undefined || update.fontWeight !== undefined || update.fontStyle !== undefined;
  if (requireUpdateBitmapData) {
    yield put(updateBitmapFonts(updatedScribe.elements.filter(element => element.type === 'Text')));
  }
}

export function* setElementImageRecolor({ scribeId, elementId, layer, value }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const updatedScribe = cloneDeep(scribe);
  const elementToUpdate = updatedScribe.elements.find(el => el.id === elementId);

  if (!elementToUpdate) return;

  if (!elementToUpdate.image.recolor) elementToUpdate.image.recolor = {};
  elementToUpdate.image.recolor[layer] = value;

  yield processImageOnChange(elementToUpdate);

  yield put(updateScribe(updatedScribe));
}

export function* clearElementImageRecolor({ scribeId, elementId }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const updatedScribe = cloneDeep(scribe);
  const elementToUpdate = updatedScribe.elements.find(el => el.id === elementId);

  if (!elementToUpdate) return;

  if (isEqual(elementToUpdate.image.recolor, elementToUpdate._recolorDefaults)) return;

  elementToUpdate.image.recolor = { ...elementToUpdate._recolorDefaults };

  yield processImageOnChange(elementToUpdate);

  yield put(updateScribe(updatedScribe));
}

export function* toggleSvgViewportSetting({ scribeId, elementId, useContentSvgViewbox }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const updatedScribe = cloneDeep(scribe);
  const elementToUpdate = updatedScribe.elements.find(el => el.id === elementId);

  if (!elementToUpdate) return;
  elementToUpdate.useContentSvgViewbox = useContentSvgViewbox;
  yield processImageOnChange(elementToUpdate, true);
  yield put(updateScribe(updatedScribe));
}

export function* updateTextFieldDimensions({ scribeId, updatedElementsConfig }) {
  const scribe = yield select(getScribeById, scribeId);
  if (!scribe) return;
  const updatedScribe = cloneDeep(scribe);

  for (let { elementId, width, height, charBounds, lineWidths } of updatedElementsConfig) {
    const updatedElement = updatedScribe.elements.find(el => el.id === elementId);
    if (!updatedElement) continue;
    updatedElement.width = width;
    updatedElement.height = height;
    updatedElement.charBounds = charBounds;
    updatedElement.lineWidths = lineWidths;
  }
  yield put(updateScribe(updatedScribe, true));
}

export function* updateTextElementFontSize({ scribeId, elementIds = [], update, incrementValue, decrementValue }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const elementsIdsToUpdate = Array.isArray(elementIds) ? elementIds : [elementIds];
  const updatedScribe = cloneDeep(scribe);
  const elementsToUpdate = updatedScribe.elements.filter(el => elementsIdsToUpdate.includes(el.id));

  if (!elementsToUpdate.length) return;

  if (update.fontSize === INDETERMINATE_DISPLAY_VALUE) {
    elementsToUpdate.forEach(element => {
      const updateIndex = updatedScribe.elements.findIndex(el => el.id === element.id);

      if (incrementValue) {
        const originalFontSize = element.fontSize;

        const roundedValue = getIncrementedValue(originalFontSize, FONT_STEP, MAX_FONT_SIZE, FONT_ALLOW_DECIMAL);

        updatedScribe.elements[updateIndex].fontSize = roundedValue;
      }

      if (decrementValue) {
        const originalFontSize = element.fontSize;

        const roundedValue = getDecrementedValue(originalFontSize, FONT_STEP, MIN_FONT_SIZE, FONT_ALLOW_DECIMAL);

        updatedScribe.elements[updateIndex].fontSize = roundedValue;
      }
    });
  } else {
    elementsToUpdate.forEach(element => {
      const updateIndex = updatedScribe.elements.findIndex(el => el.id === element.id);
      updatedScribe.elements[updateIndex] = { ...element, ...update };
    });

    updatedScribe.settings.textStylingConfig = {
      ...updatedScribe.settings.textStylingConfig,
      fontSize: update.fontSize ?? elementsToUpdate[0].fontSize
    };
  }

  yield put(updateScribe(updatedScribe));
}

export default function* elementSagas() {
  yield takeEvery(
    [
      SET_ELEMENT_OPACITY,
      SET_ELEMENT_FILL,
      SET_ELEMENT_GRADIENT_TYPE,
      SET_ELEMENT_GRADIENT_COLOR,
      SET_ELEMENT_COLOR,
      SET_ELEMENT_UNLOCKED_RATIO,
      SET_ELEMENT_FLIP_X,
      SET_ELEMENT_FLIP_Y,
      SET_ELEMENT_TEXT_ALIGN,
      SET_ELEMENT_TEXT_BOLD,
      SET_ELEMENT_TEXT_ITALIC,
      SET_ELEMENT_TEXT_FONT,
      SET_ELEMENT_TWEEN
    ],
    updateElementProperty
  );
  yield takeEvery(SET_ELEMENT_IMAGE_RECOLOR, setElementImageRecolor);
  yield takeEvery(CLEAR_ELEMENT_IMAGE_RECOLOR, clearElementImageRecolor);
  yield takeEvery(SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX, toggleSvgViewportSetting);
  yield takeEvery(SET_ELEMENT_TEXT_SIZE, updateTextElementFontSize);
  yield takeEvery(ALIGN_SELECTED_ELEMENTS, elementAlignment);
  yield takeEvery(DISTRIBUTE_SELECTED_ELEMENTS, elementDistribution);
  yield takeEvery(UPDATE_TEXT_FIELD_DIMENSIONS, updateTextFieldDimensions);
}
