import { ScribeAudioLayerModel } from 'js/models/ScribeAudioLayerModel';
import * as PIXI from 'pixi.js';
import { AdvancedAudioType, AudioClip } from 'js/types';
import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import getScribeLengthMs from 'js/playback/lib/Playback/helpers/getScribeLengthMs';

import TimelineAudioClip from './TimelineAudioClip';
import TimelineWrapper, { TIMELINE_LAYER_BACKGROUND_COLOUR } from './TimelineWrapper';
import TimelineAdvancedAudioManager from './TimelineAdvancedAudioManager';
import timelineBus, { AUDIO_BACKGROUND_LAYER_CLICKED } from './TimelineControlBus';
import { getBitmapRect } from './Utils/GetBitmapRect';
import { LABEL_WIDTH } from './SceneLabels';

export default class TimelineAdvancedAudioLayer extends PIXI.Container {
  type: AdvancedAudioType = 'scene';
  maskGraphics: PIXI.Graphics;

  audioLayerModel: ScribeAudioLayerModel;
  wrapperRef: TimelineWrapper;
  timelineAudioClips: Array<TimelineAudioClip>;
  background: PIXI.Graphics;

  currentDragged?: TimelineAudioClip;
  dragOffset?: PIXI.Point;
  managerRef: TimelineAdvancedAudioManager;
  knockbackGfx?: PIXI.Graphics;

  constructor(
    audioLayerModel: ScribeAudioLayerModel,
    wrapperRef: TimelineWrapper,
    type: AdvancedAudioType,
    audioLayerManagerRef: TimelineAdvancedAudioManager
  ) {
    super();
    this.managerRef = audioLayerManagerRef;
    this.type = type;

    this.wrapperRef = wrapperRef;
    this.audioLayerModel = audioLayerModel;
    this.timelineAudioClips = [];
    this.maskGraphics = new PIXI.Graphics();
    this.addChild(this.maskGraphics);
    this.background = new PIXI.Graphics();
    this.background.interactive = true;
    this.background.on('click', () => {
      timelineBus.emit(AUDIO_BACKGROUND_LAYER_CLICKED, this);
    });
    this.addChild(this.background);

    this.updateLayer(audioLayerModel);
  }
  get props() {
    return this.wrapperRef.props;
  }
  get scribeModel() {
    return this.props.activeScribe;
  }
  get sceneOffsets() {
    if (!this.scribeModel) return { startTime: 0, endTime: 0 };
    const scene = getActiveScene(this.scribeModel);
    return getSceneStartEndTimes(this.scribeModel, scene);
  }
  updateLayer(layerModel: ScribeAudioLayerModel) {
    this.audioLayerModel = layerModel;
    this.audioLayerModel.audioClipIds.forEach(audioClipId => {
      if (!this.timelineAudioClips.find(audioClip => audioClip.audioClip.id === audioClipId)) {
        const audioClip = this.scribeModel.audioClips?.find(clip => clip.id === audioClipId);
        if (audioClip) {
          this.addClip(audioClip);
        }
      }
    });
    this.timelineAudioClips.forEach(timelineAudioClip => {
      if (timelineAudioClip.audioClip.id) {
        if (!layerModel.audioClipIds.includes(timelineAudioClip.audioClip.id)) {
          this.timelineAudioClips = this.timelineAudioClips.filter(clip => clip !== timelineAudioClip);
          this.removeChild(timelineAudioClip);
        } else {
          const clipModel = layerModel
            .getAudioClips(this.scribeModel)
            .find(clip => clip.id === timelineAudioClip.audioClip.id);
          timelineAudioClip.updateProps(clipModel);
          if (clipModel?.id === this.wrapperRef.props.selectedAudioClip?.id) {
            timelineAudioClip.showSelected();
          }
        }
      }
    });

    this.updateBackground();
  }
  addClip(audioClip: AudioClip) {
    if (!audioClip || !audioClip?.id) return;
    let timelineAudioClip;
    if (this.managerRef.clipPool[audioClip.id]) {
      timelineAudioClip = this.managerRef.clipPool[audioClip.id];
      timelineAudioClip.updateProps(audioClip);
    } else {
      timelineAudioClip = new TimelineAudioClip(
        audioClip,
        this.wrapperRef,
        this.props.onAudioLengthChanged,
        this.props.onAudioStartOffsetChanged
      );
      this.managerRef.clipPool[audioClip.id] = timelineAudioClip;
    }

    this.timelineAudioClips.push(timelineAudioClip);
    this.wrapperRef.audioClipManager?.registerClip(timelineAudioClip);
    this.addChild(timelineAudioClip);
    if (audioClip?.id === this.wrapperRef.props.selectedAudioClip?.id) {
      timelineAudioClip.showSelected();
    }
  }

  updateZoom(zoom: PIXI.Point) {
    this.timelineAudioClips.forEach(clip => {
      clip.updateZoom(zoom);
    });
    this.updateBackground();
  }

  updateBackground() {
    let width = Math.max(
      this.wrapperRef.currentZoom.x * (getScribeLengthMs(this.wrapperRef.props.activeScribe) / 1000),
      this.wrapperRef.app.view.width
    );
    width += LABEL_WIDTH;
    const height = this.wrapperRef.currentZoom.y - 1;
    if (this.background.width !== width || this.background.height !== height) {
      this.background.clear();

      this.background.beginFill(TIMELINE_LAYER_BACKGROUND_COLOUR, 1);
      this.background.drawRect(0, 0, width, height);
    }

    if (this.type === 'scene') {
      const selectionPadding = 1;
      const maskX = (this.sceneOffsets.startTime / 1000) * this.wrapperRef.currentZoom.x;
      const maskY = -selectionPadding;
      const maskWidth = width;
      //((this.sceneOffsets.endTime - this.sceneOffsets.startTime) / 1000) * this.wrapperRef.currentZoom.x;
      const maskHeight = this.wrapperRef.currentZoom.y + 2 * selectionPadding;
      if (
        this.maskGraphics.x !== maskX ||
        this.maskGraphics.y !== maskY ||
        this.maskGraphics.width !== maskWidth ||
        this.maskGraphics.height !== maskHeight
      ) {
        this.maskGraphics.clear();
        this.maskGraphics.beginFill(0xffffff, 1);
        this.maskGraphics.drawRect(maskX, maskY, maskWidth, maskHeight);
      }
      this.addChild(this.maskGraphics);
      this.mask = this.maskGraphics;
    } else {
      const scribeLength =
        this.wrapperRef.currentZoom.x * (getScribeLengthMs(this.wrapperRef.props.activeScribe) / 1000);
      if (!this.knockbackGfx) {
        this.knockbackGfx = getBitmapRect(this.wrapperRef.currentZoom.y, 0x000000);
      }
      this.knockbackGfx.height = this.wrapperRef.currentZoom.y;
      this.knockbackGfx.width = width;
      this.knockbackGfx.alpha = 0.5;
      this.knockbackGfx.x = scribeLength;
      this.knockbackGfx.y = 0;
      this.addChild(this.knockbackGfx);
    }
  }
}
