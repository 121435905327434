import React from 'react';
import brokenBob from 'imgs/broken-image.svg';

import refreshIcon from './refresh-icon.svg';

import './ErrorBoundaryView.css';

const ErrorBoundaryBackground = ({ children }) => {
  return <div className="ErrorBoundaryView">{children}</div>;
};

const getErrorMessage = error => {
  // Ref: https://github.com/vitejs/vite/issues/11804
  const chunkLoadErrorMessages = [
    'Failed to fetch dynamically imported module', // Chrome, Opera, Edge
    'Importing a module script failed', // Safari
    'error loading dynamically imported module' // Firefox
  ];
  const isChunkLoadError = chunkLoadErrorMessages.some(message =>
    error.message.toLowerCase().includes(message.toLowerCase())
  );
  const title = isChunkLoadError ? 'Good news, a new app version is available!' : 'Oops, there’s been a problem';
  const message = 'Please refresh your browser to reload.';
  const icon = isChunkLoadError ? refreshIcon : brokenBob;

  return { title, message, icon };
};

const ErrorBoundaryModal = ({ title, message, icon }) => {
  return (
    <div className="ErrorBoundaryView_modal">
      <img className="ErrorBoundaryView_image" alt="" src={icon} />
      <h2 className="ErrorBoundaryView_title">{title}</h2>
      <p className="ErrorBoundaryView_body">{message}</p>
    </div>
  );
};

class ErrorBoundaryView extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    const { error, hasError } = this.state;

    if (hasError && error) {
      return (
        <ErrorBoundaryBackground>
          <ErrorBoundaryModal {...getErrorMessage(error)} />
        </ErrorBoundaryBackground>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryView;
