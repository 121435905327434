import { take, put, call, fork, cancel, cancelled, takeEvery, delay } from 'redux-saga/effects';

import {
  START_RENDER_STATUS_POLLING,
  STOP_RENDER_STATUS_POLLING,
  fetchRenderStatusFailed,
  stopRenderStatusPolling,
  pollingCancelled,
  renderFailed,
  RENDER_FAILED,
  fetchRenderProgressSuccess,
  GET_VIDEO_DOWNLOAD_URL_FAILED,
  DOWNLOAD_REQUEST_FAILED,
  DOWNLOAD_REQUEST_SUCCESS,
  startRenderStatusPolling,
  ABORT_DOWNLOAD
} from '../actionCreators/downloadActions';
import { appServices } from '../shared/helpers/app-services/AppServices';
import { sendErrorToSentry } from '../logging';

const polls = [];
const progressPolls = [];

function* pollProgressApi(shortId, format, trackingProperties, scribeId) {
  while (true) {
    try {
      yield delay(5000);
      const result = yield call(appServices.fetchRenderProgress, shortId);
      if (result.mergeComplete) {
        yield put(stopRenderStatusPolling(shortId, format, trackingProperties, scribeId));
      }
      if (result.errors?.length > 0) {
        const { event_detail } = result.errors[0];
        const message = event_detail ? event_detail : 'Error downloading video';
        yield put(renderFailed({ error: { message }, shortId, format, trackingProperties, scribeId }));
      }
      yield put(fetchRenderProgressSuccess(shortId, result));
    } catch (e) {
      sendErrorToSentry(e);
      yield put(fetchRenderStatusFailed(e, shortId));
    } finally {
      if (yield cancelled()) {
        yield put(pollingCancelled(shortId));
        return;
      }
    }
  }
}

function* pollDownloadSaga() {
  let action;
  while ((action = yield take(START_RENDER_STATUS_POLLING))) {
    progressPolls.push({
      shortId: action.shortId,
      pollApiTask: yield fork(
        pollProgressApi,
        action.shortId,
        action.format,
        action.trackingProperties,
        action.scribeId
      )
    });

    yield takeEvery(STOP_RENDER_STATUS_POLLING, cancelPolling);
    yield takeEvery(
      [GET_VIDEO_DOWNLOAD_URL_FAILED, DOWNLOAD_REQUEST_FAILED, RENDER_FAILED, ABORT_DOWNLOAD],
      cancelAllPolling
    );
  }
}

function* startPolling({ shortId, format, trackingProperties, scribeId }) {
  yield put(startRenderStatusPolling(shortId, format, trackingProperties, scribeId));
}

export function* startRenderPollingSagas() {
  yield takeEvery(DOWNLOAD_REQUEST_SUCCESS, startPolling);
}

function* cancelPolling(action) {
  const pollToCancel = polls.find(poll => poll.shortId === action.shortId);
  const progressPollToCancel = progressPolls.find(poll => poll.shortId === action.shortId);
  if (pollToCancel) {
    yield cancel(pollToCancel.pollApiTask);
    polls.pop(pollToCancel);
  }
  if (progressPollToCancel) {
    yield cancel(progressPollToCancel.pollApiTask);
    progressPolls.pop();
  }
}

function* cancelAllPolling() {
  yield cancel([...polls, ...progressPolls].map(p => p.pollApiTask));
}

export default pollDownloadSaga;
