import * as PIXI from 'pixi.js';
import { AnimationTimingOptions, PlaybackScribeModel, ScribeCameraElement } from 'js/types';
import { mapSceneElementIdsToElements } from 'js/shared/helpers/scenesHelpers';
import { getElementTimings } from 'js/playback/lib/Playback/helpers/getElementTimings';
import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import { renderer } from 'js/config/defaults';
import { initializeSceneViewport } from 'js/shared/helpers/initializeSceneViewport';

import { getAnimatingScenes } from './scenesAnimating';
import { stripElementForRenderer } from './stripElementForRenderer';
import { getViewportData } from './getViewportData';
import { getElementBoundingRectangle } from './getElementBoundingRectangle';

interface StripProjectOfElementsNotInSegmentCameraViewsConfig {
  project: PlaybackScribeModel;
  startTimeMs: number;
  endTimeMs: number;
}

export function stripProjectOfElementsNotInSegmentCameraViews({
  project,
  startTimeMs,
  endTimeMs
}: StripProjectOfElementsNotInSegmentCameraViewsConfig) {
  const durationMs = endTimeMs - startTimeMs;
  const numberFrames = (durationMs / 1000) * renderer.frameRate;
  const frameSize = durationMs / numberFrames;
  const frames = Array(numberFrames)
    .fill(null)
    .map((_, frameIndex) => ({ timestamp: startTimeMs + frameIndex * frameSize }));
  const canvasDimensions = getViewportData(project.canvasSize);
  const animatingScenes = getAnimatingScenes(project, project.scenes, startTimeMs, endTimeMs);

  const sceneElementsInOrderWithTimingsAndViewport = animatingScenes.map(scene => {
    const elements = mapSceneElementIdsToElements(project, scene);
    const sceneTimings = getSceneStartEndTimes(project, scene);
    const startingCamera = elements[0] as ScribeCameraElement;

    // Setup the scene viewport as we do in playback so we can feed in cameras and use `boundsAtTime` method.
    const sceneViewport = initializeSceneViewport(project, scene, startingCamera, true);

    let initialCameraTimings: AnimationTimingOptions;
    return elements.map((element, elementIndex, elements) => {
      const timings = getElementTimings(
        elements,
        element,
        project,
        scene,
        sceneTimings.startTime,
        initialCameraTimings
      );

      if (elementIndex === 0) {
        initialCameraTimings = timings;
      }

      if (element.type === 'Camera') {
        sceneViewport.initializeCamera({
          camera: element,
          startTimeMs: timings.startTimeMs,
          endTimeMs: timings.endTimeMs
        });
      }

      return {
        element,
        timings,
        sceneViewport
      };
    });
  });

  // First strip any elements that start time is after the end time of the segment
  sceneElementsInOrderWithTimingsAndViewport.flat().forEach(elementAndTimings => {
    const animatingScenes = getAnimatingScenes(project, project.scenes, startTimeMs, endTimeMs);
    let timingsOffset = 0;
    if (animatingScenes.length > 0) {
      const scene = animatingScenes.find(scene => scene.active);
      if (scene) {
        timingsOffset = scene.settings.sceneTransitionTime ?? 0;
      }
    }
    if (
      elementAndTimings.timings.startTimeMs - timingsOffset * 1000 > endTimeMs &&
      elementAndTimings.element.type !== 'Camera'
    ) {
      stripElementForRenderer(elementAndTimings.element);
    }
  });

  // Calculate the view rectangle for each frame of this segment -
  // and strip any element that don't fall inside any of the camera rectangles.
  sceneElementsInOrderWithTimingsAndViewport.forEach(elementsAndTimings => {
    const camerasAndTimings = elementsAndTimings.filter(
      elementsAndTimings => elementsAndTimings.element.type === 'Camera'
    );

    const cameraRectangles = frames.map(({ timestamp }) => {
      const activeCamera = camerasAndTimings.find((cameraAndTiming, index, arr) => {
        const nextCamera = arr[index + 1];
        if (!nextCamera) return true;
        return cameraAndTiming.timings.startTimeMs <= timestamp && nextCamera.timings.startTimeMs > timestamp;
      });

      // Find the view rectangle at the timestamp (takes into account camera animations)
      return activeCamera?.sceneViewport.boundsAtTime(timestamp) ?? new PIXI.Rectangle();
    });

    elementsAndTimings.forEach(elAndTimings => {
      if (elAndTimings.element.type === 'Camera') return;

      const elementBoundingBox = getElementBoundingRectangle(elAndTimings.element, canvasDimensions);
      const visibleInAFrame = cameraRectangles.some(cameraBoundingBox =>
        cameraBoundingBox.intersects(elementBoundingBox)
      );

      // If element is outside all of the camera rectangles then strip it out
      if (!visibleInAFrame) {
        stripElementForRenderer(elAndTimings.element);
      }
    });
  });

  return project;
}
