import React, { useMemo } from 'react';
import IconSearchEmpty from 'js/shared/icons/IconSearchEmpty';
import { ImageCategory } from 'js/types';

import CategoriesList from '../../../CategoriesList';

import './SearchResultEmptyState.css';

type SearchResultEmptyStateProps = {
  tab: string;
  alternativeTab?: string;
  onCategoryClick: (category: ImageCategory) => void;
};

const SearchResultEmptyState: React.FC<SearchResultEmptyStateProps> = ({ tab, alternativeTab, onCategoryClick }) => {
  const message = useMemo(
    () => `Sorry, there are no images within '${tab}' that match your search term(s). You can try either:`,
    [tab]
  );
  const options = useMemo(
    () => [
      ...(alternativeTab ? [`Looking in the '${alternativeTab}' section above`] : []),
      'Editing your search term(s)',
      'Browsing the categories below'
    ],
    [alternativeTab]
  );

  return (
    <div className="SearchResultEmptyState">
      <div className="SearchResultEmptyState__message-container">
        <div className="SearchResultEmptyState__message-container--left">
          <IconSearchEmpty />
        </div>
        <div className="SearchResultEmptyState__message-container--right">
          <p className="SearchResultEmptyState__message">{message}</p>
          <ul className="SearchResultEmptyState__option-list">
            {options.map((option, index) => (
              <li key={index} className="SearchResultEmptyState__option">
                {option}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <CategoriesList onCategoryClick={onCategoryClick} />
    </div>
  );
};

export default SearchResultEmptyState;
