import getScribeLengthMs from 'js/playback/lib/Playback/helpers/getScribeLengthMs';
import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import { AudioClip, ExistingScribeModel } from 'js/types';

interface DownloadRequestAsset {
  /** The users asset's ID */
  id: number;

  /** The asset type */
  type: 'audio';

  /** Volume of the individual audio clip */
  volume: number;

  /** The fade out duration of the audio clip */
  fadeOutDurationSeconds?: number;

  /** The start time of the audio clip in the output video */
  startTimeSeconds: number;

  /** The start time offset of the audio clip (for trimmed audio) */
  startOffsetSeconds: number;

  /** The duration of the audio clip in the output video (will indicate end time for trimmed audio) */
  durationSeconds: number;
}

function mapClipToDownloadAsset(
  audioClip: AudioClip,
  startTimeOffset = 0,
  projectLength: number = Number.POSITIVE_INFINITY
): DownloadRequestAsset {
  let durationSeconds = audioClip.instanceDuration ?? audioClip.duration ?? 0;
  const startTimeSeconds = (audioClip.startTime ?? 0) + startTimeOffset;
  const startOffsetSeconds = audioClip.startOffset ?? 0;
  if (durationSeconds + startTimeSeconds > projectLength) durationSeconds = projectLength - startTimeSeconds;

  let fadeProps = {};
  if (audioClip.fadeOutDurationSeconds) {
    const fadeOutDurationSeconds = Math.min(audioClip.fadeOutDurationSeconds, durationSeconds);

    fadeProps = {
      fadeOutDurationSeconds
    };
  }

  return {
    id: audioClip.assetId,
    type: 'audio',
    volume: audioClip.volume,
    startTimeSeconds,
    startOffsetSeconds,
    durationSeconds,
    ...fadeProps
  };
}

export function createDownloadAssetsFromProject(project: ExistingScribeModel): DownloadRequestAsset[] {
  const clips: DownloadRequestAsset[] = [];
  const { projectAudioLayerIds, audioClips, audioLayers } = project;
  if (!audioClips) return clips;

  const projectAudioAssets = projectAudioLayerIds?.length
    ? projectAudioLayerIds
        .flatMap(layerId => {
          const audioLayer = audioLayers.find(layer => layer.id === layerId);
          if (!audioLayer) return null;

          const clips = audioLayer.getAudioClips(project);
          return clips.map(audioClip => mapClipToDownloadAsset(audioClip, 0, getScribeLengthMs(project) / 1000));
        })
        .filter((asset): asset is DownloadRequestAsset => !!asset)
    : [];

  const sceneAudioAssets = project.scenes
    .flatMap(scene => {
      const { startTime: sceneStartTime } = getSceneStartEndTimes(project, scene);
      return (
        scene.audioLayerIds?.flatMap(layerId => {
          const audioLayer = audioLayers.find(layer => layer.id === layerId);
          if (!audioLayer) return null;

          const clips = audioLayer.getAudioClips(project);

          return clips.map(audioClip => mapClipToDownloadAsset(audioClip, sceneStartTime / 1000));
        }) ?? []
      );
    })
    .filter((asset): asset is DownloadRequestAsset => !!asset);

  clips.push(...projectAudioAssets);
  clips.push(...sceneAudioAssets);

  return clips;
}
