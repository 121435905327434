import WebFont from 'webfontloader';
import FontFaceObserver from 'fontfaceobserver';
import uniq from 'lodash.uniq';
import { DEFAULT_TEXT_ELEMENT_COPY } from 'js/config/consts';

import allFonts from '../resources/fonts';
import { defaultFontValue, fallbackFontValue } from '../resources/scribedefaults';

const loadFontCssFiles = (testStringDict, includeTestText) => {
  const fonts = Object.keys(testStringDict).map(fontName => `${fontName}:400,700,400i,700i`);
  let text;
  if (includeTestText) {
    const allText = Object.values(testStringDict).join('');
    const uniqCharacters = uniq(allText.split(''));
    text = uniqCharacters.join('');
  }

  return new Promise((resolve, reject) => {
    if (fonts.length === 0) resolve();
    WebFont.load({
      classes: false,
      google: {
        families: fonts,
        text
      },
      active() {
        resolve();
      },
      inactive() {
        reject();
      }
    });
  });
};

const variants = [
  {
    weight: 400,
    style: 'normal'
  },
  {
    weight: 400,
    style: 'italic'
  },
  {
    weight: 700,
    style: 'normal'
  },
  {
    weight: 700,
    style: 'italic'
  }
];

/*
  The way Google Fonts structures it's CSS files and font files, fonts subsets such as arabic characters don't automatically get downloaded
  via `webfontloader`. The font file containing subset characters will only get loaded once the DOM contains a character within that 
  subsets `unicode-range`. `fontfaceobserver` does a better job of loading the font file as you can provide it a test string - so we can build
  a test string from the text in the scribe to ensure that all the fonts needed to render the text in a scribe are preloaded.
*/
const loadFontsFiles = (testStringDict, includeTestText) => {
  return Promise.allSettled(
    Object.entries(testStringDict).flatMap(([font, testStr]) => {
      const variantPromises = variants.map(variant => {
        const obs = new FontFaceObserver(font, variant);
        const testString = includeTestText ? testStr : null;
        return obs.load(testString);
      });
      return variantPromises;
    })
  );
};

const loadFonts = async (testStringDict, includeTestText = true) => {
  await loadFontCssFiles(testStringDict, includeTestText);
  await loadFontsFiles(testStringDict, includeTestText);
};

const addUserFontFiles = userFontsList => {
  return Promise.all(
    userFontsList.map(font => {
      const fontFace = new FontFace(font.fontname, `url(${font.fontUrl})`);
      document.fonts.add(fontFace);
      return fontFace.load();
    })
  );
};

async function preloadElementFonts(elements) {
  const allTextInElements = elements.reduce((str, element) => str + element.text, DEFAULT_TEXT_ELEMENT_COPY);
  const testStringDict = elements.reduce(
    (dict, element) => {
      const font = element.font.value;
      const str = element.text;
      return {
        ...dict,
        [font]: (dict[font] || '') + str
      };
    },
    {
      [defaultFontValue]: allTextInElements,
      [fallbackFontValue]: allTextInElements
    }
  );

  await loadFonts(testStringDict);
}

export async function preloadAllScribesFonts(scribes) {
  const textElements = scribes.flatMap(scribe => scribe.elements).filter(el => el.type === 'Text' && !el.font.assetId);
  try {
    await preloadElementFonts(textElements);
  } catch (e) {
    console.error(e);
  }
}

export default async function preloadScribeFonts(scribe) {
  await preloadAllScribesFonts([scribe]);
}

export async function loadAllSelectableFonts(scribeText) {
  const familiesDict = allFonts.reduce((dict, font) => ({ ...dict, [font.value]: scribeText || font.value }), {});
  try {
    await loadFonts(familiesDict, false);
  } catch (e) {
    console.error(e);
  }
}

export async function loadUserSelectableFonts(scribeText, userFontsList) {
  try {
    await addUserFontFiles(userFontsList);
    const familiesDict = userFontsList.reduce((acc, current) => ({ ...acc, [current.fontname]: scribeText }), {});
    await loadFontsFiles(familiesDict, false);
  } catch (e) {
    console.error(e);
  }
}
