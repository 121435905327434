import * as PIXI from 'pixi.js';
import { SCENE_TRANSITION_NO_CURSOR_ID } from 'js/config/consts';
import { MASK_LINE_COLOR } from 'js/config/defaults';
import { MaskingLineStyleConfig, SceneTransitionConfig, VSCoordinate } from 'js/types';

import generateScribblePathArray from '../../helpers/generateScribblePathArray';
import setupBasicMask from '../../helpers/setupBasicMask';

import VSSceneTransitionBase, { VSSceneTransitionBaseProps } from './VSSceneTransitionBase';

const DURATION_OF_ANIMATION = 1;

export default class VSEraseTransition extends VSSceneTransitionBase {
  config?: SceneTransitionConfig;
  showCursor: boolean;
  brushSize?: number;
  maskLineStyleConfig?: MaskingLineStyleConfig;
  maskPathPoints?: Array<VSCoordinate>;

  constructor(props: VSSceneTransitionBaseProps) {
    super({ ...props });

    this.scene1Out = DURATION_OF_ANIMATION;
    this.scene2In = DURATION_OF_ANIMATION;
    this.config = props.config;
    this.showCursor = this.config?.cursorId !== SCENE_TRANSITION_NO_CURSOR_ID;
  }

  setupIntroTransition() {
    if (this.currentScene && !this.mask2CurrIn) {
      const { scribbleArray, brushSize } = generateScribblePathArray(
        this.viewportDimensions.width,
        this.viewportDimensions.height
      );

      this.brushSize = brushSize;
      this.maskPathPoints = scribbleArray;

      this.maskLineStyleConfig = {
        width: this.brushSize,
        color: MASK_LINE_COLOR,
        cap: PIXI.LINE_CAP.ROUND,
        join: PIXI.LINE_JOIN.ROUND
      };

      const mask = setupBasicMask(this.maskLineStyleConfig, this.maskPathPoints[0]);

      this.mask2CurrIn = mask;
      this.currentScene.viewportContainer.parent.addChild(this.mask2CurrIn);
      this.currentScene.viewportContainer.mask = this.mask2CurrIn;

      this.currentScene.viewportContainer.filters = [];
    }
  }

  revealAnimation(percentProgress: number) {
    if (this.maskPathPoints) {
      const length = this.maskPathPoints.length;
      const drawTo = Math.floor(length * percentProgress);

      const drawPathSegment = this.maskPathPoints.slice(0, drawTo);

      if (this.mask2CurrIn) {
        if (percentProgress < 1) {
          this.mask2CurrIn.clear();
          this.mask2CurrIn.lineStyle(this.maskLineStyleConfig);
        }

        if (drawPathSegment.length) {
          this.mask2CurrIn.moveTo(drawPathSegment[0].x, drawPathSegment[0].y);
          // Only draw the segment from the last update to this update
          drawPathSegment.forEach(point => {
            this.mask2CurrIn?.lineTo(point.x, point.y);
          });

          if (this.showCursor) {
            // Update cursor position to the last point in the segment
            const lastPoint = drawPathSegment[drawPathSegment.length - 1];
            this.currentScene.cursor.x = (lastPoint && lastPoint.x) || 0;
            this.currentScene.cursor.y = (lastPoint && lastPoint.y) || 0;
          }
        }
      }
    }
  }

  updateIntroProgress(progress: number) {
    if (progress < 1) {
      this.setupIntroTransition();
    }

    if (this.currentScene) {
      this.revealAnimation(progress);
    }
  }
}
