import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  addUserImageToCanvas,
  uploadImageAssetFailed,
  uploadImageAssetSuccess,
  UPLOAD_IMAGE_ASSET_ON_LOAD,
  UPLOAD_IMAGE_FILES,
  UPLOAD_LIBRARY_IMAGE_ASSET,
  UPLOAD_USER_IMAGE_ASSET
} from 'js/actionCreators/imagesActions';
import { showError } from 'js/actionCreators/uiActions';
import {
  ALLOWED_IMAGE_TYPES,
  canvasSizes,
  MAX_IMAGE_FILE_UPLOAD_SIZE,
  MAX_IMAGE_FILE_UPLOAD_SIZE_DISPLAY,
  MAX_SIMULTANEOUS_FILE_UPLOAD
} from 'js/config/defaults';
import processImageForUpload from 'js/sagas/sagaHelpers/processImageForUpload';
import { APPLICATION_DISPLAY_NAME } from 'js/config/config';
import { sendErrorToSentry } from 'js/logging';
import { getProviderForType } from 'js/shared/providers';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { updateScribe, updateUndoRedoHistory } from 'js/actionCreators/scribeActions';
import { rateLimitedfetch } from 'js/shared/helpers/rateLimitedFetch';
import { moveConvertedSVGData, moveGIFData } from 'js/shared/lib/LocalDatabase';

import {
  maxUserImageUploadRetries,
  maxImageUploadOnScribeLoadRetries,
  maxLibraryImageUploadRetries
} from '../config/consts';

import { getScribeById } from './selectors';
import {
  replaceImageUrlBrokenImageInScribe,
  replaceBrokenImageUrlInHistory,
  replaceImageUrlInScribe,
  replaceImageUrlInHistory
} from './sagaHelpers/replaceImageUrl';

export function* uploadImageFiles({ scribeId, files, uploadMethod }) {
  if (files.length > MAX_SIMULTANEOUS_FILE_UPLOAD) {
    return yield put(
      showError({
        message: 'You have exceeded the maximum number of simultaneous uploads',
        description: 'Maximum 20 images at a time'
      })
    );
  }

  const correctFileTypes = Array.from(files).filter(file => ALLOWED_IMAGE_TYPES.includes(file.type));
  const showSomeFilesIncompatibleError = correctFileTypes.length !== files.length;

  if (showSomeFilesIncompatibleError) {
    yield put(
      showError({
        message: 'Some of your files contained incompatible file types',
        description: 'These files were not added to your project'
      })
    );
  }

  const filesOverSizeLimit = [];
  const filesToProcess = [];

  correctFileTypes.forEach(file => {
    if (file.size > MAX_IMAGE_FILE_UPLOAD_SIZE) {
      filesOverSizeLimit.push(file);
    } else {
      filesToProcess.push(file);
    }
  });

  if (filesOverSizeLimit.length !== 0) {
    yield put(
      showError({
        message: `Error uploading your image file.`,
        description: `Image files must be less than ${MAX_IMAGE_FILE_UPLOAD_SIZE_DISPLAY}MB in size.`
      })
    );
  }

  const scribe = yield select(getScribeById, scribeId);
  const canvasSize = canvasSizes[scribe.canvasSize];

  for (const file of filesToProcess) {
    try {
      const { file: processedFile, name } = yield call(processImageForUpload, file);
      yield put(addUserImageToCanvas(processedFile, name, scribe.id, canvasSize, uploadMethod));
    } catch (error) {
      yield put(
        showError({
          message: 'One of your files failed to upload',
          description: error.message
        })
      );
    }
  }
}

export function* uploadLibraryImageAsset({
  file,
  filename,
  scribeId,
  originalAssetId,
  source,
  sourceIdentifier,
  isPremium
}) {
  const { assetId, signedImgUrl, error } = yield call(uploadImageAssetWithRetries, {
    file,
    filename,
    source,
    sourceIdentifier,
    maxRetries: maxLibraryImageUploadRetries,
    isPremium
  });
  if (error) {
    console.error(error);
    yield put(uploadImageAssetFailed());
    sendErrorToSentry(error);
    return;
  }
  yield call(updateScribeOnUploadSuccess, scribeId, assetId, signedImgUrl, originalAssetId, source);
}

function* uploadImageAssetOnLoad({ libraryElements, scribeId }) {
  for (const element of libraryElements) {
    const { image, isPremium } = element;
    const provider = getProviderForType(image.provider);
    const url = yield call(provider, image.assetId ?? image.imageId);
    const resp = yield rateLimitedfetch(url);
    const blob = yield resp.blob();
    const sourceMeta =
      image.provider === 'noun-project'
        ? {
            source: 'noun-project',
            sourceIdentifier: image.assetId
          }
        : {};

    const { assetId, signedImgUrl, error } = yield call(uploadImageAssetWithRetries, {
      file: blob,
      filename: image.filename,
      maxRetries: maxImageUploadOnScribeLoadRetries,
      isPremium,
      ...sourceMeta
    });
    if (error) {
      console.error(error);
      yield put(uploadImageAssetFailed());
      sendErrorToSentry(error);
      return;
    }

    yield call(updateScribeOnUploadSuccess, scribeId, assetId, signedImgUrl, image.assetId ?? image.imageId);
  }
}

/**
 *
 * @param {{ file: Blob, filename: string, maxRetries: number, source: string, sourceIdentifier: string, isPremium?: boolean}} asset
 */
export function* uploadImageAssetWithRetries({ file, filename, maxRetries, source, sourceIdentifier, isPremium }) {
  const provider = getProviderForType('user');
  let error;
  for (let retryAttempts = 0; retryAttempts < maxRetries; retryAttempts++) {
    try {
      const assetId = yield call(appServices.uploadAsset, { file, filename, source, sourceIdentifier, isPremium });
      const signedImgUrl = yield call(provider, assetId);
      return {
        assetId,
        signedImgUrl
      };
    } catch (err) {
      error = err;
      if (retryAttempts < maxRetries) {
        yield delay(5000);
      }
    }
  }
  return {
    error
  };
}

function* updateScribeOnUploadSuccess(scribeId, assetId, signedImgUrl, originalAssetId, source) {
  const activeScribe = yield select(getScribeById, scribeId);
  const skipUndoHistory = true;
  const newActiveScribe = yield call(replaceImageUrlInScribe, activeScribe, originalAssetId, assetId, signedImgUrl);

  if (newActiveScribe !== activeScribe) {
    yield put(updateScribe(newActiveScribe, skipUndoHistory));
  }

  const currentActiveScribePast = yield select(state => state.scribes.activeScribePast);
  const activeScribePast = yield replaceImageUrlInHistory(
    currentActiveScribePast,
    assetId,
    signedImgUrl,
    originalAssetId
  );

  const currentActiveScribeFuture = yield select(state => state.scribes.activeScribeFuture);
  const activeScribeFuture = yield replaceImageUrlInHistory(
    currentActiveScribeFuture,
    assetId,
    signedImgUrl,
    originalAssetId
  );

  yield put(updateUndoRedoHistory(activeScribePast, activeScribeFuture));
  yield put(uploadImageAssetSuccess(scribeId, assetId, originalAssetId, source));

  // Replace processed image key in client-side store
  yield call(moveConvertedSVGData, originalAssetId, assetId.toString());
  yield call(moveGIFData, originalAssetId, assetId.toString());
}

export function* uploadUserImageAsset({ file, filename, scribeId, originalAssetId }) {
  const { assetId, signedImgUrl, error } = yield call(uploadImageAssetWithRetries, {
    file,
    filename,
    source: 'user-upload',
    maxRetries: maxUserImageUploadRetries
  });

  if (error) {
    console.error(error);

    const activeScribe = yield select(getScribeById, scribeId);
    const skipUndoHistory = true;
    const { newActiveScribe, historyIdsToUpdate } = yield replaceImageUrlBrokenImageInScribe(
      activeScribe,
      originalAssetId
    );

    if (newActiveScribe !== activeScribe) {
      yield put(updateScribe(newActiveScribe, skipUndoHistory));
    }

    const currentActiveScribePast = yield select(state => state.scribes.activeScribePast);
    const activeScribePast = yield replaceBrokenImageUrlInHistory(currentActiveScribePast, historyIdsToUpdate);

    const currentActiveScribeFuture = yield select(state => state.scribes.activeScribeFuture);
    const activeScribeFuture = yield replaceBrokenImageUrlInHistory(currentActiveScribeFuture, historyIdsToUpdate);

    yield put(updateUndoRedoHistory(activeScribePast, activeScribeFuture));
    yield put(uploadImageAssetFailed());

    yield put(
      showError({
        message: `Uh-oh! Your image failed to upload into ${APPLICATION_DISPLAY_NAME}, but never fear, just try uploading your image again.`,
        description: 'Happy animating!'
      })
    );

    sendErrorToSentry(error);
    return;
  }

  yield call(updateScribeOnUploadSuccess, scribeId, assetId, signedImgUrl, originalAssetId);
}

export default function* imageUploadSagas() {
  yield takeEvery(UPLOAD_IMAGE_FILES, uploadImageFiles);
  yield takeEvery(UPLOAD_IMAGE_ASSET_ON_LOAD, uploadImageAssetOnLoad);
  yield takeEvery(UPLOAD_LIBRARY_IMAGE_ASSET, uploadLibraryImageAsset);
  yield takeEvery(UPLOAD_USER_IMAGE_ASSET, uploadUserImageAsset);
}
