import { IMAGE_PROVIDER_TYPES } from 'js/config/consts';

import config from '../../config/config';
import { DEFAULT_PAGE_SIZE } from '../components/ImageList';
import { getOptions, rendererHeaders } from '../helpers/app-services/AppServices/helpers';
import { rateLimitedfetch } from '../helpers/rateLimitedFetch';
import { fetchWithRefreshAuth } from '../helpers/requestLimiter';
import { appServices } from '../helpers/app-services/AppServices';
import { currentUserAccessToken } from '../helpers/authHelper';

const { LIBRARY_API_DOMAIN } = config;

// ugly fix: strip header to keep library cors happy
const fetchStripHeader = (url, options) => {
  delete options.headers['x-spk-app-name'];
  return rateLimitedfetch(url, options);
};
async function fetchWithReAuthLib(path, options) {
  return fetchWithRefreshAuth(
    fetchStripHeader,
    `${LIBRARY_API_DOMAIN}${path}`,
    appServices.exchangeCognitoTokenForSparkolToken,
    options
  ).then(res => res.json());
}

export const getImageLibraries = async () => {
  const options = getOptions();
  options.headers['Authorization'] = await currentUserAccessToken();
  return fetchWithReAuthLib(`/api/vsc/libraries`, options);
};

export const getImageLibrary = async (libraryId, searchSource, cursor = '0') => {
  const options = getOptions();
  options.headers['Authorization'] = await currentUserAccessToken();
  const url =
    searchSource === 'noun-project' ? `/api/vsc/noun-project/library/${libraryId}` : `/api/vsc/library/${libraryId}`;
  const library = await fetchWithReAuthLib(`${url}?cursor=${cursor}&pageSize=${DEFAULT_PAGE_SIZE}`, options);
  // Use cdn for thumbnails - no cdn in testing envs
  const s3BaseProduction = 'sparkol-assets-s3-production-libraries.s3.amazonaws.com';
  const cdnBaseProduction = 'cloud-files-cdn.sparkol.com/library/images/online';
  const libraryCdn = {
    ...library,
    images: library.images.map(image => ({
      ...image,
      bucketUrl: image.bucketUrl.replace(s3BaseProduction, cdnBaseProduction),
      isPremium: image.isPremium
    }))
  };
  return libraryCdn;
};

export const getImageByIdFunction = type => {
  return async (id, thumb = false) => {
    const standardOptions = getOptions();
    const options = {
      ...standardOptions,
      headers: {
        ...standardOptions.headers,
        ...rendererHeaders(true)
      }
    };
    options.headers['Authorization'] = await currentUserAccessToken();
    const url =
      type === IMAGE_PROVIDER_TYPES.NOUN_PROJECT ? `/api/vsc/noun-project/image/${id}` : `/api/vsc/library/image/${id}`;
    return fetchWithReAuthLib(url, options).then(({ data }) => {
      return `${data.bucketUrl}/${thumb ? data.thumbnailFilename : data.imageFilename}`;
    });
  };
};

const searchNounProject = async (searchTerms, nextPage) => {
  const options = getOptions('GET');
  const searchParams = new URLSearchParams({
    searchTerms: searchTerms.join(' ').toLowerCase(),
    ...(nextPage && { nextPage })
  });
  options.headers['Authorization'] = await currentUserAccessToken();
  return fetchWithReAuthLib(`/api/vsc/noun-project/search?${searchParams}`, options);
};

export const searchImageLibrary = async (imageService, search, offset = 0, nextPage) => {
  const query = search
    .trim()
    .split(/[^a-zA-Z0-9]+(?:'\w+)?/)
    .filter(n => n);
  if (imageService === 'noun-project') {
    return searchNounProject(query, nextPage);
  }
  const options = getOptions(
    'POST',
    JSON.stringify({
      searchTerms: query,
      limit: DEFAULT_PAGE_SIZE,
      excludeCommex: true,
      offset,
      nextPage: nextPage || null
    })
  );
  options.headers['Authorization'] = await currentUserAccessToken();
  return fetchWithReAuthLib(`/api/vsc/image/search`, options);
};
