import { AudioClip } from 'js/types';

export const getCachedUrlsForAudioClips = (
  audioClips: Array<AudioClip>,
  blobUrls: Array<{ assetId: number; blobUrl: string }>
): Array<AudioClip> => {
  return audioClips.map(clip => {
    const urlResource = blobUrls.find(blobUrl => blobUrl.assetId.toString() === clip.assetId.toString());
    const url = urlResource?.blobUrl;

    return {
      ...clip,
      url
    };
  });
};
