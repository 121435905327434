import { DELETE_AUDIO_FROM_PROJECT, SAVE_AUDIO, UPDATE_AUDIO_CONFIG } from 'js/actionCreators/audioActions';
import {
  TRACK_AUDIO_IMPORT_CLICK,
  TRACK_AUDIO_RECORDING_CANCEL,
  TRACK_AUDIO_SPLIT_MODE_CANCEL,
  TRACK_SPLIT_AUDIO_TRACK
} from 'js/actionCreators/trackingActions';
import { AUDIO_RECORDING_START, SET_SPLIT_MODE } from 'js/actionCreators/uiActions';
import { call, select, takeEvery } from 'redux-saga/effects';
import { SET_USER_SETTING } from 'js/actionCreators/userSettingsActions';
import { RECORD_WITH_PLAYBACK_USER_SETTING_KEY } from 'js/config/userSettingsKeys';
import getAudioFileDuration from 'js/shared/helpers/getAudioFileDuration';
import { getProjectById } from 'js/sagas/selectors';

import { track } from '../mixpanelProvider';

const getRecordWithPlaybackUserSetting = state =>
  !!state.userSettings?.settings?.[RECORD_WITH_PLAYBACK_USER_SETTING_KEY];

function* trackAudioImportClick({ scribeId, audioType }) {
  yield call(track, 'Open Audio Upload', { 'Scribe ID': scribeId, 'Audio Type': audioType });
}

function* trackAudioRecordingStart({ scribeId, audioType }) {
  const recordWithPlayback = yield select(getRecordWithPlaybackUserSetting);
  yield call(track, 'Click Record Audio', {
    'Scribe ID': scribeId,
    'Audio Type': audioType,
    'Playback Enabled': recordWithPlayback
  });
}

function* trackAudioRecordingCancel({ scribeId, audioType }) {
  const recordWithPlayback = yield select(getRecordWithPlaybackUserSetting);

  yield call(track, 'Cancel Recording Audio', {
    'Scribe ID': scribeId,
    'Audio Type': audioType,
    'Playback Enabled': recordWithPlayback
  });
}

function* trackAudioDelete({ scribeId, audioType, eventTrigger }) {
  yield call(track, 'Audio Deleted From Project', {
    'Scribe ID': scribeId,
    'Audio Type': audioType,
    'Event Trigger': eventTrigger
  });
}

function* trackAudioSave({ audio, scribeId, source, audioType }) {
  let duration;
  try {
    duration = yield call(getAudioFileDuration, audio);
  } catch (error) {
    console.error(error);
  }

  const recordWithPlayback = yield select(getRecordWithPlaybackUserSetting);

  yield call(track, 'Update Audio', {
    'Scribe ID': scribeId,
    'Audio Source': source,
    'Audio Length (seconds)': duration,
    'Audio Type': audioType,
    'Playback Enabled': recordWithPlayback
  });
}

function* trackSetUserSetting({ scribeId, setting, value }) {
  if (setting === RECORD_WITH_PLAYBACK_USER_SETTING_KEY) {
    yield call(track, value === true ? 'Enable Playback When Recording' : 'Disable Playback When Recording', {
      'Scribe ID': scribeId
    });
  }
}

function* trackUpdateAudioConfig({ scribeId, config, id: clipId }) {
  const project = yield select(getProjectById, scribeId);
  const audioType = project.audioClips?.find(clip => clip.id === clipId)?.type ?? 'project';

  if (config.volume) {
    yield call(track, 'Set Audio Volume', {
      'Volume Control': config.volumeType,
      Volume: config.volume * 100,
      'Audio Type': audioType,
      'Scribe ID': scribeId
    });
  } else if (config.fadeOutDurationSeconds) {
    yield call(track, 'Set Audio Fade Out', {
      'Fade Out': config.fadeOutDurationSeconds,
      'Audio Type': audioType,
      'Scribe ID': scribeId
    });
  } else if (config.instanceDuration) {
    yield call(track, 'Trim Audio Clip', {
      'Audio Type': audioType,
      'Scribe ID': scribeId
    });
  }
}

function* trackSplitAudioTrack({ scribeId, audioType }) {
  yield call(track, 'Confirm Split Audio', { 'Scribe ID': scribeId, 'Audio Type': audioType });
}

function* trackCancelSplitAudioTrack({ scribeId, audioType }) {
  yield call(track, 'Cancel Split Audio Track', { 'Scribe ID': scribeId, 'Audio Type': audioType });
}

function* trackSetSplitMode({ scribeId, id: clipId, trigger, modeOn }) {
  const project = yield select(getProjectById, scribeId);
  const clip = project.audioClips.find(clip => clip.id === clipId);

  if (clip && modeOn) {
    yield call(track, 'Initiate Split Audio', {
      'Scribe ID': scribeId,
      'Event Trigger': trigger,
      'Audio Type': clip.type
    });
  }
}

export default function* trackAudioSagas() {
  yield takeEvery(TRACK_AUDIO_IMPORT_CLICK, trackAudioImportClick);
  yield takeEvery(AUDIO_RECORDING_START, trackAudioRecordingStart);
  yield takeEvery(TRACK_AUDIO_RECORDING_CANCEL, trackAudioRecordingCancel);
  yield takeEvery(DELETE_AUDIO_FROM_PROJECT, trackAudioDelete);
  yield takeEvery(SAVE_AUDIO, trackAudioSave);
  yield takeEvery(SET_USER_SETTING, trackSetUserSetting);
  yield takeEvery(UPDATE_AUDIO_CONFIG, trackUpdateAudioConfig);
  yield takeEvery(TRACK_SPLIT_AUDIO_TRACK, trackSplitAudioTrack);
  yield takeEvery(TRACK_AUDIO_SPLIT_MODE_CANCEL, trackCancelSplitAudioTrack);
  yield takeEvery(SET_SPLIT_MODE, trackSetSplitMode);
}
