import uuidv4 from 'uuid/v4';
import { getViewportData } from 'js/sagas/sagaHelpers/getViewportData';
import { CanvasSize, ScribeSettings, VSElementModel } from 'js/types';
import {
  DEFAULT_SHAPE_COLOR,
  defaultBackgroundGradientType,
  defaultCanvasColor,
  defaultCanvasColorFrom,
  defaultCanvasColorTo
} from 'js/config/defaults';

import ScribeShapeElementModel from './ScribeShapeElementModel';

interface CreateGradientBackgroundElementConfig {
  id: string;
  canvasSize: CanvasSize;
  settings: ScribeSettings;
}

export const createGradientBackgroundElement = ({
  id,
  canvasSize,
  settings
}: CreateGradientBackgroundElementConfig) => {
  const size = getViewportData(canvasSize);
  const backgroundShape = new ScribeShapeElementModel({
    locked: true,
    id,
    shapeType: 'square',
    canvasSize: size,
    width: size.width,
    height: size.height,
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    animationTime: 0,
    pauseTime: 0,
    fill: DEFAULT_SHAPE_COLOR,
    backgroundType: 'gradient',
    backgroundColorFrom: settings.backgroundColorFrom ?? defaultCanvasColorFrom,
    backgroundColorTo: settings.backgroundColorTo ?? defaultCanvasColorTo,
    backgroundGradientType: settings.backgroundGradientType || defaultBackgroundGradientType,
    angle: 0,
    flipX: false,
    flipY: false
  });

  return backgroundShape;
};

interface ApplyGradientBackgroundMigrationConfig {
  canvasSize: CanvasSize;
  originalSceneSettings: ScribeSettings;
  newSceneSettings: ScribeSettings;
  elementsArray: Array<VSElementModel>;
  elementIds: Array<string>;
}

export const applyGradientBackgroundMigration = ({
  canvasSize,
  originalSceneSettings,
  newSceneSettings,
  elementsArray,
  elementIds
}: ApplyGradientBackgroundMigrationConfig) => {
  console.info('Migrating scene gradient background to starting element');
  const backgroundId = uuidv4();
  const backgroundShape = createGradientBackgroundElement({
    id: backgroundId,
    canvasSize: canvasSize ?? 'landscape',
    settings: originalSceneSettings
  });

  const startingElement = elementsArray.find(el => el.id === elementIds[0]);
  // If project has already migrated to cameras then we insert the shape after the starting camera, otherwise, at the beginning
  const insertionIndex = !startingElement || startingElement.type !== 'Camera' ? 0 : 1;

  elementIds.splice(insertionIndex, 0, backgroundId);
  elementsArray.push(backgroundShape);
  newSceneSettings.backgroundColor = defaultCanvasColor;
};
