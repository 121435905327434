import React from 'react';

import EditPanelLabel from '../EditPanelLabel';

import './AnimationTypeSelectorButtons.css';

export const AnimationTypeSelectorButtonGroupText = ({ children }: { children: React.ReactNode }) => (
  <div className="t-utility-label AnimationTypeSelectorButtonGroupText">{children}</div>
);

export const AnimationTypeSelectorButtonGroup = ({
  children,
  onClick,
  label,
  id
}: {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  id: string;
}) => {
  return (
    <div>
      <EditPanelLabel htmlFor={id}>{label}:</EditPanelLabel>
      <button id={id} type="button" onClick={onClick} className="AnimationTypeSelectorButtonGroup">
        {children}
      </button>
    </div>
  );
};

const AnimationTypeSelectorButtons = ({ children }: { children: React.ReactNode }) => (
  <div className="AnimationTypeSelectorButtons">{children}</div>
);

export default AnimationTypeSelectorButtons;
