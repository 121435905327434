import ScribeModel from 'js/models/ScribeModel';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { ExistingScribeModel, FontConfirmation, SelectedFontData, VSCFontAssetData } from 'js/types';

export const TRACK_CLICK_ADD_CUSTOM_FONTS = 'TRACK_CLICK_ADD_CUSTOM_FONTS';
export const UPLOAD_FONT_FILES = 'UPLOAD_FONT_FILES';
export const GET_USER_FONTS_REQUESTED = 'GET_USER_FONTS_REQUESTED';
export const GET_USER_FONTS_SUCCESS = 'GET_USER_FONTS_SUCCESS';
export const SHOW_UPLOAD_CUSTOM_FONTS_MODAL = 'SHOW_UPLOAD_CUSTOM_FONTS_MODAL';
export const UPLOAD_FONTS_PROGRESS_STATUS = 'UPLOAD_FONTS_PROGRESS_STATUS';
export const LOAD_USER_FONTS = 'LOAD_USER_FONTS';
export const LOAD_USER_FONTS_SUCCESS = 'LOAD_USER_FONTS_SUCCESS';
export const UPDATE_MODAL_STAGE = 'UPDATE_MODAL_STAGE';
export const UPLOAD_FONTS_CONFIRMATION_STATUS = 'UPLOAD_FONTS_CONFIRMATION_STATUS';
export const DELETE_USER_FONT_REQUESTED = 'DELETE_USER_FONT_REQUESTED';
export const DELETE_USER_FONT_SUCCESS = 'DELETE_USER_FONT_SUCCESS';
export const SHOW_DELETE_CUSTOM_FONTS_MODAL = 'SHOW_DELETE_CUSTOM_FONTS_MODAL';
export const UPDATE_BITMAP_FONTS = 'UPDATE_BITMAP_FONTS';
export const UPDATE_BITMAP_FONTS_DONE = 'UPDATE_BITMAP_FONTS_DONE';

export interface TrackClickAddCustomFontsAction {
  type: typeof TRACK_CLICK_ADD_CUSTOM_FONTS;
  scribeId: number;
}

export interface UploadFontFilesAction {
  type: typeof UPLOAD_FONT_FILES;
  fontFiles: FileList | null;
  activeScribe: ScribeModel;
}

export interface GetUserFontsRequestedAction {
  type: typeof GET_USER_FONTS_REQUESTED;
  scribeId?: number;
}

export interface GetUserFontsSuccessAction {
  type: typeof GET_USER_FONTS_SUCCESS;
  fonts: Array<VSCFontAssetData>;
}

export interface ShowUploadCustomFontsModalAction {
  type: typeof SHOW_UPLOAD_CUSTOM_FONTS_MODAL;
  value: boolean;
}

export interface UpdateModalStageAction {
  type: typeof UPDATE_MODAL_STAGE;
  value: number;
}

export interface UploadProgressStatusAction {
  type: typeof UPLOAD_FONTS_PROGRESS_STATUS;
  progress: number;
  message: string;
}

export interface UploadConfirmationStatusAction {
  type: typeof UPLOAD_FONTS_CONFIRMATION_STATUS;
  confirmation: FontConfirmation;
  error?: Error;
}

export interface LoadUserFontsAction {
  type: typeof LOAD_USER_FONTS;
  scribe: ScribeModel;
}

export interface ShowDeleteCustomFontsModalAction {
  type: typeof SHOW_DELETE_CUSTOM_FONTS_MODAL;
  value: boolean;
  font?: SelectedFontData;
}

export interface DeleteUserFontAction {
  type: typeof DELETE_USER_FONT_REQUESTED;
  fontId: number;
  activeScribe: ExistingScribeModel;
}

export interface DeleteUserFontSuccessAction {
  type: typeof DELETE_USER_FONT_SUCCESS;
  fontId: number;
}

export const trackClickAddCustomFonts = (scribeId: number): TrackClickAddCustomFontsAction => ({
  type: TRACK_CLICK_ADD_CUSTOM_FONTS,
  scribeId
});

export const uploadFontFiles = (fontFiles: FileList | null, activeScribe: ScribeModel): UploadFontFilesAction => ({
  type: UPLOAD_FONT_FILES,
  fontFiles,
  activeScribe
});

export const getUserFonts = (scribeId?: number): GetUserFontsRequestedAction => ({
  type: GET_USER_FONTS_REQUESTED,
  scribeId
});

export const getUserFontsSuccess = (fonts: Array<VSCFontAssetData>): GetUserFontsSuccessAction => ({
  type: GET_USER_FONTS_SUCCESS,
  fonts
});

export const showUploadCustomFontsModal = (value: boolean): ShowUploadCustomFontsModalAction => ({
  type: SHOW_UPLOAD_CUSTOM_FONTS_MODAL,
  value
});

export const updateModalStage = (value: number): UpdateModalStageAction => ({
  type: UPDATE_MODAL_STAGE,
  value
});

export const uploadProgressStatus = (progress: number, message: string): UploadProgressStatusAction => ({
  type: UPLOAD_FONTS_PROGRESS_STATUS,
  progress,
  message
});

export const uploadConfirmationStatus = (
  confirmation: FontConfirmation,
  error?: Error
): UploadConfirmationStatusAction => ({
  type: UPLOAD_FONTS_CONFIRMATION_STATUS,
  confirmation,
  error
});

export const loadUserFonts = (scribe: ScribeModel): LoadUserFontsAction => ({
  type: LOAD_USER_FONTS,
  scribe
});

export const loadUserFontsSuccess = () => ({
  type: LOAD_USER_FONTS_SUCCESS
});

export const showDeleteCustomFontsModal = (
  value: boolean,
  font?: SelectedFontData
): ShowDeleteCustomFontsModalAction => ({
  type: SHOW_DELETE_CUSTOM_FONTS_MODAL,
  value,
  font
});

export const deleteUserFont = (fontId: number, activeScribe: ExistingScribeModel): DeleteUserFontAction => ({
  type: DELETE_USER_FONT_REQUESTED,
  fontId,
  activeScribe
});

export const deleteUserFontSuccess = (fontId: number): DeleteUserFontSuccessAction => ({
  type: DELETE_USER_FONT_SUCCESS,
  fontId
});

export interface UpdateBitmapFontsAction {
  type: typeof UPDATE_BITMAP_FONTS;
  textElements: Array<ScribeTextElementModel>;
}

export const updateBitmapFonts = (textElements: Array<ScribeTextElementModel>): UpdateBitmapFontsAction => ({
  type: UPDATE_BITMAP_FONTS,
  textElements
});

export interface UpdateBitmapFontsDoneAction {
  type: typeof UPDATE_BITMAP_FONTS_DONE;
}

export const updateBitmapFontsDone = (): UpdateBitmapFontsDoneAction => ({
  type: UPDATE_BITMAP_FONTS_DONE
});
