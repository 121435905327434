import { TOGGLE_NEW_TIMELINE } from 'js/actionCreators/editorActions';
import { PREP_SCRIBE_FOR_EDITOR_SUCCESS } from 'js/actionCreators/scribeActions';
import { EDITOR_CLOSED } from 'js/actionCreators/editorActions';
import { RootState } from 'js/types';
import { call, CallEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';

import { registerEventSuperProperties, unregisterEventSuperProperty } from '../mixpanelProvider';

export const TIMELINE_MIXPANEL_KEY = 'Timeline Active';

export function getCurrentTimeline(state: RootState) {
  return state.ui.newTimeline;
}

export function* setTimelineSuperEvent(): Generator<SelectEffect | CallEffect, void, boolean> {
  const newTimeline = yield select(getCurrentTimeline);

  yield call(registerEventSuperProperties, {
    [TIMELINE_MIXPANEL_KEY]: newTimeline ? 'Advanced' : 'Simple'
  });
}

export default function* manageTimelineSuperEventProperties() {
  yield takeEvery([PREP_SCRIBE_FOR_EDITOR_SUCCESS, TOGGLE_NEW_TIMELINE], setTimelineSuperEvent);
  yield takeEvery(EDITOR_CLOSED, unregisterEventSuperProperty, TIMELINE_MIXPANEL_KEY);
}
