import * as PIXI from 'pixi.js';

const INIDICATOR_SIZE = 24;
const INDICATOR_FILL = 0xfeb9bf;
const INDICATOR_STROKE = 0xffffff;
const INDICATOR_STROKE_WIDTH = 2;

export default class MultiDragIndicator extends PIXI.Graphics {
  private label: PIXI.Text;

  constructor() {
    super();

    this.lineStyle(INDICATOR_STROKE_WIDTH, INDICATOR_STROKE);
    this.beginFill(INDICATOR_FILL);
    this.drawCircle(INIDICATOR_SIZE / 2, INIDICATOR_SIZE / 2, INIDICATOR_SIZE / 2);
    this.endFill();
    this.visible = false;

    this.label = new PIXI.Text('', {
      fill: 0xffffff,
      fontSize: '11px',
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--fontFamilyBody'),
      fontWeight: '700',
      align: 'center'
    });

    this.label.anchor.set(0.5);
    this.label.x = INIDICATOR_SIZE / 2;
    this.label.y = INIDICATOR_SIZE / 2;

    this.addChild(this.label);
  }

  public setLabel(labelText: string) {
    this.label.text = labelText;
  }
}
