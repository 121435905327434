import { Cursor } from 'js/types';

type CursorAttribute = NamedNodeMap & {
  uniqueID?: {
    value: string;
  };
};

export const cursorAllowedListFilter = (cursor: Cursor, allowedList: Array<number>) => {
  const attributes = new window.DOMParser()
    .parseFromString(cursor.metadata, 'text/xml')
    .getElementsByTagName('customHand')[0].attributes as CursorAttribute;

  if (!attributes.uniqueID) {
    return false;
  }

  const uniqueId = parseInt(attributes.uniqueID.value, 10);
  return allowedList.includes(uniqueId);
};
