import { DownloadTrackingProperties } from 'js/types';

export const START_RENDER_STATUS_POLLING = 'START_RENDER_STATUS_POLLING';

export type StartRenderStatusPollingAction = {
  type: typeof START_RENDER_STATUS_POLLING;
  shortId: string;
  format: string;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export const startRenderStatusPolling = (
  shortId: string,
  format: string,
  trackingProperties: DownloadTrackingProperties,
  scribeId: number
): StartRenderStatusPollingAction => ({
  type: START_RENDER_STATUS_POLLING,
  shortId,
  format,
  trackingProperties,
  scribeId
});
