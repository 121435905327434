import { AudioClip, ExistingScribeModel, ScribeAudioLayerModelProps } from 'js/types';

export class ScribeAudioLayerModel {
  audioClipIds: Array<string>;
  id: string;

  // Constructor
  constructor(audioLayerModel: ScribeAudioLayerModelProps) {
    this.id = audioLayerModel.id;
    this.audioClipIds = audioLayerModel.audioClipIds;
  }

  getAudioClips(scribeModel: ExistingScribeModel): Array<AudioClip> {
    const audioClips: Array<AudioClip> = [];
    this.audioClipIds.forEach(audioClipId => {
      const clip = scribeModel.audioClips?.find(audioClip => audioClip.id === audioClipId);
      if (clip) audioClips.push(clip);
    });
    return audioClips;
  }
}
