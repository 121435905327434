import {
  DEFAULT_TEXT_ALIGN,
  DEFAULT_TEXT_COLOR,
  DEFAULT_TEXT_FONT_STYLE,
  DEFAULT_TEXT_FONT_WEIGHT,
  DEFAULT_TEXT_OPACITY,
  DEFAULT_TEXT_SIZE
} from 'js/config/defaults';
import { DEFAULT_TEXT_ELEMENT_COPY } from 'js/config/consts';
import { CustomTextStyleType } from 'js/types';

import { defaultFontLabel, defaultFontValue } from '../shared/resources/scribedefaults';
import {
  ScribeTextElementModelProps,
  FontAttributes,
  TextAlignValue,
  TextFontWeight,
  TextFontStyle,
  NewScribeTextElementProps,
  TextElementCustomStyles
} from '../types';

import ScribeElementModel from './ScribeElementModel';

class ScribeTextElementModel extends ScribeElementModel {
  type = 'Text' as const;
  font: FontAttributes;
  align: TextAlignValue;
  fill: string;
  fontSize: number;
  fontWeight: TextFontWeight;
  fontStyle: TextFontStyle;
  opacity: number;
  bitmapSize?: number;
  text?: string;
  styles?: TextElementCustomStyles;
  charBounds?: {
    left: number;
    width: number;
    kernedWidth?: number;
    height?: number;
    deltaY?: number;
  }[][];
  lineWidths?: number[];

  /** @deprecated */
  color = undefined;

  constructor(data: ScribeTextElementModelProps | NewScribeTextElementProps) {
    super(data, 'Text');

    this.font = data.font ?? { label: defaultFontLabel, value: defaultFontValue };

    if ('id' in data) {
      this.text = data.text;
      this.align = data.align || DEFAULT_TEXT_ALIGN;
      this.fill = data.fill || data.color || DEFAULT_TEXT_COLOR;
      this.fontSize = data.fontSize || DEFAULT_TEXT_SIZE;
      this.fontWeight = data.fontWeight || DEFAULT_TEXT_FONT_WEIGHT;
      this.fontStyle = data.fontStyle || DEFAULT_TEXT_FONT_STYLE;
      this.opacity = data.opacity ?? DEFAULT_TEXT_OPACITY;
      this.styles = data.styles ?? this.createDefaultStyles();
      this.charBounds = data.charBounds ?? [];
      this.lineWidths = data.lineWidths ?? [];
    } else {
      this.text = data.text || DEFAULT_TEXT_ELEMENT_COPY;
      this.align = data.align || DEFAULT_TEXT_ALIGN;
      this.fill = data.fill || DEFAULT_TEXT_COLOR;
      this.fontSize = data.fontSize || DEFAULT_TEXT_SIZE;
      this.fontWeight = data.fontWeight || DEFAULT_TEXT_FONT_WEIGHT;
      this.fontStyle = data.fontStyle || DEFAULT_TEXT_FONT_STYLE;
      this.opacity = data.opacity || DEFAULT_TEXT_OPACITY;
      this.styles = this.createDefaultStyles();
      this.charBounds = [];
      this.lineWidths = [];
    }
  }

  createDefaultStyles(): TextElementCustomStyles {
    if (!this.text) return {};
    const rows = this.text.split('\n');
    const result: Record<number, Record<number, CustomTextStyleType>> = {};

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const rowObject: Record<number, CustomTextStyleType> = {};

      for (let j = 0; j < row.length; j++) {
        rowObject[j] = {
          // Place any customised properties here
          fill: this.fill || DEFAULT_TEXT_COLOR
        };
      }

      result[i] = rowObject;
    }

    return result;
  }
}

export default ScribeTextElementModel;
