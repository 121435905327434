import * as PIXI from 'pixi.js';

import {
  TIMELINE_ARROW_WIDTH,
  ARROW_BOTTOM,
  TOOLTIP_BOTTOM,
  BORDER_RADIUS,
  TIMELINE_TOOLTIP_HEIGHT,
  TIMELINE_TOOLTIP_TEXT_SIZE,
  TIMELINE_TOOLTIP_TEXT_COLOUR,
  TIMELINE_TOOLTIP_TEXT_WEIGHT,
  TIMELINE_TOOLTIP_BACKGROUND_COLOUR,
  TIMELINE_TOOLTIP_BORDER_COLOUR
} from '../PixiTimeline/TimelineLayerTooltip';
import TimelineElementLayer from '../PixiTimeline/TimelineElementLayer';
import TimelineCameraLayer from '../PixiTimeline/TimelineCameraLayer';
import SceneTransitionLayer from '../PixiTimeline/SceneTransitionLayer';

import timelineBus, { PLAYHEAD_CHANGE_HEIGHT, PLAYHEAD_HIDE, PLAYHEAD_MOVE, PLAYHEAD_SHOW } from './TimelineControlBus';
import { GRABHANDLE_HIGHLIGHT_COLOUR } from './TimelineElementGrabHandle';
import { TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR, TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR } from './TimelineWrapper';
import TimelineAudioClip from './TimelineAudioClip';

const PLAYHEAD_Y_POSITION = 48;

export default class TimelinePlayHead extends PIXI.Sprite {
  playheadLine: PIXI.Graphics;
  playheadTimeDisplay: PIXI.Container;
  graphics = new PIXI.Graphics();
  textPadding = 10;
  timeLabelText: PIXI.Text;
  oldTextLength = 0;
  lineColor = GRABHANDLE_HIGHLIGHT_COLOUR;
  lineHeight = 250;

  constructor() {
    super();
    this.playheadLine = new PIXI.Graphics();
    this.playheadLine.lineStyle(1, this.lineColor, 1);
    this.addChild(this.playheadLine);
    const textStyle = new PIXI.TextStyle({
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--fontFamilyBody'),
      fontSize: TIMELINE_TOOLTIP_TEXT_SIZE,
      fill: TIMELINE_TOOLTIP_TEXT_COLOUR,
      fontWeight: TIMELINE_TOOLTIP_TEXT_WEIGHT,
      align: 'center'
    });
    this.timeLabelText = new PIXI.Text('', textStyle);
    this.timeLabelText.text = '0.2';
    this.playheadTimeDisplay = new PIXI.Container();
    this.addChild(this.playheadTimeDisplay);
    this.drawPlayheadLine();
    this.drawPlayheadTime();
    timelineBus.addListener(PLAYHEAD_CHANGE_HEIGHT, this.drawPlayheadLine, this);
    timelineBus.addListener(PLAYHEAD_SHOW, this.showPlayhead, this);
    timelineBus.addListener(PLAYHEAD_HIDE, this.hidePlayhead, this);
    timelineBus.addListener(PLAYHEAD_MOVE, this.movePlayhead, this);
    this.hidePlayhead();
  }

  private drawPlayheadLine = (height = 250) => {
    if (!this.playheadLine) {
      this.playheadLine = new PIXI.Graphics();
      this.playheadTimeDisplay = new PIXI.Container();

      this.addChild(this.playheadTimeDisplay);
      this.visible = false;
    } else {
      this.playheadLine.clear();
    }
    this.playheadLine.lineStyle(1, this.lineColor, 0.6);
    this.playheadLine.moveTo(0, PLAYHEAD_Y_POSITION);
    this.playheadLine.lineTo(0, height);
    this.lineHeight = height;
  };

  private showPlayhead = (
    trigger: TimelineElementLayer | TimelineCameraLayer | SceneTransitionLayer | TimelineAudioClip
  ) => {
    this.lineColor =
      trigger instanceof TimelineCameraLayer ? TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR : GRABHANDLE_HIGHLIGHT_COLOUR;
    if (trigger instanceof TimelineAudioClip) this.lineColor = TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR;

    this.drawPlayheadLine(this.lineHeight);
    this.visible = true;
  };

  private hidePlayhead = () => {
    this.visible = false;
  };

  private movePlayhead = (position: number, time: string) => {
    this.x = position;
    this.drawText(time);
  };

  public drawText(text: string): void {
    this.timeLabelText.text = text.replace(/[\r\n]/g, ' ');
    this.timeLabelText.x = -this.timeLabelText.width / 2;
    if (this.timeLabelText.text.length !== this.oldTextLength) {
      this.drawPlayheadTime();
    }
    this.timeLabelText.y = this.graphics.y - this.graphics.height / 2 + this.timeLabelText.height / 2;
    this.oldTextLength = this.timeLabelText.text.length;
  }

  private drawPlayheadTime() {
    this.graphics.clear();
    this.graphics.beginFill(TIMELINE_TOOLTIP_BACKGROUND_COLOUR, 0.9);
    this.graphics.lineStyle(1, TIMELINE_TOOLTIP_BORDER_COLOUR);
    const textWidthWithPadding = this.textPadding * 2 + this.timeLabelText.width;
    const TOOLTIP_TOP = TOOLTIP_BOTTOM - TIMELINE_TOOLTIP_HEIGHT;
    this.graphics.moveTo(textWidthWithPadding / 2, ARROW_BOTTOM);
    this.graphics.lineTo(textWidthWithPadding / 2 - TIMELINE_ARROW_WIDTH / 2, TOOLTIP_BOTTOM);
    this.graphics.lineTo(BORDER_RADIUS, TOOLTIP_BOTTOM);
    this.graphics.quadraticCurveTo(0, TOOLTIP_BOTTOM, 0, TOOLTIP_BOTTOM - BORDER_RADIUS);
    this.graphics.lineTo(0, TOOLTIP_TOP + BORDER_RADIUS);
    this.graphics.quadraticCurveTo(0, TOOLTIP_TOP, BORDER_RADIUS, TOOLTIP_TOP);
    this.graphics.lineTo(textWidthWithPadding - BORDER_RADIUS, TOOLTIP_TOP);
    this.graphics.quadraticCurveTo(
      textWidthWithPadding,
      TOOLTIP_TOP,
      textWidthWithPadding,
      TOOLTIP_TOP + BORDER_RADIUS
    );
    this.graphics.lineTo(textWidthWithPadding, TOOLTIP_BOTTOM - BORDER_RADIUS);
    this.graphics.quadraticCurveTo(
      textWidthWithPadding,
      TOOLTIP_BOTTOM,
      textWidthWithPadding - BORDER_RADIUS,
      TOOLTIP_BOTTOM
    );
    this.graphics.lineTo(textWidthWithPadding / 2 + TIMELINE_ARROW_WIDTH / 2, TOOLTIP_BOTTOM);
    this.graphics.lineTo(textWidthWithPadding / 2, ARROW_BOTTOM);
    this.playheadTimeDisplay.addChild(this.graphics, this.timeLabelText);
    this.graphics.x = -this.graphics.width / 2;
    this.graphics.y = PLAYHEAD_Y_POSITION;
  }
}
