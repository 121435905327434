import React from 'react';
import { useDispatch } from 'react-redux';
import VSlogo from 'imgs/videoscribe-logo-h.svg';
import { showError } from 'js/actionCreators/uiActions';
import { userAcceptsTerms } from 'js/actionCreators/acceptedTermsActions';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { userAcceptsMarketing } from 'js/actionCreators/acceptedMarketingActions';

import { CallToActionButton } from '../CallToAction';

import './AcceptTermsAndMarketing.css';

const AcceptTermsAndMarketing = () => {
  const dispatch = useDispatch();
  const acceptingTerms = useTypedSelector(state => state.ui.acceptingTerms);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (acceptingTerms) return;

    if (!(event.target as HTMLFormElement).terms.checked) {
      dispatch(showError(new Error('Please accept our terms to continue.')));
      return;
    }

    dispatch(userAcceptsTerms());

    if ((event.target as HTMLFormElement).marketing.checked) {
      dispatch(userAcceptsMarketing());
    }
  };

  return (
    <div className="AcceptTermsAndMarketing">
      <header>
        <a href="/en/">
          <img src={VSlogo} alt="VideoScribe logo" />
        </a>
      </header>
      <main className="AcceptTermsAndMarketing__main">
        <h1 className="AcceptTermsAndMarketing__heading">Accept our terms to continue</h1>
        <form onSubmit={handleSubmit} className="AcceptTermsAndMarketing__form">
          <label htmlFor="terms">
            <input type="checkbox" name="terms" id="terms" />
            <span>
              I agree to Sparkol's{' '}
              <a href="https://www.sparkol.com/website-terms/" target="_blank" rel="noreferrer">
                Website Terms of Use
              </a>{' '}
              and{' '}
              <a href="https://www.sparkol.com/sparkol-terms/" target="_blank" rel="noreferrer">
                App Terms and Conditions
              </a>
              .
            </span>
          </label>

          <label htmlFor="marketing">
            <input type="checkbox" name="marketing" id="marketing" />
            <span>Yes, please send me emails including product updates, news and offers.</span>
          </label>

          <CallToActionButton fullWidth theme="dark" disabled={acceptingTerms}>
            {!acceptingTerms ? 'Continue' : 'Accepting terms...'}
          </CallToActionButton>
        </form>
      </main>
    </div>
  );
};

export default AcceptTermsAndMarketing;
