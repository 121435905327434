import { getSvgHeightAndWidth } from 'js/shared/providers';
import { getSvgDimensions } from 'js/shared/helpers/image';
import { fitElementToCanvas } from 'js/shared/helpers/element';

export default async function applyImageHeightAndWidth(imageEl, canvasSize) {
  const { url } = await getSvgHeightAndWidth({
    provider: imageEl.image.provider,
    contentType: imageEl.image.contentType,
    assetId: imageEl.image.assetId ?? imageEl.image.imageId
  });

  const svgDimensions = await getSvgDimensions(url);
  const { scaleX, scaleY, x, y, width, height } = {
    ...fitElementToCanvas(svgDimensions.width, svgDimensions.height, canvasSize),
    width: svgDimensions.width,
    height: svgDimensions.height
  };

  imageEl.scaleX = scaleX;
  imageEl.scaleY = scaleY;
  imageEl.x = x;
  imageEl.y = y;
  imageEl.width = width;
  imageEl.height = height;

  return imageEl;
}
