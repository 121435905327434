import './CategoriesView.css';

import React, { FC } from 'react';
import { ImageCategory } from 'js/types';

import CategoriesList from '../CategoriesList';

type CategoriesViewProps = {
  onCategoryClick: (category: ImageCategory) => void;
};

const CategoriesView: FC<CategoriesViewProps> = ({ onCategoryClick }) => (
  <div className="CategoriesView">
    <h2 className="CategoriesView__heading">All categories</h2>
    <CategoriesList onCategoryClick={onCategoryClick} />
  </div>
);

export default CategoriesView;
