import { updateLimits } from 'js/actionCreators/limitActions';
import { Limits } from 'js/types';
import { call, put, CallEffect, PutEffect, takeEvery } from 'redux-saga/effects';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { GET_VIDEO_DOWNLOAD_URL_SUCCESS } from 'js/actionCreators/downloadActions';
import {
  GENERATE_SCRIPT_SUCCESS,
  IMAGE_GENERATION_SUCCESS,
  VOICEOVER_GENERATION_SUCCESS
} from 'js/actionCreators/betaAiFeaturesActions';

export function* fetchAndUpdateLimits(): Generator<CallEffect | PutEffect, void, Limits> {
  try {
    const limits = yield call(appServices.getLimits);
    yield put(updateLimits(limits));
  } catch (error) {
    console.error('Error fetching and updating limits:', error);
  }
}

export default function* limitSagas() {
  yield takeEvery(AUTH_SUCCESS, fetchAndUpdateLimits);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, fetchAndUpdateLimits);
  yield takeEvery(IMAGE_GENERATION_SUCCESS, fetchAndUpdateLimits);
  yield takeEvery(VOICEOVER_GENERATION_SUCCESS, fetchAndUpdateLimits);
  yield takeEvery(GENERATE_SCRIPT_SUCCESS, fetchAndUpdateLimits);
}
