import {
  AssetLibrarySource,
  LOAD_AUDIO_LIBRARY,
  loadAudioLibraryFailed,
  loadAudioLibrarySuccess,
  AssetLibrarySourceIdType
} from 'js/actionCreators/audioLibraryActions';
import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { VSCAssetAudioData, VSCAudioLibraryApiData, VSCAssetAudioSourceName } from 'js/types';
import { CallEffect, PutEffect, call, put, takeEvery, SelectEffect, select } from 'redux-saga/effects';

import { getUserAudioAssets } from './selectors';

function* loadAudioLibrary(): Generator<CallEffect | PutEffect, void, Array<VSCAudioLibraryApiData>> {
  try {
    const data = yield call(appServices.getAudioLibrary);
    yield put(loadAudioLibrarySuccess(data));
  } catch (error) {
    if (error instanceof Error) {
      yield put(showError({ message: 'Failed to load audio library data', description: error.message }));
      yield put(loadAudioLibraryFailed(error));
    }
  }
}

export function* getLibraryItemAssetFromUserAudioList(
  libraryItemId: AssetLibrarySourceIdType<AssetLibrarySource>
): Generator<SelectEffect, VSCAssetAudioData | undefined, VSCAssetAudioData[]> {
  const userAudioAssets = yield select(getUserAudioAssets);
  return userAudioAssets.find(
    asset =>
      asset.sourceIdentifier === libraryItemId.toString() && asset.sourceName === VSCAssetAudioSourceName.SPARKOL_AUDIO
  );
}

export function* createAudioAssetByLibraryItemId(
  library: AssetLibrarySource,
  libraryItemId: AssetLibrarySourceIdType<AssetLibrarySource>
): Generator<CallEffect, VSCAssetAudioData, VSCAssetAudioData | number> {
  const assetId = yield call(appServices.createProjectAssetFromLibrary, library, libraryItemId);
  // App services GET endpoint is not proper REST as calling GET gives you signed URLs to the asset,
  // not the assets actual metadata which is what we want in this scenario. Calling the `PATCH` endpoint with
  // no update `{}` will give us back the actual asset metadata we want.
  const asset = yield call(appServices.updateAssetMetadata, assetId, {});
  return asset as VSCAssetAudioData;
}

export default function* audioLibrarySagas() {
  yield takeEvery(LOAD_AUDIO_LIBRARY, loadAudioLibrary);
}
