import * as PIXI from 'pixi.js';
export type KeysType =
  | 'Backspace'
  | 'Tab'
  | 'Enter'
  | 'ShiftLeft'
  | 'ShiftRight'
  | 'ControlLeft'
  | 'ControlRight'
  | 'AltLeft'
  | 'AltRight'
  | 'Pause'
  | 'CapsLock'
  | 'Escape'
  | 'Space'
  | 'PageUp'
  | 'PageDown'
  | 'End'
  | 'Home'
  | 'ArrowLeft'
  | 'ArrowUp'
  | 'ArrowRight'
  | 'ArrowDown'
  | 'PrintScreen'
  | 'Insert'
  | 'Delete'
  | 'Digit0'
  | 'Digit1'
  | 'Digit2'
  | 'Digit3'
  | 'Digit4'
  | 'Digit5'
  | 'Digit6'
  | 'Digit7'
  | 'Digit8'
  | 'Digit9'
  | 'KeyA'
  | 'KeyB'
  | 'KeyC'
  | 'KeyD'
  | 'KeyE'
  | 'KeyF'
  | 'KeyG'
  | 'KeyH'
  | 'KeyI'
  | 'KeyJ'
  | 'KeyK'
  | 'KeyL'
  | 'KeyM'
  | 'KeyN'
  | 'KeyO'
  | 'KeyP'
  | 'KeyQ'
  | 'KeyR'
  | 'KeyS'
  | 'KeyT'
  | 'KeyU'
  | 'KeyV'
  | 'KeyW'
  | 'KeyX'
  | 'KeyY'
  | 'KeyZ'
  | 'MetaLeft'
  | 'MetaRight'
  | 'ContextMenu'
  | 'Numpad0'
  | 'Numpad1'
  | 'Numpad2'
  | 'Numpad3'
  | 'Numpad4'
  | 'Numpad5'
  | 'Numpad6'
  | 'Numpad7'
  | 'Numpad8'
  | 'Numpad9'
  | 'NumpadMultiply'
  | 'NumpadAdd'
  | 'NumpadSubtract'
  | 'NumpadDecimal'
  | 'NumpadDivide'
  | 'F1'
  | 'F2'
  | 'F3'
  | 'F4'
  | 'F5'
  | 'F6'
  | 'F7'
  | 'F8'
  | 'F9'
  | 'F10'
  | 'F11'
  | 'F12'
  | 'NumLock'
  | 'ScrollLock'
  | 'Semicolon'
  | 'Equal'
  | 'Comma'
  | 'Minus'
  | 'Period'
  | 'Slash'
  | 'Backquote'
  | 'BracketLeft'
  | 'Backslash'
  | 'BracketRight'
  | 'Quote';

export class Input {
  private inputObjects: PIXI.DisplayObject[] | null;
  private dispatcher?: PIXI.utils.EventEmitter | null;
  private downButtons: PIXI.DisplayObject[];
  private downThisFrameButtons: PIXI.DisplayObject[];
  private downKeys: KeysType[];
  private downThisFrameKeys: KeysType[];
  public constructor(dispatcher?: PIXI.utils.EventEmitter) {
    this.downButtons = [];
    this.downThisFrameButtons = [];
    this.inputObjects = [];
    this.downKeys = [];
    this.downThisFrameKeys = [];
    this.initListeners();
    if (dispatcher) {
      this.dispatcher = dispatcher;
      this.dispatcher.addListener('lateTick', this.update);
    }
  }

  public manualUpdate(): void {
    this.update();
  }

  public registerInputButton(pixiDisplayObject: PIXI.DisplayObject): void {
    pixiDisplayObject.interactive = true;
    pixiDisplayObject.buttonMode = true;
    pixiDisplayObject.addListener('mousedown', this.buttonDown);
    pixiDisplayObject.addListener('mouseup', this.buttonUp);
    pixiDisplayObject.addListener('mouseupoutside', this.buttonUp);
    pixiDisplayObject.addListener('touchstart', this.buttonDown);
    pixiDisplayObject.addListener('touchend', this.buttonUp);
    pixiDisplayObject.addListener('touchendoutside', this.buttonUp);
    pixiDisplayObject.addListener('pointerdown', this.buttonDown);
    pixiDisplayObject.addListener('pointerup', this.buttonUp);
    this.inputObjects?.push(pixiDisplayObject);
  }
  public unregisterInputButton(pixiDisplayObject: PIXI.DisplayObject): void {
    pixiDisplayObject.addListener('pointerdown', this.buttonDown);
    pixiDisplayObject.addListener('pointerup', this.buttonUp);
    const i = this.inputObjects?.indexOf(pixiDisplayObject);
    if (i !== -1 && i !== undefined) {
      this.inputObjects?.splice(i, 1);
    }
  }

  public Dispose(): void {
    this.dispatcher?.removeListener('tick', this.update);
    this.dispatcher = null;

    this.inputObjects?.forEach(ob => {
      ob.removeListener('pointerdown', this.buttonDown);
      ob.removeListener('pointerup', this.buttonUp);
    });

    this.inputObjects = null;
  }

  public GetKeyDown(key: KeysType): boolean {
    return this.downKeys.indexOf(key) !== -1;
  }
  public GetKeyDownThisFrame(key: KeysType): boolean {
    return this.downThisFrameKeys.indexOf(key) !== -1;
  }

  public GetButtonDown(key: PIXI.DisplayObject): boolean {
    return this.downButtons.indexOf(key) !== -1;
  }
  public GetButtonDownThisFrame(key: PIXI.DisplayObject): boolean {
    return this.downThisFrameButtons.indexOf(key) !== -1;
  }
  private initListeners(): void {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  }

  private buttonUp = (e: PIXI.InteractionEvent): void => {
    const pixiObject = e.currentTarget;
    const index = this.downButtons.indexOf(pixiObject);
    if (index !== -1) {
      this.downButtons.splice(index, 1);
    }
  };

  private buttonDown = (e: PIXI.InteractionEvent): void => {
    const pixiObject = e.currentTarget;
    this.downKeys = [];
    if (this.downButtons.indexOf(pixiObject) === -1) {
      this.downButtons.push(pixiObject);
      if (this.downThisFrameButtons.indexOf(pixiObject) === -1) {
        this.downThisFrameButtons.push(pixiObject);
      }
    }
  };
  private onKeyUp = (e: KeyboardEvent): void => {
    const key: KeysType = e.code as KeysType;
    const index = this.downKeys.indexOf(key);
    if (index !== -1) {
      this.downKeys.splice(index, 1);
    }
  };

  private onKeyDown = (e: KeyboardEvent): void => {
    const key: KeysType = e.code as KeysType;
    if (this.downKeys.indexOf(key) === -1) {
      this.downKeys.push(key);
      if (this.downThisFrameKeys.indexOf(key) === -1) {
        this.downThisFrameKeys.push(key);
      }
    }
  };

  public clearKeyDown(): void {
    this.downKeys = [];
  }

  public clearBtnsDown(): void {
    this.downButtons = [];
  }

  private update = (): void => {
    this.downThisFrameKeys = [];
    this.downThisFrameButtons = [];
  };
}
