import { call, takeEvery, select } from 'redux-saga/effects';
import {
  CREATE_TEXT_ELEMENT,
  SET_SHOW_ELEMENT_MODAL,
  LOCK_ELEMENTS,
  UNLOCK_ELEMENTS,
  SET_ELEMENTS_TIMINGS
} from 'js/actionCreators/scribeActions';
import { getScribeById } from 'js/sagas/selectors';
import {
  TRACK_FREE_TRANSFORM_SELECTED,
  TRACK_FLIP_ELEMENT,
  TRACK_SELECT_FONT,
  TRACK_ALIGN_TEXT,
  TRACK_BOLD_TEXT,
  TRACK_ITALIC_TEXT,
  TRACK_CHANGE_ELEMENT_COLOR
} from 'js/actionCreators/trackingActions';
import { SET_ELEMENT_TEXT_SIZE } from 'js/actionCreators/elementActions';
import debounce from 'lodash.debounce';

import { track } from '../mixpanelProvider';

function* trackAddText({ scribeId }) {
  yield call(track, 'Add Text To Canvas', { 'Scribe ID': scribeId });
}

function* trackSetShowElementModal({ scribeId, elementType }) {
  if (elementType === 'image') {
    yield call(track, 'Open Image Modal', { 'Scribe ID': scribeId });
  }
  if (elementType === 'shape') {
    yield call(track, 'Open Shape Modal', { 'Scribe ID': scribeId });
  }
}

function* trackLockElements({ type, scribeId, elementIds, eventTrigger }) {
  const eventName = type === UNLOCK_ELEMENTS ? 'Unlock Element' : 'Lock Element';

  if (elementIds.length > 1) {
    yield call(track, eventName, { 'Scribe ID': scribeId, 'Element Type': 'Multiple', 'Event Trigger': eventTrigger });
  } else {
    const scribe = yield select(state => getScribeById(state, scribeId));
    const element = scribe.elements.find(el => el.id === elementIds[0]);
    if (element) {
      yield call(track, eventName, {
        'Scribe ID': scribeId,
        'Element Type': element.type,
        'Event Trigger': eventTrigger
      });
    }
  }
}

function* trackFreeTransformSelected({ selected, elementType, scribeId }) {
  const action = selected ? 'Select Free Transform Element' : 'Deselect Free Transform Element';
  yield call(track, action, { 'Scribe ID': scribeId, 'Element Type': elementType });
}

function* trackFlipElement({ direction, elementType, scribeId }) {
  const action = direction === 'vertical' ? 'Flip Vertically Element' : 'Flip Horizontally Element';
  yield call(track, action, { 'Scribe ID': scribeId, 'Element Type': elementType });
}

function* trackSelectFont({ fontName, scribeId }) {
  yield call(track, 'Select Font', { 'Font Name': fontName, 'Scribe ID': scribeId });
}

function* trackAlignText({ alignment, scribeId }) {
  yield call(track, 'Align Text', { Alignment: alignment, 'Scribe ID': scribeId });
}

function* trackBoldText({ selected, scribeId, eventTrigger }) {
  yield call(track, 'Bold Text', {
    'Scribe ID': scribeId,
    [selected ? 'Select' : 'Deselect']: true,
    'Event Trigger': eventTrigger
  });
}

function* trackItalicText({ selected, scribeId, eventTrigger }) {
  yield call(track, 'Italic Text', {
    'Scribe ID': scribeId,
    [selected ? 'Select' : 'Deselect']: true,
    'Event Trigger': eventTrigger
  });
}

function* trackChangeElementColor({
  elementType,
  colorType,
  gradientDirection,
  colorTab,
  scribeId,
  colorLayer,
  imageName
}) {
  yield call(track, 'Set Element Colour', {
    'Element Type': elementType,
    'Colour Type': colorType,
    'Colour Tab': colorTab,
    'Gradient Direction': gradientDirection,
    'Scribe ID': scribeId,
    'Image Name': imageName,
    'Image Recolor Type': colorLayer
  });
}

const debouncedTrack = debounce(track, 1500);

function* debouncedTrackSaga(eventName, properties, options) {
  yield debouncedTrack(eventName, properties, options);
}

function* trackSetCameraElementTimings({ scribeId, elementIds, propertyValuePair, eventTrigger }) {
  const { animationTime, pauseTime } = propertyValuePair;
  const baseEventProps = {
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger
  };
  const scribe = yield select(state => getScribeById(state, scribeId));
  const cameras = scribe.elements.filter(el => el.type === 'Camera' && elementIds.includes(el.id));

  if (cameras.length) {
    const isSettingPauseTime = typeof pauseTime !== 'undefined';
    const isSettingMovementTime = typeof animationTime !== 'undefined';

    if (isSettingMovementTime) {
      yield call(debouncedTrackSaga, 'Set Camera Movement Timing', {
        ...baseEventProps,
        'Movement Timing (seconds)': animationTime
      });
    }

    if (isSettingPauseTime) {
      yield call(debouncedTrackSaga, 'Set Camera Pause Timing', {
        ...baseEventProps,
        'Pause Timing (seconds)': pauseTime
      });
    }
  }
}

function* trackSetElementsTimings({ scribeId, elementIds, propertyValuePair, eventTrigger }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const elements = scribe.elements.filter(el => el.type !== 'Camera' && elementIds.includes(el.id));

  let elementType;
  if (elements.length > 1) {
    elementType = 'Multiple';
  } else if (elements.length === 1) {
    elementType = elements[0].type;
  } else {
    return;
  }

  const { animationTime, emphasisAnimationTime, exitAnimationTime, pauseTime } = propertyValuePair;

  const isSettingPauseTime = typeof pauseTime !== 'undefined';
  const isSettingEntranceTime = typeof animationTime !== 'undefined';
  const isSettingEmphasisTime = typeof emphasisAnimationTime !== 'undefined';
  const isSettingExitTime = typeof exitAnimationTime !== 'undefined';

  const baseEventProps = {
    'Element Type': elementType,
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger
  };

  if (isSettingPauseTime) {
    yield call(debouncedTrackSaga, 'Set Pause Timing', {
      ...baseEventProps,
      'Pause Timing (seconds)': pauseTime
    });
  }

  if (isSettingEntranceTime || isSettingEmphasisTime || isSettingExitTime) {
    yield call(debouncedTrackSaga, 'Set Animation Timing', {
      ...baseEventProps,
      ...(isSettingEntranceTime ? { 'Entrance Animation Timing (Seconds)': animationTime } : {}),
      ...(isSettingEmphasisTime ? { 'Emphasis Animation Timing (Seconds)': emphasisAnimationTime } : {}),
      ...(isSettingExitTime ? { 'Exit Animation Timing (Seconds)': exitAnimationTime } : {})
    });
  }
}

function* trackFontSize({ scribeId, update }) {
  yield call(track, 'Set Font Size', { 'Font Size': update.fontSize, 'Scribe ID': scribeId });
}

export default function* trackCanvasEvents() {
  yield takeEvery(CREATE_TEXT_ELEMENT, trackAddText);
  yield takeEvery(SET_SHOW_ELEMENT_MODAL, trackSetShowElementModal);
  yield takeEvery([LOCK_ELEMENTS, UNLOCK_ELEMENTS], trackLockElements);
  yield takeEvery(TRACK_FREE_TRANSFORM_SELECTED, trackFreeTransformSelected);
  yield takeEvery(TRACK_FLIP_ELEMENT, trackFlipElement);
  yield takeEvery(TRACK_SELECT_FONT, trackSelectFont);
  yield takeEvery(TRACK_ALIGN_TEXT, trackAlignText);
  yield takeEvery(TRACK_BOLD_TEXT, trackBoldText);
  yield takeEvery(TRACK_ITALIC_TEXT, trackItalicText);
  yield takeEvery(TRACK_CHANGE_ELEMENT_COLOR, trackChangeElementColor);
  yield takeEvery(SET_ELEMENTS_TIMINGS, trackSetElementsTimings);
  yield takeEvery(SET_ELEMENTS_TIMINGS, trackSetCameraElementTimings);
  yield takeEvery(SET_ELEMENT_TEXT_SIZE, trackFontSize);
}
