import { AlignmentAction } from 'js/editor/EditPanels/AlignmentControls';
import { DistributionAction } from 'js/editor/EditPanels/DistributionControls';

export const ALIGN_SELECTED_ELEMENTS = 'ALIGN_SELECTED_ELEMENTS';
export const DISTRIBUTE_SELECTED_ELEMENTS = 'DISTRIBUTE_SELECTED_ELEMENTS';

export interface AlignSelectedElementsAction {
  type: typeof ALIGN_SELECTED_ELEMENTS;
  alignment: AlignmentAction;
  projectId: number;
}

export const alignSelectedElements = (alignment: AlignmentAction, projectId: number): AlignSelectedElementsAction => ({
  type: ALIGN_SELECTED_ELEMENTS,
  alignment,
  projectId
});

export interface DistributeSelectedElementsAction {
  type: typeof DISTRIBUTE_SELECTED_ELEMENTS;
  distribution: DistributionAction;
  projectId: number;
}

export const distributeSelectedElements = (
  alignment: DistributionAction,
  projectId: number
): DistributeSelectedElementsAction => ({
  type: DISTRIBUTE_SELECTED_ELEMENTS,
  distribution: alignment,
  projectId
});
