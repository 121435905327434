const getGroupVisibleProperties = (locked: boolean, hidden: boolean) => {
  if (locked && hidden) {
    return {
      selectable: false,
      evented: false
    };
  } else if (locked && !hidden) {
    return {
      selectable: false,
      evented: false
    };
  } else if (!locked && hidden) {
    return {
      selectable: false,
      evented: false
    };
  } else if (!locked && !hidden) {
    return {
      selectable: true,
      evented: true
    };
  }
};

export default getGroupVisibleProperties;
