import './CategoryList.css';

import React, { FC } from 'react';
import { ImageCategory } from 'js/types';
import Skeleton from 'js/shared/components/Skeleton';
import { useDispatch } from 'react-redux';
import { trackImageLibraryClick } from 'js/actionCreators/trackingActions';
import { useProject } from 'js/shared/hooks/useProject';

import CategoryTile from '../CategoryTile';
import { useLibraries } from '../hooks/useLibraries';

const CategoriesListSkeleton = () => {
  const skeletonArray = Array.from({ length: 12 });

  return (
    <>
      {skeletonArray.map((_, index) => (
        <div key={index} className="CategoryList__skeleton">
          <Skeleton />
        </div>
      ))}
    </>
  );
};

type CategoriesListProps = {
  onCategoryClick: (category: ImageCategory) => void;
};

const CategoriesList: FC<CategoriesListProps> = ({ onCategoryClick }) => {
  const dispatch = useDispatch();
  const project = useProject();
  const [loading, libraries] = useLibraries();

  const handleTileClick = (library: ImageCategory) => {
    if (project) {
      dispatch(trackImageLibraryClick(library, project.id, 'Category Index'));
    }
    onCategoryClick(library);
  };

  return (
    <div className="CategoryList">
      {loading ? (
        <CategoriesListSkeleton />
      ) : (
        libraries.map(library => (
          <CategoryTile
            key={library.id}
            title={library.editorName}
            onClick={() => handleTileClick(library)}
            thumbnail={library.thumbnailUrl}
          />
        ))
      )}
    </div>
  );
};

export default CategoriesList;
