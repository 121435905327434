import chunk from 'lodash.chunk';
import { PathingPoint, generateMaskPath } from 'js/shared/helpers/text-draw';
import { thin } from 'js/shared/helpers/thinning';
import { getDistanceBetweenPoints } from 'js/shared/helpers/trig';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { getSVGSkeletonPaths, storeSVGSkeletonPaths } from 'js/shared/lib/LocalDatabase';
import { scaleTextureForThinning } from 'js/shared/helpers/scaleTextureForThinning';

import { findSeparateImageShapes } from './findSeparateImageShapes';
import { debugPathing } from './debugPathing';

interface GeneratePathFromSkeletonsOptions {
  pixels: Uint8ClampedArray;
  width: number;
  height: number;
  element: ScribeImageElementModel;
}

export interface PathingPointsWithMetadata {
  points: Array<PathingPoint & { pathLength: number }>;
  brushSize: number;
  totalLength: number;
}

export const generatePathFromSkeletons = async ({
  pixels,
  width,
  height,
  element
}: GeneratePathFromSkeletonsOptions): Promise<Array<PathingPointsWithMetadata>> => {
  if (element.image) {
    const dbCached = await getSVGSkeletonPaths(element.image.assetId.toString());
    if (dbCached) {
      return dbCached.data;
    }
  }

  const { scaledData, scaleFactor } = scaleTextureForThinning(pixels, width, height);

  const binaryPixels = chunk(scaledData.data, 4).map(pixel => (pixel.some(color => color > 0) ? 1 : 0));

  if (binaryPixels.every(pixel => pixel === 1) || binaryPixels.every(pixel => pixel === 0)) {
    throw new Error('Image is solid shape. Cannot generate pathing for this image.');
  }

  const pixelsByRow = chunk(binaryPixels, scaledData.width);
  const shapes = findSeparateImageShapes({ data: pixelsByRow });

  const commands = shapes.map(shape => {
    const [thinned, iterations] = thin(shape.data.flat(), scaledData.width, scaledData.height);
    const paths = generateMaskPath(thinned).flat() || [];

    if (scaleFactor !== 1) {
      paths.forEach(path => {
        path.x = path.x / scaleFactor;
        path.y = path.y / scaleFactor;
      });
    }

    const points = paths.map((path, i, arr) => ({
      ...path,
      pathLength: i === 0 || path.moveTo ? 0 : getDistanceBetweenPoints(arr[i - 1], path)
    }));

    const totalLength = points.reduce((acc, point) => {
      return acc + point.pathLength;
    }, 0);

    return {
      points,
      brushSize: iterations / scaleFactor,
      totalLength
    };
  });

  if (import.meta.env.DEV && import.meta.env.VITE_DEBUG_PATHING_ALGORITHM) {
    // Wrapping this in the logic with import.meta.env.DEV means this entire block of code and dependency module will
    // be remove in production builds. Dead code elimination FTW!
    debugPathing(commands.flatMap(com => com.points));
  }

  if (element.image) {
    await storeSVGSkeletonPaths(element.image.assetId.toString(), commands);
  }

  return commands;
};
