import * as PIXI from 'pixi.js';
import { VSElementModel } from 'js/types';
import { deg2rad } from 'js/shared/helpers/trig';

import { getViewportData } from './getViewportData';

export const getElementBoundingRectangle = (
  element: VSElementModel,
  canvasDimensions: ReturnType<typeof getViewportData>
) => {
  /**
   * The data we get from Farbic.js and subsequently what is stored is the transformed top left corner position
   * so to find the bounding box, we need to first find the center point and then
   * find the transformed corners of the element. From that we can then build a bounding box
   *
   * Reference for technique: https://math.stackexchange.com/questions/4390290/how-to-calculate-the-new-x-and-y-coordinates-of-a-scaled-rotated-rectangle#4390328
   */
  const ax = element.x;
  const ay = element.y; // x and y are already transformed

  const width = element.type === 'Camera' ? canvasDimensions.width : element.width;
  const height = element.type === 'Camera' ? canvasDimensions.height : element.height;
  const scaleX = element.type === 'Camera' ? 1 / element.scale : element.scaleX;
  const scaleY = element.type === 'Camera' ? 1 / element.scale : element.scaleY;
  const angle = deg2rad(element.type === 'Camera' ? 0 : element.angle);

  const x1 = Math.sin(angle) * ((height * scaleY) / 2);
  const y1 = Math.cos(angle) * ((height * scaleY) / 2);

  const x2 = Math.cos(angle) * ((width * scaleX) / 2);
  const y2 = Math.sin(angle) * ((width * scaleX) / 2);

  const centerX = ax + (x2 - x1);
  const centerY = ay + (y1 + y2);

  const originalScaledCorners = [
    { x: centerX - (width * scaleX) / 2, y: centerY - (height * scaleY) / 2 },
    { x: centerX + (width * scaleX) / 2, y: centerY - (height * scaleY) / 2 },
    { x: centerX + (width * scaleX) / 2, y: centerY + (height * scaleY) / 2 },
    { x: centerX - (width * scaleX) / 2, y: centerY + (height * scaleY) / 2 }
  ];

  const transformedCorners = originalScaledCorners.map(corner => {
    const x = centerX + Math.cos(angle) * (corner.x - centerX) - Math.sin(angle) * (corner.y - centerY);
    const y = centerY + Math.sin(angle) * (corner.x - centerX) + Math.cos(angle) * (corner.y - centerY);
    return { x, y };
  });

  const cornerXs = transformedCorners.map(point => point.x);
  const cornerYs = transformedCorners.map(point => point.y);

  const minX = Math.min(...cornerXs);
  const maxX = Math.max(...cornerXs);
  const minY = Math.min(...cornerYs);
  const maxY = Math.max(...cornerYs);

  return new PIXI.Rectangle(minX, minY, maxX - minX, maxY - minY);
};
