import { ROLES, SCOPE } from 'js/config/consts';

export const PERMISSIONS: Record<keyof typeof ROLES, SCOPE[]> = {
  FREE: [SCOPE.createPreviewLink, SCOPE.playbackPremiumContentWithoutWatermarks],
  PRO: [
    SCOPE.searchNounProject,
    SCOPE.addCustomFont,
    SCOPE.createLongVideo,
    SCOPE.createPreviewLink,
    SCOPE.usePremiumContent,
    SCOPE.downloadPremiumContent,
    SCOPE.playbackPremiumContentWithoutWatermarks
  ],
  PLUS: [
    SCOPE.searchNounProject,
    SCOPE.addCustomFont,
    SCOPE.createLongVideo,
    SCOPE.createPreviewLink,
    SCOPE.usePremiumContent,
    SCOPE.downloadPremiumContent,
    SCOPE.playbackPremiumContentWithoutWatermarks
  ],
  EDU: [
    SCOPE.searchNounProject,
    SCOPE.addCustomFont,
    SCOPE.createLongVideo,
    SCOPE.createPreviewLink,
    SCOPE.usePremiumContent,
    SCOPE.downloadPremiumContent,
    SCOPE.playbackPremiumContentWithoutWatermarks
  ],
  LITE: [SCOPE.downloadPremiumContent],
  CORE: [
    SCOPE.searchNounProject,
    SCOPE.addCustomFont,
    SCOPE.createLongVideo,
    SCOPE.createPreviewLink,
    SCOPE.usePremiumContent,
    SCOPE.downloadPremiumContent,
    SCOPE.playbackPremiumContentWithoutWatermarks
  ],
  MAX: [
    SCOPE.searchNounProject,
    SCOPE.addCustomFont,
    SCOPE.createLongVideo,
    SCOPE.createPreviewLink,
    SCOPE.usePremiumContent,
    SCOPE.downloadPremiumContent,
    SCOPE.playbackPremiumContentWithoutWatermarks
  ]
};
