import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationRight({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path
        d="M42.8,28.6L29.1,14.8l-2.2,2.2l11.1,11.1H16.4v3.2h21.5L26.8,42.3l2.2,2.2l13.8-13.8c0.3-0.3,0.5-0.7,0.5-1.1
	S43.1,28.9,42.8,28.6z"
      />
    </svg>
  );
}
