import svgToDataUrl from 'js/shared/helpers/svgToDataUrl';
import { convert } from 'js/shared/lib/SVGConvert';
import * as PIXI from 'pixi.js';
import { LoaderResource } from 'pixi.js';
import { ExistingScribeModel, PlaybackScribeModel, SVGAsset, VSCSVGLoaderResource } from 'js/types';
import { getConvertedSVGData, storeConvertedSVGData } from 'js/shared/lib/LocalDatabase';
import { FILE_CONTENT_TYPES } from 'js/config/consts';
import { constructSVGAssetKey } from 'js/shared/lib/LocalDatabase/keys';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { getImagePixelsFromUrl } from 'js/shared/helpers/getImagePixelsFromUrl';
import { SVG_CANVAS_SIZE } from 'js/config/defaults';

import svgSplitter from '../svgSplitter';
import setupSVGElement from '../setupSVGElement';
import { isVectorizedImage } from '../isVectorizedImage/isVectorizedImage';

const svgLoaderOptions = {
  loadType: PIXI.LoaderResource.LOAD_TYPE.XHR,
  xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.TEXT
};

export const svgLoader = new PIXI.Loader();

export const processSVG = async (key: string, svgText: string, metadata: { width?: number; height?: number }) => {
  const dbCached = await getConvertedSVGData(key);

  if (dbCached) {
    const svgParts = svgSplitter(dbCached.data.convertedSVGString);

    const { strokesTexture, fillsTexture, fullTexture } = getSvgPartTextures(dbCached.data.viewBox, svgParts, metadata);
    const pixels = await getImagePixelsFromUrl({ url: dbCached.data.encodedData });

    const resourceMetadata = {
      splitSVGData: {
        svgParts,
        viewBox: dbCached.data.viewBox,
        encodedData: dbCached.data.encodedData
      },
      strokesTexture,
      fillsTexture,
      pixels,
      isVectorizedImage: dbCached.data.isVectorizedImage
    };

    return {
      metadata: resourceMetadata,
      texture: fullTexture
    };
  }

  const vectorizedImage = isVectorizedImage(svgText);
  const convertedSVGString = convert(svgText);
  const parser = new DOMParser();
  const doc = parser.parseFromString(convertedSVGString, 'text/html');
  const svg = doc.querySelector('svg');

  if (!svg) {
    throw new Error('No SVG found in given document');
  }

  const viewBox = svg.getAttribute('viewBox');
  if (!viewBox) {
    throw new Error('No viewbox attribute on supplied SVG');
  }

  const [, , width, height] = viewBox.split(' ');
  // If there are no width or height set on the SVG then set it from the viewbox attributes so
  // PIXI knows how to handle it
  if (svg.getAttribute('width') === null) {
    svg.setAttribute('width', `${width}px`);
  }
  if (svg.getAttribute('height') === null) {
    svg.setAttribute('height', `${height}px`);
  }

  const svgParts = svgSplitter(convertedSVGString);

  let generatedOutlineSvg;
  if (vectorizedImage) {
    // for vectorized images we have no strokes, so we pixelate the outline,
    // then VSVectorImage will use thinning to generate the draw paths
    const outlineWithMeta = [...svgParts.meta, ...svgParts.strokes];
    generatedOutlineSvg = setupSVGElement(outlineWithMeta, width, height, viewBox);
  }

  // Serialize and base64 encode the SVG image
  const encodedData = svgToDataUrl(generatedOutlineSvg ?? svg);

  await storeConvertedSVGData(key, {
    encodedData,
    viewBox,
    convertedSVGString,
    isVectorizedImage: vectorizedImage
  });

  const { strokesTexture, fillsTexture, fullTexture } = getSvgPartTextures(viewBox, svgParts, metadata);

  const pixels = await getImagePixelsFromUrl({ url: encodedData });
  const resourceMetadata = {
    splitSVGData: { svgParts, viewBox, encodedData },
    strokesTexture,
    fillsTexture,
    pixels,
    isVectorizedImage: vectorizedImage
  };

  return {
    metadata: resourceMetadata,
    texture: fullTexture
  };
};

const svgPostLoadingMiddleware = async (resource: LoaderResource, next: () => void) => {
  const { metadata, texture } = await processSVG(resource.name, resource.data, resource.metadata);

  resource.metadata = { ...resource.metadata, ...metadata };
  resource.texture = texture;

  next();
};

svgLoader.use(svgPostLoadingMiddleware);

export const svgLoaderPromise = (): Promise<PIXI.utils.Dict<VSCSVGLoaderResource>> =>
  new Promise(resolve =>
    svgLoader.load((_loader, resources) => resolve(resources as PIXI.utils.Dict<VSCSVGLoaderResource>))
  );

export const svgLoaderAdd = async (asset: SVGAsset) => {
  if (!svgLoader.resources[asset.key]) {
    if (svgLoader.loading) {
      const promise: Promise<void> = new Promise(resolve => {
        svgLoader.onComplete.once(() => resolve());
      });
      await promise;
    }

    svgLoader.add({
      key: asset.key,
      url: asset.url,
      metadata: {
        width: asset.svgWidth,
        height: asset.svgHeight
      },
      ...svgLoaderOptions
    });
  }
};

function getSvgPartTextures(
  viewBox: string,
  svgParts: {
    meta: NodeListOf<Element>;
    revealPaths: NodeListOf<Element>;
    strokes: NodeListOf<Element>;
    fills: NodeListOf<Element>;
  },
  metadata: { width?: number | undefined; height?: number | undefined }
) {
  const splitViewBox = viewBox.split(' ');
  const viewBoxOffsetX = parseFloat(splitViewBox[2]);
  const viewBoxOffsetY = parseFloat(splitViewBox[3]);
  const fillsWithMeta = [...svgParts.meta, ...svgParts.fills];
  const strokesWithMeta = [...svgParts.meta, ...svgParts.strokes];
  const svgWidth = metadata.width || SVG_CANVAS_SIZE;
  const svgHeight = metadata.height || SVG_CANVAS_SIZE;

  const strokesTexture = createPartsTexture({
    parts: strokesWithMeta,
    viewBoxOffsetX,
    viewBoxOffsetY,
    viewBox,
    width: svgWidth,
    height: svgHeight
  });
  const fillsTexture = createPartsTexture({
    parts: fillsWithMeta,
    viewBoxOffsetX,
    viewBoxOffsetY,
    viewBox,
    width: svgWidth,
    height: svgHeight
  });
  const fullTexture = createPartsTexture({
    parts: [...fillsWithMeta, ...strokesWithMeta],
    viewBoxOffsetX,
    viewBoxOffsetY,
    viewBox,
    width: svgWidth,
    height: svgHeight
  });

  return { strokesTexture, fillsTexture, fullTexture };
}

function createPartsTexture({
  parts,
  viewBox,
  width,
  height
}: {
  parts: Array<Element>;
  viewBoxOffsetX: number;
  viewBoxOffsetY: number;
  viewBox: string;
  width: number;
  height: number;
}) {
  const svg = setupSVGElement(parts, width, height, viewBox);
  const svgEncodedData = svgToDataUrl(svg);
  const svgResource = new PIXI.ImageResource(svgEncodedData, {});
  const baseTexture = new PIXI.BaseTexture(svgResource);
  const texture = new PIXI.Texture(baseTexture);

  return texture;
}

/**
 * Clears resource loader of resources no longer being used in a project in order to free memory
 */
export const svgLoaderManageResourcesInProject = (project: PlaybackScribeModel | ExistingScribeModel) => {
  const svgElements = project.elements.filter(
    (el): el is ScribeImageElementModel => el.type === 'Image' && el.image?.contentType === FILE_CONTENT_TYPES.SVG
  );
  const keysInProject = svgElements
    .map(imageEl => {
      if (!imageEl.image) return null;

      return constructSVGAssetKey({
        assetId: imageEl.image.assetId,
        recolorSettings: imageEl.image?.recolor,
        useContentSvgViewbox: !!imageEl.useContentSvgViewbox
      });
    })
    .filter((key): key is string => Boolean(key));

  const cleanedLoaderResources = Object.fromEntries(
    Object.entries(svgLoader.resources).filter(([key]) => keysInProject.includes(key))
  );
  svgLoader.resources = cleanedLoaderResources;
};
