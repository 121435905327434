import mixpanel from 'mixpanel-browser';
import config from 'js/config/config';

const { MIXPANEL_API_TOKEN, DEBUG_MIXPANEL, APPLICATION_NAME } = config;
export let instance;

// https://developer.mixpanel.com/docs/javascript-full-api-reference
export const initialise = () => {
  instance = mixpanel.init(
    MIXPANEL_API_TOKEN,
    {
      opt_out_tracking_by_default: false,
      api_host: 'https://api-eu.mixpanel.com',
      debug: !!DEBUG_MIXPANEL,
      persistence: 'localStorage',
      ignore_dnt: true
    },
    // Namespacing the mixpanel instance makes it return from `init` making it easier to check if mixpanel has been initialised or not
    APPLICATION_NAME
  );
  if (instance.has_opted_out_tracking({ persistence_type: 'localStorage' })) {
    instance.clear_opt_in_out_tracking({
      persistence_type: 'localStorage'
    });
    instance.opt_in_tracking({
      persistence_type: 'localStorage'
    });
  }
  window.mixpanel = instance;
};

function selfInitialisingFn(fn) {
  return (...args) => {
    if (!instance) {
      initialise();
    }
    return fn.apply(null, args);
  };
}

export const track = selfInitialisingFn((eventName, properties, options, callback) => {
  instance.track(eventName, properties, options, callback);
});

export const identify = selfInitialisingFn(id => {
  instance.identify(id);
});

export const setUserProperties = selfInitialisingFn((prop, value) => {
  if (typeof prop === 'string') {
    instance.people.set(prop, value);
  } else {
    instance.people.set(prop);
  }
});

export const incrementUserProperties = selfInitialisingFn((prop, by = 1) => {
  if (typeof prop === 'string') {
    instance.people.increment(prop, by);
  } else {
    instance.people.increment(prop);
  }
});

export const setUserPropertiesOnce = selfInitialisingFn((prop, value) => {
  if (typeof prop === 'string') {
    instance.people.set_once(prop, value);
  } else {
    instance.people.set_once(prop);
  }
});

export const registerEventSuperProperties = selfInitialisingFn(properties => {
  instance.register(properties);
});

export const unregisterEventSuperProperty = selfInitialisingFn(property => {
  instance.unregister(property);
});

export const registerEventSuperPropertiesOnce = selfInitialisingFn(properties => {
  instance.register_once(properties);
});

export const incrementEventSuperProperty = selfInitialisingFn((property, by = 1) => {
  const currentVal = instance.get_property(property);
  const update = {};

  if (currentVal && typeof currentVal === 'number') {
    update[property] = currentVal + by;
  } else {
    update[property] = by;
  }
  registerEventSuperProperties(update);
});

export const reset = () => {
  if (!instance) return;
  instance.register({
    'Logged In': false
  });
  instance.reset();
};

export const clearInstance = () => {
  instance = undefined;
};

export const timeEvent = selfInitialisingFn(eventName => {
  instance.time_event(eventName);
});
