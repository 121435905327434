import { updateProject } from 'js/shared/helpers/app-services/AppServices/projects';
import { ExistingScribeModel, ScribeObject } from 'js/types';
import { CallEffect, put, PutEffect, call } from 'redux-saga/effects';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { updateScribeFailed, updateScribeSuccess } from 'js/actionCreators/scribeActions';
import { sendErrorToSentry } from 'js/logging';
import { logout } from 'js/actionCreators/authActions';

export function* saveProject(
  pendingProject: ExistingScribeModel
): Generator<CallEffect | PutEffect, void, ScribeObject> {
  try {
    const project = yield call(updateProject, pendingProject, appServices.exchangeCognitoTokenForSparkolToken);
    if (project) {
      yield put(updateScribeSuccess(project));
    }
  } catch (error) {
    if (error instanceof Error) {
      sendErrorToSentry(error);
      yield put(updateScribeFailed(error, pendingProject));
      console.error(error);
    } else {
      if (error === 'Failed to refresh Cognito user') {
        yield put(logout());
      }
    }
  }
}
