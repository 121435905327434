/**
 * Uses contentful Content Delivery api
 */

import chunk from 'lodash.chunk';
import { sendErrorToSentry } from 'js/logging';

import { getContentfulClient } from './helpers/contentful';

const getEntries = async (query = {}) => {
  try {
    query.limit = query.limit || 1000;
    const client = getContentfulClient();
    const response = await client.getEntries(query);
    return { error: null, entries: response.items };
  } catch (error) {
    console.error(error);
    return { error, entries: [] };
  }
};

export const entryToTemplate = entry => {
  try {
    const { scribeData = {}, title = null, preview = null, isPremium = false, templateThumbnail } = entry.fields;
    const { id } = entry.sys;
    scribeData.title = title;
    scribeData.id = id;
    scribeData.isTemplate = true;
    scribeData.sparkolTemplateId = entry.fields.sparkolTemplateId;
    scribeData.isPremium = isPremium;
    scribeData.previewUrl = preview?.fields?.file?.url;
    scribeData.thumbnailImage = templateThumbnail?.fields?.file?.url;
    scribeData.canvasSize = entry.fields.canvasSize;
    scribeData.createdDate = entry.sys.createdAt;
    return scribeData;
  } catch (error) {
    sendErrorToSentry(error);
    console.error(error);
    // If there is a problem with the scribeData model then return a null title to prevent the whole download of templates from failing
    return { title: null };
  }
};

/*
 * gets templates organised by category
 */
export const getTemplatesByCategory = async () => {
  let { error, entries: listEntries } = await getEntries({
    content_type: 'categoryList',
    include: 1
  });

  // Look for the main list and fallback to the first in the list if not found
  let mainList = listEntries?.find(list => list.fields.name === 'Main') || listEntries?.[0];
  let categoriesList = mainList?.fields?.categories;

  if (error || !categoriesList) {
    // Fallback to the list of all published categories if no list is found
    const { error: fallbackError, entries: allEntries } = await getEntries({
      content_type: 'templateCategory',
      include: 1 // fetch linked templates, but not deeper links
    });
    categoriesList = allEntries;
    error = fallbackError;
  }

  const templateIds = categoriesList.flatMap(category => category.fields.templates).map(template => template.sys.id);
  const templateIdChunks = chunk(templateIds, 100);

  const templates = [];
  for (const templateIdsChunk of templateIdChunks) {
    const templatesChunk = await getEntries({
      content_type: 'scribeTemplate',
      'sys.id': templateIdsChunk,
      select: [
        'fields.title',
        'fields.isPremium',
        'fields.preview',
        'fields.templateThumbnail',
        'fields.sparkolTemplateId',
        'fields.canvasSize',
        'sys.createdAt'
      ]
    });

    templates.push(...templatesChunk.entries);
  }

  const categories = categoriesList
    .map(entry => {
      const { title = null } = entry.fields;
      const { id } = entry.sys;

      const thisCategoryTemplateIds = entry.fields.templates.map(t => t.sys.id);
      const catTemplates = thisCategoryTemplateIds
        .map(id => templates.find(temp => temp.sys.id === id))
        .filter(Boolean);

      return {
        id,
        title,
        templates: catTemplates.map(entryToTemplate)
      };
    })
    .filter(category => category.title !== null);
  return {
    error,
    categories
  };
};
