import {
  AudioClip,
  CameraPinEventTrigger,
  CanvasSize,
  ElementAnimationStageDurationKey,
  ElementAnimationStageLoopsKey,
  ExistingScribeModel,
  HideEventTrigger,
  HomepageProject,
  LockEventTrigger,
  SceneTriggers,
  ScribeCursorId,
  ScribeImageElementModelProps,
  ScribeObject,
  ScribeScene,
  ScribeSettings,
  ScribeSource,
  ShapeType,
  SortOrder,
  TimelineDraggablePosition,
  VSDimensions,
  VSElementModel,
  CustomTextStyleType
} from 'js/types';

export const UPDATE_SCRIBE = 'UPDATE_SCRIBE';
export const UPDATE_SCRIBE_FAILED = 'UPDATE_SCRIBE_FAILED';
export const UPDATE_SCRIBE_SUCCESS = 'UPDATE_SCRIBE_SUCCESS';
export const SET_ACTIVE_SCRIBE = 'SET_ACTIVE_SCRIBE';
export const SET_ACTIVE_ELEMENTS = 'SET_ACTIVE_ELEMENTS';
export const ADD_TO_ACTIVE_ELEMENTS = 'ADD_TO_ACTIVE_ELEMENTS';
export const SET_SHOW_ELEMENT_MODAL = 'SET_SHOW_ELEMENT_MODAL';
export const ADD_SCRIBE_REQUESTED = 'ADD_SCRIBE_REQUESTED';
export const ADD_SCRIBE_SUCCESS = 'ADD_SCRIBE_SUCCESS';
export const CREATE_ELEMENT = 'CREATE_ELEMENT';
export const CREATE_TEXT_ELEMENT = 'CREATE_TEXT_ELEMENT';
export const CREATE_SHAPE_ELEMENT = 'CREATE_SHAPE_ELEMENT';
export const CREATE_IMAGE_ELEMENT = 'CREATE_IMAGE_ELEMENT';
export const MOVE_ELEMENT_ON_TIMELINE = 'MOVE_ELEMENT_ON_TIMELINE';
export const DELETE_ACTIVE_ELEMENT = 'DELETE_ACTIVE_ELEMENT';
export const LOAD_SCRIBES = 'LOAD_SCRIBES';
export const LOAD_SCRIBES_SUCCESS = 'LOAD_SCRIBES_SUCCESS';
export const DELETE_SCRIBE = 'DELETE_SCRIBE';
export const DELETE_SCRIBE_SUCCESS = 'DELETE_SCRIBE_SUCCESS';
export const SORT_SCRIBES = 'SORT_SCRIBES';
export const DELETE_MULTIPLE_ELEMENTS_BY_ID = 'DELETE_MULTIPLE_ELEMENT_BY_ID';
export const LOAD_SCRIBE_BY_ID = 'LOAD_SCRIBE_BY_ID';
export const LOAD_SCRIBE_BY_ID_SUCCESS = 'LOAD_SCRIBE_BY_ID_SUCCESS';
export const LOAD_SCRIBE_BY_ID_FAILED = 'LOAD_SCRIBE_BY_ID_FAILED';
export const LOAD_CURSORS = 'LOAD_CURSORS';
export const UPDATE_SCRIBE_CURSOR = 'UPDATE_SCRIBE_CURSOR';
export const LOCK_ELEMENTS = 'LOCK_ELEMENTS';
export const UNLOCK_ELEMENTS = 'UNLOCK_ELEMENTS';
export const LOCK_ACTIVE_ELEMENTS = 'LOCK_ACTIVE_ELEMENTS';
export const PIN_CAMERAS = 'PIN_CAMERAS';
export const UNPIN_CAMERAS = 'UNPIN_CAMERAS';
export const PIN_ACTIVE_CAMERAS = 'PIN_ACTIVE_CAMERAS';
export const UNPIN_ACTIVE_CAMERAS = 'UNPIN_ACTIVE_CAMERAS';
export const SET_FIRST_LOAD = 'SET_FIRST_LOAD';
export const TOGGLE_CAMERA_PINS = 'TOGGLE_ACTIVE_SCENE_CAMERA_PINS';
export const TOGGLE_GRID = 'TOGGLE_GRID';
export const TOGGLE_CANVAS_DRAG = 'TOGGLE_CANVAS_DRAG';
export const UNLOCK_ACTIVE_ELEMENTS = 'UNLOCK_ACTIVE_ELEMENTS';
export const UPDATE_PROJECT_TITLE = 'UPDATE_PROJECT_TITLE';
export const UPDATE_PROJECT_TITLE_SUCCESS = 'UPDATE_PROJECT_TITLE_SUCCESS';
export const UPDATE_PROJECT_TITLE_FAILED = 'UPDATE_PROJECT_TITLE_FAILED';
export const UPDATE_EDITOR_POSITION_ZOOM = 'UPDATE_EDITOR_POSITION_ZOOM';
export const SET_ELEMENTS_TIMINGS = 'SET_ELEMENTS_TIMINGS';
export const SET_ELEMENTS_LOOPS = 'SET_ELEMENTS_LOOPS';
export const PREP_SCRIBE_FOR_EDITOR_SUCCESS = 'PREP_SCRIBE_FOR_EDITOR_SUCCESS';
export const EDITOR_CLOSE = 'EDITOR_CLOSE';
export const UPDATE_SCENE_ELEMENTS = 'UPDATE_SCENE_ELEMENTS';
export const UPDATE_SCENE_THUMBNAIL = 'UPDATE_SCENE_THUMBNAIL';
export const SET_SELECTED_SCENES = 'SET_SELECTED_SCENES';
export const SET_SELECTED_AUDIO_CLIP = 'SET_SELECTED_AUDIO_CLIP';
export const SET_ACTIVE_SCENE = 'SET_ACTIVE_SCENE';
export const CREATE_SCENE = 'CREATE_SCENE';
export const DUPLICATE_SELECTED_SCENES = 'DUPLICATE_SELECTED_SCENES';
export const DELETE_SELECTED_SCENES = 'DELETE_SELECTED_SCENES';
export const UPDATE_SCENE = 'UPDATE_SCENE';
export const CHANGE_SCENE_ORDER = 'CHANGE_SCENE_ORDER';
export const REWRITE_HISTORY = 'REWRITE_HISTORY';
export const ADD_TO_SELECTED_SCENES = 'ADD_TO_SELECTED_SCENES';
export const UPDATE_LAST_SELECTED_SCENE = 'UPDATE_LAST_SELECTED_SCENE';
export const TOGGLE_ACTIVE_ELEMENT = 'TOGGLE_ACTIVE_ELEMENT';
export const SELECT_ALL = 'SELECT_ALL';
export const SET_ACTIVE_ELEMENTS_VISIBILITY = 'SET_ACTIVE_ELEMENTS_VISIBILITY';
export const SET_ELEMENTS_VISIBILITY = 'SET_ELEMENTS_VISIBILITY';
export const FILTER_SCRIBES_BY_CANVAS_SIZE = 'FILTER_SCRIBES_BY_CANVAS_SIZE';
export const FILTER_SCRIBES_BY_SEARCH_TERM = 'FILTER_SCRIBES_BY_SEARCH_TERM';
export const ON_MOVE_WITH_KEYS = 'ON_MOVE_WITH_KEYS';
export const SET_SELECTED_TEXT_COLOR = 'SET_SELECTED_TEXT_COLOR';
export const SET_CUSTOM_TEXT_STYLE = 'SET_CUSTOM_TEXT_STYLE';
export const SET_IS_TEXTBOX_EDITING = 'SET_IS_TEXTBOX_EDITING';

export interface UpdateScribeAction {
  type: typeof UPDATE_SCRIBE;
  scribe: ExistingScribeModel;
  skipUndoHistory: boolean;
  activeElementIds?: Array<string>;
  activeAudioClipId?: string;
}

export const updateScribe = (
  scribe: ExistingScribeModel,
  skipUndoHistory = false,
  activeElementIds?: Array<string>,
  activeAudioClipId?: string
): UpdateScribeAction => ({
  type: UPDATE_SCRIBE,
  scribe,
  skipUndoHistory,
  activeElementIds,
  activeAudioClipId
});

export interface UpdateScribeFailedAction {
  type: typeof UPDATE_SCRIBE_FAILED;
  error: Error;
  scribe: ExistingScribeModel;
}

export const updateScribeFailed = (error: Error, scribe: ExistingScribeModel): UpdateScribeFailedAction => ({
  type: UPDATE_SCRIBE_FAILED,
  scribe,
  error
});

export interface UpdateScribeSuccessAction {
  type: typeof UPDATE_SCRIBE_SUCCESS;
  scribe: ScribeObject;
}

export const updateScribeSuccess = (scribe: ScribeObject): UpdateScribeSuccessAction => ({
  type: UPDATE_SCRIBE_SUCCESS,
  scribe
});

export interface SetActiveScribeAction {
  type: typeof SET_ACTIVE_SCRIBE;
  scribeId: number;
}

export const setActiveScribe = (scribeId: number): SetActiveScribeAction => ({
  type: SET_ACTIVE_SCRIBE,
  scribeId
});

export interface SetActiveElementsAction {
  type: typeof SET_ACTIVE_ELEMENTS;
  elementIds?: Array<string> | string;
}

export const setActiveElements = (elementIds: string | Array<string> = []): SetActiveElementsAction => ({
  type: SET_ACTIVE_ELEMENTS,
  elementIds
});

export interface AddToActiveElementsAction {
  type: typeof ADD_TO_ACTIVE_ELEMENTS;
  elementId: string;
}

export const addToActiveElements = (elementId: string): AddToActiveElementsAction => ({
  type: ADD_TO_ACTIVE_ELEMENTS,
  elementId
});

export interface ToggleActiveElementAction {
  type: typeof TOGGLE_ACTIVE_ELEMENT;
  elementId: string;
}

export const toggleActiveElement = (elementId: string): ToggleActiveElementAction => ({
  type: TOGGLE_ACTIVE_ELEMENT,
  elementId
});

export interface SetShowElementModalAction {
  type: typeof SET_SHOW_ELEMENT_MODAL;
  elementType: string;
  elementId: string | null;
  scribeId: number;
  replaceElement: boolean;
}

export const setShowElementModal = (
  elementType: string,
  elementId: string | null,
  scribeId: number,
  replaceElement = false
): SetShowElementModalAction => ({
  type: SET_SHOW_ELEMENT_MODAL,
  elementType,
  elementId,
  scribeId,
  replaceElement
});

export interface AddScribeAction {
  type: typeof ADD_SCRIBE_REQUESTED;
  title: string;
  canvasSize: CanvasSize;
  templateId?: string | null;
  replaceHistory?: boolean;
  source: ScribeSource;
  elements?: Array<VSElementModel>;
  thumbnail?: string;
  settings?: ScribeSettings;
  cursor?: ScribeCursorId;
  scenes?: Array<ScribeScene>;
  audioClips?: Array<AudioClip>;
  date?: Date;
}

export type AddScribeProperties = Omit<AddScribeAction, 'type'>;

export const addScribe = ({
  title,
  canvasSize,
  templateId = null,
  replaceHistory = false,
  source,
  elements,
  thumbnail,
  settings,
  cursor,
  scenes,
  audioClips
}: AddScribeProperties): AddScribeAction => ({
  type: ADD_SCRIBE_REQUESTED,
  title,
  date: new Date(),
  canvasSize,
  templateId,
  replaceHistory,
  source,
  elements,
  thumbnail,
  settings,
  cursor,
  scenes,
  audioClips
});

export interface AddScribeSuccessAction {
  type: typeof ADD_SCRIBE_SUCCESS;
  scribe: ExistingScribeModel;
}

export const addScribeSuccess = (scribe: ExistingScribeModel): AddScribeSuccessAction => ({
  type: ADD_SCRIBE_SUCCESS,
  scribe
});

export interface CreateElementAction {
  type: typeof CREATE_ELEMENT;
  element: VSElementModel;
  scribeId: number;
  canvasSize: VSDimensions;
}

export const createElement = (
  element: VSElementModel,
  scribeId: number,
  canvasSize: VSDimensions
): CreateElementAction => ({
  type: CREATE_ELEMENT,
  element,
  scribeId,
  canvasSize
});

export interface CreateShapeElementAction {
  type: typeof CREATE_SHAPE_ELEMENT;
  shapeType: ShapeType;
  scribeId: number;
  canvasSize: VSDimensions;
}

export const createShapeElement = (
  shapeType: ShapeType,
  scribeId: number,
  canvasSize: VSDimensions
): CreateShapeElementAction => ({
  type: CREATE_SHAPE_ELEMENT,
  shapeType,
  scribeId,
  canvasSize
});

export interface CreateImageElementAction {
  type: typeof CREATE_IMAGE_ELEMENT;
  initialData: ScribeImageElementModelProps;
  scribeId: number;
  canvasSize: VSDimensions;
}

export const createImageElement = (
  scribeId: number,
  canvasSize: VSDimensions,
  initialData: ScribeImageElementModelProps
): CreateImageElementAction => ({
  type: CREATE_IMAGE_ELEMENT,
  scribeId,
  canvasSize,
  initialData
});

export interface DeleteMultipleElementsByIdAction {
  type: typeof DELETE_MULTIPLE_ELEMENTS_BY_ID;
  elements: Array<string>;
  scribeId: number;
}

export const deleteMultipleElementsById = (
  elements: Array<string>,
  scribeId: number
): DeleteMultipleElementsByIdAction => ({
  type: DELETE_MULTIPLE_ELEMENTS_BY_ID,
  elements,
  scribeId
});

export interface MoveElementOnTimelineAction {
  type: typeof MOVE_ELEMENT_ON_TIMELINE;
  scribeId: number;
  source: TimelineDraggablePosition;
  destination: TimelineDraggablePosition;
  draggableId: string;
}

export const moveElementOnTimeline = (
  scribeId: number,
  source: TimelineDraggablePosition,
  destination: TimelineDraggablePosition,
  draggableId: string
): MoveElementOnTimelineAction => ({
  type: MOVE_ELEMENT_ON_TIMELINE,
  scribeId,
  source,
  destination,
  draggableId
});

export interface DeleteActiveElementAction {
  type: typeof DELETE_ACTIVE_ELEMENT;
  scribeId: number;
}

export const deleteActiveElement = (scribeId: number): DeleteActiveElementAction => ({
  type: DELETE_ACTIVE_ELEMENT,
  scribeId
});

export interface LoadScribesAction {
  type: typeof LOAD_SCRIBES;
  query: object | null;
}

export const loadScribes = (query: object | null): LoadScribesAction => ({
  type: LOAD_SCRIBES,
  query
});

export interface LoadScribesSuccessAction {
  type: typeof LOAD_SCRIBES_SUCCESS;
  scribes: Array<HomepageProject>;
}

export const loadScribesSuccess = (scribes: Array<HomepageProject>): LoadScribesSuccessAction => ({
  type: LOAD_SCRIBES_SUCCESS,
  scribes
});

export interface DeleteScribeAction {
  type: typeof DELETE_SCRIBE;
  scribe: ExistingScribeModel;
}

export const deleteScribe = (scribe: ExistingScribeModel): DeleteScribeAction => ({
  type: DELETE_SCRIBE,
  scribe
});

export interface DeleteScribeSuccessAction {
  type: typeof DELETE_SCRIBE_SUCCESS;
  scribeId: number;
  title: string;
}

export const deleteScribeSuccess = (scribeId: number, title: string): DeleteScribeSuccessAction => ({
  type: DELETE_SCRIBE_SUCCESS,
  scribeId,
  title
});

export interface SortScribeAction {
  type: typeof SORT_SCRIBES;
  sortOrder: SortOrder;
}

export const sortScribes = (sortOrder: SortOrder): SortScribeAction => ({
  type: SORT_SCRIBES,
  sortOrder
});

export interface FilterByCanvasSizeAction {
  type: typeof FILTER_SCRIBES_BY_CANVAS_SIZE;
  size: string;
}

export const filterScribesByCanvasSize = (size: string): FilterByCanvasSizeAction => ({
  type: FILTER_SCRIBES_BY_CANVAS_SIZE,
  size
});

export interface UpdateProjectSearchTermAction {
  type: typeof FILTER_SCRIBES_BY_SEARCH_TERM;
  term: string;
}

export const updateProjectSearchTerm = (term: string): UpdateProjectSearchTermAction => ({
  type: FILTER_SCRIBES_BY_SEARCH_TERM,
  term
});

export interface LoadScribeByIdAction {
  type: typeof LOAD_SCRIBE_BY_ID;
  scribeId: number;
}

export const loadScribeById = (scribeId: number): LoadScribeByIdAction => ({
  type: LOAD_SCRIBE_BY_ID,
  scribeId
});

export interface LoadScribeByIdSuccessAction {
  type: typeof LOAD_SCRIBE_BY_ID_SUCCESS;
  scribe: ExistingScribeModel;
}

export const loadScribeByIdSuccess = ({
  scribe
}: Omit<LoadScribeByIdSuccessAction, 'type'>): LoadScribeByIdSuccessAction => ({
  type: LOAD_SCRIBE_BY_ID_SUCCESS,
  scribe
});

export interface LoadScribeByIdFaileAction {
  type: typeof LOAD_SCRIBE_BY_ID_FAILED;
  scribeId: number;
  error: Error;
}

export const loadScribeByIdFailed = ({
  scribeId,
  error
}: Omit<LoadScribeByIdFaileAction, 'type'>): LoadScribeByIdFaileAction => ({
  type: LOAD_SCRIBE_BY_ID_FAILED,
  scribeId,
  error
});

export interface CreateTextElementAction {
  type: typeof CREATE_TEXT_ELEMENT;
  scribeId: number;
  canvasSize: VSDimensions;
  initialText?: string;
}

export const createTextElement = (
  scribeId: number,
  canvasSize: VSDimensions,
  initialText?: string
): CreateTextElementAction => ({
  type: CREATE_TEXT_ELEMENT,
  scribeId,
  canvasSize,
  initialText
});

export const loadCursors = () => ({
  type: LOAD_CURSORS
});

export interface UpdateScribeCursorAction {
  type: typeof UPDATE_SCRIBE_CURSOR;
  scribeId: number;
  cursorId: ScribeCursorId;
}

export const updateScribeCursor = (scribeId: number, cursorId: ScribeCursorId): UpdateScribeCursorAction => ({
  type: UPDATE_SCRIBE_CURSOR,
  scribeId,
  cursorId
});

export interface LockElementsAction {
  type: typeof LOCK_ELEMENTS;
  elementIds: Array<string>;
  scribeId: number;
  eventTrigger: LockEventTrigger;
}

export const lockElements = (
  elementIds: Array<string>,
  scribeId: number,
  eventTrigger: LockEventTrigger
): LockElementsAction => ({
  type: LOCK_ELEMENTS,
  elementIds,
  scribeId,
  eventTrigger
});

export interface UnlockElementsAction {
  type: typeof UNLOCK_ELEMENTS;
  elementIds: Array<string>;
  scribeId: number;
  eventTrigger: LockEventTrigger;
}

export const unlockElements = (
  elementIds: Array<string>,
  scribeId: number,
  eventTrigger: LockEventTrigger
): UnlockElementsAction => ({
  type: UNLOCK_ELEMENTS,
  elementIds,
  scribeId,
  eventTrigger
});
export interface PinCamerasAction {
  type: typeof PIN_CAMERAS;
  elementIds: Array<string>;
  scribeId: number;
  eventTrigger: CameraPinEventTrigger;
}

export const pinCameras = (
  elementIds: Array<string>,
  scribeId: number,
  eventTrigger: CameraPinEventTrigger
): PinCamerasAction => ({
  type: PIN_CAMERAS,
  elementIds,
  scribeId,
  eventTrigger
});

export interface UnpinCamerasAction {
  type: typeof UNPIN_CAMERAS;
  elementIds: Array<string>;
  scribeId: number;
  eventTrigger: CameraPinEventTrigger;
}

export const unpinCameras = (
  elementIds: Array<string>,
  scribeId: number,
  eventTrigger: CameraPinEventTrigger
): UnpinCamerasAction => ({
  type: UNPIN_CAMERAS,
  elementIds,
  scribeId,
  eventTrigger
});

export interface PinActiveCamerasAction {
  type: typeof PIN_ACTIVE_CAMERAS;
  scribeId: number;
  eventTrigger: CameraPinEventTrigger;
}

export const pinActiveCameras = (scribeId: number, eventTrigger: CameraPinEventTrigger): PinActiveCamerasAction => ({
  type: PIN_ACTIVE_CAMERAS,
  scribeId,
  eventTrigger
});

export interface SetFirstLoadAction {
  type: typeof SET_FIRST_LOAD;
  value: boolean;
}

export const setFirstLoad = (value: boolean): SetFirstLoadAction => ({
  type: SET_FIRST_LOAD,
  value: value
});

export interface UnpinActiveCamerasAction {
  type: typeof UNPIN_ACTIVE_CAMERAS;
  scribeId: number;
  eventTrigger: CameraPinEventTrigger;
}

export const unpinActiveCameras = (
  scribeId: number,
  eventTrigger: CameraPinEventTrigger
): UnpinActiveCamerasAction => ({
  type: UNPIN_ACTIVE_CAMERAS,
  scribeId,
  eventTrigger
});

export interface LockActiveElementsAction {
  type: typeof LOCK_ACTIVE_ELEMENTS;
  scribeId: number;
  eventTrigger: LockEventTrigger;
}

export const lockActiveElements = (scribeId: number, eventTrigger: LockEventTrigger): LockActiveElementsAction => ({
  type: LOCK_ACTIVE_ELEMENTS,
  scribeId,
  eventTrigger
});

export interface UnlockActiveElementsAction {
  type: typeof UNLOCK_ACTIVE_ELEMENTS;
  scribeId: number;
  eventTrigger: LockEventTrigger;
}

export const unlockActiveElements = (scribeId: number, eventTrigger: LockEventTrigger): UnlockActiveElementsAction => ({
  type: UNLOCK_ACTIVE_ELEMENTS,
  scribeId,
  eventTrigger
});

export interface ToggleGridAction {
  type: typeof TOGGLE_GRID;
  scribeId: number;
  eventTrigger: string;
}

export const toggleGrid = (scribeId: number, eventTrigger: string): ToggleGridAction => ({
  type: TOGGLE_GRID,
  scribeId,
  eventTrigger
});

export interface TogglePinsAction {
  type: typeof TOGGLE_CAMERA_PINS;
  scribeId: number;
  eventTrigger: string;
}

export const togglePins = (scribeId: number, eventTrigger: string): TogglePinsAction => ({
  type: TOGGLE_CAMERA_PINS,
  scribeId,
  eventTrigger
});

export interface ToggleCanvasDragAction {
  type: typeof TOGGLE_CANVAS_DRAG;
  scribeId: number;
  eventTrigger: string;
}

export const toggleCanvasDrag = (scribeId: number, eventTrigger: string): ToggleCanvasDragAction => ({
  type: TOGGLE_CANVAS_DRAG,
  scribeId,
  eventTrigger
});

export interface UpdateEditorPositionAndZoomAction {
  type: typeof UPDATE_EDITOR_POSITION_ZOOM;
  scribeId: number;
  sceneId: string;
  vpTransform: number[];
  eventTrigger: string;
}

export const updateEditorPositionAndZoom = (
  scribeId: number,
  sceneId: string,
  vpTransform: number[],
  eventTrigger: string
): UpdateEditorPositionAndZoomAction => ({
  type: UPDATE_EDITOR_POSITION_ZOOM,
  sceneId,
  vpTransform,
  scribeId,
  eventTrigger
});

export interface UpdateProjectTitleAction {
  type: typeof UPDATE_PROJECT_TITLE;
  title: string;
  scribeId: number;
  eventTrigger: string;
}

export const updateProjectTitle = (
  title: string,
  scribeId: number,
  eventTrigger: string
): UpdateProjectTitleAction => ({
  type: UPDATE_PROJECT_TITLE,
  title,
  scribeId,
  eventTrigger
});

export interface UpdateProjectTitleSuccessAction {
  type: typeof UPDATE_PROJECT_TITLE_SUCCESS;
  scribeId: number;
  title: string;
}

export const updateProjectTitleSuccess = ({
  scribeId,
  title
}: Omit<UpdateProjectTitleSuccessAction, 'type'>): UpdateProjectTitleSuccessAction => ({
  type: UPDATE_PROJECT_TITLE_SUCCESS,
  scribeId,
  title
});

export interface UpdateProjectTitleFailedAction {
  type: typeof UPDATE_PROJECT_TITLE_FAILED;
  scribeId: number;
  previousTitle: string;
}

export const updateProjectTitleFailed = ({
  scribeId,
  previousTitle
}: Omit<UpdateProjectTitleFailedAction, 'type'>): UpdateProjectTitleFailedAction => ({
  type: UPDATE_PROJECT_TITLE_FAILED,
  scribeId,
  previousTitle
});

export interface SetElementsTimingsAction {
  type: typeof SET_ELEMENTS_TIMINGS;
  scribeId: number;
  elementIds: Array<string>;
  propertyValuePair: { [K in ElementAnimationStageDurationKey]?: number };
  eventTrigger: string;
}

export type SetElementTimingsProperties = Omit<SetElementsTimingsAction, 'type'>;

export const setElementsTimings = ({
  scribeId,
  elementIds,
  propertyValuePair,
  eventTrigger
}: SetElementTimingsProperties): SetElementsTimingsAction => ({
  type: SET_ELEMENTS_TIMINGS,
  scribeId,
  elementIds,
  propertyValuePair,
  eventTrigger
});

export interface SetElementsLoopsAction {
  type: typeof SET_ELEMENTS_LOOPS;
  scribeId: number;
  elementIds: Array<string>;
  property: ElementAnimationStageLoopsKey;
  value: number;
}

export type SetElementLoopsProperties = Omit<SetElementsLoopsAction, 'type'>;

export const setElementsLoops = ({
  scribeId,
  elementIds,
  property,
  value
}: SetElementLoopsProperties): SetElementsLoopsAction => ({
  type: SET_ELEMENTS_LOOPS,
  scribeId,
  elementIds,
  property,
  value
});

export interface PrepScribeForEditorSuccessAction {
  type: typeof PREP_SCRIBE_FOR_EDITOR_SUCCESS;
  scribe: ExistingScribeModel;
}
export const prepScribeForEditorSuccess = (scribe: ExistingScribeModel): PrepScribeForEditorSuccessAction => ({
  type: PREP_SCRIBE_FOR_EDITOR_SUCCESS,
  scribe
});

export const editorClose = () => ({ type: EDITOR_CLOSE });

export interface UpdateSceneElementsAction {
  type: typeof UPDATE_SCENE_ELEMENTS;
  elements: Array<VSElementModel>;
  thumbnail: string;
  scribeId: number;
  sceneId: string;
}

export const updateSceneElements = (
  elements: Array<VSElementModel>,
  thumbnail: string,
  scribeId: number,
  sceneId: string
): UpdateSceneElementsAction => ({
  type: UPDATE_SCENE_ELEMENTS,
  elements,
  thumbnail,
  scribeId,
  sceneId
});

export interface UpdateSceneThumbnailAction {
  type: typeof UPDATE_SCENE_THUMBNAIL;
  thumbnail: string;
  scribeId: number;
}

export const updateSceneThumbnail = (thumbnail: string, scribeId: number): UpdateSceneThumbnailAction => ({
  type: UPDATE_SCENE_THUMBNAIL,
  thumbnail,
  scribeId
});

export interface CreateSceneAction {
  type: typeof CREATE_SCENE;
  scribeId: number;
  eventTrigger: SceneTriggers;
}

export const createScene = (scribeId: number, eventTrigger: SceneTriggers): CreateSceneAction => ({
  type: CREATE_SCENE,
  scribeId,
  eventTrigger
});

export interface SetSelectedScenesAction {
  type: typeof SET_SELECTED_SCENES;
  sceneIds: Array<string>;
}

export const setSelectedScenes = (sceneIds = []): SetSelectedScenesAction => ({
  type: SET_SELECTED_SCENES,
  sceneIds
});

export interface SetSelectedAudioClipAction {
  type: typeof SET_SELECTED_AUDIO_CLIP;
  selectedAudioClip: AudioClip | undefined;
}

export const setSelectedAudioClip = (selectedAudioClip: AudioClip | undefined): SetSelectedAudioClipAction => ({
  type: SET_SELECTED_AUDIO_CLIP,
  selectedAudioClip
});
export interface SetActiveSceneAction {
  type: typeof SET_ACTIVE_SCENE;
  scribeId: number;
  sceneId: string;
}

export const setActiveScene = (scribeId: number, sceneId: string): SetActiveSceneAction => ({
  type: SET_ACTIVE_SCENE,
  scribeId,
  sceneId
});

export interface DuplicateScenesAction {
  type: typeof DUPLICATE_SELECTED_SCENES;
  scribeId: number;
  eventTrigger: SceneTriggers;
}

export const duplicateScenes = (scribeId: number, eventTrigger: SceneTriggers): DuplicateScenesAction => ({
  type: DUPLICATE_SELECTED_SCENES,
  scribeId,
  eventTrigger
});

export interface DeleteSelectedScenesAction {
  type: typeof DELETE_SELECTED_SCENES;
  scribeId: number;
  eventTrigger: SceneTriggers;
}

export const deleteSelectedScenes = (scribeId: number, eventTrigger: SceneTriggers): DeleteSelectedScenesAction => ({
  type: DELETE_SELECTED_SCENES,
  scribeId,
  eventTrigger
});

export interface UpdateSceneAction {
  type: typeof UPDATE_SCENE;
  scribeId: number;
  scene: ScribeScene;
}

export const updateScene = (scene: ScribeScene, scribeId: number): UpdateSceneAction => ({
  type: UPDATE_SCENE,
  scene,
  scribeId
});

export interface ChangeSceneOrderAction {
  type: typeof CHANGE_SCENE_ORDER;
  scribeId: number;
  source: TimelineDraggablePosition;
  destination: TimelineDraggablePosition;
  draggableId: string;
}

export const changeSceneOrder = (
  scribeId: number,
  source: TimelineDraggablePosition,
  destination: TimelineDraggablePosition,
  draggableId: string
): ChangeSceneOrderAction => ({
  type: CHANGE_SCENE_ORDER,
  scribeId,
  source,
  destination,
  draggableId
});

export interface UpdateUndoRedoHistoryAction {
  type: typeof REWRITE_HISTORY;
  activeScribePast: Array<ExistingScribeModel>;
  activeScribeFuture: Array<ExistingScribeModel>;
}

export const updateUndoRedoHistory = (
  activeScribePast: Array<ExistingScribeModel>,
  activeScribeFuture: Array<ExistingScribeModel>
): UpdateUndoRedoHistoryAction => ({
  type: REWRITE_HISTORY,
  activeScribePast,
  activeScribeFuture
});

export interface AddToSelectedScenesAction {
  type: typeof ADD_TO_SELECTED_SCENES;
  sceneId: string;
}

export const addToSelectedScenes = (sceneId: string): AddToSelectedScenesAction => ({
  type: ADD_TO_SELECTED_SCENES,
  sceneId
});

export interface UpdateLastSelectedSceneAction {
  type: typeof UPDATE_LAST_SELECTED_SCENE;
  sceneId: string;
}

export const updateLastSelectedScene = (sceneId: string): UpdateLastSelectedSceneAction => ({
  type: UPDATE_LAST_SELECTED_SCENE,
  sceneId
});

export const selectAll = (scribeId: number, eventTrigger: string) => ({
  type: SELECT_ALL,
  scribeId,
  eventTrigger
});

export interface OnMoveWithKeysAction {
  type: typeof ON_MOVE_WITH_KEYS;
  scribeId: number;
  axis: string;
  modifier: number;
}

export const onMoveWithKeys = (scribeId: number, axis: string, modifier: number): OnMoveWithKeysAction => ({
  type: ON_MOVE_WITH_KEYS,
  scribeId,
  axis,
  modifier
});

export interface SetActiveElementsVisibilityAction {
  type: typeof SET_ACTIVE_ELEMENTS_VISIBILITY;
  scribeId: number;
  eventTrigger: HideEventTrigger;
  hidden: boolean;
}

export const setActiveElementsVisibility = (
  scribeId: number,
  eventTrigger: HideEventTrigger,
  hidden: boolean
): SetActiveElementsVisibilityAction => ({
  type: SET_ACTIVE_ELEMENTS_VISIBILITY,
  scribeId,
  hidden,
  eventTrigger
});

export interface SetElementsVisibilityAction {
  type: typeof SET_ELEMENTS_VISIBILITY;
  scribeId: number;
  hidden: boolean;
}

export const setElementsVisibility = (scribeId: number, hidden: boolean): SetElementsVisibilityAction => ({
  type: SET_ELEMENTS_VISIBILITY,
  scribeId,
  hidden
});

export interface SetSelectedTextColorAction {
  type: typeof SET_SELECTED_TEXT_COLOR;
  color: string | undefined | null;
}

export const setSelectedTextColor = (color: string | undefined | null): SetSelectedTextColorAction => ({
  type: SET_SELECTED_TEXT_COLOR,
  color
});

export interface SetCustomTextStyleAction {
  type: typeof SET_CUSTOM_TEXT_STYLE;
  style: CustomTextStyleType | null;
}

export const setCustomTextStyle = (style: CustomTextStyleType | null): SetCustomTextStyleAction => ({
  type: SET_CUSTOM_TEXT_STYLE,
  style
});

export interface SetIsTextboxEditingAction {
  type: typeof SET_IS_TEXTBOX_EDITING;
  isEditing: boolean;
}

export const setIsTextboxEditing = (isEditing: boolean): SetIsTextboxEditingAction => ({
  type: SET_IS_TEXTBOX_EDITING,
  isEditing
});
