import { createAutosaveQueue } from 'js/actionCreators/autoSaveActions';
import { PrepScribeForEditorSuccessAction } from 'js/actionCreators/scribeActions';
import { PendingSave, RootState } from 'js/types';
import { ForkEffect, PutEffect, SelectEffect, fork, put, select } from 'redux-saga/effects';

import { autoSavePollingSaga } from './autoSavePollingSaga';

export function* queueManager({
  scribe
}: PrepScribeForEditorSuccessAction): Generator<SelectEffect | ForkEffect | PutEffect, void, PendingSave> {
  const existingQueue = yield select((state: RootState) => state.autoSave.pendingSaveQueue[scribe.id]);
  if (!existingQueue) {
    yield put(createAutosaveQueue(scribe.id));
    yield fork(autoSavePollingSaga, scribe.id);
  }
}
