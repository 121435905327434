import { ExistingScribeModel } from 'js/types';

export const EDITOR_CUT = 'EDITOR_CUT';
export const EDITOR_COPY = 'EDITOR_COPY';
export const EDITOR_PASTE = 'EDITOR_PASTE';
export const EDITOR_UNDO = 'EDITOR_UNDO';
export const EDITOR_UNDO_SUCCESS = 'EDITOR_UNDO_SUCCESS';
export const EDITOR_REDO = 'EDITOR_REDO';
export const EDITOR_REDO_SUCCESS = 'EDITOR_REDO_SUCCESS';
export const EDITOR_CLOSED = 'EDITOR_CLOSED';
export const EDITOR_KEYBOARD_DELETE = 'EDITOR_KEYBOARD_DELETE';
export const EDITOR_SET_EDIT_PANEL_MODE = 'EDITOR_SET_EDIT_PANEL_MODE';
export const TOGGLE_NEW_TIMELINE = 'TOGGLE_NEW_TIMELINE';
export const TOGGLE_FIRST_ARROW_PRESS = 'TOGGLE_FIRST_ARROW_PRESS';

export const editorClosed = () => ({
  type: EDITOR_CLOSED
});

export interface EditorCutAction {
  type: typeof EDITOR_CUT;
  scribeId: number;
}

export const editorCut = (scribeId: number): EditorCutAction => ({
  type: EDITOR_CUT,
  scribeId
});

export interface EditorCopyAction {
  type: typeof EDITOR_COPY;
  scribeId: number;
  asCut: boolean;
}

export const editorCopy = (scribeId: number, asCut = false): EditorCopyAction => ({
  type: EDITOR_COPY,
  scribeId,
  asCut
});

export interface EditorPasteAction {
  type: typeof EDITOR_PASTE;
  scribeId: number;
  inPlace: boolean;
  intialContent: string;
}

export const editorPaste = (scribeId: number, inPlace = false, intialContent = ''): EditorPasteAction => ({
  type: EDITOR_PASTE,
  scribeId,
  inPlace,
  intialContent
});

export interface EditorUndoAction {
  type: typeof EDITOR_UNDO;
  scribeId: number;
}

export const editorUndo = (scribeId: number): EditorUndoAction => ({
  type: EDITOR_UNDO,
  scribeId
});

export interface EditorUndoSuccessAction {
  type: typeof EDITOR_UNDO_SUCCESS;
  newPast: Array<ExistingScribeModel>;
  newFuture: Array<ExistingScribeModel>;
}

export const editorUndoSuccess = (
  newPast: Array<ExistingScribeModel>,
  newFuture: Array<ExistingScribeModel>
): EditorUndoSuccessAction => ({
  type: EDITOR_UNDO_SUCCESS,
  newPast,
  newFuture
});

export interface EditorRedoAction {
  type: typeof EDITOR_REDO;
  scribeId: number;
}

export const editorRedo = (scribeId: number): EditorRedoAction => ({
  type: EDITOR_REDO,
  scribeId
});

export interface EditorRedoSuccessAction {
  type: typeof EDITOR_REDO_SUCCESS;
  newPast: Array<ExistingScribeModel>;
  newFuture: Array<ExistingScribeModel>;
}

export const editorRedoSuccess = (
  newPast: Array<ExistingScribeModel>,
  newFuture: Array<ExistingScribeModel>
): EditorRedoSuccessAction => ({
  type: EDITOR_REDO_SUCCESS,
  newPast,
  newFuture
});

export interface EditorKeyboardDeleteAction {
  type: typeof EDITOR_KEYBOARD_DELETE;
  scribeId: number;
}

export const editorKeyboardDelete = (scribeId: number): EditorKeyboardDeleteAction => ({
  type: EDITOR_KEYBOARD_DELETE,
  scribeId
});
export interface EditorSetEditPanelModeAction {
  type: typeof EDITOR_SET_EDIT_PANEL_MODE;
  mode: 'audio' | null;
  scribeId?: number;
}

export const editorSetEditPanelMode = (mode: 'audio' | null, scribeId?: number): EditorSetEditPanelModeAction => ({
  type: EDITOR_SET_EDIT_PANEL_MODE,
  mode,
  scribeId
});

export const toggleNewTimeline = () => ({
  type: TOGGLE_NEW_TIMELINE
});

export const toggleFirstArrowPress = () => ({
  type: TOGGLE_FIRST_ARROW_PRESS
});
