import flatten from 'lodash.flatten';

export interface AllFonts {
  Arabic: Array<string>;
  Bengali: Array<string>;
  'Cyrillic + Extended': Array<string>;
  'Chinese Simplified': Array<string>;
  Devangari: Array<string>;
  'Greek + Extended': Array<string>;
  Gujurati: Array<string>;
  Gurmukhi: Array<string>;
  Hebrew: Array<string>;
  Japanese: Array<string>;
  Khmer: Array<string>;
  Korean: Array<string>;
  'Latin + Extended': Array<string>;
  Myanmar: Array<string>;
  Sinhala: Array<string>;
  Tamil: Array<string>;
  Telugu: Array<string>;
  Thai: Array<string>;
  Vietnamese: Array<string>;
  Others: Array<string>;
}

const allFonts: AllFonts = {
  Arabic: ['Amiri', 'Cairo', 'Lalezar', 'Lateef', 'Noto Kufi Arabic', 'Noto Sans Arabic', 'Tajawal'],
  Bengali: ['Hind Siliguri', 'Mina', 'Atma', 'Galada'],
  'Cyrillic + Extended': ['Open Sans', 'Roboto', 'Roboto Condensed', 'Playfair Display', 'Oswald', 'Pacifico'],
  'Chinese Simplified': ['Noto Serif SC', 'Noto Sans SC'],
  Devangari: ['Noto Sans', 'Poppins', 'Rajdhani', 'Laila'],
  'Greek + Extended': ['Open Sans', 'Roboto', 'Noto Serif', 'Comfortaa', 'Cardo'],
  Gujurati: ['Hind Vadodara', 'Mogra', 'Rasa', 'Shrikhand', 'Mukta Vaani'],
  Gurmukhi: ['Mukta Mahee', 'Baloo Paaji 2'],
  Hebrew: ['Arimo', 'Varela Round', 'Heebo', 'David Libre', 'Alef'],
  Japanese: ['Noto Serif JP', 'Kosugi Maru', 'M PLUS Rounded 1c', 'Sawarabi Gothic', 'Sawarabi Mincho'],
  Khmer: ['Hanuman', 'Battambang', 'Dangrek', 'Angkor', 'Content'],
  Korean: ['Nanum Gothic', 'Nanum Myeongjo', 'Nanum Pen Script', 'Song Myung', 'Black Han Sans', 'Noto Sans KR'],
  'Latin + Extended': ['Open Sans', 'Montserrat', 'Playfair Display', 'Oswald', 'Pacifico', 'Roboto'],
  Myanmar: ['Padauk'],
  Sinhala: ['Abhaya Libre'],
  Tamil: ['Catamaran', 'Hind Madurai', 'Pavanam', 'Kavivanar'],
  Telugu: ['Suranna', 'Hind Guntur', 'Sree Krushnadevaraya', 'Tenali Ramakrishna', 'Peddana'],
  Thai: ['Kanit', 'Prompt', 'Maitree', 'Sriracha', 'Pridi'],
  Vietnamese: ['Open Sans', 'Roboto', 'Playfair Display', 'Oswald', 'Pacifico'],
  Others: [
    'Abel',
    'Abril Fatface',
    'Acme',
    'Alegreya',
    'Alegreya Sans',
    'Amatic SC',
    'Anton',
    'Archivo Black',
    'Arvo',
    'Bangers',
    'Barlow',
    'Barlow Condensed',
    'Bitter',
    'Bree Serif',
    'Caveat',
    'Cinzel',
    'Comfortaa',
    'Cookie',
    'Courgette',
    'Dancing Script',
    'Dosis',
    'Exo',
    'Exo 2',
    'Fira Sans',
    'Fjalla One',
    'Fredoka One',
    'Gloria Hallelujah',
    'Gochi Hand',
    'Great Vibes',
    'Handlee',
    'Inconsolata',
    'Indie Flower',
    'Josefin Sans',
    'Kalam',
    'Kaushan Script',
    'Lato',
    'Libre Baskerville',
    'Lobster',
    'Lora',
    'Luckiest Guy',
    'Marck Script',
    'Martel',
    'Merienda',
    'Merriweather',
    'Mukta',
    'Muli',
    'Nunito',
    'Overpass',
    'Oxygen',
    'Patrick Hand',
    'Patua One',
    'Permanent Marker',
    'Poiret One',
    'PT Sans',
    'PT Serif',
    'Questrial',
    'Quicksand',
    'Raleway',
    'Righteous',
    'Rock Salt',
    'Rubik',
    'Sacramento',
    'Satisfy',
    'Shadows Into Light',
    'Slabo 27px',
    'Source Code Pro',
    'Source Sans Pro',
    'Source Serif Pro',
    'Special Elite',
    'Teko',
    'Titillium Web',
    'Ubuntu',
    'Viga',
    'Vollkorn',
    'Work Sans',
    'Yanone Kaffeesatz',
    'Yellowtail',
    'Zilla Slab'
  ]
};

export const dynamicSort = (property: string) => {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.replace('-', '');
  }

  return function (a: Record<string, string>, b: Record<string, string>) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};

const prepFonts = (fonts: Array<string>) => fonts.map(val => ({ label: val, value: val })).sort(dynamicSort('label'));

export const getFontsByRegion = (region: keyof AllFonts) => {
  if (!allFonts[region]) {
    return [];
  }

  return prepFonts(allFonts[region]);
};
export const getRegions = () => Object.keys(allFonts);
export default prepFonts(Array.from(new Set(flatten(Object.values(allFonts)))));
