import { DownloadTrackingProperties } from 'js/types';

export const DOWNLOAD_REQUEST_FAILED = 'DOWNLOAD_REQUEST_FAILED';

export type DownloadRequestFailedAction = {
  type: typeof DOWNLOAD_REQUEST_FAILED;
  error: Error;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export const downloadRequestFailed = (
  error: Error,
  trackingProperties: DownloadTrackingProperties,
  scribeId: number
) => {
  return {
    type: DOWNLOAD_REQUEST_FAILED,
    error,
    trackingProperties,
    scribeId
  };
};
