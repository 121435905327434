import getXmlFromUrl from 'js/shared/helpers/getXmlFromUrl';
import { encodeSvgFromString } from 'js/shared/helpers/svgToDataUrl';
import { FILE_CONTENT_TYPES, IMAGE_PROVIDER_TYPES } from 'js/config/consts';

import getUserImageByAssetId from './UserImageProvider';
import { getImageByIdFunction } from './ImageLibrary';
import uploadingAssetProvider from './UploadingAssetProvider';
import { getSVGAttributeValueByVSTag } from './helpers/getSVGAttributeValueByVSTag';

const parser = new DOMParser();

export const getRecolorDefaults = svg => {
  const primaryColor = getSVGAttributeValueByVSTag(svg, '#vs-primary', 'fill', 'black');
  const secondaryColor = getSVGAttributeValueByVSTag(svg, '#vs-secondary', 'fill', 'black');
  const skinColor = getSVGAttributeValueByVSTag(svg, '#vs-skin', 'fill', 'black');
  const hairColor = getSVGAttributeValueByVSTag(svg, '#vs-hair', 'fill', 'black');
  const nounColor = svg.getAttribute('data-provider') === 'noun-project' ? 'black' : undefined;

  const outlines = svg ? svg.querySelector(`#vs-outline`) : undefined;
  let strokeColor;

  if (outlines) {
    const outlinesChildren = Array.from(outlines.children);
    let strokeColors = [];
    for (const path of outlinesChildren) {
      strokeColors.push(path.getAttribute('stroke'));
    }
    let uniqueStrokeColors = [...new Set(strokeColors)];
    if (uniqueStrokeColors.length === 1) {
      strokeColor = uniqueStrokeColors[0];
    }
  }

  const highlightColor = getSVGAttributeValueByVSTag(svg, '#vs-highlight', 'fill', 'black');

  const recolorDefaults = {
    ...(nounColor && { nounColor }),
    ...(primaryColor && { primaryColor }),
    ...(secondaryColor && { secondaryColor }),
    ...(skinColor && { skinColor }),
    ...(hairColor && { hairColor }),
    ...(strokeColor && { strokeColor }),
    ...(highlightColor && { highlightColor })
  };

  return recolorDefaults;
};

export const recolorSvg = (svg, recolor) => {
  const svgDoc = parser.parseFromString(svg, FILE_CONTENT_TYPES.SVG).querySelector('svg');

  if (recolor.primaryColor) {
    svgDoc
      .querySelectorAll('#vs-primary, #vs-primary [fill]')
      .forEach(el => el.setAttribute('fill', recolor.primaryColor));
  }
  if (recolor.secondaryColor) {
    svgDoc
      .querySelectorAll('#vs-secondary, #vs-secondary [fill]')
      .forEach(el => el.setAttribute('fill', recolor.secondaryColor));
  }
  if (recolor.skinColor) {
    svgDoc.querySelectorAll('#vs-skin, #vs-skin [fill]').forEach(el => el.setAttribute('fill', recolor.skinColor));
  }
  if (recolor.hairColor) {
    svgDoc.querySelectorAll('#vs-hair, #vs-hair [fill]').forEach(el => el.setAttribute('fill', recolor.hairColor));
  }
  if (recolor.highlightColor) {
    svgDoc.querySelectorAll('#vs-highlight [fill]').forEach(el => el.setAttribute('fill', recolor.highlightColor));
  }
  if (recolor.strokeColor) {
    svgDoc.querySelectorAll('#vs-outline [stroke]').forEach(el => el.setAttribute('stroke', recolor.strokeColor));
  }
  if (recolor.nounColor) {
    svgDoc.setAttribute('fill', recolor.nounColor);
    svgDoc.querySelectorAll('[fill]').forEach(elem => elem.setAttribute('fill', recolor.nounColor));
    svgDoc.querySelectorAll('[stroke]').forEach(elem => elem.setAttribute('stroke', recolor.nounColor));
  }
  const newSvg = new XMLSerializer().serializeToString(svgDoc);
  return newSvg;
};

const addHeightAndWidth = svg => {
  const svgDoc = parser.parseFromString(svg, FILE_CONTENT_TYPES.SVG).querySelector('svg');
  const svgOriginalViewBox = svgDoc.getAttribute('viewBox').split(' ');
  svgDoc.setAttribute('width', parseFloat(svgOriginalViewBox[2]));
  svgDoc.setAttribute('height', parseFloat(svgOriginalViewBox[3]));

  const newSvg = new XMLSerializer().serializeToString(svgDoc);
  return newSvg;
};

export const getProviderForType = (type = 'user') => {
  if (type === IMAGE_PROVIDER_TYPES.UPLOAD) {
    return uploadingAssetProvider.getUrlById;
  } else if (type === IMAGE_PROVIDER_TYPES.USER || type === IMAGE_PROVIDER_TYPES.AI_LIBRARY) {
    return getUserImageByAssetId;
  } else {
    return getImageByIdFunction(type);
  }
};

export const getSvgHeightAndWidth = async ({ provider, contentType, assetId }) => {
  const url = await getProviderForType(provider)(assetId, false, contentType);

  const originalXml = await getXmlFromUrl(url);
  const xml = addHeightAndWidth(originalXml);

  return {
    url: encodeSvgFromString(xml)
  };
};
