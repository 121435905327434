import * as PIXI from 'pixi.js';

import { BUTTON_HOVER_COLOR, BUTTON_ICON_COLOR } from './consts';

export default class IconButton extends PIXI.Sprite {
  constructor(iconTexture: PIXI.Texture, onClick: () => void) {
    super(iconTexture);

    this.interactive = true;
    this.buttonMode = true;
    this.tint = BUTTON_ICON_COLOR;

    this.on('pointerdown', onClick);
    this.on('pointerover', this.handlePointerOver);
    this.on('pointerout', this.handlePointerOut);
  }

  private handlePointerOver() {
    this.tint = BUTTON_HOVER_COLOR;
  }

  private handlePointerOut() {
    this.tint = BUTTON_ICON_COLOR;
  }
}
