import { call, takeEvery } from 'redux-saga/effects';
import { GetProjectSharingIdAction, GET_PROJECT_SHARING_ID } from 'js/actionCreators/previewActions';
import { TRACK_PREVIEW_LINK_COPY, TrackPreviewLinkCopyAction } from 'js/actionCreators/trackingActions';

import { track } from '../mixpanelProvider';

function* trackCreatePreviewLink({ projectId }: GetProjectSharingIdAction) {
  yield call(track, 'Create Preview Link', { 'Scribe ID': projectId });
}

function* trackPreviewLinkCopy({ scribeId }: TrackPreviewLinkCopyAction) {
  yield call(track, 'Copy Preview Link', { 'Scribe ID': scribeId });
}

export default function* trackPreviewSagas() {
  yield takeEvery(GET_PROJECT_SHARING_ID, trackCreatePreviewLink);
  yield takeEvery(TRACK_PREVIEW_LINK_COPY, trackPreviewLinkCopy);
}
