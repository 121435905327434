import { VSShapeOrTextElementModel } from 'js/types';
import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';

import { isShapeElement, isTextElement } from '../EditorCanvas/helpers/canvasElementHelpers';

export const getShapeElementColor = (element: ScribeShapeElementModel) => {
  return isShapeElement(element) ? element.fill : undefined;
};

export const getTextElementFirstCharColor = (element: ScribeTextElementModel) => {
  const styles = Object.values(element.styles || {});
  const firstCharColor: string | undefined = styles.flatMap(Object.values).find(char => char.fill)?.fill;
  return firstCharColor;
};

export const getTextElementColor = (element: ScribeTextElementModel) => {
  const firstCharColor = getTextElementFirstCharColor(element);
  return firstCharColor;
};

export const getElementColor = (element: VSShapeOrTextElementModel) => {
  if (isShapeElement(element)) return getShapeElementColor(element);
  if (isTextElement(element)) return getTextElementColor(element);
};

export const getElementsColor = (elements: Array<VSShapeOrTextElementModel>) => {
  const colors = elements.map(getElementColor);
  return colors[0];
};
