export const AUDIO_FILE_SIZE_LIMIT_REACHED = 'AUDIO_FILE_SIZE_LIMIT_REACHED';
export const AUDIO_TRACK_LIMIT_REACHED = 'AUDIO_TRACK_LIMIT_REACHED';

export interface AudioFileSizeLimitReachedAction {
  type: typeof AUDIO_FILE_SIZE_LIMIT_REACHED;
  projectId: number;
}
export const audioFileSizeLimitReached = (projectId: number): AudioFileSizeLimitReachedAction => ({
  type: AUDIO_FILE_SIZE_LIMIT_REACHED,
  projectId
});

export interface AudioTrackLimitReachedAction {
  type: typeof AUDIO_TRACK_LIMIT_REACHED;
  projectId: number;
}
export const audioTrackLimitReached = (projectId: number): AudioTrackLimitReachedAction => ({
  type: AUDIO_TRACK_LIMIT_REACHED,
  projectId
});
