import { call, select, takeEvery, take } from 'redux-saga/effects';
import {
  CANCEL_IMAGE_CROP,
  CONFIRM_IMAGE_CROP,
  START_IMAGE_CROP,
  CANCEL_CROP_WARNING,
  CONFIRM_CROP_WARNING
} from 'js/actionCreators/croppingActions';
import { getScribeById } from 'js/sagas/selectors';

import { track } from '../mixpanelProvider';

function* trackStartImageCrop({ scribeId, elementId }) {
  const scribe = yield select(getScribeById, scribeId);
  const element = scribe.elements.find(el => el.id === elementId);

  if (element) {
    const eventProperties = {
      'Scribe ID': scribeId,
      'File Type': element.image?.contentType
    };
    yield call(track, 'Enable Cropping', eventProperties);

    const nextAction = yield take([CANCEL_IMAGE_CROP, CONFIRM_IMAGE_CROP]);
    const nextEventName = nextAction.type === CANCEL_IMAGE_CROP ? 'Cancel Cropping' : 'Confirm Cropping';

    yield call(track, nextEventName, eventProperties);
  }
}

function* cancelCropWarning({ scribeId }) {
  yield call(track, 'Cancel Removing Hand-Draw', {
    'Scribe ID': scribeId
  });
}

function* trackConfirmCropWarning({ scribeId, suppressWarning }) {
  yield call(track, 'Confirm Removing Hand-Draw', {
    'Scribe ID': scribeId,
    "Don't Show Reminder": suppressWarning
  });
}

export default function* trackImageCroppingSagas() {
  yield takeEvery(START_IMAGE_CROP, trackStartImageCrop);
  yield takeEvery(CANCEL_CROP_WARNING, cancelCropWarning);
  yield takeEvery(CONFIRM_CROP_WARNING, trackConfirmCropWarning);
}
