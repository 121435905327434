import React, { useRef } from 'react';
import { Button } from '@sparkol/videoscribe-react-ui';
import IconUpload from 'js/shared/icons/IconUpload';

type FileUploadFieldProps = {
  children: React.ReactNode;
  onFileUpload: (files: FileList) => void;
  onButtonClick?: () => void;
  id: string;
  disabled?: boolean;
  allowMimeType?: string;
  allowMultiple?: boolean;
  onFileInputClick?: () => void;
};

const FileUploadField = ({
  children,
  onFileUpload,
  onButtonClick,
  id,
  disabled,
  allowMimeType,
  allowMultiple = false,
  onFileInputClick
}: FileUploadFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    onButtonClick?.();
    inputRef.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (!files) return;
    onFileUpload(files);
  };

  return (
    <>
      <Button
        variant="tertiary"
        type="button"
        iconPosition="left"
        onClick={handleButtonClick}
        icon={<IconUpload />}
        disabled={disabled}
      >
        {children}
      </Button>
      <input
        ref={inputRef}
        type="file"
        hidden
        id={id}
        accept={allowMimeType}
        onChange={handleChange}
        disabled={disabled}
        multiple={allowMultiple}
        onClick={onFileInputClick}
      />
    </>
  );
};

export default FileUploadField;
