import * as PIXI from 'pixi.js';
import IconAudioDragIndicatorTextBox from 'imgs/pixi_icons/IconDragIndicatorTextSurround.svg';

import TimelineHelpers from './Utils/TimelineHelpers';
import TimelineWrapper from './TimelineWrapper';
import {
  TIMELINE_TOOLTIP_TEXT_COLOUR,
  TIMELINE_TOOLTIP_TEXT_SIZE,
  TIMELINE_TOOLTIP_TEXT_WEIGHT
} from './TimelineLayerTooltip';

export default class TimelineAudioDragIndicator extends PIXI.Sprite {
  dragStartX: number;

  wrapperRef: TimelineWrapper;
  dragTextDisplayBg?: PIXI.Sprite;
  dragTextDisplayText?: PIXI.Text;
  dragTextDisplayHolder?: PIXI.Container;
  textStyle: PIXI.TextStyle;
  constructor(wrapperRef: TimelineWrapper) {
    super();
    this.wrapperRef = wrapperRef;
    this.textStyle = new PIXI.TextStyle({
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--fontFamilyBody'),
      fontSize: TIMELINE_TOOLTIP_TEXT_SIZE,
      fill: TIMELINE_TOOLTIP_TEXT_COLOUR,
      fontWeight: TIMELINE_TOOLTIP_TEXT_WEIGHT,
      align: 'left'
    });
    this.dragStartX = 0;
    const grabHandleHolder = new PIXI.Container();
    this.addChild(grabHandleHolder);
    grabHandleHolder.x = -this.wrapperRef.currentZoom.y / 2;
    grabHandleHolder.y = -this.wrapperRef.currentZoom.y / 2;

    const dragTextBoxTexture = TimelineHelpers.importSVG(IconAudioDragIndicatorTextBox);
    if (dragTextBoxTexture.valid) {
      this.buildTextBoxAndBg(dragTextBoxTexture);
    } else {
      dragTextBoxTexture.on('update', () => {
        this.buildTextBoxAndBg(dragTextBoxTexture);
      });
    }
  }
  private buildTextBoxAndBg(dragTextBoxTexture: PIXI.Texture) {
    if (this.dragTextDisplayBg) return;

    this.dragTextDisplayBg = new PIXI.Sprite(dragTextBoxTexture);
    this.dragTextDisplayText = new PIXI.Text('0.2', this.textStyle);
    this.dragTextDisplayBg.scale.set(0.5);
    this.dragTextDisplayBg.anchor.set(1, 0.5);

    this.dragTextDisplayText.x = this.dragTextDisplayBg.width / 2;
    this.dragTextDisplayText.y = this.dragTextDisplayBg.height / 2;
    this.dragTextDisplayHolder = new PIXI.Container();
    this.dragTextDisplayHolder.addChild(this.dragTextDisplayBg);
    this.dragTextDisplayHolder.addChild(this.dragTextDisplayText);
    this.dragTextDisplayHolder.y = -this.wrapperRef.currentZoom.y;
    this.addChild(this.dragTextDisplayHolder);
  }

  public updateOffset(time: number, rhs: boolean, position: PIXI.Point) {
    const offsetTime = time;

    if (this.dragTextDisplayBg && this.dragTextDisplayText) {
      if (rhs === true) {
        this.dragTextDisplayBg.scale.x = 0.5;

        this.dragTextDisplayText.x = -this.dragTextDisplayBg.width / 2 - this.dragTextDisplayText.width / 2;
        this.dragTextDisplayText.y = -this.dragTextDisplayBg.height / 2 + this.dragTextDisplayText.height / 2;
      }
      if (rhs === false) {
        this.dragTextDisplayBg.scale.x = -0.5;

        this.dragTextDisplayText.x = this.dragTextDisplayBg.width / 2 - this.dragTextDisplayText.width / 2;
        this.dragTextDisplayText.y = -this.dragTextDisplayBg.height / 2 + this.dragTextDisplayText.height / 2;
      }
      this.x = position.x;
      this.y = position.y - this.wrapperRef.currentZoom.y / 2;
      this.dragTextDisplayText.text =
        (rhs ? '<' : '>') +
        Math.abs(offsetTime)
          .toFixed(2)
          .toString();
    }
  }
}
