import { SVGViewBoxAttributes, ViewBoxAttributes } from 'js/types';

import { appendImageToTempCanvas } from './appendImageToTempCanvas';
import svgToDataUrl from './svgToDataUrl';

const PADDING = 2;
const IMAGE_DATA_RGBA_VALUE_COUNT = 4;

export const SVG_BOUNDARY_ERROR = 'Failed to find SVG Boundaries';

export const scanSVGImageDataForColorValues = (imageData: Uint8ClampedArray, width: number, height: number) => {
  /**
   * A 2d array which represents the image as pixel information.
   * We can scan from each edge and find when the first piece of colour information is.
   *
   * A 2 x 2 pixel image with no colour information will be like this:
   * [
   *  [0,0,0,0,0,0,0,0],
   *  [0,0,0,0,0,0,0,0]
   * ]
   */
  const pixelValues = [];
  const columns = width * IMAGE_DATA_RGBA_VALUE_COUNT;

  while (pixelValues.length < height)
    pixelValues.push(imageData.slice(columns * pixelValues.length, columns * pixelValues.length + columns));

  let topBoundary;
  for (let i = 0; i < pixelValues.length; i++) {
    if (!pixelValues[i].every(val => val === 0)) {
      topBoundary = i;
      break;
    }
  }

  let bottomBoundary;
  for (let i = pixelValues.length - 1; i > -1; i--) {
    if (!pixelValues[i].every(val => val === 0)) {
      bottomBoundary = i;
      break;
    }
  }

  let leftBoundary;
  for (let i = 0; i < columns; i++) {
    if (typeof leftBoundary === 'number') {
      break;
    }

    for (let j = 0; j < pixelValues.length; j++) {
      if (pixelValues[j][i] !== 0) {
        leftBoundary = Math.floor(i / IMAGE_DATA_RGBA_VALUE_COUNT);
        break;
      }
    }
  }

  let rightBoundary;
  for (let i = columns - 1; i > -1; i--) {
    if (typeof rightBoundary === 'number') {
      break;
    }

    for (let j = 0; j < pixelValues.length; j++) {
      if (pixelValues[j][i] !== 0) {
        rightBoundary = Math.floor(i / IMAGE_DATA_RGBA_VALUE_COUNT);
        break;
      }
    }
  }

  return {
    leftBoundary,
    rightBoundary,
    topBoundary,
    bottomBoundary
  };
};

export async function measureSvgContentBoundingBox(svgElement: SVGSVGElement): Promise<ViewBoxAttributes> {
  if (!svgElement) {
    throw new Error('Image failed to load - no SVG Element');
  }
  const originalWidth = Number(svgElement.getAttribute('width'));
  const originalHeight = Number(svgElement.getAttribute('height'));
  const originalViewbox = svgElement.getAttribute('viewBox') ?? `0 0 ${originalWidth} ${originalHeight}`;
  const [minX, minY, w, h] = originalViewbox.split(' ');
  const originalMinX = Number(minX) || 0;
  const originalMinY = Number(minY) || 0;
  const originalViewboxWidth = Number(w) || 1;
  const originalViewboxHeight = Number(h) || 1;

  const minWidth = Math.floor(originalWidth);
  const minHeight = Math.floor(originalHeight);

  const scaleH = originalViewboxWidth / originalWidth;
  const scaleV = originalViewboxHeight / originalHeight;

  const scanSVGForBoundaries = async (): Promise<SVGViewBoxAttributes | undefined> => {
    const canvasCtx = await appendImageToTempCanvas(svgToDataUrl(svgElement), originalWidth, originalHeight);

    if (!canvasCtx) {
      return undefined;
    }

    /**
     * An array of numbers representing the RGBA values of each pixel.
     * each number value in an 'rgba(0,0,0,0)' string is returned as a number.
     * If there is no colour information it adds 4 array items, each as 0.
     *
     * A 2 x 2 pixel svg with no colour information will be like this:
     * [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
     */
    const imageDataRaw = canvasCtx.getImageData(0, 0, originalWidth, originalHeight).data;

    const { leftBoundary, rightBoundary, topBoundary, bottomBoundary } = scanSVGImageDataForColorValues(
      imageDataRaw,
      minWidth,
      minHeight
    );

    if (
      leftBoundary === undefined ||
      rightBoundary === undefined ||
      topBoundary === undefined ||
      bottomBoundary === undefined
    ) {
      return undefined;
    }

    const width = Math.ceil(rightBoundary - leftBoundary) + PADDING * 2;
    const height = Math.ceil(bottomBoundary - topBoundary) + PADDING * 2;

    const viewBox = [
      (leftBoundary + originalMinX - PADDING) * scaleH,
      (topBoundary + originalMinY - PADDING) * scaleV,
      width * scaleH,
      height * scaleV
    ].join(' ');

    return {
      viewBox,
      width,
      height
    };
  };

  const contents = await scanSVGForBoundaries();

  if (!contents) {
    throw new Error(SVG_BOUNDARY_ERROR);
  }

  return {
    contents,
    original: {
      viewBox: originalViewbox,
      width: originalWidth,
      height: originalHeight
    }
  };
}

export function applySvgViewboxAttributes(svgElement: SVGSVGElement, attributesToApply: SVGViewBoxAttributes) {
  const { width, height, viewBox } = attributesToApply;

  svgElement.setAttribute('width', width.toString());
  svgElement.setAttribute('height', height.toString());
  svgElement.setAttribute('viewBox', viewBox);
  svgElement.setAttribute('preserveAspectRatio', 'none');

  return svgElement;
}
