import UserModel from 'js/shared/helpers/app-services/UserModel';

export const SET_RENDERER_USER = 'SET_RENDERER_USER';

export type SetRendererUserAction = {
  type: typeof SET_RENDERER_USER;
  user: UserModel;
};

export const setRendererUser = (user: UserModel): SetRendererUserAction => ({
  type: SET_RENDERER_USER,
  user
});
