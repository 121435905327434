import cookie from 'cookie';
import parseISO from 'date-fns/parseISO';

const KEY_SPARKOL_TOKEN = 'token';
const KEY_UID = 'uid';
const KEY_USER_INFO = 'user_info';

export function setSparkolCookies({ token, tokenTtl, user }) {
  const path = '/';
  const timePadding = 20; // expire a bit early: better than a stale token
  const tokenTtlParsed = typeof tokenTtl === 'string' ? parseInt(tokenTtl, 10) : tokenTtl;
  const maxAge = isNaN(tokenTtlParsed) ? undefined : Math.max(0, tokenTtl - timePadding);
  document.cookie = cookie.serialize(KEY_SPARKOL_TOKEN, token, { path, maxAge });
  document.cookie = cookie.serialize(KEY_UID, user.id, { path, maxAge });
  document.cookie = cookie.serialize(KEY_USER_INFO, JSON.stringify({ ...user, id: user.id.toString() }), {
    path,
    maxAge
  });
}

export function getUID() {
  const cookies = cookie.parse(document.cookie);
  return cookies[KEY_UID] || '';
}

export function getSparkolToken() {
  const cookies = cookie.parse(document.cookie);
  return cookies[KEY_SPARKOL_TOKEN] || '';
}

export function getUserInfo() {
  const cookies = cookie.parse(document.cookie);
  const userInfo = cookies[KEY_USER_INFO];

  if (userInfo) {
    const parsed = JSON.parse(userInfo);
    parsed.trialExpiryDate = parseISO(parsed.trialExpiryDate);
    return parsed;
  } else {
    throw new Error('No user info stored in cookies');
  }
}

export function removeSparkolCookies() {
  const cookies = cookie.parse(document.cookie);
  const path = '/';

  if (typeof cookies[KEY_SPARKOL_TOKEN] === 'undefined') {
    return;
  }

  document.cookie = cookie.serialize(KEY_SPARKOL_TOKEN, false, { maxAge: -1, expires: new Date(), path });
  document.cookie = cookie.serialize(KEY_UID, false, { maxAge: -1, expires: new Date(), path });
  document.cookie = cookie.serialize(KEY_USER_INFO, false, { maxAge: -1, expires: new Date(), path });
}

export function getRendererAuth() {
  const cookies = cookie.parse(document.cookie);
  return {
    rendererAuth: cookies.rendererAuth,
    rendererUser: cookies.rendererUser
  };
}
