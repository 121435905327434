export const NEEDS_TO_ACCEPT_TERMS = 'NEEDS_TO_ACCEPT_TERMS';
export const NEEDS_TO_ACCEPT_TERMS_ERROR = 'NEEDS_TO_ACCEPT_TERMS_ERROR';
export const USER_ACCEPTS_TERMS = 'USER_ACCEPTS_TERMS';
export const USER_ACCEPTS_TERMS_SUCCESS = 'USER_ACCEPTS_TERMS_SUCCESS';
export const USER_ACCEPTS_TERMS_FAILED = 'USER_ACCEPTS_TERMS_FAILED';

export interface NeedsToAcceptTermsAction {
  type: typeof NEEDS_TO_ACCEPT_TERMS;
  needsToAccept: boolean;
}
export const needsToAcceptTerms = (needsToAccept: boolean): NeedsToAcceptTermsAction => ({
  type: NEEDS_TO_ACCEPT_TERMS,
  needsToAccept
});

export interface NeedsToAcceptTermsErrorAction {
  type: typeof NEEDS_TO_ACCEPT_TERMS_ERROR;
  error: Error;
}
export const needsToAcceptTermsError = (error: Error): NeedsToAcceptTermsErrorAction => ({
  type: NEEDS_TO_ACCEPT_TERMS_ERROR,
  error
});

export interface UserAcceptsTermsAction {
  type: typeof USER_ACCEPTS_TERMS;
}
export const userAcceptsTerms = (): UserAcceptsTermsAction => ({ type: USER_ACCEPTS_TERMS });

export interface UserAcceptsTermsSuccessAction {
  type: typeof USER_ACCEPTS_TERMS_SUCCESS;
}
export const userAcceptsTermsSuccess = (): UserAcceptsTermsSuccessAction => ({
  type: USER_ACCEPTS_TERMS_SUCCESS
});

export interface UserAcceptsTermsFailedAction {
  type: typeof USER_ACCEPTS_TERMS_FAILED;
  error: Error;
}
export const userAcceptsTermsFailed = (error: Error): UserAcceptsTermsFailedAction => ({
  type: USER_ACCEPTS_TERMS_FAILED,
  error
});
