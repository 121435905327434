function getHypotenuse(adj, opp) {
  // a squared plus b squared = c squared
  const cSquared = adj * adj + opp * opp;
  const c = Math.sqrt(cSquared);
  return c;
}

export function deg2rad(angle) {
  return (parseFloat(angle) * Math.PI) / 180;
}

export function getDistanceBetweenPoints(pointA, pointB) {
  const adj = pointB.x - pointA.x;
  const opp = pointB.y - pointA.y;
  const hyp = getHypotenuse(adj, opp);
  return hyp;
}

function getAngleFromOppAndHyp(opp, hyp) {
  return Math.asin(opp / hyp);
}

function getAdjFromAngleAndHyp(angle, hyp) {
  return Math.cos(angle) * hyp;
}

function getOppFromAngleAndHyp(angle, hyp) {
  return Math.sin(angle) * hyp;
}

// Calculates coordinates along a path given two points and returns the points (exluding origin)
export function plotPointsAlongPath(point1, point2, resolution = 5) {
  const pointA = point1.x > point2.x ? point2 : point1;
  const pointB = point1.x > point2.x ? point1 : point2;

  const hyp = getDistanceBetweenPoints(pointA, pointB);
  const opp = pointB.y - pointA.y;
  const angle = getAngleFromOppAndHyp(opp, hyp);

  const stepAlongHyp = hyp / resolution;
  const points = [];

  for (let i = 1; i <= resolution; i++) {
    const newHyp = stepAlongHyp * i;
    const newAdj = getAdjFromAngleAndHyp(angle, newHyp);
    const newOpp = getOppFromAngleAndHyp(angle, newHyp);
    const newX = pointA.x + newAdj;
    const newY = pointA.y + newOpp;

    if (!isNaN(newX) && !isNaN(newY)) {
      points.push({ x: newX, y: newY });
    }
  }

  if (point1.x > point2.x) {
    return points.reverse();
  }

  return points;
}

export function getPointAlongHypAtPercentage(startingPoint, endPoint, percentage) {
  const hyp = getDistanceBetweenPoints(startingPoint, endPoint);
  const newHyp = hyp * percentage;
  const opp = startingPoint.y < endPoint.y ? endPoint.y - startingPoint.y : startingPoint.y - endPoint.y;
  const angle = getAngleFromOppAndHyp(opp, hyp);
  const newAdj = getAdjFromAngleAndHyp(angle, newHyp);
  const newOpp = getOppFromAngleAndHyp(angle, newHyp);
  const newX = startingPoint.x < endPoint.x ? startingPoint.x + newAdj : startingPoint.x - newAdj;
  const newY = startingPoint.y < endPoint.y ? startingPoint.y + newOpp : startingPoint.y - newOpp;
  return { x: newX, y: newY };
}
