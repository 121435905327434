import { CAMERA_EASING_EASEOUT, CAMERA_EASING_LINEAR, CAMERA_EASING_NONE } from 'js/config/consts';
import ScribeElementModel from 'js/models/ScribeElementModel';
import { ScribeCameraElement } from 'js/types';

import { SetElementPropertyActionPayloadBase } from './elementActions';

export const ADD_CAMERA = 'ADD_CAMERA';
export const SET_CAMERA_TO_MATCH_EDGE = 'ADD_SET_CAMERA_TO_MATCH_EDGE';
export const SET_CAMERA_TO_MATCH_VIEW = 'SET_CAMERA_TO_MATCH_VIEW';
export const SET_VIEW_TO_CAMERA = 'SET_VIEW_TO_CAMERA';
export const SET_CAMERA_EASE_TYPE = 'SET_CAMERA_EASE_TYPE';
export const SET_VIEW_TO_ELEMENT = 'SET_VIEW_TO_ELEMENT';

export interface AddCameraAction {
  type: typeof ADD_CAMERA;
  projectId: string;
}

export const addCamera = ({ projectId }: Omit<AddCameraAction, 'type'>): AddCameraAction => ({
  type: ADD_CAMERA,
  projectId
});

export interface SetViewToCameraAction {
  type: typeof SET_VIEW_TO_CAMERA;
  cameraElement: ScribeCameraElement;
  projectId: number;
}

export const setViewToCamera = ({
  cameraElement,
  projectId
}: {
  cameraElement: ScribeCameraElement;
  projectId: number;
}): SetViewToCameraAction => ({
  type: SET_VIEW_TO_CAMERA,
  cameraElement,
  projectId
});

export interface SetCameraEasingTypeAction {
  type: typeof SET_CAMERA_EASE_TYPE;
  scribeId: number;
  elementIds?: Array<string>;
  easeType: typeof CAMERA_EASING_NONE | typeof CAMERA_EASING_EASEOUT | typeof CAMERA_EASING_LINEAR;
}

export const setCameraEasingType = ({
  scribeId,
  elementIds,
  easeType
}: Omit<SetCameraEasingTypeAction, 'type'>): SetCameraEasingTypeAction => ({
  type: SET_CAMERA_EASE_TYPE,
  scribeId,
  elementIds,
  easeType
});
export interface SetViewToElementAction {
  type: typeof SET_VIEW_TO_ELEMENT;
  element: ScribeElementModel;
}
export const setViewToElement = ({ element }: { element: ScribeElementModel }): SetViewToElementAction => ({
  type: SET_VIEW_TO_ELEMENT,
  element
});

export interface SetCameraToMatchEdgeAction {
  type: typeof SET_CAMERA_TO_MATCH_EDGE;
  scribeId: number;
  elementIds: Array<string>;
  edge: 'x' | 'y';
  position?: { x: number; y: number };
  value: number;
}

export type SetCameraToMatchEdge = SetElementPropertyActionPayloadBase & {
  edge: 'x' | 'y';
  value: number;
  position?: { x: number; y: number };
};
export const setCameraToMatchEdge = ({
  scribeId,
  elementIds,
  edge,
  position,
  value
}: SetCameraToMatchEdge): SetCameraToMatchEdgeAction => ({
  type: SET_CAMERA_TO_MATCH_EDGE,
  scribeId,
  elementIds,
  edge,
  position,
  value
});

export interface SetCameraToMatchViewAction {
  type: typeof SET_CAMERA_TO_MATCH_VIEW;
  cameraId: string;
  projectId: number;
}

export const setCameraToMatchView = (cameraId: string, projectId: number) => ({
  type: SET_CAMERA_TO_MATCH_VIEW,
  cameraId,
  projectId
});
