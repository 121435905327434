import flatten from 'svg-flatten';
import {
  isVectorizedImage,
  VECTORIZED_IMAGE_IDENTIFIER
} from 'js/playback/lib/Playback/helpers/isVectorizedImage/isVectorizedImage';

import svgAttributeWhitelist, { cssSvgAttributeWhitelist } from './whitelist';

const parser = new DOMParser();

export const svgRootFromText = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  return doc.querySelector('svg');
};

export const convertPathStylesToAttributes = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');
  const paths = svg.querySelectorAll('path');
  paths.forEach(path => {
    const styles = convertStyleToObject(path.getAttribute('style'));
    return (
      Object.entries(styles)
        .reduce((el, [style, value]) => {
          el.setAttribute(style, value);
          return el;
        }, path)
        .removeAttribute('style')
    );
  });

  return svg.outerHTML;
};

export const convertSvgToPaths = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');
  const rootGroup = doc.querySelectorAll('svg > switch > g').length
    ? doc.querySelectorAll('svg > switch > g')
    : doc.querySelectorAll('svg > *');

  svg.innerHTML = '';
  [...rootGroup].forEach(node => svg.appendChild(node));

  const sanitisedSvg = svg.outerHTML;

  return (
    flatten(sanitisedSvg)
      .pathify()
      .transform()
      .value()
  );
};

export const convertStyleToObject = styleString => {
  if (!styleString) return {};
  return styleString.split(';').reduce((acc, style) => {
    const [key, value] = style.split(':');
    if (!key || !value) return acc;
    acc[key.trim()] = value.trim();
    return acc;
  }, {});
};

const NON_INHERITED_ATTRIBUTES = [
  'id',
  'style',
  'class',
  'd',
  'x',
  'y',
  'enable-background',
  'xml:space',
  'width',
  'height',
  'viewBox',
  'version',
  'xmlns'
];

function mergeParentAttributesToChildren(parent) {
  const parentAttributes = Array.from(parent.attributes);

  Array.from(parent.children).forEach(child => {
    parentAttributes
      .filter(({ name }) => !NON_INHERITED_ATTRIBUTES.includes(name))
      .forEach(({ name, value }) => {
        if (name === 'opacity') {
          const parentOpacity = Number(value);
          const childOpacity = Number(child.getAttribute('opacity') || 1);

          child.setAttribute(name, parentOpacity * childOpacity);
        }

        if (!child.getAttribute(name)) {
          child.setAttribute(name, value);
        }
      });
    mergeParentAttributesToChildren(child);
  });
}

const ATTRIBUTE_DEFAULTS = [
  {
    name: 'fill',
    value: '#000000'
  }
];

function applyRootDefaultAttributes(root) {
  ATTRIBUTE_DEFAULTS.forEach(({ name, value }) => {
    if (!root.getAttribute(name)) {
      root.setAttribute(name, value);
    }
  });

  return root;
}

export const mergeAttributesToChildren = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');

  mergeParentAttributesToChildren(applyRootDefaultAttributes(svg));

  return svg.outerHTML;
};

const filterInvisibleElements = element => {
  let child = element;
  let inDefs = false;
  let inTransparentParent = false;

  while (!!child.parentElement) {
    child = child.parentElement;
    if (child.tagName.toLowerCase() === 'defs') {
      inDefs = true;
      break;
    }

    if (child.getAttribute('opacity') === '0') {
      inTransparentParent = true;
      break;
    }
  }

  return !inDefs && !inTransparentParent;
};

export const getAndFormatOutlines = svgString => {
  const vectorizedImage = isVectorizedImage(svgString);
  const doc = parser.parseFromString(svgString, 'text/html');

  const selector = vectorizedImage
    ? `#${VECTORIZED_IMAGE_IDENTIFIER} path`
    : `:not(#vs-reveal) path[stroke]:not([stroke-width="0"]):not([opacity="0"]):not([stroke-opacity="0"]):not([stroke="none"])`;

  const paths = doc.querySelectorAll(selector);

  return (
    [...paths]
      .filter(filterInvisibleElements)
      .map(path => {
        if (!vectorizedImage) {
          const stroke = path.getAttribute('stroke');
          if (!stroke) {
            const fill = path.getAttribute('fill') || '#000000';
            path.setAttribute('stroke', fill);
          }

          path.setAttribute('fill', 'none');
        }

        return path.outerHTML;
      })
      .join('\n')
  );
};

export const getAndFormatColorReveals = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');

  const hasVsFormatSvgReveals = Boolean(svg.querySelector('#vs-reveal *'));

  const pathSelector = hasVsFormatSvgReveals
    ? '#vs-reveal path'
    : 'path[stroke][opacity="0"], path[stroke][stroke-opacity="0"]';

  const paths = [...svg.querySelectorAll(pathSelector)];

  return (
    paths
      .map(path => {
        path.setAttribute('fill', 'none');
        path.removeAttribute('stroke-opacity');
        path.setAttribute('opacity', '0');
        return path.outerHTML;
      })
      .join('\n')
  );
};

export const getAndFormatColors = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const paths = doc.querySelectorAll(
    'path:not([fill="none"]):not([opacity="0"]):not([fill="transparent"]):not([fill-opacity="0"]), image:not([opacity="0"])'
  );

  return (
    [...paths]
      .filter(filterInvisibleElements)
      .map(path => {
        return path.outerHTML;
      })
      .join('\n')
  );
};

export const wrapSvg = ({ width, height, body, viewBox }) => {
  return `<svg
    version="1.1"
    x="0px"
    y="0px"
    width="${width}px"
    height="${height}px"
    viewBox="${viewBox}"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    ${body}
  </svg>`;
};

const flattenCSS = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');

  document.body.appendChild(svg);

  const flatten = element => {
    const style = getComputedStyle(element);

    cssSvgAttributeWhitelist.forEach(property => {
      const attribute = element.getAttribute(property);
      if (!attribute) {
        const cssValue = style.getPropertyValue(property);
        if (cssValue) {
          element.setAttribute(property, cssValue);
        }
      }
    });
  };

  const recursiveFlatten = parent => {
    flatten(parent);

    if (parent.children?.length) {
      Array.from(parent.children).forEach(child => {
        recursiveFlatten(child);
      });
    }
  };

  recursiveFlatten(svg);

  document.body.removeChild(svg);

  return svg.outerHTML;
};

const getMetaElements = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');

  // https://developer.mozilla.org/en-US/docs/Web/SVG/Element#svg_elements_by_category
  const elements = svg.querySelectorAll(
    'style, defs, pattern, linearGradient, radialGradient, stop, filter, hatch, symbol, clipPath'
  );

  return [...elements].map(el => el.outerHTML).join('\n');
};

const getBounds = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');
  const viewBox = svg.getAttribute('viewBox');
  const width = svg.getAttribute('width') ?? (viewBox ? viewBox.split(' ')[2] : '0').replace('px', '');
  const height = svg.getAttribute('height') ?? (viewBox ? viewBox.split(' ')[3] : '0').replace('px', '');

  return [width, height, viewBox];
};

export const stripNonWhiteListedAttr = svgString => {
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');
  const elements = svg.querySelectorAll('*');

  [svg, ...elements].forEach(el => {
    const attributes = [...el.attributes].map(attr => attr.name);
    attributes.forEach(attr => {
      if (!svgAttributeWhitelist.includes(attr)) {
        el.removeAttribute(attr);
      }
    });
  });

  return svg.outerHTML;
};

export const convert = svgString => {
  const cssFlattened = flattenCSS(svgString);
  const strippedOfNonWhitelisted = stripNonWhiteListedAttr(cssFlattened);
  const mergedAttributes = mergeAttributesToChildren(strippedOfNonWhitelisted);
  const pathified = convertSvgToPaths(mergedAttributes);
  const transformedSvg = convertPathStylesToAttributes(pathified);

  const [width, height, viewBox] = getBounds(transformedSvg);

  const metaElements = getMetaElements(svgString);
  const colors = getAndFormatColors(transformedSvg);
  const outlines = getAndFormatOutlines(transformedSvg);
  const reveals = getAndFormatColorReveals(transformedSvg);

  const body = [
    ['vs-meta', metaElements],
    ['vs-colour', colors],
    ['vs-outline', outlines],
    ['vs-reveal', reveals]
  ]
    .filter(([_, group]) => !!group)
    .map(([id, group]) => {
      return `<g id="${id}">${group}</g>`;
    })
    .join('\n');

  return wrapSvg({
    width,
    height,
    body,
    viewBox
  });
};
