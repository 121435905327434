import React from 'react';

const IconSearchEmpty = () => (
  <svg width="49" height="68" viewBox="0 0 49 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18.0642" cy="40.9157" r="16.0478" stroke="currentColor" strokeWidth="4.03265" strokeLinecap="round" />
    <path d="M43.0145 65.869L29.665 52.5195" stroke="currentColor" strokeWidth="4.03265" strokeLinecap="round" />
    <path
      d="M37.6733 24.6284L40.4584 25.3747L39.7382 28.0624L36.9531 27.3162L37.6733 24.6284ZM38.3729 22.8746C38.439 22.6279 38.5215 22.3717 38.6206 22.1059C38.7361 21.8307 38.884 21.5642 39.0644 21.3064C39.2483 21.0356 39.4824 20.7852 39.7667 20.5552C40.064 20.3287 40.4357 20.1361 40.8818 19.9773C41.1278 19.8901 41.3756 19.7965 41.6251 19.6963C41.8746 19.5962 42.1063 19.4843 42.3203 19.3607C42.5344 19.2372 42.7177 19.0984 42.8702 18.9445C43.0228 18.7905 43.1287 18.6032 43.1878 18.3825C43.2922 17.9929 43.2281 17.6348 42.9956 17.3081C42.7665 16.9684 42.3858 16.7272 41.8535 16.5846C41.5029 16.4907 41.1644 16.4556 40.8379 16.4795C40.5115 16.5033 40.214 16.5489 39.9455 16.6161C39.6899 16.6868 39.4651 16.7727 39.2711 16.8737C39.0934 16.9653 38.9652 17.0284 38.8864 17.0629L37.7325 15.2925C37.8218 15.219 37.9917 15.1045 38.2421 14.9489C38.4925 14.7934 38.8144 14.6569 39.2076 14.5397C39.6043 14.4094 40.072 14.326 40.6106 14.2894C41.1623 14.2563 41.7692 14.3284 42.4313 14.5059C43.0676 14.6763 43.608 14.9186 44.0527 15.2325C44.4974 15.5465 44.8468 15.9046 45.1009 16.3066C45.368 16.7122 45.5367 17.1471 45.6072 17.6113C45.6811 18.0625 45.6589 18.5088 45.5406 18.9503C45.3458 19.6774 45.0447 20.2299 44.6373 20.6078C44.2298 20.9857 43.6848 21.3059 43.0022 21.5683C42.6609 21.6995 42.3655 21.8152 42.116 21.9153C41.8665 22.0154 41.6459 22.1373 41.4544 22.2808C41.2793 22.4148 41.1315 22.577 41.0109 22.7673C40.8903 22.9577 40.79 23.2022 40.71 23.5008L40.5952 23.9293L38.2581 23.303L38.3729 22.8746Z"
      fill="#038BB8"
    />
    <path
      opacity="0.5"
      d="M29.0064 16.2175H31.8897V19H29.0064V16.2175ZM29.2282 14.3423C29.2282 14.0869 29.2416 13.818 29.2685 13.5358C29.3088 13.24 29.3828 12.9443 29.4903 12.6486C29.5978 12.3394 29.7591 12.037 29.9742 11.7412C30.2027 11.4455 30.5119 11.1632 30.9017 10.8944C31.1168 10.7465 31.3319 10.5919 31.547 10.4306C31.762 10.2693 31.9569 10.1013 32.1317 9.92653C32.3064 9.75179 32.4476 9.57032 32.5551 9.38213C32.6627 9.19394 32.7164 8.98558 32.7164 8.75706C32.7164 8.3538 32.5618 8.02447 32.2527 7.76906C31.9435 7.50022 31.5134 7.3658 30.9622 7.3658C30.5993 7.3658 30.2632 7.41957 29.9541 7.52711C29.6449 7.63464 29.3693 7.75562 29.1274 7.89005C28.8988 8.02447 28.7039 8.16561 28.5426 8.31347C28.3948 8.44789 28.2872 8.54199 28.22 8.59576L26.6473 7.18433C26.7145 7.09023 26.8489 6.93565 27.0505 6.72058C27.2522 6.5055 27.5277 6.29043 27.8772 6.07535C28.2267 5.84683 28.6569 5.6452 29.1677 5.47045C29.6919 5.29571 30.2968 5.20833 30.9824 5.20833C31.6411 5.20833 32.2258 5.30243 32.7366 5.49062C33.2474 5.67881 33.6775 5.93421 34.027 6.25682C34.39 6.57943 34.6655 6.95581 34.8537 7.38596C35.0419 7.80267 35.136 8.23954 35.136 8.69657C35.136 9.44934 34.9882 10.061 34.6924 10.5314C34.3967 11.0019 33.9531 11.4522 33.3616 11.8824C33.0659 12.0974 32.8105 12.2856 32.5954 12.4469C32.3804 12.6082 32.1989 12.783 32.051 12.9712C31.9166 13.1459 31.8158 13.3408 31.7486 13.5559C31.6814 13.771 31.6478 14.0331 31.6478 14.3423V14.7859H29.2282V14.3423Z"
      fill="#038BB8"
    />
  </svg>
);

export default IconSearchEmpty;
