import React from 'react';
import { VSImageShapeOrTextElementModel } from 'js/types';
import { selectableDrawTypeOptions } from 'js/config/animationOptions';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';

import { getSelectedDrawTypeValue } from './DrawTypeMenu';
import { AnimationTypeSelectorButtonGroupText } from './AnimationTypeSelectorButtons';

interface DrawTypeChoiceButtonProps {
  element?: VSImageShapeOrTextElementModel;
  elements?: Array<VSImageShapeOrTextElementModel>;
}

const DrawTypeChoiceButton = ({ element, elements = [] }: DrawTypeChoiceButtonProps) => {
  const selectedValue = getSelectedDrawTypeValue(!!element ? [element] : elements);

  const title = selectableDrawTypeOptions.find(option => option.value === selectedValue)?.label ?? 'Mixed';
  const Icon = selectableDrawTypeOptions.find(option => option.value === selectedValue)?.Icon ?? IconIndeterminate;

  return (
    <>
      <Icon />
      <AnimationTypeSelectorButtonGroupText>{title}</AnimationTypeSelectorButtonGroupText>
    </>
  );
};

export default DrawTypeChoiceButton;
