import createSagaMiddleware from 'redux-saga';

import sagas from '../../sagas';
import { sendErrorToSentry } from '../../logging';

const sagaMiddleware = createSagaMiddleware({
  onError: err => {
    console.error(err);
    sendErrorToSentry(err);
  }
});

export const runSagaMiddleware = () => sagaMiddleware.run(sagas);

const sagaMiddlewares = () => sagaMiddleware;

export default sagaMiddlewares;
