import { FILE_CONTENT_TYPES } from 'js/config/consts';
import { fixSvgWidthAndHeight } from 'js/shared/helpers/fixSvgWidthAndHeight';
import { svgRootFromText } from 'js/shared/lib/SVGConvert';
import { encodeSvgFromString } from 'js/shared/helpers/svgToDataUrl';
import { applySvgViewboxAttributes, measureSvgContentBoundingBox } from 'js/shared/helpers/svgViewboxHelpers';
import { getRecolorDefaults, recolorSvg } from 'js/shared/providers';

import getImageBlobFromUrl from './getImageBlobFromUrl';
import { getNewRecolorProperties } from './getNewRecolorProperties';

export async function processImageFileOnLoad({ file, scribeElement, previousRecolor = undefined }) {
  if (file.type !== FILE_CONTENT_TYPES.SVG) {
    return {
      file
    };
  }
  const svgText = await file.text();
  const svgRoot = svgRootFromText(svgText);
  const isNounProject = svgRoot?.getAttribute('data-provider') === 'noun-project';

  fixSvgWidthAndHeight(svgRoot);

  const viewboxAttributes = await measureSvgContentBoundingBox(svgRoot);

  let viewBoxToApply = 'original';
  if (!scribeElement) {
    viewBoxToApply = 'contents';
  } else {
    viewBoxToApply = scribeElement.useContentSvgViewbox ? 'contents' : 'original';
  }
  const viewBoxAttrToApply = viewboxAttributes[viewBoxToApply];
  const viewboxApplied = applySvgViewboxAttributes(svgRoot, viewBoxAttrToApply);

  // Adjust image dimensions for images with previously incorrectly calculated bounding box
  if (scribeElement && scribeElement.width !== viewBoxAttrToApply.width) {
    const difference = viewBoxAttrToApply.width - scribeElement.width;
    scribeElement.width = viewBoxAttrToApply.width;
    scribeElement.x = scribeElement.x - difference / 2;
  }
  if (scribeElement && scribeElement.height !== viewBoxAttrToApply.height) {
    const difference = viewBoxAttrToApply.height - scribeElement.height;
    scribeElement.height = viewBoxAttrToApply.height;
    scribeElement.y = scribeElement.y - difference / 2;
  }

  const viewboxScribeElementProperties = {
    useContentSvgViewbox: scribeElement?.useContentSvgViewbox ?? true,
    viewboxAttributes
  };

  const _recolorDefaults = getRecolorDefaults(svgRoot);
  const recolorAvailable = Object.keys(_recolorDefaults).length > 0 || isNounProject;
  const recolor = recolorAvailable && !!scribeElement?.image?.recolor;
  const containsEmbeddedImage = !!svgRoot.querySelector('g image');

  let recolorToUse = recolor ? scribeElement.image.recolor : undefined;

  const maintainRecolorProperties = getNewRecolorProperties(_recolorDefaults, previousRecolor);

  if (maintainRecolorProperties) {
    recolorToUse = maintainRecolorProperties;
  }

  const recolorScribeElementProperties = {
    _recolorDefaults,
    recolorAvailable
  };

  const svgUrl = encodeSvgFromString(
    !!recolorToUse ? recolorSvg(viewboxApplied.outerHTML, recolorToUse) : viewboxApplied.outerHTML
  );
  const svgBlob = await getImageBlobFromUrl(svgUrl);

  return {
    file: svgBlob,
    viewboxScribeElementProperties,
    recolorScribeElementProperties,
    recolorProperties: recolorToUse,
    containsEmbeddedImage
  };
}
