import { CurrentUser } from 'js/types';

export const validateAuthCookies = (token: string, uid: string, user: CurrentUser, authUid?: string) => {
  if (!uid || !token || !authUid) {
    return false;
  }

  if ('id' in user && user.id) {
    return uid === authUid && uid === user.id;
  }

  return false;
};
