export const getCurrentSpinDirection = (element, elements, tweenKey) => {
  if (!element && !elements) {
    return undefined;
  }

  if (element) {
    return element[tweenKey].tweens_in[0].rotation;
  }

  const allSpinDirectionsMatch = elements.every(
    el => el[tweenKey].tweens_in[0].rotation === elements[0][tweenKey].tweens_in[0].rotation
  );

  if (allSpinDirectionsMatch) {
    return elements[0][tweenKey].tweens_in[0].rotation;
  }

  return undefined;
};
