import IconAnimationBounceIn from 'imgs/pixi_icons/IconAnimationBounceIn.svg';
import IconAnimationDisappear from 'imgs/pixi_icons/IconAnimationDisappear.svg';
import IconAnimationDragIn from 'imgs/pixi_icons/IconAnimationDragIn.svg';
import IconAnimationErase from 'imgs/pixi_icons/IconAnimationErase.svg';
import IconAnimationFade from 'imgs/pixi_icons/IconAnimationFade.svg';
import IconAnimationMoveIn from 'imgs/pixi_icons/IconAnimationMoveIn.svg';
import IconAnimationMoveOut from 'imgs/pixi_icons/IconAnimationMoveOut.svg';
import IconAnimationPulse from 'imgs/pixi_icons/IconAnimationPulse.svg';
import IconAnimationShake from 'imgs/pixi_icons/IconAnimationShake.svg';
import IconAnimationSlingshot from 'imgs/pixi_icons/IconAnimationSlingshot.svg';
import IconAnimationSpinLoop from 'imgs/pixi_icons/IconAnimationSpinLoop.svg';
import IconCamera from 'imgs/pixi_icons/IconCamera.svg';
import IconCameraAnimationEaseOut from 'imgs/pixi_icons/IconCameraAnimationEaseOut.svg';
import IconCameraAnimationLinear from 'imgs/pixi_icons/IconCameraAnimationLinear.svg';
import IconDraw from 'imgs/pixi_icons/IconDraw.svg';
import IconHandDraw from 'imgs/pixi_icons/IconHandDraw.svg';
import IconNoAnimation from 'imgs/pixi_icons/IconNoAnimation.svg';
import IconPauseTime from 'imgs/pixi_icons/IconPauseTime.svg';
import IconPenDraw from 'imgs/pixi_icons/IconPenDraw.svg';
import {
  BOUNCE_IN_KEY,
  BOUNCE_LOOP_KEY,
  CAMERA_EASING_EASEOUT,
  CAMERA_EASING_LINEAR,
  DISAPPEAR_KEY,
  DRAG_IN_KEY,
  DRAW_KEY,
  EXIT_ANIMATION_ERASE_KEY,
  FADE_IN_KEY,
  FADE_OUT_KEY,
  HAND_DRAW_KEY,
  MOVE_IN_KEY,
  MOVE_OUT_KEY,
  NO_ANIMATION_KEY,
  PEN_DRAW_KEY,
  PULSE_LOOP_KEY,
  SHAKE_LOOP_KEY,
  SLINGSHOT_KEY,
  SPIN_LOOP_KEY
} from 'js/config/consts';
import { AllAnimationTypeKeys, CameraAnimationKeys } from 'js/types';
import * as PIXI from 'pixi.js';

import TimelineHelpers from './TimelineHelpers';

export const isAllAnimationTypeKey = (key: string): key is AllAnimationTypeKeys => {
  return !!iconDictionary.find(iconKey => iconKey.key === key);
};

const iconDictionary: { key: AllAnimationTypeKeys; icon: string }[] = [
  { key: DISAPPEAR_KEY, icon: IconAnimationDisappear },
  { key: FADE_IN_KEY, icon: IconAnimationFade },
  { key: FADE_OUT_KEY, icon: IconAnimationFade },
  { key: MOVE_IN_KEY, icon: IconAnimationMoveIn },
  { key: PULSE_LOOP_KEY, icon: IconAnimationPulse },
  { key: SHAKE_LOOP_KEY, icon: IconAnimationShake },
  { key: SLINGSHOT_KEY, icon: IconAnimationSlingshot },
  { key: SPIN_LOOP_KEY, icon: IconAnimationSpinLoop },
  { key: HAND_DRAW_KEY, icon: IconHandDraw },
  { key: DRAW_KEY, icon: IconDraw },
  { key: PEN_DRAW_KEY, icon: IconPenDraw },
  { key: BOUNCE_IN_KEY, icon: IconAnimationBounceIn },
  { key: MOVE_OUT_KEY, icon: IconAnimationMoveOut },
  { key: DRAG_IN_KEY, icon: IconAnimationDragIn },
  { key: BOUNCE_LOOP_KEY, icon: IconAnimationBounceIn },
  { key: NO_ANIMATION_KEY, icon: IconNoAnimation },
  { key: EXIT_ANIMATION_ERASE_KEY, icon: IconAnimationErase }
];

export const isCameraIconTypeKey = (key: string): key is CameraAnimationKeys => {
  return !!cameraIconDictionary.find(iconKey => iconKey.key === key);
};

const cameraIconDictionary: Array<{ key: CameraAnimationKeys; icon: string }> = [
  { key: CAMERA_EASING_EASEOUT, icon: IconCameraAnimationEaseOut },
  { key: CAMERA_EASING_LINEAR, icon: IconCameraAnimationLinear }
];

class GetAnimationIcon {
  public fallbackIcon: PIXI.Texture;
  public cameraFallbackIcon: PIXI.Texture;
  public iconMap: Map<AllAnimationTypeKeys, PIXI.Texture<PIXI.Resource>>;
  public iconCameraMap: Map<CameraAnimationKeys, PIXI.Texture<PIXI.Resource>>;
  public pauseIcon: PIXI.Texture;
  public linearEaseIcon: PIXI.Texture;
  public easeOutIcon: PIXI.Texture;

  constructor() {
    this.iconMap = new Map<AllAnimationTypeKeys, PIXI.Texture<PIXI.Resource>>();
    this.iconCameraMap = new Map<CameraAnimationKeys, PIXI.Texture<PIXI.Resource>>();
    this.fallbackIcon = TimelineHelpers.importSVG(NO_ANIMATION_KEY);
    this.pauseIcon = TimelineHelpers.importSVG(IconPauseTime);
    this.cameraFallbackIcon = TimelineHelpers.importSVG(IconCamera);
    this.linearEaseIcon = TimelineHelpers.importSVG(IconCameraAnimationLinear);
    this.easeOutIcon = TimelineHelpers.importSVG(IconCameraAnimationEaseOut);
    this.initialise();
  }

  private initialise() {
    iconDictionary.forEach(({ key, icon }) => {
      const pixiTexture = TimelineHelpers.importSVG(icon);
      this.iconMap.set(key, pixiTexture);
    });
    cameraIconDictionary.forEach(({ key, icon }) => {
      const pixiTexture = TimelineHelpers.importSVG(icon);
      this.iconCameraMap.set(key, pixiTexture);
    });
  }

  public getIcon(animationType: AllAnimationTypeKeys | 'pause'): PIXI.Texture {
    if (animationType === 'pause') return TimelineHelpers.importSVG(IconPauseTime);
    const record = iconDictionary.find((val: { key: string; icon: string }) => val.key === animationType);
    if (record === undefined) return this.fallbackIcon;
    const pixiIcon = TimelineHelpers.importSVG(record.icon);
    return pixiIcon;
  }

  public getCameraIcon(animationType: CameraAnimationKeys | 'pause'): PIXI.Texture {
    if (animationType === 'pause') return this.pauseIcon;
    const pixiIcon = this.iconCameraMap.get(animationType);
    return pixiIcon ?? this.cameraFallbackIcon;
  }
}

const instance = new GetAnimationIcon();
export default instance;
