import { ExistingScribeModel } from 'js/types';
import uniq from 'lodash.uniq';

export const getSortedAndUniqueAudioAssetIds = (project: ExistingScribeModel) => {
  return uniq(project.audioClips?.map(clip => clip.assetId) || []).sort();
};

export const canClipBeSplit = (duration = 0) => {
  return duration > 0.2;
};
