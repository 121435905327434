import React from 'react';
import ReactTooltip from 'react-tooltip';

import './ChoiceButtonContents.css';

const ChoiceButtonContents = ({ children, label, id }) => {
  const tooltopId = `${id}-tooltip`;

  return (
    <div className="ChoiceButtonContents" data-tip={label} data-for={tooltopId} id={id}>
      {children}
      <ReactTooltip id={tooltopId} className="t-utility-label" />
    </div>
  );
};

export default ChoiceButtonContents;
