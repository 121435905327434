import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

export const calculateNewPosition = (element: ScribeImageElementModel) => {
  if (!element.viewboxAttributes) {
    return undefined;
  }

  const currentAttrs = element.viewboxAttributes[element.useContentSvgViewbox ? 'original' : 'contents'];
  const newAttrs = element.viewboxAttributes[element.useContentSvgViewbox ? 'contents' : 'original'];

  if (!currentAttrs || !newAttrs) {
    return undefined;
  }

  const [currVBx, currVBy] = currentAttrs.viewBox.split(' ');
  const [newVBx, newVBy] = newAttrs.viewBox.split(' ');

  let x: number;
  if (currentAttrs.width > newAttrs.width) {
    const xDiff = parseInt(newVBx) - parseInt(currVBx);

    x = element.x + xDiff * element.scaleX;
  } else {
    const xDiff = parseInt(currVBx) - parseInt(newVBx);

    x = element.x - xDiff * element.scaleX;
  }

  let y: number;
  if (currentAttrs.height > newAttrs.height) {
    const yDiff = parseInt(newVBy) - parseInt(currVBy);

    y = element.y + yDiff * element.scaleY;
  } else {
    const yDiff = parseInt(currVBy) - parseInt(newVBy);

    y = element.y - yDiff * element.scaleY;
  }

  const result = { x, y, width: newAttrs.width, height: newAttrs.height };

  return result;
};
