import { AdvancedAudioType, VSCAudioLibraryApiData } from 'js/types';

export const LOAD_AUDIO_LIBRARY = 'LOAD_AUDIO_LIBRARY';
export const LOAD_AUDIO_LIBRARY_FAILED = 'LOAD_AUDIO_LIBRARY_FAILED';
export const LOAD_AUDIO_LIBRARY_SUCCESS = 'LOAD_AUDIO_LIBRARY_SUCCESS';
export const CREATE_PROJECT_ASSET_FROM_LIBRARY = 'CREATE_PROJECT_ASSET_FROM_LIBRARY';
export const CREATE_PROJECT_ASSET_FROM_LIBRARY_FAILED = 'CREATE_PROJECT_ASSET_FROM_LIBRARY_FAILED';
export const CREATE_PROJECT_ASSET_FROM_LIBRARY_SUCCESS = 'CREATE_PROJECT_ASSET_FROM_LIBRARY_SUCCESS';

export interface LoadAudioLibraryAction {
  type: typeof LOAD_AUDIO_LIBRARY;
}
export const loadAudioLibrary = (): LoadAudioLibraryAction => ({
  type: LOAD_AUDIO_LIBRARY
});

export interface LoadAudioLibraryFailedAction {
  type: typeof LOAD_AUDIO_LIBRARY_FAILED;
  error: Error;
}
export const loadAudioLibraryFailed = (error: Error): LoadAudioLibraryFailedAction => ({
  type: LOAD_AUDIO_LIBRARY_FAILED,
  error
});

export interface LoadAudioLibrarySuccessAction {
  type: typeof LOAD_AUDIO_LIBRARY_SUCCESS;
  data: Array<VSCAudioLibraryApiData>;
}
export const loadAudioLibrarySuccess = (data: Array<VSCAudioLibraryApiData>): LoadAudioLibrarySuccessAction => ({
  type: LOAD_AUDIO_LIBRARY_SUCCESS,
  data
});

// This can be extended in the future if we want to implement other libraries e.g. this would become 'audio' | 'image'
export type AssetLibrarySource = 'audio';
export type AssetLibrarySourceIdType<T extends AssetLibrarySource> = T extends 'audio' ? number : string;

export interface CreateProjectAssetFromLibraryAction<T extends AssetLibrarySource> {
  type: typeof CREATE_PROJECT_ASSET_FROM_LIBRARY;
  library: T;
  libraryItemId: AssetLibrarySourceIdType<T>;
  projectId: number;
  target: AdvancedAudioType;
}
export const createProjectAssetFromLibrary = <T extends AssetLibrarySource>(
  library: T,
  libraryItemId: AssetLibrarySourceIdType<T>,
  projectId: number,
  target: AdvancedAudioType
): CreateProjectAssetFromLibraryAction<T> => ({
  type: CREATE_PROJECT_ASSET_FROM_LIBRARY,
  library,
  libraryItemId,
  projectId,
  target
});

export interface CreateProjectAssetFromLibraryFailedAction {
  type: typeof CREATE_PROJECT_ASSET_FROM_LIBRARY_FAILED;
  error: Error;
}
export const createProjectAssetFromLibraryFailed = (error: Error): CreateProjectAssetFromLibraryFailedAction => ({
  type: CREATE_PROJECT_ASSET_FROM_LIBRARY_FAILED,
  error
});

export interface CreateProjectAssetFromLibrarySuccessAction {
  type: typeof CREATE_PROJECT_ASSET_FROM_LIBRARY_SUCCESS;
}
export const createProjectAssetFromLibrarySuccess = (): CreateProjectAssetFromLibrarySuccessAction => ({
  type: CREATE_PROJECT_ASSET_FROM_LIBRARY_SUCCESS
});
