import { UpgradeButtonClickExtraEventProps } from 'js/types';

import { UpgradeSubscriptionClickedEventTrigger } from './trackingActions';

export const UPGRADE_ACCOUNT = 'UPGRADE_ACCOUNT';

export interface UpgradeAccountAction {
  type: typeof UPGRADE_ACCOUNT;
  trigger: UpgradeSubscriptionClickedEventTrigger;
  extraEventProps?: UpgradeButtonClickExtraEventProps;
}

export const upgradeAccount = (
  trigger: UpgradeSubscriptionClickedEventTrigger,
  extraEventProps?: UpgradeButtonClickExtraEventProps
): UpgradeAccountAction => ({
  type: UPGRADE_ACCOUNT,
  trigger,
  extraEventProps
});
