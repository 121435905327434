import { GRID_SIZE } from 'js/config/config';
import { VSDimensions } from 'js/types';

const LINE_COLOR = '#00FFFFB3';
const CENTER_LINE_COLOR = '#0500FFB3';

const generateGridlines = (
  ctx: CanvasRenderingContext2D,
  canvasSize: VSDimensions,
  viewportTransform?: Array<number>
) => {
  ctx.save();

  const horizontalScale = viewportTransform?.[0] ?? 1;
  const verticalScale = viewportTransform?.[3] ?? 1;
  const horizontalOffset = viewportTransform?.[4] ?? 0;
  const verticalOffset = viewportTransform?.[5] ?? 0;

  const scaledVerticalGridGapSize = GRID_SIZE * verticalScale;
  const verticalSteps = Math.ceil(ctx.canvas.height / scaledVerticalGridGapSize);
  const gridVerticalOffset = verticalOffset % scaledVerticalGridGapSize;

  const verticalCenter = Math.floor((canvasSize.height / 2) * verticalScale + verticalOffset);
  const horizontalCenter = Math.floor((canvasSize.width / 2) * horizontalScale + horizontalOffset);

  for (let i = 0; i < verticalSteps; i++) {
    const y = i * scaledVerticalGridGapSize + gridVerticalOffset;
    const isCenter = Math.floor(y) === verticalCenter;
    ctx.fillStyle = isCenter ? CENTER_LINE_COLOR : LINE_COLOR;
    ctx.fillRect(0, y, ctx.canvas.width, 1);
  }

  const scaledHorizontalGridGapSize = GRID_SIZE * horizontalScale;
  const horizontalSteps = Math.ceil(ctx.canvas.width / scaledHorizontalGridGapSize);
  const gridHorizontalOffset = horizontalOffset % scaledHorizontalGridGapSize;

  for (let i = 0; i < horizontalSteps; i++) {
    const x = i * scaledHorizontalGridGapSize + gridHorizontalOffset;
    const isCenter = Math.floor(x) === horizontalCenter;
    ctx.fillStyle = isCenter ? CENTER_LINE_COLOR : LINE_COLOR;
    ctx.fillRect(x, 0, 1, ctx.canvas.height);
  }

  ctx.restore();
};

export default generateGridlines;
