import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationUpRight({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path d="m 38.830255,18.384818 -19.445436,-0.07071 0,3.111271 15.697771,-1e-6 -15.273507,15.273507 2.262742,2.262741 L 37.27462,23.758831 37.20391,39.38589 h 3.11127 l 1e-6,-19.516146 c 0,-0.424264 -0.141421,-0.848529 -0.424264,-1.131372 C 39.608074,18.45553 39.25452,18.38482 38.830256,18.38482 Z" />
    </svg>
  );
}
