import {
  EMPHASIS_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  EXIT_ANIMATION_DOCUMENT_KEY
} from 'js/config/consts';
import { ElementAnimationConfigurationKey, ElementAnimationStage } from 'js/types';

export default function getStageAnimationConfigurationKey(
  stage: ElementAnimationStage
): ElementAnimationConfigurationKey {
  if (stage === 'emphasis') return EMPHASIS_ANIMATION_DOCUMENT_KEY;
  if (stage === 'entrance') return ENTRANCE_ANIMATION_DOCUMENT_KEY;

  return EXIT_ANIMATION_DOCUMENT_KEY;
}
