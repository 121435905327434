import SceneDirectionChoiceButton from 'js/editor/SceneDirectionChoiceButton';
import SceneDirectionMenu from 'js/editor/SceneDirectionMenu';
import {
  IconAnimationDown,
  IconAnimationDownLeft,
  IconAnimationDownRight,
  IconAnimationLeft,
  IconAnimationRight,
  IconAnimationUp,
  IconAnimationUpLeft,
  IconAnimationUpRight
} from 'js/shared/icons/IconAnimationDirections';
import IconNoAnimation from 'js/shared/icons/IconNoAnimation';
import IconSceneSlideIn from 'js/shared/icons/SceneIcons/IconSceneSlideIn';
import IconSceneBounce from 'js/shared/icons/SceneIcons/IconSceneBounce';
import IconSceneFade from 'js/shared/icons/SceneIcons/IconSceneFade';
import SceneHandMenu from 'js/editor/SceneHandMenu';
import IconSceneErase from 'js/shared/icons/SceneIcons/IconSceneErase';
import IconSceneDrag from 'js/shared/icons/SceneIcons/IconSceneDrag';
import SceneCursorChoiceButton from 'js/editor/SceneCursorChoiceButton';
import { SceneChoiceButtonProps, SceneTransitionConfig, TransitionSubMenu, VSComponentProps } from 'js/types';

import {
  SCENE_TRANSITION_KEY_BOUNCE,
  SCENE_TRANSITION_KEY_FADE,
  SCENE_TRANSITION_KEY_NONE,
  SCENE_TRANSITION_KEY_SLIDE,
  SCENE_TRANSITION_KEY_ERASE,
  DEFAULT_ERASE_CURSOR_ID,
  SCENE_TRANSITION_KEY_DRAG_ON,
  DEFAULT_DRAG_CURSOR_ID
} from './consts';

export const DEFAULT_SCENE_TRANSITION_DURATION = 1;

export const SCENE_DIRECTION_KEYS = {
  UP: 'scene-direction-up',
  DOWN: 'scene-direction-down',
  LEFT: 'scene-direction-left',
  RIGHT: 'scene-direction-right',
  SCENE: 'scene-direction-scene',
  EMPTY: 'scene-direction-empty',
  UP_RIGHT: 'scene-direction-up-right',
  UP_LEFT: 'scene-direction-up-left',
  DOWN_RIGHT: 'scene-direction-down-right',
  DOWN_LEFT: 'scene-direction-down-left'
};

export const SCENE_DIRECTION_CHOICES = [
  { key: SCENE_DIRECTION_KEYS.EMPTY },
  { key: SCENE_DIRECTION_KEYS.DOWN, Icon: IconAnimationDown, title: 'Top' },
  { key: SCENE_DIRECTION_KEYS.EMPTY },
  { key: SCENE_DIRECTION_KEYS.RIGHT, Icon: IconAnimationRight, title: 'Left' },
  { key: SCENE_DIRECTION_KEYS.EMPTY },
  { key: SCENE_DIRECTION_KEYS.LEFT, Icon: IconAnimationLeft, title: 'Right' },
  { key: SCENE_DIRECTION_KEYS.EMPTY },
  { key: SCENE_DIRECTION_KEYS.UP, Icon: IconAnimationUp, title: 'Bottom' },
  { key: SCENE_DIRECTION_KEYS.EMPTY }
];

export const ALL_SCENE_DIRECTION_CHOICES = [
  { key: SCENE_DIRECTION_KEYS.DOWN_RIGHT, Icon: IconAnimationDownLeft, title: 'Top Left' },
  { key: SCENE_DIRECTION_KEYS.DOWN, Icon: IconAnimationDown, title: 'Top' },
  { key: SCENE_DIRECTION_KEYS.DOWN_LEFT, Icon: IconAnimationDownRight, title: 'Top Right' },
  { key: SCENE_DIRECTION_KEYS.RIGHT, Icon: IconAnimationRight, title: 'Left' },
  { key: SCENE_DIRECTION_KEYS.EMPTY },
  { key: SCENE_DIRECTION_KEYS.LEFT, Icon: IconAnimationLeft, title: 'Right' },
  { key: SCENE_DIRECTION_KEYS.UP_RIGHT, Icon: IconAnimationUpRight, title: 'Bottom Left' },
  { key: SCENE_DIRECTION_KEYS.UP, Icon: IconAnimationUp, title: 'Bottom' },
  { key: SCENE_DIRECTION_KEYS.UP_LEFT, Icon: IconAnimationUpLeft, title: 'Bottom Right' }
];

interface SceneTransitionOption {
  type: string;
  title: string;
  Icon: () => JSX.Element;
  wideIcon?: boolean;
  defaultConfig?: SceneTransitionConfig;
  secondaryMenu?: TransitionSubMenu<SceneChoiceButtonProps, VSComponentProps>;
  tertiaryMenu?: TransitionSubMenu<SceneChoiceButtonProps, VSComponentProps>;
}

const SCENE_TRANSITION_OPTIONS: Array<SceneTransitionOption> = [
  {
    type: SCENE_TRANSITION_KEY_NONE,
    title: 'None',
    Icon: IconNoAnimation
  },
  {
    type: SCENE_TRANSITION_KEY_FADE,
    title: 'Fade',
    Icon: IconSceneFade,
    wideIcon: true
  },
  {
    type: SCENE_TRANSITION_KEY_SLIDE,
    title: 'Slide In',
    Icon: IconSceneSlideIn,
    wideIcon: true,
    defaultConfig: {
      direction: SCENE_DIRECTION_KEYS.LEFT
    },
    secondaryMenu: {
      title: 'Direction',
      Component: SceneDirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: SceneDirectionChoiceButton
    }
  },
  {
    type: SCENE_TRANSITION_KEY_BOUNCE,
    title: 'Bounce',
    Icon: IconSceneBounce,
    wideIcon: true,
    defaultConfig: {
      direction: SCENE_DIRECTION_KEYS.DOWN
    },
    secondaryMenu: {
      title: 'Direction',
      Component: SceneDirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: SceneDirectionChoiceButton
    }
  },
  {
    type: SCENE_TRANSITION_KEY_ERASE,
    title: 'Erase',
    Icon: IconSceneErase,
    wideIcon: true,
    defaultConfig: {
      cursorId: DEFAULT_ERASE_CURSOR_ID
    },
    secondaryMenu: {
      title: 'Hand Type',
      Component: SceneHandMenu,
      choiceButtonLabel: 'Hand',
      ChoiceButton: SceneCursorChoiceButton
    }
  },
  {
    type: SCENE_TRANSITION_KEY_DRAG_ON,
    title: 'Drag on',
    Icon: IconSceneDrag,
    defaultConfig: {
      direction: SCENE_DIRECTION_KEYS.UP,
      cursorId: DEFAULT_DRAG_CURSOR_ID
    },
    secondaryMenu: {
      title: 'Direction',
      Component: SceneDirectionMenu,
      choiceButtonLabel: 'Direction',
      ChoiceButton: SceneDirectionChoiceButton,
      useAllDirections: true
    },
    tertiaryMenu: {
      title: 'Hand Type',
      Component: SceneHandMenu,
      choiceButtonLabel: 'Hand',
      ChoiceButton: SceneCursorChoiceButton
    }
  }
];

export default SCENE_TRANSITION_OPTIONS;
