import { RecolorDefaults } from 'js/types';
import isEmpty from 'lodash/isEmpty';

export const getNewRecolorProperties = (
  newRecolorDefaults?: RecolorDefaults,
  originalRecolor?: RecolorDefaults
): RecolorDefaults | undefined => {
  if (!newRecolorDefaults || !originalRecolor) {
    return undefined;
  }

  const recolor = Object.entries(originalRecolor).reduce<RecolorDefaults>((matchingProperties, [key, value]) => {
    const isMatcingProperty = newRecolorDefaults[key as keyof RecolorDefaults] !== undefined;

    return isMatcingProperty
      ? {
          ...matchingProperties,
          [key]: value
        }
      : matchingProperties;
  }, {});

  if (isEmpty(recolor)) {
    return undefined;
  }

  return recolor;
};
