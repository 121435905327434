import ScribeModel from 'js/models/ScribeModel';
import { pauseTime } from 'js/shared/resources/scribedefaults';
import cloneDeep from 'lodash.clonedeep';

export default function sanitiseScribe(activeScribe: ScribeModel) {
  const allElementIds = activeScribe.elements.map(element => element.id);

  const newActiveScribe = cloneDeep(activeScribe);
  newActiveScribe.scenes.forEach(scene => {
    scene.elementIds = Array.from(new Set(scene.elementIds.filter(elementId => allElementIds.includes(elementId))));
  });

  const allSceneIds = newActiveScribe.scenes.flatMap(scene => scene.elementIds);

  newActiveScribe.elements = newActiveScribe.elements.filter(element => allSceneIds.includes(element.id));

  newActiveScribe.elements.forEach(element => {
    if (typeof element.pauseTime !== 'number') {
      element.pauseTime = pauseTime;
    }
  });

  newActiveScribe.audioClips?.forEach(audioClip => {
    if (audioClip.volume === null) {
      audioClip.volume = 0;
    }
  });

  const hasActiveScene = newActiveScribe.scenes.some(scene => scene.active);
  if (!hasActiveScene) {
    newActiveScribe.scenes[newActiveScribe.scenes.length - 1].active = true;
  }

  return newActiveScribe;
}
