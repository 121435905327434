import {
  DISMISS_USERPERSONA_MODAL,
  CLOSE_USERPERSONA_MODAL,
  SHOW_USERPERSONA_MODAL,
  GetUserPersonaDetailsSuccessAction,
  GET_USERPERSONA_DETAILS_SUCCESS
} from 'js/actionCreators/marketingActions';
import { MarketingState } from 'js/types';

const initialState: MarketingState = {
  showUserpersonaModal: false
};

type MarketingAction =
  | {
      type: typeof DISMISS_USERPERSONA_MODAL | typeof CLOSE_USERPERSONA_MODAL | typeof SHOW_USERPERSONA_MODAL;
    }
  | GetUserPersonaDetailsSuccessAction;

const marketingReducer = (state = initialState, action: MarketingAction): MarketingState => {
  switch (action.type) {
    case DISMISS_USERPERSONA_MODAL:
    case CLOSE_USERPERSONA_MODAL:
      return {
        ...state,
        showUserpersonaModal: false
      };
    case SHOW_USERPERSONA_MODAL:
      return {
        ...state,
        showUserpersonaModal: true
      };
    case GET_USERPERSONA_DETAILS_SUCCESS: {
      const { dismissDate, dontShowAgain } = action;

      return {
        ...state,
        personaDismissDate: dismissDate ? new Date(dismissDate) : undefined,
        personaDontShowAgain: dontShowAgain
      };
    }
    default:
      return state;
  }
};

export default marketingReducer;
