import { ElementAnimationStage, ScribeCursorId } from 'js/types';

export const SET_ELEMENT_CURSOR = 'SET_ELEMENT_CURSOR';

export const setElementCursor = (
  scribeId: number,
  elementIds: Array<string>,
  cursorId: ScribeCursorId,
  stage: ElementAnimationStage
) => ({
  type: SET_ELEMENT_CURSOR,
  scribeId,
  elementIds,
  cursorId,
  stage
});
