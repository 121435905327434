import { UserSettingsOptions } from 'js/types';

export const SET_USER_SETTING = 'SET_USER_SETTING';
export const LOAD_USER_SETTINGS_SUCCESS = 'LOAD_USER_SETTINGS_SUCCESS';
export const LOAD_USER_SETTINGS_FAILED = 'LOAD_USER_SETTINGS_FAILED';
export interface SetUserSettingAction {
  type: typeof SET_USER_SETTING;
  setting: keyof UserSettingsOptions;
  value: boolean | string[];
  scribeId: number;
}

interface SetUserSettings {
  setting: keyof UserSettingsOptions;
  value: boolean | string[];
  scribeId: number;
}

export const setUserSetting = ({ setting, value, scribeId }: SetUserSettings): SetUserSettingAction => ({
  type: SET_USER_SETTING,
  setting,
  value,
  scribeId
});

export interface LoadUserSettingsSuccessAction {
  type: typeof LOAD_USER_SETTINGS_SUCCESS;
  settings: UserSettingsOptions;
}

export const loadUserSettingsSuccess = (settings: UserSettingsOptions): LoadUserSettingsSuccessAction => ({
  type: LOAD_USER_SETTINGS_SUCCESS,
  settings
});

export interface LoadUserSettingsFailedAction {
  type: typeof LOAD_USER_SETTINGS_FAILED;
  error: Error;
}

export const loadUserSettingsFailed = (error: Error): LoadUserSettingsFailedAction => ({
  type: LOAD_USER_SETTINGS_FAILED,
  error
});
