import React from 'react';

import { DirectionIconProps } from './types';

export function IconAnimationDownRight({ flipImage }: DirectionIconProps) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
      stroke="currentcolor"
      fill="currentcolor"
      width="60px"
      height="60px"
    >
      <path d="m 21.435429,39.549495 19.445437,0.07071 v -3.11127 l -15.697771,0 15.273507,-15.273507 -2.262742,-2.262741 -15.202796,15.202795 0.07071,-15.627059 h -3.11127 l 0,19.516147 c 0,0.424264 0.141421,0.848528 0.424264,1.131371 0.282843,0.282842 0.636396,0.353553 1.06066,0.353553 z" />
    </svg>
  );
}
