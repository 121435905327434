import { ImageGenerationApiFailReason } from 'js/types';

const IMAGE_GEN_ERROR_MESSAGE: Record<ImageGenerationApiFailReason, string> = {
  [ImageGenerationApiFailReason.PROMPT_REJECTED]:
    'There was an error with your prompt so we could not generate your image. Please write a new prompt to try again.',
  [ImageGenerationApiFailReason.SERVER_ERROR]:
    'There was an error with the server so we could not generate your image. Please try again.',
  [ImageGenerationApiFailReason.TIMEOUT]: 'The image generation request timed out. Please try again.'
};

export function mapImageGenerationFailedReasonToMessage(reason: ImageGenerationApiFailReason): string {
  return IMAGE_GEN_ERROR_MESSAGE[reason] || 'An unknown error occurred. Please try again.';
}
