import { DownloadTrackingProperties } from 'js/types';

export const GET_VIDEO_DOWNLOAD_URL_FAILED = 'GET_VIDEO_DOWNLOAD_URL_FAILED';

export type GetVideoDownloadUrlFailedAction = {
  type: typeof GET_VIDEO_DOWNLOAD_URL_FAILED;
  error: Error;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export const getVideoDownloadUrlFailed = (
  error: Error,
  trackingProperties: DownloadTrackingProperties,
  scribeId: number
): GetVideoDownloadUrlFailedAction => ({
  type: GET_VIDEO_DOWNLOAD_URL_FAILED,
  error,
  trackingProperties,
  scribeId
});
