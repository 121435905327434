import React from 'react';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { getActiveElements } from 'js/sagas/selectors';
import { useDispatch } from 'react-redux';
import { alignSelectedElements } from 'js/actionCreators/elementAlignmentActions';
import { ReactComponent as IconElementAlignLeft } from 'imgs/svg-icons/IconElementAlignLeft.svg';
import { ReactComponent as IconElementAlignBottom } from 'imgs/svg-icons/IconElementAlignBottom.svg';
import { ReactComponent as IconElementAlignHCenter } from 'imgs/svg-icons/IconElementAlignHCenter.svg';
import { ReactComponent as IconElementAlignRight } from 'imgs/svg-icons/IconElementAlignRight.svg';
import { ReactComponent as IconElementAlignTop } from 'imgs/svg-icons/IconElementAlignTop.svg';
import { ReactComponent as IconElementAlignVCenter } from 'imgs/svg-icons/IconElementAlignVCenter.svg';

import EditPanelFieldset from '../EditPanelFieldset';
import EditPanelLegend from '../EditPanelLegend';
import EditPanelTripleButtonGroup from '../EditPanelTripleButtonGroup';
import ActionButtonWithA11yLabel from '../ActionButton/ActionButtonWithA11yLabel';

import {
  ALIGN_ACTION_LEFT_LABEL_TEXT,
  ALIGN_ACTION_H_CENTER_LABEL_TEXT,
  ALIGN_ACTION_RIGHT_LABEL_TEXT,
  ALIGN_ACTION_TOP_LABEL_TEXT,
  ALIGN_ACTION_V_CENTER_LABEL_TEXT,
  ALIGN_ACTION_BOTTOM_LABEL_TEXT
} from './AlignmentControlsLabels';

import './AlignmentControls.css';

export const ALIGN_ACTION_LEFT = 'ALIGN_LEFT';
export const ALIGN_ACTION_H_CENTER = 'ALIGN_H_CENTER';
export const ALIGN_ACTION_RIGHT = 'ALIGN_RIGHT';
export const ALIGN_ACTION_TOP = 'ALIGN_TOP';
export const ALIGN_ACTION_V_CENTER = 'ALIGN_V_CENTER';
export const ALIGN_ACTION_BOTTOM = 'ALIGN_BOTTOM';

export type AlignmentAction =
  | typeof ALIGN_ACTION_RIGHT
  | typeof ALIGN_ACTION_LEFT
  | typeof ALIGN_ACTION_TOP
  | typeof ALIGN_ACTION_BOTTOM
  | typeof ALIGN_ACTION_V_CENTER
  | typeof ALIGN_ACTION_H_CENTER;

const AlignmentControls = ({ projectId }: { projectId: number }) => {
  const selectedElementIds = useTypedSelector(getActiveElements);
  const dispatch = useDispatch();

  const handleAction = (event: React.MouseEvent, alignment: AlignmentAction) => {
    event.preventDefault();
    dispatch(alignSelectedElements(alignment, projectId));
  };

  return selectedElementIds.length > 1 ? (
    <EditPanelFieldset>
      <EditPanelLegend>Alignment controls:</EditPanelLegend>
      <div className="AlignmentControls">
        <EditPanelTripleButtonGroup>
          <ActionButtonWithA11yLabel
            onClick={(event: React.MouseEvent) => handleAction(event, ALIGN_ACTION_LEFT)}
            labelText={ALIGN_ACTION_LEFT_LABEL_TEXT}
            id="element-align-left"
          >
            <IconElementAlignLeft />
          </ActionButtonWithA11yLabel>

          <ActionButtonWithA11yLabel
            onClick={(event: React.MouseEvent) => handleAction(event, ALIGN_ACTION_H_CENTER)}
            labelText={ALIGN_ACTION_H_CENTER_LABEL_TEXT}
            id="element-align-center-horizontal"
          >
            <IconElementAlignHCenter />
          </ActionButtonWithA11yLabel>

          <ActionButtonWithA11yLabel
            onClick={(event: React.MouseEvent) => handleAction(event, ALIGN_ACTION_RIGHT)}
            labelText={ALIGN_ACTION_RIGHT_LABEL_TEXT}
            id="element-align-right"
          >
            <IconElementAlignRight />
          </ActionButtonWithA11yLabel>
        </EditPanelTripleButtonGroup>
        <EditPanelTripleButtonGroup>
          <ActionButtonWithA11yLabel
            onClick={(event: React.MouseEvent) => handleAction(event, ALIGN_ACTION_TOP)}
            labelText={ALIGN_ACTION_TOP_LABEL_TEXT}
            id="element-align-top"
          >
            <IconElementAlignTop />
          </ActionButtonWithA11yLabel>

          <ActionButtonWithA11yLabel
            onClick={(event: React.MouseEvent) => handleAction(event, ALIGN_ACTION_V_CENTER)}
            labelText={ALIGN_ACTION_V_CENTER_LABEL_TEXT}
            id="element-align-center-vertical"
          >
            <IconElementAlignVCenter />
          </ActionButtonWithA11yLabel>

          <ActionButtonWithA11yLabel
            onClick={(event: React.MouseEvent) => handleAction(event, ALIGN_ACTION_BOTTOM)}
            labelText={ALIGN_ACTION_BOTTOM_LABEL_TEXT}
            id="element-align-bottom"
          >
            <IconElementAlignBottom />
          </ActionButtonWithA11yLabel>
        </EditPanelTripleButtonGroup>
      </div>
    </EditPanelFieldset>
  ) : null;
};

export default AlignmentControls;
