import { DownloadTrackingProperties } from 'js/types';

export const RENDER_FAILED = 'RENDER_FAILED';

export type RenderFailedAction = {
  type: typeof RENDER_FAILED;
  error: Error;
  shortId: string;
  format: string;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

type RenderFailedActionInput = {
  error: Error;
  shortId: string;
  format: string;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export const renderFailed = ({
  error,
  shortId,
  format,
  trackingProperties,
  scribeId
}: RenderFailedActionInput): RenderFailedAction => ({
  type: RENDER_FAILED,
  error,
  shortId,
  format,
  trackingProperties,
  scribeId
});
