import React from 'react';
import { useDispatch } from 'react-redux';
import uniq from 'lodash.uniq';
import { getScribeById } from 'js/sagas/selectors';
import { setSceneTransitionConfig } from 'js/actionCreators/sceneTransitionsActions';
import { SCENE_TRANSITION_NO_CURSOR_ID, DEFAULT_ERASE_CURSOR_ID, DEFAULT_DRAG_CURSOR_ID } from 'js/config/consts';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { VSComponentProps, ScribeCursorId, SCRIBE_CURSOR_IDS } from 'js/types';

import '../AnimationPanels/HandMenu.css';
import HandMenu from '../AnimationPanels/HandMenu';

const isValidCursorId = (cursorId: string | number): cursorId is ScribeCursorId => {
  if (typeof cursorId === 'string') {
    return SCRIBE_CURSOR_IDS.some(type => type === cursorId);
  }

  return typeof cursorId === 'number';
};

const SceneHandMenu = ({ config, onSelection, scribeId, sceneIds = undefined }: VSComponentProps) => {
  const scribe = useTypedSelector(state => getScribeById(state, scribeId));
  const activeScene = scribe && scribe.scenes.find(scene => scene.active);
  const sceneTransitionType = activeScene?.settings?.sceneTransitionType;

  const cursorKey = sceneTransitionType === 'erase' ? 'handErase' : 'handDrag';

  const hands = useTypedSelector(state => state.cursors[cursorKey]);
  const dispatch = useDispatch();

  const baseFilters = sceneTransitionType === 'erase' ? ['none', 'all'] : ['all'];
  const filters = [...baseFilters, ...uniq(hands.map(hand => hand.group.toLowerCase()).sort())];

  let selectedCursorId: string | number;
  if (config?.cursorId) {
    selectedCursorId = config.cursorId;
  } else {
    selectedCursorId = cursorKey === 'handErase' ? DEFAULT_ERASE_CURSOR_ID : DEFAULT_DRAG_CURSOR_ID;
  }

  const handleSelection = (cursorId: string | number) => {
    if (isValidCursorId(cursorId)) {
      dispatch(
        setSceneTransitionConfig({
          sceneIds,
          scribeId,
          config: {
            cursorId
          }
        })
      );
    }
    onSelection();
  };

  const handleFilterChange = (filter: string) => {
    if (filter === 'none') {
      dispatch(
        setSceneTransitionConfig({
          sceneIds,
          scribeId,
          config: {
            cursorId: SCENE_TRANSITION_NO_CURSOR_ID
          }
        })
      );
      onSelection();
    }
  };

  return (
    <HandMenu
      hands={hands}
      onSelection={handleSelection}
      selectedCursorId={selectedCursorId}
      defaultCursorId={DEFAULT_ERASE_CURSOR_ID}
      defaultCursorGroup="all"
      filters={filters}
      onFilterChange={handleFilterChange}
    />
  );
};

export default SceneHandMenu;
