import { AccountType, RootState, ScribeState, TemplateState } from 'js/types';

export const getProjectById = ({ scribes }: { scribes: ScribeState }, scribeId: string | number) => {
  const scribeIdToUse = typeof scribeId === 'number' ? scribeId : parseInt(scribeId, 10);

  return scribes && scribes.allScribes && scribes.allScribes.find(scribe => scribe.id === scribeIdToUse);
};
export const getScribeById = getProjectById;

export const getTemplateById = ({ templates }: { templates: TemplateState }, templateId: string) => {
  return (
    templates?.templatesByCategory
      .flatMap(category => category.templates)
      .find(template => template.id === templateId)
  );
};

export const getActiveElements = ({ scribes }: { scribes: ScribeState }) => scribes.activeElements;

export const getUndoObjects = ({ scribes }: { scribes: ScribeState }, scribeId: number) => ({
  activeScribe: getScribeById({ scribes }, scribeId),
  activeScribePast: scribes.activeScribePast,
  activeScribeFuture: scribes.activeScribeFuture
});

export const getContentfulManagementToken = (state: RootState) =>
  state.templates && state.templates.contentfulManagementToken;

export const getUserAccountType = (state: RootState): AccountType | undefined => {
  return 'accountType' in state.auth.currentUser ? state.auth.currentUser.accountType : undefined;
};

export const getUser = (state: RootState) => state.auth.currentUser;

export const getImageCropData = (state: RootState) => state.cropping.imageCrop;

export const getCursorsFromState = (state: RootState) =>
  Object.values(state.cursors).reduce((allCursors, cursorGroup) => [...allCursors, ...cursorGroup], []);

export const getUserAudioAssets = (state: RootState) => state.audio.userAudioAssets;

export const isAdvancedTimelineOpen = (state: RootState) => state.ui.newTimeline;

export const getUserAssetFromState = (state: RootState, assetId: number) => {
  return (
    state.images.userImages.find(asset => asset.projectAssetId === assetId) ??
    state.images.userAiImages.find(asset => asset.projectAssetId === assetId)
  );
};
export const getImageCategories = (state: RootState) => state.images.libraries;
