import { UserImageLibraryCollection, VSCAssetData, VSCAssetImageSourceName } from 'js/types';
import cloneDeep from 'lodash.clonedeep';

export const filterSourceAssetsIntoImageLibraries = (
  sourceLibrary: UserImageLibraryCollection,
  imageAssets: VSCAssetData[]
): UserImageLibraryCollection => {
  const userLibrary = cloneDeep(sourceLibrary);

  Object.values(VSCAssetImageSourceName).forEach(sourceName => {
    const filterFn =
      sourceName === VSCAssetImageSourceName.SPARKOL_IMAGE
        ? (asset: VSCAssetData) => !asset.sourceName || asset.sourceName === sourceName
        : (asset: VSCAssetData) => asset.sourceName === sourceName;

    const filteredAssets = imageAssets.filter(filterFn);

    userLibrary[sourceName] = {
      ...userLibrary[sourceName],
      sourceAssets: filteredAssets
    };
  });

  return userLibrary;
};
