import { NO_CURSOR_ID } from 'js/config/consts';
import * as PIXI from 'pixi.js';
import { CanvasSize, CursorResourceAndMetadata, ScribeCursorId, CursorTiming } from 'js/types';

import getCursorResourceGroupsFromResourcesAndMetadata from '../helpers/getCursorResourceGroupsFromResourcesAndMetadata';

const DEFAULT_HAND_SCALE_FACTOR = 1.5;
const LARGE_HAND_SCALE_FACTOR = 2;
class VSCursor {
  cursor = new PIXI.Container();
  sprites: { [key: string]: PIXI.AnimatedSprite } = {};
  timings: Array<CursorTiming>;
  previousCursorX = 0;
  lastCursorId: ScribeCursorId | undefined;

  constructor(
    startPosition: { x: number; y: number },
    cursor: {
      resourcesAndMetadata: CursorResourceAndMetadata;
      timings: Array<CursorTiming>;
    },
    canvasSize: CanvasSize,
    imageResources: PIXI.utils.Dict<PIXI.LoaderResource>
  ) {
    const handScale = canvasSize === 'portrait' ? LARGE_HAND_SCALE_FACTOR : DEFAULT_HAND_SCALE_FACTOR;

    this.cursor.x = startPosition.x || 0;
    this.cursor.y = startPosition.y || 0;

    this.sprites = {};
    this.timings = cursor.timings;

    const resourceGroups = getCursorResourceGroupsFromResourcesAndMetadata(cursor.resourcesAndMetadata);

    resourceGroups.forEach(([cursorId, cursorData]) => {
      const textures = cursorData.resources
        .map(url => imageResources[url].texture)
        .filter((texture): texture is PIXI.Texture => !!texture);

      const sprite = new PIXI.AnimatedSprite(textures);
      sprite.x = (cursorData.handOffsetX - 100) * handScale;
      sprite.y = (cursorData.handOffsetY - 100) * handScale;
      sprite.scale = { x: handScale, y: handScale };

      this.sprites[cursorId] = sprite;
    });
  }

  updateCursorPosition(point = { x: 0, y: 0 }, angle = 0, elapsedTime = 0) {
    this.previousCursorX = this.cursor.x;
    this.cursor.x = point.x;
    this.cursor.y = point.y;
    this.cursor.angle = angle;
    this.updateHandSprite(elapsedTime);
  }

  updateHandSprite(elapsedTime: number) {
    const cursorIdInThisFrame = this.timings.find(
      timing => elapsedTime >= timing.startTime && elapsedTime < timing.endTime
    )?.cursorId;

    if (!cursorIdInThisFrame || cursorIdInThisFrame !== this.lastCursorId || cursorIdInThisFrame === NO_CURSOR_ID) {
      this.cursor.removeChildren();
    }

    if (cursorIdInThisFrame && cursorIdInThisFrame !== NO_CURSOR_ID && this.sprites[cursorIdInThisFrame]) {
      if (cursorIdInThisFrame !== this.lastCursorId) {
        this.cursor.addChild(this.sprites[cursorIdInThisFrame]);
      }
      this.updateHandPosition(this.sprites[cursorIdInThisFrame]);
    }

    this.lastCursorId = cursorIdInThisFrame;
  }

  updateHandPosition(handSprite: PIXI.AnimatedSprite) {
    if (!handSprite || (handSprite && handSprite.textures.length <= 1)) {
      return;
    }
    if (this.previousCursorX < this.cursor.x) {
      // moving right
      handSprite.gotoAndStop(0);
    }
    // There is no equal condition as we don't want the hand position to remain the
    // same if there has been no change in direction
    if (this.previousCursorX > this.cursor.x) {
      // moving left
      handSprite.gotoAndStop(1);
    }
  }

  getStageElement() {
    return this.cursor;
  }
}

export default VSCursor;
