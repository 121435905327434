import {
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  EMPHASIS_TWEEN_DOCUMENT_KEY,
  EXIT_TWEEN_DOCUMENT_KEY,
  EXIT_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  EMPHASIS_ANIMATION_DOCUMENT_KEY
} from 'js/config/consts';
import uuidv4 from 'uuid/v4';
import {
  ElementType,
  TweenAnimation,
  ScribeElementModelProps,
  NewElementProps,
  ScribeCursorId,
  AnimationConfiguration
} from 'js/types';

class ScribeElementModel {
  id: string;
  type: ElementType;
  width: number;
  height: number;
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  originX: string;
  originY: string;
  flipX: boolean;
  flipY: boolean;
  locked: boolean;
  unlockedRatio: boolean;
  hidden: boolean;
  outOfCamera: boolean;

  duration?: number;
  pauseTime?: number;
  customPauseTime?: number;
  animationTime?: number;
  emphasisAnimationTime?: number;
  exitAnimationTime?: number;
  emphasisAnimationLoops?: number;
  cursorId?: ScribeCursorId;
  entranceTween?: TweenAnimation;
  emphasisTween?: TweenAnimation;
  exitTween?: TweenAnimation;
  opacity: number;
  exitAnimation?: AnimationConfiguration;
  entranceAnimation?: AnimationConfiguration;
  emphasisAnimation?: AnimationConfiguration;
  isPremium?: boolean;

  strippedInRenderer?: boolean;

  constructor(data: ScribeElementModelProps | NewElementProps, type: ElementType) {
    if ('id' in data) {
      this.id = data.id;
      this.type = data.type;
      this.width = data.width;
      this.height = data.height;
      this.x = data.x;
      this.y = data.y;
      this.duration = data.duration;

      this.angle = data.angle;
      this.scaleX = data.scaleX;
      this.scaleY = data.scaleY;

      this.flipX = data.flipX;
      this.flipY = data.flipY;
      this.originX = data.originX || 'left';
      this.originY = data.originY || 'top';
      this.unlockedRatio =
        typeof data.unlockedRatio !== 'undefined' ? data.unlockedRatio : ['Image', 'Shape'].includes(type);

      // `pauseTime` is a rename of `customPauseTime` so this needs to remain to maintain compatibility
      // with previously stored scribes
      this.pauseTime = typeof data.pauseTime === 'undefined' ? data.customPauseTime : data.pauseTime;

      this.animationTime = data.animationTime;
      this.emphasisAnimationTime = data.emphasisAnimationTime;
      this.emphasisAnimationLoops = data.emphasisAnimationLoops;
      this.exitAnimationTime = data.exitAnimationTime;
      this.locked = data.locked || false;
      this.hidden = data.hidden || false;
      this.outOfCamera = data.outOfCamera || false;
      this.opacity = data.opacity ?? 1;

      this.cursorId = data.cursorId;
      this[ENTRANCE_TWEEN_DOCUMENT_KEY] = data[ENTRANCE_TWEEN_DOCUMENT_KEY];
      this[EMPHASIS_TWEEN_DOCUMENT_KEY] = data[EMPHASIS_TWEEN_DOCUMENT_KEY];
      this[EXIT_TWEEN_DOCUMENT_KEY] = data[EXIT_TWEEN_DOCUMENT_KEY];

      this[EXIT_ANIMATION_DOCUMENT_KEY] = data[EXIT_ANIMATION_DOCUMENT_KEY];
      this[ENTRANCE_ANIMATION_DOCUMENT_KEY] = data[ENTRANCE_ANIMATION_DOCUMENT_KEY];
      this[EMPHASIS_ANIMATION_DOCUMENT_KEY] = data[EMPHASIS_ANIMATION_DOCUMENT_KEY];

      this.isPremium = data.isPremium;
    } else {
      this.id = uuidv4();
      this.x = data.canvasSize.width / 2;
      this.y = data.canvasSize.height / 2;
      this.scaleX = 1;
      this.scaleY = 1;
      this.angle = 0;
      this.originX = 'left';
      this.originY = 'top';
      this.flipX = false;
      this.flipY = false;
      this.locked = false;
      this.unlockedRatio = ['Image', 'Shape'].includes(type);
      this.width = data.width;
      this.height = data.height;
      this.hidden = false;
      this.outOfCamera = true;
      this.opacity = 1;
    }

    this.type = type;
  }
}

export default ScribeElementModel;
