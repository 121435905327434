export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const CLEAR_SEARCH_INPUT = 'CLEAR_SEARCH_INPUT';
export const SEARCH_IMAGES = 'SEARCH_IMAGES';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const RESET_SEARCH = 'RESET_SEARCH';
export interface SetSearchInputAction {
  type: typeof SET_SEARCH_INPUT;
  searchInput: string;
}
export const setSearchInput = (searchInput: string): SetSearchInputAction => ({
  type: SET_SEARCH_INPUT,
  searchInput
});

export interface ClearSearchInputAction {
  type: typeof CLEAR_SEARCH_INPUT;
}
export const clearSearchInput = (): ClearSearchInputAction => ({
  type: CLEAR_SEARCH_INPUT
});

export interface SearchImagesAction {
  type: typeof SEARCH_IMAGES;
}
export const searchImages = (): SearchImagesAction => ({
  type: SEARCH_IMAGES
});

export interface SetSelectedCategoryAction {
  type: typeof SET_SELECTED_CATEGORY;
  selectedCategoryId: string;
}
export const setSelectedCategory = (selectedCategoryId: string): SetSelectedCategoryAction => ({
  type: SET_SELECTED_CATEGORY,
  selectedCategoryId
});

export interface ResetSearchAction {
  type: typeof RESET_SEARCH;
}
export const resetSearch = (): ResetSearchAction => ({
  type: RESET_SEARCH
});
