import './CategoryTile.css';

import React from 'react';

import fallbackThumbnail from './fallback-thumbnail.png';

interface CategoryTileProps {
  title: string;
  onClick: () => void;
  thumbnail?: string;
}
const CategoryTile = ({ title, onClick, thumbnail }: CategoryTileProps) => (
  <button className="CategoryTile" onClick={onClick}>
    <img src={thumbnail || fallbackThumbnail} alt="" draggable={false} />
    <span className="t-utility-label">{title}</span>
  </button>
);

export default CategoryTile;
