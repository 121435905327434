import { useDispatch } from 'react-redux';
import { ImageCategory } from 'js/types';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import {
  setSearchInput as setSearchInputAction,
  clearSearchInput as clearSearchInputAction,
  setSelectedCategory,
  searchImages,
  resetSearch
} from 'js/actionCreators/imageSearchActions';

const useHandleSearch = () => {
  const dispatch = useDispatch();
  const { searchInput, submittedSearchInput, searchType, selectedCategoryId, displayedStep } = useTypedSelector(
    state => state.imageSearch
  );

  const setSearchInput = (input: string) => {
    dispatch(setSearchInputAction(input));
  };

  const clearSearchInput = () => {
    dispatch(clearSearchInputAction());
  };

  const handleCategoryClick = (category: ImageCategory) => {
    dispatch(setSelectedCategory(category.id));
  };

  const handleClickToCategories = () => {
    dispatch(resetSearch());
  };

  const handleSearch = () => {
    dispatch(searchImages());
  };

  return {
    searchInput,
    submittedSearchInput,
    searchType,
    selectedCategoryId,
    displayedStep,
    setSearchInput,
    clearSearchInput,
    handleCategoryClick,
    handleClickToCategories,
    handleSearch
  };
};

export default useHandleSearch;
