import { defaultScale } from '../config/defaults';
import {
  ScribeImageElementModelProps,
  ViewBoxAttributes,
  ImageDetails,
  NewElementProps,
  RecolorDefaults
} from '../types';

import ScribeElementModel from './ScribeElementModel';

class ScribeImageElementModel extends ScribeElementModel {
  type = 'Image' as const;
  useContentSvgViewbox?: boolean;
  _imageUrl?: string | null = null;
  viewboxAttributes?: ViewBoxAttributes;
  image?: ImageDetails;
  _imageLoaded?: boolean;
  _imageLoading?: boolean;
  _recolorDefaults?: RecolorDefaults;
  recolorAvailable?: boolean;
  containsEmbeddedImage?: boolean;

  constructor(data: ScribeImageElementModelProps | NewElementProps) {
    super(data, 'Image');

    if ('id' in data) {
      this.image = data.image;
      this._imageLoaded = data._imageLoaded;
      this._imageLoading = data._imageLoading;
      this.containsEmbeddedImage = data.containsEmbeddedImage;

      if (data._imageUrl !== undefined) {
        this._imageUrl = data._imageUrl;
      }

      if (data.viewboxAttributes) {
        this.viewboxAttributes = data.viewboxAttributes;
        this.useContentSvgViewbox = data.useContentSvgViewbox;
      }

      if (data.recolorAvailable) {
        this.recolorAvailable = data.recolorAvailable;
        this._recolorDefaults = { ...data._recolorDefaults };
      }
    } else {
      this.scaleX = defaultScale;
      this.scaleY = defaultScale;
    }
  }
}

export default ScribeImageElementModel;
