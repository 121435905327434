import { NO_CURSOR_ID } from 'js/config/consts';
import { CursorResourceAndMetadata } from 'js/types';

export default function getCursorResourceGroupsFromResourcesAndMetadata(
  resourcesAndMetadata: CursorResourceAndMetadata
) {
  return Object.entries(resourcesAndMetadata).filter(
    ([cursorId, cursorData]) => Number(cursorId) !== NO_CURSOR_ID && cursorData.resources.length !== 0
  );
}
