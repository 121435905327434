import uniq from 'lodash.uniq';
import { SHAKE_LOOP_KEY, BOUNCE_LOOP_KEY } from 'js/config/consts';

import { defaultShakeTiming, defaultBounceEmphasisTiming } from '../resources/scribedefaults';

const getEmphasisAnimationDuration = ({ durationProperty, element, elements, scribe }) => {
  const { settings } = scribe;
  const defaultTiming = settings[durationProperty];

  if (element && element.emphasisTween && element.emphasisTween.id === SHAKE_LOOP_KEY) {
    return element[durationProperty] && element[durationProperty] !== 0
      ? element[durationProperty]
      : defaultShakeTiming;
  } else if (element && element.emphasisTween && element.emphasisTween.id === BOUNCE_LOOP_KEY) {
    return element[durationProperty] && element[durationProperty] !== 0
      ? element[durationProperty]
      : defaultBounceEmphasisTiming;
  } else if (element && !element.emphasisTween) {
    return element[durationProperty] ?? 0;
  } else if (element) {
    return element[durationProperty] && element[durationProperty] !== 0 ? element[durationProperty] : defaultTiming;
  }

  let durationList = [];
  for (const el of elements) {
    if (el?.emphasisTween?.id === SHAKE_LOOP_KEY) {
      durationList.push(el[durationProperty] && el[durationProperty] !== 0 ? el[durationProperty] : defaultShakeTiming);
    } else if (el?.emphasisTween?.id === BOUNCE_LOOP_KEY) {
      durationList.push(
        el[durationProperty] && el[durationProperty] !== 0 ? el[durationProperty] : defaultBounceEmphasisTiming
      );
    } else if (!el.emphasisTween) {
      durationList.push(el[durationProperty] ?? 0);
    } else {
      durationList.push(el[durationProperty] && el[durationProperty] !== 0 ? el[durationProperty] : defaultTiming);
    }
  }

  const uniqueDuration = uniq(durationList);

  return uniqueDuration.length === 1 ? uniqueDuration[0] : '-';
};

export default getEmphasisAnimationDuration;
