import React from 'react';

export default function IconAnimationMoveIn({ flipImage }) {
  const style = {
    enableBackground: '0 0 100 100',
    transform: flipImage ? 'rotate(180deg)' : undefined
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      style={style}
      xmlSpace="preserve"
    >
      <path d="M24.9,38.1H13.6v3h13.2C26.1,40.2,25.4,39.2,24.9,38.1z" />
      <path
        d="M40.7,44.7c-8.2,0-14.8-6.7-14.8-14.8c0-8.2,6.7-14.8,14.8-14.8c8.2,0,14.8,6.7,14.8,14.8C55.5,38.1,48.9,44.7,40.7,44.7z
M40.7,18.1c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8s11.8-5.3,11.8-11.8S47.2,18.1,40.7,18.1z"
      />
      <path d="M26.7,18.8H14v3h10.8C25.4,20.7,26,19.7,26.7,18.8z" />
      <path d="M22.8,29.9c0-0.6,0-1.2,0.1-1.8H4v3h18.9C22.9,30.7,22.8,30.3,22.8,29.9z" />
    </svg>
  );
}
