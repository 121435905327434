import React from 'react';

const IconSceneSlideIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="90px"
    height="60px"
    viewBox="0 0 90 60"
    enableBackground="new 0 0 90 60"
    fill="currentcolor"
  >
    <path
      d="M76.6,31.9H47.5V14.7l9.7,9.7l3.2-3.2L46.9,7.7c-0.9-0.9-2.3-0.9-3.2,0L30.2,21.3l3.2,3.2l9.7-9.7v17.2H13.7
      c-2.5,0-4.6,2-4.6,4.6v15.7h72V36.5C81.2,33.9,79.1,31.9,76.6,31.9z"
    />
  </svg>
);

export default IconSceneSlideIn;
