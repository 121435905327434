import {
  SET_SCENE_TRANSITION_TYPE,
  SET_SCENE_TRANSITION_DURATION,
  SET_SCENE_TRANSITION_CONFIG
} from 'js/actionCreators/sceneTransitionsActions';
import { updateScribe } from 'js/actionCreators/scribeActions';
import SCENE_TRANSITION_OPTIONS, {
  DEFAULT_SCENE_TRANSITION_DURATION,
  SCENE_DIRECTION_KEYS
} from 'js/config/sceneTransitionsOptions';
import {
  SCENE_TRANSITIONS_WITH_DIRECTION,
  SCENE_TRANSITION_KEY_NONE,
  SCENE_TRANSITION_KEY_DRAG_ON
} from 'js/config/consts';
import cloneDeep from 'lodash.clonedeep';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';

import { getScribeById } from './selectors';

const isValidDirectionForType = (type, direction) => {
  if (type === SCENE_TRANSITION_KEY_DRAG_ON && direction) {
    return true;
  }

  switch (direction) {
    case SCENE_DIRECTION_KEYS.UP:
    case SCENE_DIRECTION_KEYS.DOWN:
    case SCENE_DIRECTION_KEYS.LEFT:
    case SCENE_DIRECTION_KEYS.RIGHT:
      return true;

    default:
      return false;
  }
};

const getActiveSceneIds = (scribe, sceneIds) => {
  if (sceneIds && sceneIds.length > 0) {
    return sceneIds;
  }

  const activeScene = getActiveScene(scribe);

  if (!activeScene) {
    return undefined;
  }

  return [activeScene.id];
};

export function* setSceneTransitionType({ transitionType, scribeId, sceneIds }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;
  const updatedScribe = cloneDeep(scribe);

  let sceneIdsToUse = getActiveSceneIds(scribe, sceneIds);

  if (!sceneIdsToUse) {
    return;
  }

  let hasUpdated = false;

  sceneIdsToUse.forEach(sceneId => {
    const scene = updatedScribe.scenes.find(scene => scene.id === sceneId);

    if (!scene) {
      return;
    }

    hasUpdated = true;

    let updateDuration =
      transitionType === SCENE_TRANSITION_KEY_NONE
        ? 0
        : scene.settings.sceneTransitionTime || DEFAULT_SCENE_TRANSITION_DURATION;
    let updateType = transitionType || scene.settings.sceneTransitionType;

    let updateConfig = {};

    if (SCENE_TRANSITIONS_WITH_DIRECTION.includes(transitionType)) {
      const defaultConfig = SCENE_TRANSITION_OPTIONS.find(option => option.type === updateType)?.defaultConfig;

      const previousDirection = scene.settings.sceneTransitionConfig?.direction;

      const usePreviousDirection = isValidDirectionForType(transitionType, previousDirection);

      updateConfig = {
        ...defaultConfig,
        direction: usePreviousDirection ? previousDirection : defaultConfig?.direction
      };
    } else {
      updateConfig = SCENE_TRANSITION_OPTIONS.find(option => option.type === updateType)?.defaultConfig;
    }

    scene.settings = {
      ...scene.settings,
      sceneTransitionTime: updateDuration,
      sceneTransitionType: updateType,
      sceneTransitionConfig: updateConfig
    };
  });

  if (!hasUpdated) {
    return;
  }

  yield put(updateScribe(updatedScribe));
}

export function* setSceneTransitionDuration({ duration, scribeId, sceneIds }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const updatedScribe = cloneDeep(scribe);

  let sceneIdsToUse = getActiveSceneIds(scribe, sceneIds);

  if (!sceneIdsToUse) {
    return;
  }

  let hasUpdated = false;

  sceneIdsToUse.forEach(sceneId => {
    const scene = updatedScribe.scenes.find(scene => scene.id === sceneId);

    if (!scene) return;

    hasUpdated = true;

    let updateDuration = duration ?? scene.settings.sceneTransitionTime;
    let updateType = duration === 0 ? SCENE_TRANSITION_KEY_NONE : scene.settings.sceneTransitionType;
    let updateConfig = duration === 0 ? undefined : scene.settings.sceneTransitionConfig;

    scene.settings = {
      ...scene.settings,
      sceneTransitionType: updateType,
      sceneTransitionTime: updateDuration,
      sceneTransitionConfig: updateConfig
    };
  });

  if (!hasUpdated) {
    return;
  }

  yield put(updateScribe(updatedScribe));
}

export function* setSceneTransitionConfig({ config, scribeId, sceneIds }) {
  const scribe = yield select(getScribeById, scribeId);

  if (!scribe) return;

  const updatedScribe = cloneDeep(scribe);

  let sceneIdsToUse = getActiveSceneIds(scribe, sceneIds);

  if (!sceneIdsToUse) {
    return;
  }

  let hasUpdated = false;

  sceneIdsToUse.forEach(sceneId => {
    const scene = updatedScribe.scenes.find(scene => scene.id === sceneId);

    if (!scene) return;

    hasUpdated = true;

    let newConfig = { ...config };

    if (scene.settings.sceneTransitionType === 'drag-on') {
      newConfig = {
        ...scene.settings.sceneTransitionConfig,
        ...newConfig
      };
    }

    scene.settings = {
      ...scene.settings,
      sceneTransitionConfig: newConfig
    };
  });

  if (!hasUpdated) {
    return;
  }

  yield put(updateScribe(updatedScribe));
}

export default function* sceneTransitionsSaga() {
  yield takeEvery(SET_SCENE_TRANSITION_TYPE, setSceneTransitionType);
  yield takeEvery(SET_SCENE_TRANSITION_DURATION, setSceneTransitionDuration);
  yield takeEvery(SET_SCENE_TRANSITION_CONFIG, setSceneTransitionConfig);
}
