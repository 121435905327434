import ScribeTextElementModel from 'js/models/ScribeTextElementModel';

import { Font } from './fontBaselineShift';

export function getProjectFontsFromTextElements(textElements: ScribeTextElementModel[]) {
  const fontFaceSet = new Set<string>();
  const projectFonts: Array<Font> = textElements.reduce<Font[]>((acc, el) => {
    const fontFamily = el.font.value;
    const fontWeight = el.fontWeight;
    const fontStyle = el.fontStyle;
    const fontFace = fontFamily + fontWeight + fontStyle;
    if (fontFaceSet.has(fontFace)) return acc;
    acc.push({
      fontFamily,
      fontWeight,
      fontStyle,
      text: el.text ?? ''
    });
    fontFaceSet.add(fontFace);
    return acc;
  }, []);
  return projectFonts;
}
