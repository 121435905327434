import { EXIT_ANIMATION_DOCUMENT_KEY, EXIT_TWEEN_DOCUMENT_KEY } from 'js/config/consts';
import { ScribeScene, ScribeCameraElement, VSElementModel, AudioClip } from 'js/types';
import ScribeModel from 'js/models/ScribeModel';

import { mapSceneElementIdsToElements } from '../../../../shared/helpers/scenesHelpers';

export type TimineProps = 'animationTime' | 'exitAnimationTime' | 'emphasisAnimationTime' | 'pauseTime';

export function getElementEntranceAnimationTimeMs(element: VSElementModel, scribe: ScribeModel) {
  const { scene } = getSceneFromElementID(element.id, scribe);

  const time = getTimingForElement('animationTime', element, scene, scribe);
  return time * 1000;
}

export function getElementExitAnimationTimeMs(element: VSElementModel, scribe: ScribeModel) {
  const { scene } = getSceneFromElementID(element.id, scribe);
  if (element.type === 'Camera') {
    return 0;
  }
  const time =
    element[EXIT_TWEEN_DOCUMENT_KEY] || element[EXIT_ANIMATION_DOCUMENT_KEY]
      ? getTimingForElement('exitAnimationTime', element, scene, scribe)
      : 0;

  return time * 1000;
}

export function getElementEmphasisTimeMs(element: VSElementModel) {
  const time = element.type === 'Camera' ? 0 : element.emphasisAnimationTime || 0;

  return time * 1000;
}

export function getSceneAudioLength(scribe: ScribeModel, scene: ScribeScene) {
  const audioElements: AudioClip[] = [];
  const audioLayers = scene.audioLayerIds?.map(audioLayerId => {
    return scribe.audioLayers?.find(audioLayer => audioLayer.id === audioLayerId);
  });
  audioLayers?.forEach(audioLayer => {
    if (audioLayer && audioLayer.audioClipIds) {
      audioLayer.audioClipIds.forEach(audioClipId => {
        const audioClip = scribe.audioClips?.find(audioClip => audioClip.id === audioClipId);
        if (audioClip) {
          audioElements.push(audioClip);
        }
      });
    }
  });

  if (audioElements.length === 0) {
    return 0;
  }
  let sceneAudioLength = 0;
  audioElements.forEach(audioElement => {
    if (audioElement && audioElement.startTime !== undefined && audioElement.instanceDuration !== undefined) {
      if (audioElement.startTime + audioElement.instanceDuration > sceneAudioLength) {
        sceneAudioLength = audioElement.startTime + audioElement.instanceDuration;
      }
    }
  });

  return sceneAudioLength * 1000;
}

export function getElementEmphasisLoops(element: VSElementModel) {
  if (element.type === 'Camera') {
    return 0;
  }
  const loops = element.emphasisAnimationLoops || 0;

  return loops;
}

export function getElementPauseTimeMs(element: VSElementModel, scribe: ScribeModel) {
  const { scene } = getSceneFromElementID(element.id, scribe);
  const time = getTimingForElement('pauseTime', element, scene, scribe);

  return time * 1000;
}

export function getElementTotalTimesLengthMs(element: VSElementModel, scribe: ScribeModel) {
  const toReturn =
    getElementEntranceAnimationTimeMs(element, scribe) +
    getElementEmphasisTimeMs(element) +
    getElementExitAnimationTimeMs(element, scribe) +
    getElementPauseTimeMs(element, scribe);
  return toReturn;
}

export function getSceneFromElementID(elementId: string, scribe: ScribeModel) {
  if (!(scribe && scribe.scenes)) {
    return { scene: null, index: -1 };
  }
  for (let i = 0; i < scribe.scenes.length; i++) {
    const scene = scribe.scenes[i];
    const isElementInScene = scribe.scenes[i].elementIds.some(eid => {
      return eid === elementId;
    });
    if (isElementInScene) return { scene, index: i };
  }
  return { scene: null, index: -1 };
}

export function getTimingForElement(
  property: TimineProps,
  element: VSElementModel,
  scene?: ScribeScene | null,
  scribe?: ScribeModel
): number {
  if (element.type === 'Camera') {
    const cameraElement = element as ScribeCameraElement;
    if (
      cameraElement &&
      (property === 'animationTime' || property === 'pauseTime') &&
      cameraElement.hasOwnProperty(property)
    )
      return cameraElement[property];
  } else {
    if (element.hasOwnProperty(property)) {
      const elementProperty = element[property];
      if (elementProperty !== undefined) {
        return elementProperty;
      }
    }
  }
  if (scene && scene.settings && scene.settings.hasOwnProperty(property)) return scene.settings[property];
  if (scribe && scribe.settings && scribe.settings.hasOwnProperty(property)) return scribe.settings[property];
  return 0;
}

export function getStartTime(scribe: ScribeModel, elementId?: string | null, sceneIndex?: number) {
  if (!elementId) {
    if (sceneIndex && sceneIndex !== -1) {
      const scene = scribe.scenes[sceneIndex];
      const sceneStartAndEndTimes = getSceneStartEndTimes(scribe, scene);
      return sceneStartAndEndTimes.startTime;
    } else {
      return 0;
    }
  }
  const { scene } = getSceneFromElementID(elementId, scribe);
  if (!scene) return 0;
  const sceneStartAndEndTimes = getSceneStartEndTimes(scribe, scene);
  const sceneElements = mapSceneElementIdsToElements(scribe, scene).map(
    element =>
      ({
        ...element,
        animationTime: getElementEntranceAnimationTimeMs(element, scribe) / 1000,
        emphasisAnimationTime: getElementEmphasisTimeMs(element) / 1000,
        exitAnimationTime: getElementExitAnimationTimeMs(element, scribe) / 1000,
        pauseTime: getElementPauseTimeMs(element, scribe) / 1000
      }) as VSElementModel
  );

  const index = sceneElements.findIndex(el => el.id === elementId);
  if (index === -1) {
    return 0;
  }

  const sceneTransitionTime = scene?.settings?.sceneTransitionTime ? scene.settings.sceneTransitionTime : 0;
  const elements: VSElementModel[] = sceneElements.slice(0, index);
  const sceneStartTime = sceneStartAndEndTimes.startTime + sceneTransitionTime * 1000;
  const startTime = elements.reduce((prevTime, el) => {
    const t = getElementTotalTimesLengthMs(el, scribe);
    return t + prevTime;
  }, sceneStartTime);
  return startTime;
}

export function getSceneStartTimeAndLength(scribe: ScribeModel, scene: ScribeScene) {
  const prevScenes = scribe.scenes.slice(0, scribe.scenes.indexOf(scene));

  let sceneTimes = 0;
  prevScenes.forEach(prevScene => {
    let sceneTransitionTimes = 0;
    let elementTimes = 0;

    if (prevScene.settings?.sceneTransitionTime) sceneTransitionTimes += prevScene.settings.sceneTransitionTime;
    prevScene.elementIds.forEach(eid => {
      for (let el = 0; el < scribe.elements.length; el++) {
        if (scribe.elements[el].id === eid) {
          const e = scribe.elements[el];
          elementTimes += getElementTotalTimesLengthMs(e, scribe);
        }
      }
    });

    sceneTimes += Math.max(getSceneAudioLength(scribe, prevScene), elementTimes + sceneTransitionTimes * 1000);
  });
  const sceneStartTime = sceneTimes;

  const sceneLength = mapSceneElementIdsToElements(scribe, scene).reduce(
    (prevTime, e) => {
      return prevTime + getElementTotalTimesLengthMs(e, scribe);
    },
    (scene?.settings?.sceneTransitionTime ? scene.settings.sceneTransitionTime : 0) * 1000
  );
  const sceneAudioLength = getSceneAudioLength(scribe, scene);
  if (sceneAudioLength > sceneLength) {
    return {
      sceneStartTime,
      sceneLength: sceneAudioLength
    };
  }

  return { sceneStartTime, sceneLength };
}

export function getLengthOfAllAssets(scribe: ScribeModel) {
  let projectLength = getSceneStartEndTimes(scribe, scribe.scenes[scribe.scenes.length - 1]).endTime / 1000;

  scribe.audioClips?.forEach(audioClip => {
    if (audioClip.startTime === undefined || audioClip.instanceDuration === undefined) return;
    if (audioClip.startTime + audioClip.instanceDuration > projectLength) {
      projectLength = audioClip.startTime + audioClip.instanceDuration;
    }
  });
  return projectLength;
}

export function getSceneStartEndTimes(scribe: ScribeModel, scene: ScribeScene) {
  const { sceneStartTime, sceneLength } = getSceneStartTimeAndLength(scribe, scene);
  const sceneEndTime = sceneStartTime + sceneLength;

  return {
    startTime: sceneStartTime,
    endTime: sceneEndTime
  };
}
export function getSceneIndexFromID(sceneId: string, scribe: ScribeModel) {
  return scribe.scenes.findIndex(scene => scene.id === sceneId);
}
