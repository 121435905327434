import {
  LoadAudioLibraryAction,
  LoadAudioLibraryFailedAction,
  LoadAudioLibrarySuccessAction,
  LOAD_AUDIO_LIBRARY,
  LOAD_AUDIO_LIBRARY_FAILED,
  LOAD_AUDIO_LIBRARY_SUCCESS,
  CreateProjectAssetFromLibraryAction,
  CREATE_PROJECT_ASSET_FROM_LIBRARY,
  CreateProjectAssetFromLibraryFailedAction,
  CreateProjectAssetFromLibrarySuccessAction,
  CREATE_PROJECT_ASSET_FROM_LIBRARY_FAILED,
  CREATE_PROJECT_ASSET_FROM_LIBRARY_SUCCESS
} from 'js/actionCreators/audioLibraryActions';
import { AudioLibraryState, VSCAudioLibraryApiData, VSCAudioLibraryTrack } from 'js/types';

const MUSIC_GENRES_DICTIONARY: { [key: number]: string } = {
  0: 'Ambient',
  1: 'Blues',
  2: 'Dance',
  3: 'Easy',
  4: 'Electronic',
  5: 'Festive',
  6: 'Folk',
  7: 'Funk',
  8: 'Jazz',
  9: 'Orchestral',
  10: 'Pop',
  11: 'Rock',
  12: 'World'
};
function mapGenreNumbersToLabels(genres: number | string): Array<string> {
  if (typeof genres === 'number') {
    return [MUSIC_GENRES_DICTIONARY[genres]];
  } else {
    const stringGenres = genres.split(',');
    return stringGenres.map(stringGenre => MUSIC_GENRES_DICTIONARY[Number(stringGenre)]);
  }
}

const TEMPO_LABEL_DICTIONARY: { [key: number]: string } = {
  1: 'Slow',
  2: 'Medium',
  3: 'Fast'
};
function mapTempoNumbersToLabels(tempo: number): string {
  return TEMPO_LABEL_DICTIONARY[tempo];
}

function parseDurationTextToSeconds(durationString: string): number {
  const [minutesString, secondsString] = durationString.split(':');
  const minutes = Number(minutesString);
  const seconds = Number(secondsString);
  if (isNaN(minutes) || isNaN(seconds)) return 0; // Problem with the parse, default to 0

  return minutes * 60 + seconds;
}

function transformApiData(data: Array<VSCAudioLibraryApiData>): Array<VSCAudioLibraryTrack> {
  return data.map(apiData => {
    return {
      id: apiData.id,
      title: apiData.name,
      genres: mapGenreNumbersToLabels(apiData.genres),
      tempo: apiData.tempo,
      tempoLabel: mapTempoNumbersToLabels(apiData.tempo),
      duration: parseDurationTextToSeconds(apiData.time),
      durationLabel: apiData.time,
      url: apiData.resources.full.url,
      loopFriendly: apiData.loopFriendly === 'true'
    };
  });
}

export const initialState: AudioLibraryState = {
  loading: false,
  tracks: [],
  creatingFromAsset: null
};

type AudioLibraryAction =
  | LoadAudioLibraryAction
  | LoadAudioLibraryFailedAction
  | LoadAudioLibrarySuccessAction
  | CreateProjectAssetFromLibraryAction<'audio'>
  | CreateProjectAssetFromLibraryFailedAction
  | CreateProjectAssetFromLibrarySuccessAction;
export default function audioLibraryReducer(state = initialState, action: AudioLibraryAction) {
  switch (action.type) {
    case LOAD_AUDIO_LIBRARY: {
      return {
        ...state,
        loading: true
      };
    }

    case LOAD_AUDIO_LIBRARY_FAILED: {
      return {
        ...state,
        loading: false
      };
    }

    case LOAD_AUDIO_LIBRARY_SUCCESS: {
      return {
        ...state,
        tracks: transformApiData(action.data),
        loading: false
      };
    }

    case CREATE_PROJECT_ASSET_FROM_LIBRARY: {
      return {
        ...state,
        creatingFromAsset: action.libraryItemId
      };
    }

    case CREATE_PROJECT_ASSET_FROM_LIBRARY_FAILED:
    case CREATE_PROJECT_ASSET_FROM_LIBRARY_SUCCESS: {
      return {
        ...state,
        creatingFromAsset: null
      };
    }

    default:
      return state;
  }
}
