import { Limits } from 'js/types';
import { LIMITS } from 'js/config/consts';

export const UPDATE_LIMITS = 'UPDATE_LIMITS';
export const DECREMENT_LIMIT = 'DECREMENT_LIMIT';

export interface UpdateLimitsAction {
  type: typeof UPDATE_LIMITS;
  limits: Limits;
}

export const updateLimits = (limits: Limits): UpdateLimitsAction => ({
  type: UPDATE_LIMITS,
  limits
});

export interface DecrementLimitAction {
  type: typeof DECREMENT_LIMIT;
  limitType: LIMITS;
  decrementAmount: number;
}

export const decrementLimit = (limitType: LIMITS, decrementAmount: number): DecrementLimitAction => ({
  type: DECREMENT_LIMIT,
  limitType,
  decrementAmount
});
