import {
  LOAD_IMAGE_CROP,
  CANCEL_IMAGE_CROP,
  SET_CROP_IMAGE,
  CONFIRM_IMAGE_CROP_SUCCESS,
  SET_CROP_SHAPE,
  CONFIRM_IMAGE_CROP_FAILED,
  SHOW_CROP_WARNING,
  CANCEL_CROP_WARNING,
  CONFIRM_CROP_WARNING,
  StartImageCropAction,
  LoadImageCropAction,
  ConfirmImageCropFailedAction,
  SetCropShapeAction,
  SetCropImageAction,
  CancelCropWarningAction
} from 'js/actionCreators/croppingActions';
import { CroppingState } from 'js/types';

const initialState: CroppingState = {
  imageCrop: null,
  imageCropShape: undefined,
  showCropWarning: false
};

type CroppingActions =
  | StartImageCropAction
  | LoadImageCropAction
  | ConfirmImageCropFailedAction
  | SetCropShapeAction
  | SetCropImageAction
  | CancelCropWarningAction
  | {
      type:
        | typeof CANCEL_IMAGE_CROP
        | typeof CONFIRM_IMAGE_CROP_SUCCESS
        | typeof SHOW_CROP_WARNING
        | typeof CONFIRM_CROP_WARNING;
    };

export default function croppingReducer(state = initialState, action: CroppingActions): CroppingState {
  switch (action.type) {
    case LOAD_IMAGE_CROP: {
      return {
        ...state,
        imageCrop: {
          scribeId: action.scribeId,
          elementId: action.elementId,
          sceneId: action.sceneId,
          imageUrl: action.imageUrl,
          transforms: action.transforms,
          imageType: action.imageType,
          filename: action.filename
        }
      };
    }

    case CANCEL_IMAGE_CROP: {
      return {
        ...state,
        imageCrop: null,
        imageCropShape: undefined
      };
    }

    case SET_CROP_IMAGE: {
      return {
        ...state,
        imageCrop: state.imageCrop
          ? {
              ...state.imageCrop,
              crop: action.crop
            }
          : null
      };
    }

    case CONFIRM_IMAGE_CROP_FAILED:
    case CONFIRM_IMAGE_CROP_SUCCESS: {
      return {
        ...state,
        imageCrop: null,
        imageCropShape: undefined
      };
    }

    case SET_CROP_SHAPE: {
      return {
        ...state,
        imageCropShape: action.shape
      };
    }

    case SHOW_CROP_WARNING: {
      return {
        ...state,
        showCropWarning: true
      };
    }

    case CONFIRM_CROP_WARNING:
    case CANCEL_CROP_WARNING: {
      return {
        ...state,
        showCropWarning: false
      };
    }

    default:
      return state;
  }
}
