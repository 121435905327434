const rootEl = document.getElementById('root');

// macOS pinch zoom gesture
window.addEventListener('gesturestart', e => e.preventDefault());
window.addEventListener('gesturechange', e => e.preventDefault());
window.addEventListener('gestureend', e => e.preventDefault());

rootEl.addEventListener('gesturestart', e => e.preventDefault());
rootEl.addEventListener('gesturechange', e => e.preventDefault());
rootEl.addEventListener('gestureend', e => e.preventDefault());
