import React from 'react';

export default function IconAnimationBounceIn({ flipImage }) {
  const style = {
    enableBackground: 'new 0 0 59.4 60',
    transform: flipImage ? 'scale(-1, 1)' : undefined
  };

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="59.4px"
      height="60px"
      viewBox="0 0 59.4 60"
      style={style}
    >
      <path
        d="M42.7,14.5c-3.7,0-6.8,2.9-6.9,6.6c-5.4,2.2-8.6,7.4-10.3,12.6c-2-6.9-6.6-13.8-15.7-13.8l0,3c11.8,0.1,13.7,14.2,14,18.9
	h-8v3H35v-3h-8c0.3-3.9,1.9-14.2,9.3-17.6c1.1,2.5,3.5,4.3,6.4,4.3c3.8,0,7-3.1,7-7S46.5,14.5,42.7,14.5z M42.7,25.4
	c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S44.9,25.4,42.7,25.4z"
      />
    </svg>
  );
}
