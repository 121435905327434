import { createInstance, setLogLevel, ReactSDKClient, enums } from '@optimizely/react-sdk';
import config from 'js/config/config';

let optimizely: ReactSDKClient;

export default function getOptimizelyInstance(): ReactSDKClient {
  if (optimizely) return optimizely;

  const { OPTIMIZELY_SDK_KEY, OPTIMIZELY_LOG_LEVEL } = config;

  optimizely = createInstance({
    sdkKey: OPTIMIZELY_SDK_KEY
  });
  setLogLevel(OPTIMIZELY_LOG_LEVEL ?? enums.LOG_LEVEL.ERROR);
  return optimizely;
}
