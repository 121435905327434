import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import EditPanelButton from '../EditPanelButton';
import EditPanelButtonGroup from '../EditPanelButtonGroup';

import OptionMenuButton from './OptionMenuButton';

import './HandMenu.css';

const TOOLTIP_ID = 'hand-tooltip';

const getSelectedCursorGroup = ({ selectedCursorId, hands, defaultCursorId, defaultCursorGroup }) => {
  const selectedCursorGroup =
    selectedCursorId === defaultCursorId
      ? defaultCursorGroup
      : (hands.find(hand => hand.id === selectedCursorId)?.group.toLowerCase() ?? 'all');
  return selectedCursorGroup;
};

const HandMenu = ({
  onSelection,
  hands,
  selectedCursorId,
  defaultCursorId,
  defaultCursorGroup,
  filters,
  onFilterChange = _filterName => {}
}) => {
  const selectedCursorGroup = getSelectedCursorGroup({ selectedCursorId, hands, defaultCursorId, defaultCursorGroup });

  const [selectedFilter, setSelectedFilter] = useState(selectedCursorGroup);

  const handleButtonClick = cursorId => {
    onSelection(cursorId);
  };

  const handleFilterButtonClick = filter => {
    onFilterChange(filter);
    setSelectedFilter(filter);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectedFilter]);

  return (
    <div className="HandMenu">
      <div>
        <EditPanelButtonGroup className="HandMenu__filters">
          {filters.map(filter => {
            const CssClassNames = classNames('HandMenu__filtersButton', {
              'HandMenu__filtersButton--selected': selectedFilter === filter.toLowerCase()
            });
            return (
              <EditPanelButton
                id={`hands-subgroup-selector-${filter.toLowerCase()}`}
                key={`hands-subgroup-selector-${filter.toLowerCase()}`}
                className={CssClassNames}
                onClick={() => handleFilterButtonClick(filter.toLowerCase())}
              >
                {filter}
              </EditPanelButton>
            );
          })}
        </EditPanelButtonGroup>
      </div>
      <div className="HandMenu__grid">
        {hands
          .filter(hand => selectedFilter === 'all' || hand.group.toLowerCase() === selectedFilter)
          .map(hand => {
            return (
              <OptionMenuButton
                key={hand.id}
                selected={hand.id === selectedCursorId}
                onClick={() => handleButtonClick(hand.id)}
                tooltipProps={{
                  'data-tip': hand.name,
                  'data-for': TOOLTIP_ID
                }}
              >
                <div
                  className="HandMenu__buttonIcon"
                  style={{ backgroundImage: `url(${hand.resources.thumbnail.url})` }}
                />
              </OptionMenuButton>
            );
          })}
      </div>
      <ReactTooltip id={TOOLTIP_ID} className="t-utility-label" />
    </div>
  );
};

export default HandMenu;
