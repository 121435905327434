import {
  AudioConfig,
  AudioSource,
  AudioActionType,
  AudioTabType,
  AudioType,
  EditableAudioClipMetadata,
  VSCAssetAudioData,
  AudioClip,
  AdvancedAudioType
} from 'js/types';

export const SAVE_AUDIO = 'SAVE_AUDIO';
export const SAVE_AUDIO_SUCCESS = 'SAVE_AUDIO_SUCCESS';
export const SAVE_AUDIO_FAILED = 'SAVE_AUDIO_FAILED';
export const DELETE_AUDIO_ASSET = 'DELETE_AUDIO_ASSET';
export const DELETE_AUDIO_FROM_PROJECT = 'DELETE_AUDIO_FROM_PROJECT';
export const UPDATE_AUDIO_CONFIG = 'UPDATE_AUDIO_CONFIG';
export const SPLIT_AUDIO_TRACK = 'SPLIT_AUDIO_TRACK';
export const UPDATE_AUDIO_LAYER = 'UPDATE_AUDIO_LAYER';
export const DELETE_AUDIO_LAYER = 'DELETE_AUDIO_LAYER';
export const GET_USER_AUDIO_REQUESTED = 'GET_USER_AUDIO_REQUESTED';
export const GET_USER_AUDIO_FAILURE = 'GET_USER_AUDIO_FAILURE';
export const GET_USER_AUDIO_SUCCESS = 'GET_USER_AUDIO_SUCCESS';
export const SAVE_AUDIO_TO_LIBRARY = 'SAVE_AUDIO_TO_LIBRARY';
export const SAVE_AUDIO_TO_LIBRARY_IN_PROGRESS = 'SAVE_AUDIO_TO_LIBRARY_IN_PROGRESS';
export const SAVE_AUDIO_TO_LIBRARY_CANCELLED = 'SAVE_AUDIO_TO_LIBRARY_CANCELLED';
export const SAVE_AUDIO_TO_LIBRARY_FAILED = 'SAVE_AUDIO_TO_LIBRARY_FAILED';
export const SAVE_AUDIO_TO_LIBRARY_SUCCESS = 'SAVE_AUDIO_TO_LIBRARY_SUCCESS';
export const SAVE_AUDIO_ASSET_METADATA = 'SAVE_AUDIO_ASSET_METADATA';
export const SAVE_AUDIO_ASSET_METADATA_FAILED = 'SAVE_AUDIO_ASSET_METADATA_FAILED';
export const SAVE_AUDIO_ASSET_METADATA_SUCCESS = 'SAVE_AUDIO_ASSET_METADATA_SUCCESS';
export const USE_AUDIO_ASSET_IN_PROJECT = 'USE_AUDIO_ASSET_IN_PROJECT';
export const USE_AUDIO_ASSET_IN_PROJECT_SUCCESS = 'USE_AUDIO_ASSET_IN_PROJECT_SUCCESS';
export const OPEN_AUDIO_MODAL = 'OPEN_AUDIO_MODAL';
export const CLOSE_AUDIO_MODAL = 'CLOSE_AUDIO_MODAL';
export const OPEN_DELETE_AUDIO_ASSET_MODAL = 'OPEN_DELETE_AUDIO_ASSET_MODAL';
export const CLOSE_DELETE_AUDIO_ASSET_MODAL = 'CLOSE_DELETE_AUDIO_ASSET_MODAL';
export const AUDIO_CLIP_LIMIT_REACHED = 'AUDIO_CLIP_LIMIT_REACHED';
export const CONFIRM_AUDIO_SELECTION = 'CONFIRM_AUDIO_SELECTION';
export const CANCEL_AUDIO_SELECTION = 'CANCEL_AUDIO_SELECTION';

export type DeleteAudioActionTrigger = 'Left Hand Panel' | 'Right Hand Panel' | 'Context Menu';
export interface DeleteAudioAction {
  type: typeof DELETE_AUDIO_FROM_PROJECT;
  scribeId: number;
  id: string;
  eventTrigger: DeleteAudioActionTrigger;
}

export const deleteAudioFromProject = (
  scribeId: number,
  id: string,
  eventTrigger: DeleteAudioActionTrigger
): DeleteAudioAction => ({
  type: DELETE_AUDIO_FROM_PROJECT,
  scribeId,
  id,
  eventTrigger
});

export interface DeleteAudioAssetAction {
  type: typeof DELETE_AUDIO_ASSET;
}

export const deleteAudioAsset = (): DeleteAudioAssetAction => ({
  type: DELETE_AUDIO_ASSET
});

export interface DeleteAudioLayerAction {
  type: typeof DELETE_AUDIO_LAYER;
  scribeId: number;
  layerId: string;
}

export const deleteAudioLayerAction = (scribeId: number, layerId: string): DeleteAudioLayerAction => ({
  type: DELETE_AUDIO_LAYER,
  scribeId,
  layerId
});

export interface UpdateAudioLayerAction {
  type: typeof UPDATE_AUDIO_LAYER;
  scribeId: number;
  layerId: string;
  audioClipId: string;
  audioType: AdvancedAudioType;
}

export const updateAudioLayer = (
  scribeId: number,
  layerId: string,
  audioClipId: string,
  audioType: AdvancedAudioType
): UpdateAudioLayerAction => ({
  type: UPDATE_AUDIO_LAYER,
  scribeId,
  layerId,
  audioClipId,
  audioType
});

export interface UpdateAudioConfigAction {
  type: typeof UPDATE_AUDIO_CONFIG;
  scribeId: number;
  id?: string;
  config: AudioConfig;
}
export type UpdateAudioProperties = Omit<UpdateAudioConfigAction, 'type'>;
export const updateAudioConfig = ({ scribeId, id, config }: UpdateAudioProperties): UpdateAudioConfigAction => ({
  type: UPDATE_AUDIO_CONFIG,
  scribeId,
  id,
  config
});

export interface SplitAudioTrackAction {
  type: typeof SPLIT_AUDIO_TRACK;
  scribeId: number;
  id: string;
  splitTime: number;
}
export type SplitAudioTrackProperties = Omit<SplitAudioTrackAction, 'type'>;
export const splitAudioTrack = ({ scribeId, id, splitTime }: SplitAudioTrackProperties): SplitAudioTrackAction => ({
  type: SPLIT_AUDIO_TRACK,
  scribeId,
  id,
  splitTime
});

export interface SaveAudioAction {
  type: typeof SAVE_AUDIO;
  audio: File;
  scribeId: number;
  source: AudioSource;
  trackLength?: number;
  audioType: AudioType;
  volume: number;
  fileName: string;
}

export type SaveAudioProperties = Omit<SaveAudioAction, 'type'>;

export const saveAudio = ({
  audio,
  scribeId,
  source,
  trackLength,
  audioType,
  volume = 1,
  fileName
}: SaveAudioProperties): SaveAudioAction => ({
  type: SAVE_AUDIO,
  audio,
  scribeId,
  source,
  trackLength,
  audioType,
  volume,
  fileName
});

export interface SaveAudioSuccessAction {
  type: typeof SAVE_AUDIO_SUCCESS;
  audioType: AudioType;
  assetId: number;
}

export const saveAudioSuccess = (audioType: AudioType, assetId: number): SaveAudioSuccessAction => ({
  type: SAVE_AUDIO_SUCCESS,
  audioType,
  assetId
});

export interface SaveAudioFailedAction {
  type: typeof SAVE_AUDIO_FAILED;
  audioType: AudioType;
}

export const saveAudioFailed = (audioType: AudioType): SaveAudioFailedAction => ({
  type: SAVE_AUDIO_FAILED,
  audioType
});
export interface GetUserAudioFailureAction {
  type: typeof GET_USER_AUDIO_FAILURE;
  error: Error;
}

export const getUserAudio = () => ({
  type: GET_USER_AUDIO_REQUESTED
});

export const getUserAudioFailure = (error: Error): GetUserAudioFailureAction => ({
  type: GET_USER_AUDIO_FAILURE,
  error
});

export interface GetUserAudioSuccessAction {
  type: typeof GET_USER_AUDIO_SUCCESS;
  payload: Array<VSCAssetAudioData>;
}

export const getUserAudioSuccess = (payload: Array<VSCAssetAudioData>): GetUserAudioSuccessAction => ({
  type: GET_USER_AUDIO_SUCCESS,
  payload
});

export interface SaveAudioToLibraryAction {
  type: typeof SAVE_AUDIO_TO_LIBRARY;
  file: File;
  source: AudioSource;
}

export const saveAudioToLibrary = (file: File, source: AudioSource): SaveAudioToLibraryAction => ({
  type: SAVE_AUDIO_TO_LIBRARY,
  file,
  source
});

export interface SaveAudioToLibraryFailedAction {
  type: typeof SAVE_AUDIO_TO_LIBRARY_FAILED;
  error: Error;
}

export const saveAudioToLibraryFailed = (error: Error): SaveAudioToLibraryFailedAction => ({
  type: SAVE_AUDIO_TO_LIBRARY_FAILED,
  error
});

export interface SaveAudioToLibrarySuccessAction {
  type: typeof SAVE_AUDIO_TO_LIBRARY_SUCCESS;
  audioClipData: VSCAssetAudioData;
  source: AudioSource | AdvancedAudioType;
}

export const saveAudioToLibrarySuccess = (
  audioClipData: VSCAssetAudioData,
  source: AudioSource | AdvancedAudioType
): SaveAudioToLibrarySuccessAction => ({
  type: SAVE_AUDIO_TO_LIBRARY_SUCCESS,
  audioClipData,
  source
});

export interface SaveAudioAssetMetadataAction {
  type: typeof SAVE_AUDIO_ASSET_METADATA;
  assetId: number;
  data: EditableAudioClipMetadata;
  source?: AudioSource;
  useItInProjectId?: number;
}

export const saveAudioAssetMetadata = ({
  assetId,
  data,
  source,
  useItInProjectId
}: {
  assetId: number;
  data: EditableAudioClipMetadata;
  source?: AudioSource;
  useItInProjectId?: number;
}) => ({
  type: SAVE_AUDIO_ASSET_METADATA,
  assetId,
  data,
  useItInProjectId,
  source
});

export interface SaveAudioAssetMetadataFailedAction {
  type: typeof SAVE_AUDIO_ASSET_METADATA_FAILED;
  error: Error;
}

export const saveAudioAssetMetadataFailed = (error: Error): SaveAudioAssetMetadataFailedAction => ({
  type: SAVE_AUDIO_ASSET_METADATA_FAILED,
  error
});

export interface SaveAudioAssetMetadataSuccessAction {
  type: typeof SAVE_AUDIO_ASSET_METADATA_SUCCESS;
  audioClipData: VSCAssetAudioData;
}

export const saveAudioAssetMetadataSuccess = (
  audioClipData: VSCAssetAudioData
): SaveAudioAssetMetadataSuccessAction => ({
  type: SAVE_AUDIO_ASSET_METADATA_SUCCESS,
  audioClipData
});

type UseAudioAssetInProjectActionTriggerType = 'Audio Library' | 'Your Files' | 'AI LHP';

export interface UseAudioAssetInProjectAction {
  type: typeof USE_AUDIO_ASSET_IN_PROJECT;
  projectId: number;
  asset: VSCAssetAudioData;
  audioType: AdvancedAudioType;
  eventTrigger?: UseAudioAssetInProjectActionTriggerType;
}

export const useAudioAssetInProject = (
  projectId: number,
  asset: VSCAssetAudioData,
  audioType: AdvancedAudioType,
  eventTrigger?: UseAudioAssetInProjectActionTriggerType
): UseAudioAssetInProjectAction => ({
  type: USE_AUDIO_ASSET_IN_PROJECT,
  projectId,
  asset,
  audioType,
  eventTrigger
});

export interface UseAudioAssetInProjectSuccessAction {
  type: typeof USE_AUDIO_ASSET_IN_PROJECT_SUCCESS;
}

export const useAudioAssetInProjectSuccess = () => ({
  type: USE_AUDIO_ASSET_IN_PROJECT_SUCCESS
});

type OpenAudioModalActionTriggerType =
  | 'LHP Text Button'
  | 'LHP Icon Button'
  | 'Modal Tab Heading'
  | 'RHP Replace Audio Button';
export interface OpenAudioModalAction {
  type: typeof OPEN_AUDIO_MODAL;
  tab: AudioTabType;
  actionTrigger?: OpenAudioModalActionTriggerType;
  initialAction?: AudioActionType;
  selectedAudioClip?: AudioClip;
}

export const openAudioModal = (
  tab: AudioTabType,
  actionTrigger?: OpenAudioModalActionTriggerType,
  initialAction?: AudioActionType,
  selectedAudioClip?: AudioClip
): OpenAudioModalAction => ({
  type: OPEN_AUDIO_MODAL,
  tab,
  actionTrigger,
  initialAction,
  selectedAudioClip
});

export interface CloseAudioModalAction {
  type: typeof CLOSE_AUDIO_MODAL;
}

export const closeAudioModal = (): CloseAudioModalAction => ({
  type: CLOSE_AUDIO_MODAL
});

export interface OpenDeleteAudioAssetModalAction {
  type: typeof OPEN_DELETE_AUDIO_ASSET_MODAL;
  assetId: number | null;
}

export const openDeleteAudioAssetModal = (assetId: number | null): OpenDeleteAudioAssetModalAction => ({
  type: OPEN_DELETE_AUDIO_ASSET_MODAL,
  assetId
});

export interface CloseDeleteAudioAssetModalAction {
  type: typeof CLOSE_DELETE_AUDIO_ASSET_MODAL;
}

export const closeDeleteAudioAssetModal = (): CloseDeleteAudioAssetModalAction => ({
  type: CLOSE_DELETE_AUDIO_ASSET_MODAL
});

export interface AudioClipLimitReachedAction {
  type: typeof AUDIO_CLIP_LIMIT_REACHED;
  projectId: number;
  incomingAsset: VSCAssetAudioData;
}

export const audioClipLimitReached = (
  projectId: number,
  incomingAsset: VSCAssetAudioData
): AudioClipLimitReachedAction => ({
  type: AUDIO_CLIP_LIMIT_REACHED,
  projectId,
  incomingAsset
});

export interface ConfirmAudioSelectionAction {
  type: typeof CONFIRM_AUDIO_SELECTION;
  projectId: number;
  audioClipIdsToKeep: Array<number>;
}

export const confirmAudioSelection = (projectId: number, audioClipIdsToKeep: Array<number>) => ({
  type: CONFIRM_AUDIO_SELECTION,
  projectId,
  audioClipIdsToKeep
});

export interface CancelAudioSelectionAction {
  type: typeof CANCEL_AUDIO_SELECTION;
}

export const cancelAudioSelection = (): CancelAudioSelectionAction => ({
  type: CANCEL_AUDIO_SELECTION
});

export interface SaveAudioToLibraryInProgressAction {
  type: typeof SAVE_AUDIO_TO_LIBRARY_IN_PROGRESS;
  abortController: AbortController;
}

export const saveAudioToLibraryInProgress = (abortController: AbortController): SaveAudioToLibraryInProgressAction => ({
  type: SAVE_AUDIO_TO_LIBRARY_IN_PROGRESS,
  abortController
});

export interface SaveAudioToLibraryCancelledAction {
  type: typeof SAVE_AUDIO_TO_LIBRARY_CANCELLED;
}

export const saveAudioToLibraryCancelled = () => ({
  type: SAVE_AUDIO_TO_LIBRARY_CANCELLED
});
