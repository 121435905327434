import { fabric } from 'fabric';
import { DirectionType } from 'js/types';

export interface GetGradientProps {
  type: DirectionType;
  width: number;
  height: number;
  from: string;
  to: string;
}

const getGradient = ({ type, width, height, from, to }: GetGradientProps) => {
  return new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels',
    coords: {
      x1: 0,
      y1: 0,
      x2: type === 'vertical' ? 0 : width,
      y2: type === 'vertical' ? height : 0
    },
    colorStops: [
      { color: from, offset: 0 },
      { color: to, offset: 1 }
    ]
  });
};

export default getGradient;
