import React, { useRef } from 'react';
import './QuickSearchCarousel.css';
import { IconChevron } from '@sparkol/videoscribe-react-ui';
import classNames from 'classnames';
import { useCarousel } from 'js/shared/hooks/useCarousel';

interface CarouselProps {
  children: React.ReactNode;
}

const QuickSearchCarousel = ({ children }: CarouselProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const { handleScrollLeft, handleScrollRight, leftOverflowing, rightOverflowing } = useCarousel(
    children,
    sliderRef,
    trackRef,
    undefined,
    undefined,
    undefined,
    10
  );

  const wrapperClassNames = classNames('QuickSearchCarousel__sliderWrapper');
  const sliderClassNames = classNames('QuickSearchCarousel__slider');

  const overflowShadowLeftClasses = classNames(
    'QuickSearchCarousel__overflowShadow',
    'QuickSearchCarousel__overflowShadow--left',
    {
      'QuickSearchCarousel__overflowShadow--visible': leftOverflowing
    }
  );
  const overflowShadowRightClasses = classNames(
    'QuickSearchCarousel__overflowShadow',
    'QuickSearchCarousel__overflowShadow--right',
    {
      'QuickSearchCarousel__overflowShadow--visible': rightOverflowing
    }
  );

  const prevArrowButtonClasses = classNames(
    'QuickSearchCarousel__arrowButton',
    'QuickSearchCarousel__arrowButton--prev'
  );
  const nextArrowButtonClasses = classNames(
    'QuickSearchCarousel__arrowButton',
    'QuickSearchCarousel__arrowButton--next'
  );

  const prevArrowIconClasses = classNames('QuickSearchCarousel__arrowIcon', 'QuickSearchCarousel__arrowIcon--prev');
  const nextArrowIconClasses = classNames('QuickSearchCarousel__arrowIcon');

  return (
    <div className={wrapperClassNames}>
      <div className={overflowShadowLeftClasses} role="presentation" aria-hidden></div>

      <button className={prevArrowButtonClasses} onClick={handleScrollLeft} disabled={!leftOverflowing}>
        <IconChevron className={prevArrowIconClasses} />
      </button>

      <div ref={sliderRef} className={sliderClassNames}>
        <div className="QuickSearchCarousel__track" ref={trackRef}>
          {children}
        </div>
      </div>

      <div className={overflowShadowRightClasses} role="presentation" aria-hidden></div>

      <button className={nextArrowButtonClasses} onClick={handleScrollRight} disabled={!rightOverflowing}>
        <IconChevron className={nextArrowIconClasses} />
      </button>
    </div>
  );
};

export default QuickSearchCarousel;
