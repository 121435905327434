import getSvgDefaultAnimationTimingFromFile from 'js/shared/helpers/getSvgDefaultAnimationTimingFromFile';

import fitImageToCanvas from './fitImageToCanvas';

export default async function getCanvasAttributeFromFile({ file, canvasSize }) {
  const fileObjectUrl = URL.createObjectURL(file);
  const { scaleX, scaleY, x, y, width, height } = await fitImageToCanvas(fileObjectUrl, file.type, canvasSize);
  const animationTime = await getSvgDefaultAnimationTimingFromFile(file, width, height);

  return {
    animationTime,
    scaleX,
    scaleY,
    x,
    y,
    width,
    height,
    _imageUrl: fileObjectUrl
  };
}
