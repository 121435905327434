import * as PIXI from 'pixi.js';
import { ENTRANCE_TWEEN_DOCUMENT_KEY } from 'js/config/consts';

import generateScribblePathArray from '../helpers/generateScribblePathArray';
import applyElementPositions from '../helpers/applyElementPositions';
import setupBasicMask from '../helpers/setupBasicMask';
import { hexColorToPixiColor } from '../../../../shared/helpers/pixi';
import { MASK_LINE_COLOR } from '../../../../config/defaults';
import { addGradient } from '../helpers/pixiGradient';
import drawEllipse from '../helpers/drawEllipse';

import AnimatableElement from './AnimatableElement';

class VSShapeElement extends AnimatableElement {
  constructor({
    element,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    timings,
    playerRef,
    scene
  }) {
    if (!element) {
      throw new Error('No element object supplied to VSShapeElement');
    }
    super({
      element,
      playerRef,
      animationTime,
      emphasisAnimationTime,
      emphasisAnimationLoops,
      exitAnimationTime,
      pauseTime,
      timings,
      scene
    });

    const { scribbleArray, brushSize } = generateScribblePathArray(element.width, element.height);

    this.maskPathPoints = scribbleArray;
    this.brushSize = brushSize;
    this.maskLineStyleConfig = {
      width: this.brushSize,
      color: MASK_LINE_COLOR,
      cap: PIXI.LINE_CAP.ROUND,
      join: PIXI.LINE_JOIN.ROUND
    };
    this.mask = setupBasicMask(this.maskLineStyleConfig, this.maskPathPoints[0]);
    this.mask.name = 'Reveal Mask';
    this.stageElement = this.createStageElement(element, this.mask, this.cursor, { x: 0.5, y: 1 });
    this.scaleHolder = this.stageElement.getChildByName('Scale Holder');

    if (element[ENTRANCE_TWEEN_DOCUMENT_KEY]) {
      this.clearMasksFromStage();
      if (!this.hasExitMask) {
        this.stageElement.mask = null;
      }

      this.clearCursorFromCanvas();
    }
    this.animationTime = animationTime;
    this.pauseTime = pauseTime;

    this.lastDrawTo = 0;
    this.lastPercentProgress = -1;
  }

  createStageElement = (element, mask, cursor) => {
    const {
      fill,
      width,
      height,
      opacity,
      backgroundType,
      backgroundColorFrom,
      backgroundColorTo,
      backgroundGradientType
    } = element;

    const sprite = new PIXI.Graphics();
    sprite.beginTextureFill({
      alpha: opacity ?? 1,
      color: hexColorToPixiColor(fill)
    });

    if (backgroundType === 'gradient') {
      sprite.beginTextureFill({
        alpha: opacity ?? 1,
        texture: addGradient(backgroundColorFrom, backgroundColorTo, width, height, backgroundGradientType)
      });
    }

    switch (element.shapeType) {
      case 'ellipse': // We no longer offer ellipse as a specific shape type but we should leave this in for existing documents
        drawEllipse(sprite, width, height);
        break;
      case 'square':
        sprite.drawRect(0, 0, width, height);
        break;
      case 'circle':
        sprite.drawCircle(width / 2, width / 2, width / 2);
        break;
      case 'triangle':
        sprite.drawPolygon([
          { x: width / 2, y: 0 },
          { x: width, y: height },
          { x: 0, y: height }
        ]);
        break;
      default:
        break;
    }

    sprite.endFill();
    const nestedClips = this.setUpNestedClips(sprite);
    const stageElement = applyElementPositions(nestedClips, element, this.scaleHolder);
    this.scaleHolder.mask = mask;
    this.scaleHolder.addChild(mask, cursor);

    return stageElement;
  };

  fillRevealMasks = () => {
    this.scaleHolder.mask = null;
    this.mask.visible = false;
  };

  revealAnimation(percentProgress) {
    const shouldRevealWhole = !this.shouldAnimateReveal || percentProgress >= 1;
    if (shouldRevealWhole) {
      this.fillRevealMasks();
      return;
    }

    if (percentProgress < 1) {
      this.scaleHolder.mask = this.mask;
      this.mask.visible = true;
    }

    const hasGoneBackInTime = this.lastPercentProgress > percentProgress;
    if (hasGoneBackInTime) {
      this.mask.clear();
      this.mask.lineStyle(this.maskLineStyleConfig);
      this.lastDrawTo = 0;
    }

    const length = this.maskPathPoints.length;
    const drawTo = Math.floor(length * percentProgress);
    const drawPathSegment = this.maskPathPoints.slice(this.lastDrawTo, drawTo);

    if (drawPathSegment.length) {
      this.mask.moveTo(drawPathSegment[0].x, drawPathSegment[0].y);
      // Only draw the segment from the last update to this update
      drawPathSegment.forEach(point => {
        this.mask.lineTo(point.x, point.y);
      });

      // Update cursor position to the last point in the segment
      const lastPoint = drawPathSegment[drawPathSegment.length - 1];
      this.cursor.x = (lastPoint && lastPoint.x) || 0;
      this.cursor.y = (lastPoint && lastPoint.y) || 0;
    }

    this.lastPercentProgress = percentProgress;
    this.lastDrawTo = drawTo;
  }

  clearMasksFromStage() {
    super.clearMasksFromStage();
    this.fillRevealMasks();
  }
}

export default VSShapeElement;
