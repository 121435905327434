export const CHECK_UPGRADE = 'CHECK_UPGRADE';
export const CHECKING_UPGRADE = 'CHECKING_UPGRADE';
export const CHECKING_UPGRADE_COMPLETE = 'CHECKING_UPGRADE_COMPLETE';

export interface CheckingUpgradeAction {
  type: typeof CHECKING_UPGRADE;
}
export const checkingUpgrade = () => ({
  type: CHECKING_UPGRADE
});

export interface CheckingUpgradeCompleteAction {
  type: typeof CHECKING_UPGRADE_COMPLETE;
  isTeamsPurchase: boolean;
}
export const checkingUpgradeComplete = ({ isTeamsPurchase }: Omit<CheckingUpgradeCompleteAction, 'type'>) => ({
  type: CHECKING_UPGRADE_COMPLETE,
  isTeamsPurchase
});

export interface CheckUpgradeAction {
  type: typeof CHECK_UPGRADE;
  userId: string;
  newTier?: string;
}
export const checkUpgrade = ({ userId, newTier }: Omit<CheckUpgradeAction, 'type'>) => ({
  type: CHECK_UPGRADE,
  userId,
  newTier
});
