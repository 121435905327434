import { call, put, takeEvery, CallEffect, PutEffect, SelectEffect, select } from 'redux-saga/effects';
import { getImageLibrary, searchImageLibrary } from 'js/shared/providers/ImageLibrary';
import { ImageLibraryCategorySearch, ImageCategory, ImageLibraryInputSearch } from 'js/types';
import { showError } from 'js/actionCreators/uiActions';
import { MAX_IMAGE_SEARCH_INPUT_LENGTH } from 'js/editor/ImageDrawer/ImageLibrary';

import {
  GetCategoryImagesAction,
  GET_CATEGORY_IMAGES,
  getCategoryImagesSuccess,
  getCategoryImagesFailure,
  GET_SEARCH_IMAGES,
  GetSearchImagesAction,
  getSearchImagesSuccess,
  getSearchImagesFailure,
  SetQuickImageSearchTagAction,
  getSearchImages,
  SET_QUICK_IMAGE_SEARCH_TAG
} from '../actionCreators/ImageDrawerActions';

import { getImageCategories } from './selectors';

function* handleGetCategoryImages({
  categoryId,
  imageSource,
  nextPageId
}: GetCategoryImagesAction): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  ImageLibraryCategorySearch | Array<ImageCategory>
> {
  try {
    const imageCategories = (yield select(getImageCategories)) as Array<ImageCategory>;
    const category = imageCategories.find(category => category.id === categoryId);
    if (!category) throw new Error('Category not found');

    if (category.editorName === 'GIFs' && imageSource === 'noun-project') {
      yield put(getCategoryImagesSuccess(categoryId, imageSource, [], null));
      return;
    }

    const { images, cursor } = (yield call(
      getImageLibrary,
      categoryId,
      imageSource,
      nextPageId
    )) as ImageLibraryCategorySearch;
    yield put(getCategoryImagesSuccess(categoryId, imageSource, images, cursor ?? null));
  } catch (err) {
    const error = err as Error;
    yield put(getCategoryImagesFailure(categoryId, imageSource));
    yield put(showError({ message: 'Failed getting images, please try again later', description: error.message }));
  }
}

function* handleSetQuickImageSearchTag({
  tag
}: SetQuickImageSearchTagAction): Generator<CallEffect | PutEffect, void, ImageLibraryInputSearch> {
  if (!tag) return;
  yield put(getSearchImages('videoscribe', tag, 0, undefined, false));
}

function* handleSearchImages({
  searchInput,
  imageSource,
  nextPageId,
  offset,
  isPrefetch = false
}: GetSearchImagesAction): Generator<CallEffect | PutEffect, void, ImageLibraryInputSearch> {
  try {
    const sanitizedSearchInput = searchInput.substring(0, MAX_IMAGE_SEARCH_INPUT_LENGTH);
    const results = yield call(searchImageLibrary, imageSource, sanitizedSearchInput, offset, nextPageId);
    const images = results.data.length ? results.data[0].images : [];

    yield put(
      getSearchImagesSuccess(
        imageSource,
        images,
        offset + results.meta.count, // don't use results.meta.offset, as it is not returning the correct value for noun-project searches
        results.meta.total,
        sanitizedSearchInput,
        isPrefetch,
        results.meta.next
      )
    );
  } catch (err) {
    const error = err as Error;
    yield put(getSearchImagesFailure(imageSource, searchInput));
    yield put(showError({ message: 'Failed searching images, please try again later', description: error.message }));
  }
}

function* imageDrawerSagas() {
  yield takeEvery(GET_CATEGORY_IMAGES, handleGetCategoryImages);
  yield takeEvery(GET_SEARCH_IMAGES, handleSearchImages);
  yield takeEvery(SET_QUICK_IMAGE_SEARCH_TAG, handleSetQuickImageSearchTag);
}

export default imageDrawerSagas;
