import React from 'react';

const IconSceneDrag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="90px"
    height="60px"
    viewBox="0 0 90 60"
    enableBackground="new 0 0 90 60"
    fill="currentcolor"
  >
    <path d="M76.5,8h-63C11,8,9,10.1,9,12.5v35.9c0,2.5,2.1,4.5,4.5,4.5h4.3h2.2h2.2V42.4c0.2-0.6,0.6-2.1,2.2-2.1  c1.8,0,2.2,1.5,2.4,2.2v4.8c0,1.2,1.1,2.2,2.2,2.2s2.2-1.1,2.2-2.2V19.4c0-1.5,1.2-2.7,2.7-2.7c1.2,0,2.1,0.9,2.1,2.1v20.8  c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2v-24c0-1.5,1.2-2.8,2.8-2.8c1.2,0,2.1,0.9,2.1,2.1v25.4c0,1.2,1.1,2.2,2.2,2.2  s2.3-1.1,2.3-2.2V17.9c0-1.2,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1v22.3c0,1.2,1.1,2.2,2.2,2.2s2.2-1.1,2.2-2.2V25.1  c0-1.2,0.9-2.1,2.1-2.1h0.4c1.2,0,2.1,0.9,2.1,2.1V53h2.3h2.2h8.4c2.6,0,4.5-2.1,4.5-4.5v-36C81,10.1,78.9,8,76.5,8z" />
  </svg>
);

export default IconSceneDrag;
