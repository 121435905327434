import * as PIXI from 'pixi.js';
import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import clamp from 'lodash.clamp';
import { setActiveScene } from 'js/actionCreators/scribeActions';
import { getStore } from 'js/store';

import {
  TIMELINE_TOOLTIP_BACKGROUND_COLOUR,
  TIMELINE_TOOLTIP_BORDER_COLOUR,
  TIMELINE_TOOLTIP_TEXT_COLOUR
} from './TimelineLayerTooltip';
import { LEFT_ZOOM_MARGIN } from './consts';
import { getBitmapRect } from './Utils/GetBitmapRect';
import TimelineWrapper from './TimelineWrapper';

export const LABEL_WIDTH = 60;
export const LABEL_HEIGHT = 20;
export const BANNER_HEIGHT = 25;
export const ACTIVE_SCENE_COLOUR = 0x1ca3c7;

export default class SceneLabels extends PIXI.Container {
  scrollBoxWidth: number;
  scrollBoxHeight: number;
  labelContent: PIXI.Container;

  activeConstraintGraphics: PIXI.Graphics;
  bgGraphics: PIXI.Graphics;
  maskClip: PIXI.Graphics;

  currentSceneId: string;
  wrapperRef: TimelineWrapper;

  constructor(scrollBoxWidth: number, wrapperRef: TimelineWrapper, initialX: number, currentSceneId: string) {
    super();
    this.wrapperRef = wrapperRef;
    this.scrollBoxWidth = scrollBoxWidth;
    this.scrollBoxHeight = 20;

    this.currentSceneId = currentSceneId;
    this.maskClip = getBitmapRect(100, 0xff0000);

    this.bgGraphics = getBitmapRect(100, TIMELINE_TOOLTIP_BACKGROUND_COLOUR);
    this.activeConstraintGraphics = new PIXI.Graphics();
    this.labelContent = new PIXI.Container();
    this.labelContent.addChild(this.activeConstraintGraphics);
    this.addChild(this.bgGraphics);
    this.draw();
    this.addChild(this.labelContent);
    this.labelContent.x = initialX - LEFT_ZOOM_MARGIN;
    this.addChild(this.maskClip);
    this.labelContent.mask = this.maskClip;
  }
  get scribe() {
    return this.wrapperRef.props.activeScribe;
  }
  get scenes() {
    return this.scribe.scenes;
  }
  get currentZoom() {
    return this.wrapperRef.currentZoom;
  }

  getSceneClip = (sceneId: string) => {
    const sceneLabel = this.labelContent.getChildByName(sceneId) as PIXI.Sprite;
    return sceneLabel;
  };

  getCurrentSceneClip = () => {
    return this.getSceneClip(this.currentSceneId);
  };

  createLabel = (index: number, isActiveScene: boolean): PIXI.Sprite => {
    const label = new PIXI.Sprite();
    const background = this.drawLabelBackground(isActiveScene);
    label.addChild(background);
    const text = new PIXI.Text('Scene ' + (index + 1), {
      fontFamily: 'Effra',
      fontSize: 11,
      fill: isActiveScene ? TIMELINE_TOOLTIP_TEXT_COLOUR : TIMELINE_TOOLTIP_BORDER_COLOUR,
      align: 'left',
      fontWeight: '700'
    });
    text.x = (background.width - text.width) / 2;
    text.y = (background.height - text.height) / 2;
    label.addChild(text);

    return label;
  };

  drawLabelBackground = (isActiveScene: boolean): PIXI.Graphics => {
    const x = isActiveScene ? 0 : 1;
    const y = 0;
    const width = LABEL_WIDTH;
    const height = LABEL_HEIGHT;
    const radius = 5;
    const graphics = new PIXI.Graphics();
    graphics.beginFill(TIMELINE_TOOLTIP_BACKGROUND_COLOUR);
    graphics.lineStyle(1, isActiveScene ? ACTIVE_SCENE_COLOUR : TIMELINE_TOOLTIP_BORDER_COLOUR);

    graphics.moveTo(x, y);
    graphics.lineTo(x + width, y);
    graphics.lineTo(x + width, y + height - radius);
    graphics.arc(x + width - radius, y + height - radius, radius, 0, 0.5 * Math.PI);
    graphics.lineTo(x, y + height);
    graphics.lineTo(x, y);

    graphics.endFill();
    return graphics;
  };
  destroy(options?: PIXI.IDestroyOptions | boolean): void {
    this.bgGraphics.destroy(options);
    this.maskClip.destroy();
    this.activeConstraintGraphics.destroy();
    this.labelContent.destroy();

    super.destroy(options);
  }

  draw = () => {
    this.labelContent.removeChildren();
    this.activeConstraintGraphics.clear();
    this.labelContent.addChild(this.activeConstraintGraphics);

    this.bgGraphics.width = this.scrollBoxWidth;
    this.bgGraphics.height = BANNER_HEIGHT;

    this.scenes.forEach((scene, index) => {
      const sceneLabel = this.createLabel(index, scene.id === this.currentSceneId);
      sceneLabel.name = scene.id;
      this.labelContent.addChild(sceneLabel);
      const sceneTimes = getSceneStartEndTimes(this.scribe, scene);
      const x = Math.min(
        (sceneTimes.startTime / 1000) * this.currentZoom.x,
        (sceneTimes.endTime / 1000) * this.currentZoom.x - LABEL_WIDTH
      );
      if (sceneTimes.endTime !== sceneTimes.startTime) {
        sceneLabel.visible = true;
        sceneLabel.x = x;
        if (scene.id === this.currentSceneId) {
          this.drawActiveSceneConstraint(
            sceneLabel,
            ((sceneTimes.endTime - sceneTimes.startTime) / 1000) * this.currentZoom.x
          );
        } else {
          this.drawSelectSceneButton(
            sceneLabel,
            ((sceneTimes.endTime - sceneTimes.startTime) / 1000) * this.currentZoom.x
          );
        }
      } else {
        sceneLabel.visible = scene.id === this.currentSceneId;
        sceneLabel.x = sceneTimes.endTime !== sceneTimes.startTime ? x : x + LABEL_WIDTH;
      }
      sceneLabel.y = 1;
    });
    this.maskClip.width = this.scrollBoxWidth;
    this.maskClip.height = this.scrollBoxHeight;
    this.labelContent.mask = this.maskClip;
  };

  drawActiveSceneConstraint = (sceneLabel: PIXI.Sprite, width: number) => {
    let offsetForNarrowScene = 0;
    if (width < LABEL_WIDTH) {
      offsetForNarrowScene = LABEL_WIDTH - width;
    }
    this.activeConstraintGraphics.clear();
    this.activeConstraintGraphics.lineStyle(2, ACTIVE_SCENE_COLOUR);
    this.activeConstraintGraphics.drawRect(sceneLabel.x + offsetForNarrowScene, 1, width, this.scrollBoxHeight - 2);
  };

  drawSelectSceneButton = (sceneLabel: PIXI.Sprite, width: number) => {
    let offsetForNarrowScene = 0;
    if (width < LABEL_WIDTH) {
      offsetForNarrowScene = LABEL_WIDTH - width;
    }
    const button = new PIXI.Graphics();
    button.beginFill(0x000000, 1);
    button.drawRect(sceneLabel.x + offsetForNarrowScene, 0, width, this.scrollBoxHeight);
    button.endFill();
    button.interactive = true;
    button.buttonMode = true;
    button.alpha = 0;

    button.on('click', () => {
      getStore().dispatch(setActiveScene(this.scribe.id, sceneLabel.name));
    });
    this.labelContent.addChild(button);
  };

  updatePosition = (x: number, forceRedraw = false) => {
    if (this.labelContent.x === x - LEFT_ZOOM_MARGIN && !forceRedraw) return;
    this.labelContent.x = x - LEFT_ZOOM_MARGIN;
    this.scenes.forEach(scene => {
      const sceneLabel = this.labelContent.getChildByName(scene.id);
      const sceneTimes = getSceneStartEndTimes(this.scribe, scene);
      const x = clamp(
        -this.labelContent.x,
        (sceneTimes.startTime / 1000) * this.currentZoom.x,
        (sceneTimes.endTime / 1000) * this.currentZoom.x - LABEL_WIDTH
      );
      sceneLabel.x = x;
    });
  };

  updateScrollBoxWidth = (scrollBoxWidth: number) => {
    this.scrollBoxWidth = scrollBoxWidth;
    this.draw();
  };

  updateScrollBoxHeight = (scrollBoxHeight: number) => {
    const x = this.labelContent.x + LEFT_ZOOM_MARGIN;
    this.scrollBoxHeight = scrollBoxHeight;
    this.draw();
    this.updatePosition(x, true);
  };

  updateZoom = () => {
    this.draw();
  };
}
