import { call, put, takeEvery, takeLatest, take, select } from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';
import uploadingAssetProvider from 'js/shared/providers/UploadingAssetProvider';
import { VSCAssetImageSourceName } from 'js/types';
import { getImageLibraries, getImageLibrary, searchImageLibrary } from 'js/shared/providers/ImageLibrary';
import { FILE_CONTENT_TYPES, IMAGE_PROVIDER_TYPES } from 'js/config/consts';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { SVG_BOUNDARY_ERROR } from 'js/shared/helpers/svgViewboxHelpers';
import { withNounProject } from 'js/shared/helpers/nounProject';
import cloneDeep from 'lodash.clonedeep';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { trackImageColourChangeSuccessful, trackImageReplace } from 'js/actionCreators/trackingActions';
import {
  PROCESS_AI_IMAGES_FAILURE,
  PROCESS_AI_IMAGES_SUCCESS,
  processAiImagesRequest,
  processImageFileOnSuccessfulUpload
} from 'js/actionCreators/imageProcessingActions';
import {
  ALLOWED_IMAGE_TYPES,
  canvasSizes,
  MAX_IMAGE_FILE_UPLOAD_SIZE,
  MAX_IMAGE_FILE_UPLOAD_SIZE_DISPLAY
} from 'js/config/defaults';
import { isPremiumImageAsset } from 'js/shared/helpers/content/premiumContent';
import { getUserImageLibrary } from 'js/selectors/getUserImageLibrary';
import { IMAGE_GENERATION_SUCCESS } from 'js/actionCreators/betaAiFeaturesActions';
import getOptimizelyInstance from 'js/shared/helpers/optimizely';
import { IMAGE_LIBRARY_REWORK } from 'js/config/featureKeys';

import {
  SEARCH_IMAGE,
  searchImagesSuccess,
  searchImagesFailure,
  ADD_USER_UPLOAD_IMAGE_TO_CANVAS,
  GET_IMAGE_LIBRARIES,
  getImageLibrariesSuccess,
  getImageLibrariesFailure,
  GET_IMAGE_LIBRARY,
  getImageLibrarySuccess,
  getImageLibraryFailure,
  GET_USER_IMAGES_REQUESTED,
  getUserImagesFailure,
  getUserImagesSuccess,
  ADD_USER_IMAGE_TO_SCRIBE,
  DELETE_USER_IMAGE_REQUESTED,
  deleteUserImageSuccess,
  deleteUserImageFailure,
  ADD_LIBRARY_IMAGE_TO_CANVAS,
  uploadUserImageAsset,
  uploadLibraryImageAsset,
  REPLACE_USER_IMAGE_ELEMENT,
  REPLACE_LIBRARY_IMAGE_ELEMENT,
  REPLACE_UPLOADED_IMAGE_ELEMENT,
  GET_USER_IMAGES_SUCCESS,
  loadUserImagesSliceSuccess,
  LOAD_MORE_USER_IMAGES,
  LOAD_MORE_USER_AI_IMAGES,
  loadUserAiImagesSliceSuccess,
  FULLY_VECTORIZE_IMAGE_AND_REPLACE_ELEMENT,
  UPLOAD_IMAGE_SUCCESS,
  LOAD_PAGE_OF_USER_IMAGES,
  clearReplaceImage
} from '../actionCreators/imagesActions';
import { UPDATE_SCRIBE_SUCCESS, createImageElement, updateScribe } from '../actionCreators/scribeActions';
import { startLoading, stopLoading, showError, hideModal } from '../actionCreators/uiActions';
import { sendErrorToSentry } from '../logging';

import { processImageFileOnLoad } from './sagaHelpers/processImageFileOnLoad';
import getCanvasAttributeFromFile from './sagaHelpers/getCanvasAttributeFromFile';
import getImageFileFromProvider from './sagaHelpers/getImageFileFromProvider';
import { getScribeById, getUserAssetFromState } from './selectors';
import { loadSvg } from './sagaHelpers/loadSvg';
import { svgToImageBitmap } from './sagaHelpers/svgToImageBitmap';
import { uploadImageAssetWithRetries } from './imageUploadSagas';
import { addAiGeneratedImagesToList, updateUserImageList } from './imageListsSagas';
import { initialLoadOfUserLibrary, loadPageOfUserLibrary } from './loadPageOfUserLibrarySaga';
import { updateRecentImages } from './recentAssetsSaga';

const badImageError =
  "There seems to be a problem with that svg which means we can't process it. You may need to fix it or try another image file.";

const imageErrorMessage = err => {
  const newError = err.message === SVG_BOUNDARY_ERROR ? new Error(badImageError) : err;
  return newError;
};

export function* getUserImages() {
  try {
    const assets = yield call({ fn: appServices.getAssetsList, context: appServices }, 'image');
    const images = assets.map(image => {
      return {
        ...image,
        id: image.projectAssetId
      };
    });
    yield put(getUserImagesSuccess(images));
  } catch (error) {
    sendErrorToSentry(error);
    yield put(getUserImagesFailure(error));
    yield put(showError(error));
  }
}

export function* processImageAndCreateElement({
  assetId,
  canvasSize,
  elementId,
  filename,
  originalFile,
  provider,
  scribeId,
  isPremium
}) {
  const {
    file: processedFile,
    viewboxScribeElementProperties,
    recolorScribeElementProperties,
    containsEmbeddedImage
  } = yield call(processImageFileOnLoad, {
    file: originalFile,
    provider
  });

  const { animationTime, scaleX, scaleY, x, y, width, height, _imageUrl } = yield call(getCanvasAttributeFromFile, {
    file: processedFile,
    canvasSize: canvasSize
  });

  uploadingAssetProvider.save(assetId, _imageUrl);

  yield put(
    createImageElement(scribeId, canvasSize, {
      id: elementId,
      image: {
        contentType: originalFile.type,
        provider,
        filename: filename,
        assetId: assetId
      },
      scaleX,
      scaleY,
      x,
      y,
      width,
      height,
      _imageUrl,
      _imageLoaded: provider === IMAGE_PROVIDER_TYPES.USER || provider === IMAGE_PROVIDER_TYPES.AI_LIBRARY,
      _imageLoading: ['library', 'noun-project', 'upload'].includes(provider),
      animationTime,
      containsEmbeddedImage,
      ...viewboxScribeElementProperties,
      ...recolorScribeElementProperties,
      isPremium
    })
  );

  yield put(processImageFileOnSuccessfulUpload({ file: processedFile, assetId }));
  yield put(stopLoading());
  yield put(hideModal(scribeId));
}

const POLLING_RETRY_COUNT = 10;
export function* postUploadPolling({
  originalFile,
  filename,
  scribeId,
  assetId,
  elementId,
  actionCreator,
  source,
  sourceIdentifier,
  isPremium
}) {
  let count = POLLING_RETRY_COUNT;

  while (true) {
    const successScribe = yield take(UPDATE_SCRIBE_SUCCESS);

    if (successScribe.scribe.projectModel.elements.find(element => element.id === elementId)) {
      yield put(actionCreator(originalFile, filename, scribeId, assetId, source, sourceIdentifier, isPremium));
      yield put(stopLoading());
      return;
    }
    count--;
    if (count === 0) {
      yield put(stopLoading());
      return;
    }
  }
}

export function* addUploadedImageToCanvas(action) {
  const { file: originalFile } = action;
  const elementId = uuidv4();
  const temporaryAssetId = `temp-${uuidv4()}`;

  try {
    yield put(startLoading());
    if (!action.file) throw new Error('No file supplied to saga');

    yield call(processImageAndCreateElement, {
      assetId: temporaryAssetId,
      canvasSize: action.canvasSize,
      elementId,
      filename: action.filename,
      originalFile,
      provider: IMAGE_PROVIDER_TYPES.UPLOAD,
      scribeId: action.scribeId
    });

    yield call(postUploadPolling, {
      actionCreator: uploadUserImageAsset,
      assetId: temporaryAssetId,
      elementId,
      filename: action.filename,
      originalFile,
      scribeId: action.scribeId
    });
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
  }
}

export function* addLibraryImageToCanvas(action) {
  const elementId = uuidv4();
  try {
    yield put(startLoading());
    const originalFile = yield call(getImageFileFromProvider, action.provider, action.imageId);
    const imageFile = yield action.provider === 'noun-project' ? withNounProject(originalFile) : originalFile;
    const isPremium = action.isPremium;
    yield call(processImageAndCreateElement, {
      originalFile: imageFile,
      canvasSize: action.canvasSize,
      assetId: action.imageId,
      scribeId: action.scribeId,
      elementId,
      filename: action.filename,
      provider: action.provider,
      isPremium
    });

    yield call(postUploadPolling, {
      actionCreator: uploadLibraryImageAsset,
      assetId: action.imageId,
      elementId,
      filename: action.filename,
      originalFile: imageFile, // avoid uploading modified files in case we break them
      scribeId: action.scribeId,
      source: action.provider,
      sourceIdentifier: action.imageId,
      isPremium
    });
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
  }
}

export function* addUserImageToScribe(action) {
  try {
    yield put(startLoading());

    const originalFile = yield call(getImageFileFromProvider, action.provider, action.imageId);

    let imageAssetData;
    if (action.imageLibrarySource) {
      const imageLibrary = yield select(getUserImageLibrary, action.imageLibrarySource);
      imageAssetData = imageLibrary.sourceAssets.find(image => image.projectAssetId === action.imageId);
    } else {
      if (action.provider !== IMAGE_PROVIDER_TYPES.AI_LIBRARY) {
        imageAssetData = yield select(getUserAssetFromState, action.imageId);
      }
    }

    if (!imageAssetData) {
      throw new Error('Image asset not found in state');
    }

    yield call(processImageAndCreateElement, {
      originalFile,
      canvasSize: action.canvasSize,
      assetId: action.imageId,
      scribeId: action.scribeId,
      elementId: uuidv4(),
      filename: action.filename,
      provider: action.provider,
      isPremium: isPremiumImageAsset(imageAssetData)
    });
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
  }
}

export function* deleteUserImage(action) {
  try {
    yield call(appServices.deleteAsset, action.imageId);
    yield put(deleteUserImageSuccess(action.imageId, action.targetLibrary));
  } catch (error) {
    sendErrorToSentry(error);
    yield put(deleteUserImageFailure(error));
    yield put(showError(error));
  }
}

function* handleGetImageLibraries() {
  try {
    const libraries = yield call({ fn: getImageLibraries, context: appServices });
    const nounProjectLibraries = cloneDeep(libraries);
    yield put(getImageLibrariesSuccess(libraries.data, nounProjectLibraries.data));
  } catch (e) {
    sendErrorToSentry(e);
    yield put(getImageLibrariesFailure(e));
    yield put(
      showError({
        message: 'There was an issue loading the image libraries. Please try again.',
        description: e.message
      })
    );
  }
}

function* handleGetImageLibrary(action) {
  try {
    const { libraryId, searchSource, cursor, libraryName } = action;
    if (libraryName === 'GIFs' && searchSource === 'noun-project') {
      yield put(getImageLibrarySuccess(libraryId, [], searchSource, cursor));
    } else {
      const library = yield call(getImageLibrary, libraryId, searchSource, cursor);
      yield put(getImageLibrarySuccess(libraryId, library.images, searchSource, library.cursor));
    }
  } catch (e) {
    sendErrorToSentry(e);
    yield put(getImageLibraryFailure(e));
  }
}

function* handleSearchImage(action) {
  const { searchSource, query, offset, next } = action;
  try {
    const results = yield call(searchImageLibrary, searchSource, query, offset, next);
    yield put(
      searchImagesSuccess(
        searchSource,
        results.data.length ? results.data[0].images : [],
        results.meta.total,
        query,
        results.meta.next
      )
    );
  } catch (e) {
    sendErrorToSentry(searchSource, e);
    yield put(searchImagesFailure(e));
  }
}

export function* fullyVectorizeImageAndReplaceElement({ elementId, scribeId }) {
  try {
    if (!elementId) {
      throw new Error('Element not selected');
    }

    const scribe = yield select(state => getScribeById(state, scribeId));

    if (!scribe) {
      throw new Error('Project not found');
    }

    yield put(startLoading());

    const newScribe = cloneDeep(scribe);

    const element = newScribe.elements.find(el => el.id === elementId);

    if (!element) {
      throw new Error('Element not found');
    }

    if (!element._imageUrl) {
      throw new Error('Element does not have an image url');
    }

    let imageBitmap;
    if (element.image.contentType === 'image/svg+xml') {
      const svg = yield call(loadSvg, element._imageUrl);
      imageBitmap = yield call(svgToImageBitmap, svg);
    }

    const images = [{ url: element._imageUrl, imageBitmap }];
    const pipeline = 'full';
    yield put(processAiImagesRequest({ images, pipeline }));

    const processedImagesResult = yield take([PROCESS_AI_IMAGES_SUCCESS, PROCESS_AI_IMAGES_FAILURE]);

    if ('error' in processedImagesResult) throw processedImagesResult.error;

    if (!(processedImagesResult.images.length && processedImagesResult.images[0].blob)) {
      throw new Error('Failed to process image');
    }

    const file = processedImagesResult.images[0].blob;

    const { assetId } = yield uploadImageAssetWithRetries({
      file,
      filename: 'AI Generated Image Full Vectorization',
      source: VSCAssetImageSourceName.AI,
      maxRetries: 3,
      sourceIdentifier: element.image.assetId
    });

    const canvasSize = canvasSizes[newScribe.canvasSize || 'landscape'];
    const originalFile = new File([file], element.image.filename, { type: 'image/svg+xml' });

    yield call(processImageAndReplaceElement, {
      assetId,
      canvasSize,
      elementId,
      filename: element.image.filename,
      originalFile,
      provider: IMAGE_PROVIDER_TYPES.AI_LIBRARY,
      scribeId
    });

    yield put(trackImageColourChangeSuccessful(scribeId));
  } catch (error) {
    console.error(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
    yield put(hideModal(scribeId));
    sendErrorToSentry(error);
  }
}

function* processImageAndReplaceElement({
  assetId,
  canvasSize,
  elementId,
  filename,
  originalFile,
  provider,
  scribeId
}) {
  if (!elementId) {
    throw new Error('Element not selected');
  }

  const scribe = yield select(state => getScribeById(state, scribeId));

  if (!scribe) {
    throw new Error('Project not found');
  }

  const newScribe = cloneDeep(scribe);

  const element = newScribe.elements.find(el => el.id === elementId);

  if (!element) {
    throw new Error('Element not found');
  }

  const scene = getActiveScene(newScribe);

  if (!scene) {
    throw new Error('Failed to replace image');
  }

  const {
    file: processedFile,
    viewboxScribeElementProperties,
    recolorScribeElementProperties,
    recolorProperties,
    containsEmbeddedImage
  } = yield call(processImageFileOnLoad, { file: originalFile, previousRecolor: element?.image?.recolor });

  const { width, height, _imageUrl } = yield call(getCanvasAttributeFromFile, {
    file: processedFile,
    canvasSize: canvasSize
  });

  uploadingAssetProvider.save(assetId, _imageUrl);

  /*
    Maintain the height of the old element, and respect the aspect ratio of the new image
  */
  const oldTotalHeight = element.height * element.scaleY;
  const newScale = oldTotalHeight / height;

  /*
    Workout new x coordinate to maintain central position
  */
  const oldCenter = element.x + (element.width * element.scaleX) / 2;
  const newX = oldCenter - (width * newScale) / 2;

  const id = uuidv4();

  const newElement = new ScribeImageElementModel({
    ...element,
    width,
    height,
    _imageUrl,
    _imageLoaded: provider === 'user' ? true : false,
    _imageLoading: ['library', 'noun-project', 'upload', 'ai-library'].includes(provider),
    useContentSvgViewbox: viewboxScribeElementProperties?.useContentSvgViewbox,
    viewboxAttributes: viewboxScribeElementProperties?.viewboxAttributes,
    _recolorDefaults: recolorScribeElementProperties?._recolorDefaults,
    recolorAvailable: recolorScribeElementProperties?.recolorAvailable,
    image: {
      contentType: originalFile.type,
      provider,
      filename,
      assetId,
      recolor: recolorProperties
    },
    containsEmbeddedImage,
    scaleY: newScale,
    scaleX: newScale,
    x: newX,
    id
  });

  newScribe.elements = newScribe.elements.reduce((els, current) => {
    if (current.id !== elementId) {
      return [...els, current];
    } else return [...els, newElement];
  }, []);

  scene.elementIds = scene.elementIds.map(elId => (elId === elementId ? id : elId));

  yield put(updateScribe(newScribe, false, [id]));

  yield put(stopLoading());
  yield put(hideModal(scribeId));
  return { id, processedFile, recolorAvailable: recolorScribeElementProperties?.recolorAvailable };
}

function* replaceUserImageElement(action) {
  const { scribeId, filename, provider, imageId } = action;

  yield put(startLoading());
  try {
    const originalFile = yield call(getImageFileFromProvider, action.provider, action.imageId);

    const result = yield call(processImageAndReplaceElement, {
      ...action,
      originalFile,
      assetId: imageId
    });

    yield put(
      trackImageReplace({
        scribeId,
        imageType: originalFile.type,
        provider,
        filename,
        recolorAvailable: result.recolorAvailable,
        imageSize: originalFile.size,
        imageId
      })
    );
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
  } finally {
    const optimizely = getOptimizelyInstance();
    if (optimizely.isFeatureEnabled(IMAGE_LIBRARY_REWORK)) {
      yield put(clearReplaceImage({ focusActiveElement: true }));
    }
  }
}

export function* replaceElementWithLibraryImage(action) {
  const { scribeId, filename, provider, imageId } = action;
  try {
    yield put(startLoading());
    const originalFile = yield call(getImageFileFromProvider, provider, imageId);
    const imageFile = yield action.provider === 'noun-project' ? withNounProject(originalFile) : originalFile;
    const { id, recolorAvailable } = yield call(processImageAndReplaceElement, {
      ...action,
      originalFile: imageFile,
      assetId: imageId
    });

    const optimizely = getOptimizelyInstance();
    if (optimizely.isFeatureEnabled(IMAGE_LIBRARY_REWORK)) {
      yield put(clearReplaceImage({ focusActiveElement: true }));
    }

    yield call(postUploadPolling, {
      actionCreator: uploadLibraryImageAsset,
      assetId: imageId,
      elementId: id,
      filename,
      originalFile: imageFile, // avoid uploading modified files in case we break them
      scribeId,
      source: action.provider,
      sourceIdentifier: action.imageId
    });

    yield put(
      trackImageReplace({
        scribeId,
        imageType: originalFile.type,
        provider,
        filename,
        recolorAvailable: recolorAvailable,
        imageSize: originalFile.size,
        imageId,
        selectedLibrary: action.selectedLibrary
      })
    );
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
  }
}

export function* replaceElementWithUploadedImage(action) {
  const { file: originalFile, scribeId, filename, elementId, canvasSize } = action;
  const temporaryAssetId = `temp-${uuidv4()}`;
  const provider = IMAGE_PROVIDER_TYPES.UPLOAD;

  try {
    yield put(startLoading());
    if (!action.file) throw new Error('No file found');

    if (!ALLOWED_IMAGE_TYPES.includes(originalFile.type)) {
      yield put(stopLoading());
      return yield put(
        showError({
          message: 'Your selected file is an incompatible file type',
          description: `Allowed image types are: ${Object.keys(FILE_CONTENT_TYPES).join(', ')}.`
        })
      );
    }

    if (originalFile.size > MAX_IMAGE_FILE_UPLOAD_SIZE) {
      yield put(stopLoading());
      return yield put(
        showError({
          message: `Error uploading your image file.`,
          description: `Image files must be less than ${MAX_IMAGE_FILE_UPLOAD_SIZE_DISPLAY}MB in size.`
        })
      );
    }

    const result = yield call(processImageAndReplaceElement, {
      assetId: temporaryAssetId,
      canvasSize,
      elementId,
      filename,
      originalFile,
      provider,
      scribeId
    });

    const optimizely = getOptimizelyInstance();
    if (optimizely.isFeatureEnabled(IMAGE_LIBRARY_REWORK)) {
      yield put(clearReplaceImage({ focusActiveElement: true }));
    }

    yield call(postUploadPolling, {
      actionCreator: uploadUserImageAsset,
      assetId: temporaryAssetId,
      elementId: result.id,
      filename,
      originalFile,
      scribeId
    });

    yield put(
      trackImageReplace({
        scribeId,
        imageType: originalFile.type,
        provider,
        filename,
        recolorAvailable: result.recolorAvailable,
        imageSize: originalFile.size,
        temporaryAssetId
      })
    );
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(showError(imageErrorMessage(error)));
    yield put(stopLoading());
  }
}

function* loadPageOfUserImages() {
  try {
    const { userImagePage, userImagePageSize, userImageAssets } = yield select(state => state.images);

    if (!userImageAssets || !userImageAssets.length) {
      return loadUserImagesSliceSuccess([]);
    }

    const start = userImagePageSize * userImagePage - userImagePageSize;
    const end = Math.min(userImagePageSize * userImagePage, userImageAssets.length);

    const assets = userImageAssets.slice(start, end);

    const signedUrls = yield call(
      { fn: appServices.getAssetUrls, context: appServices },
      assets.map(asset => asset.projectAssetId),
      true
    );

    yield put(loadUserImagesSliceSuccess(assets.map((asset, index) => ({ ...asset, url: signedUrls[index].blobUrl }))));
  } catch (error) {
    console.error(error);
    yield put(
      showError({
        message: 'There was an issue loading your images. Please try closing the library and opening it again.',
        description: `Error: ${error.message}`
      })
    );
  }
}

function* loadPageOfUserAiImages() {
  try {
    const { userAiImagePage, userImagePageSize, userAiImageAssets } = yield select(state => state.images);

    if (!userAiImageAssets || !userAiImageAssets.length) {
      return loadUserAiImagesSliceSuccess([]);
    }

    const start = userImagePageSize * userAiImagePage - userImagePageSize;
    const end = Math.min(userImagePageSize * userAiImagePage, userAiImageAssets.length);

    const assets = userAiImageAssets.slice(start, end);

    const signedUrls = yield call(
      { fn: appServices.getAssetUrls, context: appServices },
      assets.map(asset => asset.projectAssetId),
      true
    );

    yield put(
      loadUserAiImagesSliceSuccess(assets.map((asset, index) => ({ ...asset, url: signedUrls[index].blobUrl })))
    );
  } catch (error) {
    console.error(error);
    yield put(
      showError({
        message: 'There was an issue loading your AI images. Please try closing the library and opening it again.',
        description: `Error: ${error.message}`
      })
    );
  }
}

function* imageSagas() {
  yield takeEvery(ADD_USER_UPLOAD_IMAGE_TO_CANVAS, addUploadedImageToCanvas);
  yield takeEvery(ADD_LIBRARY_IMAGE_TO_CANVAS, addLibraryImageToCanvas);
  yield takeEvery(ADD_USER_IMAGE_TO_SCRIBE, addUserImageToScribe);
  yield takeEvery(GET_USER_IMAGES_REQUESTED, getUserImages);
  yield takeEvery(DELETE_USER_IMAGE_REQUESTED, deleteUserImage);
  yield takeLatest(GET_IMAGE_LIBRARIES, handleGetImageLibraries);
  yield takeEvery(GET_IMAGE_LIBRARY, handleGetImageLibrary);
  yield takeEvery(SEARCH_IMAGE, handleSearchImage);
  yield takeEvery(REPLACE_USER_IMAGE_ELEMENT, replaceUserImageElement);
  yield takeEvery(REPLACE_LIBRARY_IMAGE_ELEMENT, replaceElementWithLibraryImage);
  yield takeEvery(REPLACE_UPLOADED_IMAGE_ELEMENT, replaceElementWithUploadedImage);
  yield takeEvery([GET_USER_IMAGES_SUCCESS, LOAD_MORE_USER_IMAGES], loadPageOfUserImages);
  yield takeEvery([GET_USER_IMAGES_SUCCESS, LOAD_MORE_USER_AI_IMAGES], loadPageOfUserAiImages);
  yield takeEvery(FULLY_VECTORIZE_IMAGE_AND_REPLACE_ELEMENT, fullyVectorizeImageAndReplaceElement);
  yield takeEvery(UPLOAD_IMAGE_SUCCESS, updateUserImageList);
  yield takeEvery(GET_USER_IMAGES_SUCCESS, initialLoadOfUserLibrary);
  yield takeEvery(LOAD_PAGE_OF_USER_IMAGES, loadPageOfUserLibrary);
  yield takeEvery(ADD_USER_IMAGE_TO_SCRIBE, updateRecentImages);
  yield takeEvery(IMAGE_GENERATION_SUCCESS, addAiGeneratedImagesToList);
}

export default imageSagas;
