import {
  DELETE_AUDIO_FROM_PROJECT,
  saveAudioFailed,
  saveAudioSuccess,
  SAVE_AUDIO,
  UPDATE_AUDIO_CONFIG,
  SPLIT_AUDIO_TRACK,
  getUserAudioSuccess,
  getUserAudioFailure,
  GET_USER_AUDIO_REQUESTED,
  DELETE_AUDIO_ASSET,
  closeDeleteAudioAssetModal,
  UPDATE_AUDIO_LAYER,
  DELETE_AUDIO_LAYER
} from 'js/actionCreators/audioActions';
import { setSelectedAudioClip, updateScribe, LOAD_SCRIBE_BY_ID_SUCCESS } from 'js/actionCreators/scribeActions';
import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import cloneDeep from 'lodash.clonedeep';
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { editorSetEditPanelMode } from 'js/actionCreators/editorActions';
import { sendErrorToSentry } from 'js/logging';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { ScribeAudioLayerModel } from 'js/models/ScribeAudioLayerModel';
import uuid from 'uuid';
import { AUDIO_TEMP_LAYER } from 'js/editor/Timeline/PixiTimeline/TimelineAdvancedAudioManager';

import { getScribeById } from './selectors';
import audioLibraryUploadSagas from './audioLibraryUploadSagas';
import audioLibrarySagas from './audioLibrarySagas';

export function* saveAudio({ audio, scribeId, source, audioType, volume = 1, fileName }) {
  try {
    const assetId = yield call(appServices.uploadAsset, { file: audio, filename: fileName, source });

    const audioConfig = {
      id: uuid(),
      assetId,
      type: audioType,
      volume,
      source,
      fileName
    };

    const activeScribe = yield select(getScribeById, scribeId);
    const newActiveScribe = cloneDeep(activeScribe);

    newActiveScribe.audioClips.push(audioConfig);

    yield put(updateScribe(newActiveScribe));
    yield put(saveAudioSuccess(audioType, assetId));
  } catch (error) {
    yield put(saveAudioFailed(audioType));
    yield put(showError(error));
  }
}
export function* deleteAudioLayer({ scribeId, layerId }) {
  const activeScribe = yield select(getScribeById, scribeId);
  let newActiveScribe = cloneDeep(activeScribe);
  newActiveScribe.audioLayers.filter(audioLayer => audioLayer.id !== layerId);
  newActiveScribe = cleanupUnusedLayersAndIds(newActiveScribe);
  yield put(updateScribe(newActiveScribe));
}

const cleanupUnusedLayersAndIds = scribe => {
  const newActiveScribe = cloneDeep(scribe);
  // removes ids of audioClips that aren't in the audioClips array
  if (!newActiveScribe.audioLayers) newActiveScribe.audioLayers = [];
  if (newActiveScribe.audioLayers) {
    newActiveScribe.audioLayers.forEach(layer => {
      layer.audioClipIds = layer.audioClipIds.filter(id => newActiveScribe.audioClips.some(clip => clip.id === id));
    });
  }
  //remove any layers without any ids in them
  newActiveScribe.audioLayers = newActiveScribe.audioLayers.filter(layer => layer.audioClipIds.length > 0);
  //remove ids of audiolayers that no longer exist from projectAudioLayerIds
  if (newActiveScribe.projectAudioLayerIds) {
    newActiveScribe.projectAudioLayerIds = newActiveScribe.projectAudioLayerIds.filter(id =>
      newActiveScribe.audioLayers.find(layer => layer.id === id)
    );
  }
  //remove ids of audiolayers that no longer exist from scenes
  if (newActiveScribe.scenes) {
    newActiveScribe.scenes.forEach(scene => {
      if (scene.audioLayerIds) {
        scene.audioLayerIds = scene.audioLayerIds.filter(id =>
          newActiveScribe.audioLayers.find(layer => layer.id === id)
        );
      }
    });
  }
  return newActiveScribe;
};

export function* updateAudioLayerSaga({ scribeId, layerId, audioClipId, audioType }) {
  const activeScribe = yield select(getScribeById, scribeId);
  let newActiveScribe = cloneDeep(activeScribe);

  const currentScene = getActiveScene(newActiveScribe);
  if (!currentScene) return;
  if (!currentScene.audioLayerIds) currentScene.audioLayerIds = [];
  if (!newActiveScribe.audioLayers) newActiveScribe.audioLayers = [];
  if (!newActiveScribe.projectAudioLayerIds) newActiveScribe.projectAudioLayerIds = [];
  newActiveScribe.audioLayers.forEach(layer => {
    //remove the audioClipId from any other layers
    if (layer.audioClipIds.includes(audioClipId)) {
      layer.audioClipIds = layer.audioClipIds.filter(id => id !== audioClipId);
    }

    //add the audioClipId to the new layer
    if (layer.id === layerId) {
      layer.audioClipIds.push(audioClipId);
    }
  });

  if (layerId === AUDIO_TEMP_LAYER) {
    const newLayer = { id: uuid(), audioClipIds: [audioClipId] };
    if (audioType === 'project') {
      newActiveScribe.audioLayers = [new ScribeAudioLayerModel(newLayer), ...newActiveScribe.audioLayers];
      newActiveScribe.projectAudioLayerIds.unshift(newLayer.id);
    } else {
      newActiveScribe.audioLayers = [new ScribeAudioLayerModel(newLayer), ...newActiveScribe.audioLayers];
      currentScene.audioLayerIds = [newLayer.id, ...currentScene.audioLayerIds];
    }
  }

  newActiveScribe = cleanupUnusedLayersAndIds(newActiveScribe);
  yield put(updateScribe(newActiveScribe));
}

export function* splitAudioTrack({ scribeId, id, splitTime }) {
  // Split the audio clip at the given time relative to the start of the original audio clip
  const minimumDuration = 0.1;
  const activeScribe = yield select(getScribeById, scribeId);
  const newActiveScribe = cloneDeep(activeScribe);
  const audioIndexToSplit = newActiveScribe.audioClips.findIndex(clip => clip.id === id);
  const audioClip = newActiveScribe.audioClips[audioIndexToSplit];
  const layer = newActiveScribe.audioLayers.find(layer => layer.audioClipIds.includes(id));
  const newAudioClip = {
    ...audioClip,
    instanceDuration: splitTime - audioClip.startOffset
  };

  let originalName = audioClip.filename;
  const splitName = audioClip.filename.split('_');

  if (!isNaN(parseInt(splitName[0]))) {
    originalName = originalName.substring(originalName.indexOf('_') + 1);
  }
  const instanceCount = activeScribe.audioClips.filter(clip => clip.filename.includes(originalName)).length;
  let newName = instanceCount + '_' + originalName;
  const newAudioClip2 = {
    ...audioClip,
    id: uuid(),
    filename: newName,
    startTime: newAudioClip.startTime + newAudioClip.instanceDuration,
    startOffset: newAudioClip.instanceDuration + audioClip.startOffset,
    instanceDuration: audioClip.instanceDuration - newAudioClip.instanceDuration
  };
  // Don't split if the new audio clips are too short
  if (newAudioClip2.instanceDuration < minimumDuration || newAudioClip.instanceDuration < minimumDuration) {
    return;
  }

  layer.audioClipIds.push(newAudioClip2.id);
  newActiveScribe.audioClips.splice(audioIndexToSplit, 1, newAudioClip, newAudioClip2);

  yield put(updateScribe(newActiveScribe));
  yield put(setSelectedAudioClip(newAudioClip2));
}

export function* updateAudioConfig({ scribeId, id, config }) {
  const activeScribe = yield select(getScribeById, scribeId);
  const newActiveScribe = cloneDeep(activeScribe);
  const audioIndexToReplace = newActiveScribe.audioClips.findIndex(clip => {
    return clip.id === id;
  });

  const audioClip = {
    ...newActiveScribe.audioClips[audioIndexToReplace],
    ...config
  };
  newActiveScribe.audioClips.splice(audioIndexToReplace, 1, audioClip);
  yield put(updateScribe(newActiveScribe));
  yield put(setSelectedAudioClip(audioClip));
}

export function* deleteAudioFromProject({ scribeId, id }) {
  const activeScribe = yield select(getScribeById, scribeId);

  let newActiveScribe = cloneDeep(activeScribe);

  const audioIndexToRemove = newActiveScribe.audioClips.findIndex(clip => clip.id === id);

  newActiveScribe.audioClips.splice(audioIndexToRemove, 1);
  newActiveScribe = cleanupUnusedLayersAndIds(newActiveScribe);

  yield put(setSelectedAudioClip(null));
  yield put(editorSetEditPanelMode(null));
  yield put(updateScribe(newActiveScribe));
}

export function* deleteAudioAsset() {
  const assetId = yield select(state => state.audio.deleteAudioAssetId);
  yield call(appServices.deleteAsset, assetId);
  yield put(closeDeleteAudioAssetModal());
  yield call(getUserAudio);
}

export function* getUserAudio() {
  try {
    const assets = yield call({ fn: appServices.getAssetsList, context: appServices }, 'audio');
    const audio = assets.map(audio => {
      return {
        ...audio
      };
    });
    yield put(getUserAudioSuccess(audio));
  } catch (error) {
    sendErrorToSentry(error);
    yield put(getUserAudioFailure(error));
    yield put(showError(error));
  }
}

function* audioSagas() {
  yield fork(audioLibraryUploadSagas);
  yield fork(audioLibrarySagas);
  yield takeLatest(SAVE_AUDIO, saveAudio);
  yield takeLatest(DELETE_AUDIO_FROM_PROJECT, deleteAudioFromProject);
  yield takeLatest(DELETE_AUDIO_ASSET, deleteAudioAsset);
  yield takeLatest(UPDATE_AUDIO_CONFIG, updateAudioConfig);
  yield takeLatest(SPLIT_AUDIO_TRACK, splitAudioTrack);
  yield takeLatest(UPDATE_AUDIO_LAYER, updateAudioLayerSaga);
  yield takeLatest(DELETE_AUDIO_LAYER, deleteAudioLayer);
  yield takeEvery(GET_USER_AUDIO_REQUESTED, getUserAudio);
  yield takeLatest(LOAD_SCRIBE_BY_ID_SUCCESS, getUserAudio);
}

export default audioSagas;
