import * as PIXI from 'pixi.js';
import {
  UPLOAD_RESOURCE_TO_GPU,
  UploadResourceToGpuAction,
  uploadResourceToGpuComplete
} from 'js/actionCreators/gpuActions';
import { getPixiPlaybackApp } from 'js/playback/lib/Playback';
import {
  CallEffect,
  PutEffect,
  SelectEffect,
  TakeEffect,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  CREATE_ELEMENT,
  CreateElementAction,
  DELETE_MULTIPLE_ELEMENTS_BY_ID,
  DeleteMultipleElementsByIdAction,
  PREP_SCRIBE_FOR_EDITOR_SUCCESS,
  PrepScribeForEditorSuccessAction
} from 'js/actionCreators/scribeActions';
import resourceLoader from 'js/playback/lib/Playback/helpers/resourceLoader';
import { ExistingScribeModel, VSCSVGLoaderResource } from 'js/types';
import { SET_ELEMENT_IMAGE_RECOLOR, SetElementImageRecolorAction } from 'js/actionCreators/elementActions';
import { svgLoader } from 'js/playback/lib/Playback/helpers/resourceLoader/svgLoader';

import { getViewportData } from './sagaHelpers/getViewportData';
import { getScribeById } from './selectors';

export function* uploadResourceToGpu({
  project
}: UploadResourceToGpuAction): Generator<SelectEffect | CallEffect | PutEffect | TakeEffect, void, boolean> {
  if (PIXI.utils.isWebGLSupported()) {
    const playbackApp = getPixiPlaybackApp(getViewportData(project.canvasSize), project.settings.backgroundColor);
    const svgResources = svgLoader.resources as PIXI.utils.Dict<VSCSVGLoaderResource>;

    for (const svgResource of Object.values(svgResources)) {
      playbackApp.renderer.plugins.prepare.add(svgResource.metadata.strokesTexture);
      playbackApp.renderer.plugins.prepare.add(svgResource.metadata.fillsTexture);
    }

    yield call({
      fn: playbackApp.renderer.plugins.prepare.upload,
      context: playbackApp.renderer.plugins.prepare
    });
    yield put(uploadResourceToGpuComplete());
  }
}

export function loadResourcesIntoGpu({ scribe: project }: PrepScribeForEditorSuccessAction) {
  resourceLoader(project, true);
}

export const MANAGE_IMAGE_RESOURCE_SAGA_DELAY_MS = 3000;
export function* manageImageResourcesInGpu(
  action: CreateElementAction | SetElementImageRecolorAction | DeleteMultipleElementsByIdAction
): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined> {
  const { scribeId } = action;
  if (
    action.type === SET_ELEMENT_IMAGE_RECOLOR ||
    action.type === DELETE_MULTIPLE_ELEMENTS_BY_ID ||
    action.element.type === 'Image'
  ) {
    yield delay(MANAGE_IMAGE_RESOURCE_SAGA_DELAY_MS); // Delay the call so multiple CREATE_ELEMENT or DELETE_MULTIPLE_ELEMENTS_BY_ID actions can cancel via the takeLatest (multi-file upload)
    const project = yield select(getScribeById, scribeId);
    if (project) {
      yield call(resourceLoader, project, true);
    }
  }
}

export default function* gpuSagas() {
  yield takeEvery(UPLOAD_RESOURCE_TO_GPU, uploadResourceToGpu);
  yield takeEvery(PREP_SCRIBE_FOR_EDITOR_SUCCESS, loadResourcesIntoGpu);
  yield takeLatest(
    [CREATE_ELEMENT, SET_ELEMENT_IMAGE_RECOLOR, DELETE_MULTIPLE_ELEMENTS_BY_ID],
    manageImageResourcesInGpu
  );
}
