import { CheckoutState } from 'js/types';

import {
  CHECKING_UPGRADE,
  CHECKING_UPGRADE_COMPLETE,
  CHECK_UPGRADE,
  CheckingUpgradeAction,
  CheckingUpgradeCompleteAction,
  CheckUpgradeAction
} from '../actionCreators/checkoutActions';
import { RESET_STATE, ResetStateAction } from '../actionCreators/generalActions';

const initialState: CheckoutState = {
  step: 'INACTIVE'
};

type CheckoutAction = ResetStateAction | CheckUpgradeAction | CheckingUpgradeAction | CheckingUpgradeCompleteAction;

export default function checkoutReducer(state = initialState, action: CheckoutAction): CheckoutState {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState
      };
    }

    case CHECKING_UPGRADE: {
      return {
        ...state,
        step: 'CHECKING_UPGRADE'
      };
    }

    case CHECKING_UPGRADE_COMPLETE: {
      return {
        ...state,
        step: action.isTeamsPurchase ? 'ASSIGN_LICENSE_POPUP' : 'INACTIVE'
      };
    }

    case CHECK_UPGRADE: {
      return {
        ...state,
        step: 'CHECKING_UPGRADE'
      };
    }

    default:
      return state;
  }
}
