import React from 'react';

export default function IconAnimationDisappear() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      enableBackground="new 0 0 60 60"
      stroke="currentcolor"
      fill="currentcolor"
    >
      <rect x="28.5" y="15.5" width="3" height="5" />
      <rect x="28.5" y="40.4" width="3" height="5.1" />
      <rect x="15" y="29.2" width="5" height="3" />
      <rect x="40" y="29.2" width="5" height="3" />
      <rect x="17" y="15.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.0207 18.6565)" width="3" height="7.1" />
      <rect x="37.9" y="17.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.2949 34.9036)" width="7.1" height="3" />
      <rect x="15" y="40.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.2624 25.3903)" width="7.1" height="3" />
      <rect x="40" y="38.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.5378 41.6328)" width="3" height="7.1" />
    </svg>
  );
}
