export const SYNC_SCRIPT = 'SYNC_SCRIPT';
export const FETCH_SCRIPT_REQUESTED = 'FETCH_SCRIPT_REQUESTED';
export const FETCH_SCRIPT_SUCCEEDED = 'FETCH_SCRIPT_SUCCEEDED';
export const FETCH_SCRIPT_FAILED = 'FETCH_SCRIPT_FAILED';

export interface SyncScriptAction {
  type: typeof SYNC_SCRIPT;
  projectId: number;
  script: string;
}

export const syncScript = (projectId: number, script: string) => ({
  type: SYNC_SCRIPT,
  projectId,
  script
});

export interface FetchScriptRequestedAction {
  type: typeof FETCH_SCRIPT_REQUESTED;
  projectId: number;
}

export const fetchScriptRequested = (projectId: number) => ({
  type: FETCH_SCRIPT_REQUESTED,
  projectId
});

export interface FetchScriptSucceededAction {
  type: typeof FETCH_SCRIPT_SUCCEEDED;
  projectId: number;
  script: string;
}

export const fetchScriptSucceeded = (projectId: number, script: string) => ({
  type: FETCH_SCRIPT_SUCCEEDED,
  projectId,
  script
});

export interface FetchScriptFailedAction {
  type: typeof FETCH_SCRIPT_FAILED;
  projectId: number;
  error: Error;
}

export const fetchScriptFailed = (projectId: number, error: Error) => ({
  type: FETCH_SCRIPT_FAILED,
  projectId,
  error
});
