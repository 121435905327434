import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { UpgradeSubscriptionClickedEventTrigger } from 'js/actionCreators/trackingActions';
import { upgradeAccount } from 'js/actionCreators/userAccountAction';

import {
  DOWNLOAD_REQUEST,
  STOP_RENDER_STATUS_POLLING,
  downloadRequestSuccess,
  downloadRequestFailed,
  getVideoDownloadUrlSuccess,
  getVideoDownloadUrlFailed,
  GET_VIDEO_DOWNLOAD_URL_SUCCESS,
  START_RENDER_STATUS_POLLING,
  RENDER_FAILED,
  GET_VIDEO_DOWNLOAD_URL_FAILED,
  abortDownload,
  ABORT_DOWNLOAD,
  ABORT_DOWNLOAD_USER_ACTION,
  DOWNLOAD_SUCCESS_UPSELL_ACTION
} from '../actionCreators/downloadActions';
import { sendErrorToSentry } from '../logging';
import getScribeLengthMs from '../playback/lib/Playback/helpers/getScribeLengthMs';

import { getProjectById } from './selectors';
import getDownloadTrackingProperties from './sagaHelpers/getDownloadTrackingProperties';
import projectIsStillUploadingAssets from './sagaHelpers/projectIsStillUploadingAssets';
import { createDownloadAssetsFromProject } from './sagaHelpers/createDownloadAssetsFromProject';

function* requestDownload(action) {
  const state = yield select();

  const project = getProjectById(state, action.scribeId);

  if (!project) return;

  const userId = state.auth.currentUser?.id;

  if (!userId) return;

  const trackingProperties = yield getDownloadTrackingProperties(project, action.format, state.templates);

  try {
    if (projectIsStillUploadingAssets(project)) {
      throw new Error('PROJECT_IS_STILL_UPLOADING_IMAGES');
    }

    const scribeLength = getScribeLengthMs(project) / 1000;

    let assets = [];

    assets = createDownloadAssetsFromProject(project);

    const result = yield call(appServices.renderCloud, {
      scribeId: action.scribeId,
      format: action.format,
      assets,
      videoDuration: scribeLength
    });
    // will rename to requestId throughout app
    const { requestId: shortId, numSegments } = result;
    yield put(
      downloadRequestSuccess({
        numSegments,
        shortId,
        format: action.format,
        trackingProperties,
        scribeId: action.scribeId
      })
    );
  } catch (e) {
    sendErrorToSentry(e);
    yield put(downloadRequestFailed(e, trackingProperties, action.scribeId));
  }
}

function* requestVideoDownloadUrl(action) {
  try {
    const result = yield call(appServices.getVideoDownloadUrl, action.shortId, action.format);
    yield put(
      getVideoDownloadUrlSuccess({
        url: result,
        trackingProperties: action.trackingProperties,
        format: action.format,
        scribeId: action.scribeId
      })
    );
  } catch (e) {
    sendErrorToSentry(e);
    yield put(getVideoDownloadUrlFailed(e, action.trackingProperties, action.scribeId));
  }
}

function* listenToDownloadPollingStarted({ shortId, trackingProperties }) {
  const result = yield take([
    ABORT_DOWNLOAD_USER_ACTION,
    GET_VIDEO_DOWNLOAD_URL_SUCCESS,
    RENDER_FAILED,
    GET_VIDEO_DOWNLOAD_URL_FAILED
  ]);

  if (result.type === ABORT_DOWNLOAD_USER_ACTION) {
    yield put(abortDownload(shortId, trackingProperties));
  }
}

function* handleAbortedDownload({ shortId }) {
  try {
    yield call(appServices.abortRenderCloud, shortId);
  } catch (error) {
    console.error('Request to abort download failed', error);
  }
}

function* triggerDownloadSuccessUpsellAction() {
  yield put(upgradeAccount(UpgradeSubscriptionClickedEventTrigger.DOWNLOAD_SUCCESS_BANNER));
}

function* downloadSagas() {
  yield takeEvery(DOWNLOAD_REQUEST, requestDownload);
  yield takeEvery(STOP_RENDER_STATUS_POLLING, requestVideoDownloadUrl);
  yield takeEvery(START_RENDER_STATUS_POLLING, listenToDownloadPollingStarted);
  yield takeEvery(ABORT_DOWNLOAD, handleAbortedDownload);
  yield takeEvery(DOWNLOAD_SUCCESS_UPSELL_ACTION, triggerDownloadSuccessUpsellAction);
}

export default downloadSagas;
