/*
  In an ideal world this code would not be needed.

  As we are using two different libraries (Fabric.js for the editor canvas and Pixi.js for the playback) the
  position of text does not translate 1:1 as each library calculates the baseline of text using different methods
  which seem near impossible to override.

  To combat this this helper function will take the font and draw text on a Fabric.js canvas and a Pixi.js canvas
  then measure the difference in the height of the top of the text.

  This difference can then used to shift the text position in playback by that difference - meaning text in playback
  should match the position of the editor canvas.

  This all happens in the background but if you need to debug the measuring canvases then set an environment
  variable of `DEBUG_TEXT_BASELINE` to `true` and this will display them.
*/
import { fabric } from 'fabric';
import * as PIXI from 'pixi.js';
import chunk from 'lodash.chunk';
import getFontFamilyWithFallbackString from 'js/shared/helpers/getFontFamilyWithFallbackString';
import { getFontBaselineShift, storeFontBaselineShift } from 'js/shared/lib/LocalDatabase';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';

import { getFontFace } from './generateBitmapFonts';
import { getProjectFontsFromTextElements } from './getProjectFontsFromTextElements';

const HEIGHT = 270;
const WIDTH = 270;
const X_POS = 18;
const Y_POS = 60;
const FONT_SIZE = 100;
const TEST_STRING = 'Lorem ipsum';
const TARGET_PIXEL_HEX = 0x000000;
const targetColors: Array<number> = [];
PIXI.utils.hex2rgb(TARGET_PIXEL_HEX, targetColors);
const [targetRed, targetGreen, targetBlue] = targetColors;
const TARGET_PIXEL_COLOR = {
  red: targetRed,
  green: targetGreen,
  blue: targetBlue,
  alpha: 255
};
const TARGET_PIXEL_COLOR_RGBA = `rgba(${targetRed}, ${targetGreen}, ${targetBlue}, 1)`;
const BASELINE_SHIFT_CACHE: { [key: string]: number } = {};

let pixiApp: PIXI.Application;
let fabricCanvasElement: HTMLCanvasElement;
let debugCanvasElement: HTMLCanvasElement;
let fabricStaticCanvas: fabric.StaticCanvas;
let debugCanvasContext: CanvasRenderingContext2D | null;
function initCanvasesAndContexts() {
  if (!PIXI.utils.isWebGLSupported()) {
    throw new Error('WebGL not supported on this device. Unable to calculate font baseline shifts');
  }

  pixiApp = new PIXI.Application({
    width: WIDTH,
    height: HEIGHT,
    backgroundColor: 0xffff00
  });

  pixiApp.view.width = WIDTH;
  pixiApp.view.height = HEIGHT;
  pixiApp.view.style.position = 'fixed';
  pixiApp.view.style.top = '0px';
  pixiApp.view.style.right = '0px';
  pixiApp.view.style.display = 'none';
  pixiApp.view.id = 'pixi-font-baseline-canvas';

  if (import.meta.env.VITE_DEBUG_TEXT_BASELINE) {
    pixiApp.view.style.display = 'inline-block';
  }

  fabricCanvasElement = createCanvasElement({ id: 'fabric-font-baseline-canvas' });
  debugCanvasElement = createCanvasElement({
    verticalPos: 'bottom',
    horizontalPos: 'left',
    backgroundColor: 'blue',
    id: 'debug-font-baseline-canvas'
  });

  document.documentElement.appendChild(pixiApp.view);
  document.documentElement.appendChild(fabricCanvasElement);
  document.documentElement.appendChild(debugCanvasElement);

  fabricStaticCanvas = new fabric.StaticCanvas(fabricCanvasElement, {
    width: WIDTH,
    height: HEIGHT,
    backgroundColor: '#ffff00'
  });

  debugCanvasContext = debugCanvasElement.getContext('2d', { willReadFrequently: true });
}

interface CreateCanvasElementConfig {
  verticalPos?: 'top' | 'bottom';
  horizontalPos?: 'left' | 'right';
  backgroundColor?: string;
  id?: string;
}
function createCanvasElement({
  verticalPos = 'top',
  horizontalPos = 'left',
  backgroundColor = 'red',
  id
}: CreateCanvasElementConfig = {}) {
  const canvasElement = document.createElement('canvas');
  canvasElement.width = WIDTH;
  canvasElement.height = HEIGHT;
  canvasElement.style.position = 'fixed';
  canvasElement.style[verticalPos] = '0px';
  canvasElement.style[horizontalPos] = '0px';
  canvasElement.style.backgroundColor = backgroundColor;
  canvasElement.style.display = 'none';

  if (id) {
    canvasElement.id = id;
  }

  if (import.meta.env.VITE_DEBUG_TEXT_BASELINE) {
    canvasElement.style.display = 'inline-block';
  }

  return canvasElement;
}

function getFirstTargetPixelRow(pixels: Uint8ClampedArray) {
  const chunkedPixels = chunk(pixels, 4);
  const targetPixelIndex = chunkedPixels.findIndex(chunk => {
    const red = chunk[0];
    const green = chunk[1];
    const blue = chunk[2];
    const alpha = chunk[3];

    return (
      red === TARGET_PIXEL_COLOR.red &&
      green === TARGET_PIXEL_COLOR.green &&
      blue === TARGET_PIXEL_COLOR.blue &&
      alpha === TARGET_PIXEL_COLOR.alpha
    );
  });

  if (targetPixelIndex === -1) return 0;

  const row = Math.floor(targetPixelIndex / WIDTH);
  return row;
}

const clearCanvases = () => {
  pixiApp.stage.removeChildren();
  fabricStaticCanvas.remove(...fabricStaticCanvas.getObjects());
  debugCanvasContext?.clearRect(0, 0, debugCanvasElement.width, debugCanvasElement.height);
};

const makeCacheKey = (fontFamily: string, isBitmapFont: boolean) => (isBitmapFont ? `bmp-${fontFamily}` : fontFamily);
const getCachedBaselineShift = (fontFamily: string, isBitmapFont: boolean) =>
  BASELINE_SHIFT_CACHE[makeCacheKey(fontFamily, isBitmapFont)] || 0;
const cacheBaselineShift = (fontFamily: string, isBitmapFont: boolean, value: number) =>
  (BASELINE_SHIFT_CACHE[makeCacheKey(fontFamily, isBitmapFont)] = value);

export type Font = {
  fontFamily: string;
  fontWeight?: 'normal' | 'bold';
  fontStyle?: 'normal' | 'italic';
  text?: string;
};

export async function calculateFontBaselineShifts(textElements: ScribeTextElementModel[]) {
  const fonts = getProjectFontsFromTextElements(textElements);
  for (const font of fonts) {
    await calcFontBaselineShiftPercentage(font);
  }
}

export default function getFontBaselineShiftPercentage(fontFamily: string, isBitmapFont: boolean) {
  return getCachedBaselineShift(fontFamily, isBitmapFont);
}

export function calcFontBaselineShiftPercentage(font: Font): Promise<number> {
  if (!pixiApp) {
    initCanvasesAndContexts();
  }

  if (!debugCanvasContext) {
    throw new Error('Unable to calculate font baseline shifts as canvas context was null');
  }

  const { fontWeight = 'normal', fontStyle = 'normal', fontFamily } = font;
  const bitmapFontFace = getFontFace(fontFamily, fontStyle, fontWeight);
  const isInstalledBitmapFont =
    bitmapFontFace !== undefined && PIXI.BitmapFont.available[bitmapFontFace.name] !== undefined;

  return new Promise(async (resolve, reject) => {
    // Baseline calculation is called whenever bitmap font is updated
    // new result is then needed to be updated to cache
    if (!isInstalledBitmapFont) {
      const memoryCached = getCachedBaselineShift(fontFamily, false);
      if (memoryCached) {
        return resolve(memoryCached);
      }

      const dbCached = await getFontBaselineShift(fontFamily);
      if (dbCached) {
        BASELINE_SHIFT_CACHE[fontFamily] = dbCached.fontBaselineShift;
        cacheBaselineShift(fontFamily, false, dbCached.fontBaselineShift);
        return resolve(dbCached.fontBaselineShift);
      }
    }

    clearCanvases();

    const textToCompare = isInstalledBitmapFont && font.text ? font.text : TEST_STRING;
    const fabricText = new fabric.Text(textToCompare);
    fabricText.set({
      top: Y_POS,
      left: X_POS,
      fontFamily: getFontFamilyWithFallbackString(fontFamily),
      fontSize: FONT_SIZE,
      textAlign: 'left',
      fill: TARGET_PIXEL_COLOR_RGBA
    });

    fabricStaticCanvas.add(fabricText);
    fabricStaticCanvas.renderAll();

    // Getting accurate pixel data out of Fabric is troublesome so taking advantage
    // of the toDataURL method then printing this output into another canvas and measuring that
    const img = fabricStaticCanvas.toDataURL();
    const imgEl = document.createElement('img');
    imgEl.style.display = 'none';
    imgEl.src = img;

    const loadImageOntoCanvas = async () => {
      if (debugCanvasContext) {
        debugCanvasContext.drawImage(imgEl, 0, 0);

        const myImageData = debugCanvasContext.getImageData(0, 0, WIDTH, HEIGHT);
        const fabricY = getFirstTargetPixelRow(myImageData.data);

        if (import.meta.env.VITE_DEBUG_TEXT_BASELINE) {
          const line = new fabric.Line([0, fabricY, WIDTH, fabricY], {
            stroke: 'magenta',
            strokeWidth: 1,
            selectable: false,
            originX: 'center',
            originY: 'center'
          });
          fabricStaticCanvas.add(line);
        }
        fabricStaticCanvas.renderAll();

        let pixiText: PIXI.Text | PIXI.BitmapText;

        if (isInstalledBitmapFont) {
          pixiText = new PIXI.BitmapText(textToCompare, {
            fontName: bitmapFontFace.name,
            fontSize: FONT_SIZE,
            tint: TARGET_PIXEL_HEX
          });
        } else {
          pixiText = new PIXI.Text(textToCompare, {
            fontFamily: getFontFamilyWithFallbackString(fontFamily),
            fontSize: FONT_SIZE,
            fill: TARGET_PIXEL_HEX,
            align: 'left',
            trim: false
          });
        }

        pixiText.x = X_POS;
        pixiText.y = Y_POS;

        pixiApp.stage.addChild(pixiText);

        const renderTexture = PIXI.RenderTexture.create({
          width: pixiApp.renderer.width,
          height: pixiApp.renderer.height
        });
        pixiApp.renderer.render(pixiApp.stage, { renderTexture });

        const pixels = pixiApp.renderer.plugins.extract.pixels(renderTexture);
        const pixiY = getFirstTargetPixelRow(pixels);

        if (import.meta.env.VITE_DEBUG_TEXT_BASELINE) {
          const pixiLine = new PIXI.Graphics();
          pixiLine
            .lineStyle(2, 0xff00ff)
            .moveTo(0, pixiY)
            .lineTo(WIDTH, pixiY);
          pixiApp.stage.addChild(pixiLine);
        }

        // Clean up DOM
        if (!import.meta.env.VITE_DEBUG_TEXT_BASELINE) {
          // Clear canvases
          clearCanvases();

          // Remove elements from DOM
          imgEl.remove();
        }

        if (typeof pixiY !== 'undefined' && typeof fabricY !== 'undefined') {
          const diff = pixiY - fabricY;
          const percentage = diff / FONT_SIZE;
          cacheBaselineShift(fontFamily, isInstalledBitmapFont, percentage);
          if (!isInstalledBitmapFont) await storeFontBaselineShift(fontFamily, percentage);
          imgEl.removeEventListener('load', loadImageOntoCanvas);
          imgEl.removeEventListener('error', handleImageLoadError);
          return resolve(percentage);
        }

        imgEl.removeEventListener('load', loadImageOntoCanvas);
        imgEl.removeEventListener('error', handleImageLoadError);
        return resolve(0);
      }
    };

    const handleImageLoadError = (e: ErrorEvent) => {
      reject(e);
      imgEl.remove();
      imgEl.removeEventListener('load', loadImageOntoCanvas);
      imgEl.removeEventListener('error', handleImageLoadError);
    };

    imgEl.addEventListener('load', loadImageOntoCanvas);
    imgEl.addEventListener('error', handleImageLoadError);
    document.documentElement.appendChild(imgEl);
  });
}
