import * as PIXI from 'pixi.js';

import { plotPointsAlongPath } from '../../../../shared/helpers/trig';
import { SCRIBBLE_RESOLUTION } from '../../../../config/defaults';

const OVERLAP_SCALE_FACTOR = 0.1;

const generateScribblePathArray = (width: number, height: number, frequency = 20, xOffset = 0, yOffset = 0) => {
  const path = [{ x: 0, y: 0 }];
  let hiresPath = [{ x: 0, y: 0 }];
  const topAndSideLength = width + height;
  const stepSize = topAndSideLength / frequency;
  // Store the stepsize as the brush size so the reveal works at any scale

  const cursors = {
    top: {
      x: 0,
      y: 0
    },
    bottom: {
      x: 0,
      y: 0
    }
  };

  // Each loop move either the top or bottom cursor via the step size
  // (The top cursor will move along the top side and then down the right side
  // The bottom cursor will move down the left side and then along the bottom side)
  // and push the SVG path syntax into the path array.
  /* e.g. < ^ > v = cursor positions

          START              DURING EXAMPLE 1      DURING EXAMPLE 2             END
     v                          v                                v
  >  -----------------      -----------------      -----------------     -----------------
    |                 |   >|                 |    |                 |   |                 |
    |                 |    |                 |    |                 |   |                 |
     -----------------      -----------------      -----------------     -----------------<
                                                       ^                                 ^
  */
  // Generate the lo-res path first
  let i = 1;
  while (cursors.top.x < width || cursors.top.y < height || cursors.bottom.x < width || cursors.bottom.y < height) {
    // If number is odd
    if (i % 2 === 1) {
      // move along top
      if (cursors.top.x < width) {
        cursors.top.x += stepSize;
      } else if (cursors.top.y < height) {
        // then down side
        cursors.top.x = width;
        cursors.top.y += stepSize;
      } else {
        // we've reached the end
        cursors.top.x = width;
        cursors.top.y = height;
      }

      path.push({ x: cursors.top.x, y: cursors.top.y });
    } else {
      // move down size
      if (cursors.bottom.y < height) {
        cursors.bottom.y += stepSize;
      } else if (cursors.bottom.x < width) {
        // then along bottom
        cursors.bottom.x += stepSize;
        cursors.bottom.y = height;
      } else {
        cursors.bottom.x = width;
        cursors.bottom.y = height;
      }

      path.push({ x: cursors.bottom.x, y: cursors.bottom.y });
    }

    i++;
  }
  // Then fill inbetween each point with calculated points
  for (let i = 0; i <= path.length - 2; i++) {
    const plotPoints = plotPointsAlongPath(path[i], path[i + 1], SCRIBBLE_RESOLUTION);
    hiresPath = [...hiresPath, ...plotPoints];
  }

  const translatedPoints = hiresPath.map(point => {
    const { tx, ty } = new PIXI.Matrix(1, 0, 0, 1, point.x, point.y)
      .scale(1 + OVERLAP_SCALE_FACTOR, 1 + OVERLAP_SCALE_FACTOR)
      .translate(-(width * OVERLAP_SCALE_FACTOR) / 2, -(height * OVERLAP_SCALE_FACTOR) / 2);
    return { x: tx, y: ty };
  });

  const offsetPoints = translatedPoints.map(point => ({ x: point.x + xOffset, y: point.y + yOffset }));

  return { scribbleArray: offsetPoints, brushSize: stepSize };
};

export default generateScribblePathArray;
