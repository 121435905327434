function step(animationConfig, timestamp) {
  if (this.destroyed) return;

  const { firstTick, stepPreviousTimestamp, firstFrameDeltaAdjustment, endTime, endPlayback, updateScenes } =
    animationConfig;

  if (firstTick) {
    this.animation = window.requestAnimationFrame(step.bind(this, { ...animationConfig, firstTick: false }));

    this.app.renderer.once('postrender', () => {
      this.onTick(this.app.view, this.elapsedTime);
    });
    this.app.renderer.render(this.app.stage);
    return;
  }
  const delta = timestamp - (stepPreviousTimestamp ?? timestamp - firstFrameDeltaAdjustment);

  if (this.shouldPlay) {
    this.elapsedTime = this.elapsedTime + delta;
  }

  const hasReachedEndTime = this.elapsedTime >= endTime;
  if (hasReachedEndTime) {
    this.onReachedEndTime?.();

    if (this.shouldPlay) {
      this.onStop();
      window.cancelAnimationFrame(this.animation);
      return endPlayback();
    }
  }

  updateScenes(this.elapsedTime);

  this.animation = window.requestAnimationFrame(
    step.bind(this, { ...animationConfig, stepPreviousTimestamp: timestamp })
  );

  if (this.shouldPlay) {
    this.app.renderer.once('postrender', () => {
      this.onTick(this.app.view, this.elapsedTime);
    });
    this.app.renderer.render(this.app.stage);
  }
}

export default step;
