import { FILE_CONTENT_TYPES } from 'js/config/consts';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

import { processImageFileOnLoad } from './processImageFileOnLoad';

export async function cacheImagesForPlayback(
  imageElements: Array<ScribeImageElementModel>,
  blobUrls: Array<{ assetId: number; blobUrl: string }>
) {
  await Promise.all(
    imageElements
      .filter(imageEl => !imageEl._imageUrl)
      .map(async imageEl => {
        const urlResource = blobUrls.find(blobUrl => blobUrl.assetId.toString() === imageEl?.image?.assetId.toString());
        if (urlResource) {
          if (imageEl?.image?.contentType === FILE_CONTENT_TYPES.SVG) {
            const response = await window.fetch(urlResource.blobUrl);
            const blob = await response.blob();
            const { file: processedFile } = await processImageFileOnLoad({
              file: blob,
              scribeElement: imageEl
            });
            imageEl._imageUrl = URL.createObjectURL(processedFile);
          } else {
            imageEl._imageUrl = urlResource.blobUrl;
          }
        }
      })
  );
}
