import { call, takeEvery, select, SelectEffect, CallEffect } from 'redux-saga/effects';
import { getScribeById } from 'js/sagas/selectors';
import { TrackOpenScribeAction, TRACK_OPEN_SCRIBE } from 'js/actionCreators/trackingActions';
import {
  DeleteScribeAction,
  DELETE_SCRIBE,
  FILTER_SCRIBES_BY_CANVAS_SIZE,
  SortScribeAction,
  SORT_SCRIBES,
  FilterByCanvasSizeAction as FilterProjectsByCanvasSizeAction
} from 'js/actionCreators/scribeActions';
import {
  FILTER_TEMPLATES_BY_CANVAS_SIZE,
  SCROLL_TEMPLATE_CATEGORIES,
  SORT_TEMPLATES,
  FilterByCanvasSizeAction as FilterTemplatesByCanvasSizeAction,
  SortTemplatesAction,
  ScrollTemplateCategoriesAction
} from 'js/actionCreators/templatesActions';
import { TRACK_FILTER_TEMPLATES_BY_CATEGORY, TrackFilterByCategoryAction } from 'js/actionCreators/trackingActions';
import { ExistingScribeModel } from 'js/types';

import { track } from '../mixpanelProvider';

function* trackOpenScribe({
  scribeId
}: TrackOpenScribeAction): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined> {
  const scribe = yield select(state => getScribeById(state, scribeId));

  yield call(track, 'Open A Scribe', {
    'Scribe ID': scribeId,
    'Scribe Type': scribe?.source?.type || 'unknown'
  });
}

function* trackSortScribes({ sortOrder }: SortScribeAction) {
  let sortType;
  switch (sortOrder) {
    case 'alphaDescend':
      sortType = 'Sort Z-A';
      break;
    case 'alphaAscend':
      sortType = 'Sort A-Z';
      break;
    case 'updatedDate':
      sortType = 'Sort Recently Used';
      break;
    default:
      sortType = sortOrder;
      break;
  }
  yield call(track, 'Sort My Scribes', { 'Sort Type': sortType });
}

function* trackDeleteScribe({ scribe }: DeleteScribeAction) {
  yield call(track, 'Delete Scribe', { 'Scribe ID': scribe.id });
}

function* trackCanvasSizeFilter({ size, type }: FilterProjectsByCanvasSizeAction | FilterTemplatesByCanvasSizeAction) {
  let filterType;
  switch (size) {
    case 'allSizes':
      filterType = 'Any';
      break;
    case 'landscape':
      filterType = '16:9';
      break;
    case 'square':
      filterType = '1:1';
      break;
    case 'portrait':
      filterType = '9:16';
      break;
    default:
      filterType = size;
      break;
  }
  yield call(
    track,
    type === FILTER_SCRIBES_BY_CANVAS_SIZE ? 'Filter Projects By Orientation' : 'Filter Templates By Orientation',
    {
      'Filter Type': filterType
    }
  );
}

function* trackSortTemplates({ sortOrder }: SortTemplatesAction) {
  let sortType;
  switch (sortOrder) {
    case 'alphaDescend':
      sortType = 'Sort Z-A';
      break;
    case 'alphaAscend':
      sortType = 'Sort A-Z';
      break;
    case 'createdDate':
      sortType = 'Recently Added';
      break;
    case 'default':
      sortType = 'Default';
      break;
    default:
      sortType = sortOrder;
      break;
  }
  yield call(track, 'Sort Templates', { 'Sort Type': sortType });
}

function* trackScrollCategories({ eventTrigger }: ScrollTemplateCategoriesAction) {
  yield call(track, 'Scroll Template Categories', { 'Event Trigger': eventTrigger });
}

function* trackFilterTemplatesByCategory({ eventTrigger, categoryTitle }: TrackFilterByCategoryAction) {
  yield call(track, 'View Template Category', {
    'Event Trigger': eventTrigger,
    'Template Category': categoryTitle
  });
}

export default function* trackScribeListSagas() {
  yield takeEvery(TRACK_OPEN_SCRIBE, trackOpenScribe);
  yield takeEvery(SORT_SCRIBES, trackSortScribes);
  yield takeEvery(DELETE_SCRIBE, trackDeleteScribe);
  yield takeEvery([FILTER_SCRIBES_BY_CANVAS_SIZE, FILTER_TEMPLATES_BY_CANVAS_SIZE], trackCanvasSizeFilter);
  yield takeEvery(SORT_TEMPLATES, trackSortTemplates);
  yield takeEvery(SCROLL_TEMPLATE_CATEGORIES, trackScrollCategories);
  yield takeEvery(TRACK_FILTER_TEMPLATES_BY_CATEGORY, trackFilterTemplatesByCategory);
}
