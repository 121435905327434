import { CallEffect, TakeEffect, call, select, take, takeEvery, SelectEffect } from 'redux-saga/effects';
import {
  PlayButtonAction,
  TrackPlayScribeAction,
  TRACK_PLAY_SCRIBE,
  TRACK_TIMELINE_SCROLL_TO_BEGINNING,
  TRACK_TIMELINE_SCROLL_TO_END,
  TRACK_TIMELINE_ZOOM_EXPANDED
} from 'js/actionCreators/trackingActions';
import { TOGGLE_NEW_TIMELINE } from 'js/actionCreators/editorActions';
import { PLAYBACK_IS_READY, playbackIsReady } from 'js/actionCreators/playbackActions';
import { UNLOAD_PLAYBACK, UnloadPlaybackAction } from 'js/actionCreators/uiActions';
import { ExistingScribeModel } from 'js/types';
import { isTextElement } from 'js/editor/EditorCanvas/helpers/canvasElementHelpers';

import { timeEvent, track } from '../mixpanelProvider';
import { getScribeById } from '../../selectors';
import getTextElementsFontData from '../../sagaHelpers/getTextElementsFontData';

const actions: Record<PlayButtonAction, string> = {
  fromElement: 'Play Video From Element',
  fromScene: 'Play Video From Scene',
  fromStart: 'Play Video From Start'
};

const PLAYBACK_LOADED_EVENT_NAME = 'Playback Loaded';

function* trackPlayScribe({
  button = 'fromStart',
  scribeId,
  sceneCount,
  eventTrigger
}: TrackPlayScribeAction): Generator<
  SelectEffect | CallEffect | TakeEffect,
  void,
  ExistingScribeModel | undefined | UnloadPlaybackAction | ReturnType<typeof playbackIsReady>
> {
  const action = actions[button] || '';
  const scribe = (yield select(getScribeById, scribeId)) as ExistingScribeModel | undefined;
  if (!scribe) return;
  const scribeTextElements = scribe.elements.filter(isTextElement);
  const scribeCharTotal = scribeTextElements.reduce(
    (charTotal, element) => charTotal + (element?.text?.length || 0),
    0
  );
  const { fontFaces, totalFontFaces } = getTextElementsFontData(scribeTextElements);
  const eventPayload = {
    'Scribe ID': scribeId,
    'Number of Scenes': sceneCount,
    'Event Trigger': eventTrigger,
    'Total Number of Characters': scribeCharTotal,
    'Font Faces Used': fontFaces,
    'Total Number of Font Faces': totalFontFaces
  };

  yield call(track, action, eventPayload);
  yield call(timeEvent, PLAYBACK_LOADED_EVENT_NAME);

  const nextAction = (yield take([PLAYBACK_IS_READY, UNLOAD_PLAYBACK])) as
    | UnloadPlaybackAction
    | ReturnType<typeof playbackIsReady>;
  if (nextAction.type === PLAYBACK_IS_READY) {
    yield call(track, PLAYBACK_LOADED_EVENT_NAME, { ...eventPayload, 'Playback Trigger': action });
  }
}

function* trackTimelineZoomExpanded() {
  yield call(track, 'Set Timeline View');
}

function* trackTimelineActivation() {
  const { newTimeline } = yield select(state => state.ui);
  yield call(track, `Turn Timeline ${newTimeline ? 'On' : 'Off'}`);
}

export default function* trackTimelineSagas() {
  yield takeEvery(TRACK_PLAY_SCRIBE, trackPlayScribe);
  yield takeEvery(TRACK_TIMELINE_ZOOM_EXPANDED, trackTimelineZoomExpanded);
  yield takeEvery(TRACK_TIMELINE_SCROLL_TO_BEGINNING, track, 'Scroll to Timeline Beginning');
  yield takeEvery(TRACK_TIMELINE_SCROLL_TO_END, track, 'Scroll to Timeline End');
  yield takeEvery(TOGGLE_NEW_TIMELINE, trackTimelineActivation);
}
