import { call, takeEvery, select } from 'redux-saga/effects';
import { SET_ELEMENT_CURSOR } from 'js/actionCreators/cursorActions';
import { findCursorById } from 'js/selectors/findCursorById';
import { SET_ELEMENTS_ANIMATION_CURSOR } from 'js/actionCreators/animationConfigActions';

import { track } from '../mixpanelProvider';
import { getScribeById } from '../../selectors';

function* trackElementCursorSelection({ scribeId, elementIds, cursorId, stage }) {
  const activeScribe = yield select(state => getScribeById(state, scribeId));
  const cursor = yield select(state => findCursorById(state.cursors, cursorId));

  yield call(track, 'Select Element Cursor', {
    'Cursor Category': cursor ? cursor.group : 'No Cursor',
    'Cursor Name': cursor ? cursor.name : 'No Cursor',
    'Element Type':
      elementIds.length === 1 ? activeScribe.elements.find(element => element.id === elementIds[0]).type : 'Multiple',
    'Scribe ID': scribeId,
    'Number of Elements': elementIds.length,
    'Animation Stage': stage
  });
}

export default function* trackElementCursorSelectionSagas() {
  yield takeEvery([SET_ELEMENT_CURSOR, SET_ELEMENTS_ANIMATION_CURSOR], trackElementCursorSelection);
}
