import Bottleneck from 'bottleneck';
import { getSparkolToken, setSparkolCookies } from 'js/shared/helpers/TokenHelper';

import { refreshUser } from './authHelper';
import { rateLimitedfetch } from './rateLimitedFetch';
import { logOutAndReload } from './logOutAndReload';
import UserModel from './app-services/UserModel';

const refreshAuthToken = async (getUserByToken: (token: string) => Promise<UserModel>) => {
  const authResponse = await refreshUser();
  const idToken = authResponse.idToken.jwtToken;
  const user = await getUserByToken(idToken);
  const { token, tokenTtl } = user;
  setSparkolCookies({ token, tokenTtl, user });
};

export interface FetchOptions {
  headers?: Record<string, string>;
}

export async function fetchWithRefreshAuth(
  customFetch: (url: string, options: FetchOptions) => Promise<Response>,
  url: string,
  getUserByToken: (id: string) => Promise<UserModel>,
  options: FetchOptions
) {
  const headers = options.headers;
  const hasSparkolToken = headers && !!headers['x-spk-auth-token'];
  const isRenderer = headers && !!headers['x-spk-renderer-auth'];
  // refresh session if no token
  if (!hasSparkolToken && !isRenderer) {
    await refreshAuthToken(getUserByToken);
    options.headers = { ...options.headers, 'x-spk-auth-token': getSparkolToken() };
  }
  const response = await customFetch(url, options).catch(() => {
    // cloudfront auth error
    return {
      status: 403
    };
  });
  // refresh session and retry on auth error
  if (response.status === 403) {
    await refreshAuthToken(getUserByToken);
    const optionsNewToken = {
      ...options,
      headers: {
        ...options.headers,
        'x-spk-auth-token': getSparkolToken()
      }
    };
    return customFetch(url, optionsNewToken);
  }
  // refresh session and retry on services auth error
  if (response.status === 400) {
    const responseError = response as Response;
    const { err } = await responseError.json();

    if (err === 'auth_token_invalid') {
      await refreshAuthToken(getUserByToken);
      const optionsNewToken = {
        ...options,
        headers: {
          ...options.headers,
          'x-spk-auth-token': getSparkolToken()
        }
      };
      return customFetch(url, optionsNewToken);
    }
  }
  return response;
}

export async function fetchWithReAuth(
  url: string,
  getUserByToken: (token: string) => Promise<UserModel>,
  options: FetchOptions
) {
  return fetchWithRefreshAuth(rateLimitedfetch, url, getUserByToken, options);
}

export function makeServicesFetch({
  limit,
  maxConcurrent = 1,
  reloadOnFail = true,
  getUserByToken
}: {
  limit: number;
  maxConcurrent?: number;
  reloadOnFail?: boolean;
  getUserByToken: (token: string) => Promise<UserModel>;
}) {
  const limiter = new Bottleneck({
    maxConcurrent,
    minTime: 1000 / limit
  });
  const customFetch = limiter.wrap(window.fetch);
  return async (url: string, options: FetchOptions) => {
    return (
      fetchWithRefreshAuth(customFetch, url, getUserByToken, options)
        .catch(error => {
          if (error === 'Failed to refresh Cognito user') {
            return { status: 401 };
          }
          throw error;
        })
        .then(result => {
          if (reloadOnFail && result.status === 401) {
            setTimeout(logOutAndReload, 0);
          }
          return result;
        })
    );
  };
}
