import { SHAKE_LOOP_KEY } from 'js/config/consts';
import { ShakeTweenAnimation, TweenAnimation } from 'js/types';

export const getShakeEmphasisTween = (direction: string): TweenAnimation => {
  const tween: ShakeTweenAnimation = {
    id: SHAKE_LOOP_KEY,
    tweens_in: [
      {
        direction,
        ease: 'none',
        percentage_of_total: 0.25
      },
      {
        ease: 'none',
        percentage_of_total: 0.25
      }
    ],
    tweens_out: [
      {
        ease: 'none',
        percentage_of_total: 0.25
      },
      {
        ease: 'none',
        percentage_of_total: 0.25
      }
    ]
  };

  switch (direction) {
    case 't':
      tween.tweens_in[0].x = '+=0';
      tween.tweens_in[0].y = '-=80';

      tween.tweens_in[1].x = '+=0';
      tween.tweens_in[1].y = '+=80';

      tween.tweens_out[0].x = '+=0';
      tween.tweens_out[0].y = '+=80';

      tween.tweens_out[1].x = '+=0';
      tween.tweens_out[1].y = '-=80';
      return tween;
    case 'bl':
      tween.tweens_in[0].x = '-=80';
      tween.tweens_in[0].y = '+=80';

      tween.tweens_in[1].x = '+=80';
      tween.tweens_in[1].y = '-=80';

      tween.tweens_out[0].x = '+=80';
      tween.tweens_out[0].y = '-=80';

      tween.tweens_out[1].x = '-=80';
      tween.tweens_out[1].y = '+=80';
      return tween;
    case 'br':
      tween.tweens_in[0].x = '+=80';
      tween.tweens_in[0].y = '+=80';

      tween.tweens_in[1].x = '-=80';
      tween.tweens_in[1].y = '-=80';

      tween.tweens_out[0].x = '-=80';
      tween.tweens_out[0].y = '-=80';

      tween.tweens_out[1].x = '+=80';
      tween.tweens_out[1].y = '+=80';
      return tween;
    case 'r':
      tween.tweens_in[0].x = '+=80';
      tween.tweens_in[1].x = '-=80';

      tween.tweens_out[0].x = '-=80';
      tween.tweens_out[1].x = '+=80';
      return tween;
    case 'tl':
      tween.tweens_in[0].x = '-=80';
      tween.tweens_in[0].y = '-=80';

      tween.tweens_in[1].x = '+=80';
      tween.tweens_in[1].y = '+=80';

      tween.tweens_out[0].x = '+=80';
      tween.tweens_out[0].y = '+=80';

      tween.tweens_out[1].x = '-=80';
      tween.tweens_out[1].y = '-=80';
      return tween;
    case 'b':
      tween.tweens_in[0].x = '+=0';
      tween.tweens_in[0].y = '+=80';

      tween.tweens_in[1].x = '+=0';
      tween.tweens_in[1].y = '-=80';

      tween.tweens_out[0].x = '+=0';
      tween.tweens_out[0].y = '-=80';

      tween.tweens_out[1].x = '+=0';
      tween.tweens_out[1].y = '+=80';
      return tween;
    case 'tr':
      tween.tweens_in[0].x = '+=80';
      tween.tweens_in[0].y = '-=80';

      tween.tweens_in[1].x = '-=80';
      tween.tweens_in[1].y = '+=80';

      tween.tweens_out[0].x = '-=80';
      tween.tweens_out[0].y = '+=80';

      tween.tweens_out[1].x = '+=80';
      tween.tweens_out[1].y = '-=80';
      return tween;
    default:
      // left
      tween.tweens_in[0].x = '-=80';
      tween.tweens_in[1].x = '+=80';

      tween.tweens_out[0].x = '+=80';
      tween.tweens_out[1].x = '-=80';
      return tween;
  }
};
