import * as PIXI from 'pixi.js';
import PlusIcon from 'imgs/pixi_icons/IconPlus.svg';
import MinusIcon from 'imgs/pixi_icons/IconMinus.svg';

import Maths from './Utils/Maths';
import TimelineHelpers from './Utils/TimelineHelpers';

const AMOUNT_TO_ZOOM = 0.2;
export default class TimelineDragHandle extends PIXI.Container {
  size: number;
  maxVal: number;
  minVal: number;
  bg: PIXI.Graphics;
  handle: PIXI.Graphics;
  startDragPos?: PIXI.Point;
  isDragging?: boolean;
  updatedDragPos?: PIXI.Point;
  currentValue: number;
  scaleFn?: (arg: number) => number;
  plusButton: PIXI.Sprite;
  minusButton: PIXI.Sprite;
  isVertical: boolean;

  constructor({
    minVal,
    maxVal,
    size,
    scaleFn,
    currentZoomVal,
    angle = 0
  }: {
    minVal: number;
    maxVal: number;
    size: number;
    scaleFn?: (arg: number) => number;
    currentZoomVal?: number;
    angle?: number;
  }) {
    super();
    this.minVal = minVal;
    this.maxVal = maxVal;
    this.size = size;

    this.currentValue = currentZoomVal ? (currentZoomVal - this.minVal) / (this.maxVal - this.minVal) : this.minVal;

    if (scaleFn) this.scaleFn = scaleFn;

    this.bg = new PIXI.Graphics();
    this.bg.beginFill(0x7b7b7b);
    this.bg.drawRect(-1, 0, this.size, 2);
    this.bg.endFill();
    this.bg.hitArea = new PIXI.Rectangle(0, -4, this.size, 10);
    this.bg.interactive = true;
    this.bg.on('pointerdown', this.moveToZoom);

    this.interactive = true;
    this.buttonMode = true;

    this.handle = new PIXI.Graphics();
    this.handle.beginFill(0xffffff);
    this.handle.drawCircle(0, 1, 5);
    this.handle.endFill();
    this.handle.interactive = true;

    this.addChild(this.bg);
    this.addChild(this.handle);

    this.on('pointerdown', this.startDrag);
    this.on('pointermove', this.doDrag);
    this.on('pointerup', this.stopDrag);
    this.on('pointerupoutside', this.stopDrag);

    PIXI.Ticker.shared.add(this.update);

    this.update(0, true);

    this.angle = angle;
    this.isVertical = angle === 270;

    this.plusButton = this.addZoomInButton();
    this.minusButton = this.addZoomOutButton();
  }

  updateMaxMinVal(min: number, max: number) {
    this.minVal = min;
    this.maxVal = max;
  }
  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    if (this.destroyed) return;
    PIXI.Ticker.shared.remove(this.update);
    super.destroy(options);
  }

  updateSize(size: number) {
    if (this.destroyed) return;
    this.size = size;
    if (!this.bg) return;
    this.bg.clear();
    this.bg.beginFill(0x7b7b7b);
    this.bg.drawRect(-1, 0, this.size, 2);
    this.bg.endFill();
    this.bg.hitArea = new PIXI.Rectangle(0, -4, this.size, 10);

    if (this.handle) this.handle.x = this.currentValue * this.size;
    this.plusButton.x = this.size + 8;
  }

  emitUpdate(options?: { instant?: boolean }) {
    const instant = options?.instant;
    this.emit('update', this.minVal + this.currentValue * (this.maxVal - this.minVal), this.isDragging, instant);
  }

  startDrag = (evt: PIXI.InteractionEvent) => {
    const pos = evt.data.getLocalPosition(this);
    this.isDragging = true;
    this.startDragPos = pos;
    this.emit('handleStartDrag');
    this.doDrag(evt);
  };

  doDrag = (evt: PIXI.InteractionEvent) => {
    if (this.isDragging) {
      this.updatedDragPos = Maths.asPIXIPoint(evt.data.getLocalPosition(this));
    }
  };

  stopDrag = () => {
    this.emit('handleStopDrag');
    this.isDragging = false;
  };

  moveToZoom = (event: PIXI.InteractionEvent) => {
    event.stopPropagation();
    this.emit('handleStartDrag');
    const pos = event.data.getLocalPosition(this);
    this.isDragging = true;
    this.startDragPos = pos.clone();
    this.updatedDragPos = pos.clone();
    const { x } = Maths.asPIXIPoint(event.data.getLocalPosition(this));
    if (x === undefined || x === null || isNaN(x)) {
      return;
    }

    const elementWidth = this.bg.width;
    const percentageOfElementClickHappenedOn = (100 / elementWidth) * x;

    this.currentValue = percentageOfElementClickHappenedOn / 100;
    this.emitUpdate({ instant: true });
  };

  clamp(input: number, min: number, max: number): number {
    if (input < min) return min;
    if (input > max) return max;
    return input;
  }

  /**
   *
   * @param deltaTimeMultiplier this is a multiplier supplied by pixi ticker to adjust for difference in update time for smooth movement.
   * @param immediate don't use the transition animation.
   */
  update = (_deltaTimeMultiplier: number, immediate?: boolean): void => {
    if (this.isDragging || immediate) {
      if (this.isDragging) {
        this.updateTargetVal();
      }
      this.currentValue = this.clamp(this.currentValue, 0, 1);
      this.updateDisplay(this.currentValue);
      if (this.scaleFn) this.currentValue = this.scaleFn(this.currentValue);
      this.emitUpdate({ instant: immediate });
    }
  };

  updateTargetVal() {
    if (this.updatedDragPos && this.isDragging) {
      this.currentValue = this.updatedDragPos.x / this.size;
    } else {
      this.currentValue = this.handle.x / this.size;
    }
  }

  getRangeValue(zoomValue: number) {
    const min = zoomValue - this.minVal;
    const max = this.maxVal - this.minVal;

    const res = !min && !max ? AMOUNT_TO_ZOOM : min / max;

    return res;
  }

  updateDisplay(newValue?: number) {
    const valueToUse = newValue ?? this.currentValue;
    if (this.handle) this.handle.x = valueToUse * this.size;
  }

  zoomIn = (event: PIXI.InteractionEvent) => {
    event.stopPropagation();
    this.emit('handleStartDrag');

    this.currentValue = this.clamp(this.currentValue + AMOUNT_TO_ZOOM, 0, 1);
    this.isDragging = false;
    this.updateDisplay();
    this.emitUpdate({ instant: true });
  };

  zoomOut = (event: PIXI.InteractionEvent) => {
    event.stopPropagation();
    this.emit('handleStartDrag');
    this.currentValue = this.clamp(this.currentValue - AMOUNT_TO_ZOOM, 0, 1);
    this.isDragging = false;
    this.updateDisplay();
    this.emitUpdate({ instant: true });
  };

  addZoomInButton = () => {
    const zoomInButton = new PIXI.Sprite(TimelineHelpers.importSVG(PlusIcon));
    zoomInButton.y = this.y - 3;
    zoomInButton.x = this.size + 8;
    zoomInButton.height = 8;
    zoomInButton.width = 8;
    zoomInButton.interactive = true;
    zoomInButton.on('pointerdown', this.zoomIn);
    zoomInButton.hitArea = new PIXI.Rectangle(-1, -1, 25, 25);
    this.addChild(zoomInButton);
    return zoomInButton;
  };

  addZoomOutButton = () => {
    const zoomOutButton = new PIXI.Sprite(TimelineHelpers.importSVG(MinusIcon));
    zoomOutButton.y = this.isVertical ? this.y - 3 : this.y;
    zoomOutButton.x = this.isVertical ? this.x - 10 : this.x - 16;
    zoomOutButton.height = 2;
    zoomOutButton.width = 8;
    zoomOutButton.interactive = true;
    zoomOutButton.on('pointerdown', this.zoomOut);
    zoomOutButton.hitArea = new PIXI.Rectangle(-1, -9, 25, 25);
    zoomOutButton.angle = this.isVertical ? 90 : 0;

    this.addChild(zoomOutButton);

    return zoomOutButton;
  };
}
