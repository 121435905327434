import round from 'lodash.round';
import clamp from 'lodash.clamp';
import { convert, svgRootFromText } from 'js/shared/lib/SVGConvert';
import svgSplitter from 'js/playback/lib/Playback/helpers/svgSplitter';
import { generateFallbackRevealPath } from 'js/playback/lib/Playback/helpers/generateFallbackRevealPath';
import { DRAW_SPEED, MIN_DEFAULT_ANIMATION_TIME, MAX_DEFAULT_ANIMATION_TIME } from 'js/config/defaults';
import { animationTime as IMAGE_DEFAULT_ANIMATION_TIME } from 'js/shared/resources/scribedefaults';
import { FILE_CONTENT_TYPES } from 'js/config/consts';

import { getImagePixelsFromUrl } from './getImagePixelsFromUrl';

function getSvgPathsDistance(paths) {
  return Array.from(paths).reduce((acc, path) => {
    return acc + path.getTotalLength?.();
  }, 0);
}

async function getPixelsFromFile({ file }) {
  const url = URL.createObjectURL(file);
  const pixels = await getImagePixelsFromUrl({ url });

  URL.revokeObjectURL(url);

  return pixels;
}

async function getFallbackRevealPathLength(width, height, file) {
  const pixels = await getPixelsFromFile({ file });
  const [fallbackPath] = await generateFallbackRevealPath({ element: { width, height }, pixels });
  const { totalLength } = fallbackPath;
  return totalLength;
}

export default async function getSvgDefaultAnimationTimingFromFile(file, width, height) {
  if (file.type !== FILE_CONTENT_TYPES.SVG) {
    return;
  }

  const svgText = await file.text();
  const svg = svgRootFromText(svgText);
  const svgString = convert(svg.outerHTML);
  const svgParts = svgSplitter(svgString);

  const strokesDistance = getSvgPathsDistance(svgParts.strokes);

  if (strokesDistance === 0) {
    return IMAGE_DEFAULT_ANIMATION_TIME;
  }

  const revealsDistance =
    !svgParts.revealPaths.length && !!svgParts.fills.length
      ? await getFallbackRevealPathLength(width, height, file)
      : getSvgPathsDistance(svgParts.revealPaths);

  const totalDistance = strokesDistance + revealsDistance;

  const animationTime = round(totalDistance / DRAW_SPEED, 1);

  return clamp(animationTime, MIN_DEFAULT_ANIMATION_TIME, MAX_DEFAULT_ANIMATION_TIME);
}
