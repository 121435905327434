export const findIndexOfLastActiveElement = (
  activeElements: Array<string>,
  sceneElements: Array<string> | undefined
) => {
  if (activeElements.length === 0 || !sceneElements || sceneElements.length === 0) {
    return undefined;
  }

  const allIdsInScene = activeElements.every(elId => sceneElements.includes(elId));

  if (!allIdsInScene) {
    return undefined;
  }

  return activeElements.reduce((lastIndex, elementId) => {
    const currentIndex = sceneElements.findIndex(scId => scId === elementId);

    if (currentIndex === -1) {
      return lastIndex;
    }

    return lastIndex > currentIndex ? lastIndex : currentIndex;
  }, 0);
};
