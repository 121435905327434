import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';
import { VSElementModel } from 'js/types';

export function stripElementForRenderer(element: VSElementModel) {
  if (element.type === 'Camera') return;

  element.type = 'Shape';
  (element as ScribeShapeElementModel).fill = '#000000';
  (element as ScribeShapeElementModel).shapeType = 'circle';
  element.width = 10;
  element.height = 10;
  element.strippedInRenderer = true;
}
