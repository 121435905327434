export const BASE_PATH = import.meta.env.VITE_ASSET_PATH || '';

export const AUTH = '/auth/';
export const PREVIEW_PATH = 'preview';
export const TEMPLATES_PATH = '/templates/';

export const ROUTE_CODES = {
  HOME: '/',

  AUTH,
  LOGIN: `${AUTH}login/`,

  SCRIBE_NEW: '/new-project/',
  SCRIBE_DELETE: '/delete/:scribeId',
  TEMPLATE_PREVIEW: '/template-preview/:templateId',
  TEMPLATES_CATEGORY: `${TEMPLATES_PATH}:categoryName?`,

  RENDER: '/render/:scribeId/:startTime?/:duration?',
  PREVIEW: `/${PREVIEW_PATH}/:publicSharingId`,

  EDITOR: '/editor/:scribeId',
  SHARE: '/share/',
  DOWNLOAD: '/download/',

  ELEM_ADD: '/add/:elementType',
  HANDS: '/hands/',
  OPTIONS: '/options/',
  ELEM_REPLACE: '/replace/:elementType/',

  CONTENTFUL_CALLBACK: '/contentful-callback/',

  ENABLE_COLOR_CHANGE: '/enable-color-change/'
};
