import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './LoadingSpinner.css';
import Spinner from '../Spinner';

export default function LoadingSpinner({
  message = 'Loading...',
  inline = false,
  backdrop = true,
  shadow = true,
  delay
}: {
  message?: string;
  inline?: boolean;
  backdrop?: boolean;
  shadow?: boolean;
  delay?: number;
}) {
  const [delayRender, setDelayRender] = useState(!!delay);

  const loadingSpinnerClassNames = classnames('loading', {
    'loading--inline': inline,
    'loading--backdrop': backdrop,
    'loading--shadow': shadow
  });

  useEffect(() => {
    let cancelled = false;
    if (delay) {
      window.setTimeout(() => {
        if (!cancelled) {
          setDelayRender(false);
        }
      }, delay);
    }

    return () => {
      cancelled = true;
    };
  }, [delay]);

  return delayRender ? null : (
    <div className={loadingSpinnerClassNames}>
      <div className="loading__content">
        <Spinner />
        <div className="loading__text">{message}</div>
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  message: PropTypes.string
};
