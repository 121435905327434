import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { APP_TOOLTIP_ID } from 'js/config/consts';

import './PillButton.css';

type OnClickEvent = React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>;

interface Props {
  linkTo?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'ai';
  disabled?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  datatip?: string;
  dataTestId?: string;
  pulse?: boolean;
  onClick?: (e: OnClickEvent) => void;
  id?: string;
  iconPosition?: 'left' | 'right';
  className?: string;
}

const PillButton = ({
  linkTo,
  variant = 'primary',
  disabled = false,
  children,
  icon,
  datatip,
  dataTestId,
  pulse,
  onClick,
  iconPosition = 'left',
  className,
  ...rest
}: Props) => {
  const hasIcon = !!icon;
  const buttonClasses = classNames(
    className,
    'pill-button',
    {
      'pill-button--primary': variant === 'primary',
      'pill-button--secondary': variant === 'secondary',
      'pill-button--tertiary': variant === 'tertiary',
      'pill-button--quaternary': variant === 'quaternary',
      'pill-button--ai': variant === 'ai',
      'pill-button--pulse': pulse
    },
    {
      'pill-button--disabled': disabled
    }
  );

  const handleClick = (e: OnClickEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick !== undefined) {
      onClick(e);
    }
  };

  const Icon = <span className="pill-button__icon">{icon}</span>;
  const Content = (
    <>
      {hasIcon && iconPosition === 'left' && Icon}
      <span className="pill-button__label">{children}</span>
      {hasIcon && iconPosition === 'right' && Icon}
    </>
  );

  return !!linkTo ? (
    <Link
      className={buttonClasses}
      to={linkTo}
      data-for={APP_TOOLTIP_ID}
      data-tip={datatip}
      aria-label={datatip}
      data-testid={dataTestId}
      onClick={onClick ? handleClick : undefined}
      {...rest}
    >
      {Content}
    </Link>
  ) : (
    <button
      className={buttonClasses}
      data-for={APP_TOOLTIP_ID}
      data-tip={datatip}
      aria-label={datatip}
      data-testid={dataTestId}
      onClick={onClick ? handleClick : undefined}
      {...rest}
    >
      {Content}
    </button>
  );
};

export default PillButton;
