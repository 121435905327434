// Splits an SVG String into the component DOM parts needed for animating reveal draws
const svgSplitter = (svgString = `<svg></svg>`) => {
  // Parse the original SVG string into a DOM element
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'text/html');

  // querySelectorAll CSS selectors
  const metaSelector = 'svg > #vs-meta';
  const revealPathsSelector = 'svg > #vs-reveal path';
  const strokePathSelector = 'svg > #vs-outline path';
  const fillPathSelector = 'svg > #vs-colour';

  const meta = doc.querySelectorAll<SVGGeometryElement>(metaSelector);
  const revealPaths = doc.querySelectorAll<SVGGeometryElement>(revealPathsSelector);
  const strokes = doc.querySelectorAll<SVGGeometryElement>(strokePathSelector);
  const fills = doc.querySelectorAll<SVGGeometryElement>(fillPathSelector);

  return {
    meta,
    revealPaths,
    strokes,
    fills
  };
};

export default svgSplitter;
