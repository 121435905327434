import { DrawType, ElementAnimationStage, ScribeCursorId } from 'js/types';

export const SET_ELEMENTS_ANIMATION_CURSOR = 'SET_ELEMENTS_ANIMATION_CURSOR';
export const SET_ELEMENTS_ANIMATION_DRAW_TYPE = 'SET_ELEMENTS_ANIMATION_DRAW_TYPE';

interface SetElementsAnimationConfigPayload {
  cursorId: ScribeCursorId;
  elementIds: Array<string>;
  stage: ElementAnimationStage;
  scribeId: number;
}

export const setElementsAnimationCursor = ({
  elementIds,
  cursorId,
  stage,
  scribeId
}: SetElementsAnimationConfigPayload): SetElementsAnimationCursorAction => ({
  type: SET_ELEMENTS_ANIMATION_CURSOR,
  elementIds,
  cursorId,
  stage,
  scribeId
});

export type SetElementsAnimationCursorAction = SetElementsAnimationConfigPayload & {
  type: typeof SET_ELEMENTS_ANIMATION_CURSOR;
};

export interface SetElementsAnimationDrawTypeAction {
  type: typeof SET_ELEMENTS_ANIMATION_DRAW_TYPE;
  elementIds: Array<string>;
  projectId: number;
  stage: ElementAnimationStage;
  drawType: DrawType;
}
export const setElementsAnimationDrawType = ({
  elementIds,
  projectId,
  stage,
  drawType
}: Omit<SetElementsAnimationDrawTypeAction, 'type'>): SetElementsAnimationDrawTypeAction => ({
  type: SET_ELEMENTS_ANIMATION_DRAW_TYPE,
  elementIds,
  projectId,
  stage,
  drawType
});
