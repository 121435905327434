import { MaskingLineStyleConfig } from 'js/types';
import * as PIXI from 'pixi.js';

function setupBasicMask(
  lineStyleConfig: MaskingLineStyleConfig,
  startingPoint: { x: number; y: number }
): PIXI.Graphics {
  const mask = new PIXI.Graphics();
  mask.clear();
  mask.lineStyle(lineStyleConfig);
  mask.moveTo(startingPoint.x, startingPoint.y);
  return mask;
}

export default setupBasicMask;
