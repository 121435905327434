import {
  AUDIO_CLIP_LIMIT_REACHED,
  CANCEL_AUDIO_SELECTION,
  CONFIRM_AUDIO_SELECTION,
  DELETE_AUDIO_ASSET,
  OPEN_AUDIO_MODAL,
  OpenAudioModalAction,
  SAVE_AUDIO_ASSET_METADATA,
  SAVE_AUDIO_TO_LIBRARY_SUCCESS,
  SaveAudioAssetMetadataAction,
  USE_AUDIO_ASSET_IN_PROJECT,
  UseAudioAssetInProjectAction
} from 'js/actionCreators/audioActions';
import { CallEffect, SelectEffect, call, select, takeEvery } from 'redux-saga/effects';
import {
  TRACK_OPEN_RECORD_AUDIO_MODAL,
  TRACK_OPEN_UPLOAD_AUDIO_MODAL,
  TRACK_PREVIEW_AUDIO,
  TRACK_SEARCH_AUDIO_FILES,
  TRACK_SELECT_FILTER_AUDIO_FILES,
  TrackOpenRecordAudioModalAction,
  TrackOpenUploadAudioModalAction,
  TrackPreviewAudioAction,
  TrackSearchAudioFilesAction,
  TrackSelectFilterAudioFilesAction
} from 'js/actionCreators/trackingActions';
import { AudioSource, VoiceoverGenerationOutputs, RatingOption } from 'js/types';
import { getVoiceoverGenerationResults } from 'js/sagas/betaAiFeaturesSagas';

import { track } from '../mixpanelProvider';

import { getVoiceoverGenerationBasicData, VoiceoverGenerationBasicData } from './trackAiBetaSagas';

function* trackAudioModalOpen(action: OpenAudioModalAction) {
  const { actionTrigger, tab, initialAction } = action;

  // Trigger from LHP with multiple actions
  if (initialAction === 'record' || initialAction === 'upload') {
    yield call(track, initialAction === 'upload' ? 'Open Upload Audio' : 'Open Record Audio', {
      'Event Trigger': 'LHP'
    });
    return;
  }

  if (actionTrigger) {
    let event;
    if (tab === 'your-files') {
      event = 'Open Your Audio Files';
    } else if (tab === 'audio-library') {
      event = 'Open Audio Library';
    }

    if (event) {
      yield call(track, event, {
        'Event Trigger': actionTrigger
      });
    }
  }
}

function* trackOpenRecordAudioModal(action: TrackOpenRecordAudioModalAction) {
  const { eventTrigger } = action;
  if (eventTrigger) {
    yield call(track, 'Open Record Audio', {
      'Event Trigger': eventTrigger
    });
  }
}

function* trackOpenUploadAudioModal(action: TrackOpenUploadAudioModalAction) {
  const { eventTrigger } = action;
  if (eventTrigger) {
    yield call(track, 'Open Upload Audio', {
      'Event Trigger': eventTrigger
    });
  }
}

function* trackAudioFileUploaded(action: SaveAudioAssetMetadataAction) {
  let eventName;
  if (action.source === AudioSource.RECORDED) {
    eventName = 'Audio File Recorded';
  } else if (action.source === AudioSource.UPLOADED) {
    eventName = 'Audio File Uploaded';
  } else if (action.source === AudioSource.AI_GENERATED) {
    eventName = 'Audio File AI Generated';
  }
  if (!eventName) return;
  yield call(track, eventName);
}

function* trackUseAudioAssetInProject(
  action: UseAudioAssetInProjectAction
): Generator<SelectEffect | CallEffect, void, VoiceoverGenerationBasicData | undefined | VoiceoverGenerationOutputs> {
  let fileName;
  let voiceoverData: VoiceoverGenerationBasicData | undefined;
  let rating: RatingOption | undefined | null;

  if (action.eventTrigger === 'Audio Library') {
    fileName = action.asset.filename;
  } else if (action.eventTrigger === 'AI LHP') {
    voiceoverData = (yield call(getVoiceoverGenerationBasicData)) as VoiceoverGenerationBasicData | undefined;
    const voiceoverResult = (yield select(getVoiceoverGenerationResults)) as VoiceoverGenerationOutputs;
    rating = voiceoverResult.rating;
  }

  if (!action.eventTrigger) return;

  yield call(track, 'Use Audio File In Project', {
    'Event Trigger': action.eventTrigger,
    ...(fileName && { 'Track Name': fileName }),
    ...(voiceoverData && voiceoverData),
    ...(rating && { 'Voice Rating': rating }),
    'Audio Type': action.audioType
  });
}

function* trackPreviewAudio(action: TrackPreviewAudioAction) {
  yield call(track, 'Preview Audio', {
    'Event Trigger': action.eventTrigger,
    'Track Name': action.trackName
  });
}

function* trackSaveMetadataSubmit(action: SaveAudioAssetMetadataAction) {
  const event = action.useItInProjectId ? 'Use Audio File Now' : 'Save Audio File Only';
  let eventTrigger;
  if (action.source === 'recorded') {
    eventTrigger = 'Record';
  } else if (action.source === 'uploaded') {
    eventTrigger = 'Upload';
  }

  yield call(track, event, { 'Event Trigger': eventTrigger, 'Audio Type': action.data.audioType });
}

function* trackSearchAudioFiles({
  eventTrigger,
  term,
  genres,
  tempos,
  scribeId,
  numberOfResults
}: TrackSearchAudioFilesAction) {
  yield call(track, 'Search for Audio', {
    'Event Trigger': eventTrigger,
    'Search Term': term,
    Genres: genres,
    Tempos: tempos,
    'Scribe ID': scribeId,
    'Number of Results': numberOfResults
  });
}

function* trackSelectFilterAudioFiles({ genres, tempos, scribeId }: TrackSelectFilterAudioFilesAction) {
  yield call(track, 'Select Audio Library Filter', {
    Genres: genres,
    Tempos: tempos,
    'Scribe ID': scribeId
  });
}

function* trackDeleteAudioAsset() {
  yield call(track, 'Audio Deleted From Library');
}

function* trackAudioClipLimitReached() {
  yield call(track, 'Max Audio Tracks Reached Modal');
}

function* trackCancelAudioSelection() {
  yield call(track, 'Cancel Max Audio Tracks Reached Modal');
}

function* trackConfirmAudioSelection() {
  yield call(track, 'Confirm Tracks on Max Audio Tracks Reached Modal');
}

export default function* trackAudioLibrarySagas() {
  yield takeEvery(OPEN_AUDIO_MODAL, trackAudioModalOpen);
  yield takeEvery(TRACK_OPEN_RECORD_AUDIO_MODAL, trackOpenRecordAudioModal);
  yield takeEvery(TRACK_OPEN_UPLOAD_AUDIO_MODAL, trackOpenUploadAudioModal);
  yield takeEvery(SAVE_AUDIO_TO_LIBRARY_SUCCESS, trackAudioFileUploaded);
  yield takeEvery(USE_AUDIO_ASSET_IN_PROJECT, trackUseAudioAssetInProject);
  yield takeEvery(TRACK_PREVIEW_AUDIO, trackPreviewAudio);
  yield takeEvery(SAVE_AUDIO_ASSET_METADATA, trackSaveMetadataSubmit);
  yield takeEvery(TRACK_SEARCH_AUDIO_FILES, trackSearchAudioFiles);
  yield takeEvery(TRACK_SELECT_FILTER_AUDIO_FILES, trackSelectFilterAudioFiles);
  yield takeEvery(DELETE_AUDIO_ASSET, trackDeleteAudioAsset);
  yield takeEvery(AUDIO_CLIP_LIMIT_REACHED, trackAudioClipLimitReached);
  yield takeEvery(CANCEL_AUDIO_SELECTION, trackCancelAudioSelection);
  yield takeEvery(CONFIRM_AUDIO_SELECTION, trackConfirmAudioSelection);
}
