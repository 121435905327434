/* eslint-disable */
import chunk from 'lodash.chunk';
import cloneDeep from 'lodash.clonedeep';

export const thin = (pixels: Array<number>, width: number, _height: number): [Array<Array<number>>, number] => {
  const pixels2D = chunk(pixels, width);
  const thinner = new ZhangSuen(pixels2D);
  const iterations = thinner.thinImage();
  const thinned = guo_hall(thinner.pixels());

  return [thinned, iterations];
};

function guo_hall(pixels: Array<Array<number>>) {
  let changed;
  do {
    changed = guo_hall_iteration(pixels, false) + guo_hall_iteration(pixels, true);
  } while (changed !== 0);
  return pixels;
}

function guo_hall_iteration(pixels: Array<Array<number>>, oddIteration: boolean) {
  let changed = 0;
  for (let y = 0; y < pixels.length; y++) {
    for (let x = 0; x < pixels[0].length; x++) {
      if (pixels[y][x] === 0) continue;

      const p2 = getXY(pixels, y - 1, x); //pixels[y - 1][x];
      const p3 = getXY(pixels, y - 1, x + 1); //pixels[y - 1][x + 1];
      const p4 = getXY(pixels, y, x + 1); //pixels[y][x + 1];
      const p5 = getXY(pixels, y + 1, x + 1); //pixels[y + 1][x + 1];
      const p6 = getXY(pixels, y + 1, x); //pixels[y + 1][x];
      const p7 = getXY(pixels, y + 1, x - 1); //pixels[y + 1][x - 1];
      const p8 = getXY(pixels, y, x - 1); //pixels[y][x - 1];
      const p9 = getXY(pixels, y - 1, x - 1); //pixels[y - 1][x - 1];

      // @ts-ignore algorithm works on null or undefined
      const N1 = (p9 || p2) + (p3 || p4) + (p5 || p6) + (p7 || p8);
      // @ts-ignore algorithm works on null or undefined
      const N2 = (p2 || p3) + (p4 || p5) + (p6 || p7) + (p8 || p9);
      const N = N1 < N2 ? N1 : N2;
      const m = oddIteration ? p8 && (p6 || p7 || !p9) : p4 && (p2 || p3 || !p5);
      // @ts-ignore algorithm works on null or undefined
      const C = (!p2 && (p3 || p4)) + (!p4 && (p5 || p6)) + (!p6 && (p7 || p8)) + (!p8 && (p9 || p2));

      if (C == 1 && N >= 2 && N <= 3 && m == 0) {
        pixels[y][x] = 0;
        changed++;
      }
    }
  }

  return changed;
}

function getXY(arr: Array<Array<number>>, y: number, x: number) {
  if (x < 0 || y < 0 || x > arr[0].length || y > arr.length) return null;
  try {
    return arr[y]?.[x];
  } catch (error) {
    debugger;
  }
}

class ZhangSuen {
  nbrs = [
    [0, -1],
    [1, -1],
    [1, 0],
    [1, 1],
    [0, 1],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -1]
  ];

  nbrGroups = [
    [
      [0, 2, 4],
      [2, 4, 6]
    ],
    [
      [0, 2, 6],
      [0, 4, 6]
    ]
  ];

  toWhite = new Array();

  grid: Array<Array<number>>;

  constructor(image: Array<Array<number>>) {
    this.grid = image;
  }

  pixels = () => {
    return cloneDeep(this.grid);
  };

  thinImage() {
    var firstStep = false;
    var hasChanged;
    var iterations = 1;

    const blackPixels = new Set<[number, number]>();

    for (var row = 1; row < this.grid.length - 2; row++) {
      for (var column = 1; column < this.grid[row].length - 1; column++) {
        if (this.grid[row][column] === 1) {
          blackPixels.add([row, column]);
        }
      }
    }

    do {
      iterations++;
      hasChanged = false;
      firstStep = !firstStep;

      blackPixels.forEach(point => {
        const [row, column] = point;

        var neighbors = this.numNeighbors(row, column);
        if (neighbors < 2 || neighbors > 6) return;

        if (this.numTransitions(row, column) !== 1) return;
        if (!this.atLeastOneIsWhite(row, column, firstStep ? 0 : 1)) return;

        this.toWhite.push([column, row]);
        blackPixels.delete(point);
        hasChanged = true;
      });

      for (let i = 0; i < this.toWhite.length; i++) {
        var point = this.toWhite[i];
        this.grid[point[1]][point[0]] = 0;
      }

      this.toWhite = new Array();
    } while (firstStep || hasChanged);

    return iterations;
  }

  numNeighbors = (row: number, column: number) => {
    var count = 0;
    for (var i = 0; i < this.nbrs.length - 1; i++)
      if (this.grid[row + this.nbrs[i][1]][column + this.nbrs[i][0]] === 1) count++;
    return count;
  };

  numTransitions = (row: number, column: number) => {
    var count = 0;
    for (var i = 0; i < this.nbrs.length - 1; i++)
      if (this.grid[row + this.nbrs[i][1]][column + this.nbrs[i][0]] === 0) {
        if (this.grid[row + this.nbrs[i + 1][1]][column + this.nbrs[i + 1][0]] === 1) count++;
      }
    return count;
  };

  atLeastOneIsWhite = (row: number, column: number, step: number) => {
    var count = 0;
    var group = this.nbrGroups[step];
    for (var i = 0; i < 2; i++)
      for (var j = 0; j < group[i].length; j++) {
        var nbr = this.nbrs[group[i][j]];
        if (this.grid[row + nbr[1]][column + nbr[0]] === 0) {
          count++;
          break;
        }
      }
    return count > 1;
  };

  printResult = () => {
    for (var i = 0; i < this.grid.length; i++) {
      var row = this.grid[i];
      console.log(row.join(''));
    }
  };
}
