import direction from 'direction';

export default function shouldTextUseFallbackDrawing(text: string): boolean {
  const isTextRTL = direction(text) === 'rtl';

  const scriptsToBeExcluded = {
    devanagari: '[\\u0900-\\u097F]',
    bengali: '[\\u0980-\\u09FF]',
    tamil: '[\\u0B80-\\u0BFF]',
    telugu: '[\\u0C00-\\u0C7F]',
    kannada: '[\\u0C80-\\u0CFF]',
    malayalam: '[\\u0D00-\\u0D7F]',
    thai: '[\\u0E00-\\u0E7F]',
    khmer: '[\\u1780-\\u17FF]',
    mongolian: '[\\u1800-\\u18AF]'
  };

  const combinedRegex = new RegExp(Object.values(scriptsToBeExcluded).join('|'));

  return isTextRTL || combinedRegex.test(text);
}
