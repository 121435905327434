import { gsap } from 'gsap';
import clamp from 'lodash.clamp';
import { SCENE_DIRECTION_KEYS } from 'js/config/sceneTransitionsOptions';
import { SceneTransitionConfig } from 'js/types';

import VSSceneTransitionBase, { VSSceneTransitionBaseProps } from './VSSceneTransitionBase';

const DRAG_ANIMATION_PERCENTAGE = 0.7;
const CURSOR_EXIT_ANIMATION_PERCENTAGE = 0.3;
const EXTRA_PADDING_FOR_OFFSETS = 250;

const getCursorAngleForDragOn = (direction?: string) => {
  switch (direction) {
    case SCENE_DIRECTION_KEYS.DOWN:
    case SCENE_DIRECTION_KEYS.DOWN_LEFT:
      return -45;

    case SCENE_DIRECTION_KEYS.DOWN_RIGHT:
      return 45;

    default:
      return 0;
  }
};

export default class VSDragOnTransition extends VSSceneTransitionBase {
  config?: SceneTransitionConfig;
  sceneTween: GSAPTween;
  cursorTween: GSAPTimeline;

  constructor({
    previousScene,
    currentScene,
    transitionDuration,
    viewportDimensions,
    config
  }: VSSceneTransitionBaseProps) {
    super({ previousScene, currentScene, transitionDuration, viewportDimensions });
    this.config = config;

    this.scene1Out = 0;
    this.scene2In = 1;

    this.currentScene.cursor.angle = getCursorAngleForDragOn(config?.direction);
    this.currentScene.cursor.x = viewportDimensions.width * 2;
    this.currentScene.cursor.y = viewportDimensions.height * 2;

    this.sceneTween = this.setupSceneTimeline();
    this.cursorTween = this.setupCursorTimeline();
  }

  setupSceneTimeline() {
    const sceneInEndpoint = {
      y: 0,
      x: 0,
      duration: 1
    };

    let sceneInStartPoint;
    switch (this.config?.direction) {
      case SCENE_DIRECTION_KEYS.DOWN: {
        sceneInStartPoint = {
          y: -this.viewportDimensions.height
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.LEFT: {
        sceneInStartPoint = {
          x: this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.RIGHT: {
        sceneInStartPoint = {
          x: -this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP_RIGHT: {
        sceneInStartPoint = {
          y: this.viewportDimensions.height,
          x: -this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP_LEFT: {
        sceneInStartPoint = {
          y: this.viewportDimensions.height,
          x: this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.DOWN_RIGHT: {
        sceneInStartPoint = {
          y: -this.viewportDimensions.height,
          x: -this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.DOWN_LEFT: {
        sceneInStartPoint = {
          y: -this.viewportDimensions.height,
          x: this.viewportDimensions.width
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP:
      default: {
        sceneInStartPoint = {
          y: this.viewportDimensions.height
        };
      }
    }

    const sceneTween = gsap.fromTo(this.currentScene.viewportContainer, sceneInStartPoint, sceneInEndpoint);
    sceneTween.pause();
    return sceneTween;
  }

  setupCursorTimeline() {
    const cursorTween = gsap.timeline();

    const cursorInEndpoint = {
      x: this.viewportDimensions.width * 0.5,
      y: this.viewportDimensions.height * 0.5,
      duration: DRAG_ANIMATION_PERCENTAGE
    };
    const cursorOutEndpoint = {
      y: this.viewportDimensions.height + EXTRA_PADDING_FOR_OFFSETS,
      duration: CURSOR_EXIT_ANIMATION_PERCENTAGE,
      ease: 'power2.in'
    };

    let cursorInStartPoint;
    switch (this.config?.direction) {
      case SCENE_DIRECTION_KEYS.DOWN: {
        cursorInStartPoint = {
          x: this.viewportDimensions.width * 0.5,
          y: -this.viewportDimensions.height * 0.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.RIGHT: {
        cursorInStartPoint = {
          x: -this.viewportDimensions.width * 0.5,
          y: this.viewportDimensions.height * 0.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.LEFT: {
        cursorInStartPoint = {
          x: this.viewportDimensions.width * 1.5,
          y: this.viewportDimensions.height * 0.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.DOWN_RIGHT: {
        cursorInStartPoint = {
          x: -this.viewportDimensions.width * 0.5,
          y: -this.viewportDimensions.height * 0.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.DOWN_LEFT: {
        cursorInStartPoint = {
          x: this.viewportDimensions.width * 1.5,
          y: -this.viewportDimensions.height * 0.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP_RIGHT: {
        cursorInStartPoint = {
          x: -this.viewportDimensions.width * 0.5,
          y: this.viewportDimensions.height * 1.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP_LEFT: {
        cursorInStartPoint = {
          x: this.viewportDimensions.width * 1.5,
          y: this.viewportDimensions.height * 1.5
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.UP:
      default: {
        cursorInStartPoint = {
          x: this.viewportDimensions.width * 0.5,
          y: this.viewportDimensions.height * 1.5
        };
      }
    }

    cursorTween.add(gsap.fromTo(this.currentScene.cursor, cursorInStartPoint, cursorInEndpoint));
    cursorTween.add(gsap.to(this.currentScene.cursor, cursorOutEndpoint));
    cursorTween.pause();
    return cursorTween;
  }

  updateIntroProgress(progress: number) {
    if (this.currentScene) {
      const sceneMoveInProgress = clamp(progress / DRAG_ANIMATION_PERCENTAGE, 0, 1);
      this.sceneTween.progress(sceneMoveInProgress);
      this.cursorTween.progress(progress);
    }
  }
}
