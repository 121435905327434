import { LoginState } from 'js/types';

import { RESTART_LOGIN, AUTH_SUCCESS, AuthSuccessAction } from '../actionCreators/authActions';
import { RESET_STATE } from '../actionCreators/generalActions';

const initialState: LoginState = {
  uiState: 'request-email',
  apiError: null
};

type LoginAction = { type: typeof RESTART_LOGIN | typeof RESET_STATE } | AuthSuccessAction;

function loginReducer(state = { ...initialState }, action: LoginAction): LoginState {
  switch (action.type) {
    case RESTART_LOGIN: {
      const uiState = 'request-email';
      const apiError = undefined;

      return {
        ...state,
        uiState,
        apiError
      };
    }

    case AUTH_SUCCESS: {
      const uiState = 'complete';
      return {
        ...state,
        uiState
      };
    }

    case RESET_STATE:
      return { ...initialState };

    default:
      return state;
  }
}

export default loginReducer;
