import React from 'react';

const IconAnimationFade = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <path
      d="M44.2,35.8H15.8l-0.3-1.1c-0.5-1.5-0.7-3-0.7-4.5C14.7,21.8,21.6,15,30,15c8.4,0,15.2,6.8,15.2,15.2c0,1.5-0.2,3.1-0.7,4.5
	L44.2,35.8z M18,32.8h23.9c0.2-0.8,0.3-1.7,0.3-2.6C42.2,23.5,36.7,18,30,18c-6.7,0-12.2,5.5-12.2,12.2C17.7,31.1,17.8,31.9,18,32.8
	z"
    />
    <path d="M43.2,38H16.9c0.2,0.5,3.2,2.9,3.2,2.9h19.4C39.5,40.9,43,38.5,43.2,38z" />
    <path d="M28,44.6h4.1c1.6-0.2,3-0.7,4.4-1.4H23.9v0.2C25.2,44,26.6,44.4,28,44.6z" />
  </svg>
);

export default IconAnimationFade;
