import { FILE_CONTENT_TYPES } from 'js/config/consts';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { ExistingScribeModel, PlaybackScribeModel } from 'js/types';

export default function getScribeRasterUrls(scribe: PlaybackScribeModel | ExistingScribeModel): Array<string> {
  const scribeImageElements = scribe.elements.filter((el): el is ScribeImageElementModel => {
    return el.type === 'Image';
  });

  const rasterImageUrls = scribeImageElements
    .filter(
      (imgEl): imgEl is ScribeImageElementModel =>
        !!imgEl.image?.contentType &&
        [FILE_CONTENT_TYPES.PNG, FILE_CONTENT_TYPES.JPG, FILE_CONTENT_TYPES.BMP].includes(imgEl.image.contentType)
    )
    .map(imageEl => imageEl._imageUrl)
    .filter((url): url is string => !!url);

  return rasterImageUrls;
}
