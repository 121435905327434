import React from 'react';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';
import {
  getSelectedTweenPosition,
  getSelectedTweenEmphasisDirection
} from 'js/shared/helpers/getSelectedTweenPosition';
import { selectableEntranceAnimationOptions, selectableShakeAnimationOptions } from 'js/config/animationOptions';
import { getStageTweenKey } from 'js/shared/helpers/getStageTweenKey';
import { validateAllDirectionsMatch, validateAllPositionsMatch } from 'js/shared/helpers/validateAllPositionsMatch';

import ChoiceButtonContents from './ChoiceButtonContents';

const DirectionChoiceButton = ({ element, elements, stageKey, flipImage }) => {
  const tweenKey = getStageTweenKey(stageKey);

  if (tweenKey !== 'emphasisTween') {
    const assignedDirectionPosition = getSelectedTweenPosition(element, elements, tweenKey);

    const allPositionsMatch = assignedDirectionPosition.every(validateAllPositionsMatch);

    const selectedAnimationOption = selectableEntranceAnimationOptions.find(
      opt => opt.value !== undefined && allPositionsMatch && assignedDirectionPosition[0].position === opt.value
    );

    const optIcon =
      selectedAnimationOption !== undefined ? selectedAnimationOption.Icon({ flipImage }) : <IconIndeterminate />;
    const optTitle = allPositionsMatch && selectedAnimationOption ? selectedAnimationOption.label : 'Mixed';

    return (
      <ChoiceButtonContents label={optTitle} id={`${stageKey}-direction-button-choice-contents`}>
        {optIcon}
      </ChoiceButtonContents>
    );
  } else {
    const assignedDirectionPosition = getSelectedTweenEmphasisDirection(element, elements, tweenKey);

    const allPositionsMatch = assignedDirectionPosition.every(validateAllDirectionsMatch);

    const selectedAnimationOption = selectableShakeAnimationOptions.find(
      opt =>
        opt.direction !== undefined && allPositionsMatch && assignedDirectionPosition[0].direction === opt.direction
    );

    const optIcon =
      selectedAnimationOption !== undefined ? selectedAnimationOption.Icon({ flipImage }) : <IconIndeterminate />;
    const optTitle = allPositionsMatch && selectedAnimationOption ? selectedAnimationOption.label : 'Mixed';

    return (
      <ChoiceButtonContents label={optTitle} id={`${stageKey}-direction-button-choice-contents`}>
        {optIcon}
      </ChoiceButtonContents>
    );
  }
};

export default DirectionChoiceButton;
