import { RecolorDefaults } from 'js/types';

interface ConstructSVGAssetKeyConfig {
  assetId: number;
  recolorSettings?: RecolorDefaults;
  useContentSvgViewbox: boolean;
}

export function constructSVGAssetKey({
  assetId,
  recolorSettings,
  useContentSvgViewbox
}: ConstructSVGAssetKeyConfig): string {
  const assetKeyPart = assetId.toString();
  const recolorKeyPart = recolorSettings
    ? Object.entries(recolorSettings)
        .map(entry => {
          const [setting, value] = entry;
          return !!setting && !!value ? `${setting}-${value.toLowerCase()}` : '';
        })
        .join('-')
    : '';

  const returnParts = [assetKeyPart];

  if (useContentSvgViewbox) {
    returnParts.push('contentViewbox');
  } else {
    returnParts.push('originalViewbox');
  }

  if (recolorKeyPart) {
    returnParts.push(recolorKeyPart);
  }

  return returnParts.join('-');
}
