import { call } from 'redux-saga/effects';
import { getEditorCanvas } from 'js/editor/EditorCanvas/EditorCanvas';
import {
  AlignSelectedElementsAction,
  DistributeSelectedElementsAction
} from 'js/actionCreators/elementAlignmentActions';

export function* elementAlignment({ alignment }: AlignSelectedElementsAction) {
  const editorCanvas = getEditorCanvas();
  if (!editorCanvas) return;
  yield call({ fn: editorCanvas.alignSelectedVisibleUnlockedElements, context: editorCanvas }, alignment);
}

export function* elementDistribution({ distribution }: DistributeSelectedElementsAction) {
  const editorCanvas = getEditorCanvas();
  if (!editorCanvas) return;
  yield call({ fn: editorCanvas.distributeSelectedVisibleUnlockedElements, context: editorCanvas }, distribution);
}
