import * as PIXI from 'pixi.js';

export const addGradient = (from, to, width, height, gradientType) => {
  const c = document.createElement('canvas');
  c.height = height;
  c.width = width;
  const ctx = c.getContext('2d');
  let grd;
  if (gradientType === 'vertical') {
    grd = ctx.createLinearGradient(0, 0, 0, height);
  } else {
    grd = ctx.createLinearGradient(0, 0, width, 0);
  }
  grd.addColorStop(0, from);
  grd.addColorStop(1, to);
  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, width, height);
  return new PIXI.Texture.from(c);
};
