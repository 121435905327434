import React from 'react';

export const IconAnimationClockwise = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    stroke={'currentcolor'}
    fill={'currentcolor'}
  >
    <path
      d="M42.3,30.9c0,6.5-5.3,11.8-11.8,11.8c-6.5,0-11.8-5.3-11.8-11.8c0-5.9,4.4-10.8,10.1-11.7l-1.4,1.4l2.1,2.1l4.2-4.1
  c0.3-0.3,0.5-0.7,0.5-1.1s-0.2-0.8-0.5-1.1l-4.2-4.1l-2.1,2.1l1.6,1.6c-7.5,0.8-13.3,7.1-13.3,14.7c0,8.2,6.6,14.8,14.8,14.8
  c8.2,0,14.8-6.6,14.8-14.8H42.3z"
    />
  </svg>
);
