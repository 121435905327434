import { fabric } from 'fabric';
import { defaultCanvasColor } from 'js/config/defaults';
import { ScribeBackgroundSettings } from 'js/types';

export default class ProjectFrame extends fabric.Rect {
  constructor(width: number, height: number, settings: ScribeBackgroundSettings, xOffset = 0, yOffset = 0) {
    super({
      left: xOffset,
      top: yOffset,
      evented: false,
      selectable: false,
      strokeWidth: 0,
      width,
      height
    });
    this.set('fill', this.getBackgroundFill(settings));
  }

  private getBackgroundFill(settings: ScribeBackgroundSettings) {
    return settings.backgroundColor || defaultCanvasColor;
  }

  public updateProps(settings: ScribeBackgroundSettings) {
    this.set('fill', this.getBackgroundFill(settings));
  }
}
