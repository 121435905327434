import { select, takeEvery, SelectEffect, delay, CallEffect } from 'redux-saga/effects';
import { UPGRADE_ACCOUNT } from 'js/actionCreators/userAccountAction';
import { ACCOUNT_TYPES } from 'js/config/consts';

function* redirectToPricingPage(): Generator<SelectEffect | CallEffect, void, string> {
  const currentPath = yield select(state => state.router?.location?.pathname || '');
  const accountType = yield select(state => state.auth?.currentUser?.accountType);
  const redirectURL = `/app${currentPath}`;
  window.localStorage.setItem('checkout-success-redirect', redirectURL);
  const params = new URLSearchParams({
    referrer: 'vsc',
    action: 'upgrade',
    accountType
  });

  yield delay(300);
  window.location.href =
    accountType === ACCOUNT_TYPES.FREE ? '/pricing/' : `/account/subscriptions?${params.toString()}`;
}

function* userAccountSagas() {
  yield takeEvery(UPGRADE_ACCOUNT, redirectToPricingPage);
}

export default userAccountSagas;
