import * as PIXI from 'pixi.js';
import { PlaybackScribeModel, ScribeCameraElement, ScribeScene } from 'js/types';
import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import VSViewport from 'js/playback/lib/Playback/models/VSViewport';
import { getCanvasObjectCoordinates } from 'js/editor/EditorCanvas/helpers/getCanvasDimensions';
import { getViewportData } from 'js/sagas/sagaHelpers/getViewportData';

export function initializeSceneViewport(
  project: PlaybackScribeModel,
  scene: ScribeScene,
  initialCamera: ScribeCameraElement,
  addParentContainer?: boolean
): VSViewport {
  const maxStage = getCanvasObjectCoordinates(project.canvasSize);
  const viewportDimensions = getViewportData(project.canvasSize);
  const sceneTimings = getSceneStartEndTimes(project, scene);

  const maxStageDimensions = new PIXI.Rectangle(
    maxStage.left,
    maxStage.top,
    maxStage.right - maxStage.left,
    maxStage.bottom - maxStage.top
  );
  const initialCameraPosition = {
    x: initialCamera.x,
    y: initialCamera.y,
    zoom: initialCamera.scale
  };

  const sceneViewport = new VSViewport(
    {
      worldWidth: maxStageDimensions.width,
      worldHeight: maxStageDimensions.height,
      screenWidth: viewportDimensions.width,
      screenHeight: viewportDimensions.height
    },
    {
      startTimeMs: sceneTimings.startTime,
      endTimeMs: sceneTimings.endTime
    },
    initialCameraPosition
  );

  if (addParentContainer) {
    // Viewport expects to be inside some form of PIXI container else `updateTransform` fails
    const container = new PIXI.Container();
    container.addChild(sceneViewport);
  }

  return sceneViewport;
}
