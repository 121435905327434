import { fabric } from 'fabric';
import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';
import { ShapeType } from 'js/types';

import { ICircleElement } from '../CircleElement';
import { IEllipseElement } from '../EllipseElement';
import { ISquareElement } from '../SquareElement';
import { ITriangleElement } from '../TriangleElement';

import getGradient from './getGradient';

type Options = Partial<ICircleElement> | Partial<ITriangleElement> | Partial<ISquareElement> | Partial<IEllipseElement>;

function applyShapeElementOptions(
  options: Partial<IEllipseElement>,
  scribeElement: ScribeShapeElementModel,
  fabricObject: fabric.Object & { shapeType: 'ellipse' }
): Partial<IEllipseElement>;
function applyShapeElementOptions(
  options: Partial<ICircleElement>,
  scribeElement: ScribeShapeElementModel,
  fabricObject: fabric.Object & { shapeType: 'circle' }
): Partial<ICircleElement>;
function applyShapeElementOptions(
  options: Partial<ITriangleElement>,
  scribeElement: ScribeShapeElementModel,
  fabricObject: fabric.Object & { shapeType: 'triangle' }
): Partial<ITriangleElement>;
function applyShapeElementOptions(
  options: Partial<ISquareElement>,
  scribeElement: ScribeShapeElementModel,
  fabricObject: fabric.Object & { shapeType: 'square' }
): Partial<ISquareElement>;
function applyShapeElementOptions(
  options: Options,
  scribeElement: ScribeShapeElementModel,
  fabricObject: fabric.Object & { shapeType: ShapeType }
): Options {
  const opacity = scribeElement.opacity ?? 1;

  const { height, width, backgroundColorFrom, backgroundColorTo, backgroundGradientType, backgroundType } =
    scribeElement;

  return {
    ...options,
    fill:
      backgroundType === 'gradient'
        ? getGradient({
            type: backgroundGradientType,
            width,
            height,
            from: backgroundColorFrom,
            to: backgroundColorTo
          })
        : scribeElement?.fill || fabricObject.fill,
    strokeWidth: 0,
    opacity
  };
}

export default applyShapeElementOptions;
