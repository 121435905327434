import { createClient } from 'contentful';
import config from 'js/config/config';

let client;
export const getContentfulClient = () => {
  if (client) return client;

  client = createClient({
    space: config.CONTENTFUL_SPACE_ID,
    environment: config.CONTENTFUL_ENVIRONMENT,
    accessToken: config.CONTENTFUL_CDN_TOKEN
  });

  return client;
};
