import { select, take } from '@redux-saga/core/effects';
import { LOAD_CURSORS_SUCCESS } from 'js/actionCreators/loadCursorsSuccessAction';
import getCursorResourcesAndMetadata from 'js/sagas/sagaHelpers/getCursorResourcesAndMetadata';
import { getCursorsFromState } from 'js/sagas/selectors';

export default function* getCursorResourcesAndTimings(scribe) {
  const stateCursors = yield select(getCursorsFromState);
  const cursors = stateCursors.length ? stateCursors : (yield take(LOAD_CURSORS_SUCCESS)).cursors; // If cursors aren't loaded yet, wait until we get them to continue
  const defaultCursor = scribe.cursor;
  const elementCursors = scribe.elements
    .filter(el => el.cursorId || el?.emphasisAnimation?.config?.cursorId || el?.exitAnimation?.config?.cursorId)
    .reduce((cursorIds, el) => {
      const idsToReturn = [];
      if (el.cursorId) {
        idsToReturn.push(el.cursorId);
      }

      if (el?.emphasisAnimation?.config?.cursorId) {
        idsToReturn.push(el.emphasisAnimation.config.cursorId);
      }

      if (el?.exitAnimation?.config?.cursorId) {
        idsToReturn.push(el.exitAnimation.config.cursorId);
      }

      return [...cursorIds, ...idsToReturn];
    }, []);

  const sceneCursors = scribe.scenes.reduce((cursorIds, scene) => {
    return scene.settings?.sceneTransitionConfig?.cursorId
      ? [...cursorIds, scene.settings.sceneTransitionConfig.cursorId]
      : cursorIds;
  }, []);

  const allCursorData = [defaultCursor, ...elementCursors, ...sceneCursors].reduce((all, cursorId) => {
    all[cursorId] = {
      ...getCursorResourcesAndMetadata(cursors, cursorId)
    };
    return all;
  }, {});

  return allCursorData;
}
