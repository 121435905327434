import { ALL_ANIMATION_TWEEN_DEFAULTS } from 'js/config/tweens';

const animationDefaultTweenPosition = animationType => {
  return ALL_ANIMATION_TWEEN_DEFAULTS.filter(tweenDefault => tweenDefault.id === animationType)[0].tweens[0]?.position;
};

export const getSelectedTweenPosition = (element, elements, tweenKey, animationType) => {
  if (element === undefined && elements === undefined) {
    return undefined;
  }

  if (element !== undefined) {
    return [
      {
        id: element.id,
        position: element[tweenKey]?.tweens[0]?.position ?? animationDefaultTweenPosition(animationType)
      }
    ];
  }

  if (elements.length === 0) return undefined;

  return elements.map(el => {
    return {
      id: el.id,
      position: el[tweenKey]?.tweens[0]?.position ?? animationDefaultTweenPosition(animationType)
    };
  });
};

export const getSelectedTweenEmphasisDirection = (element, elements, tweenKey) => {
  if (element === undefined && elements === undefined) {
    return undefined;
  }

  if (element !== undefined) {
    const firstTweenIn = element[tweenKey]?.tweens_in[0];

    const direction = firstTweenIn?.direction || 'l';

    return [
      {
        id: element.id,
        direction
      }
    ];
  }

  if (elements.length === 0) return undefined;

  return elements.map(el => {
    const firstTweenIn = el[tweenKey]?.tweens_in[0];

    const direction = firstTweenIn?.direction || 'l';

    return {
      id: el.id,
      direction
    };
  });
};
