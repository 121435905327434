import './SearchResultView.css';

import React, { FC } from 'react';
import SubTabs from 'js/shared/components/SubTabs';
import IconLeftMinimise from 'js/shared/icons/IconLeftMinimise';
import { ImageCategory } from 'js/types';
import { SearchType } from 'js/reducers/imageSearchReducer';

import CategoryCarousel from '../ImageLibrary/CategoryCarousel';

import useSubTabs from './hooks/useSubTabs';

type SearchResultViewProps = {
  onClickToCategories: () => void;
  searchType: SearchType;
  searchInput: string;
  categoryId: string | null;
  onCategoryClick: (category: ImageCategory) => void;
};

const SearchResultView: FC<SearchResultViewProps> = ({
  onClickToCategories,
  searchType,
  searchInput,
  categoryId,
  onCategoryClick
}) => {
  const { handleTabChange, configTabs } = useSubTabs(searchType, searchInput, categoryId, onCategoryClick);

  return (
    <div className="SearchResultView">
      <div className="SearchResultView__navigation">
        <button className="SearchResultView__navigation-btn" type="button" onClick={onClickToCategories}>
          <IconLeftMinimise />
          back to All Categories
        </button>
      </div>

      {!!categoryId && <CategoryCarousel categoryId={categoryId} onCategoryClick={onCategoryClick} />}

      <SubTabs tabs={configTabs()} onTabChange={handleTabChange} />
    </div>
  );
};

export default SearchResultView;
