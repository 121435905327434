import gsap from 'gsap';
import {
  EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY,
  EXIT_ANIMATION_DURATION_DOCUMENT_KEY
} from 'js/config/animationOptions';
import {
  BOUNCE_LOOP_DISTANCE,
  BOUNCE_LOOP_KEY,
  DRAG_IN_KEY,
  EMPHASIS_TWEEN_DOCUMENT_KEY,
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  EXIT_ANIMATION_ERASE_KEY,
  EXIT_TWEEN_DOCUMENT_KEY,
  PULSE_LOOP_KEY,
  SHAKE_LOOP_DISTANCE,
  SHAKE_LOOP_KEY
} from 'js/config/consts';
import { MASK_LINE_COLOR } from 'js/config/defaults';
import { animationTypeHasNoDuration } from 'js/shared/helpers/animationTypeHasNoDuration';
import getNewCamera from 'js/shared/helpers/getNewCamera';
import { Tween, TweenAnimation, VSCoordinate, VSImageShapeOrTextElementModel } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import isBoolean from 'lodash.isboolean';
import isNumber from 'lodash.isnumber';
import isString from 'lodash.isstring';
import * as PIXI from 'pixi.js';
import { isPremiumElement } from 'js/shared/helpers/content/premiumContent';

import generateScribblePathArray from '../helpers/generateScribblePathArray';
import setupBasicMask from '../helpers/setupBasicMask';
import setupCursor from '../helpers/setupCursor';
import { getWatermarkTexture } from '../helpers/addWatermark';

import VSScene from './VSScene';

export interface AnimatableElementProps {
  element: VSImageShapeOrTextElementModel;
  playerRef: PIXI.Application;
  animationTime: number;
  emphasisAnimationTime: number;
  emphasisAnimationLoops: number;
  exitAnimationTime: number;
  pauseTime: number;
  timings: {
    startTimeMs: number;
    endTimeMs: number;
  };
  scene: VSScene;
  showWatermarksOnPremiumContent?: boolean;
}

export default class AnimatableElement {
  stageElement: PIXI.Container | null;
  scaleHolder: PIXI.Container | null;
  playerRef: PIXI.Application;
  elementBounds?: PIXI.Rectangle;
  cursor: PIXI.Container;

  animationTime: number;
  emphasisAnimationTime: number;
  emphasisAnimationLoops: number;
  exitAnimationTime: number;
  pauseTime: number;
  element: VSImageShapeOrTextElementModel;

  timings: {
    startTimeMs: number;
    endTimeMs: number;
  };

  entranceAnchor: VSCoordinate | null;
  emphasisAnchor: VSCoordinate | null;
  exitAnchor: VSCoordinate | null;
  currentAnchor: VSCoordinate | null;

  entranceTimeline?: gsap.core.Timeline;
  emphasisTimeline?: gsap.core.Timeline;
  exitTimeline?: gsap.core.Timeline;
  masterTimeline?: gsap.core.Timeline;
  entranceCursorTimeline?: gsap.core.Timeline | null;
  dragCursorOutPercentage: number;

  entranceProgress?: number;
  exitProgress?: number;

  masksCleared: boolean;

  hasExitMask: boolean;
  exitBrushSize?: number;
  exitMask: PIXI.Graphics | null;
  exitPathPoints?: Array<VSCoordinate>;

  shouldAnimateReveal: boolean;
  currentTime?: number;
  scene: VSScene;
  showWatermarksOnPremiumContent: boolean;

  constructor({
    playerRef,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    element,
    timings,
    scene,
    showWatermarksOnPremiumContent = false
  }: AnimatableElementProps) {
    this.scene = scene;
    this.timings = timings;
    this.stageElement = null;
    this.scaleHolder = null;
    this.playerRef = playerRef;
    this.animationTime = animationTime;
    this.emphasisAnimationTime = emphasisAnimationTime;
    this.emphasisAnimationLoops = emphasisAnimationLoops;
    this.exitAnimationTime = exitAnimationTime;
    this.pauseTime = pauseTime;
    this.entranceAnchor = null;
    this.emphasisAnchor = null;
    this.exitAnchor = null;
    this.currentAnchor = null;
    this.element = element;
    this.masksCleared = false;
    this.hasExitMask = element?.exitAnimation?.id === EXIT_ANIMATION_ERASE_KEY;
    this.exitMask = null;
    this.shouldAnimateReveal = !!element && !element[ENTRANCE_TWEEN_DOCUMENT_KEY];
    this.dragCursorOutPercentage = this.getDragCursorOutPercentage();
    this.cursor = setupCursor();
    this.showWatermarksOnPremiumContent = showWatermarksOnPremiumContent;
  }

  initialiseTimeline() {
    this.masterTimeline = gsap.timeline();

    if (this.element[ENTRANCE_TWEEN_DOCUMENT_KEY] && this.animationTime > 0) {
      const entranceTimeline = this.initialiseEntranceTweenTimeline();
      const entranceCursorTimeline = this.initializeEntranceCursorTimeline();
      if (entranceTimeline) {
        this.masterTimeline.add(entranceTimeline);
      }
      if (entranceCursorTimeline) {
        this.masterTimeline.add(entranceCursorTimeline, '<');
      }
    } else if (this.shouldAnimateReveal && this.animationTime > 0) {
      this.padOutTimeline(this.masterTimeline, this.animationTime / 1000);
    }

    if (this.element[EMPHASIS_TWEEN_DOCUMENT_KEY]) {
      const emphasisTimeline = this.initialiseEmphasisTweenTimeline();
      if (emphasisTimeline) {
        this.masterTimeline.add(emphasisTimeline);
      }
    } else if (this.element[EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY]) {
      this.padOutTimeline(this.masterTimeline, (this.animationTime + this.emphasisAnimationTime) / 1000);
    }

    if (this.element[EXIT_TWEEN_DOCUMENT_KEY]) {
      const exitTimeline = this.initialiseExitTweenTimeline();
      if (exitTimeline) {
        this.masterTimeline.add(exitTimeline);
      }
    } else if (this.element[EXIT_ANIMATION_DURATION_DOCUMENT_KEY]) {
      this.padOutTimeline(
        this.masterTimeline,
        (this.animationTime + this.emphasisAnimationTime + this.exitAnimationTime) / 1000
      );
    }

    if (this.pauseTime) {
      this.padOutTimeline(
        this.masterTimeline,
        (this.animationTime + this.emphasisAnimationTime + this.exitAnimationTime + this.pauseTime) / 1000
      );
    }

    this.masterTimeline.pause();
    this.masterTimeline.progress(0);
  }

  padOutTimeline(timeline: gsap.core.Timeline, position: number) {
    timeline.set({}, {}, position);
  }

  getDragCursorOutPercentage() {
    return this.element[ENTRANCE_TWEEN_DOCUMENT_KEY]?.id === DRAG_IN_KEY ? 0.2 : 0;
  }

  getEntranceTweenDuration() {
    return this.element[ENTRANCE_TWEEN_DOCUMENT_KEY]?.id === DRAG_IN_KEY
      ? (this.animationTime - this.animationTime * this.dragCursorOutPercentage) / 1000
      : this.animationTime / 1000;
  }

  initializeEntranceCursorTimeline() {
    if (this.element[ENTRANCE_TWEEN_DOCUMENT_KEY]?.id === DRAG_IN_KEY && this.cursor) {
      const bounds = this.scaleHolder?.getLocalBounds();
      this.stageElement?.getBounds();
      if (bounds) {
        const centrePos = new PIXI.Point((bounds.left + bounds.right) / 2, (bounds.bottom + bounds.top) / 2);

        this.cursor.x = centrePos.x;
        this.cursor.y = centrePos.y;

        const global = this.cursor.toGlobal(this.playerRef.stage);
        global.y = global.y + (this.playerRef.screen.height - centrePos.y);
        const targetPos = this.cursor.toLocal(global);

        this.entranceCursorTimeline = gsap.timeline();
        this.entranceCursorTimeline.add(
          gsap.to(this.cursor, {
            y: `+=${targetPos.y}`,
            x: `+=${targetPos.x}`,
            duration: this.animationTime / 1000 - this.getEntranceTweenDuration()
          }),
          this.getEntranceTweenDuration()
        );
        this.entranceCursorTimeline.progress(0);
        return this.entranceCursorTimeline;
      }
    }
  }

  getCameraAssignedToElement() {
    const targetIndex = this.scene.sceneModel.elementIds.indexOf(this.element.id);
    if (targetIndex !== -1) {
      const precedingElements = this.scene.sceneModel.elementIds.slice(0, targetIndex).reverse();
      for (let i = 0; i < precedingElements.length; i++) {
        const currentValue = precedingElements[i];
        const foundObject = this.scene.scribeModel.elements.find(obj => obj.id === currentValue);
        if (foundObject && foundObject.type === 'Camera') {
          return foundObject;
        }
      }
    }
    return getNewCamera();
  }

  scaleTweenValOnCameraSize(baseValue: number, thisValue: string, camZoom: number) {
    let newValue = thisValue === '-=' + baseValue.toString() ? '-=' : '+=';
    newValue += thisValue === '+=0' ? 0 : Math.round(baseValue / camZoom).toString();
    return newValue;
  }

  scaleEmphasisTweensToCamera(tweenConfig: TweenAnimation | undefined) {
    const camera = this.getCameraAssignedToElement();
    let scaledTweensIn = tweenConfig?.tweens_in;
    let scaledTweensOut = tweenConfig?.tweens_out;
    let loopDistance = 0;
    switch (tweenConfig?.id) {
      case SHAKE_LOOP_KEY:
        loopDistance = SHAKE_LOOP_DISTANCE;
        break;
      case BOUNCE_LOOP_KEY:
        loopDistance = BOUNCE_LOOP_DISTANCE;
        break;
    }
    switch (tweenConfig?.id) {
      case PULSE_LOOP_KEY:
        const pulseScale = 1 + 0.1 / camera.scale;
        scaledTweensIn = tweenConfig?.tweens_in?.map(tween => {
          return {
            ...tween,
            scaleX: pulseScale,
            scaleY: pulseScale
          };
        });
        break;
      case SHAKE_LOOP_KEY:
      case BOUNCE_LOOP_KEY:
        scaledTweensIn = tweenConfig?.tweens_in?.map(tween => {
          const newX = this.scaleTweenValOnCameraSize(
            loopDistance,
            tween.x === undefined ? '+=0' : tween.x,
            camera.scale
          );
          const newY = this.scaleTweenValOnCameraSize(
            loopDistance,
            tween.y === undefined ? '+=0' : tween.y,
            camera.scale
          );
          return {
            ...tween,
            x: newX,
            y: newY
          };
        });
        scaledTweensOut = tweenConfig?.tweens_out?.map(tween => {
          const newX = this.scaleTweenValOnCameraSize(
            loopDistance,
            tween.x === undefined ? '+=0' : tween.x,
            camera.scale
          );
          const newY = this.scaleTweenValOnCameraSize(
            loopDistance,
            tween.y === undefined ? '+=0' : tween.y,
            camera.scale
          );
          return {
            ...tween,
            x: newX,
            y: newY
          };
        });
        break;
    }
    return [scaledTweensIn, scaledTweensOut];
  }

  initialiseEntranceTweenTimeline() {
    this.entranceTimeline = gsap.timeline();
    const tweenConfig = cloneDeep(this.element[ENTRANCE_TWEEN_DOCUMENT_KEY]);
    const tweens = tweenConfig && 'tweens' in tweenConfig ? tweenConfig.tweens : [];
    if (tweens && this.entranceTimeline) {
      for (let i = 0; i < tweens.length; i++) {
        const tweenElement = tweens[i];
        const tweenVals = this.parseTweenConfig(tweenElement, 'entrance');
        const elementToTween = this.areAnyValidValues(tweenVals.scaleX, tweenVals.scaleY, tweenVals.rotation)
          ? this.scaleHolder
          : this.stageElement;
        const tween = gsap.from(elementToTween, {
          pixi: tweenVals,
          ease: tweenElement.ease,
          duration: this.getEntranceTweenDuration()
        });

        this.entranceTimeline.add(tween);

        if (this.element[ENTRANCE_TWEEN_DOCUMENT_KEY]?.id === DRAG_IN_KEY) {
          // pads out the timeline by the difference in animation time and tween duration
          this.padOutTimeline(this.entranceTimeline, this.animationTime / 1000);
        }
      }
    }

    this.entranceTimeline.progress(0);
    return this.entranceTimeline;
  }

  initialiseEmphasisTweenTimeline() {
    const tweenConfig = cloneDeep(this.element[EMPHASIS_TWEEN_DOCUMENT_KEY]);
    this.emphasisTimeline = gsap.timeline({
      yoyo: false,
      repeat: this.emphasisAnimationLoops - 1
    });
    const [scaledTweensIn, scaledTweensOut] = this.scaleEmphasisTweensToCamera(tweenConfig);
    if (scaledTweensIn) {
      for (let i = 0; i < scaledTweensIn.length; i++) {
        const tweenInElement = scaledTweensIn[i];

        if (!tweenInElement.percentage_of_total) break;

        const tweenVals = this.parseTweenConfig(tweenInElement, 'emphasis');
        const elementToTween = this.areAnyValidValues(tweenVals.scaleX, tweenVals.scaleY, tweenVals.rotation)
          ? this.scaleHolder
          : this.stageElement;

        const duration =
          (this.emphasisAnimationTime / 1000 / this.emphasisAnimationLoops) * tweenInElement.percentage_of_total;

        const tween = gsap.to(elementToTween, {
          pixi: tweenVals,
          ease: tweenInElement.ease,
          duration
        });

        this.emphasisTimeline.add(tween);
      }
    }

    if (scaledTweensOut) {
      for (let i = 0; i < scaledTweensOut.length; i++) {
        const tweenOutElement = scaledTweensOut[i];

        if (!tweenOutElement.percentage_of_total) break;

        const tweenVals = this.parseTweenConfig(tweenOutElement, 'emphasis');
        const elementToTween = this.areAnyValidValues(tweenVals.scaleX, tweenVals.scaleY, tweenVals.rotation)
          ? this.scaleHolder
          : this.stageElement;

        const duration =
          (this.emphasisAnimationTime / 1000 / this.emphasisAnimationLoops) * tweenOutElement.percentage_of_total;

        const tween = gsap.to(elementToTween, {
          pixi: tweenVals,
          ease: tweenOutElement.ease,
          duration
        });

        this.emphasisTimeline.add(tween);
      }
    }

    return this.emphasisTimeline;
  }

  initialiseExitTweenTimeline() {
    this.exitTimeline = gsap.timeline();

    const tweenConfig = cloneDeep(this.element[EXIT_TWEEN_DOCUMENT_KEY]);
    const tweens = tweenConfig && 'tweens' in tweenConfig ? tweenConfig.tweens : [];

    if (tweens) {
      for (let i = 0; i < tweens.length; i++) {
        const element = tweens[i];
        const tweenVals = this.parseTweenConfig(element, 'exit');
        const elementToTween = this.areAnyValidValues(tweenVals.scaleX, tweenVals.scaleY, tweenVals.rotation)
          ? this.scaleHolder
          : this.stageElement;

        const gsapTweenConfig = {
          pixi: tweenVals
        };

        const tween =
          this.exitAnimationTime === 0
            ? gsap.set(elementToTween, { ...gsapTweenConfig, immediateRender: false })
            : gsap.to(elementToTween, {
                ...gsapTweenConfig,
                ease: element.ease,
                duration: this.exitAnimationTime / 1000
              });
        this.exitTimeline.add(tween);
      }
    }

    return this.exitTimeline;
  }

  areAnyValidValues(...values: Array<string | number | undefined | null | boolean>) {
    return values.some(value => (isNumber(value) || isString(value)) && !isBoolean(value) && value !== '');
  }

  parseTweenConfig(tweenConfig?: Tween, animationStage?: string) {
    const toReturn: PixiPlugin.Vars = {};

    if (!tweenConfig) {
      return toReturn;
    }

    for (const key in tweenConfig) {
      let valueToParse = tweenConfig[key];
      if (typeof valueToParse === 'string' && valueToParse.indexOf('@') === 0) {
        const positionValue = valueToParse.substr(1);
        const fromPos = { x: -1, y: -1 };
        switch (positionValue.toLowerCase()) {
          case 'tl':
            fromPos.x = 0;
            fromPos.y = 0;
            break;

          case 't':
            fromPos.y = 0;
            break;

          case 'tr':
            fromPos.x = 1;
            fromPos.y = 0;
            break;

          case 'l':
            fromPos.x = 0;
            break;

          case 'r':
            fromPos.x = 1;
            break;

          case 'bl':
            fromPos.x = 0;
            fromPos.y = 1;
            break;

          case 'b':
            fromPos.y = 1;
            break;

          case 'br':
            fromPos.x = 1;
            fromPos.y = 1;
            break;

          case 'm':
            fromPos.x = 0.5;
            fromPos.y = 0.5;
            break;

          default:
            break;
        }
        if (key === 'position') {
          const offscreenPos = this.getOffScreenPosition(fromPos, animationStage);
          toReturn.x = offscreenPos.x;
          toReturn.y = offscreenPos.y;
        }
        if (key === 'anchor') {
          if (animationStage === 'entrance') {
            this.entranceAnchor = fromPos;
          }
          if (animationStage === 'emphasis') {
            this.emphasisAnchor = fromPos;
          }
          if (animationStage === 'exit') {
            this.exitAnchor = fromPos;
          }
        }
      } else {
        if (!this.scaleHolder) {
          return toReturn;
        }

        if (key !== 'ease') {
          if (key === 'scaleX' && typeof valueToParse === 'number') {
            valueToParse *= this.scaleHolder.scale.x;
          }
          if (key === 'scaleY' && typeof valueToParse === 'number') {
            valueToParse *= this.scaleHolder.scale.y;
          }
          if (key === 'rotation' && typeof valueToParse === 'number') {
            valueToParse += this.scaleHolder.angle;
          }
          if (key !== 'duration' && key !== 'percentage_of_total') {
            toReturn[key] = valueToParse;
          }
          if (key === 'x' && typeof valueToParse === 'number') {
            valueToParse += this.scaleHolder.x;
          }
          if (key === 'y' && typeof valueToParse === 'number') {
            valueToParse += this.scaleHolder.y;
          }
        }
      }
    }
    return toReturn;
  }

  checkPointEquality(point1?: VSCoordinate | null, point2?: VSCoordinate | null) {
    if (!point1 || !point2) return false;
    return point1.x === point2.x && point1.y === point2.y;
  }

  progressEntranceMaskAnimation(progress: number) {
    if (this.entranceProgress === progress) return;
    this.entranceProgress = progress;

    this.revealAnimation(progress);
  }

  progressExitMaskAnimation(progress: number) {
    if (this.exitProgress === progress) return;
    this.exitProgress = progress;

    this.exitMaskAnimation(progress);
  }

  getOffScreenPosition(fromPos: VSCoordinate, animationStage?: string) {
    const stage = this.playerRef.screen;

    const stageWidth = stage.width;
    const stageHeight = stage.height;

    const elementOffsetX = 0;
    const elementOffsetY = 0;

    let toReturn: PIXI.Point;
    if (this.stageElement) {
      this.revealAnimation(1); // set the reveal to 100% so the element has bounds
      this.stageElement.updateTransform();
      this.stageElement.calculateBounds();
      const elementBounds = this.stageElement.getLocalBounds();
      this.revealAnimation(0); // reset the reveal animation

      const cameraBounds = this.scene.viewport.boundsAtTime(
        animationStage === 'exit' ? this.timings.endTimeMs - this.exitAnimationTime : this.timings.startTimeMs
      );

      const offCamera = {
        x:
          fromPos.x === 0
            ? cameraBounds.left - elementBounds.width - elementBounds.x
            : cameraBounds.right - elementBounds.x,
        y:
          fromPos.y === 0
            ? cameraBounds.top - elementBounds.height - elementBounds.y
            : cameraBounds.bottom - elementBounds.y
      };

      toReturn = new PIXI.Point(offCamera.x, offCamera.y);

      if (fromPos.x === -1) {
        toReturn.x = this.element.x;
      }

      if (fromPos.y === -1) {
        toReturn.y = this.element.y;
      }
    } else {
      toReturn = new PIXI.Point(fromPos.x * stageWidth + elementOffsetX, fromPos.y * stageHeight + elementOffsetY);
    }

    return toReturn;
  }

  setUpNestedClips(element: PIXI.Graphics | PIXI.Container, width = this.element.width, height = this.element.height) {
    if (isPremiumElement(this.element) && this.showWatermarksOnPremiumContent) {
      const watermark = PIXI.Sprite.from(getWatermarkTexture());
      watermark.name = 'watermark' + this.element.id;

      const positionWatermark = () => {
        watermark.width = this.element.width;
        watermark.height = (this.element.width / watermark.texture.width) * watermark.texture.height;
        watermark.x = this.element.width - watermark.width;
        watermark.y = this.element.height / 2 - watermark.height / 2;
      };

      if (watermark.texture.baseTexture.valid) {
        positionWatermark();
      } else {
        watermark.texture.baseTexture.on('loaded', () => {
          positionWatermark();
        });
      }
      element.addChild(watermark);
    }

    this.scaleHolder = new PIXI.Container();
    this.scaleHolder.name = 'Scale Holder';
    const posHolder = new PIXI.Container();
    posHolder.name = 'Position Holder';

    this.scaleHolder.addChild(element);
    posHolder.addChild(this.scaleHolder);

    if (this.hasExitMask) {
      this.setupExitMask(width, height);
    }

    return posHolder;
  }

  exitMaskAnimation(progress: number) {
    if (this.exitMask) {
      if (this.stageElement && this.stageElement.mask !== this.exitMask) {
        this.scaleHolder?.addChild(this.exitMask);
        this.stageElement.mask = this.exitMask;
      }

      this.exitMask.clear();

      this.exitMask.lineStyle({
        width: this.exitBrushSize,
        color: MASK_LINE_COLOR,
        cap: PIXI.LINE_CAP.SQUARE,
        join: PIXI.LINE_JOIN.ROUND
      });

      if (this.exitPathPoints) {
        const length = this.exitPathPoints.length;
        const drawTo = Math.floor(length * progress);

        const drawPathSegment = this.exitPathPoints.slice(drawTo);

        if (drawPathSegment.length) {
          this.exitMask.moveTo(drawPathSegment[0].x, drawPathSegment[0].y);
          drawPathSegment.forEach(point => {
            this.exitMask?.lineTo(point.x, point.y);
          });

          if (this.cursor && progress > 0) {
            this.cursor.x = drawPathSegment[0].x - 5;
            this.cursor.y = drawPathSegment[0].y + 5;
          }
        }
      }
    }
  }

  setupExitMask(width: number, height: number) {
    const { scribbleArray, brushSize } = generateScribblePathArray(width, height);

    this.exitBrushSize = brushSize;
    this.exitPathPoints = scribbleArray;

    this.exitMask = setupBasicMask(
      {
        width: this.exitBrushSize,
        color: MASK_LINE_COLOR,
        cap: PIXI.LINE_CAP.ROUND,
        join: PIXI.LINE_JOIN.ROUND
      },
      this.exitPathPoints[0]
    );
    this.exitMask.name = 'Exit Mask';

    // Draw the whole mask so it shows the element.
    this.exitMask.moveTo(this.exitPathPoints[0].x, this.exitPathPoints[0].y);
    this.exitPathPoints.forEach(point => {
      this.exitMask?.lineTo(point.x, point.y);
    });
  }

  positionNestedClips(scalePoint: VSCoordinate | null | undefined) {
    if (!scalePoint) scalePoint = { x: 0.5, y: 0.5 };

    if (this.scaleHolder) {
      const scale = { x: this.scaleHolder.scale.x, y: this.scaleHolder.scale.y };
      const angle = this.scaleHolder.angle;
      const element = this.scaleHolder.getChildAt(0);
      const elementBounds = element.getLocalBounds();
      const pos = this.scaleHolder.toGlobal(
        new PIXI.Point(
          elementBounds.left + Math.abs(elementBounds.width * scalePoint.x),
          elementBounds.top + Math.abs(elementBounds.height * scalePoint.y)
        )
      );
      this.scaleHolder.scale.set(1);
      this.scaleHolder.rotation = 0;
      this.scaleHolder.updateTransform();

      this.scaleHolder.pivot.set(
        elementBounds.left + Math.abs(scalePoint.x * elementBounds.width),
        elementBounds.top + Math.abs(scalePoint.y * elementBounds.height)
      );

      this.elementBounds = elementBounds;
      this.scaleHolder.scale.set(scale.x, scale.y);
      this.scaleHolder.angle = angle;
      const transformedPos = this.scaleHolder.parent.toLocal(pos);
      this.scaleHolder.position.set(transformedPos.x, transformedPos.y);
    }
  }

  clearCursorFromCanvas() {
    if (this.cursor) {
      this.cursor.position.x = Infinity;
      this.cursor.position.y = Infinity;
    }
  }

  getAnimationPhase({
    relativeCurrentTime,
    entranceStart,
    emphasisStart,
    exitStart,
    exitEnd
  }: {
    relativeCurrentTime: number;
    entranceStart: number;
    emphasisStart: number;
    exitStart: number;
    exitEnd: number;
  }) {
    let phase = 'none';

    if (relativeCurrentTime >= entranceStart && relativeCurrentTime <= emphasisStart) {
      phase = 'entrance';
    }
    if (relativeCurrentTime > emphasisStart && relativeCurrentTime <= exitStart) {
      phase = 'emphasis';
    }
    if (relativeCurrentTime > exitStart && relativeCurrentTime <= exitEnd) {
      phase = 'exit';
    }
    if (relativeCurrentTime > exitEnd) {
      phase = 'exitEnd';
    }

    return phase;
  }

  updateAnchors = (phase: string) => {
    let anchor;
    switch (phase) {
      case 'entrance':
        anchor = this.entranceAnchor;
        break;
      case 'emphasis':
        anchor = this.emphasisAnchor;
        break;
      case 'exit':
        anchor = this.exitAnchor;
        break;
    }

    if (anchor) {
      if (!this.checkPointEquality(this.currentAnchor, anchor)) {
        this.currentAnchor = anchor;
        this.clearMasksFromStage();
        this.positionNestedClips(this.currentAnchor);
      }
    }
  };

  manageStageElementVisibility = (currentTime: number, sceneTransitionTime: number, sceneStartTime: number) => {
    if (this.stageElement) {
      this.stageElement.visible = true;

      const currentTimeIsBeforeElementStartTime = currentTime < this.timings.startTimeMs;
      const elementHasNoAnimationTime = this.element.animationTime === 0;
      const sceneTransitionAdjustedStartTime = this.timings.startTimeMs - sceneTransitionTime;

      const currentTimeIsAfterElementEndTime = currentTime > this.timings.endTimeMs;
      const elementExits =
        (this.element.exitAnimationTime && this.element.exitAnimationTime > 0) ||
        !!this.element.exitAnimation ||
        !!this.element.exitTween;

      if (
        (currentTimeIsBeforeElementStartTime &&
          !(elementHasNoAnimationTime && sceneTransitionAdjustedStartTime === sceneStartTime)) ||
        (currentTimeIsAfterElementEndTime && elementExits)
      ) {
        this.stageElement.visible = false;
      }
    }
  };
  destroy() {
    if (this.element.type === 'Text') {
      this.stageElement?.destroy({ children: true, texture: true, baseTexture: true });
    } else {
      this.stageElement?.destroy({ children: true, texture: false, baseTexture: false });
    }
    this.emphasisTimeline = undefined;
    this.entranceTimeline = undefined;
    this.masterTimeline = undefined;
    this.exitTimeline = undefined;
  }

  update(currentTime: number, sceneTransitionTime: number, sceneStartTime: number) {
    if (currentTime === this.currentTime) return;
    this.currentTime = currentTime;

    this.manageStageElementVisibility(currentTime, sceneTransitionTime, sceneStartTime);

    if (this.timings && currentTime < this.timings.startTimeMs) {
      this.progressEntranceMaskAnimation(0);
      if (this.element.animationTime === 0 && this.timings.startTimeMs - sceneTransitionTime === sceneStartTime) {
        if (this.masksCleared === false) {
          this.clearMasksFromStage();
          this.positionNestedClips(this.currentAnchor);
        }
      }
    }

    if (this.timings) {
      const relativeCurrentTime = currentTime - this.timings.startTimeMs;
      const entranceStart = 0;
      const emphasisStart = this.animationTime;
      const exitStart = emphasisStart + this.emphasisAnimationTime;
      const exitEnd = exitStart + this.exitAnimationTime;
      const phase = this.getAnimationPhase({
        relativeCurrentTime,
        entranceStart,
        emphasisStart,
        exitStart,
        exitEnd
      });

      const entranceProgress =
        this.animationTime !== 0 ? (currentTime - this.timings.startTimeMs) / this.animationTime : 1;

      const exitProgress =
        this.exitAnimationTime !== 0 || animationTypeHasNoDuration(this.element?.exitTween?.id)
          ? (currentTime - (this.timings.startTimeMs + this.animationTime + this.emphasisAnimationTime)) /
            this.exitAnimationTime
          : 0;

      const masterProgress = gsap.utils.clamp(
        0,
        1,
        (currentTime - this.timings.startTimeMs) / (this.timings.endTimeMs - this.timings.startTimeMs)
      );

      this.updateAnchors(phase);
      this.masterTimeline?.progress(masterProgress);

      if (phase === 'entrance' || phase === 'none') {
        this.progressEntranceMaskAnimation(gsap.utils.clamp(0, 1, entranceProgress));
        this.progressExitMaskAnimation(0);
      } else if (phase === 'emphasis') {
        this.progressEntranceMaskAnimation(1);
        this.progressExitMaskAnimation(0);
      } else if (phase === 'exit') {
        this.progressEntranceMaskAnimation(1);
        this.progressExitMaskAnimation(gsap.utils.clamp(0, 1, exitProgress));
      } else if (phase === 'exitEnd') {
        this.progressEntranceMaskAnimation(1);
        this.progressExitMaskAnimation(1);
      }
    }
  }

  revealAnimation(_number?: number) {
    // declaring function
  }

  clearMasksFromStage() {
    this.masksCleared = true;
  }
}
