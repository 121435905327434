import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './CallToAction.css';

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  renderAs?: 'button';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  theme?: 'primary' | 'secondary' | 'dark' | 'amber';
  fullWidth?: boolean;
  id?: string;
  type?: 'button' | 'reset' | 'submit';
};

type LinkProps = {
  children: React.ReactNode;
  className?: string;
  renderAs?: 'link';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  theme?: 'primary' | 'secondary' | 'dark' | 'amber';
  fullWidth?: boolean;
  to: string;
  id?: string;
};

type Props = ButtonProps | LinkProps;

const CallToAction = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    { children, className, renderAs = 'button', disabled = false, onClick, theme = 'primary', fullWidth, ...props },
    ref
  ) => {
    function handleLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
      if (disabled) {
        event.preventDefault();
      } else {
        if (onClick) {
          onClick(event);
        }
      }
    }

    return renderAs === 'link' && 'to' in props ? (
      <Link
        innerRef={node => {
          if (ref && 'current' in ref) {
            ref.current = node;
          }
        }}
        className={classnames(
          'cta__link--base',
          `cta__link--${theme}`,
          { 'cta__link--disabled': disabled },
          { 'cta__link--fullWidth': fullWidth },
          className && { [className]: className },
          't-button-text'
        )}
        onClick={handleLinkClick}
        {...props}
      >
        {children}
      </Link>
    ) : (
      <button
        ref={ref as React.LegacyRef<HTMLButtonElement>}
        className={classnames(
          'cta__button--base',
          `cta__button--${theme}`,
          { 'cta__button--disabled': disabled },
          { 'cta__button--fullWidth': fullWidth },
          className && { [className]: className },
          't-button-text'
        )}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export const CallToActionButton = React.forwardRef<HTMLButtonElement, ButtonProps & React.HTMLProps<HTMLButtonElement>>(
  ({ children, theme = 'primary', disabled = false, fullWidth = false, className, onClick, ...props }, ref) => {
    return (
      <button
        data-testid="cta__button"
        ref={ref as React.LegacyRef<HTMLButtonElement>}
        className={classnames(
          'cta__button--base',
          `cta__button--${theme}`,
          { 'cta__button--disabled': disabled },
          { 'cta__button--fullWidth': fullWidth },
          className && { [className]: className },
          't-button-text'
        )}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default CallToAction;
