import { takeEvery } from 'redux-saga/effects';
import { AUTH_SUCCESS, LOGOUT } from 'js/actionCreators/authActions';
import { GET_VIDEO_DOWNLOAD_URL_SUCCESS } from 'js/actionCreators/downloadActions';
import { LOAD_SCRIBES_SUCCESS } from 'js/actionCreators/scribeActions';
import { getUID } from 'js/shared/helpers/TokenHelper';
import { SUBMIT_USERPERSONA_MODAL } from 'js/actionCreators/marketingActions';
import { TRACK_PERSONA_ANSWERS } from 'js/actionCreators/trackingActions';

const hotjar = window.hj;

const debug = !!import.meta.env.VITE_DEBUG_HOTJAR;
const logger = debug ? (...args) => console.log(`%c[HotJar Debug]: `, 'color: #FF3C00', ...args) : () => {};

function hotjarIdentifyWithAttributes(userAttributes) {
  if (hotjar) {
    const userId = getUID() || null;
    hotjar('identify', userId, userAttributes);
    logger(`Identified user ${userId} with user attributes`, userAttributes);
  }
}

function hotjarIdentifyUser({ user }) {
  const userAttributes = {
    'Subscription Tier': user.accountType,
    'Licence Type': user.accountType
  };
  hotjarIdentifyWithAttributes(userAttributes);
}

function hotjarApplyScribes({ scribes }) {
  const userAttributes = {
    'Current Number of Scribes': scribes.length
  };
  hotjarIdentifyWithAttributes(userAttributes);
}

function hotjarApplyDownloaded() {
  const userAttributes = {
    'Has Downloaded': true
  };
  hotjarIdentifyWithAttributes(userAttributes);
}

function hotjarApplyPersona({ answers }) {
  const organization = answers.user_type === 'other' ? `other:${answers.user_type_text}` : answers.user_type;
  const userAttributes = {
    'User Organisation': organization,
    'User Intentions': answers.intention.join('|')
  };
  hotjarIdentifyWithAttributes(userAttributes);
}

function hotjarApplyAnonUser() {
  hotjarIdentifyWithAttributes();
}

export default function* hotjarSagas() {
  yield takeEvery(AUTH_SUCCESS, hotjarIdentifyUser);
  yield takeEvery(LOAD_SCRIBES_SUCCESS, hotjarApplyScribes);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, hotjarApplyDownloaded);
  yield takeEvery([SUBMIT_USERPERSONA_MODAL, TRACK_PERSONA_ANSWERS], hotjarApplyPersona);
  yield takeEvery(LOGOUT, hotjarApplyAnonUser);
}
