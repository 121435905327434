import React from 'react';

const IconLeftMinimise = () => (
  <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.3125 13.125L1.53395 7.74914C1.40766 7.60705 1.40766 7.39295 1.53395 7.25086L6.3125 1.875"
      stroke="currentcolor"
      strokeWidth="1.875"
      strokeLinecap="round"
    />
  </svg>
);

export default IconLeftMinimise;
