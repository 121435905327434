import { gsap } from 'gsap';

import VSSceneTransitionBase, { VSSceneTransitionBaseProps } from './VSSceneTransitionBase';

export default class VSFadeTransition extends VSSceneTransitionBase {
  tween2CurrIn: GSAPTween;

  constructor(props: VSSceneTransitionBaseProps) {
    super({ ...props });

    this.scene1Out = 1;
    this.scene2In = 1;

    this.tween2CurrIn = this.setupIntroTransition();
  }

  setupIntroTransition() {
    const tween2CurrIn = gsap.fromTo(
      this.currentScene.viewportContainer,
      {
        alpha: 0
      },
      { alpha: 1, duration: 1 }
    );

    tween2CurrIn.pause();
    return tween2CurrIn;
  }

  updateIntroProgress(progress: number) {
    if (this.tween2CurrIn) {
      this.tween2CurrIn.progress(progress);
    }
  }
}
