import React from 'react';
import uniq from 'lodash.uniq';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { ElementMenuProps, ScribeCursorId } from 'js/types';
import { DEFAULT_ERASE_CURSOR_ID } from 'js/config/consts';
import { useDispatch } from 'react-redux';
import { setElementsAnimationCursor } from 'js/actionCreators/animationConfigActions';

import HandMenu from '../AnimationPanels/HandMenu';

export default function EraseHandPicker({ element, elements, scribe, onSelection, stageKey: stage }: ElementMenuProps) {
  const hands = useTypedSelector(state => state.cursors.handErase);
  const filters = ['none', 'all', ...uniq(hands.map(hand => hand.group.toLowerCase()).sort())];
  const elementIds = !!element ? [element.id] : elements.map(el => el.id);

  const dispatch = useDispatch();

  const handleSelection = (cursorId: ScribeCursorId) => {
    dispatch(setElementsAnimationCursor({ cursorId, elementIds, stage, scribeId: scribe.id }));
    onSelection();
  };

  const handleFilterChange = (filterName: string) => {
    if (filterName === 'none') {
      dispatch(setElementsAnimationCursor({ cursorId: -1, elementIds, stage, scribeId: scribe.id }));
      onSelection();
    }
  };

  let selectedCursorId;
  if (element) {
    selectedCursorId = element.exitAnimation?.config?.cursorId;
  } else {
    const firstElCursorId = elements[0].exitAnimation?.config?.cursorId;
    const allSame = elements.every(el => (el.cursorId ?? scribe.cursor) === firstElCursorId);
    if (allSame) {
      selectedCursorId = firstElCursorId;
    }
  }

  return (
    <HandMenu
      hands={hands}
      onSelection={handleSelection}
      selectedCursorId={selectedCursorId}
      defaultCursorId={DEFAULT_ERASE_CURSOR_ID}
      defaultCursorGroup="all"
      filters={filters}
      onFilterChange={handleFilterChange}
    />
  );
}
