import * as PIXI from 'pixi.js';

import TimelineAudioClip from './TimelineAudioClip';

export const buildSplitTrackConfirmation = (
  splitModeClip: TimelineAudioClip,
  stopSplitMode: () => void,
  splitTrack: (clip: TimelineAudioClip) => void
) => {
  const conformation = new PIXI.Graphics();

  conformation.beginFill(0xffffff);
  conformation.drawRoundedRect(0, 0, 165, 74, 3);
  conformation.endFill();
  const bounds = splitModeClip.getBounds();
  conformation.x = bounds.x + bounds.width / 2 - 165;
  conformation.y = bounds.y - conformation.height;
  const textStyle = new PIXI.TextStyle({
    fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--fontFamilyBody'),
    fontSize: 16,
    fill: 0x000000,
    fontWeight: '400',
    align: 'left'
  });
  const boldTextStyle = new PIXI.TextStyle({
    fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--fontFamilyBody'),
    fontSize: 12,
    fill: 0x444444,
    fontWeight: '700',
    align: 'left'
  });

  const confirmSplitBtn = new PIXI.Container();
  const confirmBg = new PIXI.Graphics();
  confirmBg.beginFill(0xffffff);
  confirmBg.drawRoundedRect(-12, -10, 165, 35, 3);
  confirmBg.endFill();
  confirmBg.name = 'bg';
  confirmSplitBtn.addChild(confirmBg);
  const confirmSplitTxt = new PIXI.Text('Confirm Split', textStyle);
  confirmSplitTxt.alpha = 0.87;
  confirmSplitBtn.addChild(confirmSplitTxt);

  const confirmSplitKeyBg = new PIXI.Graphics();
  confirmSplitKeyBg.beginFill(0xd9dbdb);
  confirmSplitKeyBg.drawRoundedRect(0, 0, 45, 20, 4);
  confirmSplitKeyBg.endFill();
  confirmSplitKeyBg.x = 92;
  confirmSplitKeyBg.y = 0;

  const entText = new PIXI.Text('Ent', boldTextStyle);
  entText.x = 13;
  entText.y = 2;
  confirmSplitKeyBg.addChild(entText);
  confirmSplitBtn.addChild(confirmSplitKeyBg);
  confirmSplitBtn.x = 12;
  confirmSplitBtn.y = 10;

  const cancelSplitBtn = new PIXI.Container();
  const cancelBg = new PIXI.Graphics();
  cancelBg.beginFill(0xffffff);
  cancelBg.drawRoundedRect(-12, -6, 165, 35, 3);
  cancelBg.endFill();
  cancelBg.name = 'bg';
  cancelSplitBtn.addChild(cancelBg);

  const cancelSplit = new PIXI.Text('Cancel Split', textStyle);
  cancelSplit.alpha = 0.87;
  cancelSplitBtn.addChild(cancelSplit);
  const cancelSplitKeyBg = new PIXI.Graphics();
  cancelSplitKeyBg.beginFill(0xd9dbdb);
  cancelSplitKeyBg.drawRoundedRect(0, 0, 45, 20, 4);
  cancelSplitKeyBg.endFill();
  cancelSplitKeyBg.x = 92;
  cancelSplitKeyBg.y = 0;
  const escText = new PIXI.Text('Esc', boldTextStyle);
  escText.x = 13;
  escText.y = 2;
  cancelSplitKeyBg.addChild(escText);
  cancelSplitBtn.addChild(cancelSplitKeyBg);

  const rollOverHandler = (e: PIXI.InteractionEvent) => {
    if (e.currentTarget instanceof PIXI.Container) {
      (e.currentTarget.getChildByName('bg') as PIXI.Graphics).tint = 0xf1f3f2;
    }
  };
  const rollOutHandler = (e: PIXI.InteractionEvent) => {
    if (e.currentTarget instanceof PIXI.Container) {
      (e.currentTarget.getChildByName('bg') as PIXI.Graphics).tint = 0xffffff;
    }
  };

  cancelSplitBtn.x = 12;
  cancelSplitBtn.y = 45;
  cancelSplitBtn.interactive = true;
  cancelSplitBtn.buttonMode = true;

  cancelSplitBtn.on('pointerup', () => {
    stopSplitMode();
  });
  cancelSplitBtn.on('pointerover', rollOverHandler);
  cancelSplitBtn.on('pointerout', rollOutHandler);
  confirmSplitBtn.interactive = true;
  confirmSplitBtn.buttonMode = true;
  confirmSplitBtn.on('pointerup', () => {
    if (splitModeClip) splitTrack(splitModeClip);
  });
  confirmSplitBtn.on('pointerover', rollOverHandler);
  confirmSplitBtn.on('pointerout', rollOutHandler);

  const keyDownHandler = (e: KeyboardEvent) => {
    window.removeEventListener('keydown', keyDownHandler);
    if (e.key === 'Enter') {
      if (splitModeClip) splitTrack(splitModeClip);
    } else if (e.key === 'Escape') {
      stopSplitMode();
    }
  };
  window.addEventListener('keydown', keyDownHandler);

  conformation.addChild(confirmSplitBtn);
  conformation.addChild(cancelSplitBtn);
  return conformation;
};

export const makeSplitCaret = (height: number) => {
  const indicator = new PIXI.Graphics();
  indicator.lineStyle(1, 0xffb434, 1);
  indicator.moveTo(0, 0);
  indicator.lineTo(4, 0);
  indicator.lineTo(4, height);
  indicator.lineTo(0, height);
  indicator.moveTo(10, 0);
  indicator.lineTo(6, 0);
  indicator.lineTo(6, height);
  indicator.lineTo(10, height);

  return indicator;
};
