import { gsap } from 'gsap';
import Maths from 'js/editor/Timeline/PixiTimeline/Utils/Maths';
import { AnimationTimingOptions, ScribeCameraElement } from 'js/types';
import clamp from 'lodash.clamp';
import { IViewportOptions, Viewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js';

interface InitializeCameraProps {
  camera: ScribeCameraElement;
  startTimeMs: number;
  endTimeMs: number;
}
interface CameraPosition {
  x: number;
  y: number;
  zoom: number;
}

export default class VSViewport extends Viewport {
  timelinePositionAndZoom: gsap.core.Timeline;
  sceneTimings: AnimationTimingOptions;
  tweeningPositions: CameraPosition;
  initialPosition: CameraPosition;
  lastProgress = Infinity;

  constructor(
    viewportOptions: IViewportOptions,
    sceneTimings: AnimationTimingOptions,
    initialPositions?: CameraPosition
  ) {
    super({
      ...viewportOptions,
      noTicker: true // we control ticks, not Pixi
    });

    this.initialPosition = initialPositions ?? {
      x: 0,
      y: 0,
      zoom: 1
    };

    this.tweeningPositions = {
      x: this.initialPosition.x * this.initialPosition.zoom,
      y: this.initialPosition.y * this.initialPosition.zoom,
      zoom: this.initialPosition.zoom
    };
    this.sceneTimings = sceneTimings;
    this.updatePositions = this.updatePositions.bind(this);

    this.timelinePositionAndZoom = this.initializeTimeline(sceneTimings);
  }

  initializeTimeline(sceneTimings: AnimationTimingOptions) {
    this.setZoom(this.initialPosition.zoom, true);
    this.x = -this.tweeningPositions.x;
    this.y = -this.tweeningPositions.y;

    const timeline = gsap.timeline({ paused: true });
    const sceneDurationSeconds = (sceneTimings.endTimeMs - sceneTimings.startTimeMs) / 1000;

    timeline.set({}, {}, sceneDurationSeconds); //  Pad out the timeline to the correct scene time

    timeline.progress(0);
    timeline.eventCallback('onUpdate', this.updatePositions);

    return timeline;
  }

  getProgress(time: number) {
    const progress = clamp(Maths.rangeValue(this.sceneTimings.startTimeMs, this.sceneTimings.endTimeMs, time), 0, 1);
    return progress;
  }

  public boundsAtTime(time: number) {
    const progressToRestore = this.timelinePositionAndZoom.progress();
    const progressAtTime = this.getProgress(time);
    this.timelinePositionAndZoom.progress(progressAtTime, false);
    const bounds = new PIXI.Rectangle(
      this.tweeningPositions.x / this.tweeningPositions.zoom,
      this.tweeningPositions.y / this.tweeningPositions.zoom,
      this.screenWidth / this.tweeningPositions.zoom,
      this.screenHeight / this.tweeningPositions.zoom
    );
    this.timelinePositionAndZoom.progress(progressToRestore, true);
    return bounds;
  }

  public initializeCamera({ camera, startTimeMs, endTimeMs }: InitializeCameraProps) {
    const startTimeSeconds = startTimeMs / 1000;
    const endTimeSeconds = endTimeMs / 1000;
    const sceneStartTimeSeconds = this.sceneTimings.startTimeMs / 1000;
    const adjustedStartTimeSeconds = startTimeSeconds - sceneStartTimeSeconds;
    const adjustedEndTimeSeconds = endTimeSeconds - sceneStartTimeSeconds;
    const animationTimeSeconds = camera.animationTime;
    const cameraProps = {
      x: camera.x * camera.scale,
      y: camera.y * camera.scale,
      zoom: camera.scale,
      ease: camera.easingType
    };

    if (animationTimeSeconds || animationTimeSeconds === 0) {
      this.timelinePositionAndZoom.add(
        gsap.to(this.tweeningPositions, {
          x: cameraProps.x,
          y: cameraProps.y,
          zoom: cameraProps.zoom,
          ease: cameraProps.ease,
          duration: animationTimeSeconds
        }),
        adjustedStartTimeSeconds
      );
    }
    if (animationTimeSeconds === 0) {
      this.timelinePositionAndZoom.set(this.tweeningPositions, cameraProps, adjustedStartTimeSeconds);
    }
    this.timelinePositionAndZoom.set(this.tweeningPositions, cameraProps, adjustedEndTimeSeconds);

    this.updatePositions();
  }

  updatePositions() {
    this.setZoom(this.tweeningPositions.zoom, false);
    this.x = -this.tweeningPositions.x;
    this.y = -this.tweeningPositions.y;
    this.updateTransform();
  }

  update(currentTime: number) {
    const progress = this.getProgress(currentTime);
    if (progress !== this.lastProgress) {
      this.timelinePositionAndZoom.progress(progress);
      this.lastProgress = currentTime;
    }
    super.update(currentTime);
  }
}
