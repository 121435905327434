import { useMemo } from 'react';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { ImageCategory, RootState } from 'js/types';
import sortBy from 'lodash.sortby';

const selector = (state: RootState) => ({
  libraries: state.images.libraries,
  loading: state.images.loadingLibraries
});

export function useLibraries(): [boolean, ImageCategory[]] {
  const { libraries, loading } = useTypedSelector(selector);
  const sortedLibrariesByName = useMemo(() => sortBy(libraries, 'editorName'), [libraries]);
  return [loading, sortedLibrariesByName];
}
