import { BitmapFont, Texture, utils, XMLStringFormat } from 'pixi.js';

import { BITMAP_TEXT_SCALING_FACTOR } from '../../../../config/defaults';

/*
  This is a copy of the install function from pixi.js: https://github.com/pixijs/pixijs/blob/v5.3.x/packages/text-bitmap/src/BitmapFont.ts#L269
  it would call XMLStringFormat.parse(data), which would truncate decimal for xoffset, yoffset, xadvance, and size
  so the BitmapFont would be created using only integer values, leading to offset when rendering text
  this updated version allows us to keep the decimal values when we create the BitmapFont
*/
export default function installBitmapFont(
  data: string,
  textures: Texture | Texture[] | utils.Dict<Texture>,
  ownsTextures?: boolean
): BitmapFont {
  const fontData = XMLStringFormat.parse(data);
  if (textures instanceof Texture) {
    textures = [textures];
  }
  fontData.char = fontData.char.map(char => ({
    ...char,
    xoffset: char.xoffset / Math.pow(BITMAP_TEXT_SCALING_FACTOR, 2),
    yoffset: char.yoffset / Math.pow(BITMAP_TEXT_SCALING_FACTOR, 2),
    xadvance: char.xadvance / Math.pow(BITMAP_TEXT_SCALING_FACTOR, 2)
  }));
  fontData.info[0].size /= BITMAP_TEXT_SCALING_FACTOR;
  const font = new BitmapFont(fontData, textures, ownsTextures);
  BitmapFont.available[font.font] = font;
  return font;
}
