import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { VSLibraryImage, PremiumSearchImage, ImageLibrarySearch, ImageLibraryCategory, ImageSource } from 'js/types';
import { getCategoryImages, getSearchImages } from 'js/actionCreators/ImageDrawerActions';
import { trackImageSearch } from 'js/actionCreators/trackingActions';
import { SearchType } from 'js/reducers/imageSearchReducer';

import useGetSearchResult from './useGetSearchResult';

type ExistingTabResult =
  | ImageLibrarySearch<VSLibraryImage>
  | ImageLibrarySearch<PremiumSearchImage>
  | ImageLibraryCategory;

const useLoadImages = (
  searchType: SearchType,
  searchInput: string,
  categoryId: string | null,
  selectedTab: ImageSource
) => {
  const dispatch = useDispatch();
  const { scribeId } = useParams<{ scribeId: string }>();

  const { getExistingTabResult, isTabLoading } = useGetSearchResult(searchType, searchInput, categoryId);

  const isSearchData = (
    data: ExistingTabResult
  ): data is ImageLibrarySearch<VSLibraryImage> | ImageLibrarySearch<PremiumSearchImage> => {
    return !data || 'offset' in data;
  };

  const fetchImages = useCallback(
    (tab: ImageSource, initFetch = false, isPrefetch = false) => {
      if (isTabLoading(tab)) return;

      const selectedData = getExistingTabResult(tab);
      if (searchType === SearchType.INPUT && isSearchData(selectedData)) {
        const { total, offset } = selectedData ?? {};
        if (total !== undefined && offset !== undefined && offset >= total) return;

        dispatch(getSearchImages(tab, searchInput, selectedData?.offset ?? 0, selectedData?.nextPageId, isPrefetch));
        const tabName = tab === 'videoscribe' ? 'VideoScribe' : 'Noun Project';
        if (initFetch && !isPrefetch) dispatch(trackImageSearch(searchInput, parseInt(scribeId), tabName));
      } else if (searchType === SearchType.CATEGORY && categoryId) {
        if (selectedData?.nextPageId === null) return;
        dispatch(getCategoryImages(categoryId, tab, selectedData?.nextPageId ?? '0'));
      }
    },
    [categoryId, dispatch, getExistingTabResult, searchInput, searchType, isTabLoading, scribeId]
  );

  const loadImages = useCallback(
    (initFetch = false) => {
      const nonSelectedTab = selectedTab === 'videoscribe' ? 'noun-project' : 'videoscribe';
      fetchImages(selectedTab, initFetch, false);
      if (initFetch) fetchImages(nonSelectedTab, initFetch, true);
    },
    [selectedTab, fetchImages]
  );

  return loadImages;
};

export default useLoadImages;
