export class UploadingAssetProvider {
  constructor() {
    this.assets = {};
  }

  save = (id, url) => {
    this.assets[id] = url;
  };

  getUrlById = id => {
    return this.assets[id];
  };
}
const uploadingAssetProvider = new UploadingAssetProvider();
export default uploadingAssetProvider;
