export const pauseTime = 0.5;
export const emphasisAnimationTime = 3.5;
export const exitAnimationTime = 2.5;
export const animationTime = 2.5;
export const emphasisAnimationLoops = 1;
export const fallbackFontValue = 'Noto Sans';
export const defaultFontValue = 'Open Sans';
export const defaultFontLabel = 'Open Sans';
export const minScaleValue = 1;
export const minScaleValueAsPercentage = minScaleValue / 100;
export const elementSelectionPadding = 0;
export const canvasSizeDefault = 'landscape';
export const textCharacterAnimationTime = 0.2;
export const defaultShakeTiming = 3;
export const defaultShakeLoops = 10;
export const defaultBounceEmphasisTiming = 3;
export const defaultBounceEmphasisLoops = 3;
