import classNames from 'classnames';
import React from 'react';

import './EditPanelHeading.css';

interface Props {
  Icon?: ({ className }: { className?: string }) => JSX.Element;
  children?: React.ReactNode;
  control?: () => React.ReactNode;
  id?: string;
}

const EditPanelHeading = ({ Icon, children, control, id }: Props) => {
  const cssClassnames = classNames('EditPanelHeading', {
    'EditPanelHeading--withControl': !!control,
    'EditPanelHeading--withIcon': !!Icon
  });

  return (
    <header className={cssClassnames}>
      {!!Icon && (
        <span className="EditPanelHeading__icon">
          <Icon className="heading-icon-medium" />
        </span>
      )}
      <h2 id={id} className="EditPanelHeading__text">
        {children}
      </h2>
      {control?.()}
    </header>
  );
};

export default EditPanelHeading;
