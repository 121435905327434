import './ImageThumbnail.css';

import React, { DragEvent, useEffect } from 'react';
import { IconPremiumCrown } from '@sparkol/videoscribe-react-ui';
import ReactTooltip from 'react-tooltip';
import { APP_TOOLTIP_ID } from 'js/config/consts';
import useOnClickOutside from 'js/shared/hooks/useOnClickOutside';
import { ReactComponent as IconDelete } from 'imgs/svg-icons/IconDelete.svg';
import { ReactComponent as IconClose } from 'imgs/svg-icons/IconCircleClose.svg';
import { ReactComponent as IconConfirm } from 'imgs/svg-icons/IconCircleTick.svg';

import colorChangeIcon from './recolor.svg';

type ImageThumbnailProps = {
  id: string | number;
  src: string;
  premiumTooltip?: string;
  canChangeColor?: boolean;
  onClickImage?: () => void;
  onDragStart?: (e: DragEvent<HTMLButtonElement>) => void;
  onPremiumIconClick?: () => void;
  onConfirmDeleteClick?: (id: string | number) => void;
};

const ImageThumbnail = React.forwardRef<HTMLLIElement, ImageThumbnailProps>(
  (
    {
      src,
      onClickImage,
      onDragStart,
      premiumTooltip,
      onPremiumIconClick,
      onConfirmDeleteClick,
      canChangeColor = false,
      id
    },
    ref
  ) => {
    const [isDeleting, setIsDeleting] = React.useState(false);
    const deleteModalRef = React.useRef<HTMLDivElement>(null);

    useOnClickOutside(deleteModalRef, () => setIsDeleting(false));

    const handleConfirmDeleteClick = () => {
      setIsDeleting(false);
      onConfirmDeleteClick?.(id);
    };

    useEffect(() => {
      if (isDeleting) {
        deleteModalRef.current?.focus();
        ReactTooltip.rebuild();

        const handleEsc = (e: KeyboardEvent) => {
          if (e.key === 'Escape') {
            setIsDeleting(false);
          }
        };

        document.addEventListener('keydown', handleEsc);

        return () => {
          document.removeEventListener('keydown', handleEsc);
        };
      }
    }, [isDeleting]);

    return (
      <li className="ImageThumbnail" ref={ref}>
        <button onClick={onClickImage} draggable onDragStart={onDragStart}>
          <img className="ImageThumbnail__image" src={src} alt="" draggable={false} />
        </button>

        {canChangeColor && (
          <div
            className="ImageThumbnail__colorChange"
            data-tip="The color of this image can be changed in the editor"
            data-for={APP_TOOLTIP_ID}
          >
            <img src={colorChangeIcon} alt="" />
          </div>
        )}

        {!!premiumTooltip && (
          <button className="ImageThumbnail__premium" onClick={onPremiumIconClick}>
            <span data-tip={premiumTooltip} data-for={APP_TOOLTIP_ID}>
              <IconPremiumCrown />
            </span>
          </button>
        )}

        {!!onConfirmDeleteClick && (
          <button className="ImageThumbnail__delete" onClick={() => setIsDeleting(true)}>
            <IconDelete />
          </button>
        )}

        {isDeleting && (
          <div className="ImageThumbnail__deleteConfirm" ref={deleteModalRef}>
            <p>Delete image?</p>
            <div className="ImageThumbnail__deleteButtons">
              <button className="ImageThumbnail__circleButton" onClick={() => setIsDeleting(false)}>
                <span className="sr-only">Cancel delete</span>
                <IconClose />
              </button>
              <button
                className="ImageThumbnail__circleButton ImageThumbnail__deleteButtons--destructive"
                data-tip="This action cannot be undone"
                data-for={APP_TOOLTIP_ID}
                data-place="bottom"
                onClick={handleConfirmDeleteClick}
              >
                <span className="sr-only">Confirm delete</span>
                <IconConfirm />
              </button>
            </div>
          </div>
        )}
      </li>
    );
  }
);

export default ImageThumbnail;
