import {
  BackgroundGradientType,
  DirectionType,
  FontAttributes,
  TextAlignValue,
  TextFontStyle,
  TextFontWeight,
  UpdateElementProperty,
  TextElementCharBounds
} from 'js/types';

export const SET_ELEMENT_OPACITY = 'SET_ELEMENT_OPACITY';
export const SET_ELEMENT_FILL = 'SET_ELEMENT_FILL';
export const SET_ELEMENT_COLOR = 'SET_ELEMENT_COLOR';
export const SET_ELEMENT_GRADIENT_TYPE = 'SET_ELEMENT_GRADIENT_TYPE';
export const SET_ELEMENT_GRADIENT_COLOR = 'SET_ELEMENT_GRADIENT_COLOR';
export const SET_ELEMENT_UNLOCKED_RATIO = 'SET_ELEMENT_UNLOCKED_RATIO';
export const SET_ELEMENT_FLIP_X = 'SET_ELEMENT_FLIP_X';
export const SET_ELEMENT_FLIP_Y = 'SET_ELEMENT_FLIP_Y';
export const SET_ELEMENT_TEXT_ALIGN = 'SET_ELEMENT_TEXT_ALIGN';
export const SET_ELEMENT_TEXT_BOLD = 'SET_ELEMENT_TEXT_BOLD';
export const SET_ELEMENT_TEXT_ITALIC = 'SET_ELEMENT_TEXT_ITALIC';
export const SET_ELEMENT_TEXT_FONT = 'SET_ELEMENT_TEXT_FONT';
export const SET_ELEMENT_TEXT_SIZE = 'SET_ELEMENT_TEXT_SIZE';

export const SET_ELEMENT_IMAGE_RECOLOR = 'SET_ELEMENT_IMAGE_RECOLOR';
export const CLEAR_ELEMENT_IMAGE_RECOLOR = 'CLEAR_ELEMENT_IMAGE_RECOLOR';

export const SET_ELEMENT_TWEEN = 'SET_ELEMENT_TWEEN';
export const SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX = 'SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX';

export const UPDATE_TEXT_FIELD_DIMENSIONS = 'UPDATE_TEXT_FIELD_DIMENSIONS';

export interface SetElementOpacityAction {
  type: typeof SET_ELEMENT_OPACITY;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementPropertyActionPayloadBase = {
  scribeId: number;
  elementIds: Array<string>;
};

export type SetElementOpacityProperty = SetElementPropertyActionPayloadBase & {
  value: number;
};

export const setElementOpacity = ({
  scribeId,
  elementIds,
  value
}: SetElementOpacityProperty): SetElementOpacityAction => ({
  type: SET_ELEMENT_OPACITY,
  scribeId,
  elementIds,
  update: {
    opacity: value
  }
});

export interface UpdatedTextElementConfig {
  elementId: string;
  width: number;
  height: number;
  charBounds: TextElementCharBounds;
  lineWidths: Array<number>;
}

export interface UpdateTextFieldDimensionsAction {
  type: typeof UPDATE_TEXT_FIELD_DIMENSIONS;
  scribeId: number;
  updatedElementsConfig: Array<UpdatedTextElementConfig>;
}

export const updateTextFieldDimensions = (
  scribeId: number,
  updatedElementsConfig: Array<UpdatedTextElementConfig>
): UpdateTextFieldDimensionsAction => ({
  type: UPDATE_TEXT_FIELD_DIMENSIONS,
  scribeId,
  updatedElementsConfig
});

export interface SetElementFillAction {
  type: typeof SET_ELEMENT_FILL;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementFillProperty = SetElementPropertyActionPayloadBase & {
  value: string;
};

export const setElementFill = ({ scribeId, elementIds, value }: SetElementFillProperty): SetElementFillAction => ({
  type: SET_ELEMENT_FILL,
  scribeId,
  elementIds,
  update: {
    fill: value,
    backgroundType: 'solid'
  }
});

export interface SetElementGradientAction {
  type: typeof SET_ELEMENT_GRADIENT_TYPE;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementGradientProperty = SetElementPropertyActionPayloadBase & {
  value: DirectionType;
};

export const setElementGradientType = ({
  scribeId,
  elementIds,
  value
}: SetElementGradientProperty): SetElementGradientAction => ({
  type: SET_ELEMENT_GRADIENT_TYPE,
  scribeId,
  elementIds,
  update: {
    backgroundGradientType: value
  }
});

export interface SetElementGradientColorAction {
  type: typeof SET_ELEMENT_GRADIENT_COLOR;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementGradientColorProperty = SetElementPropertyActionPayloadBase & {
  value: BackgroundGradientType;
  position: string;
};

export const setElementGradientColor = ({
  scribeId,
  elementIds,
  value,
  position = 'from'
}: SetElementGradientColorProperty): SetElementGradientColorAction => ({
  type: SET_ELEMENT_GRADIENT_COLOR,
  scribeId,
  elementIds,
  update: {
    backgroundType: 'gradient',
    [position === 'from' ? 'backgroundColorFrom' : 'backgroundColorTo']: value
  }
});

export interface SetElementUnlockedRatioAction {
  type: typeof SET_ELEMENT_UNLOCKED_RATIO;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementUnlockedRatioProperty = SetElementPropertyActionPayloadBase & {
  value: boolean;
};

export const setElementUnlockedRatio = ({
  scribeId,
  elementIds,
  value
}: SetElementUnlockedRatioProperty): SetElementUnlockedRatioAction => ({
  type: SET_ELEMENT_UNLOCKED_RATIO,
  scribeId,
  elementIds,
  update: {
    unlockedRatio: value
  }
});

export interface SetElementFlipXAction {
  type: typeof SET_ELEMENT_FLIP_X;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementFlipXProperty = SetElementPropertyActionPayloadBase & {
  value: boolean;
};

export const setElementFlipX = ({ scribeId, elementIds, value }: SetElementFlipXProperty): SetElementFlipXAction => ({
  type: SET_ELEMENT_FLIP_X,
  scribeId,
  elementIds,
  update: {
    flipX: value
  }
});

export interface SetElementFlipYAction {
  type: typeof SET_ELEMENT_FLIP_Y;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementFlipYProperty = SetElementPropertyActionPayloadBase & {
  value: boolean;
};

export const setElementFlipY = ({ scribeId, elementIds, value }: SetElementFlipYProperty): SetElementFlipYAction => ({
  type: SET_ELEMENT_FLIP_Y,
  scribeId,
  elementIds,
  update: {
    flipY: value
  }
});

export interface SetElementTextAlignAction {
  type: typeof SET_ELEMENT_TEXT_ALIGN;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementTextAlignProperty = SetElementPropertyActionPayloadBase & {
  value: TextAlignValue;
};

export const setElementTextAlign = ({
  scribeId,
  elementIds,
  value
}: SetElementTextAlignProperty): SetElementTextAlignAction => ({
  type: SET_ELEMENT_TEXT_ALIGN,
  scribeId,
  elementIds,
  update: {
    align: value
  }
});

export interface SetElementTextBoldAction {
  type: typeof SET_ELEMENT_TEXT_BOLD;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementTextBoldProperty = SetElementPropertyActionPayloadBase & {
  value: TextFontWeight;
};

export const setElementTextBold = ({
  scribeId,
  elementIds,
  value
}: SetElementTextBoldProperty): SetElementTextBoldAction => ({
  type: SET_ELEMENT_TEXT_BOLD,
  scribeId,
  elementIds,
  update: {
    fontWeight: value
  }
});

export interface SetElementTextItalicAction {
  type: typeof SET_ELEMENT_TEXT_ITALIC;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementTextItalicProperty = SetElementPropertyActionPayloadBase & {
  value: TextFontStyle;
};

export const setElementTextItalic = ({
  scribeId,
  elementIds,
  value
}: SetElementTextItalicProperty): SetElementTextItalicAction => ({
  type: SET_ELEMENT_TEXT_ITALIC,
  scribeId,
  elementIds,
  update: {
    fontStyle: value
  }
});

export interface SetElementTextFontAction {
  type: typeof SET_ELEMENT_TEXT_FONT;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
}

export type SetElementTextFontProperty = SetElementPropertyActionPayloadBase & {
  value: FontAttributes;
};

export const setElementTextFont = ({
  scribeId,
  elementIds,
  value
}: SetElementTextFontProperty): SetElementTextFontAction => ({
  type: SET_ELEMENT_TEXT_FONT,
  scribeId,
  elementIds,
  update: {
    font: value
  }
});

export interface SetElementImageRecolorAction {
  type: typeof SET_ELEMENT_IMAGE_RECOLOR;
  scribeId: number;
  elementId: string;
  value: string;
  layer: string;
}

export type SetElementImageRecolorProperties = Omit<SetElementImageRecolorAction, 'type'>;

export const setElementImageRecolor = ({
  scribeId,
  elementId,
  layer,
  value
}: SetElementImageRecolorProperties): SetElementImageRecolorAction => ({
  type: SET_ELEMENT_IMAGE_RECOLOR,
  scribeId,
  elementId,
  layer,
  value
});

export interface ClearElementImageRecolorAction {
  type: typeof CLEAR_ELEMENT_IMAGE_RECOLOR;
  scribeId: number;
  elementId: string;
}

export type ClearElementImageRecolorProperties = Omit<ClearElementImageRecolorAction, 'type'>;

export const clearElementImageRecolor = ({
  scribeId,
  elementId
}: ClearElementImageRecolorProperties): ClearElementImageRecolorAction => ({
  type: CLEAR_ELEMENT_IMAGE_RECOLOR,
  scribeId,
  elementId
});

export interface SetElementUseContentSvgViewboxAction {
  type: typeof SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX;
  scribeId: number;
  elementId: string;
  useContentSvgViewbox: boolean;
}

export type SetElementUseContentSvgViewboxProperties = Omit<SetElementUseContentSvgViewboxAction, 'type'>;

export const setElementUseContentSvgViewbox = ({
  scribeId,
  elementId,
  useContentSvgViewbox
}: SetElementUseContentSvgViewboxProperties): SetElementUseContentSvgViewboxAction => ({
  type: SET_ELEMENT_USE_CONTENT_SVG_VIEWBOX,
  scribeId,
  elementId,
  useContentSvgViewbox
});

export interface SetElementTextSizeAction {
  type: typeof SET_ELEMENT_TEXT_SIZE;
  scribeId: number;
  elementIds: Array<string>;
  update: UpdateElementProperty;
  incrementValue?: boolean;
  decrementValue?: boolean;
}

export type SetElementTextSizeProperty = SetElementPropertyActionPayloadBase & {
  value: number | string;
  incrementValue?: boolean;
  decrementValue?: boolean;
};

export const setElementTextSize = ({
  scribeId,
  elementIds,
  value,
  incrementValue,
  decrementValue
}: SetElementTextSizeProperty): SetElementTextSizeAction => ({
  type: SET_ELEMENT_TEXT_SIZE,
  scribeId,
  elementIds,
  update: {
    scaleX: 1,
    scaleY: 1,
    fontSize: value
  },
  incrementValue,
  decrementValue
});
