import { InputBase, InputBaseComponentProps } from '@material-ui/core';
import { ReactComponent as IconSearch } from 'imgs/svg-icons/IconSearch.svg';
import { ReactComponent as IconClose } from 'imgs/svg-icons/IconClose.svg';
import React from 'react';
import './SearchBar.css';
const SearchBar: React.FC<{
  value: string;
  placeholder: string;
  onChange: (searchInput: string) => void;
  clearSearchInput?: () => void;
  searchIconLeftOrRight?: 'left' | 'right';
  clearIconLeftOrRight?: 'left' | 'right';
  inputProps?: InputBaseComponentProps;
  onSubmit: () => void;
}> = ({
  value,
  placeholder,
  onChange,
  clearSearchInput,
  searchIconLeftOrRight = 'left',
  clearIconLeftOrRight = 'right',
  inputProps = {},
  onSubmit
}) => {
  const handleClearSearchInput = () => {
    clearSearchInput?.();
  };

  const SearchButton = () => {
    return (
      <button className="SearchBar__searchButtonIcon" aria-label="search" type="submit">
        <IconSearch />
      </button>
    );
  };

  const ClearButton = () => {
    return (
      <button className="SearchBar__clearButtonIcon" aria-label="clear" onClick={handleClearSearchInput}>
        <IconClose />
      </button>
    );
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit?.();
  };

  return (
    <form className="SearchBar__search" onSubmit={handleSubmit}>
      {searchIconLeftOrRight === 'left' && <SearchButton />}
      {value && clearIconLeftOrRight === 'left' && <ClearButton />}
      <InputBase
        className="SearchBar__input"
        placeholder={placeholder}
        inputProps={inputProps}
        onChange={event => onChange(event.target.value)}
        value={value}
      />
      {searchIconLeftOrRight === 'right' && <SearchButton />}
      {value && clearIconLeftOrRight === 'right' && <ClearButton />}
    </form>
  );
};

export default SearchBar;
