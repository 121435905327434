import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { ExistingScribeModel, VSElementModel } from 'js/types';

const isImageElement = (element: VSElementModel): element is ScribeImageElementModel => {
  return element.type === 'Image';
};

// Check if the project is ready for rendering (no temp/lib image ids)
export default function projectIsStillUploadingAssets(project: ExistingScribeModel) {
  const { elements } = project;

  const isUploading = elements.filter(isImageElement).some(element => typeof element.image?.assetId !== 'number');

  return isUploading;
}
