import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { loadScribes, loadCursors } from 'js/actionCreators/scribeActions';
import { getImageLibraries } from 'js/actionCreators/imagesActions';
import { checkUpgrade } from 'js/actionCreators/checkoutActions';

import { mountSleekNote, closeSleekNote } from './sagaHelpers/sleekNote';
import { getUpgradedSubscriptionTier, hasUpgradedOnWebsite } from './sagaHelpers/websiteUpgrade';

export function* authStartup({ user, revalidation }) {
  if (user.accountType !== 'FREE') {
    yield call(closeSleekNote);
  }

  const hasUpgraded = hasUpgradedOnWebsite(user.id);
  if (hasUpgraded) {
    const newTier = getUpgradedSubscriptionTier(user.id);
    yield put(checkUpgrade({ userId: user.id, newTier }));
  }

  if (revalidation && !hasUpgraded) return;

  yield put(loadScribes());
  yield put(getImageLibraries());
  yield put(loadCursors());

  if (user.accountType === 'FREE') {
    yield call(mountSleekNote);
  }
}

export default function* appStartupSagas() {
  yield takeEvery(AUTH_SUCCESS, authStartup);
}
