import { rateLimitedfetch } from 'js/shared/helpers/rateLimitedFetch';
import { getProviderForType } from 'js/shared/providers';

export default async function getImageFileFromProvider(provider, imageId) {
  const providerFn = getProviderForType(provider);
  const url = await providerFn(imageId);
  const resp = await rateLimitedfetch(url);
  const originalFile = await resp.blob();
  return originalFile;
}
