import { gsap, Bounce } from 'gsap';
import { SCENE_DIRECTION_KEYS } from 'js/config/sceneTransitionsOptions';
import { SceneTransitionConfig } from 'js/types';

import VSSceneTransitionBase, { VSSceneTransitionBaseProps } from './VSSceneTransitionBase';

export default class VSBounceTransition extends VSSceneTransitionBase {
  tween2CurrIn: GSAPTween;
  config?: SceneTransitionConfig;

  constructor(props: VSSceneTransitionBaseProps) {
    super({ ...props });
    this.config = props.config;

    this.scene1Out = 1;
    this.scene2In = 1;
    this.tween2CurrIn = this.setupIntroTransition();
  }

  setupIntroTransition() {
    let tweenConfig;
    switch (this.config?.direction) {
      case SCENE_DIRECTION_KEYS.DOWN: {
        tweenConfig = {
          y: -this.viewportDimensions.height,
          duration: 1,
          ease: Bounce.easeOut
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.LEFT: {
        tweenConfig = {
          x: this.viewportDimensions.width,
          duration: 1,
          ease: Bounce.easeOut
        };
        break;
      }
      case SCENE_DIRECTION_KEYS.RIGHT: {
        tweenConfig = {
          x: -this.viewportDimensions.width,
          duration: 1,
          ease: Bounce.easeOut
        };
        break;
      }

      case SCENE_DIRECTION_KEYS.UP:
      default: {
        tweenConfig = {
          y: this.viewportDimensions.height,
          duration: 1,
          ease: Bounce.easeOut
        };
      }
    }

    const tween = gsap.from(this.currentScene.viewportContainer, tweenConfig);
    tween.pause();

    return tween;
  }

  updateIntroProgress(progress: number) {
    if (this.tween2CurrIn) {
      this.tween2CurrIn.progress(progress);
    }
  }
}
