import React from 'react';

import EditPanelHeading from '../EditPanelHeading';

import './AnimationOptionMenu.css';

interface Props {
  title?: string;
  children: React.ReactNode;
}

const AnimationOptionMenu = React.forwardRef<HTMLDivElement, Props>(({ title, children }, ref) => (
  <div className="AnimationOptionMenu" id="animation-options-menu" ref={ref}>
    <EditPanelHeading>{title}</EditPanelHeading>
    <div>{children}</div>
  </div>
));

export default AnimationOptionMenu;
