import './UploadImageButton.css';

import { ALLOWED_IMAGE_TYPES, canvasSizes } from 'js/config/defaults';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { replaceUploadedImageElement, uploadImageFiles } from 'js/actionCreators/imagesActions';
import { trackImageImportClick } from 'js/actionCreators/trackingActions';
import useReplaceImage from 'js/shared/hooks/useReplaceImage';
import { useProject } from 'js/shared/hooks/useProject';

import FileUpload from '../ImageLibrary/FileUpload';

const UploadImageButton = () => {
  const dispatch = useDispatch();
  const { scribeId } = useParams<{ scribeId: string }>();
  const project = useProject();
  const { isInReplaceImageMode, replacingImageElementId } = useReplaceImage();

  const handleFileUpload = (files: FileList) => {
    if (isInReplaceImageMode && replacingImageElementId) {
      const file = files.item(0);
      if (!file || !project) return;
      const canvasSize = canvasSizes[project.canvasSize];

      dispatch(
        replaceUploadedImageElement({
          file,
          scribeId: project.id,
          canvasSize,
          filename: file.name,
          elementId: replacingImageElementId,
          imageType: file.type
        })
      );
    } else {
      dispatch(uploadImageFiles({ files, scribeId: parseInt(scribeId), uploadMethod: 'Upload Button' }));
    }
  };

  const handleFileInputClick = () => dispatch(trackImageImportClick(parseInt(scribeId)));

  return (
    <div className="UploadImageButton__container">
      <FileUpload
        onFileUpload={handleFileUpload}
        onFileInputClick={handleFileInputClick}
        allowMimeType={ALLOWED_IMAGE_TYPES.join(',')}
        allowMultiple={!isInReplaceImageMode}
        id="image-file-upload"
      >
        Upload<span className="UploadImageButton--hidden"> image</span>
      </FileUpload>
    </div>
  );
};

export default UploadImageButton;
