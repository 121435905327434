import Bottleneck from 'bottleneck';

import { logOutAndReload } from './logOutAndReload';

export const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 333 // limit to 3 requests per second
});

const limitedFetch = limiter.wrap(window.fetch);

export async function rateLimitedfetch(url, options) {
  return limitedFetch(url, options).then(response => {
    if (response.status === 401) {
      setTimeout(logOutAndReload, 0);
    }
    return response;
  });
}
