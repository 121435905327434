import {
  GET_USER_AUDIO_FAILURE,
  GET_USER_AUDIO_REQUESTED,
  GET_USER_AUDIO_SUCCESS,
  GetUserAudioFailureAction,
  GetUserAudioSuccessAction,
  SAVE_AUDIO_ASSET_METADATA_SUCCESS,
  SAVE_AUDIO_TO_LIBRARY_SUCCESS,
  SaveAudioAssetMetadataSuccessAction,
  SaveAudioToLibrarySuccessAction,
  OpenAudioModalAction,
  CloseAudioModalAction,
  OPEN_AUDIO_MODAL,
  CLOSE_AUDIO_MODAL,
  OpenDeleteAudioAssetModalAction,
  CloseDeleteAudioAssetModalAction,
  OPEN_DELETE_AUDIO_ASSET_MODAL,
  CLOSE_DELETE_AUDIO_ASSET_MODAL,
  UseAudioAssetInProjectSuccessAction,
  USE_AUDIO_ASSET_IN_PROJECT_SUCCESS,
  ConfirmAudioSelectionAction,
  CONFIRM_AUDIO_SELECTION
} from 'js/actionCreators/audioActions';
import { AudioState } from 'js/types';
import cloneDeep from 'lodash.clonedeep';

type AudioActionTypes =
  | GetUserAudioFailureAction
  | GetUserAudioSuccessAction
  | SaveAudioToLibrarySuccessAction
  | SaveAudioAssetMetadataSuccessAction
  | OpenAudioModalAction
  | CloseAudioModalAction
  | OpenDeleteAudioAssetModalAction
  | CloseDeleteAudioAssetModalAction
  | UseAudioAssetInProjectSuccessAction
  | ConfirmAudioSelectionAction
  | { type: typeof GET_USER_AUDIO_REQUESTED };

export const initialState: AudioState = {
  userAudioLoading: false,
  userAudioAssets: [],
  audioModalTab: null,
  audioModalInitialAction: null,
  deleteAudioAssetModalOpen: false,
  deleteAudioAssetId: null,
  selectedAudioClip: null
};

export default function audioReducer(state = initialState, action: AudioActionTypes): AudioState {
  switch (action.type) {
    case GET_USER_AUDIO_REQUESTED: {
      return {
        ...state,
        userAudioLoading: true
      };
    }

    case GET_USER_AUDIO_FAILURE: {
      return {
        ...state,
        userAudioLoading: false
      };
    }

    case GET_USER_AUDIO_SUCCESS: {
      return {
        ...state,
        userAudioLoading: false,
        userAudioAssets: action.payload
      };
    }

    case SAVE_AUDIO_TO_LIBRARY_SUCCESS: {
      return {
        ...state,
        userAudioAssets: [...state.userAudioAssets, action.audioClipData]
      };
    }

    case SAVE_AUDIO_ASSET_METADATA_SUCCESS: {
      const userAudioAssets = cloneDeep(state.userAudioAssets);
      const updateIndex = userAudioAssets.findIndex(
        asset => asset.projectAssetId === action.audioClipData.projectAssetId
      );

      if (updateIndex !== -1) {
        userAudioAssets[updateIndex] = action.audioClipData;
        return {
          ...state,
          userAudioAssets
        };
      }

      return state;
    }

    case OPEN_AUDIO_MODAL: {
      return {
        ...state,
        audioModalTab: action.tab,
        audioModalInitialAction: action.initialAction,
        ...(action.selectedAudioClip && { selectedAudioClip: action.selectedAudioClip })
      };
    }

    case CONFIRM_AUDIO_SELECTION:
    case USE_AUDIO_ASSET_IN_PROJECT_SUCCESS:
    case CLOSE_AUDIO_MODAL: {
      return {
        ...state,
        audioModalTab: null,
        selectedAudioClip: null
      };
    }

    case OPEN_DELETE_AUDIO_ASSET_MODAL: {
      return {
        ...state,
        deleteAudioAssetModalOpen: true,
        deleteAudioAssetId: action.assetId
      };
    }

    case CLOSE_DELETE_AUDIO_ASSET_MODAL: {
      return {
        ...state,
        deleteAudioAssetModalOpen: false,
        deleteAudioAssetId: null
      };
    }

    default:
      return state;
  }
}
