import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import drawEllipse from 'js/playback/lib/Playback/helpers/drawEllipse';
import { addGradient } from 'js/playback/lib/Playback/helpers/pixiGradient';
import getFontFamilyWithFallbackString from 'js/shared/helpers/getFontFamilyWithFallbackString';
import { hexColorToPixiColor } from 'js/shared/helpers/pixi';
import {
  ScribeBackgroundSettings,
  ScribeScene,
  ScribeSettings,
  PlaybackImageResources,
  VSImageShapeOrTextElementModel
} from 'js/types';
import * as PIXI from 'pixi.js';
import { FILE_CONTENT_TYPES } from 'js/config/consts';
import { constructSVGAssetKey } from 'js/shared/lib/LocalDatabase/keys';

import { getTextElementFirstCharColor } from '../../EditPanels/ColorPanelHelper';

import TimelineLayerTooltip, { TOOLTIP_ALIGN_RIGHT } from './TimelineLayerTooltip';
import timelineBus, { LAYER_CLICKED, TOOLTIP_HIDE, TOOLTIP_SHOW } from './TimelineControlBus';
import TimelineWrapper from './TimelineWrapper';
import { THUMBNAIL_WIDTH } from './consts';
import ThumbnailLockedIcon from './ThumbnailLockedIcon';
import Maths from './Utils/Maths';
import WarningTriangle from './WarningTriangle';

type PropsType = {
  element: VSImageShapeOrTextElementModel;
  zoomValue: PIXI.Point;
  wrapperRef: TimelineWrapper;
  backgroundSettings: ScribeBackgroundSettings;
  imageResources: PlaybackImageResources;
};

const TEXT_THUMB_PADDING = 30;
export const LAYER_GAP = 6;

export default class TimelineLayerThumbnail extends PIXI.Container {
  imageSprite?: PIXI.Sprite;
  shapeGraphic?: PIXI.Graphics;
  textField?: PIXI.Text;
  baseTextFieldHeight = 0;
  background: PIXI.Graphics;
  padding: number;
  highlight: PIXI.Graphics;
  wrapperRef: TimelineWrapper;
  tooltip?: TimelineLayerTooltip;
  textElement?: ScribeTextElementModel;
  activeScene?: ScribeScene;
  backgroundSettings: ScribeBackgroundSettings;
  imageResources: PlaybackImageResources;
  element: VSImageShapeOrTextElementModel;
  dragTimeoutId = 0;
  padlock?: ThumbnailLockedIcon;
  warningIcon?: WarningTriangle;
  opaqueOverlay?: PIXI.Graphics;
  locked = false;
  hidden = false;
  outOfCamera = false;

  constructor({ element, wrapperRef, imageResources, backgroundSettings }: PropsType) {
    super();
    this.wrapperRef = wrapperRef;
    this.backgroundSettings = backgroundSettings;
    this.imageResources = imageResources;
    this.element = element;

    this.background = new PIXI.Graphics();
    this.background.name = 'Background';
    this.highlight = new PIXI.Graphics();
    this.highlight.name = 'Highlight';
    this.updateBackground(new PIXI.Point(10, 18));
    this.padding = 2;
    this.addChild(this.background);
    this.updateGraphic(element);
    this.updateZoom();
    this.on('click', event => {
      this.emit(LAYER_CLICKED, this.element.id, event);
    });
    this.locked = this.element.locked;
    this.outOfCamera = this.element.outOfCamera;
    if (this.element.locked) this.lock();
    if (this.element.hidden) this.hide();
    if (this.element.outOfCamera) this.showWarning();
  }
  get currentZoom() {
    return this.wrapperRef.currentZoom;
  }
  public hide() {
    this.hidden = true;
    if (this.opaqueOverlay && this.opaqueOverlay !== undefined) this.opaqueOverlay.clear();
    this.opaqueOverlay = new PIXI.Graphics();
    this.opaqueOverlay.beginFill(0x000000, 0.5);
    this.opaqueOverlay.drawRect(0, 1, this.background.width, this.background.height + 1);
    this.addChild(this.opaqueOverlay);
  }

  public show() {
    this.hidden = false;
    if (this.opaqueOverlay && this.opaqueOverlay !== undefined) this.opaqueOverlay.clear();
  }

  public lock() {
    this.locked = true;
    if (this.padlock === undefined) this.padlock = new ThumbnailLockedIcon();
    this.padlock.visible = true;
    this.addChild(this.padlock);
    if (this.outOfCamera && this.padlock && this.warningIcon) {
      this.warningIcon.x = this.padlock.width + 2;
    }
  }

  public unlock() {
    this.locked = false;
    if (this.padlock !== undefined) this.padlock.visible = false;
    if (this.outOfCamera && this.padlock && this.warningIcon) this.warningIcon.x -= this.padlock?.width + 2;
  }

  public showWarning() {
    this.outOfCamera = true;
    if (this.warningIcon === undefined) this.warningIcon = new WarningTriangle();

    this.warningIcon.x = this.padlock?.visible ? this.padlock.width + 2 : 2;
    this.warningIcon.visible = true;

    this.warningIcon.on('pointerover', this.rolloverWarningIcon);
    this.warningIcon.on('pointerout', this.rolloffIcon);
    this.addChild(this.warningIcon);
  }

  rolloverWarningIcon = (event: PIXI.InteractionEvent) => {
    if (this.wrapperRef.dragging) return;
    timelineBus.emit(
      TOOLTIP_SHOW,
      event,
      ['This element is not within view of any camera'],
      TOOLTIP_ALIGN_RIGHT,
      this.currentZoom.y
    );
  };

  public hideWarning() {
    this.outOfCamera = false;
    this.warningIcon?.off('pointerover', this.rolloverWarningIcon);
    this.warningIcon?.off('pointerout', this.rolloffIcon);
    if (this.warningIcon !== undefined) this.warningIcon.visible = false;
  }

  private rolloffIcon = () => {
    if (this.wrapperRef.dragging) return;
    timelineBus.emit(TOOLTIP_HIDE);
  };

  public updateBackgroundSettings(settings: ScribeSettings) {
    this.backgroundSettings = settings;
  }

  public updateGraphic(element: VSImageShapeOrTextElementModel) {
    if (element.type === 'Shape') {
      this.createShapeElement(element);
    }
    if (element.type === 'Image') {
      this.createImageElement(element);
    }
    if (element.type === 'Text') {
      this.textElement = element;
      this.createTextElement(element);
    }

    this.addChild(this.highlight);
    if (element.locked) this.lock();
    else if (!element.locked && this.locked) this.unlock();
    if (element.hidden && !this.hidden) this.hide();
    else if (!element.hidden && this.hidden) this.show();
    if (element.outOfCamera) this.showWarning();
    else if (!element.outOfCamera && this.outOfCamera) this.hideWarning();
  }

  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    if (this.imageSprite) {
      this.imageSprite.destroy({
        children: true,
        texture: false,
        baseTexture: false
      });
    }
    if (this.shapeGraphic) {
      this.shapeGraphic.destroy({
        children: true,
        texture: true,
        baseTexture: true
      });
    }
    if (this.textField) {
      this.textField.destroy({ texture: true, baseTexture: true });
    }
    super.destroy(options);
  }

  private updateBackground(zoomVal: PIXI.Point) {
    const { backgroundColor } = this.backgroundSettings;

    this.background.clear();
    const height = zoomVal.y;
    const fillOpt = { color: PIXI.utils.string2hex(backgroundColor) };
    this.background.beginTextureFill(fillOpt);
    this.background.drawRoundedRect(0, 1, THUMBNAIL_WIDTH - LAYER_GAP, height - LAYER_GAP, 3);
    this.background.endFill();
  }

  public updateZoom() {
    const wasCachedAsBitmap = this.cacheAsBitmap;
    this.cacheAsBitmap = false;
    this.updateBackground(this.currentZoom);
    if (this.imageSprite) {
      this.centerGraphics(this.imageSprite);
    }
    if (this.shapeGraphic) {
      this.centerGraphics(this.shapeGraphic);
    }

    if (this.textField) {
      this.fitTextField();
      this.textField.x = (this.background.width + this.padding) / 2 - this.textField.width / 2;
      this.textField.y = (this.background.height + this.padding) / 2 - this.textField.height / 2;
    }

    if (this.hidden) this.hide();
    this.cacheAsBitmap = wasCachedAsBitmap;
  }

  public updateImageResources(imageResources: PlaybackImageResources) {
    this.imageResources = imageResources;
  }

  centerGraphics(thumbnailElement: PIXI.Sprite | PIXI.Graphics) {
    const bgAspect = this.background.width / this.background.height;
    const imageAspect = thumbnailElement.width / thumbnailElement.height;
    if (imageAspect < bgAspect) {
      thumbnailElement.height = this.background.height - this.padding * 2;
      thumbnailElement.scale.x = thumbnailElement.scale.y = Math.abs(thumbnailElement.scale.y);
    } else {
      thumbnailElement.width = this.background.width - this.padding * 2;
      thumbnailElement.scale.y = thumbnailElement.scale.x = Math.abs(thumbnailElement.scale.x);
    }

    thumbnailElement.x = (this.background.width + this.padding) / 2 - thumbnailElement.width / 2 - this.padding;
    thumbnailElement.y = (this.background.height + this.padding) / 2 - thumbnailElement.height / 2;
  }

  createTextElement(element: ScribeTextElementModel) {
    const firstCharColor = getTextElementFirstCharColor(element);
    if (element.type === 'Text') {
      const styleConfig = {
        fontFamily: getFontFamilyWithFallbackString(element.font.value),
        fill: firstCharColor ?? element.fill,
        fontSize: 20,
        align: element.align,
        strokeThickness: 0,
        fontStyle: element.fontStyle,
        fontWeight: element.fontWeight
      };
      if (this.textField) {
        this.textField.destroy();
      }
      this.textField = new PIXI.Text(element.text?.substring(0, 6), styleConfig);
      this.baseTextFieldHeight = this.textField.height;
      this.fitTextField();
      this.addChild(this.textField);
      this.textField.x = (this.background.width - this.textField.width) / 2;
      this.textField.y = (this.background.height - this.textField.height) / 2;
    }
  }

  fitTextField = () => {
    if (!this.textField) return;
    if ((this.textField.width + TEXT_THUMB_PADDING) * this.currentZoom.x > THUMBNAIL_WIDTH) {
      const newScale = THUMBNAIL_WIDTH / (this.textField.width + TEXT_THUMB_PADDING);
      this.textField.scale = new PIXI.Point(newScale, newScale);
    } else {
      //fit to the vertical
      const newScale = (this.background.height - 2) / this.baseTextFieldHeight;
      this.textField.scale.x = this.textField.scale.y = newScale;
    }
  };

  createImageElement = (element: VSImageShapeOrTextElementModel) => {
    if (element.type === 'Image') {
      if (this.imageResources && element._imageUrl) {
        if (this.imageSprite) {
          this.imageSprite.destroy({
            children: true,
            texture: false,
            baseTexture: false
          });
        }

        let texture;
        switch (element.image?.contentType) {
          case FILE_CONTENT_TYPES.GIF:
            texture = this.imageResources.gifResources[element.image.assetId]?.texture ?? PIXI.Texture.EMPTY;
            break;
          case FILE_CONTENT_TYPES.SVG:
            const key = constructSVGAssetKey({
              assetId: element.image.assetId,
              recolorSettings: element.image.recolor,
              useContentSvgViewbox: !!element.useContentSvgViewbox
            });
            texture = this.imageResources.svgResources[key]?.texture ?? PIXI.Texture.EMPTY;
            break;
          case FILE_CONTENT_TYPES.JPG:
          case FILE_CONTENT_TYPES.PNG:
          case FILE_CONTENT_TYPES.BMP:
            texture = this.imageResources.rasterResources[element._imageUrl]?.texture ?? PIXI.Texture.EMPTY;
            break;
          default:
            texture = PIXI.Texture.EMPTY;
            break;
        }

        this.imageSprite = new PIXI.Sprite(texture ?? PIXI.Texture.EMPTY);
        this.imageSprite.width = this.background.width;
        this.imageSprite.height = this.background.height;
        this.addChild(this.imageSprite);
        this.updateZoom();
        this.imageSprite.texture.once('update', () => {
          if (this.imageSprite) {
            this.updateZoom();
            this.centerGraphics(this.imageSprite);
          }
        });
      }
    }
  };

  createShapeElement = (element: VSImageShapeOrTextElementModel) => {
    if (element.type === 'Shape') {
      const {
        fill,
        width,
        height,
        opacity,
        backgroundType,
        backgroundColorFrom,
        backgroundColorTo,
        backgroundGradientType
      } = element;
      if (this.shapeGraphic) {
        this.shapeGraphic.clear();
      } else {
        this.shapeGraphic = new PIXI.Graphics();
      }
      this.shapeGraphic.beginTextureFill({
        alpha: opacity ?? 1,
        color: hexColorToPixiColor(fill)
      });

      if (backgroundType === 'gradient') {
        this.shapeGraphic.beginTextureFill({
          alpha: opacity ?? 1,
          texture: addGradient(backgroundColorFrom, backgroundColorTo, width, height, backgroundGradientType)
        });
      }

      switch (element.shapeType) {
        case 'ellipse':
          drawEllipse(this.shapeGraphic, width, height);
          break;
        case 'square':
          this.shapeGraphic.drawRect(0, 0, width, height);
          break;
        case 'circle':
          this.shapeGraphic.drawCircle(width / 2, width / 2, width / 2);
          break;
        case 'triangle':
          this.shapeGraphic.drawPolygon([
            Maths.asPIXIPoint({ x: width / 2, y: 0 }),
            Maths.asPIXIPoint({ x: width, y: height }),
            Maths.asPIXIPoint({ x: 0, y: height })
          ]);
          break;
        default:
          break;
      }

      this.shapeGraphic.endFill();
      this.shapeGraphic.pivot.set(0.5, 0.5);
      this.addChild(this.shapeGraphic);
    }
  };
}
