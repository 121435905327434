import { takeLatest, put, call, select } from 'redux-saga/effects';
import config, { LOGIN_URL } from 'js/config/config';
import { refreshUser, cognitoSignOut } from 'js/shared/helpers/authHelper';

import {
  VALIDATE_TOKEN_REQUESTED,
  LOGOUT,
  logout as logoutAction,
  authSuccess,
  validateTokenFailure,
  validateTokenSuccess,
  setAccountManagementRedirect,
  setNoOnlineStorage
} from '../actionCreators/authActions';
import { reset } from '../actionCreators/generalActions';
import * as tokenHelper from '../shared/helpers/TokenHelper';
import { appServices } from '../shared/helpers/app-services/AppServices';
import { sendErrorToSentry } from '../logging';

import redirectUserToReturnPath from './sagaHelpers/redirectUserToReturnPath';
import { validateAuthCookies } from './sagaHelpers/validateAuthCookies';
import { trackLogout } from './mixpanel';

const { MANAGE_ACCOUNT_URL } = config;

function* validateToken({ revalidation, userUpgraded }) {
  let authResponse;
  const pathname = yield select(({ router }) => router.location.pathname);

  const currentUserId = yield select(({ auth }) => auth.currentUser.id);

  try {
    authResponse = yield call(refreshUser);
    if (currentUserId !== '' && currentUserId !== authResponse.idToken.payload['custom:uid']) {
      window.location.reload();
    }
  } catch (error) {
    yield put(validateTokenFailure(error));
    if (pathname.includes('/render') || pathname.startsWith('/auth') || pathname.includes('/preview')) return;
    yield put(logoutAction({ userInitiated: false }));
    return;
  }

  let user;
  let userError = 'Unknown user error';
  let currentUser;
  const uidCookie = tokenHelper.getUID();
  const sparkolToken = tokenHelper.getSparkolToken();

  try {
    currentUser = tokenHelper.getUserInfo();
  } catch (error) {
    console.warn('No user info stored in cookies', error);
    currentUser = { accountType: 'FREE' };
  }

  const currentCookiesAreValid = validateAuthCookies(
    sparkolToken,
    uidCookie,
    currentUser,
    authResponse?.idToken?.payload['custom:uid']
  );

  try {
    if (currentCookiesAreValid && !userUpgraded) {
      user = currentUser;
    } else {
      user = yield call(
        { fn: appServices.exchangeCognitoTokenForSparkolToken, context: appServices },
        authResponse.idToken.jwtToken
      );
    }
  } catch (error) {
    if (error.errorCode === 'online_storage_disabled') {
      return yield put(setNoOnlineStorage(error));
    }

    userError = error;
    sendErrorToSentry(error);
    console.error(error);

    if (!pathname.includes('/render')) {
      yield put(logoutAction());
    }
  }

  if (!user) {
    yield put(validateTokenFailure(userError));
    return;
  }

  if ((!currentCookiesAreValid || userUpgraded) && user.token) {
    yield call(tokenHelper.setSparkolCookies, { token: user.token, tokenTtl: user.tokenTtl, user });
  }

  yield put(authSuccess(user, revalidation));
  if (!revalidation) {
    yield call(externalRedirect);
    yield redirectUserToReturnPath();
  }
  yield put(validateTokenSuccess(user));
}

function* externalRedirect() {
  const accountManagementRedirect = yield select(state => state.auth.accountManagementRedirect);

  if (accountManagementRedirect) {
    yield put(setAccountManagementRedirect(false));
    window.location.replace(`${MANAGE_ACCOUNT_URL}?referrer=loginredirect`);
    return yield;
  }
}

function* logout({ userInitiated }) {
  if (userInitiated) {
    yield call(trackLogout, userInitiated);
  }
  yield call(appServices.deleteSession);
  yield call(tokenHelper.removeSparkolCookies);
  yield put(reset());
  yield call(cognitoSignOut);
  window.location.href = LOGIN_URL;
}

function* authSagas() {
  yield takeLatest(VALIDATE_TOKEN_REQUESTED, validateToken);
  yield takeLatest(LOGOUT, logout);
}

export default authSagas;
