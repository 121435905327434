import { LOCATION_CHANGE } from 'connected-react-router';
import { CLOSE_AUDIO_MODAL, USE_AUDIO_ASSET_IN_PROJECT_SUCCESS } from 'js/actionCreators/audioActions';
import {
  UploadResourceToGpuAction,
  UPLOAD_RESOURCE_TO_GPU_COMPLETE,
  UPLOAD_RESOURCE_TO_GPU
} from 'js/actionCreators/gpuActions';
import {
  GET_SCRIBE_FOR_PLAYBACK_SUCCESS,
  GET_SCRIBE_FOR_PLAYBACK_ERROR,
  SET_MUTED,
  PLAY_FROM_EDITOR,
  PLAYBACK_IS_READY,
  START_PLAYBACK,
  STOP_PLAYBACK,
  PlayFromSceneEditorAction,
  PlayFromElementEditorAction,
  SetMutedAction,
  GetScribeForRenderAction,
  GetScribeForPlaybackSuccessAction,
  GetScribeForPlaybackErrorAction
} from 'js/actionCreators/playbackActions';
import { SETUP_PLAYBACK_STATE_FOR_PREVIEW } from 'js/actionCreators/previewActions';
import { UNLOAD_PLAYBACK } from 'js/actionCreators/uiActions';
import { PlaybackState } from 'js/types';

const initialState: PlaybackState = {
  playbackScribe: null,
  error: null,
  audioMuted: undefined,
  showPlaybackView: false,
  startingElementId: null,
  startingSceneIndex: null,
  autoPlay: true,
  isRecordingAudio: false,
  previewAudioPlayback: false,
  audioUrl: null,
  playAudio: true,
  playbackIsReady: false,
  startPlayback: false,
  uploadingResourceToGpu: false
};

type PlaybackAction =
  | {
      type:
        | typeof USE_AUDIO_ASSET_IN_PROJECT_SUCCESS
        | typeof PLAYBACK_IS_READY
        | typeof START_PLAYBACK
        | typeof STOP_PLAYBACK
        | typeof LOCATION_CHANGE
        | typeof UNLOAD_PLAYBACK
        | typeof SETUP_PLAYBACK_STATE_FOR_PREVIEW
        | typeof UPLOAD_RESOURCE_TO_GPU_COMPLETE
        | typeof CLOSE_AUDIO_MODAL;
    }
  | PlayFromSceneEditorAction
  | PlayFromElementEditorAction
  | SetMutedAction
  | GetScribeForRenderAction
  | GetScribeForPlaybackSuccessAction
  | GetScribeForPlaybackErrorAction
  | UploadResourceToGpuAction;

export default function playbackReducer(state = initialState, action: PlaybackAction): PlaybackState {
  switch (action.type) {
    case PLAY_FROM_EDITOR: {
      return {
        ...state,
        audioMuted: typeof state.audioMuted === 'undefined' ? false : state.audioMuted,
        showPlaybackView: true,
        startingElementId: 'startingElementId' in action ? action.startingElementId : null,
        startingSceneIndex: 'startingSceneIndex' in action ? action.startingSceneIndex : null,
        autoPlay: action.autoPlay,
        isRecordingAudio: action.isRecordingAudio,
        previewAudioPlayback: action.previewAudioPlayback,
        audioUrl: action.audioUrl,
        playAudio: action.playAudio,
        playbackIsReady: false,
        startPlayback: action.autoPlay ? true : false
      };
    }

    case STOP_PLAYBACK:
    case UNLOAD_PLAYBACK:
    case LOCATION_CHANGE: {
      return {
        ...state,
        ...initialState,
        showPlaybackView: false,
        audioMuted: state.audioMuted
      };
    }

    case PLAYBACK_IS_READY: {
      return {
        ...state,
        playbackIsReady: true
      };
    }

    case START_PLAYBACK: {
      return {
        ...state,
        startPlayback: true
      };
    }

    case USE_AUDIO_ASSET_IN_PROJECT_SUCCESS: {
      return {
        ...state,
        playbackScribe: null,
        showPlaybackView: false
      };
    }

    case GET_SCRIBE_FOR_PLAYBACK_SUCCESS: {
      return {
        ...state,
        playbackScribe: action.scribeData,
        error: null
      };
    }

    case CLOSE_AUDIO_MODAL: {
      return {
        ...state,
        playbackScribe: null,
        showPlaybackView: false
      };
    }

    case GET_SCRIBE_FOR_PLAYBACK_ERROR: {
      return {
        ...state,
        playbackScribe: null,
        error: action.error,
        showPlaybackView: false
      };
    }
    case SETUP_PLAYBACK_STATE_FOR_PREVIEW: {
      return {
        ...state,
        autoPlay: true,
        startPlayback: true,
        playAudio: true
      };
    }

    case SET_MUTED: {
      return {
        ...state,
        audioMuted: action.muted
      };
    }

    case UPLOAD_RESOURCE_TO_GPU: {
      return {
        ...state,
        uploadingResourceToGpu: true
      };
    }

    case UPLOAD_RESOURCE_TO_GPU_COMPLETE: {
      return {
        ...state,
        uploadingResourceToGpu: false
      };
    }

    default:
      return state;
  }
}
