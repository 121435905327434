const TIMELINE_OPEN = 'timeline-open';

export const getTimelineOpenStatus = () => {
  return localStorage.getItem(TIMELINE_OPEN) === 'true';
};

export const saveTimelineOpenStatus = (newStatus: boolean): { success: boolean; message?: string } => {
  try {
    localStorage.setItem(TIMELINE_OPEN, newStatus.toString());
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false, message: 'Failed to save new timeline state' };
  }
};
