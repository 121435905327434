import { END, EventChannel, eventChannel } from 'redux-saga';
import { put, take, takeEvery } from 'redux-saga/effects';
import UrlToFileWorker from 'js/shared/lib/UrlToFile/UrlToFile.worker?worker';
import {
  UrlToFileMessageEvent,
  UrlToFileCompleteEvent,
  UrlToFileErrorEvent
} from 'js/shared/lib/UrlToFile/UrlToFileTypes';
import {
  URL_TO_FILE_REQUEST,
  UrlToFileRequestAction,
  urlToFileSuccess,
  urlToFileFailure
} from 'js/actionCreators/urlToFileActions';

function createUrlToFileChannel(
  processor: Worker
): EventChannel<UrlToFileMessageEvent | UrlToFileCompleteEvent | UrlToFileErrorEvent> {
  return eventChannel(emitter => {
    const handleMessage = (event: MessageEvent<UrlToFileCompleteEvent | UrlToFileErrorEvent>) => {
      if ('error' in event.data) {
        emitter({ error: event.data.error });
        return emitter(END);
      }
      emitter({ file: event.data.file });
      return emitter(END);
    };
    processor.addEventListener('message', handleMessage);
    return () => processor.removeEventListener('message', handleMessage);
  });
}

function* urlToFile({ url, filename, mimeType }: UrlToFileRequestAction) {
  const processor = new UrlToFileWorker();
  const channel = createUrlToFileChannel(processor);

  try {
    processor.postMessage({ url, filename, mimeType });
    while (true) {
      const { file, error } = yield take(channel);
      if (file) {
        yield put(urlToFileSuccess({ file }));
      } else if (error) {
        yield put(urlToFileFailure({ error }));
      }
    }
  } finally {
    channel.close();
    processor.terminate();
  }
}

export default function* urlToFileSagas() {
  yield takeEvery(URL_TO_FILE_REQUEST, urlToFile);
}
