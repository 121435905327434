import { LOGOUT } from 'js/actionCreators/authActions';
import { DownloadRequestFailedAction } from 'js/actionCreators/downloadRequestFailedAction';
import { DownloadRequestSuccessAction } from 'js/actionCreators/downloadRequestSuccessAction';
import { GetVideoDownloadUrlFailedAction } from 'js/actionCreators/getVideoDownloadUrlFailedAction';
import { GetVideoDownloadUrlSuccessAction } from 'js/actionCreators/getVideoDownloadUrlSuccessAction';
import { RenderFailedAction } from 'js/actionCreators/renderFailedAction';
import { StartRenderStatusPollingAction } from 'js/actionCreators/startRenderStatusPollingAction';
import { StopRenderStatusPollingAction } from 'js/actionCreators/stopRenderStatusPollingAction';
import { DownloadState, RenderProgressSuccessResult } from 'js/types';

import {
  DOWNLOAD_RESET,
  DOWNLOAD_REQUEST,
  DOWNLOAD_REQUEST_FAILED,
  DOWNLOAD_REQUEST_SUCCESS,
  START_RENDER_STATUS_POLLING,
  STOP_RENDER_STATUS_POLLING,
  GET_VIDEO_DOWNLOAD_URL_SUCCESS,
  GET_VIDEO_DOWNLOAD_URL_FAILED,
  FETCH_RENDER_PROGRESS_SUCCESS,
  RENDER_FAILED,
  RequestDownloadAction,
  FetchRenderProgressSuccessAction
} from '../actionCreators/downloadActions';

const defaultState: DownloadState = {
  error: null,
  renderInProgress: false,
  downloadUrl: null,
  downloadingInProgress: false,
  formatRequested: null,
  segmentsRequested: 0,
  currentDownloadTrackingProperties: null,
  renderStatus: {
    max: 100,
    complete: 0,
    message: 'Requesting render',
    started: 0
  }
};

type DownloadAction =
  | DownloadRequestSuccessAction
  | GetVideoDownloadUrlFailedAction
  | DownloadRequestFailedAction
  | RenderFailedAction
  | RequestDownloadAction
  | StartRenderStatusPollingAction
  | StopRenderStatusPollingAction
  | GetVideoDownloadUrlSuccessAction
  | FetchRenderProgressSuccessAction
  | { type: typeof DOWNLOAD_RESET | typeof LOGOUT };

export default function downloadReducer(state = defaultState, action: DownloadAction): DownloadState {
  switch (action.type) {
    case DOWNLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        downloadingInProgress: true,
        segmentsRequested: action.numSegments,
        currentDownloadTrackingProperties: action.trackingProperties
      };

    case GET_VIDEO_DOWNLOAD_URL_FAILED:
    case DOWNLOAD_REQUEST_FAILED:
    case RENDER_FAILED:
      return {
        ...state,
        error: action.error,
        downloadUrl: null,
        downloadingInProgress: false
      };

    case DOWNLOAD_REQUEST:
      return {
        ...state,
        error: null,
        downloadingInProgress: true,
        formatRequested: action.format
      };

    case START_RENDER_STATUS_POLLING: {
      return {
        ...state,
        downloadingInProgress: true,
        renderInProgress: true
      };
    }

    case STOP_RENDER_STATUS_POLLING: {
      return {
        ...state,
        downloadingInProgress: true,
        renderInProgress: false
      };
    }

    case GET_VIDEO_DOWNLOAD_URL_SUCCESS: {
      return {
        ...state,
        downloadingInProgress: false,
        downloadUrl: action.downloadUrl,
        currentDownloadTrackingProperties: null
      };
    }

    case FETCH_RENDER_PROGRESS_SUCCESS: {
      const renderStatus = getRenderStatusState(state, action.result);
      return { ...state, renderStatus };
    }

    case DOWNLOAD_RESET:
      return {
        ...defaultState
      };

    case LOGOUT: {
      return defaultState;
    }

    default:
      return state;
  }
}

function getRenderStatusState(state: DownloadState, result: RenderProgressSuccessResult) {
  const message = getRenderStatusMessage(result);
  const { max, complete, started } = getRenderStatusComplete(state, result);

  return {
    ...state.renderStatus,
    message,
    max,
    complete,
    started
  };
}

function getRenderStatusMessage(result: RenderProgressSuccessResult) {
  const { segmentTotal, segmentRendersStarted, segmentRendersCompleted, transcodeComplete, mergeComplete } = result;

  if (mergeComplete) {
    return `Downloading project`;
  }

  if (transcodeComplete) {
    return `Merging ${segmentTotal} segments`;
  }

  if (segmentRendersCompleted > 0) {
    return `Rendering ${segmentTotal} segments`;
  }

  if (segmentRendersStarted > 0) {
    return 'Rendering has started';
  }

  return 'Your project is currently in a queue waiting to start rendering';
}

function getRenderStatusComplete(state: DownloadState, result: RenderProgressSuccessResult) {
  const { segmentsRequested } = state;
  const {
    videoRequestComplete,
    segmentTotal,
    transcodeComplete,
    mergeComplete,
    segmentTranscodesCompleted,
    segmentRendersStarted,
    segmentRendersCompleted
  } = result;

  if (!segmentsRequested) {
    return {
      max: 100,
      complete: 0
    };
  }

  const nonValueSteps = [videoRequestComplete, transcodeComplete, mergeComplete];
  const totalSteps = nonValueSteps.length + segmentTotal * 2;
  const started = segmentRendersStarted > 0 ? segmentRendersStarted * 2 + nonValueSteps.length : segmentRendersStarted;
  const completeNonValueSteps = nonValueSteps.filter(Boolean).length;
  const complete = segmentRendersCompleted + segmentTranscodesCompleted + completeNonValueSteps;

  return {
    max: totalSteps,
    complete: complete,
    started
  };
}
