import { MAX_CAM_ZOOM, MIN_CAM_ZOOM } from 'js/config/defaults';
import { getPositionAdjustmentToFitInBounds } from 'js/sagas/sagaHelpers/getPositionAdjustmentToFitInBounds';
import { ViewportRectAndCenter } from 'js/types';
import clamp from 'lodash.clamp';
import * as PIXI from 'pixi.js';

/**
 * Calculates what the camera position should be keeping it fitted to the viewport, but adjusting it
 * so that scales stay withing scale bounds and positions stay within canvas bounds
 */
export const getAdjustedNewCameraRect = (
  viewportRectAndCenter: ViewportRectAndCenter,
  canvasSize: { width: number; height: number },
  canvasBounds: PIXI.Rectangle
) => {
  const { viewportRectangle, viewportCenter } = viewportRectAndCenter;

  const cameraAspect = canvasSize.width / canvasSize.height;
  const newCamBounds = new PIXI.Rectangle(
    0,
    0,
    Math.min(viewportRectangle.width, canvasBounds.width),
    Math.min(viewportRectangle.height, canvasBounds.height)
  );
  const viewportAspect = newCamBounds.width / newCamBounds.height;
  let newFittedScale;
  // Determine the scaling factor based on aspect ratios
  if (cameraAspect > viewportAspect) {
    // Fit the target rectangle horizontally within the container
    newFittedScale = newCamBounds.width / canvasSize.width;
  } else {
    // Fit the target rectangle vertically within the container
    newFittedScale = newCamBounds.height / canvasSize.height;
  }
  newFittedScale = clamp(1 / newFittedScale, MIN_CAM_ZOOM, MAX_CAM_ZOOM);

  const targetCameraRect = new PIXI.Rectangle(
    viewportCenter.x - canvasSize.width / newFittedScale / 2,
    viewportCenter.y - canvasSize.height / newFittedScale / 2,
    canvasSize.width / newFittedScale,
    canvasSize.height / newFittedScale
  );

  const { xAdjust, yAdjust } = getPositionAdjustmentToFitInBounds(targetCameraRect, canvasBounds);
  const adjustedCameraRectangle = new PIXI.Rectangle(
    targetCameraRect.x + xAdjust,
    targetCameraRect.y + yAdjust,
    targetCameraRect.width,
    targetCameraRect.height
  );

  return {
    scale: newFittedScale,
    adjustedCameraRectangle
  };
};
