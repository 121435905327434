import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      xs: 380,
      tablet: 600,
      sm: 768,
      md: 900,
      laptop: 1024,
      lg: 1200,
      desktop: 1440,
      xl: 1536,
      largeDesktop: 1728
    }
  },
  typography: {
    fontFamily: `var(--fontFamilyBody) sans-serif`
  },
  palette: {
    primary: {
      main: '#17a9ce'
    }
  },
  props: {
    MuiTab: {
      disableFocusRipple: true,
      disableRipple: true
    }
  },
  overrides: {
    MuiPopover: {
      paper: {
        borderRadius: 8
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: 8
      }
    },
    MuiDivider: {
      root: {
        margin: '0 32px 0 32px',
        width: 'auto',
        backgroundColor: 'var(--colorMainBlue)'
      }
    },
    MuiTabs: {
      root: {
        minHeight: 'auto'
      },
      indicator: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: 'transparent',
        '& > span': {
          width: '100%',
          backgroundColor: '#17a9ce'
        }
      },
      flexContainer: {
        gap: '64px'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minWidth: '60px',
        padding: '0 0 8px 0',
        width: 'auto',
        minHeight: 'auto',
        '@media (min-width:768px)': {
          minWidth: 'auto'
        }
      },
      wrapper: {
        alignItems: 'flex-start'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 61, 81, .85)'
      }
    },
    MuiMenuItem: {
      root: {
        color: 'inherit',

        '&:hover': {
          color: 'var(--colorMainBlue)'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 38,
        color: 'currentcolor'
      }
    },
    MuiListSubheader: {
      root: {
        lineHeight: 2,
        fontWeight: 700,
        color: 'inherit',
        marginTop: 5
      }
    },
    MuiIconButton: {
      root: {
        color: 'inherit',

        '&:hover': {
          color: 'var(--colorMainBlue)',
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTableSortLabel: {
      root: {
        color: 'var(--colorBackgroundDark)',
        '& $icon': {
          opacity: 1,
          color: 'var(--colorBackgroundDark)'
        },
        '&:hover': {
          color: 'var(--colorBackgroundDark)',
          '&& $icon': {
            opacity: 1,
            color: 'var(--colorAccentMainBlue)'
          }
        },
        '&&$active': {
          color: 'var(--colorBackgroundDark)',
          '&& $icon': {
            color: 'var(--colorBackgroundDark)'
          },
          '&:hover': {
            color: 'var(--colorBackgroundDark)',
            '&& $icon': {
              opacity: 1,
              color: 'var(--colorAccentMainBlue)'
            }
          }
        }
      }
    }
  }
});

export default theme;
