import { defaultFontLabel, defaultFontValue } from 'js/shared/resources/scribedefaults';
import { TextStylingConfig } from 'js/types';

import { CROP_SHAPE_TYPES } from './config';
import { FILE_CONTENT_TYPES } from './consts';

export const defaultCanvasColor = '#FFFFFF';
export const defaultCanvasColorFrom = '#FFFFFF';
export const defaultCanvasColorTo = '#FFFFFF';
export const defaultBackgroundType = 'solid';
export const defaultBackgroundGradientType = 'vertical';
export const renderer = {
  frameRate: import.meta.env.VITE_CAPTURE_FRAMERATE || 25,
  format: 'png'
};
export const canvasSizes = {
  landscape: {
    width: 1920,
    height: 1080
  },
  square: {
    width: 1080,
    height: 1080
  },
  portrait: {
    width: 1080,
    height: 1920
  }
};
export const resolution = 1;
export const defaultScale = 1;

export const EDITOR_CANVAS_CONTROL_VISIBILITY = {
  object: {
    ml: false,
    mr: false,
    mb: false,
    mt: false,
    mtr: true,
    bl: true,
    br: true,
    tl: true,
    tr: true,
    pinControl: false
  },
  objectUnlockedRatio: {
    ml: true,
    mr: true,
    mb: true,
    mt: true,
    mtr: true,
    bl: true,
    br: true,
    tl: true,
    tr: true,
    pinControl: false
  },
  group: {
    ml: false,
    mr: false,
    mb: false,
    mt: false,
    mtr: true,
    bl: true,
    br: true,
    tl: true,
    tr: true,
    pinControl: true
  },
  hidden: {
    ml: false,
    mr: false,
    mb: false,
    mt: false,
    mtr: false,
    bl: false,
    br: false,
    tl: false,
    tr: false,
    lockIndicator: false,
    pinControl: false
  },
  camera: {
    ml: false,
    mr: false,
    mb: false,
    mt: false,
    mtr: false,
    bl: true,
    br: true,
    tl: true,
    tr: true,
    lockIndicator: false,
    pinControl: true
  }
};

const CROP_CANVAS_CONTROL_VISIBILITY_DEFAULTS = {
  mtr: false,
  ml: true,
  mr: true,
  mb: true,
  mt: true,
  bl: true,
  br: true,
  tl: true,
  tr: true
};

export const CROP_CANVAS_CONTROL_VISIBILITY = {
  DEFAULT: {
    ...CROP_CANVAS_CONTROL_VISIBILITY_DEFAULTS
  },
  [CROP_SHAPE_TYPES.SQUARE]: {
    ...CROP_CANVAS_CONTROL_VISIBILITY_DEFAULTS,
    ml: false,
    mr: false,
    mt: false,
    mb: false
  },
  [CROP_SHAPE_TYPES.CIRCLE]: {
    ...CROP_CANVAS_CONTROL_VISIBILITY_DEFAULTS,
    ml: false,
    mr: false,
    mt: false,
    mb: false
  },
  [CROP_SHAPE_TYPES.ELLIPSE]: {
    ...CROP_CANVAS_CONTROL_VISIBILITY_DEFAULTS
  }
};

export const EDITOR_CANVAS_SELECTION_OPTIONS = {
  borderColor: '#17a9ce',
  cornerColor: '#17a9ce',
  selectionBackgroundColor: 'rgba(39, 168, 203, 0.1)',
  transparentCorners: false,
  cornerSize: 16,
  lockScalingFlip: true
};

export const SVG_DEFAULT_STROKE_WIDTH = 1;
export const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
export const MASK_LINE_COLOR = 0xff0000;
export const SCRIBBLE_RESOLUTION = 40;

export const FREE_TIER_PROJECT_LIMIT = 30;
export const SCRIBE_TEXT_LINE_HEIGHT = 1.4;
export const SCRIBE_TEXT_MULT = 1.13;
export const MAX_UPLOADED_IMAGE_DIMENSION = 2048;
export const MAX_CHARS_IN_TEXT = 200;
export const FREE_ELEMENTS_LIMIT = Infinity;
export const WATERMARK_CORNER_PADDING = 40;
export const MAX_IMAGE_FILE_UPLOAD_SIZE_DISPLAY = 10; // MB
export const MAX_IMAGE_FILE_UPLOAD_SIZE = MAX_IMAGE_FILE_UPLOAD_SIZE_DISPLAY * 1024 * 1024;

export const MAX_SIMULTANEOUS_FILE_UPLOAD = 20;

export const DEFAULT_TEXT_COLOR = '#131515';
export const DEFAULT_TEXT_ALIGN = 'left';
export const DEFAULT_TEXT_SIZE = 120;
export const DEFAULT_TEXT_FONT_WEIGHT = 'normal';
export const DEFAULT_TEXT_FONT_STYLE = 'normal';
export const DEFAULT_TEXT_OPACITY = 1;
export const DEFAULT_TEXT_FONT = {
  label: defaultFontLabel,
  value: defaultFontValue
};

export const defaultTextStylingConfig: TextStylingConfig = {
  font: DEFAULT_TEXT_FONT,
  align: DEFAULT_TEXT_ALIGN,
  fill: DEFAULT_TEXT_COLOR,
  fontSize: DEFAULT_TEXT_SIZE,
  fontWeight: DEFAULT_TEXT_FONT_WEIGHT,
  fontStyle: DEFAULT_TEXT_FONT_STYLE,
  opacity: DEFAULT_TEXT_OPACITY
};

export const ALLOWED_IMAGE_TYPES = [
  FILE_CONTENT_TYPES.BMP,
  FILE_CONTENT_TYPES.SVG,
  FILE_CONTENT_TYPES.PNG,
  FILE_CONTENT_TYPES.JPG,
  FILE_CONTENT_TYPES.GIF
];

export const ALLOWED_FONT_TYPES = ['woff', 'woff2', 'ttf', 'otf'];

export const DRAW_SPEED = 2000; // pixels per second
export const MAX_DEFAULT_ANIMATION_TIME = 6;
export const MIN_DEFAULT_ANIMATION_TIME = 3;
export const STROKE_ANIMATION_SEGMENT_PERCENTAGE = 0.7;

export const SPIN_CLOCKWISE_DEFAULT = 360;
export const SPIN_COUNTER_CLOCKWISE_DEFAULT = -360;

export const MAX_SCRIBE_LENGTH_LONG_IN_MS = 15 * 60 * 1000;
export const MAX_SCRIBE_LENGTH_SHORT_IN_MS = 5 * 60 * 1000;
export const MAX_NUMBER_OF_SCENES = 25;
export const MAX_NUMBER_OF_ELEMENTS = 150;
export const MIN_FONT_SIZE = 1;
export const MAX_FONT_SIZE = 999;
export const MAX_RECOMMENDED_AUDIO_TRACKS = Number(import.meta.env.VITE_MAX_RECOMMENDED_AUDIO_TRACKS) || 50;
export const MAX_TOTAL_AUDIO_FILE_SIZE = Number(import.meta.env.VITE_MAX_TOTAL_AUDIO_FILE_SIZE) || 314572800; // 300MB
export const FONT_STEP = 1;
export const FONT_ALLOW_DECIMAL = false;

export const INDETERMINATE_DISPLAY_VALUE = '-';

export const TIMELINE_HEIGHT = 170;
export const EXPANDED_TIMELINE_HEIGHT = 280;
export const TIMELINE_MAX_HEIGHT = 500;

export const DEFAULT_SHAPE_COLOR = '#C5C5C5';
export const CAMERA_COLOR = '#FF00F5';
export const MAX_CAM_ZOOM = 6;
export const MIN_CAM_ZOOM = 0.05;

export const MAX_SCROLL_MULTIPLIER = 3;

export const SVG_CANVAS_SIZE = 1000;

export const BITMAP_TEXT_SCALING_FACTOR = 100000;

export const MIN_IMAGE_DRAWER_WIDTH = 450;
export const MAX_IMAGE_DRAWER_WIDTH = 804;
