import { ScribeAudioLayerModel } from 'js/models/ScribeAudioLayerModel';
import { AudioClip, ElementClone, ExistingScribeModel, SceneClipboardPayload, SceneClone } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import uuid from 'uuid/v4';

export default function createSceneClipboardPayload(
  project: ExistingScribeModel,
  sceneIds: string[]
): SceneClipboardPayload {
  const elementClones: ElementClone[] = [];
  const audioClips: AudioClip[] = [];
  const audioLayers: ScribeAudioLayerModel[] = [];

  const scenesToClone = project.scenes.filter(scene => sceneIds.includes(scene.id));
  const sceneClones = scenesToClone.map((scene: SceneClone) => {
    const sceneClone = cloneDeep(scene);
    delete sceneClone.id;
    sceneClone.active = false;

    sceneClone.elementIds.forEach((elementId, index, arr) => {
      const element = project.elements.find(el => el.id === elementId);
      if (!element) return;
      const elementClone = cloneDeep(element) as ElementClone;
      delete elementClone.id;

      elementClone.tempId = uuid();
      arr[index] = elementClone.tempId;
      elementClones.push(elementClone);
    });

    if (sceneClone.audioLayerIds?.length) {
      sceneClone.audioLayerIds.forEach(audioLayerId => {
        const audioLayer = project.audioLayers.find(layer => layer.id === audioLayerId);
        if (!audioLayer) return;
        const audioLayerClone = cloneDeep(audioLayer) as ScribeAudioLayerModel;
        audioLayers.push(audioLayerClone);

        if (audioLayerClone.audioClipIds?.length) {
          audioLayerClone.audioClipIds.forEach(audioClipId => {
            const audioClip = project.audioClips?.find(clip => clip.id === audioClipId);
            if (!audioClip) return;
            const audioClipClone = cloneDeep(audioClip) as AudioClip;
            audioClips.push(audioClipClone);
          });
        }
      });
    }

    return sceneClone;
  });

  return {
    type: 'scene',
    metadata: {
      elements: elementClones,
      canvasSize: project.canvasSize,
      audioClips,
      audioLayers
    },
    data: sceneClones
  };
}
