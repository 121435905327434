import { call, CallEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { SHOW_LEFT_HAND_PANEL, ShowLeftHandPanelAction } from 'js/actionCreators/uiActions';
import {
  BetaAiPanelTab,
  ExistingScribeModel,
  ImageGenerationInputs,
  ImageGenerationOutputs,
  ScriptGenerationInputs,
  VoiceoverGenerationInputs,
  VoiceoverGenerationOutputs,
  LeftHandPanel
} from 'js/types';
import {
  CHANGE_AI_PANEL_TAB,
  ChangeAiPanelTabAction,
  ChangeStepTrigger,
  GENERATE_IMAGES,
  SET_IMAGE_GENERATION_INPUTS,
  SetImageGenerationInputsAction,
  REGENERATE_IMAGES,
  TRY_NEW_IMAGE_PROMPT,
  PREVIEW_VOICEOVER_VOICE,
  PreviewVoiceoverVoiceAction,
  GENERATE_VOICEOVER,
  RESET_VOICEOVER_GENERATION,
  EDIT_VOICEOVER,
  GENERATE_SCRIPT,
  SAVE_GENERATED_SCRIPT,
  TRY_NEW_SCRIPT_PROMPT,
  TryNewScriptPromptAction,
  COPY_SCRIPT_TO_CLIPBOARD,
  EDIT_IMAGE_PROMPT,
  SET_VOICEOVER_GENERATION_INPUTS,
  SetVoiceoverGenerationInputsAction,
  SUBMIT_IMAGE_FEEDBACK,
  SubmitImageFeedbackAction,
  SubmitScriptFeedbackAction,
  SubmitVoiceoverFeedbackAction,
  SUBMIT_SCRIPT_FEEDBACK,
  SUBMIT_VOICEOVER_FEEDBACK
} from 'js/actionCreators/betaAiFeaturesActions';
import {
  getImageGenerationInputs,
  getImageGenerationResults,
  getScriptGenerationInputs,
  getVoiceoverGenerationInputs,
  getVoiceoverGenerationResults
} from 'js/sagas/betaAiFeaturesSagas';
import getProjectFromPathname from 'js/sagas/sagaHelpers/getProjectFromPathname';

import { track } from '../mixpanelProvider';

function getPromptProps(prompt: string) {
  let promptProps: { Prompt: string; 'Prompt (cont.)'?: string } = {
    Prompt: prompt
  };
  if (prompt.length > MIXPANEL_MAX_VALUE_LENGTH) {
    promptProps = {
      Prompt: prompt.substring(0, MIXPANEL_MAX_VALUE_LENGTH),
      'Prompt (cont.)': prompt.substring(MIXPANEL_MAX_VALUE_LENGTH)
    };
  }

  return promptProps;
}

function* trackShowAiPanel(
  action: ShowLeftHandPanelAction
): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  if (action.name !== LeftHandPanel.AI_GENERATION) return;
  const project = yield call(getProjectFromPathname);
  if (!project) return;

  const { trigger, tab } = action;

  if (trigger === ChangeStepTrigger.LEFT_HAND_PANEL) {
    yield call(track, 'Open AI LHP', { 'Scribe ID': project.id });
  }
  if (tab) {
    if (tab === BetaAiPanelTab.IMAGE_GENERATION) {
      yield call(track, 'Open AI Image Gen', { 'Scribe ID': project.id, 'Event Trigger': trigger });
    } else if (tab === BetaAiPanelTab.VOICE_GENERATION) {
      yield call(track, 'Open AI VO Gen', { 'Scribe ID': project.id, 'Event Trigger': trigger });
    }
  }
}

function* trackChangeAiPanelTab({
  tab,
  trigger
}: ChangeAiPanelTabAction): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project || !tab) return;

  if (tab === BetaAiPanelTab.IMAGE_GENERATION) {
    yield call(track, 'Open AI Image Gen', { 'Scribe ID': project.id, 'Event Trigger': trigger });
  } else if (tab === BetaAiPanelTab.VOICE_GENERATION) {
    yield call(track, 'Open AI VO Gen', { 'Scribe ID': project.id, 'Event Trigger': trigger });
  } else if (tab === BetaAiPanelTab.SCRIPT_GENERATION) {
    yield call(track, 'Open AI Script Gen', { 'Scribe ID': project.id, 'Event Trigger': trigger });
  }
}

function* trackSelectedImageGenerationStyle({
  style
}: SetImageGenerationInputsAction): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;

  if (style) {
    yield call(track, 'Select AI Image Gen Style', { 'Scribe ID': project.id, 'Image Style Selected': style });
  }
}

const MIXPANEL_MAX_VALUE_LENGTH = 255;
function* trackGenerateImage(): Generator<
  CallEffect | SelectEffect,
  void,
  ExistingScribeModel | ImageGenerationInputs | undefined
> {
  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project) return;

  const { style, prompt } = (yield select(getImageGenerationInputs)) as ImageGenerationInputs;

  const promptProps = getPromptProps(prompt);
  yield call(track, 'Generate AI Image', { 'Scribe ID': project.id, 'Image Style Selected': style, ...promptProps });
}

type ImageGenerationBasicData = {
  'Image Style Selected': string;
  Prompt: string;
  'Scribe ID': number;
  'Generation ID': string;
  'Prompt (cont.)'?: string;
};

export function* getImageGenerationBasicData(): Generator<
  SelectEffect | CallEffect,
  ImageGenerationBasicData | undefined,
  ExistingScribeModel | undefined | ImageGenerationInputs | ImageGenerationOutputs
> {
  const { style, prompt } = (yield select(getImageGenerationInputs)) as ImageGenerationInputs;
  const { id } = (yield select(getImageGenerationResults)) as ImageGenerationOutputs;

  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project || !style || !id) return;
  return {
    'Image Style Selected': style,
    'Scribe ID': project.id,
    'Generation ID': id,
    ...getPromptProps(prompt)
  };
}

function* trackRegenerateImage(): Generator<CallEffect, void, ImageGenerationBasicData> {
  const basicData = (yield call(getImageGenerationBasicData)) as ImageGenerationBasicData | undefined;
  if (!basicData) return;
  yield call(track, 'Regenerate Image', {
    ...basicData
  });
}

function* trackEditImagePrompt(): Generator<
  CallEffect | SelectEffect,
  void,
  ExistingScribeModel | ImageGenerationInputs | undefined
> {
  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project) return;
  const { prompt, style } = (yield select(getImageGenerationInputs)) as ImageGenerationInputs;
  yield call(track, 'Edit AI Image Gen Prompt', {
    'Scribe ID': project.id,
    'Image Style Selected': style,
    ...getPromptProps(prompt)
  });
}

function* trackTryNewImagePrompt(): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project) return;
  yield call(track, 'Edit Image Gen Prompt', {
    'Scribe ID': project.id
  });
}

function* trackSelectVoiceoverLanguage({
  language
}: SetVoiceoverGenerationInputsAction): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project || !language) return;
  yield call(track, 'Select AI VO Gen Language & Accent', {
    'Language & Accent': language,
    'Scribe ID': project.id
  });
}

function* trackSelectVoiceoverVoice({
  language,
  voice
}: SetVoiceoverGenerationInputsAction): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project || !language || !voice) return;
  yield call(track, 'Select AI VO Profile', {
    'Language & Accent': language,
    Gender: voice.gender,
    Voice: voice.displayedName,
    'Scribe ID': project.id
  });
}

function* trackPreviewVoiceoverVoice({
  language,
  voice,
  gender
}: PreviewVoiceoverVoiceAction): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;
  yield call(track, 'Preview AI Example VO Profile', {
    'Language & Accent': language,
    Gender: gender,
    Voice: voice,
    'Scribe ID': project.id
  });
}

function* trackGenerateVoiceover(): Generator<
  CallEffect | SelectEffect,
  void,
  ExistingScribeModel | VoiceoverGenerationInputs | undefined
> {
  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project) return;
  const { language, voice, prompt } = (yield select(getVoiceoverGenerationInputs)) as VoiceoverGenerationInputs;
  yield call(track, 'Click Generate AI Voiceover', {
    'Language & Accent': language,
    Gender: voice.gender,
    Voice: voice.displayedName,
    'Number of Characters': prompt.length,
    'Scribe ID': project.id
  });
}

export type VoiceoverGenerationBasicData = {
  'Language & Accent': string;
  Voice: string;
  Gender: string;
  'Scribe ID': number;
  'Audio ID': string;
};

export function* getVoiceoverGenerationBasicData(): Generator<
  SelectEffect | CallEffect,
  VoiceoverGenerationBasicData | undefined,
  ExistingScribeModel | undefined | VoiceoverGenerationInputs | VoiceoverGenerationOutputs
> {
  const { language, voice } = (yield select(getVoiceoverGenerationInputs)) as VoiceoverGenerationInputs;
  const { id } = (yield select(getVoiceoverGenerationResults)) as VoiceoverGenerationOutputs;

  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project || !language || !voice || !id) return;
  return {
    'Language & Accent': language,
    Voice: voice.displayedName,
    Gender: voice.gender,
    'Scribe ID': project.id,
    'Audio ID': id
  };
}

function* trackResetVoiceoverGeneration(): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;
  yield call(track, 'Click Generate New Voiceover Button', {
    'Scribe ID': project.id
  });
}

function* trackEditVoiceoverGeneration(): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;
  yield call(track, 'Click Edit This Voiceover Button', {
    'Scribe ID': project.id
  });
}

function getScriptTrackingProperties(inputs: ScriptGenerationInputs) {
  const lengthOfScript = inputs.lengthOfScriptMinutes * 60 + inputs.lengthOfScriptSeconds;
  return {
    'Number of Scenes': inputs.numberOfScenes,
    'Duration of Script (seconds)': lengthOfScript,
    'Style/Tone Options': inputs.predefinedStyles.filter(option => option.isChecked).map(option => option.label),
    'Style/Tone Other': inputs.customStyle,
    'Prompt Character Length': Array.from(inputs.prompt).length
  };
}

function* trackGenerateScript(): Generator<
  CallEffect | SelectEffect,
  void,
  ExistingScribeModel | ScriptGenerationInputs | undefined
> {
  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project) return;

  const inputs = (yield select(getScriptGenerationInputs)) as ScriptGenerationInputs;

  yield call(track, 'Click Generate AI Script', {
    'Scribe ID': project.id,
    ...getScriptTrackingProperties(inputs)
  });
}

function* submitScriptFeedback(
  action: SubmitScriptFeedbackAction
): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined | ScriptGenerationInputs> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;
  const { rating, tags, comment } = action;
  const inputs = yield select(getScriptGenerationInputs);

  if (!!inputs && 'lengthOfScriptMinutes' in inputs && 'id' in project) {
    yield call(track, 'How is the generated script?', {
      'Scribe ID': project.id,
      'Script Rating': rating,
      Options: tags,
      Comment: comment,
      ...getScriptTrackingProperties(inputs)
    });
  }
}

function* submitImageFeedback(
  action: SubmitImageFeedbackAction
): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;
  const basicData = (yield call(getImageGenerationBasicData)) as ImageGenerationBasicData | undefined;
  const { imageId, rating, comment, options } = action.feedbackData;
  yield call(track, 'How is the generated image?', {
    ...basicData,
    'Scribe ID': project.id,
    'Image ID': imageId,
    'Image Rating': rating,
    Comment: comment,
    Tags: options.join(', ')
  });
}

function* submitVoiceoverFeedback(action: SubmitVoiceoverFeedbackAction): Generator<SelectEffect | CallEffect, void> {
  const { rating, tags, comment } = action;
  const basicData = (yield call(getVoiceoverGenerationBasicData)) as VoiceoverGenerationBasicData | undefined;
  if (!rating || !basicData) return;

  const properties = {
    rating,
    tags: tags.join(', '),
    comment,
    ...basicData
  };

  track('How Is The Generated Voiceover?', properties);
}

function* trackSaveGeneratedScript(): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;

  yield call(track, 'Click Save Script Button', {
    'Scribe ID': project.id
  });
}

function* trackTryNewScriptPrompt(
  action: TryNewScriptPromptAction
): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;

  if (action.reset) {
    yield call(track, 'Click Generate A New Script Button', {
      'Scribe ID': project.id
    });
  } else {
    yield call(track, 'Click Edit The Prompt Button', {
      'Scribe ID': project.id
    });
  }
}

function* trackCopyScriptToClipboard(): Generator<CallEffect, void, ExistingScribeModel | undefined> {
  const project = yield call(getProjectFromPathname);
  if (!project) return;

  yield call(track, 'Click Copy Script to Clipboard Button', {
    'Scribe ID': project.id
  });
}

export default function* trackAiBetaSagas() {
  yield takeEvery(SHOW_LEFT_HAND_PANEL, trackShowAiPanel);
  yield takeEvery(CHANGE_AI_PANEL_TAB, trackChangeAiPanelTab);
  yield takeEvery(SET_IMAGE_GENERATION_INPUTS, trackSelectedImageGenerationStyle);
  yield takeEvery(GENERATE_IMAGES, trackGenerateImage);
  yield takeEvery(REGENERATE_IMAGES, trackRegenerateImage);
  yield takeEvery(EDIT_IMAGE_PROMPT, trackEditImagePrompt);
  yield takeEvery(TRY_NEW_IMAGE_PROMPT, trackTryNewImagePrompt);
  yield takeEvery(SET_VOICEOVER_GENERATION_INPUTS, trackSelectVoiceoverLanguage);
  yield takeEvery(SET_VOICEOVER_GENERATION_INPUTS, trackSelectVoiceoverVoice);
  yield takeEvery(PREVIEW_VOICEOVER_VOICE, trackPreviewVoiceoverVoice);
  yield takeEvery(GENERATE_VOICEOVER, trackGenerateVoiceover);
  yield takeEvery(RESET_VOICEOVER_GENERATION, trackResetVoiceoverGeneration);
  yield takeEvery(EDIT_VOICEOVER, trackEditVoiceoverGeneration);
  yield takeEvery(GENERATE_SCRIPT, trackGenerateScript);
  yield takeEvery(SAVE_GENERATED_SCRIPT, trackSaveGeneratedScript);
  yield takeEvery(TRY_NEW_SCRIPT_PROMPT, trackTryNewScriptPrompt);
  yield takeEvery(COPY_SCRIPT_TO_CLIPBOARD, trackCopyScriptToClipboard);
  yield takeEvery(SUBMIT_IMAGE_FEEDBACK, submitImageFeedback);
  yield takeEvery(SUBMIT_SCRIPT_FEEDBACK, submitScriptFeedback);
  yield takeEvery(SUBMIT_VOICEOVER_FEEDBACK, submitVoiceoverFeedback);
}
