import { useState, useCallback, useEffect, RefObject } from 'react';

const useIsElementsSameWidth = (
  firstElement: RefObject<HTMLElement> | null,
  secondElement: RefObject<HTMLElement> | null,
  initialState = false,
  threshold = 0
) => {
  const [isSameWidth, setIsSameWidth] = useState(initialState);

  const updateWidth = useCallback(() => {
    if (!firstElement?.current || !secondElement?.current) return;
    const firstElementWidth = firstElement?.current.getBoundingClientRect().width;
    const secondElementWidth = secondElement?.current.getBoundingClientRect().width;
    setIsSameWidth(Math.abs(firstElementWidth - secondElementWidth) <= threshold);
  }, [firstElement, secondElement, threshold]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [updateWidth]);

  return isSameWidth;
};

export default useIsElementsSameWidth;
