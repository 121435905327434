import { AUTH_SUCCESS, AuthSuccessAction } from 'js/actionCreators/authActions';
import {
  ABORT_DOWNLOAD,
  DOWNLOAD_REQUEST_FAILED,
  GET_VIDEO_DOWNLOAD_URL_FAILED,
  GET_VIDEO_DOWNLOAD_URL_SUCCESS
} from 'js/actionCreators/downloadActions';
import { DownloadRequestFailedAction } from 'js/actionCreators/downloadRequestFailedAction';
import {
  DOWNLOAD_REQUEST_SUCCESS,
  DownloadRequestSuccessAction,
  downloadRequestSuccess
} from 'js/actionCreators/downloadRequestSuccessAction';
import { GetVideoDownloadUrlFailedAction } from 'js/actionCreators/getVideoDownloadUrlFailedAction';
import { GetVideoDownloadUrlSuccessAction } from 'js/actionCreators/getVideoDownloadUrlSuccessAction';
import { RenderFailedAction, RENDER_FAILED } from 'js/actionCreators/renderFailedAction';
import { showCallout } from 'js/actionCreators/uiActions';
import { ROUTE_CODES } from 'js/config/routes';
import ScribeModel from 'js/models/ScribeModel';
import getRendererErrorMessage from 'js/shared/helpers/getRendererErrorMessage';
import { RootState, AccountContentKey } from 'js/types';
import { SagaIterator } from 'redux-saga';
import { takeEvery, call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { NEXT_ACTION_TYPES } from 'js/shared/components/Callout/consts';

import { yieldUserWithPermission } from './sagaHelpers/yieldUserWithPermission';
import { getScribeById } from './selectors';

type DownloadRequestSuccessActionWithUserId = DownloadRequestSuccessAction & { userId: string };

export const DOWNLOAD_MANAGER_STORAGE_KEY = 'vscCurrentDownload';
export const selectUserId = (state: RootState) => state.auth.currentUser.id;
export const selectCurrentRoutePath = (state: RootState) => state.router.location?.pathname;
export const storeSetContext = { fn: localStorage.setItem, context: localStorage };
export const storeClearContext = { fn: localStorage.removeItem, context: localStorage };
export const storeGetContext = { fn: localStorage.getItem, context: localStorage };

export function* persistCurrentDownload(
  action: DownloadRequestSuccessAction
): Generator<SelectEffect | CallEffect, void, string | undefined> {
  const userId = yield select(selectUserId);
  yield call(
    storeSetContext,
    DOWNLOAD_MANAGER_STORAGE_KEY,
    JSON.stringify({ ...action, userId } as DownloadRequestSuccessActionWithUserId)
  );
}

export function* clearPersistedDownload() {
  yield call(storeClearContext, DOWNLOAD_MANAGER_STORAGE_KEY);
}

export function* initialiseDownloadManager({
  revalidation
}: AuthSuccessAction): Generator<
  CallEffect<string | null> | PutEffect<DownloadRequestSuccessAction> | SelectEffect | CallEffect<void>,
  void,
  string | null
> {
  if (!revalidation) {
    const currentDownloadString = yield call(storeGetContext, DOWNLOAD_MANAGER_STORAGE_KEY);

    if (currentDownloadString) {
      try {
        const { userId, ...currentDownload } = JSON.parse(
          currentDownloadString
        ) as DownloadRequestSuccessActionWithUserId;
        const currentUserId = yield select(selectUserId);
        if (currentUserId === userId) {
          yield put(downloadRequestSuccess(currentDownload));
        } else {
          yield call(clearPersistedDownload);
        }
      } catch (error) {
        console.error('Error reading JSON from persisted download', error);
      }
    }
  }
}

export function* notifyUserOfSuccessfulDownload(action: GetVideoDownloadUrlSuccessAction): SagaIterator {
  const currentRoute: string = yield select(selectCurrentRoutePath);

  if (!currentRoute.includes(ROUTE_CODES.DOWNLOAD)) {
    const scribe: ScribeModel | undefined = yield select(getScribeById, action.scribeId);
    const { getContent } = yield call(yieldUserWithPermission);

    // In theory the user could have deleted the project before the download completes so we need to take that into account
    const title = scribe?.title ? `"${scribe.title}"` : `id ${action.scribeId}`;

    const upsellMsg = getContent(AccountContentKey.DOWNLOAD_UPSELL_MSG);

    yield put(
      showCallout({
        title: `Your download for project ${title} is complete.`,
        details: `Check your downloads folder for the file. ${upsellMsg}`,
        ...(upsellMsg
          ? {
              callToAction: {
                type: NEXT_ACTION_TYPES.DOWNLOAD_SUCCESS_UPSELL_ACTION,
                label: `${getContent(AccountContentKey.ACCOUNT_NEXT_ACTION)} here`
              }
            }
          : {}),
        autoDismiss: false
      })
    );
  }
}

export function* notifyUserOfFailedDownload(
  action: RenderFailedAction | GetVideoDownloadUrlFailedAction | DownloadRequestFailedAction
): SagaIterator {
  const currentRoute: string = yield select(selectCurrentRoutePath);

  if (!currentRoute.includes(ROUTE_CODES.DOWNLOAD)) {
    const scribe: ScribeModel | undefined = yield select(getScribeById, action.scribeId);
    // In theory the user could have deleted the project before the download completes so we need to take that into account
    const title = scribe?.title ? `"${scribe.title}"` : `id ${action.scribeId}`;

    const errorMessage = getRendererErrorMessage(
      action.error,
      'Retry your download by clicking the "Download" button in the editor.'
    );

    yield put(
      showCallout({
        title: `Uh-oh! We've hit a roadblock. Your download for project ${title} failed.`,
        details: errorMessage,
        isError: true,
        autoDismiss: false
      })
    );
  }
}

export default function* downloadManagerSagas() {
  yield takeEvery(DOWNLOAD_REQUEST_SUCCESS, persistCurrentDownload);
  yield takeEvery(
    [
      RENDER_FAILED,
      GET_VIDEO_DOWNLOAD_URL_FAILED,
      DOWNLOAD_REQUEST_FAILED,
      GET_VIDEO_DOWNLOAD_URL_SUCCESS,
      ABORT_DOWNLOAD
    ],
    clearPersistedDownload
  );
  yield takeEvery(AUTH_SUCCESS, initialiseDownloadManager);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, notifyUserOfSuccessfulDownload);
  yield takeEvery([RENDER_FAILED, GET_VIDEO_DOWNLOAD_URL_FAILED, DOWNLOAD_REQUEST_FAILED], notifyUserOfFailedDownload);
}
