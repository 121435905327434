import React from 'react';

const IconSceneBounce = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="90px"
    height="60px"
    viewBox="0 0 90 60"
    enableBackground="new 0 0 90 60"
    fill="currentcolor"
  >
    <path
      d="M9.2,8v15.7c0,2.5,2,4.6,4.6,4.6h24.6c-0.9,0.3-1.6,1.1-1.6,2.2c0,1.2,1,2.3,2.3,2.3h5.8l-7.1,6c-0.7,0.6-1,1.6-0.7,2.5
      c0.3,0.9,1.2,1.5,2.1,1.5h5.7l-7.4,6.2c-0.7,0.6-1,1.6-0.7,2.5c0.3,0.9,1.2,1.5,2.1,1.5h12.1c1.2,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3
      h-5.9l7.4-6.2c0.7-0.6,1-1.6,0.7-2.5C52.9,38.6,52,38,51.1,38h-5.7l7.1-6c0.7-0.6,1-1.6,0.7-2.5c-0.2-0.7-0.8-1.2-1.4-1.4h24.8
      c2.5,0,4.6-2,4.6-4.6V8H9.2z"
    />
  </svg>
);

export default IconSceneBounce;
