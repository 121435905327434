import { CallEffect, PutEffect, SelectEffect, call, put, select, takeEvery } from 'redux-saga/effects';
import cloneDeep from 'lodash.clonedeep';
import {
  CONFIRM_AUDIO_SELECTION,
  ConfirmAudioSelectionAction,
  SAVE_AUDIO_ASSET_METADATA,
  SAVE_AUDIO_TO_LIBRARY,
  SaveAudioAssetMetadataAction,
  SaveAudioToLibraryAction,
  USE_AUDIO_ASSET_IN_PROJECT,
  UseAudioAssetInProjectAction,
  saveAudioAssetMetadataFailed,
  saveAudioAssetMetadataSuccess,
  saveAudioToLibraryFailed,
  saveAudioToLibraryInProgress,
  saveAudioToLibrarySuccess,
  useAudioAssetInProject
} from 'js/actionCreators/audioActions';
import { showError } from 'js/actionCreators/uiActions';
import { ALLOWED_AUDIO_MIME_TYPE } from 'js/config/config';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import getAudioFileDuration from 'js/shared/helpers/getAudioFileDuration';
import {
  AudioSource,
  ExistingScribeModel,
  RootState,
  VSCAssetAudioData,
  AudioState,
  AudioClip,
  ScribeAudioLayerModelProps,
  AdvancedAudioType
} from 'js/types';
import { setSelectedAudioClip } from 'js/actionCreators/scribeActions';
import { editorSetEditPanelMode, toggleNewTimeline } from 'js/actionCreators/editorActions';
import uuid from 'uuid';
import { ScribeAudioLayerModel } from 'js/models/ScribeAudioLayerModel';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { getAudioAssetDuration } from 'js/shared/helpers/getAudioAssetDuration';

import { getProjectById, isAdvancedTimelineOpen } from './selectors';
import { useAudioAssetInProjectSuccessSaga } from './useAudioAssetInProjectSuccessSaga';

function* saveAudioToLibrary({
  file,
  source
}: SaveAudioToLibraryAction): Generator<PutEffect | CallEffect, void | string, number | VSCAssetAudioData> {
  if (file.type !== ALLOWED_AUDIO_MIME_TYPE) {
    yield put(
      showError({
        message: `Error uploading your audio file. Type ${file.type} is not supported`,
        description: `Supported audio file format is ${ALLOWED_AUDIO_MIME_TYPE}`
      })
    );
    yield put(saveAudioToLibraryFailed(new Error('Audio Upload file error. Type not supported ' + file.type)));
    return;
  }

  try {
    /* 
      Creating an audio asset needs to be done in two parts. Firstly the project asset
      needs to be created in services, then we can set the audio duration metadata as a second API call
    */
    const abortController = new AbortController();
    const signal = abortController.signal;

    yield put(saveAudioToLibraryInProgress(abortController));
    const assetId = yield call(appServices.uploadAsset, { file, filename: file.name, source }, signal);

    const audioDurationSeconds = yield call(getAudioFileDuration, file);
    const assetData = yield call(appServices.updateAssetMetadata, assetId, {
      audioDurationSeconds
    });

    if (typeof assetId === 'number' && typeof assetData !== 'number' && typeof audioDurationSeconds === 'number') {
      yield put(saveAudioToLibrarySuccess(assetData, source));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(saveAudioToLibraryFailed(error));
      yield put(
        showError({
          message: `There was an issue uploading your audio file`,
          description: error.message
        })
      );
      return;
    }
  }
}

function* saveAudioAssetMetadata({
  data,
  assetId,
  useItInProjectId,
  source
}: SaveAudioAssetMetadataAction): Generator<CallEffect | PutEffect, void, VSCAssetAudioData> {
  try {
    const assetData = yield call(appServices.updateAssetMetadata, assetId, data);
    yield put(saveAudioAssetMetadataSuccess(assetData));
    if (useItInProjectId) {
      const eventTrigger =
        source === AudioSource.RECORDED || source === AudioSource.UPLOADED ? 'Your Files' : undefined;
      yield put(useAudioAssetInProject(useItInProjectId, assetData, data.audioType ?? 'project', eventTrigger));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(saveAudioAssetMetadataFailed(error));

      yield put(
        showError({
          message: `There was an issue updating your audio file`,
          description: error.message
        })
      );
      return;
    }
  }
}

export function* addOrReplaceAudioAssetInProject(
  asset: VSCAssetAudioData,
  project: ExistingScribeModel,
  assetToReplaceId?: string,
  audioType?: AdvancedAudioType
): Generator<PutEffect | CallEffect | ReturnType<typeof useAudioAssetInProjectSuccessSaga>, void, number> {
  const audioFileDuration = yield call(getAudioAssetDuration, asset.projectAssetId);

  const audioConfig: AudioClip = {
    id: uuid(),
    assetId: asset.projectAssetId,
    volume: 1,
    source: AudioSource.UPLOADED,
    fadeOutDurationSeconds: 0,
    filename: asset.filename ?? '',
    type: audioType ?? 'project',
    duration: audioFileDuration,
    instanceDuration: audioFileDuration
  };
  const clonedProject = cloneDeep(project);

  let audioLayer: ScribeAudioLayerModelProps | undefined = undefined;
  const clipId = audioConfig.id ?? '';
  const currentScene = getActiveScene(clonedProject);

  audioConfig.duration = audioFileDuration;
  audioConfig.instanceDuration = audioFileDuration;
  audioConfig.startOffset = 0;
  audioConfig.startTime = 0;
  audioConfig.id = audioConfig.id ?? uuid(); // this is just to keep the compiler happy it should be set above

  if (!assetToReplaceId) {
    audioLayer = {
      id: uuid(),
      audioClipIds: [clipId]
    };
  }

  if (assetToReplaceId) {
    if (clonedProject.audioClips) {
      const replacementIndex = clonedProject.audioClips.findIndex(clip => clip.id === assetToReplaceId);

      if (replacementIndex !== -1) {
        const outgoingClip = clonedProject.audioClips[replacementIndex];
        const existingLayer = clonedProject.audioLayers?.find(layer => layer.audioClipIds.includes(outgoingClip.id));

        if (existingLayer) {
          audioConfig.startTime = outgoingClip.startTime;

          const currentLayerClipsSortedByStartTime = existingLayer
            .getAudioClips(clonedProject)
            .sort((a, b) => (a.startTime ?? 0) - (b.startTime ?? 0));
          const currentLayerOrderedIndex = currentLayerClipsSortedByStartTime.findIndex(
            clip => clip.id === outgoingClip.id
          );
          const nextClip =
            currentLayerOrderedIndex !== -1
              ? currentLayerClipsSortedByStartTime[currentLayerOrderedIndex + 1]
              : undefined;

          let replacementCanFitInExistingSpace = false;
          if (!nextClip) {
            replacementCanFitInExistingSpace = true;
          } else {
            const availableSpace = nextClip.startTime ? nextClip.startTime - (outgoingClip.startTime ?? 0) : 0;
            replacementCanFitInExistingSpace = availableSpace >= (audioConfig.instanceDuration ?? 0);
          }

          // Replace the audio clip
          clonedProject.audioClips[replacementIndex] = audioConfig;

          if (replacementCanFitInExistingSpace) {
            const existingIndex = existingLayer.audioClipIds.findIndex(id => id === outgoingClip.id);
            if (existingIndex !== -1) {
              existingLayer.audioClipIds[existingIndex] = audioConfig.id;
            }
          } else {
            existingLayer.audioClipIds = existingLayer.audioClipIds.filter(id => id !== outgoingClip.id);
            audioLayer = {
              id: uuid(),
              audioClipIds: [audioConfig.id]
            };
          }
        }
      }
    }
  } else if (clonedProject.audioClips) {
    clonedProject.audioClips.push(audioConfig);
  } else {
    clonedProject.audioClips = [audioConfig];
  }

  if (audioLayer !== undefined) {
    if (audioType === 'project' || !audioType) {
      if (!clonedProject.projectAudioLayerIds) {
        clonedProject.projectAudioLayerIds = [];
      }
      clonedProject.projectAudioLayerIds.push(audioLayer.id);
    } else {
      if (currentScene) {
        if (currentScene && !currentScene.audioLayerIds) {
          currentScene.audioLayerIds = [];
        }
        currentScene.audioLayerIds?.push(audioLayer.id);
      }
    }

    if (!clonedProject.audioLayers) clonedProject.audioLayers = [];
    if (clonedProject.audioLayers) clonedProject.audioLayers.push(new ScribeAudioLayerModel(audioLayer));
  }

  yield useAudioAssetInProjectSuccessSaga(clonedProject, undefined, audioConfig.id);
  yield put(setSelectedAudioClip(audioConfig));
  yield put(editorSetEditPanelMode('audio'));
}

export const getAudioState = ({ audio }: RootState) => audio;

export function* useAudioInProject({
  projectId,
  asset,
  audioType
}: UseAudioAssetInProjectAction): Generator<
  SelectEffect | CallEffect | PutEffect | ReturnType<typeof addOrReplaceAudioAssetInProject>,
  void,
  ExistingScribeModel | undefined | AudioState | boolean
> {
  const isTimelineCurrentlyOpen = yield select(isAdvancedTimelineOpen);
  if (!isTimelineCurrentlyOpen) yield put(toggleNewTimeline());

  const project = (yield select(getProjectById, projectId)) as ExistingScribeModel | undefined;
  if (!project) return;

  const audioState = (yield select(getAudioState)) as AudioState;
  const isToReplaceExistingAudio = audioState.audioModalInitialAction === 'replace';

  if (isToReplaceExistingAudio) {
    const audioToBeReplaced = audioState.selectedAudioClip;
    if (!audioToBeReplaced) return;
    yield call(
      addOrReplaceAudioAssetInProject,
      asset,
      project,
      audioToBeReplaced.id,
      audioToBeReplaced.type as AdvancedAudioType
    );
  } else {
    yield call(addOrReplaceAudioAssetInProject, asset, project, undefined, audioType);
  }
}

function* updateAudioSelection({
  projectId,
  audioClipIdsToKeep
}: ConfirmAudioSelectionAction): Generator<
  | SelectEffect
  | ReturnType<typeof addOrReplaceAudioAssetInProject>
  | ReturnType<typeof useAudioAssetInProjectSuccessSaga>,
  void,
  ExistingScribeModel | undefined | VSCAssetAudioData
> {
  const project = yield select(getProjectById, projectId);
  const incomingAudio = yield select(({ audioUpload }: RootState) => audioUpload.incomingAudioAsset);
  if (!project || !incomingAudio) return;

  if ('id' in project && 'projectAssetId' in incomingAudio) {
    const clonedProject = cloneDeep(project);

    clonedProject.audioClips =
      clonedProject.audioClips?.filter(clip => audioClipIdsToKeep.includes(clip.assetId)) ?? [];

    if (audioClipIdsToKeep.includes(incomingAudio.projectAssetId)) {
      yield addOrReplaceAudioAssetInProject(incomingAudio, clonedProject);
    } else {
      yield useAudioAssetInProjectSuccessSaga(clonedProject, incomingAudio.projectAssetId);
    }
  }
}

export default function* audioLibraryUploadSagas() {
  yield takeEvery(SAVE_AUDIO_TO_LIBRARY, saveAudioToLibrary);
  yield takeEvery(SAVE_AUDIO_ASSET_METADATA, saveAudioAssetMetadata);
  yield takeEvery(USE_AUDIO_ASSET_IN_PROJECT, useAudioInProject);
  yield takeEvery(CONFIRM_AUDIO_SELECTION, updateAudioSelection);
}
