import {
  getStartTime,
  getElementEntranceAnimationTimeMs,
  getElementPauseTimeMs,
  getSceneIndexFromID
} from 'js/playback/lib/Playback/helpers/timings';
import {
  ElementAnimationStageDurationKey,
  ExistingScribeModel,
  PlaybackImageResources,
  ScribeCameraElement,
  VSImageShapeOrTextElementModel
} from 'js/types';
import * as PIXI from 'pixi.js';
import SceneTransitionElementModel from 'js/models/SceneTransitionElementModel';

import TimelineWrapper from './TimelineWrapper';
import { LAYER_CLICKED, LAYER_DOUBLE_CLICKED, LAYER_MOVE_BEGUN, LAYER_MOVE_ENDED } from './TimelineControlBus';
import { DRAG_DELAY_TIMEOUT } from './consts';
import TimelineLayerCameraThumbnail from './TimelineLayerCameraThumbnail';
import TimelineLayerThumbnail from './TimelineLayerThumbnail';
import TimelineElementGrabHandle from './TimelineElementGrabHandle';
export interface TimelineBaseLayerConfig {
  elementModel: ScribeCameraElement | SceneTransitionElementModel | VSImageShapeOrTextElementModel;
  sceneID: string;
  scribe: ExistingScribeModel;
  sceneStartTime: number;
  isActive: boolean;
  wrapperRef: TimelineWrapper;
  imageResources: PlaybackImageResources;
  currentSceneLength: number;
}
export default abstract class LayerBase extends PIXI.Container {
  public elementModel: VSImageShapeOrTextElementModel | SceneTransitionElementModel | ScribeCameraElement;
  public id: string;
  public startTime: number;
  public sceneStartTime: number;
  public endTime: number;
  currentSceneLength: number;
  sceneID: string;
  animationTime: number;
  pauseTime: number;
  imageResources: PlaybackImageResources;
  dragTimeoutId = 0;
  currentDraggedPos?: PIXI.Point;
  backgroundGraphics: PIXI.Graphics;
  foregroundGraphics: PIXI.Graphics;
  foregroundSprites: PIXI.Sprite;
  continuationGraphics = new PIXI.Graphics();
  overlay: PIXI.Graphics;
  doubleClickTimeout: Date = new Date();
  thumb?: TimelineLayerThumbnail | TimelineLayerCameraThumbnail;
  handles: Array<TimelineElementGrabHandle> = [];
  timeTypes: Array<ElementAnimationStageDurationKey> = [];
  wrapperRef: TimelineWrapper;
  isActive?: boolean;
  disableDrag = false;
  movedDuringLayerSwap = false;

  constructor({
    elementModel,
    sceneID,
    scribe,
    sceneStartTime,
    currentSceneLength,
    isActive,
    wrapperRef,
    imageResources
  }: TimelineBaseLayerConfig) {
    super();
    this.imageResources = imageResources;
    this.elementModel = elementModel;
    this.id = this.elementModel.id;
    this.sceneID = sceneID;
    this.sceneStartTime = sceneStartTime;
    if (this.elementModel.type === 'SceneTransition') {
      this.startTime = this.sceneStartTime;
      this.endTime = this.sceneStartTime + this.elementModel.sceneTransitionTime;
      this.animationTime = this.elementModel.sceneTransitionTime;
      this.pauseTime = 0;
    } else {
      this.startTime = this.endTime =
        Math.round(getStartTime(scribe, elementModel.id, getSceneIndexFromID(sceneID, scribe))) / 1000;
      this.animationTime = Math.round(getElementEntranceAnimationTimeMs(elementModel, scribe)) / 1000;
      this.pauseTime = Math.round(getElementPauseTimeMs(elementModel, scribe)) / 1000;
    }
    this.currentSceneLength = currentSceneLength;
    this.foregroundGraphics = new PIXI.Graphics();
    this.foregroundSprites = new PIXI.Sprite();
    this.overlay = new PIXI.Graphics();
    this.backgroundGraphics = new PIXI.Graphics();

    this.handles = [];
    this.wrapperRef = wrapperRef;
    this.isActive = isActive;
  }
  createThumbnail(): TimelineLayerCameraThumbnail | TimelineLayerThumbnail {
    throw new Error('Method not implemented.');
  }
  get pixelsPerSecond() {
    return this.wrapperRef.currentZoom.x;
  }
  get currentZoom() {
    return this.wrapperRef.currentZoom;
  }
  get layerHeight() {
    return this.wrapperRef.currentZoom.y;
  }
  get scribe() {
    return this.wrapperRef.props.activeScribe;
  }
  public onDragStart = (event: PIXI.InteractionEvent) => {
    if (this.wrapperRef.dragging) return;
    this.wrapperRef.dragging = true;

    window.removeEventListener('mouseup', this.onDragEnd);
    window.addEventListener('mouseup', this.onDragEnd);

    if (event.data.button === 0 && !event.data.originalEvent.ctrlKey) {
      if (!this.disableDrag) {
        if (this.dragTimeoutId) window.clearTimeout(this.dragTimeoutId);
        this.dragTimeoutId = window.setTimeout(() => {
          this.currentDraggedPos = this.parent.toLocal(event.data.global);
          this.overlay.beginFill(0x0000aa, 0.2);
          this.overlay.drawRect(
            this.sceneStartTime * this.pixelsPerSecond,
            1,
            this.currentSceneLength * this.pixelsPerSecond,
            this.layerHeight
          );
          this.overlay.endFill();
          this.drawForegroundGraphics();
          this.emit(LAYER_CLICKED, this.id, event);
          this.emit(LAYER_MOVE_BEGUN, this, event);
          if (this.thumb) this.thumb.visible = false;
        }, DRAG_DELAY_TIMEOUT);
      }
      const clickTime = new Date();
      if (clickTime.getTime() - this.doubleClickTimeout.getTime() < 350) {
        this.emit(LAYER_DOUBLE_CLICKED, this.id, event);
      } else {
        this.doubleClickTimeout = clickTime;
      }
    } else if (event.data.button === 2 || (event.data.originalEvent.ctrlKey && event.data.button === 0)) {
      this.handleRightClick(event);
    }
  };

  public onDragEnd = (event: PIXI.InteractionEvent | MouseEvent) => {
    window.removeEventListener('mouseup', this.onDragEnd);

    if (!(event instanceof MouseEvent)) {
      event.data.originalEvent.preventDefault();
    }
    window.clearTimeout(this.dragTimeoutId);

    if (this.wrapperRef.dragging) {
      this.wrapperRef.dragging = false;
      this.drawForegroundGraphics();
      this.overlay.clear();
      if (this.thumb) this.thumb.visible = true;
      this.emit(LAYER_MOVE_ENDED, this);
    }
  };

  select() {
    this.updateIsActive(true);
  }

  deselect() {
    this.updateIsActive(false);
  }

  protected handleRightClick = (_event: PIXI.InteractionEvent) => {};
  protected updateIsActive(_isActive: boolean) {}

  getStartX(): number {
    return this.startTime * this.pixelsPerSecond;
  }

  getEndX(): number {
    return this.endTime * this.pixelsPerSecond;
  }

  public setY(value: number) {
    this.y = value;
  }
  protected drawForegroundGraphics() {}
  protected drawBackgroundGraphics() {}

  public updateZoom() {
    this.handles?.forEach(handle => {
      handle.updateZoom();
    });
    if (this.thumb) this.thumb.updateZoom();
    this.redraw();
  }

  protected drawElementTimeOnStage(): void {
    this.drawBackgroundGraphics();
    this.drawForegroundGraphics();
    this.overlay.hitArea = new PIXI.Rectangle(0, 1, this.currentSceneLength * this.pixelsPerSecond, this.layerHeight);
  }
  public getGlobalStartPosition() {
    const global = this.foregroundGraphics.toGlobal(this.wrapperRef.app.stage);
    return new PIXI.Point(global.x + this.getStartX(), global.y);
  }
  public redraw() {}
}
