import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import getScribeLengthMs from 'js/playback/lib/Playback/helpers/getScribeLengthMs';
import getFileTypeFromFormat from 'js/shared/helpers/getFileTypeFromFormat';
import {
  DownloadTrackingAudioProperties,
  DownloadTrackingProperties,
  DownloadTrackingTemplateProperties,
  ExistingScribeModel,
  TemplateState,
  VSElementModel
} from 'js/types';
import { getSortedAndUniqueAudioAssetIds } from 'js/shared/helpers/audioAssets';
import { getAudioAssetsSize } from 'js/shared/helpers/projectLimits';

import { getTemplateCategory } from './getTemplateCategory';
import getTextElementsFontData from './getTextElementsFontData';

const fetchAllImageElementSizes = async (elements: Array<VSElementModel>): Promise<number> => {
  let totalSize = 0;

  for (const element of elements) {
    if (element.type !== 'Image') {
      continue;
    }

    if (!element?._imageUrl) {
      continue;
    }

    const { size } = await fetch(element._imageUrl)
      .then(r => r.blob())
      .catch(() => {
        return { size: 0 };
      });

    totalSize += size;
  }

  return totalSize;
};

const assertTextElements = (element: VSElementModel): element is ScribeTextElementModel => element.type === 'Text';

const getDownloadTrackingAudioProperties = async (
  project: ExistingScribeModel
): Promise<DownloadTrackingAudioProperties> => {
  const audioClips = project.audioClips || [];
  const projectClips = audioClips.filter(clip => clip.type === 'project');
  const sceneClips = audioClips.filter(clip => clip.type === 'scene');

  const uniqueAssetIds = getSortedAndUniqueAudioAssetIds(project);

  let totalSizes = 0;
  try {
    totalSizes = await getAudioAssetsSize(uniqueAssetIds);
  } catch (error) {
    console.error('Error calculating asset sizes', error);
  }

  return {
    'Number of Project Audio Clips': projectClips.length,
    'Number of Scene Audio Clips': sceneClips.length,
    'Total Number of Audio Clips': projectClips.length + sceneClips.length,
    'Total Audio Clips Asset Size': totalSizes
  };
};

export default async function getDownloadTrackingProperties(
  scribe: ExistingScribeModel,
  format: string,
  templateState: TemplateState
): Promise<DownloadTrackingProperties> {
  const numberOfElements = scribe.elements?.length || 0;
  const numberOfScenes = scribe.scenes?.length || 0;
  const scribeLengthSeconds = getScribeLengthMs(scribe) / 1000;
  const fileType = getFileTypeFromFormat(format);
  const { source } = scribe;
  const templateProperties: DownloadTrackingTemplateProperties = {};

  if (source?.type === 'template') {
    const templateCategory = getTemplateCategory(templateState, source?.meta?.templateId);

    templateProperties['Scribe Template ID'] = source.meta?.templateId;
    templateProperties['Scribe Template Title'] = source.meta?.title;
    templateProperties['Template Category'] = templateCategory;
  }

  const totalSizeOfImages = await fetchAllImageElementSizes(scribe.elements);

  const scribeTextElements = scribe.elements.filter(assertTextElements);
  const scribeCharTotal = scribeTextElements.reduce(
    (charTotal, element) => charTotal + (element?.text?.length || 0),
    0
  );
  const { fontFaces, totalFontFaces } = getTextElementsFontData(scribeTextElements);
  const audioProperties = await getDownloadTrackingAudioProperties(scribe);
  const eventProperties = {
    'Scribe ID': scribe.id,
    'File Type': fileType,
    'Scribe Length': scribeLengthSeconds,
    'Number of Scenes': numberOfScenes,
    'Number of Elements': numberOfElements,
    'Number of Text Boxes': scribeTextElements.length,
    'Total Number of Characters': scribeCharTotal,
    'Scribe Type': source?.type || 'unknown',
    'Total Image Asset Size': totalSizeOfImages,
    'Font Faces Used': fontFaces,
    'Total Number of Font Faces': totalFontFaces,
    ...templateProperties,
    ...audioProperties
  };

  return eventProperties;
}
