import * as PIXI from 'pixi.js';
import IconPadlockLocked from 'imgs/pixi_icons/IconPadlockLocked.svg';

import TimelineHelpers from './Utils/TimelineHelpers';
export default class ThumbnailLockedIcon extends PIXI.Container {
  public lockIcon;

  constructor() {
    super();
    this.lockIcon = new PIXI.Sprite(TimelineHelpers.importSVG(IconPadlockLocked));
    if (this.lockIcon.texture.baseTexture.valid) {
      this.draw();
    } else {
      this.lockIcon.texture.once('update', () => {
        this.draw();
      });
    }
  }

  draw = () => {
    const graphics = new PIXI.Graphics();
    graphics.lineStyle(1, 0xffffff);
    graphics.beginFill(0x000000);
    graphics.drawRoundedRect(0, 0, 12, 12, 1);
    this.addChild(graphics);
    this.lockIcon.width = this.lockIcon.height = 10;
    this.lockIcon.x = this.lockIcon.y = 1;
    this.addChild(this.lockIcon);
    this.x = 2;
    this.y = 3;
  };
}
