import * as PIXI from 'pixi.js';

import {
  TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR,
  TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
  TIMELINE_LAYER_BACKGROUND_COLOUR
} from './TimelineWrapper';
import { LayerZoomConfig } from './TimelineControlBus';
import { ELEMENT_LAYER_PADDING } from './consts';
import TimelineAudioWaveform from './TimelineAudioWaveform';
import TimelineWrapper, { SCROLL_BOX_MARGIN } from './TimelineWrapper';
import { LEFT_ZOOM_MARGIN } from './consts';

export interface TimelineAudioLayerConfig {
  sceneStartTime: number;
  scribeLength: number;
  currentZoom: PIXI.Point;
  windowRef: PIXI.Rectangle;
  waveform: TimelineAudioWaveform;
  layerIndex: number;
  audioType: string;
  wrapperRef: TimelineWrapper;
  assetId: string;
  isActive: boolean;
  id: string;
}

export default class TimelineAudioLayerProject extends PIXI.Container {
  currentZoom: PIXI.Point;
  sceneStartTime: number;
  scribeLength: number;
  layerIndex: number;
  backgroundGraphics: PIXI.Graphics;
  maskGraphics: PIXI.Graphics;
  foregroundGraphics: PIXI.Graphics;
  foregroundSprites: PIXI.Sprite;
  activeElementOutline: PIXI.Graphics;
  assetId: string;
  waveform: TimelineAudioWaveform;
  audioType: string;
  windowRef: PIXI.Rectangle;
  maskGutter = 65;
  wrapperRef: TimelineWrapper;
  isActive: boolean;
  id: string;

  constructor({
    sceneStartTime,
    scribeLength,
    currentZoom,
    windowRef,
    waveform,
    layerIndex,
    audioType,
    wrapperRef,
    assetId,
    isActive,
    id
  }: TimelineAudioLayerConfig) {
    super();
    this.layerIndex = layerIndex;
    this.windowRef = windowRef;
    this.currentZoom = currentZoom;
    this.sceneStartTime = sceneStartTime;
    this.scribeLength = scribeLength;
    this.waveform = waveform;
    this.assetId = assetId;
    this.id = id;
    this.audioType = audioType;
    this.wrapperRef = wrapperRef;
    this.foregroundGraphics = new PIXI.Graphics();
    this.backgroundGraphics = new PIXI.Graphics();
    this.maskGraphics = new PIXI.Graphics();
    this.foregroundSprites = new PIXI.Sprite();
    this.addChild(this.backgroundGraphics);
    this.isActive = isActive;
    this.foregroundSprites.addChild(this.foregroundGraphics);
    this.addChild(this.foregroundSprites);
    this.activeElementOutline = new PIXI.Graphics();
    this.interactive = this.buttonMode = true;
    this.drawLayers();
    this.positionWaveform(this.wrapperRef.topMeasure?.x ?? 0);
  }

  select() {
    this.isActive = true;
    this.drawForeground();
  }

  deselect() {
    this.isActive = false;
    this.drawForeground();
  }

  drawBackground() {
    if (this.wrapperRef.scrollBox) {
      this.backgroundGraphics.clear();
      this.backgroundGraphics.beginFill(0x000000);
      this.backgroundGraphics.drawRect(
        0,
        0,
        this.wrapperRef.scrollBox?.boxWidth,
        this.currentZoom.y + ELEMENT_LAYER_PADDING
      );

      this.backgroundGraphics.beginFill(TIMELINE_LAYER_BACKGROUND_COLOUR, 1);
      this.backgroundGraphics.drawRect(0, 0, this.wrapperRef.scrollBox?.boxWidth, this.currentZoom.y);
      this.backgroundGraphics.endFill();
    }

    this.maskGraphics.clear();
    this.maskGraphics.beginFill(TIMELINE_LAYER_BACKGROUND_COLOUR, 1);

    if (this.wrapperRef.scrollBox) {
      this.maskGraphics.drawRect(0, 0, this.wrapperRef.scrollBox.boxWidth, this.currentZoom.y + ELEMENT_LAYER_PADDING);
      this.maskGraphics.endFill();
    }
  }

  positionWaveform(x: number) {
    const leftPadding = LEFT_ZOOM_MARGIN;
    if (this.waveform && this.waveform.container) {
      const oldWaveFormX = this.waveform.x;
      this.waveform.x = x - leftPadding;
      if (this.waveform.x !== oldWaveFormX) {
        if (this.backgroundGraphics) {
          this.drawLayers();
        }
      }
    }
  }

  drawForeground() {
    this.activeElementOutline.clear();
    this.addChild(this.waveform);
    this.addChild(this.maskGraphics);
    this.waveform.mask = this.maskGraphics;
    if (this.isActive && this.wrapperRef.scrollBox) {
      this.activeElementOutline.lineStyle({
        width: TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
        color: TIMELINE_AUDIO_LAYER_ACTIVE_OUTLINE_COLOUR,
        alignment: 0
      });
      this.activeElementOutline.drawRect(0, 0, this.wrapperRef.scrollBox?.boxWidth, this.currentZoom.y);
      this.addChild(this.activeElementOutline);
    }
  }

  drawLayers() {
    this.drawBackground();
    this.drawForeground();
  }

  updateCurrentZoom({ x, y }: LayerZoomConfig) {
    if (!this.currentZoom) {
      this.currentZoom = new PIXI.Point(x, y);
    }
    this.currentZoom.x = x as number;
    this.currentZoom.y = y as number;

    if (this.backgroundGraphics) {
      this.drawLayers();
    }
    this.y = this.windowRef.height - SCROLL_BOX_MARGIN - (this.layerIndex - 1) - this.layerIndex * this.currentZoom.y;
  }

  updateScribeLength(scribeLength: number) {
    this.scribeLength = scribeLength;

    this.drawBackground();
  }

  getStartX() {
    return 0;
  }
}
