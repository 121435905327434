import {
  USER_ACCEPTS_TERMS,
  needsToAcceptTerms,
  needsToAcceptTermsError,
  userAcceptsTermsFailed,
  userAcceptsTermsSuccess
} from 'js/actionCreators/acceptedTermsActions';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { call, put, takeLatest } from 'redux-saga/effects';

export const ERROR_MESSAGE =
  'There was an issue with our system accepting the terms. Please try again. If the problem persists, please contact support.';

export function* checkAcceptedTerms() {
  try {
    const { acceptedTerms } = yield call(appServices.getAcceptedTerms);
    yield put(needsToAcceptTerms(!acceptedTerms));
  } catch (error) {
    console.error('There was an issue fetching the users accepted terms', error);
    if (error instanceof Error) {
      yield put(needsToAcceptTermsError(error));
    }
  }
}

export function* sendAcceptedTerms() {
  try {
    yield call(appServices.sendAcceptedTerms);
    yield put(userAcceptsTermsSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        showError({
          message: ERROR_MESSAGE,
          description: error.message
        })
      );
      yield put(userAcceptsTermsFailed(error));
    }
  }
}

export default function* acceptedTermsSaga() {
  yield takeLatest(AUTH_SUCCESS, checkAcceptedTerms);
  yield takeLatest(USER_ACCEPTS_TERMS, sendAcceptedTerms);
}
