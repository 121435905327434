import { fabric } from 'fabric';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { ScribeCameraElement, ShapeElement, VSElementModel } from 'js/types';
import { EDITOR_CANVAS_CONTROL_VISIBILITY, EDITOR_CANVAS_SELECTION_OPTIONS } from 'js/config/defaults';
import ScribeElementModel from 'js/models/ScribeElementModel';
import { Rectangle } from 'pixi.js';

import TextElement from '../TextElement';
import ImageElement from '../ImageElement';
import BrokenImageElement from '../BrokenImageElement';
import SVGImageElement from '../SVGImageElement';
import CameraElement from '../CameraElement';
import ActiveSelection from '../ActiveSelection';

export type CanvasImageElement = ImageElement | SVGImageElement | BrokenImageElement;

export type CanvasElement = ViewableCanvasElement | CameraElement;
export type ViewableCanvasElement = TextElement | ShapeElement | CanvasImageElement;

export type CanvasElementOptions = {
  Shape: [ScribeShapeElementModel, ShapeElement];
  Image: [ScribeImageElementModel, CanvasImageElement];
  Text: [ScribeTextElementModel, TextElement];
  Camera: [ScribeCameraElement, CameraElement];
};

export const assertElementType = <K extends keyof CanvasElementOptions>(
  type: K,
  elementProps: VSElementModel
): elementProps is CanvasElementOptions[K][0] => {
  return elementProps.type === type;
};

export const assertCanvasElementType = <K extends keyof CanvasElementOptions>(
  type: K,
  canvasElement: CanvasElement
): canvasElement is CanvasElementOptions[K][1] => {
  return canvasElement.elementType === type;
};

export const isTextElement = (element: VSElementModel): element is ScribeTextElementModel => {
  return element.type === 'Text';
};

export const isShapeElement = (element: VSElementModel): element is ScribeShapeElementModel => {
  return element.type === 'Shape';
};

export const isImageElement = (element: VSElementModel): element is ScribeImageElementModel => {
  return element.type === 'Image';
};

export const isCameraElement = (element: VSElementModel): element is ScribeCameraElement => {
  return element.type === 'Camera';
};

export const assertIsElement = (object: fabric.Object | CanvasElement): object is CanvasElement => {
  return 'id' in object;
};

export const assertIsCameraCanvasElement = (object?: fabric.Object | null): object is CameraElement => {
  if (!object) return false;
  return assertIsElement(object) && assertCanvasElementType('Camera', object);
};

export const assertIsTextCanvasElement = (object?: fabric.Object | null): object is TextElement => {
  if (!object) return false;
  return assertIsElement(object) && assertCanvasElementType('Text', object);
};

export const assertIsActiveSelection = (object: fabric.Object): object is ActiveSelection => {
  return object.type === 'activeSelection';
};

export const getElementBoundaryControls = (element: ScribeElementModel) => {
  return element.unlockedRatio
    ? { ...EDITOR_CANVAS_CONTROL_VISIBILITY.objectUnlockedRatio, lockIndicator: element.locked }
    : { ...EDITOR_CANVAS_CONTROL_VISIBILITY.object, lockIndicator: element.locked };
};

export const applyBoundingBoxStyles = <T extends fabric.Object>(canvasElement: T, props: ScribeElementModel) => {
  canvasElement.set(EDITOR_CANVAS_SELECTION_OPTIONS);

  canvasElement.setControlsVisibility({
    ...getElementBoundaryControls(props),
    lockIndicator: props.locked
  });

  if (props.hidden === true) {
    canvasElement.setControlsVisibility({ ...EDITOR_CANVAS_CONTROL_VISIBILITY.hidden });
    canvasElement.borderColor = 'transparent';
  } else {
    canvasElement.borderColor = EDITOR_CANVAS_SELECTION_OPTIONS.borderColor;
  }
};

export const setGroupTransform = <T extends fabric.Object>(canvasElement: T): T | undefined => {
  if (canvasElement.group && canvasElement.group.type === 'activeSelection') {
    // if an element is part of a group then certain properties are set relative to the group
    // To get the absolute values we need to apply the groups transform matrix to the object, capture the
    // data we want, and then reinstate the original values

    const originalGroupValues = canvasElement.toObject(['id', 'hidden', 'locked']);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore types are incorrect `addTransformToObject` was introduced in v5
    fabric.util.addTransformToObject(canvasElement, canvasElement.group.calcTransformMatrix());
    return originalGroupValues;
  }

  return;
};

export const getAlignmentRectangleFromBounds = (boundingRects: Array<Rectangle>) => {
  const left = Math.min(...boundingRects.map(boundingRect => boundingRect.left));
  const right = Math.max(...boundingRects.map(boundingRect => boundingRect.right));
  const top = Math.min(...boundingRects.map(boundingRect => boundingRect.top));
  const bottom = Math.max(...boundingRects.map(boundingRect => boundingRect.bottom));
  const hCenter = (right - left) / 2 + left;
  const vCenter = (bottom - top) / 2 + top;

  return {
    top,
    right,
    bottom,
    left,
    hCenter,
    vCenter
  };
};
