import { RENDERER_RATE_LIMIT_ERROR_STRING } from 'js/config/consts';

export default function getRendererErrorMessage(
  error: Error,
  retryMessage = 'Please try downloading your project again.'
): string {
  let errorMessage;

  switch (error.message) {
    case 'PROJECT_EXCEEDS_MAX_LENGTH':
      errorMessage = 'We recommend you keep your project under 20 minutes.';
      break;
    case 'MERGE_VIDEO_ERROR':
    case 'MERGE_AUDIO_ERROR':
      errorMessage =
        "Your project appears to be quite large and we're having trouble rendering it. Try reducing the number or size of the images you're using.";
      break;
    case 'PROJECT_IS_STILL_UPLOADING_IMAGES':
      errorMessage =
        'Your project is still uploading your images to our system in the background. Please wait a moment and then refresh your browser before trying to download again.';
      break;
    default:
      errorMessage = retryMessage;
  }

  if (error.message.includes(RENDERER_RATE_LIMIT_ERROR_STRING)) {
    // TODO: Customise displayed number based on subscribed plan
    errorMessage = `You have reached the maximum number of download requests within 24 hours. \nYou can request to download your projects again in 24 hours.`;
  }

  return errorMessage;
}
