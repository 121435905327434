import config from 'js/config/config';
import { ACCESS_TOKEN_HEADER_KEY } from 'js/config/consts';

import { getRendererAuth, getSparkolToken } from '../../TokenHelper';

const { APPLICATION_NAME } = config;
const HTTP_SUCCESS_RANGE = {
  lower: 200,
  upper: 300
};

export function getServicesUrl() {
  const { APPLICATION_SERVICES_URL } = config;
  if (APPLICATION_SERVICES_URL.endsWith('/')) return APPLICATION_SERVICES_URL;
  else return `${APPLICATION_SERVICES_URL}/`;
}

export function isHttpFail(status: number) {
  return status >= HTTP_SUCCESS_RANGE.upper || status < HTTP_SUCCESS_RANGE.lower;
}

type AppServiceError = Error & {
  httpStatusCode?: number;
};

interface AppServiceResponse {
  status: number;
  text: () => Promise<string>;
  statusText?: string;
}

export async function validateResponse<T>(
  r: AppServiceResponse,
  customErrorMessage?: string
): Promise<T | AppServiceResponse> {
  return new Promise((resolve, reject) => {
    if (isHttpFail(r.status)) {
      if (customErrorMessage) {
        const appServicesError: AppServiceError = new Error(`${customErrorMessage} | Status: ${r.status}`);
        appServicesError.httpStatusCode = r.status;
        return reject(appServicesError);
      }

      r.text().then(errorText => {
        const appServicesError: AppServiceError = new Error(errorText || r.statusText);
        appServicesError.httpStatusCode = r.status;
        reject(appServicesError);
      });
    } else {
      resolve(r);
    }
  });
}

export async function getObject<T>(r: AppServiceResponse): Promise<T> {
  return r.text().then(JSON.parse);
}

interface RendererHeaders {
  'x-spk-renderer-auth'?: string;
  'x-spk-renderer-user'?: string;
  'x-spk-app-name'?: string;
  'x-spk-auth-token'?: string;
}

export function rendererHeaders(withAuthToken?: boolean) {
  const { rendererAuth, rendererUser } = getRendererAuth();
  const headers: RendererHeaders = {};

  if (!rendererAuth || !rendererUser) {
    return headers;
  }

  headers['x-spk-renderer-auth'] = rendererAuth;
  headers['x-spk-renderer-user'] = rendererUser;
  headers['x-spk-app-name'] = APPLICATION_NAME;

  if (withAuthToken) {
    headers['x-spk-auth-token'] = rendererAuth;
  }

  return headers;
}

interface GetOptionsRequestHeaders {
  Accept: string;
  'Content-Type'?: string;
  'x-spk-auth-token'?: string;
  'x-spk-app-name'?: string;
  'x-spk-access-token'?: string;
}

export function getOptions<T>(
  method = 'GET',
  body?: T,
  contentType?: string,
  skipAuth = false,
  cognitoAccessToken?: string
) {
  const headers: GetOptionsRequestHeaders = {
    Accept: 'application/json'
  };

  // If we have content, default to application/json content-type
  if ((body || contentType) && contentType !== '' && contentType !== 'auto') {
    headers['Content-Type'] = contentType || 'application/json';
  }

  const authToken = getSparkolToken();
  if (!skipAuth && authToken) {
    // If there is already an authtoken with the User then set the header
    headers['x-spk-auth-token'] = authToken;
    headers['x-spk-app-name'] = APPLICATION_NAME;
  } else {
    // don't send sparkol token when we request one
    delete headers['x-spk-auth-token'];
  }

  if (cognitoAccessToken) {
    headers[ACCESS_TOKEN_HEADER_KEY] = cognitoAccessToken;
  }

  return {
    method,
    headers: { ...headers, ...rendererHeaders() },
    body
  };
}
