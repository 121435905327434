import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'connected-react-router';

import { BASE_PATH } from '../../config/routes';
import { setSeverityLevel } from '../../logging';

const history = createHistory({ basename: BASE_PATH });

setSeverityLevel(history);

export const getHistory = () => history;

const routesMiddleware = () => routerMiddleware(history);

export default routesMiddleware;
