import { call, CallEffect, select, SelectEffect, take, takeEvery } from 'redux-saga/effects';
import {
  DELETE_SELECTED_SCENES,
  UPDATE_SCRIBE,
  CREATE_SCENE,
  DUPLICATE_SELECTED_SCENES,
  DeleteSelectedScenesAction,
  CreateSceneAction,
  DuplicateScenesAction
} from 'js/actionCreators/scribeActions';
import {
  SetSceneTransitionConfigAction,
  SetSceneTransitionDurationAction,
  SetSceneTransitionTypeAction,
  SET_SCENE_TRANSITION_CONFIG,
  SET_SCENE_TRANSITION_DURATION,
  SET_SCENE_TRANSITION_TYPE
} from 'js/actionCreators/sceneTransitionsActions';
import { SCENE_DIRECTION_CHOICES, ALL_SCENE_DIRECTION_CHOICES } from 'js/config/sceneTransitionsOptions';
import { SCENE_TRANSITIONS_WITH_DIRECTION, SCENE_TRANSITION_KEY_NONE } from 'js/config/consts';
import { getScribeById } from 'js/sagas/selectors';
import getAllCursors from 'js/selectors/getAllCursors';
import { ExistingScribeModel, RootState } from 'js/types';
import { SCENE_TRANSITION_PANEL_EVENT_TRIGGER } from 'js/editor/EditScenePanels/SceneTransitionsPanel';

import { track } from '../mixpanelProvider';

function* trackDeleteSelectedScenes({ scribeId, eventTrigger }: DeleteSelectedScenesAction) {
  const { scribe: updatedScribe } = yield take(UPDATE_SCRIBE);
  yield call(track, 'Delete Scene', {
    'Scribe ID': scribeId,
    'Number of Scenes': updatedScribe.scenes.length,
    'Event Trigger': eventTrigger
  });
}

function* trackAddScene({ scribeId, eventTrigger }: CreateSceneAction) {
  const { scribe: updatedScribe } = yield take(UPDATE_SCRIBE);
  yield call(track, 'Add Scene', {
    'Scribe ID': scribeId,
    'Number of Scenes': updatedScribe.scenes.length,
    'Event Trigger': eventTrigger
  });
}

function* trackDuplicateScene({ scribeId, eventTrigger }: DuplicateScenesAction) {
  const { scribe: updatedScribe } = yield take(UPDATE_SCRIBE);
  yield call(track, 'Duplicate Scene', {
    'Scribe ID': scribeId,
    'Number of Scenes': updatedScribe.scenes.length,
    'Event Trigger': eventTrigger
  });
}

function* trackSceneTransitionTypeChange({ transitionType, sceneIds, scribeId }: SetSceneTransitionTypeAction) {
  const eventProperties = {
    'Scribe ID': scribeId,
    'Scene Transition': transitionType
  };

  yield call(track, 'Set Scene Transition', { ...eventProperties, 'Number of Scenes': sceneIds?.length });

  if (transitionType === SCENE_TRANSITION_KEY_NONE) {
    yield call(track, 'Set Scene Transition Timing', {
      ...eventProperties,
      'Animation Timing (seconds)': 0,
      'Event Trigger': SCENE_TRANSITION_PANEL_EVENT_TRIGGER
    });
  }
}

function* trackSceneTransitionDurationChange({
  duration,
  scribeId,
  sceneIds,
  eventTrigger
}: SetSceneTransitionDurationAction): Generator<SelectEffect | CallEffect, void, ExistingScribeModel | undefined> {
  const scribe = yield select(getScribeById, scribeId);
  if (!scribe) return;

  const sceneId = sceneIds ? sceneIds[0] : undefined;
  if (!sceneId) return;

  const scene = scribe.scenes.find(scene => scene.id === sceneId);
  if (!scene) return;

  const currentSceneTransitionType = scene.settings.sceneTransitionType;

  const eventProperties = {
    'Scribe ID': scribeId,
    'Scene Transition': duration === 0 ? SCENE_TRANSITION_KEY_NONE : currentSceneTransitionType
  };

  if (duration === 0) {
    yield call(track, 'Set Scene Transition', { ...eventProperties, 'Number of Scenes': sceneIds?.length });
  }

  yield call(track, 'Set Scene Transition Timing', {
    ...eventProperties,
    'Animation Timing (seconds)': duration,
    'Event Trigger': eventTrigger
  });
}

function* trackSceneTransitionConfigChange({
  config,
  scribeId,
  sceneIds,
  useAllDirections
}: SetSceneTransitionConfigAction): Generator<SelectEffect | CallEffect, void, RootState> {
  const state = yield select();

  const scribe = getScribeById(state, scribeId);
  if (!scribe) return;

  const sceneId = sceneIds ? sceneIds[0] : undefined;
  if (!sceneId) return;

  const scene = scribe.scenes.find(scene => scene.id === sceneId);
  if (!scene) return;

  const currentSceneTransitionType = scene.settings.sceneTransitionType;
  const additionalEventProperties: Record<string, string | number | undefined> = {
    'Scribe ID': scribeId,
    'Scene Transition': currentSceneTransitionType,
    'Number of Scenes': sceneIds?.length
  };

  let typeTitle = 'Config';

  if (
    currentSceneTransitionType &&
    SCENE_TRANSITIONS_WITH_DIRECTION.includes(currentSceneTransitionType) &&
    config.cursorId === undefined
  ) {
    let sceneDirection: string | undefined;

    if (useAllDirections) {
      sceneDirection = ALL_SCENE_DIRECTION_CHOICES.find(choice => choice.key === config.direction)?.title;
    } else {
      sceneDirection = SCENE_DIRECTION_CHOICES.find(choice => choice.key === config.direction)?.title;
    }
    additionalEventProperties['Transition Direction'] = sceneDirection;

    typeTitle = 'Direction';
  }

  if (config && config.cursorId) {
    typeTitle = 'Cursor';

    const cursors = getAllCursors(state);
    const cursor = cursors.find(cursor => cursor.id === config.cursorId);

    if (cursor) {
      additionalEventProperties['Cursor Category'] = cursor.group;
      additionalEventProperties['Cursor Name'] = cursor.name;
    } else {
      additionalEventProperties['Cursor ID'] = config.cursorId;
    }
  }

  yield call(track, `Set Scene Transition ${typeTitle}`, additionalEventProperties);
}

export default function* trackScenesSagas() {
  yield takeEvery(DELETE_SELECTED_SCENES, trackDeleteSelectedScenes);
  yield takeEvery(CREATE_SCENE, trackAddScene);
  yield takeEvery(DUPLICATE_SELECTED_SCENES, trackDuplicateScene);
  yield takeEvery(SET_SCENE_TRANSITION_TYPE, trackSceneTransitionTypeChange);
  yield takeEvery(SET_SCENE_TRANSITION_DURATION, trackSceneTransitionDurationChange);
  yield takeEvery(SET_SCENE_TRANSITION_CONFIG, trackSceneTransitionConfigChange);
}
