import { DownloadTrackingProperties } from 'js/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const DOWNLOAD_REQUEST_SUCCESS = 'DOWNLOAD_REQUEST_SUCCESS';

type DownloadRequestSuccessActionInput = {
  numSegments: number;
  shortId: string;
  format: string;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export type DownloadRequestSuccessAction = {
  type: typeof DOWNLOAD_REQUEST_SUCCESS;
  numSegments: number;
  shortId: string;
  format: string;
  trackingProperties: DownloadTrackingProperties;
  scribeId: number;
};

export const downloadRequestSuccess = ({
  numSegments,
  shortId,
  format,
  trackingProperties,
  scribeId
}: DownloadRequestSuccessActionInput): DownloadRequestSuccessAction => {
  return {
    type: DOWNLOAD_REQUEST_SUCCESS,
    numSegments,
    shortId,
    format,
    trackingProperties,
    scribeId
  };
};
