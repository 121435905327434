export const URL_TO_FILE_REQUEST = 'URL_TO_FILE_REQUEST';
export const URL_TO_FILE_SUCCESS = 'URL_TO_FILE_SUCCESS';
export const URL_TO_FILE_FAILURE = 'URL_TO_FILE_FAILURE';

export interface UrlToFileRequestAction {
  type: typeof URL_TO_FILE_REQUEST;
  url: string;
  filename: string;
  mimeType: string;
}

export const urlToFileRequest = ({
  url,
  filename,
  mimeType
}: Omit<UrlToFileRequestAction, 'type'>): UrlToFileRequestAction => ({
  type: URL_TO_FILE_REQUEST,
  url,
  filename,
  mimeType
});

export interface UrlToFileSuccessAction {
  type: typeof URL_TO_FILE_SUCCESS;
  file: File;
}

export const urlToFileSuccess = ({ file }: Omit<UrlToFileSuccessAction, 'type'>): UrlToFileSuccessAction => ({
  type: URL_TO_FILE_SUCCESS,
  file
});

export interface UrlToFileFailureAction {
  type: typeof URL_TO_FILE_FAILURE;
  error: Error;
}

export const urlToFileFailure = ({ error }: Omit<UrlToFileFailureAction, 'type'>): UrlToFileFailureAction => ({
  type: URL_TO_FILE_FAILURE,
  error
});
