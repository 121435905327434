import { fabric } from 'fabric';
import { FILE_CONTENT_TYPES } from 'js/config/consts';

export const getSvgDimensions = src => {
  return new Promise(resolve => {
    fabric.loadSVGFromURL(
      src,
      (img, options) => {
        const obj = fabric.util.groupSVGElements(img, options);
        obj.setCoords();
        resolve({ height: options.height, width: options.width });
      },
      null,
      {
        crossOrigin: 'anonymous'
      }
    );
  });
};

export const getImageDimensions = (src, contentType) => {
  if (contentType === FILE_CONTENT_TYPES.SVG) {
    return getSvgDimensions(src);
  }
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = err => {
      reject(err);
    };
    img.src = src;
  });
};
