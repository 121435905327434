import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { APP_TOOLTIP_ID } from 'js/config/consts';

import './ScribelyIconButton.css';

type VSIconButtonMouseEvent = React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>;

type Props = React.HTMLAttributes<HTMLAnchorElement> &
  React.HTMLAttributes<HTMLButtonElement> & {
    linkTo?: string;
    children?: React.ReactNode;
    icon: React.ReactNode;
    onClick: (event: VSIconButtonMouseEvent) => void;
    disabled?: boolean;
    datatip?: string;
    outline?: boolean;
    wideIcon?: boolean;
    dataplace?: string;
    theme?: 'purple';
    active?: boolean;
  };

const ScribelyIconButton = ({
  linkTo,
  children,
  icon,
  onClick,
  disabled,
  datatip,
  outline,
  wideIcon,
  dataplace,
  theme,
  active,
  ...rest
}: Props) => {
  const buttonClasses = classNames('scribely-icon-button', {
    'scribely-icon-button--disabled': disabled,
    'scribely-icon-button--outline': outline,
    'scribely-icon-button--wideIcon': wideIcon,
    'scribely-icon-button--active': active,
    [`scribely-icon-button--${theme}`]: theme
  });

  const handleClick = (event: VSIconButtonMouseEvent) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    onClick(event);
  };

  const hasChildren = children !== undefined;

  const iconClasses = classNames({
    'scribely-icon-button__icon': hasChildren,
    'scribely-icon-button__icon-single-element': !hasChildren
  });

  const content = (
    <>
      <div className={iconClasses}>{icon}</div>
      {hasChildren && <div className="scribely-icon-button__label">{children}</div>}
    </>
  );

  return linkTo ? (
    <Link
      to={linkTo}
      onClick={handleClick}
      className={buttonClasses}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      role="button"
      {...rest}
    >
      {content}
    </Link>
  ) : (
    <button
      onClick={handleClick}
      className={buttonClasses}
      aria-label={datatip}
      data-tip={datatip}
      data-for={APP_TOOLTIP_ID}
      data-place={dataplace}
      {...rest}
    >
      {content}
    </button>
  );
};

export default ScribelyIconButton;
