import { CropShapeTypes, VSCrop } from 'js/types';

export const START_IMAGE_CROP = 'START_IMAGE_CROP';
export const LOAD_IMAGE_CROP = 'LOAD_IMAGE_CROP';
export const CANCEL_IMAGE_CROP = 'CANCEL_IMAGE_CROP';
export const CONFIRM_IMAGE_CROP = 'CONFIRM_IMAGE_CROP';
export const CONFIRM_IMAGE_CROP_SUCCESS = 'CONFIRM_IMAGE_CROP_SUCCESS';
export const CONFIRM_IMAGE_CROP_FAILED = 'CONFIRM_IMAGE_CROP_FAILED';
export const SET_CROP_IMAGE = 'SET_CROP_IMAGE';
export const SET_CROP_SHAPE = 'SET_CROP_SHAPE';
export const SHOW_CROP_WARNING = 'SHOW_CROP_WARNING';
export const CONFIRM_CROP_WARNING = 'CONFIRM_CROP_WARNING';
export const CANCEL_CROP_WARNING = 'CANCEL_CROP_WARNING';

export interface StartImageCropAction {
  type: typeof START_IMAGE_CROP;
  scribeId: number;
  elementId: string;
  sceneId: string;
}

export interface LoadImageCropAction {
  type: typeof LOAD_IMAGE_CROP;
  scribeId: number;
  elementId: string;
  sceneId: string;
  imageUrl: string;
  transforms: {
    scaleX: number;
    scaleY: number;
    flipX: boolean;
    flipY: boolean;
  };
  imageType: string;
  filename?: string;
}

export interface ConfirmImageCropFailedAction {
  type: typeof CONFIRM_IMAGE_CROP_FAILED;
  error?: Error;
}

export interface SetCropShapeAction {
  type: typeof SET_CROP_SHAPE;
  shape: CropShapeTypes;
}

export interface SetCropImageAction {
  type: typeof SET_CROP_IMAGE;
  crop: VSCrop;
}

export interface CancelCropWarningAction {
  type: typeof CANCEL_CROP_WARNING;
  scribeId: number;
}

export const startImageCrop = ({
  scribeId,
  elementId,
  sceneId
}: Omit<StartImageCropAction, 'type'>): StartImageCropAction => ({
  type: START_IMAGE_CROP,
  scribeId,
  elementId,
  sceneId
});

export const loadImageCrop = ({
  scribeId,
  elementId,
  sceneId,
  imageUrl,
  transforms,
  filename,
  imageType
}: Omit<LoadImageCropAction, 'type'>) => ({
  type: LOAD_IMAGE_CROP,
  scribeId,
  elementId,
  sceneId,
  imageUrl,
  transforms,
  filename,
  imageType
});

export const cancelImageCrop = () => ({
  type: CANCEL_IMAGE_CROP
});

export const confirmImageCrop = () => ({
  type: CONFIRM_IMAGE_CROP
});

export const confirmImageCropSuccess = () => ({
  type: CONFIRM_IMAGE_CROP_SUCCESS
});

export const confirmImageCropFailed = (error?: Error): ConfirmImageCropFailedAction => ({
  type: CONFIRM_IMAGE_CROP_FAILED,
  error
});

export const setCropImage = (crop: VSCrop): SetCropImageAction => ({
  type: SET_CROP_IMAGE,
  crop
});

export const setCropShape = (shape: CropShapeTypes): SetCropShapeAction => ({
  type: SET_CROP_SHAPE,
  shape
});

export const showCropWarning = () => ({
  type: SHOW_CROP_WARNING
});

export interface ConfirmCropWarningAction {
  type: typeof CONFIRM_CROP_WARNING;
  scribeId: number;
  suppressWarning?: boolean;
}

export const confirmCropWarning = ({
  scribeId,
  suppressWarning
}: Omit<ConfirmCropWarningAction, 'type'>): ConfirmCropWarningAction => ({
  type: CONFIRM_CROP_WARNING,
  scribeId,
  suppressWarning
});

export const cancelCropWarning = (scribeId: number): CancelCropWarningAction => ({
  type: CANCEL_CROP_WARNING,
  scribeId
});
