import ScribeModel from 'js/models/ScribeModel';
import { defaultFontLabel, defaultFontValue } from 'js/shared/resources/scribedefaults';
import { VSCFontAssetData } from 'js/types';
import cloneDeep from 'lodash.clonedeep';

export default function checkScribeFonts(activeScribe: ScribeModel, userFonts: Array<VSCFontAssetData>) {
  const userFontIds = userFonts.reduce((acc: Array<number>, current: VSCFontAssetData) => {
    return [...acc, current.projectAssetId];
  }, []);

  const newActiveScribe = cloneDeep(activeScribe);

  newActiveScribe.elements.forEach(element => {
    if (element.type === 'Text' && element.font?.assetId && !userFontIds.includes(element.font.assetId)) {
      element.font = {
        label: defaultFontLabel,
        value: defaultFontValue
      };
    }
  });

  return newActiveScribe;
}
