import React from 'react';

const IconAnimationDragIn = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <path d="M18.4,36.1h-7.2v3h9.1C19.6,38.2,19,37.2,18.4,36.1z" />
    <path
      d="M50.5,38.1c-0.8,0-1.5,0.2-2.2,0.7c-0.7-0.8-1.7-1.2-2.7-1.3c2.2-2.6,3.5-5.9,3.5-9.5c0-8.2-6.6-14.8-14.8-14.8
   c-8.2,0-14.8,6.6-14.8,14.8c0,5.1,2.6,9.6,6.6,12.3c-1,0.8-1.5,2.1-1.6,3.1c0,0,0,0.1,0,0.1v6.4h3v-6.3c0-0.3,0.2-1.2,1.2-1.2
   c1,0,1.2,0.8,1.3,1.2v2.9c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V29.7c0-0.9,0.6-1.5,1.5-1.5c0.6,0,1.1,0.5,1.1,1.1v12.6
   c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.6,0,1.1,0.5,1.1,1.1v0.8c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5
   v-0.8c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v0.8c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-0.1c0-0.6,0.5-1.1,1.2-1.1
   c0.6,0,1.1,0.5,1.1,1.1v7.7h3v-7.7C54.7,40,52.8,38.1,50.5,38.1z M34.5,25.2c-2.5,0-4.5,2-4.5,4.5v9.2c-4.4-1.7-7.5-6-7.5-11
   c0-6.5,5.3-11.8,11.8-11.8c6.5,0,11.8,5.3,11.8,11.8c0,4-2,7.5-5,9.6c-0.3-0.1-0.6-0.1-1-0.1c-0.5,0-1,0.1-1.5,0.3v-8.4
   C38.6,27.1,36.7,25.2,34.5,25.2z"
    />
    <path d="M16.4,27.9c0-0.6,0-1.2,0.1-1.8H5.6v3h10.9C16.4,28.7,16.4,28.3,16.4,27.9z" />
    <path d="M20.3,16.8h-8.7v3h6.8C18.9,18.7,19.5,17.7,20.3,16.8z" />
  </svg>
);

export default IconAnimationDragIn;
