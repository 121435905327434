import iconCameraImageUrl from 'imgs/icons/IconCamera.svg';

let cameraIcon: HTMLImageElement;

export function getCameraIcon() {
  if (cameraIcon) {
    return cameraIcon;
  }

  cameraIcon = new Image(20, 17);
  cameraIcon.src = iconCameraImageUrl;
  return cameraIcon;
}
