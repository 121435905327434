import { AnimationStage, AllAnimationTypeKeys, TweenPosition } from 'js/types';

export const SET_ANIMATION_TYPE = 'SET_ANIMATION_TYPE';
export const SET_ANIMATION_DIRECTION = 'SET_ANIMATION_DIRECTION';
export const SET_EMPHASIS_ANIMATION_DIRECTION = 'SET_EMPHASIS_ANIMATION_DIRECTION';

export interface SetAnimationTypeAction {
  type: typeof SET_ANIMATION_TYPE;
  animationType: AllAnimationTypeKeys;
  stage: AnimationStage;
  elementIds: Array<string>;
  scribeId: number;
  tweenPositions: Array<TweenPosition>;
}

export const setAnimationType = ({
  animationType,
  stage,
  elementIds,
  scribeId,
  tweenPositions
}: Omit<SetAnimationTypeAction, 'type'>): SetAnimationTypeAction => ({
  type: SET_ANIMATION_TYPE,
  animationType,
  stage,
  elementIds,
  scribeId,
  tweenPositions
});

export interface SetAnimationDirectionAction {
  type: typeof SET_ANIMATION_DIRECTION;
  animationType: string;
  stage: AnimationStage;
  elementIds: Array<string>;
  scribeId: number;
  newTweenPosition?: string;
  spinRotation?: number;
}

export const setAnimationDirection = ({
  animationType,
  stage,
  elementIds,
  scribeId,
  newTweenPosition,
  spinRotation
}: Omit<SetAnimationDirectionAction, 'type'>): SetAnimationDirectionAction => ({
  type: SET_ANIMATION_DIRECTION,
  animationType,
  stage,
  elementIds,
  scribeId,
  newTweenPosition,
  spinRotation
});

export type SetEmphasisAnimationDirectionAction = Omit<SetAnimationDirectionAction, 'type'> & {
  type: typeof SET_EMPHASIS_ANIMATION_DIRECTION;
};

export const setEmphasisAnimationDirection = ({
  animationType,
  stage,
  elementIds,
  scribeId,
  newTweenPosition,
  spinRotation
}: Omit<SetEmphasisAnimationDirectionAction, 'type'>): SetEmphasisAnimationDirectionAction => ({
  type: SET_EMPHASIS_ANIMATION_DIRECTION,
  animationType,
  stage,
  elementIds,
  scribeId,
  newTweenPosition,
  spinRotation
});
