import {
  ExistingScribeModel,
  PlaybackImageResources,
  Cursor,
  VSImageShapeOrTextElementModel,
  SceneTransitionKey
} from 'js/types';
import IconSceneTransitionBounce from 'imgs/pixi_icons/IconSceneTransitionBounce.svg';
import IconSceneTransitionDragOn from 'imgs/pixi_icons/IconSceneTransitionDragOn.svg';
import IconSceneTransitionErase from 'imgs/pixi_icons/IconSceneTransitionErase.svg';
import IconSceneTransitionFade from 'imgs/pixi_icons/IconSceneTransitionFade.svg';
import IconSceneTransitionNone from 'imgs/pixi_icons/IconSceneTransitionNone.svg';
import IconSceneTransitionSlide from 'imgs/pixi_icons/IconSceneTransitionSlide.svg';
import * as PIXI from 'pixi.js';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { defaultCanvasColor } from 'js/config/defaults';
import SceneTransitionElementModel from 'js/models/SceneTransitionElementModel';
import {
  MAX_ANIMATION_TIME_SECONDS,
  SCENE_TRANSITION_KEY_BOUNCE,
  SCENE_TRANSITION_KEY_DRAG_ON,
  SCENE_TRANSITION_KEY_ERASE,
  SCENE_TRANSITION_KEY_FADE,
  SCENE_TRANSITION_KEY_NONE,
  SCENE_TRANSITION_KEY_SLIDE
} from 'js/config/consts';
import SCENE_TRANSITION_OPTIONS from 'js/config/sceneTransitionsOptions';

import TimelineElementGrabHandle from './TimelineElementGrabHandle';
import TimelineLayerThumbnail from './TimelineLayerThumbnail';
import timelineBus, {
  LAYER_CLICKED,
  PLAYHEAD_HIDE,
  PLAYHEAD_MOVE,
  PLAYHEAD_SHOW,
  TOOLTIP_HIDE,
  TOOLTIP_SHOW
} from './TimelineControlBus';
import { TimelineTimingsComparisonModel } from './TimelineTimingsComparisonModel';
import {
  SCENE_TRANSITION_LENGTH_CHANGED,
  TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
  TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR,
  TIMELINE_LAYER_BACKGROUND_COLOUR,
  TIMELINE_LAYER_FOREGROUND_COLOUR
} from './TimelineWrapper';
import LayerBase, { TimelineBaseLayerConfig } from './LayerBase';
import TimelineHelpers from './Utils/TimelineHelpers';
import { TOOLTIP_ALIGN_CENTRAL, TOOLTIP_ICON_ALIGN_LEFT } from './TimelineLayerTooltip';

const LAYER_GAP = 1;

export interface SceneTransitionLayerConfig extends TimelineBaseLayerConfig {
  cursors: Array<Cursor>;
  sceneTransitionTime: number;
  sceneTransitionType: string;
}

export default class SceneTransitionLayer extends LayerBase {
  public elementModel: VSImageShapeOrTextElementModel;
  public leftPosition: number;
  public rightPosition: number;

  scribeLengthLimit = 0;
  sceneTransitionTime = 0;
  sceneTransitionType: SceneTransitionKey;
  emphasisAnimationTime = 0;
  exitAnimationTime: number | undefined;
  continuationGraphics = new PIXI.Graphics();
  icons: PIXI.Container;
  tooltipIcon?: PIXI.Sprite;
  activeElementOutline: PIXI.Graphics;
  minimumIconWidth = 34;
  tooltipThumb: TimelineLayerThumbnail;

  cursors: Array<Cursor>;
  type = 'SceneTransition' as const;
  iconLookup: { [k in string]: string };
  handle?: TimelineElementGrabHandle;

  constructor({
    elementModel,
    sceneID,
    scribe,
    sceneStartTime,
    currentSceneLength,
    isActive,

    wrapperRef,
    imageResources,
    cursors
  }: SceneTransitionLayerConfig) {
    super({
      elementModel,
      sceneID,
      scribe,
      sceneStartTime,
      currentSceneLength,
      isActive,

      wrapperRef,
      imageResources
    });
    this.elementModel = elementModel as SceneTransitionElementModel;
    this.sceneTransitionTime = this.elementModel.sceneTransitionTime;
    this.sceneTransitionType = this.elementModel.sceneTransitionType;
    this.cursors = cursors;
    this.overlay = new PIXI.Graphics();
    this.backgroundGraphics = new PIXI.Graphics();

    this.backgroundGraphics.interactive = true;
    this.backgroundGraphics.buttonMode = true;
    this.backgroundGraphics.on('click', this.handleClick, this);

    this.foregroundGraphics = new PIXI.Graphics();
    this.foregroundSprites = new PIXI.Sprite();
    this.icons = new PIXI.Container();
    this.icons.interactive = true;
    this.icons.buttonMode = true;
    this.disableDrag = true;
    this.icons.on('click', this.handleClick, this);

    this.iconLookup = {
      [SCENE_TRANSITION_KEY_BOUNCE]: IconSceneTransitionBounce,
      [SCENE_TRANSITION_KEY_ERASE]: IconSceneTransitionErase,
      [SCENE_TRANSITION_KEY_FADE]: IconSceneTransitionFade,
      [SCENE_TRANSITION_KEY_NONE]: IconSceneTransitionNone,
      [SCENE_TRANSITION_KEY_SLIDE]: IconSceneTransitionSlide,
      [SCENE_TRANSITION_KEY_DRAG_ON]: IconSceneTransitionDragOn
    };

    this.activeElementOutline = new PIXI.Graphics();
    this.addChild(this.backgroundGraphics);
    this.addChild(this.continuationGraphics);
    this.addChild(this.foregroundGraphics);
    this.addChild(this.foregroundSprites);
    this.addChild(this.overlay);
    this.foregroundSprites.addChild(this.foregroundGraphics);
    this.foregroundSprites.addChild(this.icons);
    this.foregroundSprites.addChild(this.activeElementOutline);
    this.thumb = this.createThumbnail();

    this.populate();
    this.updateZoom();
    this.updateIsActive(isActive);
    this.leftPosition = 0;
    this.rightPosition = 0;
    this.addChild(this.foregroundSprites);

    const activeScene = getActiveScene(scribe);
    this.tooltipThumb = new TimelineLayerThumbnail({
      element: this.elementModel,
      zoomValue: this.currentZoom,
      wrapperRef: this.wrapperRef,
      backgroundSettings: activeScene?.settings ?? {
        backgroundColor: defaultCanvasColor
      },
      imageResources: this.imageResources
    });
  }

  createThumbnail() {
    return new TimelineLayerThumbnail({
      element: this.elementModel as VSImageShapeOrTextElementModel,
      zoomValue: this.currentZoom,
      wrapperRef: this.wrapperRef,
      backgroundSettings: getActiveScene(this.scribe)?.settings ?? {
        backgroundColor: defaultCanvasColor
      },
      imageResources: this.imageResources
    });
  }
  updateProperties(changes: TimelineTimingsComparisonModel, newModel: VSImageShapeOrTextElementModel) {
    this.foregroundSprites.removeChildren();
    this.icons.removeChildren();
    this.foregroundSprites.addChild(this.icons);
    this.foregroundSprites.addChild(this.activeElementOutline);
    this.elementModel = newModel;
    this.startTime = this.endTime = changes.startTime / 1000;
    this.populate();
    this.setIcons();
    this.drawIcons();
    this.setHandleClamps();
    this.updateIsActive();
  }

  public updateScribe(scribe: ExistingScribeModel) {
    const activeScene = getActiveScene(scribe);

    if (activeScene) {
      (this.thumb as TimelineLayerThumbnail).updateBackgroundSettings(activeScene.settings);
    }
  }

  public updateImageResources(imageResources: PlaybackImageResources) {
    (this.thumb as TimelineLayerThumbnail).updateImageResources(imageResources);
  }

  protected updateIsActive(isActive?: boolean) {
    this.activeElementOutline.clear();
    if (isActive !== undefined) {
      this.isActive = isActive;
    }
    if (this.isActive) {
      this.activeElementOutline.lineStyle({
        width: TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
        color: TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR,
        alignment: 0
      });
      this.activeElementOutline.drawRect(
        this.startTime * this.pixelsPerSecond,
        1,
        (this.currentSceneLength - (this.startTime - this.sceneStartTime)) * this.pixelsPerSecond,
        this.layerHeight - LAYER_GAP
      );
      this.parent?.addChild(this);
    }
  }

  private grabHandleAdjust = (handle: TimelineElementGrabHandle, event: PIXI.InteractionEvent): void => {
    event.stopPropagation();
    if (this.handle === undefined) return;
    handle.x = Math.ceil(handle.x / this.pixelsPerSecond / 0.1) * 0.1 * this.pixelsPerSecond;
    const delta = (this.endTime * this.pixelsPerSecond - handle.x) / this.pixelsPerSecond;
    this.endTime -= delta;
    timelineBus.emit(
      PLAYHEAD_MOVE,
      handle.getGlobalPosition().x,
      TimelineHelpers.formatTime(handle.x / this.pixelsPerSecond)
    );

    timelineBus.emit(
      TOOLTIP_SHOW,
      event,
      [TimelineHelpers.roundNumber(this.handle.x / this.currentZoom.x - this.sceneStartTime) + 's <'],
      TOOLTIP_ICON_ALIGN_LEFT,
      this.currentZoom.y,
      handle
    );
    this.sceneTransitionTime = TimelineHelpers.roundNumber(this.handle.x / this.currentZoom.x - this.sceneStartTime);
    timelineBus.emit(PLAYHEAD_SHOW, this);
    this.drawForegroundGraphics();
    this.drawActiveSelection();
  };

  drawActiveSelection(): void {
    this.isActive = true;
    if (this.activeElementOutline && this.foregroundGraphics) {
      this.removeChild(this.activeElementOutline);
      this.activeElementOutline.destroy();
    }
    this.addChild((this.activeElementOutline = new PIXI.Graphics()));
    this.activeElementOutline.clear();
    this.activeElementOutline.lineStyle({
      width: TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
      color: TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR,
      alignment: 0
    });
    this.activeElementOutline.drawRect(
      this.sceneStartTime * this.currentZoom.x,
      1,
      this.sceneTransitionTime * this.currentZoom.x,
      this.currentZoom.y - 1
    );
  }

  private populate(): void {
    if (this.handle) {
      this.foregroundSprites.removeChild(this.handle);
      this.handle.destroy();
    }
    this.drawElementTimeOnStage();
    this.foregroundSprites.interactiveChildren = true;
    this.handles = [];
    this.handle = new TimelineElementGrabHandle(
      1,
      true,
      this.wrapperRef,
      this.handleRightClick,
      TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR
    ); //right this.handle
    this.handle.interactive = this.handle.buttonMode = true;
    this.handle.cursor = 'timeDrag';
    this.handle.x = (this.sceneStartTime + this.sceneTransitionTime) * this.currentZoom.x;
    this.endTime = (this.sceneStartTime + this.sceneTransitionTime) * this.currentZoom.x;
    this.foregroundSprites.addChild(this.handle);
    this.handles.push(this.handle);
    this.handle.on('onGrabHandleDragged', this.grabHandleAdjust);
    this.handle.on('onGrabHandleDragComplete', this.timingsChanged);

    this.setHandleClamps();
    this.foregroundSprites.addChild(this.activeElementOutline);
  }

  timingsChanged = () => {
    timelineBus.emit(TOOLTIP_HIDE);
    timelineBus.emit(PLAYHEAD_HIDE);
    this.emit(SCENE_TRANSITION_LENGTH_CHANGED, this.sceneTransitionTime);
  };

  private setHandleClamps(): void {
    const lower = this.sceneStartTime * this.currentZoom.x;
    const upper = Math.floor(lower + MAX_ANIMATION_TIME_SECONDS * this.pixelsPerSecond);
    this.handle?.setClamps(lower, upper);
  }

  protected drawBackgroundGraphics() {
    if (this.wrapperRef.scrollBox) {
      this.backgroundGraphics.clear();
      this.backgroundGraphics.beginFill(TIMELINE_LAYER_BACKGROUND_COLOUR, 1);
      this.backgroundGraphics.drawRect(
        this.sceneStartTime * this.currentZoom.x,
        1,
        this.currentSceneLength * this.currentZoom.x,
        this.currentZoom.y - 1
      );
      this.backgroundGraphics.endFill();
    }
  }

  public getGlobalStartPosition() {
    const global = this.foregroundGraphics.toGlobal(this.wrapperRef.app.stage);
    return new PIXI.Point(global.x + this.getStartX(), global.y);
  }

  protected drawForegroundGraphics() {
    this.foregroundGraphics.clear();
    this.foregroundGraphics.beginFill(TIMELINE_LAYER_FOREGROUND_COLOUR, 1);
    this.foregroundGraphics.drawRect(
      this.sceneStartTime * this.currentZoom.x,
      1,
      this.sceneTransitionTime * this.currentZoom.x,
      this.currentZoom.y - 1
    );
    this.foregroundGraphics.endFill();
    this.drawIcons();
  }

  public recalculateEndTime() {}

  private addIcon() {
    const icon = new PIXI.Sprite(TimelineHelpers.importSVG(this.iconLookup[this.sceneTransitionType]));
    if (icon !== undefined) {
      const iconPadding = 2;
      const iconRatio = 1.33;
      if (this.sceneTransitionTime * this.currentZoom.x > this.currentZoom.y + iconPadding * 2) {
        icon.height = this.currentZoom.y - iconPadding * 2;
        icon.width = icon.height * iconRatio;
        icon.y = iconPadding;
        icon.x =
          (this.sceneTransitionTime * this.currentZoom.x - icon.width) / 2 + this.sceneStartTime * this.currentZoom.x;
        icon.alpha = 1;
      } else {
        icon.height = this.currentZoom.y;
        icon.width = this.sceneTransitionTime * this.currentZoom.x;
        icon.y = 0;
        icon.x = this.sceneStartTime * this.currentZoom.x;
        icon.alpha = 0;
      }
    }
    icon.interactive = true;
    icon.on('pointerover', this.rolloverIcon);
    icon.on('pointerout', this.rolloffIcon);
    icon.texture.once('update', () => {
      if (icon) {
        this.addIcon();
      }
    });
    this.icons.addChild(icon);
  }

  public drawIcons() {
    this.icons.removeChildren();
    this.addIcon();
    this.tooltipIcon = new PIXI.Sprite(TimelineHelpers.importSVG(this.iconLookup[this.sceneTransitionType]));
  }

  private handleClick(event: PIXI.InteractionEvent) {
    event.stopPropagation();
    this.emit(LAYER_CLICKED, this.id, event);
  }

  public handleRightClick = (event: PIXI.InteractionEvent) => {
    event.stopPropagation();
    event.data.originalEvent.preventDefault();
    event.data.originalEvent.stopPropagation();
    window.setTimeout(() => this.wrapperRef.onContextClick(context), 1);
    if (event.data.originalEvent.ctrlKey && !this.wrapperRef.isMac) {
      return;
    }
    this.emit(LAYER_CLICKED, this.id, event);

    const x = (event.data.originalEvent as MouseEvent).clientX;
    const y = (event.data.originalEvent as MouseEvent).clientY;

    const context = {
      type: 'element' as const,
      scribeId: this.wrapperRef.props.activeScribe.id,
      mouseX: x,
      mouseY: y,
      elementId: this.elementModel.id
    };
  };

  updateScribeLength(sceneLength: number) {
    this.currentSceneLength = sceneLength;
    this.drawElementTimeOnStage();
  }

  private setIcons() {}

  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    // Remove the assignment to the 'destroyed' property

    if (this.thumb) this.thumb.destroy({ children: true, texture: true, baseTexture: false });
    this.backgroundGraphics.destroy({ children: true, texture: true, baseTexture: false });
    this.icons.destroy({ children: true, texture: true, baseTexture: false });
    this.handle?.destroy({ children: true, texture: true, baseTexture: false });
    this.handles = [];
    this.overlay.destroy({ children: true, texture: true, baseTexture: false });
    this.continuationGraphics.destroy({ children: true, texture: true, baseTexture: false });
    this.foregroundGraphics.destroy({ children: true, texture: true, baseTexture: false });
    this.activeElementOutline.destroy({ children: true, texture: true, baseTexture: false });
    super.destroy(options);
  }

  private rolloverIcon = (event: PIXI.InteractionEvent) => {
    if (this.handle === undefined || this.wrapperRef.dragging) return;
    const rolloverText = [
      this.tooltipIcon,
      'Scene transition ',
      SCENE_TRANSITION_OPTIONS.find(e => e.type === this.sceneTransitionType)?.title,
      TimelineHelpers.roundNumber(this.handle.x / this.currentZoom.x - this.sceneStartTime) + 's'
    ];
    timelineBus.emit(TOOLTIP_SHOW, event, rolloverText, TOOLTIP_ALIGN_CENTRAL, this.currentZoom.y);
  };

  private rolloffIcon = () => {
    if (this.handle === undefined || this.wrapperRef.dragging) return;
    timelineBus.emit(TOOLTIP_HIDE);
  };

  public redraw(): void {}

  updateZoom() {
    super.updateZoom();
    this.populate();
    this.setIcons();
    this.drawIcons();
    this.setHandleClamps();
    this.updateIsActive();
    if (this.handle) this.handle.updateZoom();
    if (this.thumb) this.thumb.updateZoom();
    if (this.tooltipThumb) this.tooltipThumb.updateZoom();
  }
}
