import { ScribeState } from 'js/types';
import cloneDeep from 'lodash.clonedeep';

import { replaceHomepageProject } from './homepageProjectHelpers';

export default function renameProjectReducer(state: ScribeState, projectId: number, title: string) {
  const allScribes = cloneDeep(state.allScribes);
  const scribeToUpdate = allScribes.find(scribe => scribe.id === projectId);
  const activeScribePast = cloneDeep(state.activeScribePast);
  const activeScribeFuture = cloneDeep(state.activeScribeFuture);

  [...activeScribePast, ...activeScribeFuture]
    .filter(project => project.id === projectId)
    .forEach(project => {
      project.title = title;
    });

  const homepageProject = state.homepageProjects.find(project => project.id === projectId);

  let homepageProjects = state.homepageProjects;

  if (homepageProject) {
    const updatedHomePageProject = { ...homepageProject, title };

    homepageProjects = replaceHomepageProject(state.homepageProjects, updatedHomePageProject);
  }

  if (scribeToUpdate) {
    scribeToUpdate.title = title;

    return {
      ...state,
      allScribes,
      activeScribePast,
      activeScribeFuture,
      homepageProjects
    };
  }

  return { ...state, homepageProjects };
}
