import uniq from 'lodash.uniq';
import { SHAKE_LOOP_KEY, BOUNCE_LOOP_KEY } from 'js/config/consts';
import { EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY } from 'js/config/animationOptions';

import { defaultShakeLoops, defaultBounceEmphasisLoops } from '../resources/scribedefaults';

const getAnimationLoops = ({ loopsProperty, element, elements, scribe }) => {
  const { settings } = scribe;
  const defaultLoops = settings[loopsProperty];

  if (element && element.emphasisTween && element.emphasisTween.id === SHAKE_LOOP_KEY) {
    return element[loopsProperty] && element[loopsProperty] !== 0 ? element[loopsProperty] : defaultShakeLoops;
  } else if (element && element.emphasisTween && element.emphasisTween.id === BOUNCE_LOOP_KEY) {
    return element[loopsProperty] && element[loopsProperty] !== 0 ? element[loopsProperty] : defaultBounceEmphasisLoops;
  } else if (element) {
    return element[loopsProperty] && element[loopsProperty] !== 0 ? element[loopsProperty] : defaultLoops;
  }

  let loopsList = [];
  for (const el of elements) {
    if (loopsProperty === EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY && el?.emphasisTween?.id === SHAKE_LOOP_KEY) {
      loopsList.push(el[loopsProperty] && el[loopsProperty] !== 0 ? el[loopsProperty] : defaultShakeLoops);
    } else if (loopsProperty === EMPHASIS_ANIMATION_LOOPS_DOCUMENT_KEY && el?.emphasisTween?.id === BOUNCE_LOOP_KEY) {
      loopsList.push(el[loopsProperty] && el[loopsProperty] !== 0 ? el[loopsProperty] : defaultBounceEmphasisLoops);
    } else {
      loopsList.push(el[loopsProperty] && el[loopsProperty] !== 0 ? el[loopsProperty] : defaultLoops);
    }
  }

  const uniqueLoops = uniq(loopsList);

  return uniqueLoops.length === 1 ? uniqueLoops[0] : '-';
};

export default getAnimationLoops;
