import { SceneTransitionConfig, Viewport } from 'js/types';
import clamp from 'lodash.clamp';
import * as PIXI from 'pixi.js';
import { Component } from 'react';

import VSScene from '../VSScene';
export interface VSSceneTransitionBaseProps {
  previousScene: VSScene | null;
  currentScene: VSScene;
  transitionDuration: number;
  viewportDimensions: Viewport;
  config?: SceneTransitionConfig;
  onTransitionComplete?: () => void;
}
export default class VSSceneTransitionBase extends Component {
  constructor({
    previousScene,
    currentScene,
    transitionDuration,
    viewportDimensions,
    onTransitionComplete
  }: VSSceneTransitionBaseProps) {
    super({ previousScene, currentScene, transitionDuration, viewportDimensions });
    this.previousScene = previousScene;
    this.currentScene = currentScene;
    this.scene1Out = 0;
    this.scene2In = 1;
    this.transitionDuration = transitionDuration;
    this.mask1PrevOut = null;
    this.mask2CurrIn = null;
    this.isDisposed = false;
    this.viewportDimensions = viewportDimensions;
    this.exitProgress = 0;
    this.onTransitionComplete = onTransitionComplete;
    this.isComplete = false;
  }
  onTransitionComplete: (() => void) | undefined;

  previousScene: VSScene | null;
  currentScene: VSScene;
  scene1Out: number;
  scene2In: number;
  transitionDuration: number;
  mask1PrevOut: PIXI.Graphics | null;
  mask2CurrIn: PIXI.Graphics | null;
  isDisposed: boolean;
  viewportDimensions: Viewport;
  exitProgress?: number;
  introProgress?: number;
  isComplete = false;

  setupExitTransition() {
    // add you custom masks here
  }

  setupIntroTransition() {
    // add you custom masks here
  }

  /**
   * this called with a normalized value of progress
   * @param {*} progress 0-1
   * @returns
   */
  update(progress: number) {
    if (this.isDisposed) return;
    //exit

    const exitProgress = clamp(progress / this.scene1Out, 0, 1);
    if (this.exitProgress !== exitProgress) {
      this.updateExitProgress(exitProgress);
      this.exitProgress = exitProgress;
    }

    //intro
    const introLength = this.scene2In;
    const introStartPos = 1 - introLength;
    const introProgress = (progress - introStartPos) / introLength;

    if (this.introProgress !== introProgress) {
      this.updateIntroProgress(introProgress);
      this.introProgress = introProgress;
    }
    if (introProgress >= 1) {
      this.transitionComplete();
    }
  }

  updateExitProgress(_progress: number): void {
    return;
  }

  updateIntroProgress(_progress: number): void {
    return;
  }

  public transitionComplete = () => {
    if (this.isComplete === false) {
      this.isComplete = true;
      if (this.onTransitionComplete) {
        this.onTransitionComplete();
      }
    }
  };
}
