import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import {
  SCENE_DIRECTION_CHOICES,
  ALL_SCENE_DIRECTION_CHOICES,
  SCENE_DIRECTION_KEYS
} from 'js/config/sceneTransitionsOptions';
import { setSceneTransitionConfig } from 'js/actionCreators/sceneTransitionsActions';
import { getScribeById } from 'js/sagas/selectors';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { VSComponentProps } from 'js/types';

import OptionMenuButton from '../AnimationPanels/OptionMenuButton';

import './SceneDirectionMenu.css';

const TOOLTIP_ID = 'scene-direction-menu-tooltip';
const SceneDirectionMenu = ({ config, onSelection, scribeId, sceneIds, useAllDirections }: VSComponentProps) => {
  const dispatch = useDispatch();
  const scribe = useTypedSelector(state => getScribeById(state, scribeId));
  const activeScene = scribe && scribe.scenes.find(scene => scene.active);

  const directionButtons = useAllDirections ? ALL_SCENE_DIRECTION_CHOICES : SCENE_DIRECTION_CHOICES;

  const { direction } = config;

  const handleButtonClick = (newDirection: string) => {
    dispatch(
      setSceneTransitionConfig({
        sceneIds,
        scribeId,
        config: {
          direction: newDirection
        }
      })
    );
    onSelection();
  };

  return (
    <>
      <div className="SceneDirectionMenu">
        {directionButtons.map((choice, index) => {
          if (choice.key === SCENE_DIRECTION_KEYS.EMPTY) {
            return <div key={choice.key + index} />;
          }

          if (choice.key === SCENE_DIRECTION_KEYS.SCENE) {
            return (
              <div key={choice.key + index} className="SceneDirectionMenu__SceneContainer">
                <img className="SceneDirectionMenu__SceneTile" src={activeScene?.thumbnailImage} alt={choice.title} />
              </div>
            );
          }

          const { Icon } = choice;

          return (
            <OptionMenuButton
              key={direction === 'mixed' ? 'mixed' : choice.title}
              selected={choice.key === direction}
              onClick={() => handleButtonClick(choice.key)}
              tooltipProps={{
                'data-tip': choice.title,
                'data-for': TOOLTIP_ID
              }}
            >
              {Icon && <Icon />}
            </OptionMenuButton>
          );
        })}
      </div>
      <ReactTooltip id={TOOLTIP_ID} className="t-utility-label" />
    </>
  );
};

export default SceneDirectionMenu;
