import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { VSImageShapeOrTextElementModel } from 'js/types';

const shapePropertyKeys: Array<keyof VSImageShapeOrTextElementModel> = ['scaleX', 'scaleY', 'width', 'height'];
const textPropertyKeys: Array<keyof ScribeTextElementModel> = ['scaleX', 'scaleY', 'width', 'height', 'fontSize'];

export const elementDimensionsHaveChanged = (
  oldElement: ScribeTextElementModel | VSImageShapeOrTextElementModel,
  newElement: ScribeTextElementModel | VSImageShapeOrTextElementModel
): boolean => {
  if (oldElement.type === 'Text' && newElement.type === 'Text') {
    return textPropertyKeys.some(key => oldElement[key] !== newElement[key]);
  }

  return shapePropertyKeys.some(key => oldElement[key] !== newElement[key]);
};
