import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { upgradeAccount } from 'js/actionCreators/userAccountAction';
import { UpgradeSubscriptionClickedEventTrigger } from 'js/actionCreators/trackingActions';

import PillButton from '../shared/components/PillButton';

import './Notification.css';

export const WARNING = 'warning';
export const ERROR = 'error';
export const INFO = 'info';

type NotificationType = 'warning' | 'error' | 'info';

interface Props {
  classes?: Array<string>;
  message?: string;
  type: NotificationType;
  onUpgradeClick?: () => void;
  children?: React.ReactNode;
}

export function Notification({ classes, message, type, onUpgradeClick, children }: Props) {
  if (type !== INFO) {
    return (
      <div className={classnames('notification', `notification--${type}`, classes)}>
        <div className="notification__icon">
          <Icon />
        </div>
        {message && <p className="notification__text">{message}</p>}
        {children}
      </div>
    );
  } else {
    return (
      <div className={classnames('notification', `notification--${type}`, classes)}>
        <div className="notification__icon-info">
          <Icon />
        </div>
        {message && <p className="notification__text-info">{message}</p>}
        {children}
        <div className="notification__upgrade">
          <PillButton
            onClick={() => {
              if (onUpgradeClick) {
                onUpgradeClick();
              }
            }}
            variant="primary"
          >
            {'Upgrade'}
          </PillButton>
        </div>
      </div>
    );
  }
}

function Icon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z" />
    </svg>
  );
}

const ConnectedNotification = connect(null, dispatch => {
  return {
    onUpgradeClick() {
      dispatch(upgradeAccount(UpgradeSubscriptionClickedEventTrigger.DOWNLOAD_SUCCESS_BANNER));
    }
  };
})(Notification);

export default ConnectedNotification;
