import { animationTypeHasNoDuration } from './animationTypeHasNoDuration';
import getElementAnimationType from './getElementAnimationType';

const getAnimationType = ({
  element,
  elements,
  scribe,
  duration,
  tweenProperty,
  cursors,
  durationKey,
  animationProperty
}) => {
  if (element) {
    const elementAnimationType = getElementAnimationType({
      element,
      scribe,
      tweenProperty,
      cursors,
      durationKey,
      animationProperty
    });

    if (animationTypeHasNoDuration(elementAnimationType)) {
      return elementAnimationType;
    } else {
      return !duration ? 'none' : elementAnimationType;
    }
  }

  const firstElementAnimationType = getElementAnimationType({
    element: elements[0],
    scribe,
    tweenProperty,
    cursors,
    durationKey,
    animationProperty
  });
  const allSame = elements.every(
    el =>
      firstElementAnimationType ===
      getElementAnimationType({ element: el, scribe, tweenProperty, cursors, durationKey, animationProperty })
  );
  if (allSame) return firstElementAnimationType;

  return 'mixed';
};

export default getAnimationType;
