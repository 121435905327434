import {
  ElementAnimationStageDurationKey,
  ExistingScribeModel,
  PlaybackImageResources,
  Cursor,
  VSImageShapeOrTextElementModel,
  ScribeScene
} from 'js/types';
import * as PIXI from 'pixi.js';
import clamp from 'lodash.clamp';
import {
  getElementEmphasisTimeMs,
  getElementPauseTimeMs,
  getSceneStartTimeAndLength
} from 'js/playback/lib/Playback/helpers/timings';
import {
  HAND_DRAW_KEY,
  NO_ANIMATION_KEY,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  EXIT_ANIMATION_ERASE_KEY,
  MAX_ANIMATION_TIME_SECONDS
} from 'js/config/consts';
import {
  EMPHASIS_ANIMATION_OPTIONS,
  ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY,
  ENTRANCE_ANIMATION_OPTIONS,
  EXIT_ANIMATION_OPTIONS
} from 'js/config/animationOptions';
import getAnimationType from 'js/shared/helpers/getAnimationType';
import { getActiveScene, getScenesById } from 'js/shared/helpers/scenesHelpers';
import { defaultCanvasColor } from 'js/config/defaults';
import isEqual from 'lodash.isequal';
import getScribeLengthMs from 'js/playback/lib/Playback/helpers/getScribeLengthMs';

import TimelineElementGrabHandle from './TimelineElementGrabHandle';
import TimelineHelpers, { getElementExitTimeForDefinedAnimationMs } from './Utils/TimelineHelpers';
import TimelineLayerThumbnail from './TimelineLayerThumbnail';
import timelineBus, {
  LAYER_CLICKED,
  PLAYHEAD_HIDE,
  PLAYHEAD_MOVE,
  PLAYHEAD_SHOW,
  TOOLTIP_HIDE,
  TOOLTIP_SHOW
} from './TimelineControlBus';
import { TimelineTimingsComparisonModel } from './TimelineTimingsComparisonModel';
import { ELEMENT_ANIMATION_TOOLTIPS, TOOLTIP_ALIGN_CENTRAL, TOOLTIP_ALIGN_LEFT } from './TimelineLayerTooltip';
import TimelineWrapper, {
  TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
  TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR,
  TIMELINE_LAYER_FOREGROUND_COLOUR,
  TIMINGS_CHANGED
} from './TimelineWrapper';
import GetAnimationIcon, { isAllAnimationTypeKey } from './Utils/GetAnimationIcon';
import { MAX_LANE_ZOOM, MIN_LANE_ZOOM, THUMBNAIL_WIDTH } from './consts';
import Maths from './Utils/Maths';
import LayerBase from './LayerBase';
import { getBitmapRect } from './Utils/GetBitmapRect';

const TIMELINE_ICON_VISIBILITY_THRESHOLD = 10;
const LAYER_GAP = 1;

const iconAnimationTimeName = 'iconAnimationTime';
const iconEmphasisAnimationTimeName = 'iconEmphasisAnimationTime';
const iconExitAnimationTimeName = 'iconExitAnimationTime';
const iconPauseTimeName = 'iconPauseTime';

export interface TimelineElementLayerConfig {
  elementModel: VSImageShapeOrTextElementModel;
  sceneID: string;
  scribe: ExistingScribeModel;
  sceneStartTime: number;
  currentSceneLength: number;
  isActive: boolean;
  wrapperRef: TimelineWrapper;
  imageResources: PlaybackImageResources;
  cursors: Array<Cursor>;
}

export default class TimelineElementLayer extends LayerBase {
  public elementModel: VSImageShapeOrTextElementModel;
  public leftPosition: number;
  public rightPosition: number;
  timeTypes: Array<ElementAnimationStageDurationKey> = [
    'animationTime',
    'emphasisAnimationTime',
    'exitAnimationTime',
    'pauseTime'
  ];

  scribeLengthLimit = 0;

  iconAnimationTime?: PIXI.Sprite;
  iconEmphasisAnimationTime?: PIXI.Sprite;
  iconExitAnimationTime?: PIXI.Sprite;
  iconPauseTime?: PIXI.Sprite;
  emphasisAnimationTime: number;
  pauseTime: number;
  exitAnimationTime: number | undefined;
  icons: PIXI.Container;
  activeElementOutline: PIXI.Graphics;
  minimumIconWidth = 34;
  entranceType?: string;
  tooltipThumb: TimelineLayerThumbnail;
  emphasisType?: string;
  exitType?: string;

  cursors: Array<Cursor>;

  type = 'Element' as const;
  foregroundGraphicsHolder: PIXI.Container<PIXI.DisplayObject>;
  lastCalculations?: {
    remainsOnStage: boolean;
    elementExits: boolean;
    pauseTimeSectionX: number;
    endpointLength: number;
    elementModel: VSImageShapeOrTextElementModel;
    currentZoomX: number;
    currentZoomY: number;
    currentSceneLength: number;
    timeLeftInScene: number;
  };
  mainBlock?: PIXI.Graphics;
  pauseTimeBlock?: PIXI.Graphics;
  continuationBlock?: PIXI.Graphics;
  currentSceneModel: ScribeScene;
  constructor({
    elementModel,
    sceneID,
    scribe,
    sceneStartTime,
    currentSceneLength,
    isActive,

    wrapperRef,
    imageResources,
    cursors
  }: TimelineElementLayerConfig) {
    super({
      elementModel,
      sceneID,
      scribe,
      sceneStartTime,
      currentSceneLength,
      isActive,
      wrapperRef,
      imageResources
    });
    this.elementModel = elementModel as VSImageShapeOrTextElementModel;
    this.cursors = cursors;
    this.emphasisAnimationTime = Math.round(getElementEmphasisTimeMs(elementModel)) / 1000;
    this.exitAnimationTime = getElementExitTimeForDefinedAnimationMs(elementModel, scribe);
    if (this.exitAnimationTime !== undefined) this.exitAnimationTime = Math.round(this.exitAnimationTime) / 1000;
    this.pauseTime = Math.round(getElementPauseTimeMs(elementModel, scribe)) / 1000;
    this.overlay = new PIXI.Graphics();
    this.backgroundGraphics = new PIXI.Graphics();
    this.backgroundGraphics.interactive = true;
    this.backgroundGraphics.buttonMode = true;
    this.backgroundGraphics
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd)
      .on('click', this.handleClick, this);
    this.foregroundGraphicsHolder = new PIXI.Container();
    this.foregroundGraphics = new PIXI.Graphics();
    this.foregroundSprites = new PIXI.Sprite();
    this.icons = new PIXI.Container();
    this.icons.interactive = true;
    this.icons.buttonMode = true;

    this.icons
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd)
      .on('click', this.handleClick, this);

    this.activeElementOutline = new PIXI.Graphics();
    this.addChild(this.backgroundGraphics);
    this.addChild(this.continuationGraphics);
    this.addChild(this.foregroundGraphicsHolder);
    this.addChild(this.foregroundSprites);
    this.addChild(this.overlay);
    this.foregroundSprites.addChild(this.icons);
    this.foregroundSprites.addChild(this.activeElementOutline);
    this.thumb = this.createThumbnail();
    this.setIcons();
    this.currentSceneModel = getScenesById([this.sceneID], this.scribe)[0];
    this.populate();
    this.updateZoom();
    this.updateIsActive(isActive);
    this.leftPosition = 0;
    this.rightPosition = 0;

    const activeScene = getActiveScene(scribe);
    this.tooltipThumb = new TimelineLayerThumbnail({
      element: this.elementModel,
      zoomValue: this.currentZoom,
      wrapperRef: this.wrapperRef,
      backgroundSettings: activeScene?.settings ?? {
        backgroundColor: defaultCanvasColor
      },
      imageResources: this.imageResources
    });
  }

  createThumbnail() {
    return new TimelineLayerThumbnail({
      element: this.elementModel as VSImageShapeOrTextElementModel,
      zoomValue: this.currentZoom,
      wrapperRef: this.wrapperRef,
      backgroundSettings: getActiveScene(this.scribe)?.settings ?? {
        backgroundColor: defaultCanvasColor
      },
      imageResources: this.imageResources
    });
  }
  updateProperties(changes: TimelineTimingsComparisonModel, newModel: VSImageShapeOrTextElementModel) {
    const elementModel = this.elementModel as VSImageShapeOrTextElementModel;
    const thumb = this.thumb as TimelineLayerThumbnail;

    this.foregroundSprites.removeChildren();
    this.icons.removeChildren();
    this.foregroundSprites.addChild(this.icons);
    this.foregroundSprites.addChild(this.activeElementOutline);
    this.elementModel = newModel;
    this.startTime = this.endTime = changes.startTime / 1000;
    if (changes.animationTime !== undefined)
      this.elementModel.animationTime = this.animationTime = changes.animationTime / 1000;
    if (changes.emphasisAnimationTime !== undefined)
      this.elementModel.emphasisAnimationTime = this.emphasisAnimationTime = changes.emphasisAnimationTime / 1000;
    if (changes.exitAnimationTime !== undefined)
      this.elementModel.exitAnimationTime = this.exitAnimationTime = changes.exitAnimationTime / 1000;
    if (changes.pauseTime !== undefined) this.elementModel.pauseTime = this.pauseTime = changes.pauseTime / 1000;
    if (this.elementModel.cursorId !== changes.cursorId) this.elementModel.cursorId = changes.cursorId;
    this.timeTypes = ['animationTime', 'emphasisAnimationTime', 'exitAnimationTime', 'pauseTime'];
    this.populate();
    this.setIcons();
    this.drawIcons();
    this.setHandleClamps();
    this.updateIsActive();
    this.currentSceneModel = getScenesById([this.sceneID], this.scribe)[0];
    thumb.updateGraphic(elementModel);

    this.tooltipThumb.updateGraphic(this.elementModel);
    this.updateZoom();
  }

  public updateScribe(scribe: ExistingScribeModel) {
    const activeScene = getActiveScene(scribe);

    if (activeScene) {
      (this.thumb as TimelineLayerThumbnail).updateBackgroundSettings(activeScene.settings);
    }
  }

  public updateImageResources(imageResources: PlaybackImageResources) {
    (this.thumb as TimelineLayerThumbnail).updateImageResources(imageResources);
  }

  protected updateIsActive(isActive?: boolean) {
    this.activeElementOutline.clear();
    if (isActive !== undefined) {
      this.isActive = isActive;
    }
    if (this.isActive) {
      this.activeElementOutline.lineStyle({
        width: TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
        color: TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR,
        alignment: 0
      });
      this.activeElementOutline.drawRect(
        this.startTime * this.pixelsPerSecond,
        1,
        !this.remainsOnStage
          ? this.getEndX() - this.getStartX()
          : (this.currentSceneLength - (this.startTime - this.sceneStartTime)) * this.pixelsPerSecond,
        this.layerHeight - LAYER_GAP
      );
      this.parent?.addChild(this);
    }
  }

  private populate(): void {
    for (let i = this.timeTypes.length - 1; i > -1; i--) {
      if (this[this.timeTypes[i]] === undefined || this[this.timeTypes[i]] === 0) {
        this.timeTypes.splice(i, 1);
      }
    }
    if (this.handles !== undefined && this.handles.length > 0) {
      this.handles.forEach(handle => {
        this.foregroundSprites.removeChild(handle);
        handle.destroy();
      });
    }
    this.drawElementTimeOnStage();
    let timeTypeIndex = 0;
    let handleX: number = this.startTime * this.pixelsPerSecond; //left handle
    let handle: TimelineElementGrabHandle;
    this.foregroundSprites.interactiveChildren = true;
    this.handles = [];
    for (timeTypeIndex; timeTypeIndex < this.timeTypes.length; timeTypeIndex++) {
      const timeProperty = this[this.timeTypes[timeTypeIndex]];
      if (timeProperty !== undefined) {
        handle = new TimelineElementGrabHandle(timeTypeIndex, false, this.wrapperRef, this.handleRightClick);
        handle.x = handleX;
        handleX += timeProperty * this.pixelsPerSecond;
        this.foregroundSprites.addChild(handle);
        this.handles.push(handle);
        handle.on('onGrabHandleDragged', this.grabHandleAdjust);
        handle.on('onGrabHandleDragComplete', this.timingsChanged);
      }
    }
    handle = new TimelineElementGrabHandle(timeTypeIndex, true, this.wrapperRef, this.handleRightClick); //right handle
    handle.x = handleX;
    this.endTime = handleX / this.pixelsPerSecond;
    this.foregroundSprites.addChild(handle);
    this.handles.push(handle);
    handle.on('onGrabHandleDragged', this.grabHandleAdjust);
    handle.on('onGrabHandleDragComplete', this.timingsChanged);
    this.setHandleClamps();
    this.foregroundSprites.addChild(this.activeElementOutline);
  }

  timingsChanged = (handle: TimelineElementGrabHandle, event: PIXI.InteractionEvent) => {
    timelineBus.emit(TOOLTIP_HIDE);
    timelineBus.emit(PLAYHEAD_HIDE);
    const allTimings: { [k in ElementAnimationStageDurationKey]?: number } = {};
    if (event.data.originalEvent.shiftKey && !handle.lastHandle) {
      allTimings[this.timeTypes[handle.index]] = clamp(
        TimelineHelpers.roundNumber(this[this.timeTypes[handle.index]]),
        0,
        this.sceneStartTime + MAX_ANIMATION_TIME_SECONDS
      );
    }
    allTimings[this.timeTypes[handle.index - 1]] = clamp(
      TimelineHelpers.roundNumber(this[this.timeTypes[handle.index - 1]]),
      0,
      this.sceneStartTime + MAX_ANIMATION_TIME_SECONDS
    );
    this.emit(TIMINGS_CHANGED, this.elementModel.id, allTimings);
    if (handle.lastHandle) {
      this.scribeLengthLimit = 0;
    }
  };

  private setHandleClamps(): void {
    this.handles.forEach((handle, handleIndex, handles) => {
      const isFirstHandle = handleIndex === 0;
      const isLastHandle = handleIndex === handles.length - 1;
      const nextHandle = handles[handleIndex + 1];
      const previousHandle = handles[handleIndex - 1];

      let lower = 0;
      let upper = Infinity;

      if (isFirstHandle && nextHandle) {
        lower = this.startTime * this.pixelsPerSecond;
        upper = nextHandle.x;
      } else if (isLastHandle && previousHandle) {
        const maxX = Math.floor(previousHandle.x + MAX_ANIMATION_TIME_SECONDS * this.pixelsPerSecond);

        lower = previousHandle.x;
        upper = maxX;
      } else if (previousHandle) {
        const maxX = Math.floor(previousHandle.x + MAX_ANIMATION_TIME_SECONDS * this.pixelsPerSecond);

        lower = previousHandle.x;
        upper = Math.min(nextHandle.x, maxX);
      }

      handle.setClamps(lower, upper);
    });
  }

  protected drawBackgroundGraphics() {
    if (this.wrapperRef.scrollBox) {
      this.backgroundGraphics.hitArea = new PIXI.Rectangle(
        this.sceneStartTime * this.pixelsPerSecond,
        1,
        this.currentSceneLength * this.pixelsPerSecond,
        this.layerHeight - 1
      );
    }
  }

  public getGlobalStartPosition() {
    const global = this.foregroundGraphics.toGlobal(this.wrapperRef.app.stage);
    return new PIXI.Point(global.x + this.getStartX(), global.y);
  }
  get remainsOnStage() {
    const elementModel = this.elementModel as VSImageShapeOrTextElementModel;
    return (
      this.exitAnimationTime === undefined ||
      this.exitType === undefined ||
      this.exitType === 'none' ||
      (this.exitAnimationTime === 0 && elementModel.exitTween === undefined)
    );
  }
  protected drawForegroundGraphics() {
    const elementModel = this.elementModel as VSImageShapeOrTextElementModel;

    const endpointLength =
      (this.animationTime + this.emphasisAnimationTime + (this.exitAnimationTime ?? 0)) * this.pixelsPerSecond;
    this.foregroundGraphics.removeChildren();
    const elementExits =
      (this.exitType === EXIT_ANIMATION_ERASE_KEY &&
        this.exitAnimationTime !== undefined &&
        this.exitAnimationTime > 0) ||
      (this.exitType !== undefined &&
        this.exitType !== 'none' &&
        this.pauseTime > 0 &&
        this.exitAnimationTime !== undefined &&
        elementModel.exitTween !== undefined);
    const pauseTimeSectionX =
      (this.startTime + this.animationTime + this.emphasisAnimationTime + (this.exitAnimationTime ?? 0)) *
      this.currentZoom.x;
    const pauseTimeSectionWidth = this.pauseTime * this.currentZoom.x - 1;
    this.currentSceneLength =
      getSceneStartTimeAndLength(this.wrapperRef.props.activeScribe, this.currentSceneModel).sceneLength / 1000;
    this.recalculateEndTime();
    const timeLeftInScene = this.sceneStartTime + this.currentSceneLength - this.endTime;
    const currentCals = {
      remainsOnStage: this.remainsOnStage,
      elementExits,
      pauseTimeSectionX,
      endpointLength,
      elementModel,
      currentZoomX: this.currentZoom.x,
      currentZoomY: this.currentZoom.y,
      currentSceneLength: this.currentSceneLength,
      timeLeftInScene
    };

    if (isEqual(this.lastCalculations, currentCals)) {
      return;
    }

    this.lastCalculations = {
      remainsOnStage: this.remainsOnStage,
      elementExits,
      pauseTimeSectionX,
      endpointLength,
      elementModel,
      currentZoomX: this.currentZoom.x,
      currentZoomY: this.currentZoom.y,
      currentSceneLength: this.currentSceneLength,
      timeLeftInScene
    };

    this.foregroundGraphicsHolder.x = 0;

    if (!this.mainBlock) {
      this.mainBlock = getBitmapRect(this.layerHeight - 1, TIMELINE_LAYER_FOREGROUND_COLOUR);
    }
    this.mainBlock.height = this.layerHeight - 1;
    this.mainBlock.x = this.startTime * this.currentZoom.x;
    this.mainBlock.y = 1;
    this.mainBlock.scale.x = endpointLength / 100;
    this.foregroundGraphicsHolder.addChild(this.mainBlock);

    // pause time section
    if (this.remainsOnStage || elementExits) {
      if (!this.pauseTimeBlock) {
        this.pauseTimeBlock = getBitmapRect(this.layerHeight - 1, TIMELINE_LAYER_FOREGROUND_COLOUR);
      }
      this.pauseTimeBlock.height = this.layerHeight - 1;
      this.pauseTimeBlock.x = pauseTimeSectionX;
      this.pauseTimeBlock.y = 1;
      this.pauseTimeBlock.scale.x = pauseTimeSectionWidth / 100;
      this.foregroundGraphicsHolder.addChild(this.pauseTimeBlock);
    } else {
      this.pauseTimeBlock?.parent?.removeChild(this.pauseTimeBlock);
    }

    if (this.remainsOnStage) {
      if (!this.continuationBlock) {
        this.continuationBlock = getBitmapRect(this.layerHeight - 1, TIMELINE_LAYER_FOREGROUND_COLOUR);
      }
      this.continuationBlock.height = this.layerHeight - 1;
      this.continuationBlock.x = this.endTime * this.currentZoom.x;
      this.continuationBlock.y = 1;
      this.continuationBlock.scale.x = (timeLeftInScene * this.currentZoom.x) / 100;
      this.foregroundGraphicsHolder.addChild(this.continuationBlock);
    } else {
      this.continuationBlock?.parent?.removeChild(this.continuationBlock);
    }
    this.updateIsActive();
  }

  public recalculateEndTime(): void {
    this.endTime = this.startTime;
    for (let i = 0; i < this.timeTypes.length; i++) {
      const phaseTime = this[this.timeTypes[i]];
      if (phaseTime !== undefined && !isNaN(phaseTime)) this.endTime += phaseTime;
    }
  }

  private grabHandleAdjust = (handle: TimelineElementGrabHandle, event: PIXI.InteractionEvent): void => {
    event.stopPropagation();
    handle.x = Math.round(Math.ceil(handle.x / this.pixelsPerSecond / 0.1) * 0.1 * this.pixelsPerSecond);

    let delta: number;

    if (handle.index === 0) {
      return;
    } else if (handle.lastHandle) {
      delta = (this.endTime * this.pixelsPerSecond - handle.x) / this.pixelsPerSecond;
      if (
        TimelineHelpers.roundNumber(handle.x) >= TimelineHelpers.roundNumber(this.handles[this.handles.length - 2].x)
      ) {
        this[this.timeTypes[handle.index - 1]] -= delta;
        this.endTime -= delta;
        timelineBus.emit(
          PLAYHEAD_MOVE,
          handle.getGlobalPosition().x,
          TimelineHelpers.formatTime(handle.x / this.pixelsPerSecond)
        );
      } else if (this.handles[this.handles.length - 2].x - this.handles[this.handles.length - 1].x > 0) {
        this[this.timeTypes[handle.index - 1]] = 0;
        handle.x = this.handles[this.handles.length - 2].x = this.handles[this.handles.length - 1].x;
        timelineBus.emit(
          PLAYHEAD_MOVE,
          handle.getGlobalPosition().x,
          TimelineHelpers.formatTime(handle.x / this.pixelsPerSecond)
        );
      }
    } else {
      let prevTime = this[this.timeTypes[handle.index - 1]];
      if (prevTime === undefined || isNaN(prevTime)) prevTime = 0;
      delta = prevTime - (this.handles[handle.index].x - this.handles[handle.index - 1].x) / this.pixelsPerSecond;
      this[this.timeTypes[handle.index - 1]] -= delta;
      if (event.data.originalEvent.shiftKey) {
        this[this.timeTypes[handle.index]] += delta;
      } else {
        this.endTime -= delta;
      }

      timelineBus.emit(
        PLAYHEAD_MOVE,
        handle.getGlobalPosition().x,
        TimelineHelpers.formatTime(handle.x / this.pixelsPerSecond)
      );
    }

    const prevSectionTime = TimelineHelpers.roundNumber(this[this.timeTypes[handle.index - 1]]);
    const nextSectionTime = !handle.lastHandle ? TimelineHelpers.roundNumber(this[this.timeTypes[handle.index]]) : null;
    const prevSectionTooltipText = `${prevSectionTime}s < `;
    const nextSectionTooltipText = nextSectionTime ? `> ${nextSectionTime}s` : '';
    const tipText = `${prevSectionTooltipText}${nextSectionTooltipText}`;

    const diff = handle.x / this.pixelsPerSecond - this.scribeLengthLimit;
    if (diff && this.wrapperRef.topMeasure && this.wrapperRef.scrollBox && this.scribeLengthLimit > 0) {
      const scribeLength = getScribeLengthMs(this.scribe);
      this.wrapperRef.topMeasure.updateScribeLength(
        scribeLength / 1000,
        new PIXI.Point(this.pixelsPerSecond, this.layerHeight)
      );
    }
    timelineBus.emit(
      TOOLTIP_SHOW,
      event,
      [tipText],
      handle.lastHandle ? TOOLTIP_ALIGN_LEFT : TOOLTIP_ALIGN_CENTRAL,
      this.layerHeight,
      handle
    );
    timelineBus.emit(PLAYHEAD_SHOW, this);
    if (handle.lastHandle && this.scribeLengthLimit === 0) this.scribeLengthLimit = this.currentSceneLength;
    if (handle.lowerClamp && handle.lastHandle && this.scribeLengthLimit !== 0 && handle.x > handle.lowerClamp) {
      this.currentSceneLength -= delta;
      this.drawForegroundGraphics();
    }
    this.redraw();
  };

  private getIconVisibilityThreshold(): number {
    const value = Maths.rangeValue(MIN_LANE_ZOOM, MAX_LANE_ZOOM, this.layerHeight);
    return Math.round(value * TIMELINE_ICON_VISIBILITY_THRESHOLD);
  }

  public drawIcons() {
    this.icons.removeChildren();
    //draw the thumbnail if there is enough space in the first section
    if (this.handles.length > 1 && this.thumb) {
      const firstSectionWidth = this.handles[1].x - this.handles[0].x;
      if (firstSectionWidth > this.minimumIconWidth) {
        const iconPadding = Math.round((this.layerHeight - this.thumb.background.height) / 2) - 0.5;
        this.thumb.x = this.handles[0].x + iconPadding;
        this.thumb.y = iconPadding;
        this.thumb.scale.x = this.thumb.scale.y = 1;
        //if the element is a text element add rollover to the thumbnail
        if (this.elementModel.type === 'Text') {
          this.thumb.removeAllListeners();
          this.thumb.on('pointerover', this.rolloverIcon);
          this.thumb.on('pointerout', this.rolloffIcon);
          this.thumb.on('pointerdown', this.onDragStart);
          this.thumb.on('pointerup', this.onDragEnd);
          this.thumb.on('x', this.onDragEnd);

          this.thumb.on('click', this.handleClick, this);
          this.thumb.interactive = true;
          this.thumb.buttonMode = true;
        } else {
          this.thumb.off('pointerover', this.rolloverIcon);
          this.thumb.off('pointerout', this.rolloffIcon);
          this.thumb.interactive = false;
          this.thumb.buttonMode = false;
        }
        this.addChild(this.thumb);
      } else {
        this.removeChild(this.thumb);
      }
    }
    for (let i = 0; i < this.handles.length - 1; i++) {
      const handle: TimelineElementGrabHandle = this.handles[i];
      switch (this.timeTypes[handle.index]) {
        case 'animationTime':
          if (this.iconAnimationTime) this.addIcon(this.iconAnimationTime, handle, i);
          break;
        case 'emphasisAnimationTime':
          if (this.iconEmphasisAnimationTime) this.addIcon(this.iconEmphasisAnimationTime, handle, i);
          break;
        case 'exitAnimationTime':
          if (this.iconExitAnimationTime) this.addIcon(this.iconExitAnimationTime, handle, i);
          break;
        default:
          if (this.iconPauseTime) this.addIcon(this.iconPauseTime, handle, i);
          break;
      }
    }
    if (this.leftPosition === this.rightPosition && this.thumb) {
      const smallerThumbForZeroElementsScale = 0.75;
      this.thumb.scale.x = this.thumb.scale.y = smallerThumbForZeroElementsScale;
      const iconPadding =
        Math.round((this.layerHeight - this.thumb.background.height * smallerThumbForZeroElementsScale) / 2) - 0.5;
      this.thumb.x = this.handles[0].x + iconPadding;
      this.thumb.y = iconPadding;
      if (this.elementModel.type !== 'Text') {
        this.thumb.removeAllListeners();
        this.thumb
          .on('pointerover', this.rolloverIcon)
          .on('pointerout', this.rolloffIcon)
          .on('pointerdown', this.onDragStart)
          .on('pointerup', this.onDragEnd)
          .on('click', this.handleClick);
        this.thumb.interactive = true;
        this.thumb.buttonMode = true;
      }
      this.thumb.cacheAsBitmap = true;
      this.addChild(this.thumb);
    }
  }

  private addIcon(icon: PIXI.Sprite | TimelineLayerThumbnail, handle: TimelineElementGrabHandle, handleIndex: number) {
    const iconPadding = 2;
    const availableSpace = this.handles[handleIndex + 1].x - handle.x;
    if (
      (handleIndex !== 0 && availableSpace - this.getIconVisibilityThreshold() > this.minimumIconWidth) ||
      (this.thumb && this.thumb.visible && handleIndex === 0 && availableSpace > THUMBNAIL_WIDTH * 2)
    ) {
      icon.x = handle.x + (availableSpace - icon.width) / 2;
      if (this.thumb && this.thumb.visible && handleIndex === 0) icon.x += THUMBNAIL_WIDTH / 2;
      icon.height = icon.width = this.layerHeight - iconPadding * 2;
      icon.y = iconPadding;
      icon.alpha = 1;
    } else {
      icon.x = handle.x;
      icon.width = availableSpace;
      icon.height = this.layerHeight;
      icon.alpha = 0;
    }
    icon.cacheAsBitmap = true;
    this.icons.addChild(icon);
  }

  private handleClick = (event: PIXI.InteractionEvent) => {
    this.cacheAsBitmap = false;
    this.wrapperRef.dragging = false;
    event.stopPropagation();
    timelineBus.emit(TOOLTIP_HIDE);
    this.emit(LAYER_CLICKED, this.id, event);
  };

  public handleRightClick = (event: PIXI.InteractionEvent) => {
    this.cacheAsBitmap = false;
    event.stopPropagation();
    event.data.originalEvent.preventDefault();
    event.data.originalEvent.stopPropagation();
    window.setTimeout(() => this.wrapperRef.onContextClick(context), 1);
    if (event.data.originalEvent.ctrlKey && !this.wrapperRef.isMac) {
      return;
    }
    this.emit(LAYER_CLICKED, this.id, event);

    const x = (event.data.originalEvent as MouseEvent).clientX;
    const y = (event.data.originalEvent as MouseEvent).clientY;

    const context = {
      type: 'element' as const,
      scribeId: this.wrapperRef.props.activeScribe.id,
      mouseX: x,
      mouseY: y,
      elementId: this.elementModel.id
    };
  };

  updateScribeLength(sceneLength: number) {
    this.currentSceneLength = sceneLength;
    this.drawElementTimeOnStage();
  }

  private configureIcon(icon: PIXI.Texture, name: string): PIXI.Sprite {
    const sprite = new PIXI.Sprite(icon);
    sprite.interactive = true;
    sprite.name = name;
    sprite.on('pointerover', this.rolloverIcon).on('pointerout', this.rolloffIcon);
    return sprite;
  }

  private setIcons() {
    const elementModel = this.elementModel as VSImageShapeOrTextElementModel;
    const entranceType = getAnimationType({
      element: elementModel,
      elements: [],
      scribe: this.scribe,
      duration:
        elementModel.animationTime !== undefined ? elementModel.animationTime : this.scribe.settings.animationTime,
      tweenProperty: ENTRANCE_TWEEN_DOCUMENT_KEY,
      cursors: this.cursors,
      durationKey: ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY,
      animationProperty: ENTRANCE_ANIMATION_DOCUMENT_KEY
    });

    if (entranceType !== 'none') {
      this.entranceType = entranceType;
    } else {
      this.entranceType = HAND_DRAW_KEY;
    }

    if (this.entranceType && isAllAnimationTypeKey(this.entranceType)) {
      this.iconAnimationTime = this.configureIcon(GetAnimationIcon.getIcon(this.entranceType), iconAnimationTimeName);
    }

    if (elementModel.emphasisAnimationTime !== undefined) {
      this.emphasisType = elementModel.emphasisTween ? elementModel.emphasisTween.id : 'none';

      if (this.emphasisType && isAllAnimationTypeKey(this.emphasisType)) {
        this.iconEmphasisAnimationTime = this.configureIcon(
          GetAnimationIcon.getIcon(this.emphasisType),
          iconEmphasisAnimationTimeName
        );
      }
    }

    if (elementModel.exitAnimationTime !== undefined) {
      if (elementModel.exitTween) {
        this.exitType = elementModel.exitTween.id;
      } else if (elementModel.exitAnimation) {
        this.exitType = elementModel.exitAnimation.id;
      }

      if (this.exitType && isAllAnimationTypeKey(this.exitType)) {
        this.iconExitAnimationTime = this.configureIcon(
          GetAnimationIcon.getIcon(this.exitType),
          iconExitAnimationTimeName
        );
      }
    }

    this.iconPauseTime = this.configureIcon(GetAnimationIcon.getIcon('pause'), iconPauseTimeName);
  }

  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    // Remove the assignment to the 'destroyed' property

    if (this.thumb) this.thumb.destroy({ children: true, texture: true, baseTexture: false });

    this.iconPauseTime?.destroy({ children: true, texture: true, baseTexture: false });
    this.iconAnimationTime?.destroy({ children: true, texture: true, baseTexture: false });
    this.iconEmphasisAnimationTime?.destroy({ children: true, texture: true, baseTexture: false });
    this.iconExitAnimationTime?.destroy({ children: true, texture: true, baseTexture: false });
    this.backgroundGraphics.destroy({ children: true, texture: true, baseTexture: false });
    this.icons.destroy({ children: true, texture: true, baseTexture: false });
    this.handles.forEach(handle => {
      handle.destroy({ children: true, texture: true, baseTexture: false });
    });
    this.handles = [];
    this.overlay.destroy({ children: true, texture: true, baseTexture: false });
    this.continuationGraphics.destroy({ children: true, texture: true, baseTexture: false });
    this.continuationBlock?.destroy();
    this.foregroundGraphics.destroy({ children: true, texture: true, baseTexture: false });
    this.activeElementOutline.destroy({ children: true, texture: true, baseTexture: false });
    this.foregroundGraphicsHolder.destroy({ children: true, texture: true, baseTexture: false });
    this.lastCalculations = undefined;
    super.destroy(options);
  }

  private rolloverIcon = (event: PIXI.InteractionEvent) => {
    if (this.wrapperRef.dragging) return;
    let rolloverText: (string | TimelineLayerThumbnail)[] = [''];
    switch (event.target.name) {
      case iconAnimationTimeName:
        const entranceType: string = this.entranceType === undefined ? HAND_DRAW_KEY : this.entranceType;
        const entranceAnimObject = ENTRANCE_ANIMATION_OPTIONS.find(element => element.type === entranceType);
        if (entranceAnimObject) {
          rolloverText = [
            this.tooltipThumb,
            'Entrance Animation',
            entranceAnimObject.title,
            TimelineHelpers.roundNumber(this.animationTime) + 's'
          ];
        }
        break;
      case iconEmphasisAnimationTimeName:
        const emphasisType: string = this.emphasisType === undefined ? NO_ANIMATION_KEY : this.emphasisType;
        const emphasiseAnimObject = EMPHASIS_ANIMATION_OPTIONS.find(element => element.type === emphasisType);
        if (emphasiseAnimObject) {
          rolloverText = [
            this.tooltipThumb,
            'Emphasis Animation',
            emphasiseAnimObject.title,
            TimelineHelpers.roundNumber(this.emphasisAnimationTime) + 's'
          ];
        }
        break;
      case iconExitAnimationTimeName:
        const exitAnimObject = EXIT_ANIMATION_OPTIONS.find(element => element.type === this.exitType);
        if (exitAnimObject) {
          rolloverText = [
            this.tooltipThumb,
            'Exit Animation',
            exitAnimObject.title,
            TimelineHelpers.roundNumber(this.exitAnimationTime) + 's'
          ];
        }
        break;
      case iconPauseTimeName:
        rolloverText = [this.tooltipThumb, 'Pause', TimelineHelpers.roundNumber(this.pauseTime) + 's'];
        break;

      default:
        event.target = this.handles[0];
        if (this.startTime === this.endTime) {
          rolloverText = [this.tooltipThumb, '0s', 'No animations'];
        } else {
          rolloverText = [this.tooltipThumb];
        }
        if (this.elementModel.type === 'Text' && this.elementModel.text) {
          let textString = '';
          if (this.elementModel.text?.length <= 15) {
            textString = this.elementModel.text;
          } else {
            textString = this.elementModel.text.slice(0, 15);
          }
          rolloverText.push(textString);
        }

        break;
    }
    if (!this.wrapperRef.dragging)
      timelineBus.emit(TOOLTIP_SHOW, event, rolloverText, ELEMENT_ANIMATION_TOOLTIPS, this.layerHeight);
  };

  private rolloffIcon = () => {
    timelineBus.emit(TOOLTIP_HIDE);
    if (this.wrapperRef.dragging) return;
  };
  public redraw(): void {
    this.drawElementTimeOnStage();
    let handleX = (this.handles[0].x = this.startTime * this.pixelsPerSecond);
    for (let i = 0; i < this.timeTypes.length; i++) {
      const timeProperty = this[this.timeTypes[i]];
      if (timeProperty !== undefined) {
        const handle = this.handles[i];
        handle.x = handleX;
        handleX += timeProperty * this.pixelsPerSecond;
      }
    }
    this.handles[this.handles.length - 1].x = this.endTime * this.pixelsPerSecond;
    this.leftPosition = this.handles[0].x;
    this.rightPosition = this.handles[this.handles.length - 1].x;

    this.drawIcons();
    this.setHandleClamps();
    this.updateIsActive();
  }
  updateZoom() {
    super.updateZoom();
    if (this.thumb) this.thumb.updateZoom();
    if (this.tooltipThumb) this.tooltipThumb.updateZoom();
  }
}
