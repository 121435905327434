import { getTimeRemaining } from 'js/shared/helpers/getTimeRemaining';

export const getRoundedDaysRemaining = (trialExpiryDate: Date, todayDate = new Date()) => {
  const { days, hours } = getTimeRemaining(trialExpiryDate, todayDate);

  if (hours >= 12) {
    return days + 1;
  }

  return days;
};
