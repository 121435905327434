import {
  CAMERA_EASING_EASEOUT,
  CURSOR_TYPES,
  DRAW_KEY,
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  HAND_DRAW_KEY,
  NO_ANIMATION_KEY,
  PEN_DRAW_KEY
} from 'js/config/consts';

import getCursorTypeFromId from './getCursorTypeFromId';

const getElementAnimationType = ({ element, scribe, tweenProperty, cursors, durationKey, animationProperty }) => {
  if (element[durationKey] === 0) {
    return NO_ANIMATION_KEY;
  }
  if (element.type === 'Camera' && tweenProperty === 'entranceTween') {
    const ease = element.easingType;
    if (ease) return ease;
    return CAMERA_EASING_EASEOUT; // default
  }
  const animation = element[animationProperty];
  if (animation) return animation.id;

  const tween = element[tweenProperty];
  if (tween && tween.id) return tween.id;

  if (tweenProperty === ENTRANCE_TWEEN_DOCUMENT_KEY) {
    const cursorId = element.cursorId ?? scribe.cursor;
    if (!cursorId || cursorId === -1) return DRAW_KEY;

    const cursorType = getCursorTypeFromId({ cursorId, cursors });
    if (!cursorType) return DRAW_KEY;

    return cursorType === CURSOR_TYPES.PEN ? PEN_DRAW_KEY : HAND_DRAW_KEY;
  }
  return NO_ANIMATION_KEY;
};

export default getElementAnimationType;
