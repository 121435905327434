import {
  EXIT_ANIMATION_DOCUMENT_KEY,
  EXIT_TWEEN_DOCUMENT_KEY,
  HAND_DRAW_KEY,
  SCENE_TRANSITION_KEY_NONE
} from 'js/config/consts';
import { sendErrorToSentry } from 'js/logging';
import {
  getElementEmphasisTimeMs,
  getElementEntranceAnimationTimeMs,
  getElementPauseTimeMs,
  getSceneFromElementID,
  getStartTime,
  getTimingForElement
} from 'js/playback/lib/Playback/helpers/timings';
import { AudioClip, HTTPError, ScribeScene, VSImageShapeOrTextElementModel } from 'js/types';
import * as PIXI from 'pixi.js';

import ScribeModel from '../../../../models/ScribeModel';
import { TimelinePropsConnected } from '../../TimelineView';
import TimelineElementLayer from '../TimelineElementLayer';
import { TimelineTimingsComparisonModel } from '../TimelineTimingsComparisonModel';
import { AllTimelineLayerTypes } from '../TimelineWrapper';

export function sceneHasTransition(scene: ScribeScene | undefined): boolean {
  return (
    scene !== undefined &&
    scene.settings !== undefined &&
    scene.settings.sceneTransitionType !== undefined &&
    scene.settings.sceneTransitionType !== SCENE_TRANSITION_KEY_NONE &&
    scene.settings.sceneTransitionTime !== undefined &&
    scene.settings.sceneTransitionTime > 0
  );
}

export function createTimingsComparisonFromProps(props: TimelinePropsConnected): Array<TimelineTimingsComparisonModel> {
  if (props.activeScribe.elements.length === 0) return [];
  const currentScene = getSceneFromElementID(props.activeScribe.elements[0].id, props.activeScribe);
  const elements = props.activeScribe.elements;
  const result = new Array<TimelineTimingsComparisonModel>();
  for (let i = 0; i < elements.length; i++) {
    const elementModel = elements[i];
    let startTime = 0;
    try {
      startTime = getStartTime(props.activeScribe, elementModel.id, currentScene.index);
    } catch (error) {
      sendErrorToSentry(error as HTTPError);
      console.error(error, elementModel, props.activeScribe, currentScene.index);
    }

    let animationType;
    if (elementModel.type !== 'Camera') {
      if (!!elementModel.entranceTween) {
        animationType = elementModel.entranceTween.id;
      } else if (typeof elementModel.cursorId !== 'undefined') {
        animationType = elementModel.cursorId.toString();
      } else if (elementModel.hasOwnProperty('entranceTween')) {
        animationType = undefined;
      } else if (
        animationType === undefined &&
        getElementEntranceAnimationTimeMs(elementModel, props.activeScribe) === 0
      ) {
        animationType = undefined;
      } else {
        animationType = HAND_DRAW_KEY;
      }

      result.push(
        new TimelineTimingsComparisonModel({
          id: elementModel.id,
          startTime: startTime,
          emphasisType: elementModel.emphasisTween !== undefined ? elementModel.emphasisTween.id : 'none',
          exitType: elementModel.exitTween !== undefined ? elementModel.exitTween.id : 'none',
          animationType: animationType,
          cursorId: elementModel.cursorId,
          animationTime: getElementEntranceAnimationTimeMs(elementModel, props.activeScribe),
          emphasisAnimationTime: getElementEmphasisTimeMs(elementModel),
          exitAnimationTime: getElementExitTimeForDefinedAnimationMs(elementModel, props.activeScribe),
          pauseTime: getElementPauseTimeMs(elementModel, props.activeScribe),
          modelString: JSON.stringify(elementModel),
          locked: elementModel.locked,
          outOfCamera: elementModel.outOfCamera
        })
      );
    } else {
      result.push(
        new TimelineTimingsComparisonModel({
          id: elementModel.id,
          startTime: startTime,
          emphasisType: 'none',
          exitType: 'none',
          animationType: animationType,
          cursorId: 'default',
          animationTime: getElementEntranceAnimationTimeMs(elementModel, props.activeScribe),
          emphasisAnimationTime: getElementEmphasisTimeMs(elementModel),
          exitAnimationTime: undefined,
          pauseTime: getElementPauseTimeMs(elementModel, props.activeScribe),
          modelString: JSON.stringify(elementModel),
          locked: elementModel.locked
        })
      );
    }
  }
  return result;
}

function isVSImageShapeOrTextElementModel(input: AllTimelineLayerTypes): input is TimelineElementLayer {
  return input.elementModel && input.elementModel.type !== 'Camera';
}

export function createTimingsComparisonFromLayers(
  layers: Array<AllTimelineLayerTypes>
): Array<TimelineTimingsComparisonModel> {
  const result: Array<TimelineTimingsComparisonModel> = new Array<TimelineTimingsComparisonModel>();
  layers.forEach(layer => {
    const entranceType =
      layer.type !== 'SceneTransition' && layer.entranceType !== undefined ? layer.entranceType : undefined;

    result.push(
      new TimelineTimingsComparisonModel({
        id: layer.id,
        startTime: Math.round(layer.startTime * 1000),
        emphasisType:
          layer.type !== 'SceneTransition' && layer.emphasisType !== undefined ? layer.emphasisType : 'none',
        exitType: layer.type !== 'SceneTransition' && layer.exitType !== undefined ? layer.exitType : 'none',
        animationType:
          Math.round(layer.animationTime * 1000) === 0 || entranceType === undefined ? undefined : entranceType,
        cursorId: layer.type === 'Element' ? (layer as TimelineElementLayer).elementModel.cursorId : undefined,
        animationTime: Math.round(layer.animationTime * 1000),
        emphasisAnimationTime: layer.type === 'Element' ? Math.round(layer.emphasisAnimationTime * 1000) : 0,
        exitAnimationTime:
          layer.exitAnimationTime !== undefined ? Math.round(layer.exitAnimationTime * 1000) : undefined,
        pauseTime: Math.round(layer.pauseTime * 1000),
        modelString: JSON.stringify(layer.elementModel),
        locked: layer.elementModel.locked,
        ...(isVSImageShapeOrTextElementModel(layer) && {
          outOfCamera: layer.elementModel.outOfCamera
        })
      })
    );
  });
  return result;
}

export function getElementExitTimeForDefinedAnimationMs(
  element: VSImageShapeOrTextElementModel,
  scribe: ScribeModel
): number | undefined {
  const { scene } = getSceneFromElementID(element.id, scribe);
  const time =
    element[EXIT_TWEEN_DOCUMENT_KEY] || element[EXIT_ANIMATION_DOCUMENT_KEY]
      ? getTimingForElement('exitAnimationTime', element, scene, scribe) * 1000
      : 0;
  return time;
}

export function compareAudioClipArrays(arr1: Array<AudioClip>, arr2: Array<AudioClip>) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const propArray1 = arr1.map(item => item.assetId).sort();
  const propArray2 = arr2.map(item => item.assetId).sort();

  for (let i = 0; i < propArray1.length; i++) {
    if (propArray1[i] !== propArray2[i]) {
      return false;
    }
  }

  return true;
}
export const svgLookup: { [key: string]: PIXI.BaseTexture } = {};
export default class TimelineHelpers {
  public static importSVG(sourceBase64: string): PIXI.Texture {
    let baseTexture: PIXI.BaseTexture;
    if (!svgLookup[sourceBase64]) {
      const svgResource: PIXI.SVGResource = new PIXI.SVGResource(sourceBase64, { scale: 2 });
      baseTexture = new PIXI.BaseTexture(svgResource);
      svgLookup[sourceBase64] = baseTexture;
    } else {
      baseTexture = svgLookup[sourceBase64];
    }
    const pixiTexture: PIXI.Texture = new PIXI.Texture(baseTexture);
    return pixiTexture;
  }

  public static formatTime(time: number): string {
    const minutes = TimelineHelpers.roundNumber(Math.floor(time / 60));
    const seconds = TimelineHelpers.roundNumber(time - minutes * 60);
    const minuteString: string = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    const secondString: string = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
    return minuteString + ':' + secondString;
  }

  public static roundNumber(num?: number): number {
    if (num === undefined) return 0;
    return Math.round(num * 10) / 10;
  }
}
