import { CallEffect, TakeEffect, call, take } from 'redux-saga/effects';
import {
  DuplicateScribeSuccessAction,
  DuplicateScribeFailedAction,
  DUPLICATE_SCRIBE_FAILED,
  DUPLICATE_SCRIBE_SUCCESS
} from 'js/actionCreators/duplicateScribeActions';

import { incrementEventSuperProperty, incrementUserProperties, track } from '../mixpanelProvider';

export default function* trackDuplicateScribeSaga(): Generator<
  TakeEffect | CallEffect,
  void,
  DuplicateScribeSuccessAction | DuplicateScribeFailedAction
> {
  const successOrFailAction = yield take([DUPLICATE_SCRIBE_SUCCESS, DUPLICATE_SCRIBE_FAILED]);

  if (successOrFailAction.type === DUPLICATE_SCRIBE_SUCCESS) {
    const eventProperties = {
      'Scribe ID': successOrFailAction.originalProjectId
    };

    yield call(track, 'Duplicate Scribe', eventProperties);

    yield call(incrementUserProperties, {
      'Total Scribes Created': 1,
      'Duplicate Scribes Created': 1
    });

    yield call(incrementEventSuperProperty, 'Total Scribes Created');
    yield call(incrementEventSuperProperty, 'Duplicate Scribes Created');
  }
}
