import { ACCOUNT_TYPES } from 'js/config/consts';
import { getTimeRemaining } from 'js/shared/helpers/getTimeRemaining';
import { AuthState, CurrentUser } from 'js/types';
import { SET_RENDERER_USER, SetRendererUserAction } from 'js/actionCreators/rendererActions';

import {
  AUTH_SUCCESS,
  VALIDATE_TOKEN_REQUESTED,
  VALIDATE_TOKEN_FAILED,
  SET_ACCOUNT_MANAGEMENT_REDIRECT,
  SET_TRIAL_HAS_EXPIRED,
  SET_NO_ONLINE_STORAGE,
  AuthSuccessAction,
  ValidateTokenAction,
  SetTrialHasExpiredAction,
  SetNoOnlineStorageAction
} from '../actionCreators/authActions';
import { RESET_STATE } from '../actionCreators/generalActions';

const initialState: AuthState = {
  currentUser: {
    id: '',
    gracePeriodHasEnded: false,
    accountType: 'FREE'
  },
  isAuthenticating: true,
  accountManagementRedirect: false,
  noOnlineStorage: false
};

type AuthAction =
  | {
      type: typeof VALIDATE_TOKEN_FAILED | typeof SET_ACCOUNT_MANAGEMENT_REDIRECT | typeof RESET_STATE;
    }
  | AuthSuccessAction
  | ValidateTokenAction
  | SetTrialHasExpiredAction
  | SetNoOnlineStorageAction
  | SetRendererUserAction;

function authReducer(state = { ...initialState }, action: AuthAction): AuthState {
  switch (action.type) {
    case AUTH_SUCCESS: {
      const { email, id, firstname, accountType, term, lastname, signupDate, trialExpiryDate } = action.user;
      const { expired } = getTimeRemaining(trialExpiryDate);

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          id: id.toString(),
          authenticated: true,
          email,
          accountType,
          term,
          firstname,
          lastname,
          gracePeriodHasEnded: expired,
          signupDate,
          trialExpiryDate
        } as CurrentUser,
        isAuthenticating: false
      };
    }

    case SET_NO_ONLINE_STORAGE: {
      return {
        ...state,
        error: action.error.err,
        isAuthenticating: false,
        noOnlineStorage: true
      };
    }

    case VALIDATE_TOKEN_REQUESTED: {
      const { showSpinner } = action;

      return {
        ...state,
        isAuthenticating: showSpinner,
        currentUser: {
          ...state.currentUser
        }
      };
    }

    case VALIDATE_TOKEN_FAILED: {
      const isAuthenticating = false;
      return { ...state, isAuthenticating };
    }

    case RESET_STATE:
      return {
        ...initialState,
        currentUser: { ...initialState.currentUser },
        isAuthenticating: state.isAuthenticating
      };

    case SET_ACCOUNT_MANAGEMENT_REDIRECT: {
      return {
        ...state,
        accountManagementRedirect: true
      };
    }

    case SET_TRIAL_HAS_EXPIRED: {
      return {
        ...state,
        isAuthenticating: false,
        currentUser: {
          ...state.currentUser,
          ...action.user,
          accountType: ACCOUNT_TYPES.FREE,
          gracePeriodHasEnded: true
        } as CurrentUser
      };
    }

    case SET_RENDERER_USER: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.user,
          id: action.user.id.toString()
        }
      };
    }

    default:
      return state;
  }
}

export default authReducer;
