import React from 'react';
import PropTypes from 'prop-types';

const IconTrash = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.14286 19.7778C5.14286 21 6.17143 22 7.42857 22H16.5714C17.8286 22 18.8571 21 18.8571 19.7778V8.66667C18.8571 7.44444 17.8286 6.44444 16.5714 6.44444H7.42857C6.17143 6.44444 5.14286 7.44444 5.14286 8.66667V19.7778ZM18.8571 3.11111H16L15.1886 2.32222C14.9829 2.12222 14.6857 2 14.3886 2H9.61143C9.31429 2 9.01714 2.12222 8.81143 2.32222L8 3.11111H5.14286C4.51429 3.11111 4 3.61111 4 4.22222C4 4.83333 4.51429 5.33333 5.14286 5.33333H18.8571C19.4857 5.33333 20 4.83333 20 4.22222C20 3.61111 19.4857 3.11111 18.8571 3.11111Z"
      fill="currentcolor"
    />
  </svg>
);

IconTrash.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default IconTrash;
