import { RESET_STATE } from 'js/actionCreators/generalActions';
import {
  SET_USER_SETTING,
  LOAD_USER_SETTINGS_SUCCESS,
  LOAD_USER_SETTINGS_FAILED,
  LoadUserSettingsFailedAction,
  LoadUserSettingsSuccessAction,
  SetUserSettingAction
} from 'js/actionCreators/userSettingsActions';
import { UserSettingState } from 'js/types';

const initialState: UserSettingState = {
  loadingUserSettings: true,
  settings: { recordWithPlayback: true, hidePlaybackWarning: false, customColours: [] }
};

type UserSettingAction =
  | {
      type: typeof LOAD_USER_SETTINGS_FAILED | typeof RESET_STATE;
    }
  | LoadUserSettingsFailedAction
  | LoadUserSettingsSuccessAction
  | SetUserSettingAction;

export default function userSettingsReducer(state = initialState, action: UserSettingAction): UserSettingState {
  switch (action.type) {
    case LOAD_USER_SETTINGS_FAILED: {
      return {
        ...state,
        loadingUserSettings: false
      };
    }

    case LOAD_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loadingUserSettings: false,
        settings: {
          ...state.settings,
          ...action.settings
        }
      };
    }

    case SET_USER_SETTING: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.setting]: action.value
        }
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}
