import { ElementAnimationStageDurationKey, ScribeCameraElement, CameraAnimationStageDurationKey } from 'js/types';
import * as PIXI from 'pixi.js';
import clamp from 'lodash.clamp';
import {
  CAMERA_EASING_EASEOUT,
  CAMERA_EASING_NONE,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_TWEEN_DOCUMENT_KEY,
  MAX_ANIMATION_TIME_SECONDS
} from 'js/config/consts';
import { ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY } from 'js/config/animationOptions';
import getAnimationType from 'js/shared/helpers/getAnimationType';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { defaultCanvasColor } from 'js/config/defaults';
import IconCamera from 'imgs/pixi_icons/IconCamera.svg';

import TimelineElementGrabHandle from './TimelineElementGrabHandle';
import TimelineHelpers from './Utils/TimelineHelpers';
import timelineBus, {
  LAYER_CLICKED,
  PLAYHEAD_HIDE,
  PLAYHEAD_MOVE,
  PLAYHEAD_SHOW,
  TOOLTIP_HIDE,
  TOOLTIP_SHOW
} from './TimelineControlBus';
import { TimelineTimingsComparisonModel } from './TimelineTimingsComparisonModel';
import {
  ELEMENT_ANIMATION_TOOLTIPS,
  TOOLTIP_ALIGN_CENTRAL,
  TOOLTIP_ALIGN_LEFT,
  TOOLTIP_ICON_ALIGN_RIGHT
} from './TimelineLayerTooltip';
import TimelineWrapper, {
  TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
  TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR,
  TIMELINE_LAYER_BACKGROUND_COLOUR,
  TIMELINE_LAYER_FOREGROUND_COLOUR,
  TIMINGS_CHANGED
} from './TimelineWrapper';
import GetAnimationIcon, { isCameraIconTypeKey } from './Utils/GetAnimationIcon';
import { THUMBNAIL_WIDTH } from './consts';
import TimelineLayerCameraThumbnail from './TimelineLayerCameraThumbnail';
import LayerBase, { TimelineBaseLayerConfig } from './LayerBase';

const LAYER_GAP = 1;

const iconAnimationTimeName = 'iconAnimationTime';
const iconPauseTimeName = 'iconPauseTime';

export interface TimelineCameraLayerConfig extends TimelineBaseLayerConfig {
  cameraNumber: number;
}

export default class TimelineCameraLayer extends LayerBase {
  elementModel: ScribeCameraElement;
  public leftPosition: number;
  public rightPosition: number;
  timeTypes: Array<CameraAnimationStageDurationKey> = ['animationTime', 'pauseTime'];
  scribeLengthLimit = 0;

  iconCamEaseType?: PIXI.Sprite;
  iconEmphasisAnimationTime?: PIXI.Sprite;
  iconExitAnimationTime?: PIXI.Sprite;
  iconPauseTime?: PIXI.Sprite;

  exitAnimationTime: number | undefined;
  icons: PIXI.Container;
  tooltipIcon?: PIXI.Container;
  handles: Array<TimelineElementGrabHandle> = [];

  activeElementOutline: PIXI.Graphics;
  minimumIconWidth = 34;

  entranceType?: string;
  emphasisType?: string;
  exitType?: string;
  wrapperRef: TimelineWrapper;
  dragTimeoutId = 0;
  type = 'Camera' as const;
  cameraIcon: TimelineLayerCameraThumbnail;
  cameraNumber: number;

  constructor({
    elementModel,
    sceneID,
    scribe,
    sceneStartTime,

    currentSceneLength,
    isActive,

    wrapperRef,
    imageResources,

    cameraNumber
  }: TimelineCameraLayerConfig) {
    super({
      elementModel,
      sceneID,
      scribe,
      sceneStartTime,
      isActive,

      wrapperRef,
      imageResources,
      currentSceneLength
    });
    this.elementModel = elementModel as ScribeCameraElement;

    const texture = TimelineHelpers.importSVG(IconCamera);
    if (texture.valid) {
      this.tooltipIcon = new PIXI.Sprite(texture);
    } else {
      texture.on('update', () => {
        this.tooltipIcon = new PIXI.Sprite(texture);
      });
    }

    this.backgroundGraphics.interactive = true;
    this.backgroundGraphics.buttonMode = true;
    this.wrapperRef = wrapperRef;
    this.cameraNumber = cameraNumber;
    if (cameraNumber === 1) this.disableDrag = true;
    this.thumb = this.createThumbnail();
    this.backgroundGraphics.removeAllListeners();
    this.backgroundGraphics
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd)
      .on('click', this.handleClick, this);

    this.icons = new PIXI.Container();
    this.icons.interactive = true;
    this.icons.buttonMode = true;
    this.icons.removeAllListeners();
    this.icons
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd);

    this.activeElementOutline = new PIXI.Graphics();
    this.addChild(this.backgroundGraphics);
    this.addChild(this.continuationGraphics);
    this.addChild(this.foregroundGraphics);
    this.addChild(this.foregroundSprites);
    this.addChild(this.overlay);
    this.foregroundSprites.addChild(this.icons);
    this.foregroundSprites.addChild(this.activeElementOutline);

    this.setIcons();

    this.cameraIcon = this.createThumbnail();

    this.populate();
    this.updateZoom();
    this.updateIsActive(isActive);
    this.leftPosition = 0;
    this.rightPosition = 0;
  }
  createThumbnail() {
    return new TimelineLayerCameraThumbnail({
      element: this.elementModel as ScribeCameraElement,
      zoomValue: this.currentZoom,
      wrapperRef: this.wrapperRef,
      backgroundSettings: getActiveScene(this.scribe)?.settings ?? {
        backgroundColor: defaultCanvasColor
      },
      cameraNumber: this.cameraNumber
    });
  }
  updateProperties(changes: TimelineTimingsComparisonModel, newModel: ScribeCameraElement) {
    this.foregroundSprites.removeChildren();
    this.icons.removeChildren();
    this.foregroundSprites.addChild(this.icons);
    this.foregroundSprites.addChild(this.activeElementOutline);
    this.elementModel = newModel;
    this.startTime = this.endTime = changes.startTime / 1000;
    if (changes.animationTime !== undefined)
      this.elementModel.animationTime = this.animationTime = changes.animationTime / 1000;

    if (changes.pauseTime !== undefined) this.elementModel.pauseTime = this.pauseTime = changes.pauseTime / 1000;

    this.timeTypes = ['animationTime', 'pauseTime'];
    this.populate();
    this.setIcons();
    this.drawIcons();
    this.setHandleClamps();
    this.updateIsActive();
    (this.thumb as TimelineLayerCameraThumbnail).updateGraphic(newModel);
    this.updateZoom();
  }

  protected updateIsActive(isActive?: boolean) {
    this.activeElementOutline.clear();
    if (isActive !== undefined) {
      this.isActive = isActive;
    }
    if (this.isActive) {
      this.activeElementOutline.lineStyle({
        width: TIMELINE_LAYER_ACTIVE_LINE_WIDTH,
        color: 0xfe19f2,
        alignment: 0
      });
      this.activeElementOutline.drawRect(
        this.startTime * this.pixelsPerSecond,
        1,
        this.endTime !== 0 ? this.getEndX() - this.getStartX() : 1,
        this.layerHeight - LAYER_GAP
      );
      this.parent?.addChild(this);
    }
  }

  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    if (this.thumb) this.thumb.destroy();
    this.backgroundGraphics.destroy();
    this.foregroundGraphics.destroy();
    this.foregroundSprites.destroy();
    this.continuationGraphics.destroy();
    this.icons.destroy({ children: true, texture: false, baseTexture: false });

    super.destroy(options);
  }

  private populate(): void {
    for (let i = this.timeTypes.length - 1; i > -1; i--) {
      if (this[this.timeTypes[i]] === undefined || this[this.timeTypes[i]] === 0) {
        this.timeTypes.splice(i, 1);
      }
    }
    if (this.handles !== undefined && this.handles.length > 0) {
      this.handles.forEach(handle => {
        this.foregroundSprites.removeChild(handle);
        handle.destroy();
      });
    }
    this.drawElementTimeOnStage();
    let timeTypeIndex = 0;
    let handleX: number = this.startTime * this.pixelsPerSecond; //left handle
    let handle: TimelineElementGrabHandle;
    this.foregroundSprites.interactiveChildren = true;
    this.handles = [];
    for (timeTypeIndex; timeTypeIndex < this.timeTypes.length; timeTypeIndex++) {
      const timeProperty = this[this.timeTypes[timeTypeIndex]];
      if (timeProperty * this.pixelsPerSecond < 20) {
      }
      if (timeProperty !== undefined) {
        handle = new TimelineElementGrabHandle(
          timeTypeIndex,
          undefined,
          this.wrapperRef,
          this.handleRightClick,
          TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR
        );
        handle.x = handleX;
        handleX += timeProperty * this.pixelsPerSecond;
        this.foregroundSprites.addChild(handle);
        this.handles.push(handle);
        handle.on('onGrabHandleDragged', this.grabHandleAdjust);
        handle.on('onGrabHandleDragComplete', this.timingsChanged);
      }
    }
    handle = new TimelineElementGrabHandle(
      timeTypeIndex,
      undefined,
      this.wrapperRef,
      this.handleRightClick,
      TIMELINE_LAYER_ACTIVE_OUTLINE_COLOUR
    ); //right handle
    handle.x = handleX;
    this.endTime = handleX / this.pixelsPerSecond;
    this.foregroundSprites.addChild(handle);
    this.handles.push(handle);
    handle.on('onGrabHandleDragged', this.grabHandleAdjust);
    handle.on('onGrabHandleDragComplete', this.timingsChanged);

    this.setHandleClamps();
    this.foregroundSprites.addChild(this.activeElementOutline);
  }

  timingsChanged = (handle: TimelineElementGrabHandle, event: PIXI.InteractionEvent) => {
    timelineBus.emit(TOOLTIP_HIDE);
    timelineBus.emit(PLAYHEAD_HIDE);
    const allTimings: { [k in ElementAnimationStageDurationKey]?: number } = {};
    if (event.data.originalEvent.shiftKey && !handle.lastHandle) {
      allTimings[this.timeTypes[handle.index]] = clamp(
        TimelineHelpers.roundNumber(this[this.timeTypes[handle.index]]),
        0,
        this.sceneStartTime + MAX_ANIMATION_TIME_SECONDS
      );
    }
    allTimings[this.timeTypes[handle.index - 1]] = clamp(
      TimelineHelpers.roundNumber(this[this.timeTypes[handle.index - 1]]),
      0,
      this.sceneStartTime + MAX_ANIMATION_TIME_SECONDS
    );
    this.emit(TIMINGS_CHANGED, this.elementModel.id, allTimings);
    if (handle.lastHandle) this.scribeLengthLimit = 0;
  };

  private setHandleClamps(): void {
    this.handles.forEach((handle, handleIndex, handles) => {
      const isFirstHandle = handleIndex === 0;
      const isLastHandle = handleIndex === handles.length - 1;
      const nextHandle = handles[handleIndex + 1];
      const previousHandle = handles[handleIndex - 1];

      let lower = 0;
      let upper = Infinity;

      if (isFirstHandle && nextHandle) {
        lower = this.startTime * this.pixelsPerSecond;
        upper = nextHandle.x;
      } else if (isLastHandle && previousHandle) {
        const maxX = Math.floor(previousHandle.x + MAX_ANIMATION_TIME_SECONDS * this.pixelsPerSecond);

        lower = previousHandle.x;
        upper = maxX;
      } else if (previousHandle) {
        const maxX = Math.floor(previousHandle.x + MAX_ANIMATION_TIME_SECONDS * this.pixelsPerSecond);

        lower = previousHandle.x;
        upper = Math.min(nextHandle.x, maxX);
      }
      handle.setClamps(lower, upper);
    });
  }

  protected drawBackgroundGraphics() {
    if (this.wrapperRef.scrollBox) {
      this.backgroundGraphics.clear();
      this.backgroundGraphics.beginFill(TIMELINE_LAYER_BACKGROUND_COLOUR, 1);
      this.backgroundGraphics.drawRect(
        this.sceneStartTime * this.pixelsPerSecond,
        1,
        this.currentSceneLength * this.pixelsPerSecond,
        this.layerHeight - 1
      );
      this.backgroundGraphics.endFill();
    }
  }

  public getGlobalStartPosition() {
    const global = this.foregroundGraphics.toGlobal(this.wrapperRef.app.stage);
    return new PIXI.Point(global.x + this.getStartX(), global.y);
  }

  protected drawForegroundGraphics() {
    this.foregroundGraphics.clear();
    this.continuationGraphics.clear();
    this.foregroundGraphics.beginFill(TIMELINE_LAYER_FOREGROUND_COLOUR, 1);

    const remainsOnStage = false;

    const endpointLength = this.animationTime * this.pixelsPerSecond;
    this.foregroundGraphics.drawRect(this.startTime * this.pixelsPerSecond, 1, endpointLength, this.layerHeight - 1);

    const elementExits = this.pauseTime > 0;

    const pauseTimeSectionX = (this.startTime + this.animationTime) * this.pixelsPerSecond;
    const pauseTimeSectionWidth = this.pauseTime * this.pixelsPerSecond - 1;

    // pause time section
    if (elementExits) {
      this.foregroundGraphics.lineStyle(1, TIMELINE_LAYER_FOREGROUND_COLOUR);
    }
    this.foregroundGraphics.beginFill(TIMELINE_LAYER_FOREGROUND_COLOUR, elementExits ? 0 : 1);
    this.foregroundGraphics.drawRect(pauseTimeSectionX, 1, pauseTimeSectionWidth, this.layerHeight - 1);
    this.foregroundGraphics.endFill();

    if (remainsOnStage && !this.wrapperRef.dragging) {
      const timeLeftInScene = this.sceneStartTime + this.currentSceneLength - this.endTime;
      this.continuationGraphics.beginFill(TIMELINE_LAYER_FOREGROUND_COLOUR);
      this.continuationGraphics.drawRect(
        this.endTime * this.pixelsPerSecond,
        1,
        timeLeftInScene * this.pixelsPerSecond,
        this.layerHeight - 1
      );
      this.continuationGraphics.endFill();
    }
  }

  drawElementTimeOnStage(): void {
    this.drawBackgroundGraphics();
    this.drawForegroundGraphics();
    this.overlay.hitArea = new PIXI.Rectangle(0, 1, this.currentSceneLength * this.pixelsPerSecond, this.layerHeight);
  }

  public recalculateEndTime(): void {
    this.endTime = this.startTime;
    for (let i = 0; i < this.timeTypes.length; i++) {
      const phaseTime = this[this.timeTypes[i]];
      if (phaseTime !== undefined && !isNaN(phaseTime)) this.endTime += phaseTime;
    }
  }

  private grabHandleAdjust = (handle: TimelineElementGrabHandle, event: PIXI.InteractionEvent): void => {
    event.stopPropagation();
    handle.x = Math.ceil(handle.x / this.pixelsPerSecond / 0.1) * 0.1 * this.pixelsPerSecond;
    let delta: number;

    if (handle.index === 0) {
      return;
    } else if (handle.lastHandle) {
      delta = (this.endTime * this.pixelsPerSecond - handle.x) / this.pixelsPerSecond;
      if (
        TimelineHelpers.roundNumber(handle.x) >= TimelineHelpers.roundNumber(this.handles[this.handles.length - 2].x)
      ) {
        this[this.timeTypes[handle.index - 1]] -= delta;
        this.endTime -= delta;
        timelineBus.emit(
          PLAYHEAD_MOVE,
          handle.getGlobalPosition().x,
          TimelineHelpers.formatTime(handle.x / this.pixelsPerSecond + this.sceneStartTime)
        );
      }
    } else {
      let prevTime = this[this.timeTypes[handle.index - 1]];
      if (prevTime === undefined || isNaN(prevTime)) prevTime = 0;
      delta = prevTime - (this.handles[handle.index].x - this.handles[handle.index - 1].x) / this.pixelsPerSecond;
      this[this.timeTypes[handle.index - 1]] -= delta;
      if (event.data.originalEvent.shiftKey) {
        this[this.timeTypes[handle.index]] += delta;
      } else {
        this.endTime -= delta;
      }
      timelineBus.emit(
        PLAYHEAD_MOVE,
        handle.getGlobalPosition().x,
        TimelineHelpers.formatTime(handle.x / this.pixelsPerSecond + this.sceneStartTime)
      );
    }

    const prevSectionTime = TimelineHelpers.roundNumber(this[this.timeTypes[handle.index - 1]]);
    const nextSectionTime = !handle.lastHandle ? TimelineHelpers.roundNumber(this[this.timeTypes[handle.index]]) : null;
    const prevSectionTooltipText = `${prevSectionTime}s < `;
    const nextSectionTooltipText = nextSectionTime ? `> ${nextSectionTime}s` : '';
    const tipText = `${prevSectionTooltipText}${nextSectionTooltipText}`;

    timelineBus.emit(
      TOOLTIP_SHOW,
      event,
      [tipText],
      handle.lastHandle ? TOOLTIP_ALIGN_LEFT : TOOLTIP_ALIGN_CENTRAL,
      this.layerHeight,
      handle
    );
    timelineBus.emit(PLAYHEAD_SHOW, this);
    if (handle.lastHandle && this.scribeLengthLimit === 0) this.scribeLengthLimit = this.currentSceneLength;
    if (handle.lowerClamp && handle.lastHandle && this.scribeLengthLimit !== 0 && handle.x > handle.lowerClamp) {
      this.currentSceneLength -= delta;
      this.drawForegroundGraphics();
    }
    this.redraw();
  };

  public drawIcons() {
    this.icons.removeChildren();
    if (this.handles.length > 1 && this.thumb) {
      const firstSectionWidth = this.handles[1].x - this.handles[0].x;
      if (firstSectionWidth > this.minimumIconWidth) {
        const iconPadding = 2;
        this.thumb.x = this.handles[0].x + iconPadding;
        this.thumb.y = iconPadding;
        this.thumb.visible = true;
        this.thumb.removeAllListeners();
        this.thumb.on('pointerover', this.rolloverIcon).on('pointerout', this.rolloffIcon);
        this.thumb.on('pointerdown', this.onDragStart);
        this.thumb.on('pointerup', this.onDragEnd);
        this.thumb.on('click', this.handleClick, this);
        this.thumb.interactive = true;
        this.addChild(this.thumb);
      } else {
        this.removeChild(this.thumb);
      }
    }

    if (this.handles.length > 1) {
      for (let i = 0; i < this.handles.length - 1; i++) {
        const handle: TimelineElementGrabHandle = this.handles[i];
        const availableSpace =
          i === 0 ? this.handles[i + 1].x - handle.x + THUMBNAIL_WIDTH : this.handles[i + 1].x - handle.x;
        switch (this.timeTypes[handle.index]) {
          case 'animationTime':
            if (this.iconCamEaseType) this.addIcon(this.iconCamEaseType, handle, availableSpace, i);
            break;
          default:
            if (this.iconPauseTime) this.addIcon(this.iconPauseTime, handle, availableSpace, i);
            break;
        }
      }
    } else {
      //only add if in the current scene
      if (this.thumb) this.removeChild(this.thumb);
      this.addCamIcon(this.cameraIcon, this.handles[0]);
      this.cameraIcon.removeAllListeners();
      this.cameraIcon.on('pointerover', this.rolloverIcon);
      this.cameraIcon.on('pointerout', this.rolloffIcon);
      this.cameraIcon.on('click', this.handleClick, this);
      this.cameraIcon.interactive = true;
    }
  }

  private addCamIcon(icon: TimelineLayerCameraThumbnail, handle: TimelineElementGrabHandle) {
    const iconPadding = 2;
    icon.height = this.layerHeight - iconPadding * 3;
    icon.scale.x = icon.scale.y;
    icon.x = handle.x + iconPadding;
    icon.y = iconPadding;
    this.icons.addChild(icon);
  }

  private addIconByTimeType(icon: PIXI.Sprite, timeType: 'animationTime' | 'pauseTime') {
    const index = this.timeTypes.indexOf(timeType);
    const handle = this.handles[index];
    const diff = this.handles[index + 1].x - handle.x;
    this.addIcon(icon, handle, diff, index);
  }

  private addIcon(icon: PIXI.Sprite, handle: TimelineElementGrabHandle, availableSpace: number, handleIndex: number) {
    const iconPadding = 2;
    if (
      availableSpace < this.minimumIconWidth ||
      (handleIndex === 0 && availableSpace - THUMBNAIL_WIDTH - this.minimumIconWidth < this.minimumIconWidth)
    ) {
      icon.x = handle.x;
      icon.width = availableSpace + THUMBNAIL_WIDTH;
      icon.height = this.layerHeight;
      icon.alpha = 0;
    } else {
      icon.x = handle.x + (availableSpace - icon.width) / 2;
      icon.height = this.layerHeight - iconPadding * 2;
      icon.scale.x = icon.scale.y;
      icon.y = iconPadding;
    }
    this.icons.addChild(icon);
  }

  public updateZoom() {
    this.handles?.forEach(handle => {
      handle.updateZoom();
    });
    if (this.thumb) this.thumb.updateZoom();
    this.redraw();
  }

  private handleClick(event: PIXI.InteractionEvent) {
    event.stopPropagation();
    this.emit(LAYER_CLICKED, this.id, event);
  }

  public handleRightClick = (event: PIXI.InteractionEvent) => {
    event.stopPropagation();
    event.data.originalEvent.preventDefault();
    event.data.originalEvent.stopPropagation();
    window.setTimeout(() => this.wrapperRef.onContextClick(context), 1);
    if (event.data.originalEvent.ctrlKey && !this.wrapperRef.isMac) {
      return;
    }
    this.emit(LAYER_CLICKED, this.id, event);

    const x = (event.data.originalEvent as MouseEvent).clientX;
    const y = (event.data.originalEvent as MouseEvent).clientY;

    const context = {
      type: 'element' as const,
      scribeId: this.wrapperRef.props.activeScribe.id,
      mouseX: x,
      mouseY: y,
      elementId: this.elementModel.id
    };
  };
  updateScribeLength(sceneLength: number) {
    this.currentSceneLength = sceneLength;
    this.drawElementTimeOnStage();
  }

  private configureIcon(
    icon: PIXI.Texture,
    name: string,
    timeTypeId: CameraAnimationStageDurationKey = 'animationTime'
  ): PIXI.Sprite {
    const sprite = new PIXI.Sprite(icon);
    sprite.interactive = true;
    sprite.name = name;
    sprite.removeAllListeners();
    sprite
      .on('pointerover', this.rolloverIcon)
      .on('pointerout', this.rolloffIcon)
      .on('click', this.handleClick, this);
    sprite.texture.once('update', () => {
      if (sprite) {
        this.addIconByTimeType(sprite, timeTypeId);
      }
    });
    return sprite;
  }

  private setIcons() {
    const entranceType = getAnimationType({
      element: this.elementModel,
      elements: [],
      scribe: this.scribe,
      duration: this.elementModel.animationTime !== undefined ? this.elementModel.animationTime : 0,
      tweenProperty: ENTRANCE_TWEEN_DOCUMENT_KEY,
      cursors: [],
      durationKey: ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY,
      animationProperty: ENTRANCE_ANIMATION_DOCUMENT_KEY
    });
    if (entranceType !== CAMERA_EASING_NONE) {
      this.entranceType = entranceType;
    }
    if (this.entranceType && isCameraIconTypeKey(this.entranceType)) {
      this.iconCamEaseType = this.configureIcon(
        GetAnimationIcon.getCameraIcon(this.entranceType),
        iconAnimationTimeName,
        'animationTime'
      );
    }
    this.iconPauseTime = this.configureIcon(GetAnimationIcon.getIcon('pause'), iconPauseTimeName);
  }

  private rolloverIcon = (event: PIXI.InteractionEvent) => {
    if (this.wrapperRef.dragging) return;
    const elementModel = this.elementModel as ScribeCameraElement;
    if (this.cameraNumber === 1 && event.target.name !== iconPauseTimeName) {
      if (!this.wrapperRef.dragging)
        timelineBus.emit(
          TOOLTIP_SHOW,
          event,
          [this.tooltipIcon, 'Starting camera cannot be rearranged'],
          TOOLTIP_ICON_ALIGN_RIGHT,
          this.layerHeight
        );
      return;
    }
    if (this.wrapperRef.dragging || !this.tooltipIcon) return;
    let rolloverText: (string | PIXI.Container)[] = [''];
    const iconName = elementModel.easingType === CAMERA_EASING_EASEOUT ? 'ease out' : 'linear';
    switch (event.target.name) {
      case iconAnimationTimeName:
        rolloverText = [
          this.tooltipIcon,
          'C' + this.cameraNumber,
          'Camera movement ',
          iconName.charAt(0).toUpperCase() + iconName.slice(1),
          TimelineHelpers.roundNumber(this.animationTime) + 's'
        ];
        break;

      case iconPauseTimeName:
        rolloverText = [
          this.tooltipIcon,
          'C' + this.cameraNumber,
          'Pause',
          TimelineHelpers.roundNumber(this.pauseTime) + 's'
        ];
        break;

      default:
        rolloverText = [this.tooltipIcon, 'C' + this.cameraNumber];
        break;
    }
    if (!this.wrapperRef.dragging)
      timelineBus.emit(TOOLTIP_SHOW, event, rolloverText, ELEMENT_ANIMATION_TOOLTIPS, this.layerHeight);
  };

  private rolloffIcon = () => {
    if (this.wrapperRef.dragging) return;
    timelineBus.emit(TOOLTIP_HIDE);
  };
  public redraw(): void {
    this.drawElementTimeOnStage();
    let handleX = (this.handles[0].x = this.startTime * this.pixelsPerSecond);
    for (let i = 0; i < this.timeTypes.length; i++) {
      const timeProperty = this[this.timeTypes[i]];
      if (timeProperty !== undefined) {
        const handle = this.handles[i];
        handle.x = handleX;
        handleX += timeProperty * this.pixelsPerSecond;
      }
    }
    this.handles[this.handles.length - 1].x = this.endTime * this.pixelsPerSecond;
    this.leftPosition = this.handles[0].x;
    this.rightPosition = this.handles[this.handles.length - 1].x;

    this.drawIcons();
    this.setHandleClamps();
    this.updateIsActive();
  }
}
