import { useAudioAssetInProject, getUserAudio } from 'js/actionCreators/audioActions';
import {
  CreateProjectAssetFromLibraryAction,
  AssetLibrarySource,
  createProjectAssetFromLibrarySuccess,
  createProjectAssetFromLibraryFailed,
  CREATE_PROJECT_ASSET_FROM_LIBRARY
} from 'js/actionCreators/audioLibraryActions';
import { showError } from 'js/actionCreators/uiActions';
import { VSCAssetAudioData } from 'js/types';
import { CallEffect, PutEffect, call, put, takeEvery } from 'redux-saga/effects';

import { getLibraryItemAssetFromUserAudioList, createAudioAssetByLibraryItemId } from './audioLibrarySagas';

export function* createProjectAssetFromLibrary({
  library,
  libraryItemId,
  projectId,
  target
}: CreateProjectAssetFromLibraryAction<AssetLibrarySource>): Generator<
  CallEffect | PutEffect,
  void,
  VSCAssetAudioData | undefined
> {
  try {
    if (library === 'audio') {
      let assetToBeAdded: VSCAssetAudioData;
      const existingAsset = yield call(getLibraryItemAssetFromUserAudioList, libraryItemId);
      const hasExistingAsset = existingAsset !== undefined;

      if (hasExistingAsset) {
        assetToBeAdded = existingAsset as VSCAssetAudioData;
      } else {
        const createdAsset = yield call(createAudioAssetByLibraryItemId, library, libraryItemId);
        assetToBeAdded = createdAsset as VSCAssetAudioData;
      }
      yield put(createProjectAssetFromLibrarySuccess());
      yield put(useAudioAssetInProject(projectId, assetToBeAdded, target ?? 'project', 'Audio Library'));
      yield put(getUserAudio());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(showError({ message: 'Failed to add audio library item to your project', description: error.message }));
      yield put(createProjectAssetFromLibraryFailed(error));
    }
  }
}

export default function* createProjectAssetFromLibrarySaga() {
  yield takeEvery(CREATE_PROJECT_ASSET_FROM_LIBRARY, createProjectAssetFromLibrary);
}
