import { put, select, take } from 'redux-saga/effects';

import { editorClosed } from '../actionCreators/editorActions';

function* detectEditorClosed() {
  const isEditorPath = path => /^\/editor/i.test(path);
  while (true) {
    const oldPathname = yield select(state => state.router?.location?.pathname || '');
    yield take('@@router/LOCATION_CHANGE');
    const newPathname = yield select(state => state.router?.location?.pathname || '');
    if (isEditorPath(oldPathname) && !isEditorPath(newPathname)) {
      yield put(editorClosed());
    }
  }
}

function* routerSagas() {
  yield detectEditorClosed();
}

export default routerSagas;
