import React from 'react';

export default function IconAnimationShake() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      enableBackground="new 0 0 60 60"
    >
      <path d="M8.5,23.4l-3-0.5c0.9-5.1,3.5-9.1,7.8-12l1.7,2.5C11.4,15.8,9.3,19.1,8.5,23.4z" />
      <path d="M9.2,30.1C8.9,21.7,13.1,15.3,21.8,11l1.3,2.7c-7.7,3.8-11.2,9.1-10.9,16.3L9.2,30.1z" />
      <path d="M51.5,23.5c-0.8-4.3-3-7.6-6.7-10l1.6-2.5c4.3,2.8,7,6.8,8,11.9L51.5,23.5z" />
      <path d="M50.5,30.1l-3-0.1c0.3-7.2-3.2-12.5-10.9-16.3l1.3-2.7C46.6,15.3,50.8,21.7,50.5,30.1z" />
      <path
        d="M29.8,45C21.6,45,15,38.3,15,30.1s6.7-14.8,14.8-14.8S44.7,22,44.7,30.1S38,45,29.8,45z M29.8,18.3
	c-6.5,0-11.8,5.3-11.8,11.8S23.3,42,29.8,42s11.8-5.3,11.8-11.8S36.3,18.3,29.8,18.3z"
      />
    </svg>
  );
}
