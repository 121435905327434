import { USER_ACCEPTS_MARKETING } from 'js/actionCreators/acceptedMarketingActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { call, takeEvery } from 'redux-saga/effects';

function* userAcceptsMarketing() {
  try {
    yield call(appServices.sendAcceptedMarketing);
  } catch (error) {
    console.error('There was an issue sending the accepted marketing', error);
  }
}

export default function* acceptedMarketingSaga() {
  yield takeEvery(USER_ACCEPTS_MARKETING, userAcceptsMarketing);
}
