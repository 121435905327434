import cloneDeep from 'lodash.clonedeep';
import canDeleteScenes from 'js/shared/helpers/canDeleteScenes';
import { ExistingScribeModel } from 'js/types';

function deleteScenesElementsAndAudioFromScribe(scribe: ExistingScribeModel, sceneIdsToDelete: string[]) {
  const updatedScribe = cloneDeep(scribe);
  const deletedScene = updatedScribe.scenes.find(scene => sceneIdsToDelete.includes(scene.id));
  const deletedSceneAudioClipIds = deletedScene?.audioLayerIds?.flatMap(
    audioLayerId => updatedScribe.audioLayers.find(audioLayer => audioLayer.id === audioLayerId)?.audioClipIds
  );
  updatedScribe.scenes = updatedScribe.scenes.filter(scene => !sceneIdsToDelete.includes(scene.id));

  const remainingElementIds = updatedScribe.scenes.flatMap(scene => scene.elementIds);
  updatedScribe.elements = updatedScribe.elements.filter(element => remainingElementIds.includes(element.id));

  updatedScribe.audioLayers = updatedScribe.audioLayers.filter(
    audioLayer => audioLayer.id && !deletedScene?.audioLayerIds?.includes(audioLayer.id)
  );

  updatedScribe.audioClips = updatedScribe.audioClips?.filter(
    audioClip => audioClip.id && !deletedSceneAudioClipIds?.includes(audioClip.id)
  );

  return updatedScribe;
}

export default function deleteScenesFromScribe(scribe: ExistingScribeModel, sceneIdsToDelete: string[]) {
  if (!scribe || !sceneIdsToDelete || !sceneIdsToDelete.length) return scribe;

  const scribeHasScenesToBeDeleted = sceneIdsToDelete.some(
    sceneId => !!scribe.scenes.find(scene => scene.id === sceneId)
  );
  if (!scribeHasScenesToBeDeleted) return scribe;

  if (!canDeleteScenes(scribe, sceneIdsToDelete)) return scribe;

  const deletedScenesContainsActiveScene = sceneIdsToDelete.includes(
    scribe.scenes.find(scene => scene.active)?.id ?? ''
  );
  if (!deletedScenesContainsActiveScene) {
    return deleteScenesElementsAndAudioFromScribe(scribe, sceneIdsToDelete);
  }

  const activeSceneIsFirstScene = scribe.scenes.findIndex(scene => scene.active) === 0;
  if (activeSceneIsFirstScene) {
    const updatedScribe = deleteScenesElementsAndAudioFromScribe(scribe, sceneIdsToDelete);
    updatedScribe.scenes[0].active = true;
    return updatedScribe;
  } else {
    let loopbackIndex = scribe.scenes.findIndex(scene => scene.active);
    let sceneIdToActivate: string | undefined;
    while (loopbackIndex > 0) {
      loopbackIndex -= 1;

      if (!sceneIdsToDelete.includes(scribe.scenes[loopbackIndex].id)) {
        sceneIdToActivate = scribe.scenes[loopbackIndex]?.id;
        break;
      }
    }

    const updatedScribe = deleteScenesElementsAndAudioFromScribe(scribe, sceneIdsToDelete);
    if (!sceneIdToActivate) {
      updatedScribe.scenes[0].active = true;
    } else {
      const sceneToActivate = updatedScribe.scenes.find(scene => scene.id === sceneIdToActivate);
      if (sceneToActivate) {
        sceneToActivate.active = true;
      }
    }

    return updatedScribe;
  }
}
