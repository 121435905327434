import { AUTH_SUCCESS, LOGOUT } from 'js/actionCreators/authActions';
import { reset, ResetStateAction } from 'js/actionCreators/generalActions';
import { eventChannel } from 'redux-saga';
import {
  CallEffect,
  ChannelTakeEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import createBroadcastChannel from './sagaHelpers/createBroadcastChannel';

const LOGOUT_ALL_ACTION = 'LOGOUT_ALL_ACTION';

function createBroadcastSagaChannel(broadcastChannel: BroadcastChannel) {
  return eventChannel(emit => {
    broadcastChannel.onmessage = event => {
      emit(event.data);
    };
    return () => {
      broadcastChannel.close();
    };
  });
}

export function* broadcastChannelSaga(): Generator<
  ForkEffect | ChannelTakeEffect<null | object> | PutEffect<ResetStateAction> | CallEffect,
  void,
  string
> {
  const broadcastChannel = createBroadcastChannel();
  const broadcastSagaChannel = createBroadcastSagaChannel(broadcastChannel);

  yield takeEvery(LOGOUT, () => {
    broadcastChannel.postMessage(LOGOUT_ALL_ACTION);
  });

  while (true) {
    try {
      const message = yield take(broadcastSagaChannel);
      if (message === LOGOUT_ALL_ACTION) {
        yield delay(500);
        yield put(reset());
        window.location.reload();
      }
    } catch (error) {
      console.error('Error in broadcast channel saga', error);
    }
  }
}

export default function* saga() {
  yield takeLatest(AUTH_SUCCESS, broadcastChannelSaga);
}
