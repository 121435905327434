import {
  AUDIO_FILE_SIZE_LIMIT_REACHED,
  AUDIO_TRACK_LIMIT_REACHED,
  AudioFileSizeLimitReachedAction,
  AudioTrackLimitReachedAction
} from 'js/actionCreators/projectLimitsActions';
import { showAudioLimitsModal } from 'js/actionCreators/uiActions';
import { put, takeEvery } from 'redux-saga/effects';

const hasWarnedAboutFileSize = new Set();
function* audioFileSizeLimitReached({ projectId }: AudioFileSizeLimitReachedAction) {
  if (hasWarnedAboutFileSize.has(projectId)) {
    return;
  }

  hasWarnedAboutFileSize.add(projectId);
  yield put(showAudioLimitsModal(projectId, 'size'));
}

const hasWarnedAboutTrackLimits = new Set();
function* audioTrackLimitReached({ projectId }: AudioTrackLimitReachedAction) {
  if (hasWarnedAboutTrackLimits.has(projectId)) {
    return;
  }

  hasWarnedAboutTrackLimits.add(projectId);
  yield put(showAudioLimitsModal(projectId, 'tracks'));
}

export default function* audioLimitModalSagas() {
  yield takeEvery(AUDIO_FILE_SIZE_LIMIT_REACHED, audioFileSizeLimitReached);
  yield takeEvery(AUDIO_TRACK_LIMIT_REACHED, audioTrackLimitReached);
}
