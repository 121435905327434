import React from 'react';

const IconDraw = () => (
  <svg
    version="1.1"
    id="Layer_1"
    width="59.4px"
    height="60px"
    viewBox="0 0 59.4 60"
    enableBackground="new 0 0 59.4 60"
    xmlSpace="preserve"
  >
    <path
      d="M36.7,48.5c-2-1.5-3-3.1-3.1-4.8c0-3.1,3-5.4,5.9-7.7c2-1.5,4.4-3.5,4.5-4.8c0-0.5-0.4-1-1.1-1.6c-0.8-0.7-1.4-0.7-1.7-0.6
	c-1.9,0.3-4.2,3.9-6.2,7.1c-2.7,4.3-5.6,8.7-9.4,9.2c-1.8,0.3-3.5-0.3-5.2-1.8c-1.4-1.2-2-2.5-1.9-3.9c0.2-3.6,5-6.9,10.1-10.3
	c4.1-2.7,9.1-6.1,9.3-8.6c0-0.4,0-1-1-1.9c-0.9-0.8-1.8-1.2-2.7-1.1c-2.6,0.4-5.5,4.9-8,8.9c-2.9,4.6-5.4,8.5-8.5,8.8
	c-1.4,0.1-2.7-0.5-3.9-1.9c-1.1-1.3-1.6-2.6-1.5-4c0.2-3.1,3.3-5.6,6.3-8.1c2.4-2,5.2-4.3,5.1-6.1c0-0.9-0.8-1.8-2.3-2.8l1.7-2.5
	c2.3,1.6,3.5,3.3,3.6,5.2c0.2,3.3-3.1,6-6.2,8.6c-2.4,2-5,4.2-5.2,6c0,0.4,0,1,0.7,1.8c0.8,0.9,1.2,0.9,1.4,0.8
	c1.7-0.1,4.2-4.2,6.2-7.4c3-4.8,6.1-9.7,10.1-10.3c1.8-0.2,3.5,0.4,5.1,1.8c1.4,1.3,2.1,2.7,2,4.3c-0.3,3.9-5.3,7.3-10.6,10.8
	c-3.9,2.6-8.7,5.8-8.8,8c0,0.3,0.1,0.8,0.9,1.5c1,0.9,1.9,1.2,2.8,1.1c2.5-0.4,5-4.4,7.3-7.9c2.6-4.1,5.1-7.9,8.3-8.4
	c1.4-0.2,2.8,0.2,4.1,1.3c1.5,1.2,2.2,2.6,2.2,4c0,2.8-2.7,4.9-5.6,7.1c-2.2,1.7-4.7,3.7-4.7,5.3c0,0.7,0.6,1.6,1.9,2.5L36.7,48.5z"
    />
  </svg>
);
export default IconDraw;
