export const manifest = {
  fonts: [
    {
      fontFamily: 'Abel',
      Regular: {
        name: 'Abel-Regular',
        filename: 'Abel-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'AbhayaLibre',
      Bold: {
        name: 'AbhayaLibre-Bold',
        filename: 'AbhayaLibre-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'AbhayaLibre-Regular',
        filename: 'AbhayaLibre-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'AbrilFatface',
      Regular: {
        name: 'AbrilFatface-Regular',
        filename: 'AbrilFatface-Regular.ttf',
        variable: false,
        pixelRange: 18
      }
    },
    {
      fontFamily: 'Acme',
      Regular: {
        name: 'Acme-Regular',
        filename: 'Acme-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Alef',
      Bold: {
        name: 'Alef-Bold',
        filename: 'Alef-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Alef-Regular',
        filename: 'Alef-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Alegreya',
      Italic: {
        name: 'Alegreya-Italic',
        filename: 'Alegreya-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Alegreya-BoldItalic',
        filename: 'Alegreya-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Alegreya-Regular',
        filename: 'Alegreya[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Alegreya-Bold',
        filename: 'Alegreya[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'AlegreyaSans',
      Bold: {
        name: 'AlegreyaSans-Bold',
        filename: 'AlegreyaSans-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'AlegreyaSans-BoldItalic',
        filename: 'AlegreyaSans-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'AlegreyaSans-Italic',
        filename: 'AlegreyaSans-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'AlegreyaSans-Regular',
        filename: 'AlegreyaSans-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'AmaticSC',
      Bold: {
        name: 'AmaticSC-Bold',
        filename: 'AmaticSC-Bold.ttf',
        variable: false,
        pixelRange: 15
      },
      Regular: {
        name: 'AmaticSC-Regular',
        filename: 'AmaticSC-Regular.ttf',
        variable: false,
        pixelRange: 20
      }
    },
    {
      fontFamily: 'Amiri',
      Bold: {
        name: 'Amiri-Bold',
        filename: 'Amiri-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Amiri-BoldItalic',
        filename: 'Amiri-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Amiri-Italic',
        filename: 'Amiri-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Amiri-Regular',
        filename: 'Amiri-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Angkor',
      Regular: {
        name: 'Angkor-Regular',
        filename: 'Angkor-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Anton',
      Regular: {
        name: 'Anton-Regular',
        filename: 'Anton-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'ArchivoBlack',
      Regular: {
        name: 'ArchivoBlack-Regular',
        filename: 'ArchivoBlack-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Arimo',
      Italic: {
        name: 'Arimo-Italic',
        filename: 'Arimo-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Arimo-BoldItalic',
        filename: 'Arimo-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Arimo-Regular',
        filename: 'Arimo[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Arimo-Bold',
        filename: 'Arimo[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Arvo',
      Bold: {
        name: 'Arvo-Bold',
        filename: 'Arvo-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Arvo-BoldItalic',
        filename: 'Arvo-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Arvo-Italic',
        filename: 'Arvo-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Arvo-Regular',
        filename: 'Arvo-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Atma',
      Bold: {
        name: 'Atma-Bold',
        filename: 'Atma-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Atma-Regular',
        filename: 'Atma-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Baloo',
      Regular: {
        name: 'Baloo-Regular',
        filename: 'Baloo-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Baloo2',
      Regular: {
        name: 'Baloo2-Regular',
        filename: 'Baloo2[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Baloo2-Bold',
        filename: 'Baloo2[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'BalooPaaji2',
      Regular: {
        name: 'BalooPaaji2-Regular',
        filename: 'BalooPaaji2[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'BalooPaaji2-Bold',
        filename: 'BalooPaaji2[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Bangers',
      Regular: {
        name: 'Bangers-Regular',
        filename: 'Bangers-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Barlow',
      Bold: {
        name: 'Barlow-Bold',
        filename: 'Barlow-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Barlow-BoldItalic',
        filename: 'Barlow-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Barlow-Italic',
        filename: 'Barlow-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Barlow-Regular',
        filename: 'Barlow-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'BarlowCondensed',
      Bold: {
        name: 'BarlowCondensed-Bold',
        filename: 'BarlowCondensed-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'BarlowCondensed-BoldItalic',
        filename: 'BarlowCondensed-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'BarlowCondensed-Italic',
        filename: 'BarlowCondensed-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'BarlowCondensed-Regular',
        filename: 'BarlowCondensed-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Battambang',
      Bold: {
        name: 'Battambang-Bold',
        filename: 'Battambang-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Battambang-Regular',
        filename: 'Battambang-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Bitter',
      Italic: {
        name: 'Bitter-Italic',
        filename: 'Bitter-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Bitter-BoldItalic',
        filename: 'Bitter-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Bitter-Regular',
        filename: 'Bitter[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Bitter-Bold',
        filename: 'Bitter[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'BlackHanSans',
      Regular: {
        name: 'BlackHanSans-Regular',
        filename: 'BlackHanSans-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'BreeSerif',
      Regular: {
        name: 'BreeSerif-Regular',
        filename: 'BreeSerif-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Cairo',
      Regular: {
        name: 'Cairo-Regular',
        filename: 'Cairo[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Cairo-Bold',
        filename: 'Cairo[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Cardo',
      Bold: {
        name: 'Cardo-Bold',
        filename: 'Cardo-Bold.ttf',
        variable: false
      },
      Italic: {
        name: 'Cardo-Italic',
        filename: 'Cardo-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Cardo-Regular',
        filename: 'Cardo-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Catamaran',
      Regular: {
        name: 'Catamaran-Regular',
        filename: 'Catamaran[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Catamaran-Bold',
        filename: 'Catamaran[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Caveat',
      Regular: {
        name: 'Caveat-Regular',
        filename: 'Caveat[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Caveat-Bold',
        filename: 'Caveat[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Cinzel',
      Regular: {
        name: 'Cinzel-Regular',
        filename: 'Cinzel[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Cinzel-Bold',
        filename: 'Cinzel[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Comfortaa',
      Regular: {
        name: 'Comfortaa-Regular',
        filename: 'Comfortaa-Regular.ttf',
        variable: false
      },
      Bold: {
        name: 'Comfortaa-Bold',
        filename: 'Comfortaa-Bold.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Content',
      Bold: {
        name: 'Content-Bold',
        filename: 'Content-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Content-Regular',
        filename: 'Content-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Cookie',
      Regular: {
        name: 'Cookie-Regular',
        filename: 'Cookie-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Courgette',
      Regular: {
        name: 'Courgette-Regular',
        filename: 'Courgette-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'DancingScript',
      Regular: {
        name: 'DancingScript-Regular',
        filename: 'DancingScript[wght].ttf',
        variable: true,
        variableWeight: 400,
        pixelRange: 19
      },
      Bold: {
        name: 'DancingScript-Bold',
        filename: 'DancingScript[wght].ttf',
        variable: true,
        variableWeight: 700,
        pixelRange: 15
      }
    },
    {
      fontFamily: 'Dangrek',
      Regular: {
        name: 'Dangrek-Regular',
        filename: 'Dangrek-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'DavidLibre',
      Bold: {
        name: 'DavidLibre-Bold',
        filename: 'DavidLibre-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'DavidLibre-Regular',
        filename: 'DavidLibre-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Dosis',
      Regular: {
        name: 'Dosis-Regular',
        filename: 'Dosis[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Dosis-Bold',
        filename: 'Dosis[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Exo',
      Italic: {
        name: 'Exo-Italic',
        filename: 'Exo-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Exo-BoldItalic',
        filename: 'Exo-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Exo-Regular',
        filename: 'Exo[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Exo-Bold',
        filename: 'Exo[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Exo2',
      Italic: {
        name: 'Exo2-Italic',
        filename: 'Exo2-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Exo2-BoldItalic',
        filename: 'Exo2-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Exo2-Regular',
        filename: 'Exo2[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Exo2-Bold',
        filename: 'Exo2[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'FiraSans',
      Bold: {
        name: 'FiraSans-Bold',
        filename: 'FiraSans-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'FiraSans-BoldItalic',
        filename: 'FiraSans-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'FiraSans-Italic',
        filename: 'FiraSans-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'FiraSans-Regular',
        filename: 'FiraSans-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'FjallaOne',
      Regular: {
        name: 'FjallaOne-Regular',
        filename: 'FjallaOne-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'FredokaOne',
      Regular: {
        name: 'FredokaOne-Regular',
        filename: 'FredokaOne-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Galada',
      Regular: {
        name: 'Galada-Regular',
        filename: 'Galada-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'GochiHand',
      Regular: {
        name: 'GochiHand-Regular',
        filename: 'GochiHand-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'GreatVibes',
      Regular: {
        name: 'GreatVibes-Regular',
        filename: 'GreatVibes-Regular.ttf',
        variable: false,
        pixelRange: 18
      }
    },
    {
      fontFamily: 'Handlee',
      Regular: {
        name: 'Handlee-Regular',
        filename: 'Handlee-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Hanuman',
      Bold: {
        name: 'Hanuman-Bold',
        filename: 'Hanuman-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Hanuman-Regular',
        filename: 'Hanuman-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Heebo',
      Regular: {
        name: 'Heebo-Regular',
        filename: 'Heebo[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Heebo-Bold',
        filename: 'Heebo[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Hind',
      Bold: {
        name: 'Hind-Bold',
        filename: 'Hind-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Hind-Regular',
        filename: 'Hind-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'HindGuntur',
      Bold: {
        name: 'HindGuntur-Bold',
        filename: 'HindGuntur-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'HindGuntur-Regular',
        filename: 'HindGuntur-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'HindMadurai',
      Bold: {
        name: 'HindMadurai-Bold',
        filename: 'HindMadurai-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'HindMadurai-Regular',
        filename: 'HindMadurai-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'HindSiliguri',
      Bold: {
        name: 'HindSiliguri-Bold',
        filename: 'HindSiliguri-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'HindSiliguri-Regular',
        filename: 'HindSiliguri-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'HindVadodara',
      Bold: {
        name: 'HindVadodara-Bold',
        filename: 'HindVadodara-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'HindVadodara-Regular',
        filename: 'HindVadodara-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Inconsolata',
      Regular: {
        name: 'Inconsolata-Regular',
        filename: 'Inconsolata[wdth,wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Inconsolata-Bold',
        filename: 'Inconsolata[wdth,wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'IndieFlower',
      Regular: {
        name: 'IndieFlower-Regular',
        filename: 'IndieFlower-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'JosefinSans',
      Italic: {
        name: 'JosefinSans-Italic',
        filename: 'JosefinSans-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'JosefinSans-BoldItalic',
        filename: 'JosefinSans-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'JosefinSans-Regular',
        filename: 'JosefinSans[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'JosefinSans-Bold',
        filename: 'JosefinSans[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Kalam',
      Bold: {
        name: 'Kalam-Bold',
        filename: 'Kalam-Bold.ttf',
        variable: false
      },
      Light: {
        name: 'Kalam-Light',
        filename: 'Kalam-Light.ttf',
        variable: false
      },
      Regular: {
        name: 'Kalam-Regular',
        filename: 'Kalam-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Kanit',
      Bold: {
        name: 'Kanit-Bold',
        filename: 'Kanit-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Kanit-BoldItalic',
        filename: 'Kanit-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Kanit-Italic',
        filename: 'Kanit-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Kanit-Regular',
        filename: 'Kanit-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'KaushanScript',
      Regular: {
        name: 'KaushanScript-Regular',
        filename: 'KaushanScript-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Kavivanar',
      Regular: {
        name: 'Kavivanar-Regular',
        filename: 'Kavivanar-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'KosugiMaru',
      Regular: {
        name: 'KosugiMaru-Regular',
        filename: 'KosugiMaru-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Laila',
      Bold: {
        name: 'Laila-Bold',
        filename: 'Laila-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Laila-Regular',
        filename: 'Laila-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Lalezar',
      Regular: {
        name: 'Lalezar-Regular',
        filename: 'Lalezar-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Lato',
      Bold: {
        name: 'Lato-Bold',
        filename: 'Lato-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Lato-BoldItalic',
        filename: 'Lato-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Lato-Italic',
        filename: 'Lato-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Lato-Regular',
        filename: 'Lato-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'LibreBaskerville',
      Bold: {
        name: 'LibreBaskerville-Bold',
        filename: 'LibreBaskerville-Bold.ttf',
        variable: false
      },
      Italic: {
        name: 'LibreBaskerville-Italic',
        filename: 'LibreBaskerville-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'LibreBaskerville-Regular',
        filename: 'LibreBaskerville-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Lobster',
      Regular: {
        name: 'Lobster-Regular',
        filename: 'Lobster-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Lora',
      Italic: {
        name: 'Lora-Italic',
        filename: 'Lora-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Lora-BoldItalic',
        filename: 'Lora-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Lora-Regular',
        filename: 'Lora[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Lora-Bold',
        filename: 'Lora[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'LuckiestGuy',
      Regular: {
        name: 'LuckiestGuy-Regular',
        filename: 'LuckiestGuy-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'MPLUSRounded1c',
      Bold: {
        name: 'MPLUSRounded1c-Bold',
        filename: 'MPLUSRounded1c-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'MPLUSRounded1c-Regular',
        filename: 'MPLUSRounded1c-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Maitree',
      Bold: {
        name: 'Maitree-Bold',
        filename: 'Maitree-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Maitree-Regular',
        filename: 'Maitree-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'MarckScript',
      Regular: {
        name: 'MarckScript-Regular',
        filename: 'MarckScript-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Martel',
      Bold: {
        name: 'Martel-Bold',
        filename: 'Martel-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Martel-Regular',
        filename: 'Martel-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Merienda',
      Bold: {
        name: 'Merienda-Bold',
        filename: 'Merienda-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Merienda-Regular',
        filename: 'Merienda-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Merriweather',
      Bold: {
        name: 'Merriweather-Bold',
        filename: 'Merriweather-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Merriweather-BoldItalic',
        filename: 'Merriweather-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Merriweather-Italic',
        filename: 'Merriweather-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Merriweather-Regular',
        filename: 'Merriweather-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Mina',
      Bold: {
        name: 'Mina-Bold',
        filename: 'Mina-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Mina-Regular',
        filename: 'Mina-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Mogra',
      Regular: {
        name: 'Mogra-Regular',
        filename: 'Mogra-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Montserrat',
      Italic: {
        name: 'Montserrat-Italic',
        filename: 'Montserrat-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Montserrat-BoldItalic',
        filename: 'Montserrat-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Montserrat-Regular',
        filename: 'Montserrat[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Montserrat-Bold',
        filename: 'Montserrat[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Mukta',
      Bold: {
        name: 'Mukta-Bold',
        filename: 'Mukta-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Mukta-Regular',
        filename: 'Mukta-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'MuktaMahee',
      Bold: {
        name: 'MuktaMahee-Bold',
        filename: 'MuktaMahee-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'MuktaMahee-Regular',
        filename: 'MuktaMahee-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'MuktaVaani',
      Bold: {
        name: 'MuktaVaani-Bold',
        filename: 'MuktaVaani-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'MuktaVaani-Regular',
        filename: 'MuktaVaani-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'NanumGothic',
      Bold: {
        name: 'NanumGothic-Bold',
        filename: 'NanumGothic-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'NanumGothic-Regular',
        filename: 'NanumGothic-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'NanumMyeongjo',
      Bold: {
        name: 'NanumMyeongjo-Bold',
        filename: 'NanumMyeongjo-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'NanumMyeongjo-Regular',
        filename: 'NanumMyeongjo-Regular.ttf',
        variable: false,
        pixelRange: 18
      }
    },
    {
      fontFamily: 'NanumPenScript',
      Regular: {
        name: 'NanumPenScript-Regular',
        filename: 'NanumPenScript-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'NotoKufiArabic',
      Regular: {
        name: 'NotoKufiArabic-Regular',
        filename: 'NotoKufiArabic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'NotoKufiArabic-Bold',
        filename: 'NotoKufiArabic[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'NotoSans',
      Bold: {
        name: 'NotoSans-Bold',
        filename: 'NotoSans-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'NotoSans-BoldItalic',
        filename: 'NotoSans-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'NotoSans-Italic',
        filename: 'NotoSans-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'NotoSans-Regular',
        filename: 'NotoSans-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'NotoSansArabic',
      Regular: {
        name: 'NotoSansArabic-Regular',
        filename: 'NotoSansArabic[wdth,wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'NotoSansArabic-Bold',
        filename: 'NotoSansArabic[wdth,wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'NotoSansDevanagari',
      Regular: {
        name: 'NotoSansDevanagari-Regular',
        filename: 'NotoSansDevanagari-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'NotoSerif',
      Bold: {
        name: 'NotoSerif-Bold',
        filename: 'NotoSerif-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'NotoSerif-Regular',
        filename: 'NotoSerif-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Nunito',
      Italic: {
        name: 'Nunito-Italic',
        filename: 'Nunito-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Nunito-BoldItalic',
        filename: 'Nunito-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Nunito-Regular',
        filename: 'Nunito[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Nunito-Bold',
        filename: 'Nunito[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'OpenSans',
      Italic: {
        name: 'OpenSans-Italic',
        filename: 'OpenSans-Italic[wdth,wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'OpenSans-BoldItalic',
        filename: 'OpenSans-Italic[wdth,wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'OpenSans-Regular',
        filename: 'OpenSans[wdth,wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'OpenSans-Bold',
        filename: 'OpenSans[wdth,wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Oswald',
      Regular: {
        name: 'Oswald-Regular',
        filename: 'Oswald[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Oswald-Bold',
        filename: 'Oswald[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Overpass',
      Italic: {
        name: 'Overpass-Italic',
        filename: 'Overpass-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Overpass-BoldItalic',
        filename: 'Overpass-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Overpass-Regular',
        filename: 'Overpass[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Overpass-Bold',
        filename: 'Overpass[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Oxygen',
      Bold: {
        name: 'Oxygen-Bold',
        filename: 'Oxygen-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Oxygen-Regular',
        filename: 'Oxygen-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'PT_Sans',
      Web: {
        name: 'PT_Sans-Web',
        filename: 'PT_Sans-Web-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'PT_Serif',
      Web: {
        name: 'PT_Serif-Web',
        filename: 'PT_Serif-Web-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Pacifico',
      Regular: {
        name: 'Pacifico-Regular',
        filename: 'Pacifico-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Padauk',
      Bold: {
        name: 'Padauk-Bold',
        filename: 'Padauk-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Padauk-Regular',
        filename: 'Padauk-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'PatrickHand',
      Regular: {
        name: 'PatrickHand-Regular',
        filename: 'PatrickHand-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'PatuaOne',
      Regular: {
        name: 'PatuaOne-Regular',
        filename: 'PatuaOne-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Pavanam',
      Regular: {
        name: 'Pavanam-Regular',
        filename: 'Pavanam-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Peddana',
      Regular: {
        name: 'Peddana-Regular',
        filename: 'Peddana-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'PermanentMarker',
      Regular: {
        name: 'PermanentMarker-Regular',
        filename: 'PermanentMarker-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'PlayfairDisplay',
      Italic: {
        name: 'PlayfairDisplay-Italic',
        filename: 'PlayfairDisplay-Italic[wght].ttf',
        variable: true,
        variableWeight: 400,
        pixelRange: 20
      },
      BoldItalic: {
        name: 'PlayfairDisplay-BoldItalic',
        filename: 'PlayfairDisplay-Italic[wght].ttf',
        variable: true,
        variableWeight: 700,
        pixelRange: 20
      },
      Regular: {
        name: 'PlayfairDisplay-Regular',
        filename: 'PlayfairDisplay[wght].ttf',
        variable: true,
        variableWeight: 400,
        pixelRange: 20
      },
      Bold: {
        name: 'PlayfairDisplay-Bold',
        filename: 'PlayfairDisplay[wght].ttf',
        variable: true,
        variableWeight: 700,
        pixelRange: 20
      }
    },
    {
      fontFamily: 'PoiretOne',
      Regular: {
        name: 'PoiretOne-Regular',
        filename: 'PoiretOne-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Poppins',
      Bold: {
        name: 'Poppins-Bold',
        filename: 'Poppins-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Poppins-BoldItalic',
        filename: 'Poppins-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Poppins-Italic',
        filename: 'Poppins-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Poppins-Regular',
        filename: 'Poppins-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Pridi',
      Bold: {
        name: 'Pridi-Bold',
        filename: 'Pridi-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Pridi-Regular',
        filename: 'Pridi-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Prompt',
      Bold: {
        name: 'Prompt-Bold',
        filename: 'Prompt-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Prompt-BoldItalic',
        filename: 'Prompt-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Prompt-Italic',
        filename: 'Prompt-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Prompt-Regular',
        filename: 'Prompt-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Questrial',
      Regular: {
        name: 'Questrial-Regular',
        filename: 'Questrial-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Quicksand',
      Regular: {
        name: 'Quicksand-Regular',
        filename: 'Quicksand[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Quicksand-Bold',
        filename: 'Quicksand[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Rajdhani',
      Bold: {
        name: 'Rajdhani-Bold',
        filename: 'Rajdhani-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Rajdhani-Regular',
        filename: 'Rajdhani-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Raleway',
      Italic: {
        name: 'Raleway-Italic',
        filename: 'Raleway-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Raleway-BoldItalic',
        filename: 'Raleway-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Raleway-Regular',
        filename: 'Raleway[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Raleway-Bold',
        filename: 'Raleway[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Rasa',
      Italic: {
        name: 'Rasa-Italic',
        filename: 'Rasa-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Rasa-BoldItalic',
        filename: 'Rasa-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Rasa-Regular',
        filename: 'Rasa[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Rasa-Bold',
        filename: 'Rasa[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Righteous',
      Regular: {
        name: 'Righteous-Regular',
        filename: 'Righteous-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Roboto',
      Bold: {
        name: 'Roboto-Bold',
        filename: 'Roboto-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Roboto-BoldItalic',
        filename: 'Roboto-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Roboto-Italic',
        filename: 'Roboto-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Roboto-Regular',
        filename: 'Roboto-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'RobotoCondensed',
      Bold: {
        name: 'RobotoCondensed-Bold',
        filename: 'RobotoCondensed-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'RobotoCondensed-BoldItalic',
        filename: 'RobotoCondensed-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'RobotoCondensed-Italic',
        filename: 'RobotoCondensed-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'RobotoCondensed-Regular',
        filename: 'RobotoCondensed-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'RockSalt',
      Regular: {
        name: 'RockSalt-Regular',
        filename: 'RockSalt-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Rubik',
      Italic: {
        name: 'Rubik-Italic',
        filename: 'Rubik-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Rubik-BoldItalic',
        filename: 'Rubik-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Rubik-Regular',
        filename: 'Rubik[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Rubik-Bold',
        filename: 'Rubik[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Sacramento',
      Regular: {
        name: 'Sacramento-Regular',
        filename: 'Sacramento-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Satisfy',
      Regular: {
        name: 'Satisfy-Regular',
        filename: 'Satisfy-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SawarabiGothic',
      Regular: {
        name: 'SawarabiGothic-Regular',
        filename: 'SawarabiGothic-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SawarabiMincho',
      Regular: {
        name: 'SawarabiMincho-Regular',
        filename: 'SawarabiMincho-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Shrikhand',
      Regular: {
        name: 'Shrikhand-Regular',
        filename: 'Shrikhand-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Slabo27px',
      Regular: {
        name: 'Slabo27px-Regular',
        filename: 'Slabo27px-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SongMyung',
      Regular: {
        name: 'SongMyung-Regular',
        filename: 'SongMyung-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SourceCodePro',
      Italic: {
        name: 'SourceCodePro-Italic',
        filename: 'SourceCodePro-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'SourceCodePro-BoldItalic',
        filename: 'SourceCodePro-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'SourceCodePro-Regular',
        filename: 'SourceCodePro[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'SourceCodePro-Bold',
        filename: 'SourceCodePro[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'SourceSansPro',
      Bold: {
        name: 'SourceSansPro-Bold',
        filename: 'SourceSansPro-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'SourceSansPro-BoldItalic',
        filename: 'SourceSansPro-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'SourceSansPro-Italic',
        filename: 'SourceSansPro-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'SourceSansPro-Regular',
        filename: 'SourceSansPro-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SourceSerifPro',
      Bold: {
        name: 'SourceSerifPro-Bold',
        filename: 'SourceSerifPro-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'SourceSerifPro-BoldItalic',
        filename: 'SourceSerifPro-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'SourceSerifPro-Italic',
        filename: 'SourceSerifPro-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'SourceSerifPro-Regular',
        filename: 'SourceSerifPro-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SpecialElite',
      Regular: {
        name: 'SpecialElite-Regular',
        filename: 'SpecialElite-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'SreeKrushnadevaraya',
      Regular: {
        name: 'SreeKrushnadevaraya-Regular',
        filename: 'SreeKrushnadevaraya-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Sriracha',
      Regular: {
        name: 'Sriracha-Regular',
        filename: 'Sriracha-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Suranna',
      Regular: {
        name: 'Suranna-Regular',
        filename: 'Suranna-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Tajawal',
      Bold: {
        name: 'Tajawal-Bold',
        filename: 'Tajawal-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Tajawal-Regular',
        filename: 'Tajawal-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Teko',
      Bold: {
        name: 'Teko-Bold',
        filename: 'Teko-Bold.ttf',
        variable: false
      },
      Regular: {
        name: 'Teko-Regular',
        filename: 'Teko-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'TenaliRamakrishna',
      Regular: {
        name: 'TenaliRamakrishna-Regular',
        filename: 'TenaliRamakrishna-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'TitilliumWeb',
      Bold: {
        name: 'TitilliumWeb-Bold',
        filename: 'TitilliumWeb-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'TitilliumWeb-BoldItalic',
        filename: 'TitilliumWeb-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'TitilliumWeb-Italic',
        filename: 'TitilliumWeb-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'TitilliumWeb-Regular',
        filename: 'TitilliumWeb-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Ubuntu',
      Bold: {
        name: 'Ubuntu-Bold',
        filename: 'Ubuntu-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'Ubuntu-BoldItalic',
        filename: 'Ubuntu-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'Ubuntu-Italic',
        filename: 'Ubuntu-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'Ubuntu-Regular',
        filename: 'Ubuntu-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'VarelaRound',
      Regular: {
        name: 'VarelaRound-Regular',
        filename: 'VarelaRound-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Viga',
      Regular: {
        name: 'Viga-Regular',
        filename: 'Viga-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'Vollkorn',
      Italic: {
        name: 'Vollkorn-Italic',
        filename: 'Vollkorn-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'Vollkorn-BoldItalic',
        filename: 'Vollkorn-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'Vollkorn-Regular',
        filename: 'Vollkorn[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'Vollkorn-Bold',
        filename: 'Vollkorn[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'WorkSans',
      Italic: {
        name: 'WorkSans-Italic',
        filename: 'WorkSans-Italic[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      BoldItalic: {
        name: 'WorkSans-BoldItalic',
        filename: 'WorkSans-Italic[wght].ttf',
        variable: true,
        variableWeight: 700
      },
      Regular: {
        name: 'WorkSans-Regular',
        filename: 'WorkSans[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'WorkSans-Bold',
        filename: 'WorkSans[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'YanoneKaffeesatz',
      Regular: {
        name: 'YanoneKaffeesatz-Regular',
        filename: 'YanoneKaffeesatz[wght].ttf',
        variable: true,
        variableWeight: 400
      },
      Bold: {
        name: 'YanoneKaffeesatz-Bold',
        filename: 'YanoneKaffeesatz[wght].ttf',
        variable: true,
        variableWeight: 700
      }
    },
    {
      fontFamily: 'Yellowtail',
      Regular: {
        name: 'Yellowtail-Regular',
        filename: 'Yellowtail-Regular.ttf',
        variable: false
      }
    },
    {
      fontFamily: 'ZillaSlab',
      Bold: {
        name: 'ZillaSlab-Bold',
        filename: 'ZillaSlab-Bold.ttf',
        variable: false
      },
      BoldItalic: {
        name: 'ZillaSlab-BoldItalic',
        filename: 'ZillaSlab-BoldItalic.ttf',
        variable: false
      },
      Italic: {
        name: 'ZillaSlab-Italic',
        filename: 'ZillaSlab-Italic.ttf',
        variable: false
      },
      Regular: {
        name: 'ZillaSlab-Regular',
        filename: 'ZillaSlab-Regular.ttf',
        variable: false
      }
    }
  ]
};
