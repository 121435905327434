import classNames from 'classnames';
import React from 'react';

import './Spinner.css';

interface Props {
  icon?: boolean;
}

const Spinner = ({ icon = false }: Props) => {
  const classnames = classNames('Spinner', {
    'Spinner--icon': icon
  });
  return <div className={classnames} />;
};

export default Spinner;
