import { CursorsState } from 'js/types';

import { LoadCursorsSuccessAction, LOAD_CURSORS_SUCCESS } from '../actionCreators/loadCursorsSuccessAction';

const initialState: CursorsState = {
  handErase: [],
  handDrag: [],
  penDraw: [],
  handDraw: []
};

export default function cursorReducer(
  state: CursorsState = initialState,
  action: LoadCursorsSuccessAction
): CursorsState {
  switch (action.type) {
    case LOAD_CURSORS_SUCCESS:
      return {
        ...state,
        handErase: action.eraseCursors,
        handDrag: action.dragCursors,
        penDraw: action.cursors.filter(cursor => cursor.group === 'pens'),
        handDraw: action.cursors.filter(cursor => cursor.group !== 'pens')
      };

    default:
      return state;
  }
}
