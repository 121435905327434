import { fabric } from 'fabric';
import { IObjectOptions } from 'fabric/fabric-impl';

interface ActiveSelectionOptions extends IObjectOptions {
  lockIndicator?: boolean;
}

interface SetControlsVisibilityOptions {
  bl?: boolean;
  br?: boolean;
  mb?: boolean;
  ml?: boolean;
  mr?: boolean;
  mt?: boolean;
  tl?: boolean;
  tr?: boolean;
  mtr?: boolean;
  lockIndicator?: boolean;
}
class ActiveSelection extends fabric.ActiveSelection {
  public lockIndicator: boolean;
  constructor(elements?: fabric.Object[], options?: ActiveSelectionOptions) {
    super(elements, options);
    this.lockIndicator = options?.lockIndicator || false;
  }

  setControlsVisibility(options?: SetControlsVisibilityOptions) {
    super.setControlsVisibility(options);
    this.lockIndicator = options?.lockIndicator || this.lockIndicator;

    return this;
  }
}

export default ActiveSelection;
