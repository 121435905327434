import React from 'react';
import PropTypes from 'prop-types';

const IconTick = ({ className, src }) => (
  <span className={`icon ${className}`} src={src}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      style={{ enableBackground: 'new 0 0 100 100' }}
      xmlSpace="preserve"
    >
      <path
        d="M7.9,42.5c0.9,0,1.7,0.3,2.4,1L35.7,69l54.1-54.1c0.7-0.7,1.5-1,2.4-1c0.9,0,1.7,0.3,2.4,1l4.4,4.4c0.7,0.7,1,1.5,1,2.4
    s-0.3,1.7-1,2.4l-60.9,61c-0.7,0.7-1.5,1-2.4,1c-0.9,0-1.7-0.3-2.4-1L1,52.8c-0.7-0.7-1-1.5-1-2.4c0-0.9,0.3-1.7,1-2.4l4.4-4.4
    C6.2,42.9,6.9,42.5,7.9,42.5z"
      />
    </svg>
  </span>
);

IconTick.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export default IconTick;
