export const getSVGAttributeValueByVSTag = (
  svg: SVGSVGElement | null,
  VSTag?: string,
  svgAttribute?: string,
  fallbackValue?: string
) => {
  if (!svg || !VSTag || !svgAttribute) {
    return undefined;
  }

  const hasTag = svg.querySelector(VSTag) !== null;

  if (!hasTag) return undefined;

  const value =
    svg.querySelector(VSTag)?.getAttribute(svgAttribute) ??
    svg.querySelector(`${VSTag} [${svgAttribute}]:first-child`)?.getAttribute(svgAttribute);

  return value || fallbackValue;
};
