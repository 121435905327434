import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { getStore } from 'js/store';
import * as PIXI from 'pixi.js';

import RendererWorker from '../PixiTimeline/worker/WaveformRenderer.worker?worker';

import { PostMessageData, WaveformImageData } from './worker/WaveformRenderer.worker';
import timelineBus, { LayerZoomConfig, WRAPPER_UPDATE_ZOOM } from './TimelineControlBus';
import { getMaxTextureSize, isOffscreenCanvasSupported, renderWaveformImages } from './Utils/waveformHelper';

type WaveformTextureData = { baseTexture: PIXI.BaseTexture; startPoint: number };

let waveFormsPool: { [key: string]: WaveformTextureData[] } = {};
export let soundsPool: { [key: string]: AudioBuffer } = {};
let preloadAudioPromises: { [key: string]: Promise<void> } = {};
const WAVEFORM_FILL_COLOR = '#ffffff';
const WAVEFORM_DOWNSCALE_FACTOR_X = 4;
const WAVEFORM_DOWNSCALE_FACTOR_Y = 2;

export function deleteWaveformCachedDataById(assetId: string) {
  waveFormsPool.hasOwnProperty(assetId) && delete waveFormsPool[assetId];
  soundsPool.hasOwnProperty(assetId) && delete soundsPool[assetId];
  preloadAudioPromises.hasOwnProperty(assetId) && delete preloadAudioPromises[assetId];
}

export function deleteAllWaveformCachedData() {
  waveFormsPool = {};
  soundsPool = {};
  preloadAudioPromises = {};
}

export default class TimelineAudioWaveform extends PIXI.Container {
  assetId: string;
  currentZoom: PIXI.Point;
  container: PIXI.Container;
  maxZoomX = 250;
  maxZoomY = 60;
  rendererWorker?: Worker;
  maxTextureSize: number;
  waitingToLoad = false;

  constructor(assetId: string, currentZoom: PIXI.Point, gl: WebGLRenderingContext) {
    super();
    timelineBus.addListener(WRAPPER_UPDATE_ZOOM, this.updateZoom, this);
    this.assetId = assetId;
    this.currentZoom = currentZoom;
    this.container = new PIXI.Container();
    this.addChild(this.container);
    this.maxTextureSize = getMaxTextureSize(gl);
  }

  async preloadAudio() {
    if (soundsPool[this.assetId]) return;
    const assetUrl = await appServices.getAssetUrl(this.assetId);
    const audioContext = typeof AudioContext !== 'undefined' ? new AudioContext() : undefined;
    const response = await fetch(assetUrl);
    const arrayBuffer = await response?.arrayBuffer();
    const audioBuffer = await audioContext?.decodeAudioData(arrayBuffer);
    audioBuffer && (soundsPool[this.assetId] = audioBuffer);
  }

  destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    this.rendererWorker?.removeEventListener('message', this.handleGeneratedWaveform);
    this.rendererWorker?.terminate();

    super.destroy(options);
  }

  init() {
    if (!preloadAudioPromises[this.assetId]) preloadAudioPromises[this.assetId] = this.preloadAudio();
  }

  async generate() {
    if (this.waitingToLoad) return;
    this.waitingToLoad = true;
    // make sure preload audio is done and prevent duplicate fetching
    await preloadAudioPromises[this.assetId];
    this.waitingToLoad = false;
    // if waveform already generated, just add it to the sprite
    if (waveFormsPool[this.assetId]) return this.addWaveFormToSprite(waveFormsPool[this.assetId]);
    // if offscreen canvas is supported, use worker to generate waveform
    if (isOffscreenCanvasSupported()) this.rendererWorker = new RendererWorker();
    // if offscreen canvas is not supported, fallback to generate waveform in main thread
    if (!this.rendererWorker) return this.fallbackGenerateWaveform();
    // generate waveform using worker
    this.rendererWorker?.addEventListener('message', this.handleGeneratedWaveform);
    const renderWaveformImageConfig = this.getRenderWaveformImageConfig(true);
    this.rendererWorker.postMessage(renderWaveformImageConfig);
  }

  handleRenderError() {
    getStore().dispatch(
      showError({
        message: 'Oops! We weren’t able to load the audio file onto the advanced timeline.',
        description: 'Try refreshing the page, or record/upload your audio again.'
      })
    );
  }

  handleGeneratedWaveform = (e: MessageEvent<PostMessageData>) => {
    if (e.data.error || !e.data.waveformImages) return this.handleRenderError();
    waveFormsPool[this.assetId] = this.generateTexturesDataFromImagesData(e.data.waveformImages);
    this.addWaveFormToSprite(waveFormsPool[this.assetId]);
    this.emit('waveformGenerated');
    this.rendererWorker?.removeEventListener('message', this.handleGeneratedWaveform);
    setTimeout(() => {
      this.rendererWorker?.terminate();
    }, 10);
  };

  generateTexturesDataFromImagesData = (waveformImages: WaveformImageData[]) => {
    const textureData: WaveformTextureData[] = [];
    for (const waveformImage of waveformImages) {
      const { imageUrl, imageStartPoint } = waveformImage;
      const baseTexture = PIXI.BaseTexture.from(imageUrl);
      textureData.push({
        baseTexture: baseTexture,
        startPoint: imageStartPoint
      });
    }
    return textureData;
  };

  addWaveFormToSprite = (textureData: WaveformTextureData[]) => {
    for (const { baseTexture, startPoint } of textureData) {
      baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
      const texture = new PIXI.Texture(baseTexture);
      const childSprite = new PIXI.Sprite(texture);
      this.container.addChild(childSprite);
      childSprite.x = startPoint;
    }

    this.updateZoom({ x: this.currentZoom.x, y: this.currentZoom.y });
  };

  updateZoom(newZoom: LayerZoomConfig) {
    if (!this.currentZoom) {
      this.currentZoom = new PIXI.Point(newZoom.x, newZoom.y);
    }

    this.currentZoom.x = newZoom.x as number;
    this.currentZoom.y = newZoom.y as number;
    this.container.scale.x = (WAVEFORM_DOWNSCALE_FACTOR_X * this.currentZoom.x) / this.maxZoomX;
    this.container.scale.y = (WAVEFORM_DOWNSCALE_FACTOR_Y * this.currentZoom.y) / this.maxZoomY;
    this.container.x = 0;
  }

  getRenderWaveformImageConfig(useOffscreenCanvas = false) {
    const duration = soundsPool[this.assetId]?.duration;
    const audioData = soundsPool[this.assetId]?.getChannelData(0);
    const renderPieces = Math.ceil((duration * this.maxZoomX) / WAVEFORM_DOWNSCALE_FACTOR_X / this.maxTextureSize);
    return {
      audioData,
      renderPieces,
      pieceWidth: this.maxTextureSize,
      canvasWidth: (duration * this.maxZoomX) / WAVEFORM_DOWNSCALE_FACTOR_X,
      canvasHeight: this.maxZoomY / WAVEFORM_DOWNSCALE_FACTOR_Y,
      fill: WAVEFORM_FILL_COLOR,
      useOffscreenCanvas
    };
  }

  async fallbackGenerateWaveform() {
    const renderWaveformImageConfig = this.getRenderWaveformImageConfig(false);

    const waveformImages = await renderWaveformImages(renderWaveformImageConfig);
    if (!waveformImages) return this.handleRenderError();
    waveFormsPool[this.assetId] = this.generateTexturesDataFromImagesData(waveformImages);

    this.addWaveFormToSprite(waveFormsPool[this.assetId]);
  }
}
