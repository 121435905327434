import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import getOptimizelyInstance from 'js/shared/helpers/optimizely';
import LoadingSpinner from 'js/shared/components/LoadingSpinner';
import ReactTooltip from 'react-tooltip';
import Callout from 'js/shared/components/Callout';

import AuthenticatedComponent from './shared/components/AuthenticatedComponent';
import { validateToken, setAccountManagementRedirect } from './actionCreators/authActions';
import { ROUTE_CODES } from './config/routes';
import config from './config/config';
import loadFacebookPixel from './shared/helpers/loadFacebookPixel';
import { APP_TOOLTIP_ID } from './config/consts';
import { NoOnlineStorage } from './shared/components/NoOnlineStorage';
import { rendererLog } from './shared/lib/CloudRenderer';
import DownloadIframe from './shared/components/DownloadIframe';
import { loadAudioLibrary } from './actionCreators/audioLibraryActions';
import AcceptTermsAndMarketing from './shared/components/AcceptTermsAndMarketing';

import './App.css';

const HomeView = lazy(() => import('./connectors/HomeViewConnector'));
const AuthenticationRouter = lazy(() => import('./authentication/AuthenticationRouter'));
const RendererView = lazy(() => import('./connectors/RendererViewConnector'));
const EditorView = lazy(() => import('./editor/EditorView'));
const ContentfulCallback = lazy(() => import('./shared/components/ContentfulCallback'));
const PreviewView = lazy(() => import('./preview/PreviewView'));

const { ENVIRONMENT } = config;

const optimizely = getOptimizelyInstance();

class App extends Component {
  constructor(props) {
    super(props);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  componentDidMount() {
    const { validateToken, history, setAccountManagementRedirect, loadAudioLibrary } = this.props;

    if (!history.location.pathname.includes('/render')) {
      document.addEventListener('visibilitychange', this.onVisibilityChange);
      document.addEventListener('sleekNote', this.onSleekNoteLoad);
      // Only load these dependencies for the main app and not the renderer to try and speed things up a little on the renderer.
      loadFacebookPixel();
    }

    if (window.location.search.indexOf('referrer=account') !== -1) {
      setAccountManagementRedirect(true);
    }

    validateToken({ showSpinner: true, revalidation: false });
    loadAudioLibrary();
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    document.removeEventListener('sleekNote', this.onSleekNoteLoad);
  }

  onVisibilityChange() {
    const { validateToken, isAuthenticating } = this.props;
    const pageVisible = document.visibilityState === 'visible';
    if (pageVisible) {
      rendererLog('Page has moved to foreground');
      validateToken({ showSpinner: false, revalidation: !isAuthenticating });
    } else {
      rendererLog('Page has moved to BACKGROUND');
    }
  }

  onSleekNoteLoad(e) {
    if (e.data.type === 'load') window.sleekNoteClose = () => e.data.command('close', e.data.campaignId);
  }

  render() {
    const {
      currentUser,
      isAuthenticating,
      noOnlineStorage,
      checkingUpgrade,
      needsToAcceptTerms,
      isCheckingTermsAcceptance
    } = this.props;
    const { id, accountType, email } = currentUser;

    if (isAuthenticating) {
      return <LoadingSpinner />;
    }

    ReactTooltip.rebuild();

    return (
      <OptimizelyProvider
        optimizely={optimizely}
        user={{ id, attributes: { userTier: accountType, env: ENVIRONMENT, email, userId: id } }}
      >
        <div className="App">
          <Callout />
          <Suspense fallback={<LoadingSpinner />}>
            <Switch>
              <Route path={ROUTE_CODES.RENDER} component={RendererView} />

              <Route path={ROUTE_CODES.PREVIEW} component={PreviewView} />

              <Route path={ROUTE_CODES.AUTH} component={AuthenticationRouter} />

              {isCheckingTermsAcceptance && <LoadingSpinner />}

              {needsToAcceptTerms && <AcceptTermsAndMarketing />}

              {noOnlineStorage && <NoOnlineStorage />}

              <AuthenticatedComponent path={ROUTE_CODES.CONTENTFUL_CALLBACK} component={ContentfulCallback} />
              <AuthenticatedComponent path={ROUTE_CODES.EDITOR} component={EditorView} />
              <AuthenticatedComponent path={ROUTE_CODES.HOME} component={HomeView} />
            </Switch>
            <ReactTooltip id={APP_TOOLTIP_ID} className="t-utility-label" />

            <DownloadIframe />
          </Suspense>

          {checkingUpgrade && <LoadingSpinner message="Upgrading your account" />}
        </div>
      </OptimizelyProvider>
    );
  }
}

const mapStateToProps = ({ auth, checkout, ui }) => ({
  isAuthenticating: auth && auth.isAuthenticating,
  currentUser: auth && auth.currentUser,
  noOnlineStorage: auth && auth.noOnlineStorage,
  checkingUpgrade: checkout.step === 'CHECKING_UPGRADE',
  isCheckingTermsAcceptance: ui.isCheckingTermsAcceptance,
  needsToAcceptTerms: ui && ui.needsToAcceptTerms
});

const mapDispatchToProps = dispatch => ({
  validateToken: ({ showSpinner, revalidation }) => dispatch(validateToken({ showSpinner, revalidation })),
  setAccountManagementRedirect: redirect => dispatch(setAccountManagementRedirect(redirect)),
  loadAudioLibrary: () => dispatch(loadAudioLibrary())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
