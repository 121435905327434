import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { setAnimationDirection } from 'js/actionCreators/animationActions';
import { selectableSpinDirectionOptions } from 'js/config/animationOptions';
import { getStageTweenKey } from 'js/shared/helpers/getStageTweenKey';
import { getCurrentSpinDirection } from 'js/shared/helpers/getCurrentSpinDirection';

import OptionMenuButton from './OptionMenuButton';

import './SpinDirectionMenu.css';

const TOOLTIP_ID = 'spin-direction-tooltip';

const SpinDirectionMenu = ({ element, elements, scribe, onSelection, stageKey }) => {
  const dispatch = useDispatch();
  const activeScene = scribe.scenes.find(scene => scene.active);
  const tweenKey = getStageTweenKey(stageKey);

  const animationType = element !== undefined ? element[tweenKey].id : elements[0][tweenKey].id;

  const handleButtonClick = spinRotation => {
    let elementIds;
    if (element !== undefined) {
      elementIds = [element.id];
    }

    if (elements !== undefined) {
      elementIds = elements.map(el => el.id);
    }

    dispatch(
      setAnimationDirection({
        animationType,
        stage: stageKey,
        elementIds,
        scribeId: scribe.id,
        spinRotation
      })
    );

    onSelection();
  };

  const currentSelectedValue = getCurrentSpinDirection(element, elements, tweenKey);

  return (
    <div className="SpinDirectionMenu">
      <div className="SpinDirectionMenu__Grid">
        {selectableSpinDirectionOptions.map(dir => {
          if (dir.label === 'Scene Thumbnail') {
            return (
              <div key={dir.label} className="SpinDirectionMenu__SceneContainer">
                <img className="SpinDirectionMenu__SceneTile" src={activeScene.thumbnailImage} alt={dir.label} />
              </div>
            );
          }
          return (
            <OptionMenuButton
              key={dir.label}
              selected={dir.value === currentSelectedValue}
              onClick={() => handleButtonClick(dir.value)}
              tooltipProps={{
                'data-tip': dir.label,
                'data-for': TOOLTIP_ID
              }}
            >
              {dir.Icon()}
            </OptionMenuButton>
          );
        })}
      </div>
      <ReactTooltip id={TOOLTIP_ID} className="t-utility-label" />
    </div>
  );
};

export default SpinDirectionMenu;
