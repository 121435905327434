import React from 'react';

export default function IconAnimationSpinLoop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      enableBackground="new 0 0 60 60"
      xmlSpace="preserve"
      stroke={'currentcolor'}
      fill={'currentcolor'}
    >
      <path d="M30,38.5c-4.3,0-7.7-3.5-7.7-7.7s3.5-7.7,7.7-7.7c4.3,0,7.7,3.5,7.7,7.7S34.3,38.5,30,38.5z M30,26.1  c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7S32.6,26.1,30,26.1z" />
      <path d="M41,34.9c-1.7,4.4-6,7.5-11,7.5c-5.9,0-10.9-4.4-11.7-10.1l1.4,1.4l2.1-2.1l-4.1-4.1c-0.6-0.6-1.5-0.6-2.1,0l0,0l-0.1-0.1  c0,0.1,0,0.1,0,0.2l-4,4l2.1,2.1l1.7-1.7C16,39.5,22.3,45.3,30,45.3c5.9,0,10.9-3.4,13.3-8.4L41,34.9z" />
      <path d="M44.5,33.5l4-4l-2.1-2.1l-1.7,1.7C44,21.6,37.7,15.7,30,15.7c-5.8,0-10.9,3.4-13.3,8.3l2.3,2.1c1.8-4.3,6-7.4,11-7.4  c6,0,10.9,4.4,11.7,10.2l-1.5-1.5l-2.1,2.1l4.1,4.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.2,1.1-0.4L44.5,33.5L44.5,33.5  C44.5,33.6,44.5,33.6,44.5,33.5z" />
    </svg>
  );
}
