import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import EditPanel from 'js/editor/EditPanel';
import EditPanelHeading from 'js/editor/EditPanelHeading';
import EditPanelForm from 'js/editor/EditPanelForm';
import EditPanelFieldset from 'js/editor/EditPanelFieldset';
import AnimationTypeSelectorButtons, {
  AnimationTypeSelectorButtonGroup,
  AnimationTypeSelectorButtonGroupText
} from 'js/editor/AnimationPanels/AnimationTypeSelectorButtons';
import { Popover } from '@material-ui/core';
import AnimationOptionMenu from 'js/editor/AnimationPanels/AnimationOptionMenu';
import ScribelyIconButton from 'js/editor/ScribelyIconButton';
import { getScenesById } from 'js/shared/helpers/scenesHelpers';
import { setSceneTransitionType, setSceneTransitionDuration } from 'js/actionCreators/sceneTransitionsActions';
import SCENE_TRANSITION_OPTIONS from 'js/config/sceneTransitionsOptions';
import { SCENE_TRANSITION_KEY_NONE } from 'js/config/consts';
import EditPanelNumberInput from 'js/editor/EditPanelNumberInput';
import { ExistingScribeModel, ScribeCursorId } from 'js/types';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';

interface Props {
  scribe: ExistingScribeModel;
  selectedSceneIds: Array<string>;
}

interface MenuOptions {
  anchor: Element | null;
  primary: boolean;
  secondary: boolean;
  tertiary: boolean;
}

type AllSceneTransitionSettings = {
  cursorId?: ScribeCursorId | 'Mixed';
  direction?: string;
  rotate?: string;
  times?: string | number;
};

export const SCENE_TRANSITION_PANEL_EVENT_TRIGGER = 'Scene Transition Panel';

const SceneTransitionsPanel = ({ scribe, selectedSceneIds }: Props) => {
  const [menuOpen, setMenuOpen] = useState<MenuOptions | null>(null);
  const scenes = useMemo(() => getScenesById(selectedSceneIds, scribe), [scribe, selectedSceneIds]);

  const firstTransitionType = scenes[0]?.settings?.sceneTransitionType || SCENE_TRANSITION_KEY_NONE;
  const allScenesTransitionType = scenes.every(
    scene => (scene?.settings.sceneTransitionType ?? SCENE_TRANSITION_KEY_NONE) === firstTransitionType
  )
    ? firstTransitionType
    : 'Mixed';

  const firstTransitionSettings = scenes[0]?.settings?.sceneTransitionConfig || {};
  const direction = firstTransitionSettings.direction;
  const cursorId = firstTransitionSettings.cursorId;
  const rotate = firstTransitionSettings.rotate;
  const times = firstTransitionSettings.times;
  const allScenesTransitionSettings: AllSceneTransitionSettings = cloneDeep(firstTransitionSettings);
  scenes.forEach(scene => {
    const sceneConfig = scene?.settings.sceneTransitionConfig;
    allScenesTransitionSettings.direction = sceneConfig?.direction === direction ? direction : 'Mixed';
    allScenesTransitionSettings.cursorId = sceneConfig?.cursorId === cursorId ? cursorId : 'Mixed';
    allScenesTransitionSettings.rotate = sceneConfig?.rotate === rotate ? rotate : 'Mixed';
    allScenesTransitionSettings.times = sceneConfig?.times === times ? times : 'Mixed';
  });

  const firstSceneDuration = scenes[0]?.settings?.sceneTransitionTime || 0;
  const allScenesTransitionDuration = scenes.every(scene =>
    isEqual(scene?.settings.sceneTransitionTime, firstSceneDuration)
  )
    ? firstSceneDuration
    : undefined;

  const animationType = allScenesTransitionType;
  const config = allScenesTransitionSettings;
  const duration = allScenesTransitionDuration ? allScenesTransitionDuration : '-';

  const dispatch = useDispatch();

  // Scene Transition
  const sceneTransitionsOptions = SCENE_TRANSITION_OPTIONS.find(opt => opt.type === animationType);

  const animationTypeTitle =
    allScenesTransitionType === 'Mixed' ? allScenesTransitionType : sceneTransitionsOptions?.title;
  const Icon = allScenesTransitionType === 'Mixed' ? IconIndeterminate : sceneTransitionsOptions?.Icon;

  const containsSceneTransitionNone = scenes.some(
    scene =>
      scene.settings.sceneTransitionType === SCENE_TRANSITION_KEY_NONE ||
      scene.settings.sceneTransitionType === undefined
  );

  // Secondary Menu
  let ChoiceButton;
  let secondaryMenuLabel = '';
  let allDirectionsSecondary = false;
  let secondaryMenu;

  if (allScenesTransitionType !== 'Mixed' && sceneTransitionsOptions?.secondaryMenu) {
    secondaryMenu = sceneTransitionsOptions?.secondaryMenu;
    secondaryMenuLabel = sceneTransitionsOptions.secondaryMenu.title;
    ChoiceButton = sceneTransitionsOptions.secondaryMenu.ChoiceButton;
    if (sceneTransitionsOptions.secondaryMenu.useAllDirections) {
      allDirectionsSecondary = true;
    }
  }

  // Tertiary Menu
  let TertiaryChoiceButton;
  let tertiaryMenuLabel = '';
  let allDirectionsTertiary = false;
  let tertiaryMenu;

  if (allScenesTransitionType !== 'Mixed' && sceneTransitionsOptions?.tertiaryMenu) {
    tertiaryMenu = sceneTransitionsOptions?.tertiaryMenu;
    TertiaryChoiceButton = tertiaryMenu?.ChoiceButton;
    allDirectionsTertiary = !!tertiaryMenu?.useAllDirections;
    tertiaryMenuLabel = sceneTransitionsOptions.tertiaryMenu.title;
    if (sceneTransitionsOptions.tertiaryMenu.useAllDirections) {
      allDirectionsTertiary = true;
    }
  }

  const actionSettings = {
    scribeId: scribe.id,
    sceneIds: scenes.map(({ id }) => id),
    animationType
  };

  const handleButtonClick = (payload: MenuOptions) => {
    setMenuOpen(payload);
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleMenuItemClick = (option: { type: string }) => {
    dispatch(setSceneTransitionType({ ...actionSettings, transitionType: option.type }));

    handleClose();
  };

  const handleDurationChange = ({ value }: { value: number | string }) => {
    dispatch(
      setSceneTransitionDuration({
        ...actionSettings,
        duration: value,
        eventTrigger: SCENE_TRANSITION_PANEL_EVENT_TRIGGER
      })
    );
  };

  return (
    <EditPanel>
      <EditPanelHeading>Scene entrance transition</EditPanelHeading>
      <EditPanelForm>
        <EditPanelFieldset>
          <AnimationTypeSelectorButtons>
            <div>
              <AnimationTypeSelectorButtonGroup
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleButtonClick({ anchor: e.currentTarget, primary: true, secondary: false, tertiary: false })
                }
                label="Type"
                id={`Type-AnimationTypeSelectorButtonGroup-scene`}
              >
                {Icon && <Icon />}
                <AnimationTypeSelectorButtonGroupText>{animationTypeTitle}</AnimationTypeSelectorButtonGroupText>
              </AnimationTypeSelectorButtonGroup>

              <div className="AnimationStagePanel__subset">
                {animationTypeTitle && (
                  <span hidden={animationType === SCENE_TRANSITION_KEY_NONE || containsSceneTransitionNone}>
                    <EditPanelNumberInput
                      id={`${animationTypeTitle.toLowerCase().replace(' ', '-')}-time`}
                      value={duration}
                      onChange={handleDurationChange}
                      units="s"
                      label="Duration:"
                      className={undefined}
                      disabled={undefined}
                    />
                  </span>
                )}
              </div>
            </div>

            {ChoiceButton && config && (
              <AnimationTypeSelectorButtonGroup
                onClick={e =>
                  handleButtonClick({ anchor: e.currentTarget, primary: false, secondary: true, tertiary: false })
                }
                label={secondaryMenuLabel}
                id={`${secondaryMenuLabel}-AnimationTypeSelectorButtonGroup-scene`}
              >
                <ChoiceButton {...actionSettings} useAllDirections={allDirectionsSecondary} config={config} />
              </AnimationTypeSelectorButtonGroup>
            )}

            {TertiaryChoiceButton && config && (
              <AnimationTypeSelectorButtonGroup
                onClick={e =>
                  handleButtonClick({ anchor: e.currentTarget, primary: false, secondary: false, tertiary: true })
                }
                label={tertiaryMenuLabel}
                id={`${tertiaryMenuLabel}-AnimationTypeSelectorButtonGroup-scene`}
              >
                <TertiaryChoiceButton {...actionSettings} useAllDirections={allDirectionsTertiary} config={config} />
              </AnimationTypeSelectorButtonGroup>
            )}
          </AnimationTypeSelectorButtons>

          <Popover
            className="AnimationStagePanel__menu"
            id={`${animationTypeTitle}-animation-options-menu`}
            anchorEl={menuOpen && menuOpen.anchor ? menuOpen.anchor : null}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(menuOpen?.anchor)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              style: {
                maxHeight: '50vh',
                minWidth: 200
              }
            }}
            BackdropProps={{ id: 'scene-transition-submenu-bg', invisible: true }}
          >
            {menuOpen?.primary && (
              <AnimationOptionMenu title="Type">
                <div className="AnimationStagePanel__optionsGrid">
                  {SCENE_TRANSITION_OPTIONS.map(opt => {
                    const { Icon: OptIcon, title: optTitle, type: optType, wideIcon } = opt;
                    return (
                      <ScribelyIconButton
                        id="scribe-transition-button"
                        outline={optType === animationType}
                        key={optType}
                        icon={<OptIcon />}
                        onClick={() => handleMenuItemClick(opt)}
                        wideIcon={wideIcon}
                      >
                        {optTitle}
                      </ScribelyIconButton>
                    );
                  })}
                </div>
              </AnimationOptionMenu>
            )}

            {menuOpen?.secondary && secondaryMenu && config && (
              <AnimationOptionMenu title={secondaryMenu.title}>
                <secondaryMenu.Component
                  onSelection={handleClose}
                  config={config}
                  useAllDirections={allDirectionsSecondary}
                  {...actionSettings}
                />
              </AnimationOptionMenu>
            )}
            {menuOpen?.tertiary && tertiaryMenu && config && (
              <AnimationOptionMenu title={tertiaryMenu.title}>
                <tertiaryMenu.Component
                  onSelection={handleClose}
                  config={config}
                  useAllDirections={!!tertiaryMenu?.useAllDirections}
                  {...actionSettings}
                />
              </AnimationOptionMenu>
            )}
          </Popover>
        </EditPanelFieldset>
      </EditPanelForm>
    </EditPanel>
  );
};

export default SceneTransitionsPanel;
