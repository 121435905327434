import { getEditorCanvas } from 'js/editor/EditorCanvas/EditorCanvas';
import { VSCoordinate } from 'js/types';
import * as PIXI from 'pixi.js';

export const centerElementOnCanvas = (
  width: number,
  height: number,
  scaleX: number,
  scaleY: number,
  maxCanvasWidth: number,
  maxCanvasHeight: number
): VSCoordinate => {
  return {
    x: maxCanvasWidth - (width * scaleX) / 2,
    y: maxCanvasHeight - (height * scaleY) / 2
  };
};

type MaxCanvasHeightWidthRatioCalculationsReturnType = {
  maxCanvasHeight: number;
  maxCanvasWidth: number;
  ratioW: number;
  ratioH: number;
};

const maxCanvasHeightWidthRatioCalculations = (
  width: number,
  height: number,
  canvasSize: { height: number; width: number }
): MaxCanvasHeightWidthRatioCalculationsReturnType => {
  const maxCanvasHeight = canvasSize.height / 2;
  const maxCanvasWidth = canvasSize.width / 2;

  const ratioW = maxCanvasWidth / width;
  const ratioH = maxCanvasHeight / height;

  return { maxCanvasHeight, maxCanvasWidth, ratioW, ratioH };
};

type FitElementToCanvasReturnType = {
  scaleX: number;
  scaleY: number;
  x: number;
  y: number;
};

export const fitElementToCanvas = (
  width: number,
  height: number,
  canvasSize: { height: number; width: number }
): FitElementToCanvasReturnType => {
  const { maxCanvasHeight, maxCanvasWidth, ratioW, ratioH } = maxCanvasHeightWidthRatioCalculations(
    width,
    height,
    canvasSize
  );

  if (ratioW <= ratioH) {
    return {
      scaleX: ratioW,
      scaleY: ratioW,
      ...centerElementOnCanvas(width, height, ratioW, ratioW, maxCanvasWidth, maxCanvasHeight)
    };
  }
  return {
    scaleX: ratioH,
    scaleY: ratioH,
    ...centerElementOnCanvas(width, height, ratioH, ratioH, maxCanvasWidth, maxCanvasHeight)
  };
};

export const getCenterOfViewport = (width: number, height: number): { x: number; y: number } => {
  const viewport = getEditorCanvas()?.calcViewportBoundaries();
  if (viewport) {
    const viewportRect = new PIXI.Rectangle(
      viewport?.tl.x,
      viewport?.tl.y,
      viewport?.br.x - viewport?.tl.x,
      viewport?.br.y - viewport?.tl.y
    );
    return {
      x: viewportRect.x + viewportRect.width / 2 - width / 2,
      y: viewportRect.y + viewportRect.height / 2 - height / 2
    };
  }
  return { x: 0, y: 0 };
};
