type LocalStorageTimelineKey = 'zoom' | 'position' | 'height' | 'scrollBoxHeight' | 'audioScrollBoxHeight' | 'sceneId';

const storageDictionary: Record<LocalStorageTimelineKey, string> = {
  zoom: 'VSCTimelineZoom_',
  position: 'VSCTimelinePosition_',
  height: 'VSCTimelineHeight_',
  scrollBoxHeight: 'VSCTimelineElementScrollBoxHeight_',
  audioScrollBoxHeight: 'VSCTimelineAudioScrollBoxHeight_',
  sceneId: 'VSCTimelineSceneId_'
};

const getTimelineStorageKey = (projectId: string | number, key: LocalStorageTimelineKey) =>
  storageDictionary[key] + projectId;

export const getTimelineLocalStorage = (projectId: string | number, key: LocalStorageTimelineKey) => {
  return localStorage.getItem(getTimelineStorageKey(projectId, key));
};

export const setTimelineLocalStorage = (projectId: string | number, key: LocalStorageTimelineKey, newValue: string) => {
  try {
    localStorage.setItem(getTimelineStorageKey(projectId, key), newValue);
  } catch (error) {
    console.error(error);
  }
};

export const getTimelineHeight = (projectId: string | number) => Number(getTimelineLocalStorage(projectId, 'height'));
