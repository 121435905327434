import { getGIFData, storeGIFData } from 'js/shared/lib/LocalDatabase';
import { ExtractedGIFData } from 'js/types';
import { GifReader } from 'omggif';

const GIF_DATA_MEMORY_CACHE = new Map<string, ExtractedGIFData>();

/*
  This helper reads the individual GIF frames, prints them to a canvas and then 
  takes the result as data URLs. The reason for this is so we get the image as it would look at each 
  frame. GIFs can sometimes contain image deltas in the frames (the difference between frames, not whole images)
  so we can't just extract each frame. Having each frame then allows us complete control over the playback
  as we can use the frames in an Animated Sprite.
*/
export default async function extractGifData(id: string, arrayBuffer: ArrayBuffer): Promise<ExtractedGIFData> {
  const memoryCached = GIF_DATA_MEMORY_CACHE.get(id);
  if (memoryCached) {
    return memoryCached;
  }

  const clientCached = await getGIFData(id);
  if (clientCached) {
    GIF_DATA_MEMORY_CACHE.set(id, clientCached.data);
    return clientCached.data;
  }

  const arr = new Uint8Array(arrayBuffer);
  const reader = new GifReader(arr);
  const canvas = document.createElement('canvas');
  canvas.width = reader.width;
  canvas.height = reader.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('Unable to get canvas 2d context for extracting GIF frames');

  let prevFrameInfo = null;
  const totalFrames = reader.numFrames();
  const frames = [];
  let gifLengthMs = 0;
  const frameTimes = [];

  for (let i = 0; i < totalFrames; i += 1) {
    const frameInfo = reader.frameInfo(i);
    const frameStartTime = !prevFrameInfo ? 0 : gifLengthMs;
    const frameEndTime = frameStartTime + frameInfo.delay * 10;
    frameTimes.push({
      startTimeMs: frameStartTime,
      endTimeMs: frameEndTime
    });
    gifLengthMs += frameInfo.delay * 10;

    // Inspiration for how to print GIF frames taken from GIF inspector
    // https://github.com/movableink/gif-inspector
    if (i === 0) {
      // always clear canvas to start
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    } else if (prevFrameInfo && prevFrameInfo.disposal === 2) {
      // disposal was "restore to background" which is essentially "restore to transparent"
      ctx.clearRect(prevFrameInfo.x, prevFrameInfo.y, prevFrameInfo.width, prevFrameInfo.height);
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    reader.decodeAndBlitFrameRGBA(i, imageData.data);
    ctx.putImageData(imageData, 0, 0);
    frames.push(canvas.toDataURL());
    prevFrameInfo = frameInfo;
  }

  const gifData = { frames, totalFrames, loopCount: reader.loopCount(), gifLengthMs, frameTimes };
  GIF_DATA_MEMORY_CACHE.set(id, gifData);
  await storeGIFData(id, gifData);

  return gifData;
}
