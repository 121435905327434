import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

import BrokenImageElement from '../BrokenImageElement';
import ImageElement from '../ImageElement';
import SVGImageElement from '../SVGImageElement';

import { isSVGElement } from './isSVGElement';

const createImageElement = async (element: ScribeImageElementModel) => {
  if (!element._imageUrl) {
    const brokenImage = await BrokenImageElement.createBrokenBob(element);

    return brokenImage;
  }

  if (isSVGElement(element)) {
    return await SVGImageElement.createSVGElement(element);
  }

  return await ImageElement.createImageElement(element);
};

export default createImageElement;
