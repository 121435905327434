import getProjectFromPathname from 'js/sagas/sagaHelpers/getProjectFromPathname';
import { ExistingScribeModel } from 'js/types';
import { call, takeEvery } from 'redux-saga/effects';
import { SET_QUICK_IMAGE_SEARCH_TAG, SetQuickImageSearchTagAction } from 'js/actionCreators/ImageDrawerActions';

import { track } from '../mixpanelProvider';
function* trackQuickImageSearchTag({ tag }: SetQuickImageSearchTagAction) {
  const project = (yield call(getProjectFromPathname)) as ExistingScribeModel | undefined;
  if (!project) return;
  if (!tag) return;
  yield call(track, 'Use Quick Image Search Tag', {
    'Scribe ID': project.id,
    'Search Tag': tag
  });
}
export default function* trackImageDrawerSagas() {
  yield takeEvery(SET_QUICK_IMAGE_SEARCH_TAG, trackQuickImageSearchTag);
}
