import defaultHandImageMovingRight from 'imgs/hands/Rosie1.png';
import defaultHandImageMovingLeft from 'imgs/hands/Rosie2.png';
import defaultPenImageMovingRight from 'imgs/hands/default-pen-1.png';
import defaultPenImageMovingLeft from 'imgs/hands/default-pen-2.png';
import defaultEraseImageRight from 'imgs/hands/default-erase-1.png';
import defaultEraseImageLeft from 'imgs/hands/default-erase-2.png';
import defaultDragImage from 'imgs/hands/default-drag.png';
import {
  DEFAULT_HAND_CURSOR_ID,
  DEFAULT_PEN_CURSOR_ID,
  DEFAULT_ERASE_CURSOR_ID,
  DEFAULT_DRAG_CURSOR_ID
} from 'js/config/consts';

export default function getCursorResourcesAndMetadata(cursors, cursorId) {
  const noCursor = {
    resources: [],
    handOffsetX: 0,
    handOffsetY: 0
  };

  if (cursorId === -1) {
    return noCursor;
  }

  if (cursorId === DEFAULT_HAND_CURSOR_ID) {
    return {
      resources: [defaultHandImageMovingRight, defaultHandImageMovingLeft],
      handOffsetX: 65,
      handOffsetY: -35
    };
  }

  if (cursorId === DEFAULT_PEN_CURSOR_ID) {
    return {
      resources: [defaultPenImageMovingRight, defaultPenImageMovingLeft],
      handOffsetX: 96,
      handOffsetY: 96
    };
  }

  if (cursorId === DEFAULT_ERASE_CURSOR_ID) {
    return {
      resources: [defaultEraseImageRight, defaultEraseImageLeft],
      handOffsetX: -3,
      handOffsetY: -55
    };
  }

  if (cursorId === DEFAULT_DRAG_CURSOR_ID) {
    return {
      resources: [defaultDragImage],
      handOffsetX: -174,
      handOffsetY: -21
    };
  }

  const cursorData = cursors.find(cursor => cursor.id === cursorId);

  if (!cursorData) {
    return noCursor;
  }

  const attributes = new window.DOMParser()
    .parseFromString(cursorData.metadata, 'text/xml')
    .getElementsByTagName('customHand')[0].attributes;
  const resources = Object.entries(cursorData.resources)
    .map(([_, v]) => {
      if (v.type === 'thumbnail') {
        return null;
      }

      return v.url;
    })
    .filter(Boolean);

  return {
    resources,
    handOffsetX: attributes.offset1X.value,
    handOffsetY: attributes.offset1Y.value
  };
}
